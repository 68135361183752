import React from "react";
import Header from "components/Headers/Header.js"
import {
  Form,
  FormGroup,
  Input,
  CardBody,
  Col,
  Card,
  Row,
  Container,
  Button,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

const Curatemanually = () => {

  return (
    <>
      <Header />
      <Container className="mt-5"></Container>
      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>
          Button Dropdown
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Header</DropdownItem>
          <DropdownItem disabled>Action</DropdownItem>
          <DropdownItem>Another Action</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </>)
}

export default Curatemanually;