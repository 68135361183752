import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Card, Container, Form, FormGroup, Label, Col, Input,
    Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button,
    Modal, ModalHeader, ModalFooter, ModalBody
} from 'reactstrap';

export default class AddMedia extends Component {
    constructor(props) {
        super(props);

        this.handleToggle = this.handleToggle.bind(this);
        this.modal = this.modal.bind(this);
        this.onClick = this.onClick.bind(this);

        this.state = {
            modal: false,
            showForm: false,
            allDropDown: {
                isMovie: false,
                isOpenOtt: false,
                isOpenCountry: false,
                isOpenLanguage: false,

            },
        };
    }
    onClick() {
        // On click we change our state – this will trigger our `render` method
        this.setState({ showForm: true });
    }

    handleToggle(name) {
        let newForm = {
            ...this.state.allDropDown,
            [name]: !this.state.allDropDown[name]
        }
        this.setState({
            allDropDown: newForm
        })
    }

    modal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleDeleteForm = () => {
        this.setState({
            showForm: false,
        })
    }


    renderShowForm = () => {
        return (
            <div className='show-form'>
                <div className='show-form-delete'>
                    <i className="fa fa-trash" aria-hidden="true" onClick={this.handleDeleteForm}></i>
                </div>
                <Form>
                    <FormGroup row>
                        <Label for="exampleEmail2" sm={2}>Key</Label>
                        <Col sm={4} className="show-form-input">
                            <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail2" sm={2}>Name</Label>
                        <Col sm={4} className="show-form-input">
                            <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail2" sm={2}>Site</Label>
                        <Col sm={4} className="show-form-input">
                            <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail2" sm={2}>Map</Label>
                        <Col sm={4} className="show-form-input">
                            <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }

    render() {
        const { showForm } = this.state;
        return (
            <>
                <Header />
                <Container className='mt-3' >
                    <h3 className='mt-3' >Add Media</h3>
                    <Card className='mt-3 mb-3 ' >
                        <Row className={'m-0 mt-3'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Title</Label>
                                        <Col sm={9}>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Content Title" bsSize="lg" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col >
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Type</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isMovie']} toggle={() => this.handleToggle('isMovie')} >
                                                <DropdownToggle caret>
                                                    Movie
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col >
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Country</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenCountry']} toggle={() => this.handleToggle('isOpenCountry')} >
                                                <DropdownToggle caret>
                                                    Select Country
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col >
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Language</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenLanguage']} toggle={() => this.handleToggle('isOpenLanguage')} >
                                                <DropdownToggle caret>
                                                    Select Language
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Release Date</Label>
                                        <Col sm={9}>
                                            <FormGroup className='m-0'>
                                                <Input type="date" name="date" id="exampleDate" placeholder="From" />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">OTT</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenOtt']} toggle={() => this.handleToggle('isOpenOtt')} >
                                                <DropdownToggle caret>

                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Duration</Label>
                                        <Col sm={9}>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="" bsSize="lg" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Category</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenOtt']} toggle={() => this.handleToggle('isOpenOtt')} >
                                                <DropdownToggle caret>

                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Genre</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenOtt']} toggle={() => this.handleToggle('isOpenOtt')} >
                                                <DropdownToggle caret>

                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Cast</Label>
                                        <Col sm={9}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenOtt']} toggle={() => this.handleToggle('isOpenOtt')} >
                                                <DropdownToggle caret>

                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Popularity Index</Label>
                                        <Col sm={9}>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Content Title" bsSize="lg" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Rating</Label>
                                        <Col sm={9}>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Content Title" bsSize="lg" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Keywords</Label>
                                        <Col sm={9}>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Content Title" bsSize="lg" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label for="exampleEmail" sm={3} size="lg">Director</Label>
                                        <Col sm={9}>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Content Title" bsSize="lg" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row className={'m-0'} >
                            <Col>
                                <Form>
                                    <FormGroup row>
                                        <Label style={{ width: "110px", marginLeft: "15px" }} for="exampleEmail" size="lg">Overview</Label>
                                        <Col >
                                            <FormGroup>
                                                <Input type="textarea" name="text" id="exampleText" />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <div className='mb-3' >
                            <h3 className='ml-3 heading'  >
                                Add Content
                            </h3>
                            <Row className='mt-3 m-0' style={{ width: "50%", justifyContent: 'space-between' }}>
                                <Col>
                                    <h3>
                                        Trailer
                                    </h3>
                                </Col>
                                <Col>
                                    <Button color='danger' onClick={() => this.setState({ showForm: true })}>Add Trailer</Button>
                                </Col>
                            </Row>
                            {
                                showForm ?
                                    <Row className='mt-3 m-0' >
                                        <Col sm={3}>
                                        </Col>
                                        <Col sm={9}>
                                            {this.renderShowForm()}
                                        </Col>
                                    </Row>
                                    : null
                            }
                            <Row className='mt-3 m-0' mt={3} style={{ width: "50%", justifyContent: 'space-between' }}>
                                <Col>
                                    <h3>
                                        Poster
                                    </h3>
                                </Col>
                                <Col>
                                    <Button onClick={() => this.setState({ showForm: true })} color='info'>Add Images</Button>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Container>
                <Modal isOpen={this.state.modal} toggle={this.modal} >
                    <ModalHeader toggle={this.modal}></ModalHeader>
                    <ModalBody >
                        <Form>
                            <FormGroup row>
                                <Label for="exampleEmail2" sm={2}>Key</Label>
                                <Col sm={10}>
                                    <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="exampleEmail2" sm={2}>Name</Label>
                                <Col sm={10}>
                                    <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="exampleEmail2" sm={2}>Site</Label>
                                <Col sm={10}>
                                    <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="exampleEmail2" sm={2}>Map</Label>
                                <Col sm={10}>
                                    <Input type="email" name="email" id="exampleEmail2" placeholder="" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>

            </>
        )
    }
}
