import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

// student list

export const getStudentdata = () => {
  return (dispatch) => {
    dispatch({ type: WEB.STU_DATA_GET_REQUEST });
    let url = `tracking/studentalldetail`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          console.log(payload);
          dispatch({
            type: WEB.STU_DATA_GET_SUCCESS,
            payload: {
              data: payload,
            },
          });
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.STU_DATA_GET_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};

// QR List

export const getQRdata = () => {
  return (dispatch) => {
    dispatch({ type: WEB.QR_DATA_GET_REQUEST });
    let url = `tracking/qrlinkdetail`;

    axiosInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          console.log(payload);
          dispatch({
            type: WEB.QR_DATA_GET_SUCCESS,
            payload: {
              data: payload,
            },
          });
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.QR_DATA_GET_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};

export const updateQRdata = (body) => {
  return (dispatch) => {
    dispatch({ type: WEB.QR_DATA_UPDATE_REQUEST });
    let url = `tracking/qrlinkdetail`;

    axiosInstance
      .put(url,body)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.QR_DATA_UPDATE_SUCCESS,
            payload: {
              data: payload,
            },
          });
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.QR_DATA_UPDATE_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};
