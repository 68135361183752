import React from 'react'
import Header from "components/Headers/Header.js";
import { Container, Card, Row, Col, Button, Table, Link, Modal, ModalHeader, ModalBody, Dropdown, CustomInput, Label, DropdownToggle, DropdownMenu, DropdownItem, Input, ModalFooter } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from 'components/Pagination/CustomPagination';
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../common/Loader'
import Select from 'react-select';
import chroma from 'chroma-js';
import makeAnimated from 'react-select/animated';
import { getBundleList, addBundle, deleteBundle, clearMessage } from '../../redux/actions/bundle.action';
import { getPlanTypes, getPlatformList, getPlatformPriceDropDown } from '../../redux/actions/platform.action';
import CustomMyInput from 'views/common/CustomMyInput';
import ReactGA from 'react-ga';


export default function BundleManagment() {
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [isPlan, setIsPlan] = React.useState(false)
    const [isCurrency, setIsCurrency] = React.useState(false)

    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);


    // form state
    const [isExhausted, setIsExhausted] = React.useState(false);
    const [status, setStatus] = React.useState(true);
    const [bundleName, setBundleName] = React.useState('');
    const [planType, setPlanType] = React.useState('');
    const [currency, setCurrency] = React.useState('');
    const [platfromPrice, setPlatfromPrice] = React.useState([]);
    const [price, setPrice] = React.useState('');
    const [discount, setDiscount] = React.useState('');
    const [frenziPoint, setFrenziPoints] = React.useState('');

    // dropdown values
    const [currencyName, setCurrencyName] = React.useState('');
    const [platfromPriceName, setPlatfromPriceName] = React.useState('');

    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();

    const bundleList = useSelector((state) => state.bundle && state.bundle.bundleListResult ? state.bundle.bundleListResult : {})

    const platformPlanType = useSelector((state) => state.platform && state.platform.platformPlanTypeListResult ? state.platform.platformPlanTypeListResult : {})
    const platformPriceDropdownList = useSelector((state) => state.platform && state.platform.platformPriceDropdownListResult ? state.platform.platformPriceDropdownListResult : {})


    const message = useSelector((state) => state.bundle && state.bundle.message ? state.bundle.message : false)
    const errMesg = useSelector((state) => state.bundle && state.bundle.errMesg ? state.bundle.errMesg : false)
    const apiStatus = useSelector((state) => state.bundle && state.bundle.status ? state.bundle.status : false)
    const isLoading = useSelector((state) => state.bundle && state.bundle.isLoading ? state.bundle.isLoading : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        dispatch(getBundleList(searchText, currentPage, pageLimit));
    }, [currentPage, pageLimit, message])

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(getBundleList(searchText, 1, pageLimit));
    }

    React.useEffect(() => {
        dispatch(getPlanTypes());
    }, [])

    React.useEffect(() => {
        if (message && message != "") {
            console.log(apiStatus)
            if (apiStatus) {
                toast.success(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    const getMessage = (value) => {
        return value.split('=>')[1];
    }

    const getDropDownList = (type) => {
        setPlanType(type);
        dispatch(getPlatformPriceDropDown(type));
    }

    const getPlanName = (type) => {
        var plan_types = platformPlanType.data && platformPlanType.data.plan_types;
        if (plan_types && plan_types.length > 0) {
            var result = plan_types.filter(item => item.plan_type == type);
        }
        return result && result[0] ? result[0].plan_name : 'N/A';
    }

    const handleSave = () => {
        const body = {
            "bundle_name": bundleName,
            "platform_pd": platfromPrice,
            "price": price,
            "currency": currency,
            "discount": discount,
            "frenzi_points": frenziPoint,
            "plan_type": planType,
            "is_active": status,
            "is_exhausted": isExhausted
        }
        eventReporting('Subscription', 'Bundle added  - ' + bundleName, frenziPoint);
        dispatch(addBundle(body));
        if (!isLoading) {
            setIsAddPlatform(false);
        }
    }

    const handleMultiSelectChange = (e) => {
        var temp = [];
        if (e && e.length > 0) {
            e.map((y, j) => {
                temp.push(y.value);
            })
        }
        setPlatfromPrice([...temp])
    }

    const getOptions = (items) => {
        const tempArr = [];
        if (items && items.length > 0) {
            items.map((x, i) => {
                tempArr.push({ value: x.platform_pd, label: x.platform_name })
            })
        }
        return tempArr;
    }

    const getPlatformListName = (list) => {
        var tempName = "";
        if (list && list.length > 0) {
            list.map((x, l) => {
                if (l == list.length - 1) {
                    tempName += x.platform_name
                } else {
                    tempName += x.platform_name + ", "
                }
            })
        } else {
            tempName = 'N/A';
        }
        return tempName;
    }

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <h3 className='mb-3'>Bundle Management</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <Button color="info" onClick={() => {
                            debugger
                            setIsAddPlatform(!isAddPlatform);
                            ReactGA.modalview(window.location.pathname + '?modal=Add Bundle');
                        }} >Add Bundle</Button>
                    </Row>
                    <Row className='justify-content-between m-0'>
                        <Container className='table'>
                            <Table hover className='mt-3 className="align-items-center table-flush"' >
                                <thead >
                                    <tr>
                                        <th className='table_heading' width="7%">
                                            S. No.
                                        </th>
                                        <th className='table_heading'>
                                            Name
                                        </th>
                                        <th className='table_heading'>
                                            Platforms

                                        </th>
                                        <th className='table_heading'>
                                            Plan Type
                                        </th>
                                        <th className='table_heading'>
                                            Currency
                                        </th >
                                        <th className='table_heading'>
                                            Price
                                        </th>
                                        <th className='table_heading'>
                                            Discount
                                        </th>
                                        <th className='table_heading'>
                                            Frenzi Points
                                        </th>
                                        <th className='table_heading'>
                                            Exhausted
                                        </th>
                                        <th className='table_heading'>
                                            Status
                                        </th>
                                        <th className='table_heading'>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bundleList && bundleList.data && bundleList.data.results && bundleList.data.results.length > 0 ? bundleList.data.results.map((x, i) => {
                                        return <tr>
                                            <th scope="row"> {i + 1}</th>
                                            <td>{x.bundle_name ? x.bundle_name : 'N/A'}</td>
                                            <td>{x.platforms && x.platforms.length > 0 ? getPlatformListName(x.platforms) : 'N/A'}</td>
                                            <td>{x.plan_type ? getPlanName(x.plan_type) : 'N/A'}</td>
                                            <td>{x.currency ? x.currency : 'N/A'}</td>
                                            <td>{x.price ? x.price : 'N/A'}</td>
                                            <td>{x.discount ? x.discount : 'N/A'}</td>
                                            <td>{x.frenzi_points ? x.frenzi_points : 'N/A'}</td>
                                            <td>{x.is_exhausted ? 'YES' : 'NO'}</td>
                                            <td>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    label={`${x.is_active ? 'Active' : 'Inactive'}`}
                                                    className=''
                                                    checked={x.is_active}
                                                    disabled={true}
                                                />
                                            </td>
                                            <td>
                                                {/* <Link to="/admin/edit-category/923993432dsknfdks">
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="info"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 10 }}
                                                    // onClick={() => handleEdit(i)}
                                                    >
                                                        <i className="fas fa-pen pt-1"></i>
                                                    </Button>
                                                </Link> */}
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to delete this item ?")) {
                                                            eventReporting('Subscription', 'Bundle deleted  - ', x.uuid);
                                                            dispatch(deleteBundle(x.uuid))
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    })
                                        :
                                        <tr>
                                            <td colSpan={11}><h2 className='text-center'>No Data Available</h2></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            {bundleList && bundleList.data && bundleList.data.pagination && (bundleList.data.pagination.is_next_page || bundleList.data.pagination.is_previous_page) &&
                                <CustomPagination
                                    pagination={bundleList.data && bundleList.data.pagination && bundleList.data.pagination ? bundleList.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={bundleList.data && bundleList.data.results ? bundleList.data.results.length : 20}
                                />
                            }
                            <Modal
                                isOpen={isAddPlatform}
                                toggle={() => setIsAddPlatform(!isAddPlatform)}
                                size={'lg'}
                            >
                                <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                                    <h3>Add Bundle</h3>
                                </ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <div className=' pl-3 pr-3 ml-3'>
                                            <Row  >
                                                <Label for="exampleEmail" size="lg"><h4>Exhausted</h4></Label>
                                                <CustomInput
                                                    // label="Exhausted"
                                                    type="switch"
                                                    id="exampleCustomSwitch"
                                                    name="customSwitch"
                                                    className='ml-5 mt-3'
                                                    checked={isExhausted}
                                                    onChange={(e) => setIsExhausted(e.target.checked)}
                                                />
                                            </Row>
                                        </div>
                                        <div className=' pl-3 pr-3 ml-6'>
                                            <Row>
                                                <Label for="exampleEmail" size="lg"><h4>Status</h4></Label>
                                                <CustomInput
                                                    // label="Exhausted"
                                                    type="switch"
                                                    id="exampleCustomSwitch2"
                                                    name="customSwitch2"
                                                    className='ml-5 mt-3'
                                                    checked={status}
                                                    onChange={(e) => setStatus(e.target.checked)}
                                                />
                                            </Row>
                                        </div>

                                    </Row>
                                    <Row className='pl-3 pr-3 mt-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Bundle Name</p>
                                        </Col>
                                        <Col sm={9}>
                                            <CustomMyInput className='w-100' onChange={(e) => setBundleName(e.target.value)} required={true} />
                                        </Col>
                                    </Row>
                                    <Row className='pl-3 mt-3 pr-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Plan Type</p>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="d-flex m-100 justify-content-center">
                                                <Dropdown className='w-100' isOpen={isPlan} toggle={() => setIsPlan(!isPlan)}>
                                                    <DropdownToggle className='w-100' caret>
                                                        {planType != "" ? getPlanName(planType) : 'Select Plan Type'}
                                                    </DropdownToggle>
                                                    <DropdownMenu
                                                    >
                                                        {platformPlanType && platformPlanType.data && platformPlanType.data.plan_types && platformPlanType.data.plan_types.length > 0 && platformPlanType.data.plan_types.map((y, j) => {
                                                            return <DropdownItem onClick={() => getDropDownList(y.plan_type)}>
                                                                {y.plan_name}
                                                            </DropdownItem>
                                                        })}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='pl-3 mt-3 pr-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Currency</p>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="d-flex m-100 justify-content-center">
                                                <Dropdown className='w-100' isOpen={isCurrency} toggle={() => setIsCurrency(!isCurrency)}>
                                                    <DropdownToggle className='w-100' caret>
                                                        {currency && currency != "" ? currency : 'Choose Currency'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setCurrency('INR')}>INR</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='pl-3 mt-3 pr-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Platform Price</p>
                                        </Col>
                                        <Col sm={9}>
                                            <div className="d-flex m-100 justify-content-center">
                                                <Select
                                                    // value={selectedOption}
                                                    className='w-100'
                                                    components={animatedComponents}
                                                    onChange={(e) => handleMultiSelectChange(e)}
                                                    options={getOptions(platformPriceDropdownList && platformPriceDropdownList.data && platformPriceDropdownList.data.results && platformPriceDropdownList.data.results.length > 0 ? platformPriceDropdownList.data.results : [])}
                                                    isMulti={true}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='pl-3 mt-3 pr-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Price</p>
                                        </Col>
                                        <Col sm={9}>
                                            <CustomMyInput
                                                onChange={(e) => setPrice(e.target.value)}
                                                required={true}
                                                validationType={'price'}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='pl-3 mt-3 pr-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Discount</p>
                                        </Col>
                                        <Col sm={9}>
                                            <CustomMyInput
                                                onChange={(e) => setDiscount(e.target.value)}
                                                required={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='pl-3 mt-3 pr-3 w-100'>
                                        <Col sm={3} className="p-0">
                                            <p className='mr-3 mb-0 v-middle'>Frenzi Points</p>
                                        </Col>
                                        <Col sm={9}>
                                            <CustomMyInput
                                                onChange={(e) => setFrenziPoints(e.target.value)}
                                                required={true}
                                                validationType={'number'}
                                            />
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <div className='text-center mb-3'>
                                    <Button
                                        color="success"
                                        onClick={() => handleSave()}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Modal>
                        </Container>
                    </Row>
                </Card>
            </Container>

        </>

    )
}
