import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.PERSON_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.PERSON_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const deletePerson = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.PERSON_DELETE_REQUEST });

        axiosInstance
            .delete(`adminpanel/person/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PERSON_DELETE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PERSON_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
                if (err.status === 500) {
                    dispatch({
                        type: WEB.PERSON_DELETE_FAILED,
                        payload: {
                            error: "Server error",
                        },
                    });
                }
            })
    }
}

export const getPersonDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.PERSON_GET_DETAILS_REQUEST });
        axiosInstance
            .get(`adminpanel/person/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PERSON_GET_DETAILS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PERSON_GET_DETAILS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const updatePersonDetails = (id, body = {}) => {
    return (dispatch) => {
        dispatch({ type: WEB.PERSON_UPDATE_REQUEST });

        axiosInstance
            .put(`adminpanel/person/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PERSON_UPDATE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PERSON_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
                if (err.status === 500) {
                    dispatch({
                        type: WEB.PERSON_UPDATE_FAILED,
                        payload: {
                            error: "Server error",
                        },
                    });
                }
            })
    }
}


export const addPerson = (body = {}) => {
    return (dispatch) => {
        dispatch({ type: WEB.PERSON_ADD_REQUEST });

        axiosInstance
            .post(`adminpanel/person`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PERSON_ADD_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PERSON_ADD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
                if (err.status === 500) {
                    dispatch({
                        type: WEB.PERSON_ADD_FAILED,
                        payload: {
                            error: "Server error",
                        },
                    });
                }
            })
    }
}