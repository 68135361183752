import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";


export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.WATCHLIST_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.WATCHLIST_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getWatchList = (value, page = 1, pagelimit = 20 ) => {
    return (dispatch) => {
        dispatch({ type: WEB.WATCHLIST_GET_REQUEST });

        axiosInstance
            .get(`native_main/adminwatchlist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WATCHLIST_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WATCHLIST_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const createWatchList = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.CREATE_WATCHLIST_REQUEST });

        axiosInstance
            .post(`native_main/adminwatchlist`,body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.CREATE_WATCHLIST_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CREATE_WATCHLIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const deleteWatchList = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_WATCHLIST_REQUEST });

        axiosInstance
            .delete(`native_main/adminwatchlistdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_WATCHLIST_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_WATCHLIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const updateWatchList = (id,body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_WATCHLIST_REQUEST });

        axiosInstance
            .put(`native_main/adminwatchlistdetail/${id}`,body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_WATCHLIST_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_WATCHLIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const getWatchListMemberList = (value, page = 1, pagelimit = 30) => {
    return (dispatch) => {
        dispatch({ type: WEB.WATCHLIST_MEMBER_GET_REQUEST });

        axiosInstance
            .get(`native_main/adminwatchlistmember?watchlist=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WATCHLIST_MEMBER_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WATCHLIST_MEMBER_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getWatchListMediaList = (value, page = 1, pagelimit = 30) => {
    return (dispatch) => {
        dispatch({ type: WEB.WATCHLIST_MEDIA_GET_REQUEST });

        axiosInstance
            .get(`native_main/adminwatchlistmedia?watchlist=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WATCHLIST_MEDIA_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WATCHLIST_MEDIA_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addOrRemoveMembers = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_REMOVE_MEMBERS_REQUEST });

        axiosInstance
            .post(`native_main/adminwatchlistmember`,body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.ADD_REMOVE_MEMBERS_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_REMOVE_MEMBERS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addOrRemoveMedia = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_REMOVE_MEDIA_REQUEST });

        axiosInstance
            .post(`native_main/adminwatchlistmedia`,body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.ADD_REMOVE_MEDIA_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_REMOVE_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}