import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";


export const clearMessage = (clear = true) => {
    return (dispatch) => {
      dispatch({ type: WEB.ADMIN_CLEAR_MESSAGE_REQUEST });
      if (clear) {
        dispatch({ type: WEB.ADMIN_CLEAR_MESSAGE_SUCCESS });
      }
    }
  }

export const adminList = (value, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_ADMIN_LIST_REQUEST });
        axiosInstance
            .get(`adminpanel/administrator?search=${value}&pagelimit=${pagelimit}&page=${page}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_ADMIN_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_ADMIN_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const createAdmin = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.POST_ADD_ADMIN_REQUEST });

        axiosInstance
            .post(`adminpanel/administrator`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.POST_ADD_ADMIN_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.POST_ADD_ADMIN_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const adminPermissionDropdown = () => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_ADMIN_PERMISSION_DROPDOWN_REQUEST });
        axiosInstance
            .get(`adminpanel/permissionsdropdown`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_ADMIN_PERMISSION_DROPDOWN_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_ADMIN_PERMISSION_DROPDOWN_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteAdmin = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_ADMIN_REQUEST });

        axiosInstance
            .delete(`adminpanel/administratordetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_ADMIN_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_ADMIN_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateAdmin = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_ADMIN_LIST_REQUEST });

        axiosInstance
            .put(`adminpanel/administratordetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_ADMIN_LIST_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_ADMIN_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const retriveAdminDetail = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_RETRIVE_ADMIN_DETAIL_REQUEST });

        axiosInstance
            .get(`adminpanel/administratordetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_RETRIVE_ADMIN_DETAIL_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_RETRIVE_ADMIN_DETAIL_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const changeAdminPassword = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.CHANGE_ADMIN_PASSWORD_REQUEST });

        axiosInstance
            .put(`main/changepassword`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.CHANGE_ADMIN_PASSWORD_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CHANGE_ADMIN_PASSWORD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}