import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { toast, ToastContainer } from "react-toastify";
import { Card, Container, CardBody, Table, CustomInput, Input, Col } from "reactstrap";
import CustomPagination from "components/Pagination/CustomPagination";
import { useSelector, useDispatch } from "react-redux";
import { getProvidersDropdown } from "redux/actions/media.action";
import { updateWatchOnTv } from "redux/actions/platform.action";
import { clearMessage } from "redux/actions/platform.action";
import ReactGA from 'react-ga';

export default function WatchOnTv() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [inputShow, setInputShow] = useState('');
  const [inputShow1, setInputShow1] = useState('');
  const [inputShow2, setInputShow2] = useState('');
  const [inputShow3, setInputShow3] = useState('');
  const [inputText, setInputText] = useState('');
  const [categoryName, setCategoryName] = useState('');

  const dispatch = useDispatch();
  const status = useSelector((state) => state.platform && state.platform.status ? state.platform.status : '')
  const errMesg = useSelector((state) => state.platform && state.platform.errMesg ? state.platform.errMesg : '')

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    dispatch(getProvidersDropdown(categoryName, currentPage, pageLimit));
  }, [currentPage, pageLimit, categoryName]);

  const eventReporting = (category, action, val) => {
    ReactGA.event({
      category: category,
      action: action,
      label: val
    });
  }

  useEffect(() => {
    if (status && status != "") {
      toast.success((status), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearMessage());
      dispatch(getProvidersDropdown(categoryName, currentPage, pageLimit));
    }

    if (errMesg && errMesg != "") {
      toast.error((errMesg), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearMessage());
    }
  }, [errMesg, status])

  const providersList = useSelector((state) =>
    state.media && state.media.providersListData
      ? state.media.providersListData
      : {}
  );

  useEffect(() => {
    if (status && status != "") {
      dispatch(getProvidersDropdown(categoryName, currentPage, pageLimit));
    }
  }, [status])


  const handleUpdate = (val, prevVal, data, name, id) => {
    if (!prevVal || val != prevVal) {
      const body = {
        watch_on_tv: val
      }
      eventReporting('Admin', 'Changed watch on tv status of ' + id + ' to - ' + val, id);
      dispatch(updateWatchOnTv(id, body));
    }
  }

  const handleUpdatePackage = (val, prevVal, data, name, id) => {
    if ((!prevVal || val != prevVal) && val != '') {
      const body = {
        [name]: val,
      }
      eventReporting('Admin', 'Changed ' + name + ' ' + id + ' to - ' + val, id);

      dispatch(updateWatchOnTv(id, body));
      setInputShow('');
      setInputShow1('');
      setInputShow2('');
      setInputShow3('');
      setInputText('');
    } else {
      setInputShow('');
      setInputShow1('');
      setInputShow2('');
      setInputShow3('');
      setInputText('');
    }
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <div>
        <Container>
          <Card className="mt-4 p-3 rounded-lg">
            <CardBody>
              <Col sm={3} className="mb-3">
                <Input placeholder='Search Platform here...'
                  onChange={(e) => setCategoryName(e.target.value)}
                  value={categoryName}
                />
              </Col>

              <div style={{ overflowX: 'auto' }}>
                <Table>
                  <thead>
                    <tr>
                      <th width='50px' className="Platform_On_TV">S.No.</th>
                      <th className="Platform_On_TV">Platform Logo</th>
                      <th className="Platform_On_TV">Platform Name</th>
                      <th className="Platform_On_TV">Android TV Package</th>
                      <th className="Platform_On_TV">Android Component</th>
                      <th className="Platform_On_TV">JIO Component</th>
                      <th className="Platform_On_TV">JIO Package</th>
                      <th className="Platform_On_TV">Watch On TV?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {providersList.data?.results &&
                      providersList.data.results.length > 0 ? (
                      providersList.data.results.map((x, i) => {
                        return (
                          <tr key={i + 1}>
                            <td>
                              {(pageLimit * (currentPage - 1)) + (i + 1)}.
                            </td>
                            <td>
                              <img
                                alt="N/A"
                                className="platform-img"
                                src={x.platform_logo ? x.platform_logo : ""}
                              />
                            </td>
                            <td>{x.platform_name ? x.platform_name : "N/A"}</td>
                            <td>
                              {inputShow === x.uuid ?
                                <Input
                                  style={{ width: 'auto' }}
                                  size={'sm'}
                                  autoFocus={true}
                                  placeholder='Android TV Package Name'
                                  onChange={(e) => setInputText(e.target.value)}
                                  onBlur={(e) => handleUpdatePackage(e.target.value, x?.android_tv_package, x, 'android_tv_package', x.uuid)}
                                  onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                      handleUpdatePackage(e.target.value, x?.android_tv_package, x, 'android_tv_package', x.uuid)
                                    }
                                  }}
                                  value={inputText} />
                                :
                                <span className='pointer' onClick={() => {
                                  setInputText(x?.android_tv_package)
                                  setInputShow(x.uuid);
                                }}>{x?.android_tv_package || 'N/A'}</span>
                              }
                            </td>
                            <td>
                              {inputShow3 === x.uuid ?
                                <Input
                                  style={{ width: 'auto' }}
                                  size={'sm'}
                                  autoFocus={true}
                                  placeholder='jio component name'
                                  onChange={(e) => setInputText(e.target.value)}
                                  onBlur={(e) => handleUpdatePackage(e.target.value, x?.component_name, x, 'component_name', x.uuid)}
                                  onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                      handleUpdatePackage(e.target.value, x?.component_name, x, 'component_name', x.uuid)
                                    }
                                  }}
                                  value={inputText} />
                                :
                                <span className='pointer' onClick={() => {
                                  setInputText(x?.component_name)
                                  setInputShow3(x.uuid);
                                }}>{x?.component_name || 'N/A'}</span>
                              }
                            </td>
                            <td>
                              {inputShow1 === x.uuid ?
                                <Input
                                  style={{ width: 'auto' }}
                                  size={'sm'}
                                  autoFocus={true}
                                  placeholder='jio component name'
                                  onChange={(e) => setInputText(e.target.value)}
                                  onBlur={(e) => handleUpdatePackage(e.target.value, x?.jio_stb_component_name, x, 'jio_stb_component_name', x.uuid)}
                                  onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                      handleUpdatePackage(e.target.value, x?.jio_stb_component_name, x, 'jio_stb_component_name', x.uuid)
                                    }
                                  }}
                                  value={inputText} />
                                :
                                <span className='pointer' onClick={() => {
                                  setInputText(x?.jio_stb_component_name)
                                  setInputShow1(x.uuid);
                                }}>{x?.jio_stb_component_name || 'N/A'}</span>
                              }
                            </td>
                            <td>
                              {inputShow2 === x.uuid ?
                                <Input
                                  style={{ width: 'auto' }}
                                  size={'sm'}
                                  autoFocus={true}
                                  placeholder='jio package name'
                                  onChange={(e) => setInputText(e.target.value)}
                                  onBlur={(e) => handleUpdatePackage(e.target.value, x?.jio_stb_package, x, 'jio_stb_package', x.uuid)}
                                  onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                      handleUpdatePackage(e.target.value, x?.jio_stb_package, x, 'jio_stb_package', x.uuid)
                                    }
                                  }}
                                  value={inputText} />
                                :
                                <span className='pointer' onClick={() => {
                                  setInputText(x?.jio_stb_package)
                                  setInputShow2(x.uuid);
                                }}>{x?.jio_stb_package || 'N/A'}</span>
                              }
                            </td>
                            <td>
                              <div className="text-center">

                                <CustomInput
                                  type="switch"
                                  id={`exampleCustomActiveSwitch-${i}`}
                                  name="customSwitch"
                                  className=''
                                  checked={x.watch_on_tv}
                                  onChange={(e) => handleUpdate(e.target.checked, x.watch_on_tv, x, 'watch_on_tv', x.uuid)}
                                />

                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <h2 className="text-center">No Data Available</h2>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {providersList.data &&
                  providersList.data.pagination &&
                  (providersList.data.pagination.is_next_page ||
                    providersList.data.pagination.is_previous_page) && (
                    <CustomPagination
                      pagination={
                        providersList.data &&
                          providersList.data.pagination &&
                          providersList.data.pagination
                          ? providersList.data.pagination
                          : {}
                      }
                      setCurrentPage={(val) => setCurrentPage(val)}
                      setPageLimit={(val) => setPageLimit(val)}
                      defaultPageList={
                        providersList.data && providersList.data.results
                          ? providersList.data.results.length
                          : 20
                      }
                    />
                  )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
}
