import Index from "views/Index.js";
import Analytics from "views/examples/Analytics";
import Register from "views/examples/Register";
import Insights from "components/Media/Insights";
import Curated from "views/examples/Curated";
import Newplaylist from "views/examples/Newplaylist";
import Curatemanually from "views/examples/CurateManually";
import Operation from "views/Operation/Operation";
import Media from "views/examples/Media";
import AddMedia from "views/examples/AddMedia";
import EditMedia from "./views/Media/EditMedia"
import DynamicFeed from "views/examples/DynamicFeed";
import NotificationList from "views/examples/NotificationList";
import UserReview from "views/ReviewManagment/UserReview";
import ReportedReview from "views/ReviewManagment/ReportedReview";
import UploadReview from "views/ReviewManagment/UploadReview";
import ReviewManagment from "views/ReviewManagment/ReviewManagment";
import RequestedReview from "views/ReviewManagment/RequestReview"
import PlatformMangement from "views/subscription/PlatformMangement";
import CouponManagement from "views/subscription/CouponManagement";
import CategoriesManagement from "views/categories/CategoriesManagement";
import AddCategory from "views/categories/AddCategory";
import AdminManagement from "views/admin/AdminManagement";
import BundleManagment from "views/subscription/BundleManagment";
import BulkUploadCoupon from "views/subscription/BulkUploadCoupon";
import FrenziPointsBulkUpload from "views/FrenziPointsManagment/FrenziPointsBulkUpload";
import PointsActivity from "views/FrenziPointsManagment/PointsActivity";
import RateManagement from "views/subscription/RateManagement";
import PlatformPriceManagement from "views/subscription/PlatformPriceManagement";
import Users from "views/UserManagment/Users";
import ReportedUsers from "views/UserManagment/ReportedUsers";
import UsersList from "views/UserManagment/UsersList";
import UserContact from "views/UserManagment/UserContact"
import FrenziPoints from "views/FrenziPointsManagment/FrenziPoints";
import RateManagment from "views/subscription/RateManagment";
import PlatformPriceManagment from "views/subscription/PlatformPriceManagment";
import MediaNews from "views/ManagePage/MediaNews";
import Faq from "views/ManagePage/Faq";
import AddEditNews from "views/ManagePage/AddEditNews";
import InternalAdsSection from "views/AdvertismentManagment/InternalAdsSection";
import AddInternalAds from "views/AdvertismentManagment/AddInternalAds";
import AdvertismentManagment from "views/AdvertismentManagment/AdvertismentManagment";
import Login from "views/auth/Login";
import NewMedia from "views/Media/NewMedia"
import WatchList from "views/watchlist/WatchList"
import WatchListCategory from "views/watchlist/WatchListCategory"
import FlaggedComment from "views/FlaggedComment/FlaggedComment"
import TermCondition from "views/ManagePage/TermCondition"
import PrivacyPolicy from "views/ManagePage/PrivacyPolicy"
import InBetweenFeed from "views/InBetweenFeed/InBetweenFeed"
import Genre from "views/Prefrence/Genre"
import Ott from "views/Prefrence/Ott"
import Language from "views/Prefrence/Language"
import BulkDeepLinkUpload from "views/Media/BulkDeepLinkUpload";
import CastCrew from "views/Media/CastCrew"
import EditCastCrew from "views/Media/EditCastCrew"
import Unauthorized from "views/auth/Unauthorized";
import FrenziKeys from "views/FrenziKeys/FrenziKeys";
import AddPerson from "views/Media/AddPerson";
import DynamicFeedList from "views/DynamicFeed/DynamicFeedList";
import PointsDetails from "views/FrenziPointsManagment/PointsDetails";
import AdvPlatformUsers from "views/AdvertismentManagment/AdvPlatformUsers";
import ChangePassword from "views/admin/ChangePassword";
import GoogleAnalytics from "views/GoogleAnalytics";
import ReportUpload from "views/AdvertismentManagment/ReportUpload";
import Polls from "views/Polls/Polls";
import Quize from "views/Quizes/Quize";
import YoutubeCollection from "views/youtubecollection/YoutubeCollection";
import EditCollection from "views/youtubecollection/EditCollection";
import EditMediaProviders from "views/Media/EditMediaProviders";
import FreeDeeplinkUpload from "views/Media/FreeDeeplinkUpload";
import Discover from "views/Discover/Discover";
import Blogs from "views/Blog/Blogs";
import ToggleAds from "views/AdvertismentManagment/ToggleAds";
import CollegeInteractions from "views/extras/CollegeInteractions";
import WatchOnTv from "views/Media/WatchOnTv";
import NewBulkDeepLinkUpload from "views/Media/NewBulkDeepLinkUpload";
import Webstories from "views/webStories/Webstories";
import AnalyticsData from 'views/Analytics-Data/AnalyticsData'
import CXList from "views/Operation/CXList";
import ExportTrailer from "views/Media/ExportTrailer";


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fas fa-chart-line text-muted",
    component: Index,
    key: "dashboard",
    layout: "/admin"
  },
  {
    path: "parent",
    name: "User Management",
    icon: "ni ni-circle-08 text-muted",
    component: Users,
    key: "user_mgmt",
    layout: "/admin",
    children: [
      {
        path: "/UsersList",
        name: "Users List",
        icon: "ni ni-ui-04 text-muted",
        component: UsersList,
        key: "user_mgmt",
        layout: "/admin",
      },
      {
        path: "/ReportedUsers",
        name: "Reported Users",
        icon: "ni ni-ui-04 text-muted",
        component: ReportedUsers,
        key: "user_mgmt",
        layout: "/admin",
      },
      // {
      //   path: "/UserContact",
      //   name: "User Contact",
      //   icon: "ni ni-ui-04 text-muted",
      //   key: "user_mgmt",
      //   component: UserContact,
      //   layout: "/admin",
      // },

    ]
  },
  {
    path: "parent",
    name: "Media Management",
    icon: "ni ni-tv-2 text-muted",
    component: NewMedia,
    key: "media",
    layout: "/admin",
    children: [
      {
        path: "/media",
        name: "Media List",
        icon: "ni ni-ui-04 text-muted",
        key: "media",
        component: NewMedia,
        layout: "/admin",
      },
      // {
      //   path: "/bulk-data",
      //   name: "Bulk Deeplink Upload",
      //   icon: "ni ni-ui-04 text-muted",
      //   key: "media",
      //   component: BulkDeepLinkUpload,
      //   layout: "/admin",
      // },
      {
        path: "/bulk-upload",
        name: "New Bulk Deeplink Upload",
        icon: "ni ni-ui-04 text-muted",
        key: "media",
        component: NewBulkDeepLinkUpload,
        layout: "/admin",
      },
      {
        path: "/bulk-free-data",
        name: "Free Bulk Upload",
        icon: "ni ni-ui-04 text-muted",
        key: "media",
        component: FreeDeeplinkUpload,
        layout: "/admin",
      },
      {
        path: "/cast_crew",
        name: "Cast & Crew ",
        icon: "ni ni-ui-04 text-muted",
        key: "media",
        component: CastCrew,
        layout: "/admin",
      },
      {
        path: "/watch-on-tv",
        name: "Watch On TV",
        icon: "ni ni-ui-04 text-muted",
        key: "media",
        component: WatchOnTv,
        layout: "/admin",
      },
      {
        path: "/export-trailer",
        name: "Export Trailer",
        icon: "ni ni-ui-04 text-muted",
        key: "media",
        component: ExportTrailer,
        layout: "/admin",
        hidden: true
      },
    ]
  },
  {
    path: "parent",
    name: "Review Management",
    icon: "ni ni-chat-round text-muted",
    component: UserReview,
    key: "review_mgmt",
    layout: "/admin",
    children: [
      {
        path: "/user_review",
        name: "User Review",
        icon: "ni ni-ui-04 text-muted",
        key: "review_mgmt",
        component: UserReview,
        layout: "/admin",
      },
      {
        path: "/reported_review",
        name: "Reported Review",
        icon: "ni ni-settings text-muted",
        key: "review_mgmt",
        component: ReportedReview,
        layout: "/admin",
      },
      {
        path: "/upload_review",
        name: "Upload Review",
        icon: "ni ni-settings text-muted",
        key: "review_mgmt",
        component: UploadReview,
        layout: "/admin",
      },
      {
        path: "/ReviewManagment",
        name: "AI Reported Review",
        icon: "ni ni-settings text-muted",
        key: "review_mgmt",
        component: ReviewManagment,
        layout: "/admin",
      },
      {
        path: "/requested_review",
        name: "Requested Review",
        icon: "ni ni-settings text-muted",
        key: "review_mgmt",
        component: RequestedReview,
        layout: "/admin",
      }
    ]
  },
  {
    path: "/categories",
    name: "Collection Management",
    icon: "fas fa-boxes text-muted",
    component: CategoriesManagement,
    key: 'categories',
    layout: "/admin",
  },
  {
    path: "/ytbcollection",
    name: "Youtube Collection",
    icon: "ni ni-button-play text-muted",
    component: YoutubeCollection,
    key: 'categories',
    layout: "/admin",
  },
  // {
  //   path: "parent",
  //   name: "Adv. Management",
  //   icon: "fas fa-paper-plane text-muted",
  //   component: InternalAdsSection,
  //   key: 'adv_mgmt',
  //   layout: "/admin",
  //   children: [
  //     {
  //       path: "/InternalAdsSection",
  //       name: "Internal Add Section",
  //       icon: "ni ni-ui-04 text-muted",
  //       component: InternalAdsSection,
  //       key: 'adv_mgmt',
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/AdvertismentManagment",
  //       name: "Advertisement Management",
  //       icon: "ni ni-settings text-muted",
  //       component: AdvertismentManagment,
  //       key: 'adv_mgmt',
  //       layout: "/admin",
  //       hidden: true
  //     },
  //   ]
  // },
  {
    path: "/admin-management",
    name: "Admin Access",
    icon: "fas fa-user-plus text-muted",
    component: AdminManagement,
    key: "admin_mgmt",
    layout: "/admin",
  },
  {
    path: "parent",
    name: "Manage Page",
    icon: "fas fa-file-alt text-muted",
    component: MediaNews,
    key: "manage_page",
    layout: "/admin",
    children: [
      {
        path: "/media_news",
        name: "News & Article",
        icon: "ni ni-ui-04 text-muted",
        component: MediaNews,
        key: "manage_page",
        layout: "/admin",
      },
      {
        path: "/terms",
        name: "Term & Condition",
        icon: "ni ni-settings text-muted",
        component: TermCondition,
        key: "manage_page",
        layout: "/admin",
      },
      {
        path: "/faq",
        name: "FAQ",
        icon: "ni ni-settings text-muted",
        component: Faq,
        key: "manage_page",
        layout: "/admin",
      },
      {
        path: "/privacy",
        name: "Privacy Policy",
        icon: "ni ni-settings text-muted",
        component: PrivacyPolicy,
        key: "manage_page",
        layout: "/admin",
      }
    ]
  },
  {
    path: "parent",
    name: "Subscription Management",
    icon: "fas fa-users text-muted",
    component: PlatformMangement,
    key: "sub_mgmt",
    layout: "/admin",
    children: [
      {
        path: "/rate",
        name: "Rate Management",
        icon: "ni ni-ui-04 text-muted",
        component: RateManagement,
        key: "sub_mgmt",
        layout: "/admin",
      },
      {
        path: "/platform",
        name: "Platform Management",
        icon: "ni ni-ui-04 text-muted",
        component: PlatformMangement,
        key: "sub_mgmt",
        layout: "/admin",
      },
      {
        path: "/PlatformPriceManagment",
        name: "Platform Price Management",
        icon: "ni ni-ui-04 text-muted",
        component: PlatformPriceManagment,
        key: "sub_mgmt",
        layout: "/admin",
      },
      {
        path: "/coupon",
        name: "Coupon Management",
        icon: "ni ni-settings text-muted",
        component: PlatformMangement,
        key: "sub_mgmt",
        layout: "/admin",
      },
      {
        path: "/BundleManagment",
        name: 'Bundle Management',
        component: PlatformMangement,
        key: "sub_mgmt",
        layout: "/admin",
      },
      {
        path: "/BulkUploadCoupon",
        name: 'Bulk Upload Coupons',
        component: PlatformMangement,
        key: "sub_mgmt",
        layout: "/admin",
      },
    ]
  },
  {
    path: "parent",
    name: "Frenzi Point",
    icon: "fas fa-dollar-sign text-muted",
    component: FrenziPointsBulkUpload,
    key: "frenzi_mgmt",
    layout: "/admin",
    children: [
      {
        path: "/FrenziPoints",
        name: "Frenzi Points ",
        icon: "ni ni-ui-04 text-muted",
        component: FrenziPoints,
        layout: "/admin",
      },
      {
        path: "/FrenziPointsBulkUpload",
        name: "Frenzi Points Bulk Upload",
        icon: "ni ni-ui-04 text-muted",
        component: FrenziPointsBulkUpload,
        layout: "/admin",
      },
      {
        path: "/PointsActivity",
        name: "Point Activity",
        icon: "ni ni-ui-04 text-muted",
        component: PointsActivity,
        layout: "/admin",
      },
    ]
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: "ni ni-chart-bar-32 text-blue ",
    component: Analytics,
    key: "",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/dynamicfeed",
    name: "Dynamic Feed",
    icon: "ni ni-diamond text-muted",
    component: DynamicFeedList,
    key: "my_frenz",
    layout: "/admin",
  },
  {
    path: "/frenziKeys",
    name: "Frenzi Valid Values",
    icon: "ni ni-settings-gear-65 text-muted",
    key: 'frenzi_valid_values',
    component: FrenziKeys,
    layout: "/admin",
  },
  // {
  //   path: "/notificationlist",
  //   name: "Notification List",
  //   icon: "ni ni-notification-70 text-muted",
  //   component: NotificationList,
  //   key: "msg_notify",
  //   layout: "/admin",
  // },
  {
    path: "/operations",
    name: "Operations",
    icon: "ni ni-settings text-muted",
    key: 'monitering_mgmt',
    component: Operation,
    layout: "/admin",
  },
  {
    path: "/analyticsData",
    name: "Analytics Data Download",
    icon: "ni ni-spaceship text-muted",
    key: 'monitering_mgmt',
    component: AnalyticsData,
    layout: "/admin",
  },
  {
    path: "/WatchList",
    name: "WatchList",
    icon: "fas fa-clipboard-list text-muted",
    component: WatchList,
    key: "watchlist",
    layout: "/admin",
  },
  {
    path: "parent",
    name: "Adv. Management",
    icon: "ni ni-laptop text-muted",
    component: AdvPlatformUsers,
    key: "adv_mgmt",
    layout: "/admin",
    children: [
      {
        path: "/advm",
        name: "Advertisement Management",
        icon: "ni ni-ui-04 text-muted",
        component: AdvPlatformUsers,
        layout: "/admin",
      },
      {
        path: "/report",
        name: "Report Upload",
        icon: "ni ni-ui-04 text-muted",
        component: ReportUpload,
        layout: "/admin",
      },
      {
        path: "/toggle-ads",
        name: "Toggle Ads",
        icon: "ni ni-ui-04 text-muted",
        component: ToggleAds,
        layout: "/admin",
      }
    ]
  },
  {
    path: "parent",
    name: "Polls & Quizes",
    icon: "ni ni-chart-pie-35 text-muted",
    component: Polls,
    key: "poll_quiz",
    layout: "/admin",
    children: [
      {
        path: "/polls",
        name: "Polls",
        icon: "ni ni-ui-04 text-muted",
        component: Polls,
        layout: "/admin",
      },
      {
        path: "/quizes",
        name: "Quizes",
        icon: "ni ni-ui-04 text-muted",
        component: Quize,
        layout: "/admin",
      }
    ]
  },
  {
    path: "/discovery",
    name: "Discovery Module",
    icon: "ni ni-headphones text-muted",
    component: Discover,
    key: "discover",
    layout: "/admin",
  },
  {
    path: "/blogs",
    name: "Blogs",
    icon: "ni ni-collection text-muted",
    component: Blogs,
    key: "blog",
    layout: "/admin",
  },
  {
    path: "/webstories",
    name: "Webstories",
    icon: "ni ni-image text-muted",
    component: Webstories,
    key: "blog",
    layout: "/admin",
  },
  {
    path: "/cx",
    name: "CX Daily Update",
    icon: "ni ni-sound-wave text-muted",
    component: CXList,
    key: "monitering_mgmt",
    layout: "/admin",
  },
  {
    path: "/college",
    name: "College Interactions",
    icon: "ni ni-paper-diploma text-muted",
    component: CollegeInteractions,
    key: "student",
    layout: "/admin",
  },
  {
    path: "/polls",
    name: "Polls & Quizes",
    icon: "ni ni-settings text-muted",
    component: Polls,
    layout: "/admin",
    key: "poll_quiz",
    hidden: true
  },
  {
    path: "/quizes",
    name: "Polls & Quizes",
    icon: "ni ni-settings text-muted",
    component: Quize,
    layout: "/admin",
    key: "poll_quiz",
    hidden: true
  },
  {
    path: "/WatchListCategory",
    name: "WatchListCategory",
    icon: "ni ni-settings text-muted",
    component: WatchListCategory,
    layout: "/admin",
    key: "",
    hidden: true
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-settings text-muted",
    component: ChangePassword,
    key: "public_key",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/advm",
    name: "Advertisement Management",
    icon: "ni ni-settings text-muted",
    component: AdvPlatformUsers,
    layout: "/admin",
    key: "adv_mgmt",
    hidden: true
  },
  {
    path: "/report",
    name: "Report Upload",
    icon: "ni ni-settings text-muted",
    component: ReportUpload,
    layout: "/admin",
    key: "adv_mgmt",
    hidden: true
  },
  {
    path: "/toggle-ads",
    name: "Toggle Ads",
    icon: "ni ni-ui-04 text-muted",
    component: ToggleAds,
    layout: "/admin",
    key: "adv_mgmt",
    hidden: true
  },
  // {
  //   path: "/FlaggedComment",
  //   name: "Flagged Comment",
  //   icon: "fas fa-flag text-muted",
  //   component: FlaggedComment,
  //   key: "",
  //   layout: "/admin",
  // },
  // {
  //   path: "/InBetweenFeed",
  //   name: "In Between Feed",
  //   icon: "fas fa-box text-muted",
  //   component: InBetweenFeed,
  //   key: "",
  //   layout: "/admin",
  // },
  // {
  //   path: "parent",
  //   name: "Prefrence",
  //   icon: "fas fa-asterisk text-muted",
  //   component: Users,
  //   layout: "/admin",
  //   key: "",
  //   children: [
  //     {
  //       path: "/Genre",
  //       name: "Genre",
  //       icon: "ni ni-settings text-muted",
  //       component: Genre,
  //       key: "",
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/Ott",
  //       name: "Ott",
  //       icon: "ni ni-settings text-muted",
  //       component: Ott,
  //       key: "",
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/Language",
  //       name: "Language",
  //       key: "",
  //       icon: "ni ni-settings text-muted",
  //       component: Language,
  //       layout: "/admin",
  //     },

  //   ]
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-circle-08 text-muted",
    component: Login,
    key: "",
    layout: "/auth",
    hidden: true
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    icon: "ni ni-circle-08 text-muted",
    component: Unauthorized,
    key: "",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/media",
    name: "Media List",
    icon: "ni ni-ui-04 text-muted",
    component: NewMedia,
    key: "media",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/bulk-data",
    name: "Bulk Data Upload",
    icon: "ni ni-ui-04 text-muted",
    component: BulkDeepLinkUpload,
    key: "media",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/bulk-upload",
    name: "New Bulk Data Upload",
    icon: "ni ni-ui-04 text-muted",
    component: NewBulkDeepLinkUpload,
    key: "media",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/bulk-free-data",
    name: "Free Bulk Upload",
    icon: "ni ni-ui-04 text-muted",
    component: FreeDeeplinkUpload,
    key: "media",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/cast_crew",
    name: "Cast & Crew",
    icon: "ni ni-ui-04 text-muted",
    component: CastCrew,
    key: "media",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/watch-on-tv",
    name: "Watch On TV",
    icon: "ni ni-ui-04 text-muted",
    key: "media",
    component: WatchOnTv,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/export-trailer",
    name: "Export Trailer",
    icon: "ni ni-ui-04 text-muted",
    key: "media",
    component: ExportTrailer,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/curatedforyou",
    component: Curated,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/newplaylist",
    component: Newplaylist,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/curatemanually",
    component: Curatemanually,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/addmedia",
    component: AddMedia,
    layout: "/admin",
    key: "media",
    hidden: true
  },
  {
    path: "/editmedia/:id",
    component: EditMedia,
    layout: "/admin",
    key: "media",
    hidden: true
  },
  {
    path: "/editmedia-providers/:id",
    component: EditMediaProviders,
    layout: "/admin",
    key: "media",
    hidden: true
  },
  {
    path: "/editperson/:pk",
    component: EditCastCrew,
    layout: "/admin",
    key: "media",
    hidden: true
  },
  {
    path: "/addperson",
    component: AddPerson,
    layout: "/admin",
    key: "media",
    hidden: true
  },
  {
    path: "/RateManagment",
    name: 'Subscription Management',
    component: RateManagment,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/platform",
    name: 'Subscription Management',
    component: PlatformMangement,
    key: "sub_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/PlatformPriceManagment",
    name: 'Subscription Management',
    component: PlatformPriceManagment,
    key: "sub_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/coupon",
    name: 'Subscription Management',
    component: CouponManagement,
    key: "sub_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/BundleManagment",
    name: 'Subscription Management',
    component: BundleManagment,
    key: "sub_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/BulkUploadCoupon",
    name: 'Subscription Management',
    component: BulkUploadCoupon,
    key: "sub_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/FrenziPoints",
    name: 'Frenzi Point',
    component: FrenziPoints,
    key: "frenzi_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/points-details/:id",
    name: 'Frenzi Point Details',
    component: PointsDetails,
    key: "frenzi_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/FrenziPointsBulkUpload",
    name: 'Frenzi Point',
    component: FrenziPointsBulkUpload,
    key: "frenzi_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/PointsActivity",
    name: 'Frenzi Point',
    component: PointsActivity,
    key: "frenzi_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/Users",
    name: 'User Managment',
    component: Users,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/ReportedUsers",
    name: 'User Managment',
    component: ReportedUsers,
    key: "user_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/UserContact",
    name: 'User Managment',
    component: UserContact,
    key: "user_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/UsersList",
    name: 'Reported Users',
    component: UsersList,
    key: "user_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/add-category",
    name: 'Category',
    component: AddCategory,
    layout: "/admin",
    key: 'categories',
    hidden: true
  },
  {
    path: "/edit-category/:id",
    name: 'Category',
    component: AddCategory,
    layout: "/admin",
    key: 'categories',
    hidden: true
  },
  {
    path: "/edit-collection/:id",
    name: 'Category',
    component: EditCollection,
    layout: "/admin",
    key: 'categories',
    hidden: true
  },
  {
    path: "/user_review",
    name: "Review Management",
    icon: "ni ni-ui-04 text-muted",
    component: UserReview,
    key: "review_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/reported_review",
    name: "Review Management",
    icon: "ni ni-settings text-muted",
    component: ReportedReview,
    layout: "/admin",
    key: 'review_mgmt',
    hidden: true
  },
  {
    path: "/upload_review",
    name: "Review Management",
    icon: "ni ni-settings text-muted",
    component: UploadReview,
    key: "review_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/ReviewManagment",
    name: "Review Management",
    icon: "ni ni-settings text-muted",
    component: ReviewManagment,
    key: "review_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/requested_review",
    name: "Requested Review",
    icon: "ni ni-settings text-muted",
    key: "review_mgmt",
    component: RequestedReview,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/rate",
    name: "Subscription Management",
    icon: "ni ni-ui-04 text-muted",
    component: RateManagement,
    key: "sub_mgmt",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/platform-price",
    name: "Subscription Management",
    icon: "ni ni-settings text-muted",
    component: PlatformPriceManagement,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/media_news",
    name: "Manage Page",
    icon: "ni ni-ui-04 text-muted",
    component: MediaNews,
    key: "manage_page",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/terms",
    name: "Term & Condition",
    icon: "ni ni-settings text-muted",
    component: TermCondition,
    key: "manage_page",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "ni ni-settings text-muted",
    component: Faq,
    key: "manage_page",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    icon: "ni ni-settings text-muted",
    component: PrivacyPolicy,
    key: "manage_page",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/media_news_add",
    name: "Manage Page",
    icon: "ni ni-ui-04 text-muted",
    component: AddEditNews,
    key: "manage_page",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/media_news_edit/:id",
    name: "Manage Page",
    icon: "ni ni-settings text-muted",
    component: AddEditNews,
    key: "manage_page",
    layout: "/admin",
    hidden: true
  },
  // {
  //   path: "/InternalAdsSection",
  //   name: "Advertisement Management",
  //   icon: "ni ni-settings text-muted",
  //   component: InternalAdsSection,
  //   key: 'adv_mgmt',
  //   layout: "/admin",
  //   hidden: true
  // },
  {
    path: "/AddInternalAds",
    name: "Advertisement Management",
    icon: "ni ni-settings text-muted",
    component: AddInternalAds,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/AdvertismentManagment",
    name: "Advertisement Management",
    icon: "ni ni-settings text-muted",
    component: AdvertismentManagment,
    key: 'adv_mgmt',
    layout: "/admin",
    hidden: true
  },
  {
    path: "/Genre",
    name: "Prefrence",
    icon: "ni ni-settings text-muted",
    component: Genre,
    key: "",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/Ott",
    name: "Prefrence",
    icon: "ni ni-settings text-muted",
    component: Ott,
    key: "",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/Language",
    name: "Prefrence",
    icon: "ni ni-settings text-muted",
    component: Language,
    key: "",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-settings text-muted",
    component: ChangePassword,
    key: "public_key",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/ga",
    name: "Google Analytics",
    icon: "ni ni-settings text-muted",
    component: GoogleAnalytics,
    key: "public_key",
    layout: "/admin",
    hidden: true
  },

];
export default routes;
