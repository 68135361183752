import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.BUNDLE_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.BUNDLE_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getBundleList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.BUNDLE_GET_REQUEST });

        axiosInstance
            .get(`subscription/bundlelist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BUNDLE_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BUNDLE_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const addBundle = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.BUNDLE_ADD_REQUEST });

        axiosInstance
            .post(`subscription/bundlelist`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.BUNDLE_ADD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BUNDLE_ADD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deleteBundle = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.BUNDLE_DELETE_REQUEST });

        axiosInstance
            .delete(`subscription/bundledetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.BUNDLE_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BUNDLE_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}