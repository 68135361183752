import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  CustomInput,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getWebstoryReelsData } from "redux/actions/blogs.action";
import { getWebstoryDetails } from "redux/actions/blogs.action";
import { updateWebstoryData } from "redux/actions/blogs.action";

import AWS from 'aws-sdk'
import { createWebstoryReelsData } from "redux/actions/blogs.action";
import { deleteWebstoryReelsData } from "redux/actions/blogs.action";
import { updateWebstoryReelsData } from "redux/actions/blogs.action";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

export default function EditWebStories(props) {
  const [webstoriesFormData, setwebstoriesFormData] = useState({
    title: "",
    description: "",
  });
  const [isBannerImage, setIsBannerImage] = React.useState(false);

  const [reelFormData, setReelFormData] = useState([]);

  const dispatch = useDispatch();

  const webstoryDetails = useSelector((state) => {
    return state.blogs && state.blogs.webstoryDetails
      ? state.blogs.webstoryDetails
      : {};
  });

  const webstoryReelsDetails = useSelector((state) => {
    return state.blogs && state.blogs.webstoryReelsList
      ? state.blogs.webstoryReelsList
      : {};
  });

  const handleWebstoryFormChange = (val, name) => {
    setwebstoriesFormData({ ...webstoriesFormData, [name]: val });
  };

  useEffect(() => {
    dispatch(getWebstoryDetails(props.editId));
    dispatch(getWebstoryReelsData(props.editId));
  }, []);

  useEffect(() => {
    if (webstoryDetails?.data?.title) {
      setwebstoriesFormData({ ...webstoryDetails?.data });
    }
  }, [webstoryDetails]);

  useEffect(() => {
    if (webstoryReelsDetails?.data &&
      webstoryReelsDetails?.data?.results &&
      webstoryReelsDetails?.data?.results.length > 0) {
      const tempData = [...webstoryReelsDetails?.data?.results];
      setReelFormData([...tempData]);
    } else {
      setReelFormData([{
        title: "",
        description: "",
        redirection_url: "",
        s3_image_url: "",
      }])
    }
  }, [webstoryReelsDetails]);

  useEffect(() => {
    if (webstoriesFormData?.title) {
      updateWebstory();
    }
  }, [webstoriesFormData]);

  const updateWebstory = (goBack = false) => {
    if (webstoriesFormData?.title) {
      const body = {
        title: webstoriesFormData.title,
        description: webstoriesFormData?.description || null,
      };
      dispatch(updateWebstoryData(props.editId, body, goBack));
      if (goBack) {
        reelFormData.map(x => {
          if (x.title) {
            if (!x?.uuid || x.uuid == "") {
              saveReels(x);
            }
          }
        })

        setwebstoriesFormData({
          title: "",
          description: "",
        });
        props.handleDone();
      }
    } else {
      alert("Title can't be empty");
    }
  };

  const handleReelFormChange = (val, name, i) => {
    const tempData = [...reelFormData];
    tempData[i] = {
      ...tempData[i],
      [name]: val
    };
    setReelFormData([...tempData]);
  };

  useEffect(() => {
    if (reelFormData.length > 0) {
      handleUpdateCreateReels(reelFormData);
    }
  }, [reelFormData]);

  const handleUpdateCreateReels = (data) => {
    data.map(x => {
      if (x.title) {
        if (x?.uuid && x.uuid != "") {
          updateReel(x);
        }
      }
    })
  }

  const updateReel = (data) => {
    if (data.title || data?.s3_image_url) {
      dispatch(
        updateWebstoryReelsData(data?.uuid, {
          title: data?.title,
          description: data?.description || null,
          s3_image_url: data?.s3_image_url,
          redirection_url: data?.redirection_url || null,
          web_story: props.editId,
        })
      );
    }
  }

  const saveReels = (data) => {
    if (data.title || data?.s3_image_url) {
      dispatch(
        createWebstoryReelsData({
          title: data?.title,
          description: data?.description || null,
          s3_image_url: data?.s3_image_url,
          redirection_url: data?.redirection_url || null,
          web_story: props.editId,
        })
      );
    }
  };


  const handleUploadBanner = (event, i) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }

    let s3_path = `Stories/banner-`

    var timeStamp = new Date().getTime();
    var randomNo = parseInt(Math.random() * 100000);
    var keyName = s3_path + timeStamp + "-" + randomNo + "-" + file.name;

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME },
      region: process.env.REACT_APP_AWS_REGION,
    })
    const params = {
      Key: keyName,
      Body: file,
    }
    s3.upload(params, async function (err, data) {
      if (data && data.Location) {
        handleReelFormChange(data.Location, 's3_image_url', i);
      }
      if (err) {
        return err;
      }
    });
  }

  const deleteReelRow = (uuid, index) => {
    if (window.confirm("Are you sure want to delete this reel?")) {
      const tempData = reelFormData;
      tempData.splice(index, 1);
      setReelFormData([...tempData]);
      dispatch(deleteWebstoryReelsData(uuid));
    }
  };

  const addReel = () => {
    const tempData = reelFormData;
    tempData.push({
      title: "",
      description: "",
      redirection_url: "",
      s3_image_url: "",
    });
    setReelFormData([...tempData]);
  };

  return (
    <>
      <div>
        <h2>Edit Webstory - <span className="text-danger">{webstoriesFormData.title}</span></h2>
        <Row>
          <Col xs={6}>
            <Label size="sm" className="m-0">
              Title
            </Label>
            <Input
              id="title"
              size={"sm"}
              placeholder="Title"
              onChange={(e) =>
                handleWebstoryFormChange(e.target.value, "title")
              }
              value={webstoriesFormData.title}
            />
          </Col>
          <Col xs={6}>
            <Label size="sm" className="m-0">
              Description
            </Label>
            <Input
              id="description"
              size={"sm"}
              placeholder="Description"
              onChange={(e) =>
                handleWebstoryFormChange(e.target.value, "description")
              }
              value={webstoriesFormData.description}
            />
          </Col>

          <Col xs={12} className="mt-3">
            <hr />
            <h3>Update Reels</h3>
            {reelFormData.length > 0 &&
              reelFormData.map((x, i) => {
                return (
                  <div
                    style={{
                      border: "1px solid grey",
                      padding: "10px",
                      marginTop: "10px",
                      position: "relative",
                    }}
                    key={i}
                  >
                    <Button
                      className=" btn-icon btn-simple"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={() => {
                        deleteReelRow(x?.uuid, i);
                      }}
                      style={{
                        position: "absolute",
                        right: "3px",
                        top: 0,
                        zIndex: 100,
                      }}
                    >
                      <i className="fas fa-trash pt-1"></i>
                    </Button>
                    <Row>
                      <Col xs={6}>
                        <Label size="sm" className="m-0">
                          Reel Title
                        </Label>
                        <Input
                          id="title"
                          size={"sm"}
                          placeholder="Reel Title"
                          onChange={(e) =>
                            handleReelFormChange(
                              e.target.value,
                              "title",
                              i
                            )
                          }
                          value={x?.title || ''}
                        />
                      </Col>
                      <Col xs={6}>
                        <Label size="sm" className="m-0">
                          Reel Description
                        </Label>
                        <Input
                          id="description"
                          size={"sm"}
                          placeholder="Reel Description"
                          onChange={(e) =>
                            handleReelFormChange(
                              e.target.value,
                              "description",
                              i
                            )
                          }
                          value={x?.description || ''}
                        />
                      </Col>
                      <Col xs={6}>
                        <Label size="sm" className="m-0">
                          Redirect URL
                        </Label>
                        <Input
                          id="redirection_url"
                          size={"sm"}
                          placeholder="Redirect URL"
                          onChange={(e) =>
                            handleReelFormChange(
                              e.target.value,
                              "redirection_url",
                              i
                            )
                          }
                          value={x.redirection_url || ''}
                        />
                      </Col>
                      <Col xs={6}>
                        <Label size='sm' className='m-0' >
                          <Row style={{ alignItems: 'center' }} className='m-0'>
                            <span>Banner {isBannerImage ? 'Image' : 'Url'}</span>
                            <CustomInput
                              type="switch"
                              id={`exadmpleCustomFeaturedSwitchCreate`}
                              name="customSswitch"
                              className='ml-3'
                              checked={isBannerImage}
                              onChange={(e) => setIsBannerImage(e.target.checked)}
                            />
                          </Row>
                        </Label>
                        {isBannerImage ?
                          <Row className='m-0' style={{ alignItems: 'center' }}>
                            <img style={{ width: 200, height: 100, marginRight: 20 }} src={x.s3_image_url} alt='' />
                            <Input
                              style={{ width: 'auto' }}
                              id="title"
                              size={'sm'}
                              type='file'
                              placeholder='Title'
                              onChange={(e) => handleUploadBanner(e, i)}
                            />
                          </Row>
                          :
                          <Row className='m-0' style={{ alignItems: 'center' }}>
                            <Input
                              id="imgurl"
                              size={'sm'}
                              placeholder='banner url'
                              onChange={(e) => handleReelFormChange(e.target.value, 's3_image_url', i)}
                              value={x.s3_image_url}
                            />
                          </Row>
                        }
                      </Col>
                    </Row>
                  </div>
                );
              })
            }
          </Col>
          <Col xs={12} className="mt-3">
            <Row className="justify-content-end m-0">
              <Button onClick={() => addReel()} color="primary">
                Add reel
              </Button>
            </Row>
          </Col>

          <Col xs={12} className="mt-3">
            <Row className="justify-content-center m-0">
              <Button onClick={() => updateWebstory(true)} color="success">
                Done
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}