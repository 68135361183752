import React from 'react'
import Header from "components/Headers/Header.js";
import { Container, Card, Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Input, Spinner, Pagination, PaginationItem, PaginationLink, CustomInput } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import { userSearch } from "../../redux/actions/search.action"
import { activateDeactivateUsers, clearMessage, reportUser } from "../../redux/actions/user.action"
import times from "lodash/times";
import CustomPagination from 'components/Pagination/CustomPagination';
import { ToastContainer, toast } from 'react-toastify';
import UserProfile from './UserProfile';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

const list = [1, 2, 3, 4, 5, 6];
const style1 = { width: "80px", height: "70px" };

export default function UsersList() {
    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = React.useState(false)
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [checked, setChecked] = React.useState(true);
    const [isShowProfile, setIsShowProfile] = React.useState(false);
    const [profileId, setProfileId] = React.useState('');
    const [searchString, setSearchString] = React.useState('');
    const [sortType, setSortType] = React.useState('');
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const userSearchResult = useSelector((state) => state.commonSearch && state.commonSearch.userSearchResult ? state.commonSearch.userSearchResult : {})
    const isLoading = useSelector((state) => state.commonSearch && state.commonSearch.isLoading ? state.commonSearch.isLoading : false)
    const errMesg = useSelector((state) => state.user && state.user.errMesg ? state.user.errMesg : '')
    const status = useSelector((state) => state.user && state.user.status ? state.user.status : '')

    React.useEffect(() => {
        dispatch(userSearch(searchString, sortType, currentPage, pageLimit));
    }, [currentPage, pageLimit, sortType])

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const handleSearch = () => {
        dispatch(userSearch(searchString, sortType, 1, pageLimit));
        setCurrentPage(1);
    }

    const handleActiveInactive = (id, val) => {
        var body = {
            is_active: val
        }
        dispatch(activateDeactivateUsers(id, body));
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            dispatch(userSearch(searchString, sortType, currentPage, pageLimit));
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const handleReport = (id) => {
        var message = window.prompt("Please enter the reason");
        if (message && message != "") {
            var body = {
                target_user: id,
                is_reported: true,
                report_reason: message
            }
            ReactGA.event({
                category: 'User',
                action: 'User reported',
                value: id,
                label: id
            });
            dispatch(reportUser(body));
        }
    }

    return (
        <>
            <Header />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLoading && <Loader />}
            <Container>
                {isShowProfile && profileId != "" ?
                    <Card className='mt-4 p-4 rounded-lg'>
                        <Col sm={4} className="p-0 pl-3 search-icon-box">
                            <Row>
                                <Button
                                    className="btn-icon btn-simple text-center"
                                    color="dark"
                                    size="sm"
                                    type="button"
                                    style={{ minWidth: 40 }}
                                    onClick={() => {
                                        setProfileId('');
                                        setIsShowProfile(false);
                                    }}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                                <Row className='h2100 justify-content-center p-0 m-0'>
                                    <h3 className='p-0 m-0'>User Detail</h3>
                                </Row>
                            </Row>
                        </Col>
                        <UserProfile id={profileId} />
                    </Card>
                    :
                    <Card className='mt-4 p-4 rounded-lg'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <h3 className='mb-3'>Users List</h3>
                        </Col>
                        <Row className='justify-content-between m-0 '>
                            <Col sm={6} className="p-0 search-icon-box">
                                <Row className='justify-content-between m-0 '>
                                    <Col className="p-0 search-icon-box">
                                        <Input onChange={(e) => setSearchString(e.target.value)} placeholder='Search' onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                                        <i className="fas fa-search" onClick={() => handleSearch()} ></i>
                                    </Col>
                                    <div style={{ marginLeft: 20 }}>
                                        {/* <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                Sort By
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                <DropdownItem>Frenzi Points</DropdownItem>
                                                <DropdownItem>Deactivate Users</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> */}
                                        {(sortType != "" || searchString != "") &&
                                            <Button onClick={() => {
                                                setCurrentPage(1);
                                                setSortType('');
                                                setSearchString('');
                                                handleSearch();
                                            }} color='primary'>Reset</Button>
                                        }
                                    </div>
                                </Row>
                            </Col>
                            <Button color="info" onClick={() => setIsAddPlatform(!isAddPlatform)} >Download CSV</Button>

                        </Row>
                        <Container className=' table mt-4' style={{ overflowY: 'hidden' }}>
                            <Table hover className="align-items-center table-flush" >
                                <thead >
                                    <tr>
                                        <th className='table_heading' width="7%">
                                            S.No.
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "username" ? "-username" : 'username');
                                        }} className='table_heading pointer'>
                                            Name
                                            {sortType == "username" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-username" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "email" ? "-email" : 'email');
                                        }} className='table_heading pointer'>
                                            Email ID
                                            {sortType == "email" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-email" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th className='table_heading'>
                                            Unique Name
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "frenzi_points" ? "-frenzi_points" : 'frenzi_points');
                                        }} style={{ minWidth: 110 }} className='table_heading pointer'>
                                            Points
                                            {sortType == "frenzi_points" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-frenzi_points" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th className='table_heading'>
                                            Profile
                                        </th>
                                        {/* <th className='table_heading'>
                                        Comments
                                    </th>
                                    <th className='table_heading'>
                                        Rewards
                                    </th>
                                    <th className='table_heading'>
                                        Activity
                                    </th> */}
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "is_active" ? "-is_active" : 'is_active');
                                        }} style={{ minWidth: 110 }} className='text-left table_heading pointer'>
                                            Status
                                            {sortType == "is_active" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-is_active" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th className='table_heading'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading === true && (!userSearchResult || !userSearchResult.data) ?
                                        <tr>
                                            <td colSpan={9} className='text-center'>
                                                <Spinner color="info" />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {userSearchResult && userSearchResult.data && userSearchResult.data.results && userSearchResult.data.results.length > 0 ? userSearchResult.data.results.map((x, i) => {
                                                return <tr key={i}>
                                                    <td scope="row">
                                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.username}
                                                    </td>
                                                    <td>
                                                        {x.email}
                                                    </td>
                                                    <td>
                                                        {x.unique_name}
                                                    </td>
                                                    <td className='text-center'>
                                                        {x.frenzi_points}
                                                    </td>
                                                    <td className='text-center'>
                                                        {/* <img
                                                    alt="..."
                                                    className=" img-fluid rounded shadow-lg"
                                                    src={x.profile_image ? x.profile_image : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgSmojUgwjIB87c4Q0hLCAyl__oiTySWGWJUZtUNHlHjBALLzTsu_vMHYMaEwLts4QEoo&usqp=CAU"}
                                                    style={style1}
                                                ></img> */}
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="success"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => {
                                                                ReactGA.event({
                                                                    category: 'User',
                                                                    action: 'Viewed an user details',
                                                                    value: x.uuid,
                                                                    label: x.uuid
                                                                });
                                                                setProfileId(x.uuid);
                                                                setIsShowProfile(true);
                                                            }}
                                                        >
                                                            <i className="fas fa-eye pt-1"></i>
                                                        </Button>
                                                    </td>
                                                    {/* <td className='text-center'>
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                    >
                                                        <i className="fas fa-eye pt-1"></i>
                                                    </Button>
                                                </td>
                                                <td className='text-center'>
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="warning"
                                                        size="sm"
                                                        type="button"
                                                    >
                                                        <i className="fas fa-eye pt-1"></i>
                                                    </Button>
                                                </td>
                                                <td className='text-center'>
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="info"
                                                        size="sm"
                                                        type="button"
                                                    >
                                                        <i className="fas fa-eye pt-1"></i>
                                                    </Button>
                                                </td> */}
                                                    <td className='text-center pointer'>
                                                        {/* <CustomInput
                                                        type="switch"
                                                        id={`exampleCustomSwitch-${i}`}
                                                        name="customSwitch"
                                                        className='p-0 pointer ml-4 pl-5'
                                                        style={{ cursor: 'pointer' }}
                                                        checked={x.is_active}
                                                        // label={x.is_active ? "Active" : "In-Active"}
                                                        onChange={(e) => handleActiveInactive(x.uuid, e.target.checked)}
                                                    /> */}
                                                        {x.is_active ?
                                                            <p className='text-success p-0 m-0'>Active</p>
                                                            :
                                                            <p className='text-danger p-0 m-0'>In-Active</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        <Row>
                                                            {/* <Button
                                                            className=" btn-icon btn-simple"
                                                            color="info"
                                                            size="sm"
                                                            type="button"
                                                        >
                                                            <i className="fas fa-pen pt-1"></i>
                                                        </Button> */}
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="danger"
                                                                size="sm"
                                                                type="button"
                                                                onClick={() => handleReport(x.uuid)}
                                                            >
                                                                Report
                                                                {/* <i className="fas fa-trash pt-1"></i> */}
                                                            </Button>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            })
                                                :
                                                <tr>
                                                    <td colSpan={9} className='text-center'>
                                                        <h3>
                                                            No Data Available
                                                        </h3>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    }
                                </tbody>
                            </Table>
                            <div className='text-right mt-2'>
                                {userSearchResult.data && userSearchResult.data.pagination &&
                                    <CustomPagination
                                        pagination={userSearchResult.data && userSearchResult.data.pagination && userSearchResult.data.pagination ? userSearchResult.data.pagination : {}}
                                        setCurrentPage={(val) => setCurrentPage(val)}
                                        setPageLimit={(val) => setPageLimit(val)}
                                        defaultPageList={userSearchResult.data && userSearchResult.data.results ? userSearchResult.data.results.length : 20}
                                    />
                                }
                            </div>
                        </Container>
                    </Card>
                }
            </Container>
        </>
    )
}
