import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table } from 'reactstrap'
import { getBlogData } from 'redux/actions/blogs.action';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { deleteBlogData } from 'redux/actions/blogs.action';
import { updateBlogData } from 'redux/actions/blogs.action';
import CustomPagination from 'components/Pagination/CustomPagination';
import AWS from 'aws-sdk'

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

export const getSelectOptions = (arr) => {
    var temp = [];
    arr.length > 0 && arr.map((x, i) => {
        temp.push({
            label: x.sub_category || x.category || x.label,
            value: x.uuid || x.value,
        });
    })
    return temp;
}

export default function BlogContent(props) {
    const { activeTab } = props;
    const animatedComponents = makeAnimated();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [toggleIdFilter, setToggleIdFilter] = useState(false);
    const [activeSubCategory, setActiveSubCategory] = React.useState('');
    const [activeSubCategoryName, setActiveSubCategoryName] = React.useState('');
    const dispatch = useDispatch();

    const categoryList = useSelector((state) => state.blogs && state.blogs.categoryList ? state.blogs.categoryList : {})

    const subCategoryList = useSelector((state) => state.blogs && state.blogs.subCategoryList ? state.blogs.subCategoryList : {})

    const blogList = useSelector((state) => state.blogs && state.blogs.blogList ? state.blogs.blogList : {})

    const isLoading = useSelector((state) => state.blogs && state.blogs.isLoading ? state.blogs.isLoading : false)
    const status = useSelector((state) => state.blogs && state.blogs.status ? state.blogs.status : '')
    const errMesg = useSelector((state) => state.blogs && state.blogs.errMesg ? state.blogs.errMesg : '')

    useEffect(() => {
        if (status && status != "") {
            dispatch(getBlogData(searchText, currentPage, pageLimit));
        }
    }, [errMesg, status])

    useEffect(() => {
        dispatch(getBlogData(searchText, currentPage, pageLimit));
    }, [currentPage])

    useEffect(() => {
        setCurrentPage(1);
    }, [pageLimit])

    const handleUpdate = (val, prevVal, data, name, id) => {
        let newData = { ...data };
        if (!prevVal || val != prevVal) {
            if (name != 'sub_categories') {
                delete newData.sub_categories;
            }
            const body = { ...newData, [name]: val }
            dispatch(updateBlogData(id, body));
        }
    }

    const handleDeleteKey = (bdata) => {
        const bucketName = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        const s3 = new AWS.S3({
            params: { Bucket: bucketName },
            region: process.env.REACT_APP_AWS_REGION,
        })
        var params = {
            Prefix: `Blogs/${bdata?.s3_path}`,
            Bucket: bucketName,
        }

        s3.listObjects(params, function (err, data) {
            if (err) {
                console.log(err);
                return err;
            }
            if (data.Contents.length == 0) {
                dispatch(deleteBlogData(bdata?.uuid));
            }
            params = { Bucket: bucketName };
            params.Delete = { Objects: [] };
            data.Contents.forEach(function (content) {
                params.Delete.Objects.push({ Key: content.Key });
            });

            s3.deleteObjects(params, function (err, data) {
                if (err) {
                    console.log(err);
                    return err;
                }
                if (data) {
                    dispatch(deleteBlogData(bdata?.uuid));
                }
            });
        });
    }

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <Row className='m-0'>
                        <Col>
                            <Button onClick={() => props.handleCreate()} color='success'>Create New Blog</Button>
                        </Col>
                    </Row>
                    <Table size='sm' className="align-items-center mt-3 table-flush " >
                        <thead >
                            <tr>
                                <th className='table_heading'>Sr No.</th>
                                <th className='table_heading'>Title</th>
                                <th className='table_heading'>Author</th>
                                {/* <th className='table_heading'>Banner</th> */}
                                <th style={{ minWidth: 200 }} className='table_heading'>Sub-Category</th>
                                <th className='table_heading'>Date</th>
                                <th className='table_heading'>Is Active</th>
                                <th className='table_heading'>Is Featured</th>
                                <th className='table_heading'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogList.data?.results?.length > 0 &&
                                blogList.data?.results.map((x, i) => {
                                    return <tr>
                                        <th scope="row">{(pageLimit * (currentPage - 1)) + (i + 1)}</th>
                                        <td>{x?.title}</td>
                                        <td>{x?.author__username}</td>
                                        {/* <td><img style={{ height: 30, width: 40 }} src={x?.banner} /></td> */}
                                        <td>
                                            <Select
                                                key={i}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                placeholder={"Sub-Categories"}
                                                components={components}
                                                options={
                                                    getSelectOptions(subCategoryList?.data || [])
                                                }
                                                value={
                                                    getSelectOptions(x?.sub_categories || [])
                                                }
                                                onChange={(e) => handleUpdate(e.map(opt => opt.value), x?.sub_categories.map(cat => cat.uuid), x, 'sub_categories', x.uuid)}
                                            />
                                        </td>
                                        <td>{x?.date_published}</td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomActiveSwitch-${i}`}
                                                    name="customSwitch"
                                                    className=''
                                                    checked={x.is_active}
                                                    onChange={(e) => handleUpdate(e.target.checked, x.is_active, x, 'is_active', x.uuid)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomFeaturedSwitch-${i}`}
                                                    name="customSwitch"
                                                    className=''
                                                    checked={x.is_featured}
                                                    onChange={(e) => handleUpdate(e.target.checked, x.is_featured, x, 'is_featured', x.uuid)}
                                                />
                                            </div>
                                        </td>

                                        <td style={{ minWidth: '160px' }} className="td-actions text-left">
                                            <Row>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => props.handleEdit(x?.title, x.uuid)}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="primary"
                                                    size="sm"
                                                    type="button"
                                                // onClick={() => }
                                                >
                                                    <i className="fas fa-eye pt-1"></i>
                                                </Button>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        if (window.confirm("Do you really want to delete this blog ?")) {
                                                            handleDeleteKey(x);
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </Row>
                                        </td>

                                    </tr>
                                })
                            }
                            {blogList.data?.results?.length == 0 &&
                                <tr><td colSpan={4} className="text-center">No Data Found</td></tr>
                            }
                        </tbody>
                    </Table>
                    <CustomPagination
                        pagination={blogList.data && blogList.data.pagination && blogList.data.pagination ? blogList.data.pagination : {}}
                        setCurrentPage={(val) => setCurrentPage(val)}
                        setPageLimit={(val) => setPageLimit(val)}
                        defaultPageList={blogList.data && blogList.data.results ? blogList.data.results.length : 10}
                    />
                </Col>
            </Row>
        </div>
    )
}
