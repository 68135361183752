import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../common/Loader'
import './_subscriptions.scss';
import CustomPagination from 'components/Pagination/CustomPagination';
import { getCouponList, deleteCoupon, updateCoupon, addCoupon, clearMessage } from '../../redux/actions/coupon.action';
import { getPlanTypes, getPlatformPriceDropDown } from '../../redux/actions/platform.action';
import CustomMyInput from 'views/common/CustomMyInput';
import ReactGA from 'react-ga';

export default function CouponManagement() {
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpen2, setIsOpen2] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);

    const [editId, setEditId] = React.useState('');
    const [isEdit, setIsEdit] = React.useState(false);

    // selected values
    const [isUsed, setIsUsed] = React.useState(false);
    const [couponCode, setCouponCode] = React.useState('');
    const [planType, setPlanType] = React.useState('');
    const [platformPrice, setPlatformPrice] = React.useState('');
    const [platformName, setPlatformName] = React.useState('');
    const [expiryDate, setExpiryDate] = React.useState('');

    const dispatch = useDispatch();

    const couponList = useSelector((state) => state.coupon && state.coupon.couponListResult ? state.coupon.couponListResult : {})

    const platformPlanType = useSelector((state) => state.platform && state.platform.platformPlanTypeListResult ? state.platform.platformPlanTypeListResult : {})
    const platformPriceDropdownList = useSelector((state) => state.platform && state.platform.platformPriceDropdownListResult ? state.platform.platformPriceDropdownListResult : {})


    const message = useSelector((state) => state.coupon && state.coupon.message ? state.coupon.message : false)
    const errMesg = useSelector((state) => state.coupon && state.coupon.errMesg ? state.coupon.errMesg : false)
    const apiStatus = useSelector((state) => state.coupon && state.coupon.status ? state.coupon.status : false)
    const isLoading = useSelector((state) => state.coupon && state.coupon.isLoading ? state.coupon.isLoading : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        dispatch(getCouponList(searchText, currentPage, pageLimit));
    }, [currentPage, pageLimit, message])

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(getCouponList(searchText, 1, pageLimit));
    }

    React.useEffect(() => {
        dispatch(getPlanTypes());
    }, [])

    React.useEffect(() => {
        if (message && message != "") {
            console.log(apiStatus)
            if (apiStatus) {
                toast.success(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    const getMessage = (value) => {
        return value.split('=>')[1];
    }

    const handleStatusChange = (val, id) => {
        const body = {
            is_used: val
        }
        eventReporting('Subscription', 'Coupon status changed to  - ' + val, id);
        dispatch(updateCoupon(id, body));
    }

    const handleAddCoupon = () => {
        const body = {
            "platform_pd": platformPrice,
            "coupon_code": couponCode,
            "is_used": isUsed,
            "expiry_date": expiryDate
        }
        if (isEdit && editId != "") {
            eventReporting('Subscription', 'Coupon updated  - ' + couponCode, editId);
            dispatch(updateCoupon(editId, body));
        } else {
            eventReporting('Subscription', 'Coupon added  - ' + couponCode, platformPrice);
            dispatch(addCoupon(body));
        }
        if (!isLoading) {
            setIsAddPlatform(!isAddPlatform);
        }
    }

    const getPlanName = (type) => {
        var plan_types = platformPlanType.data && platformPlanType.data.plan_types;
        if (plan_types && plan_types.length > 0) {
            var result = plan_types.filter(item => item.plan_type == type);
        }
        return result && result[0] ? result[0].plan_name : 'N/A';
    }

    const getDropDownList = (type) => {
        setPlanType(type);
        dispatch(getPlatformPriceDropDown(type));
    }

    const handleClearFormData = () => {
        setIsUsed(false);
        setCouponCode('');
        setPlanType('');
        setPlatformPrice('');
        setPlatformName('');
        setExpiryDate('');
    }

    const handleEditFormData = (data) => {
        setIsUsed(data.is_used);
        setCouponCode(data.coupon_code);
        setPlanType(data.plan_type);
        setPlatformPrice(data.platform_pd_id);
        setPlatformName(data.platform_name);
        var date = new Date(data.expiry_date);
        let m = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var res = date.getFullYear() + "-" + m + "-" + d;
        setExpiryDate(res);
    }

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <h3 className='mb-3'>Coupons Management</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <Button color="info" onClick={() => {
                            setEditId('');
                            handleClearFormData();
                            setIsEdit(false);
                            setIsAddPlatform(!isAddPlatform);
                            ReactGA.modalview(window.location.pathname + '?modal=Add Coupon')
                        }} >Add Coupon</Button>
                    </Row>
                    <Container className='table'>
                        <Table hover className='mt-3 align-items-center table-flush'>
                            <thead>
                                <tr>
                                    <th width="7%">
                                        S. No.
                                    </th>
                                    <th>
                                        Logo
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Plan Type
                                    </th>
                                    <th>
                                        Coupon Code
                                    </th>
                                    <th>
                                        Expiry Date
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {couponList && couponList.data && couponList.data.results && couponList.data.results.length > 0 ? couponList.data.results.map((x, i) => {
                                    return <tr key={x.uuid}>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img className='platform-img' alt="No Image" src={x.platform_logo ? x.platform_logo : ''} />
                                        </td>
                                        <td>
                                            {x.platform_name ? x.platform_name : 'N/A'}
                                        </td>
                                        <td>
                                            {x.plan_type ? x.plan_type : 'N/A'}
                                        </td>
                                        <td>
                                            {x.coupon_code ? x.coupon_code : 'N/A'}
                                        </td>
                                        <td>
                                            {x.expiry_date ? x.expiry_date : 'N/A'}
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    label={`${x.is_used ? 'Used' : 'Unused'}`}
                                                    className=''
                                                    checked={x.is_used}
                                                    onChange={(e) => handleStatusChange(e.target.checked, x.uuid)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <Row>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to delete this item ?")) {
                                                            eventReporting('Subscription', 'Coupon deleted  - ' + x.coupon_code, x.uuid);
                                                            dispatch(deleteCoupon(x.uuid))
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>

                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="info"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        setEditId(x.uuid);
                                                        handleEditFormData(x);
                                                        setIsEdit(true);
                                                        setIsAddPlatform(!isAddPlatform);
                                                        ReactGA.modalview(window.location.pathname + '?modal=Edit Coupon')
                                                    }}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>
                                            </Row>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr>
                                        <td colSpan={8}><h2 className='text-center'>No Data Available</h2></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {couponList && couponList.data && couponList.data.pagination && (couponList.data.pagination.is_next_page || couponList.data.pagination.is_previous_page) &&
                            <CustomPagination
                                pagination={couponList.data && couponList.data.pagination && couponList.data.pagination ? couponList.data.pagination : {}}
                                setCurrentPage={(val) => setCurrentPage(val)}
                                setPageLimit={(val) => setPageLimit(val)}
                                defaultPageList={couponList.data && couponList.data.results ? couponList.data.results.length : 20}
                            />
                        }
                    </Container>
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            {isEdit ? 'Edit' : 'Add'} Coupon
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-right pl-3 pr-3'>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className=''
                                    checked={isUsed}
                                    onChange={(e) => setIsUsed(e.target.checked)}
                                />
                            </div>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Coupon code</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' value={couponCode} onChange={(e) => setCouponCode(e.target.value)} required={true} />
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Plan Type</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                {planType != "" ? getPlanName(planType) : 'Select Plan Type'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {platformPlanType && platformPlanType.data && platformPlanType.data.plan_types && platformPlanType.data.plan_types.length > 0 && platformPlanType.data.plan_types.map((y, j) => {
                                                    return <DropdownItem onClick={() => getDropDownList(y.plan_type)} key={j}>
                                                        {y.plan_name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Price</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen2} toggle={() => setIsOpen2(!isOpen2)}>
                                            <DropdownToggle className='w-100' caret>
                                                {platformName && platformName != "" ? platformName : 'Select Platform Price'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {platformPriceDropdownList && platformPriceDropdownList.data && platformPriceDropdownList.data.results && platformPriceDropdownList.data.results.length > 0 ? platformPriceDropdownList.data.results.map((y, j) => {
                                                    return <DropdownItem onClick={() => { setPlatformPrice(y.platform_pd); setPlatformName(y.platform_name) }} key={j}>
                                                        {y.platform_name}
                                                    </DropdownItem>
                                                })
                                                    :
                                                    <DropdownItem onClick={() => {
                                                        setIsOpen2(false);
                                                        setIsOpen(true);
                                                    }}>
                                                        Select Plan Type First
                                                    </DropdownItem>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Expiry Date</p>
                                </Col>
                                <Col sm={7}>
                                    <Input
                                        id="exampleDate"
                                        name="date"
                                        placeholder="date placeholder"
                                        type="date"
                                        value={expiryDate}
                                        onChange={(e) => setExpiryDate(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                onClick={() => handleAddCoupon()}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
