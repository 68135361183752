import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";
import axios from "axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_SUCCESS });
        }
    }
}


export const getCollections = () => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_COLLECTIONS_REQUEST });
        axiosInstance
            .get(`native_main/collections`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_COLLECTIONS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_COLLECTIONS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const pushDynamicPost = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.PUSH_DYNAMIC_POST_REQUEST });
        axiosInstance
            .post(`native_main/pushpost`,body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PUSH_DYNAMIC_POST_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PUSH_DYNAMIC_POST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getActorDropDown = (val) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_ACTOR_DROPDOWN_REQUEST });
        axiosInstance
            .get(`native_main/actordropdown?type=actor&q=${val}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_ACTOR_DROPDOWN_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_ACTOR_DROPDOWN_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}