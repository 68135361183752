import React, { useEffect } from 'react'
import Header from "components/Headers/Header.js";
import { Card, Container, Row, Form, FormGroup, Label, Col, Input, Button, Table, Dropdown, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import MediaType from 'components/MediaType/MediaType';
import { useSelector, useDispatch } from 'react-redux'
import { userReview, reportReviewByClick, deleteReview, deleteMultipleReview, clearMessage } from "../../redux/actions/review.action"
import CustomPagination from 'components/Pagination/CustomPagination';
import Loader from '../common/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

const list = [
    {
        name: "Duplicate"
    },
    {
        name: "Irrelevant to content"
    },
    {
        name: "Offensive"
    },
    {
        name: "Spam"
    },
    {
        name: "Opinion, not review"
    }
]


const defaultReason = {
    uuid: '',
    reason: ''
}

export default function UserReview() {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState('');
    const [todate, setToDate] = React.useState('');
    const [allChecked, setAllChecked] = React.useState(false);
    const [allCheckedIds, setAllCheckedIds] = React.useState([]);
    const [fromdate, setFromDate] = React.useState('');
    const [reason, setReason] = React.useState('')
    const [currentPage, setCurrentPage] = React.useState(1);
    const [userReviewsData, setUserReviewsData] = React.useState([]);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [viewModal, setViewModal] = React.useState(false);
    const [viewText, setViewText] = React.useState('');
    const [selectedReason, setSelectedReason] = React.useState(defaultReason)
    const [commentType, setCommentType] = React.useState(null)
    const [searchType, setSearchType] = React.useState('media_only')
    const [isReview, setIsReview] = React.useState(false);
    const [openType, setOpenType] = React.useState(false);
    const [viewModalCommon, setViewModalCommon] = React.useState(false);
    const [selectedReasonCommon, setSelectedReasonCommon] = React.useState('');

    const userReviewResult = useSelector((state) => state.userReview && state.userReview.userReviewResult ? state.userReview.userReviewResult : {})

    const message = useSelector((state) => state.userReview && state.userReview.message ? state.userReview.message : '')
    const isLoading = useSelector((state) => state.userReview && state.userReview.isLoading ? state.userReview.isLoading : false)
    const errMesg = useSelector((state) => state.userReview && state.userReview.errMesg ? state.userReview.errMesg : false)
    const apiStatus = useSelector((state) => state.userReview && state.userReview.status ? state.userReview.status : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (message && message != "") {
            if (apiStatus) {
                toast.success((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
            var date = '';
            if (todate || fromdate) {
                var date = todate + "," + fromdate;
            }
            dispatch(userReview(value, date, commentType, currentPage, pageLimit));
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    const getMessage = (value) => {
        return value.split('=>')[1];
    }

    React.useEffect(() => {
        if (userReviewResult && userReviewResult.data && userReviewResult.data.results && userReviewResult.data.results.length > 0) {
            setUserReviewsData(userReviewResult.data.results);
        } else {
            setUserReviewsData([]);
        }
    }, [userReviewResult])

    useEffect(() => {
        var date = '';
        if (todate || fromdate) {
            var date = todate + "," + fromdate;
        }
        dispatch(userReview(value, date, commentType, currentPage, pageLimit));
    }, [currentPage, pageLimit, commentType]);

    const handleSearch = () => {
        var date = '';
        if (todate || fromdate) {
            var date = todate + "," + fromdate;
        }
        dispatch(userReview(value, date, commentType, 1, pageLimit, searchType));
        setCurrentPage(1);
    }

    useEffect(() => {
        if (!isLoading && message == "Review Deleted") {
            setAllCheckedIds([])
            handleUserSearch()
        }
    }, [message])

    const handleUserSearch = () => {
        if (todate || fromdate) {
            var date = todate + "," + fromdate;
        }
        dispatch(userReview(value, date, commentType));
    }

    const handleReport = (id) => {
        if (selectedReason.reason != "") {
            var body = {
                "review": id,
                "admin_reported": true,
                "report_reason": selectedReason.reason
            }
            eventReporting('Review', 'Review reported  - ' + selectedReason.reason, id);
            dispatch(reportReviewByClick(body))
        } else {
            alert('reason can not be null')
        }
    }
    // console.log(handleReport, "555555555")

    const handleSingleReviewDelete = (id, reason) => {
        if (reason != "") {
            dispatch(deleteReview(id, reason))
        } else {
            alert('reason can not be null')
        }
    }
    // console.log(message,"*******8888********")

    const handleInputCheck = (e, id) => {

        var tempArr = allCheckedIds;
        if (e.target.checked) {
            tempArr.push(id);
        } else {
            var index = tempArr.indexOf(id);
            if (index => 0) {
                tempArr.splice(index, 1);
            }
        }

        setAllCheckedIds([...tempArr]);
    }

    const handleAllInputCheck = (e) => {
        setAllChecked(e.target.checked);
        var checkBoxArr = document.getElementsByClassName('checkbox-common');
        if (checkBoxArr && checkBoxArr.length > 0) {
            for (var i = 0; i < checkBoxArr.length; i++) {
                checkBoxArr[i].checked = e.target.checked;
            }
        }

        if (e.target.checked) {
            if (checkBoxArr && checkBoxArr.length > 0) {
                for (var i = 0; i < checkBoxArr.length; i++) {
                    var tempArr = allCheckedIds;
                    var id = checkBoxArr[i].getAttribute('data-id');
                    if (e.target.checked && !tempArr.includes(id)) {
                        tempArr.push(id);
                    }
                    setAllCheckedIds([...tempArr]);
                }
            }
        } else {
            setAllCheckedIds([]);
        }
    }

    const handleReason = (id, reason) => {
        // var tempArr = userReviewsData;
        // tempArr.map((x, i) => {
        //     if (x.uuid == id) {
        //         x.reason = reason;
        //     }
        // })
        // setUserReviewsData([...tempArr]);
        setSelectedReason({ uuid: id, reason: reason });
    }


    const handleDelete = () => {
        if (allCheckedIds && allCheckedIds.length > 0) {
            const body = {
                "reviews": allCheckedIds
            }
            if (selectedReasonCommon && selectedReasonCommon != "") {
                eventReporting('Review', 'Multiple User reported Review deleted  - ' + selectedReasonCommon, JSON.stringify(allCheckedIds));
                dispatch(deleteMultipleReview(body, selectedReasonCommon));
                setViewModalCommon(!viewModalCommon);
                setSelectedReasonCommon("");
            } else {
                toast.error("Please select an reason to delete", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Please select atleast one review", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    console.log("allCheckedIdsallCheckedIds", allCheckedIds)

    return (
        <>
            <Header />
            <Container>
                <Card style={{ padding: '20px', marginTop: "15px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className='heading'>
                        <h4>
                            User Reviews
                        </h4>
                    </div>
                    <Row className='mt-4 marg-bot-minus'>
                        <Col sm={2}>
                            <Dropdown className='w-100' isOpen={openType} toggle={() => setOpenType(!openType)}>
                                <DropdownToggle className='w-100' caret>
                                    {searchType && searchType == 'user_only' ? 'User Name' : 'Media Title'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setSearchType('user_only')}>User Name</DropdownItem>
                                    <DropdownItem onClick={() => setSearchType('media_only')}>Media Title</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col sm={3} className='pb-3 p-0 m-0'>
                            <Col sm={12} className="p-0 search-icon-box">
                                <Input onChange={(e) => setValue(e.target.value)} placeholder="Search here..." onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                                <i className="fas fa-search" onClick={() => handleSearch()}></i>
                            </Col>
                        </Col>
                        <Col sm={2} className='pr-0 mr-0'>
                            <Form>
                                <FormGroup>
                                    {/* <Label for="exampleDate">Date</Label> */}
                                    <Input onChange={(e) => setToDate(e.target.value)} type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm={2}>
                            <Form>
                                <FormGroup>
                                    {/* <Label for="exampleDate">Date</Label> */}
                                    <Input onChange={(e) => setFromDate(e.target.value)} type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm={1} className='p-0 m-0'>
                            <Dropdown className='w-100' isOpen={isReview} toggle={() => setIsReview(!isReview)}>
                                <DropdownToggle className='w-100' caret>
                                    {commentType ? commentType : 'All'}
                                </DropdownToggle>
                                <DropdownMenu
                                >
                                    <DropdownItem onClick={() => setCommentType(null)}>All</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('text')}>Text</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('audio')}>Audio</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('video')}>Video</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col sm={2} className="success-btn">
                            <Button className='w-100' onClick={() => handleUserSearch()} color="darker" >Search</Button>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <Button
                                disabled={allCheckedIds.length > 0 ? false : true}
                                onClick={() => setViewModalCommon(true)}
                                color="danger">Delete Selected</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Container className='table'>
                            <Table hover className="align-items-center table-flush">
                                <thead>
                                    <tr>
                                        <th>
                                            <Form>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            checked={allChecked}
                                                            onChange={(e) => handleAllInputCheck(e)}
                                                            type="checkbox" className='checkbox' />
                                                    </Label>
                                                </FormGroup>
                                            </Form>
                                        </th>
                                        <th className='table_heading' style={{ width: '18%' }}>
                                            Date & Time
                                        </th>
                                        <th className='table_heading'>
                                            Media Title
                                        </th>
                                        <th className='table_heading'>
                                            Username
                                        </th>
                                        <th className='table_heading'>
                                            Review Comment
                                        </th>
                                        <th className='table_heading'>
                                            Type
                                        </th>
                                        <th className='table_heading'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userReviewsData.length > 0 ? userReviewsData.map((x, i) => {
                                        return <tr key={i}>
                                            <th scope="row">
                                                <Form>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                // checked={allChecked}
                                                                checked={allCheckedIds.length > 0 && allCheckedIds.indexOf(x.uuid) != -1 ? true : false}
                                                                data-id={x.uuid}
                                                                onChange={(e) => handleInputCheck(e, x.uuid)}
                                                                type="checkbox" className='checkbox checkbox-common' />
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </th>
                                            <td>
                                                {x.created_at}
                                            </td>
                                            <td>
                                                <h5>
                                                    {x.title}
                                                </h5>
                                            </td>
                                            <td>
                                                <h5>
                                                    {x.username}
                                                </h5>
                                            </td>
                                            <td>
                                                {x.comment_type == 'text' ?
                                                    x.comment.length > 50 ? <span className='pointer' onClick={() => {
                                                        setViewModal(true);
                                                        setViewText(x.comment);
                                                    }}>{x.comment.substring(0, 50)} ...</span> : x.comment
                                                    :
                                                    <MediaType type={x.comment_type} src={x.comment_url} />
                                                }
                                            </td>
                                            <td className='text-capitalize'>
                                                {x.comment_type}
                                            </td>
                                            <td>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Col>
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle caret>
                                                                {selectedReason && selectedReason.reason && x.uuid === selectedReason.uuid && selectedReason.reason != "" ? selectedReason.reason : 'Select reason'}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {list.map((y, j) => {
                                                                    return <DropdownItem onClick={() => handleReason(x.uuid, y.name)}>
                                                                        {y.name}
                                                                        <DropdownItem divider />
                                                                    </DropdownItem>
                                                                })}
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </Col>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <Button
                                                            // disabled={x && x.reason && x.reason != "" ? false : true}
                                                            disabled={!(x.uuid === selectedReason.uuid)}
                                                            onClick={() => handleReport(x.uuid)}
                                                            size="sm" color="warning" >Report</Button>
                                                        {/* <Button
                                                            disabled={x && x.reason && x.reason != "" ? false : true}
                                                            onClick={() => handleSingleReviewDelete(x.uuid,x && x.reason ? x.reason : '')}
                                                            size="sm" color="danger" >Delete</Button> */}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                        :
                                        <tr>
                                            <td colSpan={9} className='text-center'>
                                                <h3>
                                                    No Data Available
                                                </h3>
                                            </td>
                                        </tr>
                                    }


                                </tbody>
                            </Table>
                            {userReviewResult.data && userReviewResult.data.pagination &&
                                <CustomPagination
                                    pagination={userReviewResult.data && userReviewResult.data.pagination && userReviewResult.data.pagination ? userReviewResult.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={userReviewResult.data && userReviewResult.data.results ? userReviewResult.data.results.length : 20}
                                />
                            }
                            <Col sm={12} className="success-btn text-left">
                                <Button
                                    onClick={() => setViewModalCommon(true)}
                                    disabled={allCheckedIds.length > 0 ? false : true}
                                    color="danger">Delete Selected</Button>
                            </Col>
                        </Container>
                    </Row>
                </Card>

                <Modal
                    isOpen={viewModalCommon}
                    toggle={() => setViewModalCommon(!viewModalCommon)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModalCommon(!viewModalCommon)}>
                        Select reason to delete
                    </ModalHeader>
                    <ModalBody className='pt-0'>
                        <Row>
                            <Col className='pb-3'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {selectedReasonCommon && selectedReasonCommon != "" ? selectedReasonCommon : 'Reason to delete'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {list.map((y, j) => {
                                            return <DropdownItem onClick={() => setSelectedReasonCommon(y.name)}>
                                                {y.name}
                                                <DropdownItem divider />
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => setViewModalCommon(!viewModalCommon)} color='danger'>Cancel</Button>
                            </Col>
                            <Col className='text-right'>
                                <Button onClick={() => handleDelete()} color='success'>Submit</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Modal
                    isOpen={viewModal}
                    toggle={() => setViewModal(!viewModal)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModal(!viewModal)}>
                        View Comment
                    </ModalHeader>
                    <ModalBody className='pt-0' style={{ wordBreak: 'break-all' }}>
                        {viewText}
                    </ModalBody>
                </Modal>
            </Container>

        </>
    )
}
