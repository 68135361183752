import { User } from "../constants/constants";
import { WEB } from "../constants/constants";

import axiosInstance from "../../helpers/axios";


export const mediasearch = (value) => {
    return (dispatch) => {
        dispatch({ type: User.SEARCH_MEDIA_REQUEST });

        axiosInstance
            .post(`native_main/frenzisearch?query=${value}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: User.SEARCH_MEDIA_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.SEARCH_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const userSearch = (value, sort = "dd", page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: User.SEARCH_USER_REQUEST });
        var path = `adminpanel/userlist?search=${value}&pagelimit=${pagelimit}&page=${page}`;
        if (sort && sort != "") {
            path = `adminpanel/userlist?search=${value}&pagelimit=${pagelimit}&page=${page}&sort=${sort}`;
        }
        axiosInstance
            .get(path)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: User.SEARCH_USER_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.SEARCH_USER_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getMediaTypeSearchList = (query) => {
    return (dispatch) => {
        dispatch({ type: User.MEDIA_TYPE_REQUEST });
        axiosInstance
            .post(`https://ungjb4hwzg.execute-api.ap-south-1.amazonaws.com/dev/frenzee/native_main/frenzisearch?query=${query}&search_type=media`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: User.MEDIA_TYPE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.MEDIA_TYPE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const personSearch = (text = "" ,page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_PERSON_SEARCH_LIST_REQUEST });

        axiosInstance
            .get(`adminpanel/person?search=${text}&pagelimit=${pagelimit}&page=${page}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_PERSON_SEARCH_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_PERSON_SEARCH_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}