import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    errMesg: "",
    status: "",
    req: {},
    notifyListResult: {},
    errorReportResult: {},
    feedbackResult: {},
    cxEngineList: {},
    cxDeeplinkList: {},
    cxCsvData: {}
};


export const operationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.OPERATIONS_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.OPERATIONS_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
            };
        case WEB.NOTIFY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.NOTIFY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                notifyListResult: payload,
            };
        case WEB.NOTIFY_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_ERROR_REPORT_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_ERROR_REPORT_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                errorReportResult: payload,
            };
        case WEB.GET_ERROR_REPORT_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.POST_ERROR_REPORT_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POST_ERROR_REPORT_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Status',

            };
        case WEB.POST_ERROR_REPORT_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.POST_ERROR_REPORT_STATUS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POST_ERROR_REPORT_STATUS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Status',

            };
        case WEB.POST_ERROR_REPORT_STATUS_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_FEEDBACK_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_FEEDBACK_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                feedbackResult: payload,
            };
        case WEB.GET_FEEDBACK_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_CX_ENGINE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_CX_ENGINE_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                cxEngineList: payload,
            };
        case WEB.GET_CX_ENGINE_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_CX_DEEPLINK_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_CX_DEEPLINK_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                cxDeeplinkList: payload,
            };
        case WEB.GET_CX_DEEPLINK_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.DELETE_CX_RECORD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_CX_RECORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'CX Record Deleted Successfully',
            };
        case WEB.DELETE_CX_RECORD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_CX_REMARK_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_CX_REMARK_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'CX Record Updated Successfully',
            };
        case WEB.GET_CX_REMARK_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.EXPORT_CX_CSV_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.EXPORT_CX_CSV_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                cxCsvData: payload,
                status: payload.message ? payload.message : 'CX Record Exported Successfully',
            };
        case WEB.EXPORT_CX_CSV_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.CLEAR_EXPORT_DATA:
            return {
                ...state,
                cxCsvData: {}
            };
        default:
            return state;
    }
}