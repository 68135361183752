import React from 'react'
import Header from "components/Headers/Header.js";
import { Card, Container, Row, Col, Form, FormGroup, Label, CustomInput, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import "./_frenziPoint.scss"
import { bulkFrenziPoints, clearMessage } from '../../redux/actions/frenziPoint.action';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

export default function FrenziPointsBulkUpload() {
    const dispatch = useDispatch()
    const [isPlan, setIsPlan] = React.useState(false)
    const [file, setFile] = React.useState('');
    const [bulkPoint, setBulkPoint] = React.useState('');
    const [desciption, setDesciption] = React.useState('');

    const status = useSelector((state) => state.frenziPoint && state.frenziPoint.status ? state.frenziPoint.status : '')
    const isLoading = useSelector((state) => state.frenziPoint && state.frenziPoint.isLoading ? state.frenziPoint.isLoading : false)
    const errMesg = useSelector((state) => state.frenziPoint && state.frenziPoint.errMesg ? state.frenziPoint.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleUpload = () => {
        var formData = new FormData();
        formData.append('users', file[0]);
        formData.append('points', bulkPoint);
        formData.append('description', desciption);
        dispatch(bulkFrenziPoints(formData));
        eventReporting('Frenzi Points', 'Frenzi Points bulk uploaded - ' + bulkPoint, file[0].name ? file[0].name : '');
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-4 rounded-lg'>
                    <Row className='sub_heading'>
                        <Col>
                            <h3 className='mb-3'>Bulk Upload Frenzi Points</h3>
                        </Col>
                    </Row>
                    <div className='inner_div'>
                        <Col >
                            <Form >
                                <FormGroup>
                                    <Label for="exampleCustomFileBrowser">File Browser</Label>
                                    <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile" onChange={(e) => setFile(e.target.files)} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup>
                                    <Label for="exampleDatetime">Points</Label>
                                    <Input type="number" name="frenziPoint" id="exampleDate" placeholder="Enter Points" onChange={(e) => setBulkPoint(e.target.value)} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup>
                                    <Label for="exampleDatetime">Description</Label>
                                    <Input placeholder="Enter Descriptions" onChange={(e) => setDesciption(e.target.value)} />
                                </FormGroup>
                            </Form>
                        </Col>
                        {/* <Col>
                            <Col sm={5} className="p-0">
                                <Label className='mr-3 mb-3 v-middle'>Platform</Label>
                            </Col>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isPlan} toggle={() => setIsPlan(!isPlan)}>
                                    <DropdownToggle className='w-100' caret>
                                        Select Platform
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {list.map((y, j) => {
                                            return <DropdownItem>
                                                {y.name}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col> */}
                        <Col className='text-right mt-4' width="7%">
                            <Button color="info" width="10%" onClick={() => handleUpload()} >Upload</Button>
                        </Col>
                    </div>
                </Card>
            </Container>
        </>
    )
}
