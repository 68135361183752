import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import Header from 'components/Headers/Header';
import { Button, Card, Col, Container, CustomInput, Input, Row, Table } from 'reactstrap';

import { getPollsList, deletePoll, clearMessage, updatePoll } from '../../redux/actions/polls.action';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from 'components/Pagination/CustomPagination';
import AddPoll from './AddPoll';
import UpdatePoll from './UpdatePoll';
import Loader from 'views/common/Loader';

export default function Polls() {
    const [searchText, setSearchText] = useState('');
    const [isAddPoll, setIsAddPoll] = useState(false);
    const [isEditPoll, setIsEditPoll] = useState(false);
    const [editData, setEditData] = useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);

    const dispatch = useDispatch();
    const pollsList = useSelector((state) => state.polls && state.polls.pollsListResult ? state.polls.pollsListResult : {});
    const status = useSelector((state) => state.polls && state.polls.status ? state.polls.status : '');
    const errMesg = useSelector((state) => state.polls && state.polls.errMesg ? state.polls.errMesg : '');
    const isLoading = useSelector((state) => state.polls && state.polls.isLoading ? state.polls.isLoading : false);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    useEffect(() => {
        dispatch(getPollsList(currentPage, pageLimit));
    }, [currentPage, pageLimit])

    useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            dispatch(getPollsList(currentPage, pageLimit));
            if (status == 'poll created' || status == 'poll updated') {
                resetAll();
            }
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleAddPoll = () => {
        ReactGA.modalview(window.location.pathname + '?modal=AddPoll')
        setIsAddPoll(true);
    }

    const handleStatusChange = (e, id) => {
        const body = {
            is_active: e.target.checked
        }
        eventReporting('Admin', 'Changed Admin status to - ' + e.target.checked, id);
        dispatch(updatePoll(id, body))
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this poll ?")) {
            eventReporting('Polls', 'Poll deleted', id);
            dispatch(deletePoll(id));
        }
    }

    const resetAll = () => {
        setEditData({});
        setIsEditPoll(false);
        setIsAddPoll(false);
    }

    const handleEdit = (data) => {
        ReactGA.modalview(window.location.pathname + '?modal=EditPoll')
        setEditData(data);
        setIsEditPoll(true);
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-3'>
                    {/* <h3 className='mb-3'>Polls</h3> */}
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            {/* <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />
                            <i className="fas fa-search"></i> */}
                            <h3 className='mb-3'>{isAddPoll ? 'Add Poll' : isEditPoll ? "Edit Poll" : 'Polls'}</h3>
                        </Col>
                        <div>
                            {isAddPoll || isEditPoll ?
                                <Button color="dark" onClick={() => resetAll()} >Back</Button>
                                :
                                <Button color="info" onClick={() => handleAddPoll()} >Add Poll</Button>
                            }
                        </div>
                    </Row>
                    {!isAddPoll && !isEditPoll &&
                        <Container style={{ overflowY: 'auto' }} className='table'>
                            <Table hover className='mt-3 align-items-center table-flush'>
                                <thead>
                                    <tr>
                                        <th width="9%" className='table_heading'>
                                            S.No
                                        </th>
                                        <th className='table_heading'>
                                            Question
                                        </th>
                                        <th className='table_heading'>
                                            Status
                                        </th>
                                        <th className='table_heading'>
                                            Image
                                        </th>
                                        <th className='table_heading'>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='vertical-middle'>
                                    {pollsList && pollsList.data && pollsList.data.results && pollsList.data.results.length > 0 ? pollsList.data.results.map((x, i) => {
                                        return <tr key={i}>
                                            <td scope="row">
                                                {(pageLimit * (currentPage - 1)) + (i + 1)}
                                            </td>
                                            <td>
                                                {x.question}
                                            </td>
                                            <td>
                                                <div className='text-center'>
                                                    <CustomInput
                                                        type="switch"
                                                        id={`exampleCustomSwitch-${i}`}
                                                        name="customSwitch"
                                                        className='p-0'
                                                        checked={x.is_active ? x.is_active : false}
                                                        onChange={(e) => handleStatusChange(e, x.uuid)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                {x.image && x.image != "" ?
                                                    <img style={{ maxHeight: 70, maxWidth: 125, borderRadius: 3 }} src={x.image} />
                                                    :
                                                    <div style={{ maxHeight: 70, maxWidth: 125, width: '100%' }} className='default-img'>
                                                        <i className='fas fa-image'></i>
                                                    </div>
                                                }
                                            </td>
                                            <td>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleEdit(x)}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleDelete(x.uuid)}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    })
                                        :
                                        <tr>
                                            <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            {pollsList.data && pollsList.data.pagination &&
                                <CustomPagination
                                    pagination={pollsList.data && pollsList.data.pagination && pollsList.data.pagination ? pollsList.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={pollsList.data && pollsList.data.results ? pollsList.data.results.length : 10}
                                />
                            }
                        </Container>
                    }
                    {isAddPoll &&
                        <Container >
                            <AddPoll />
                        </Container>
                    }
                    {isEditPoll &&
                        <Container >
                            <UpdatePoll data={editData} />
                        </Container>
                    }
                </Card>
            </Container>
        </>
    )
}
