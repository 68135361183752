import Header from 'components/Headers/Header'
import helpers from 'helpers/common';
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { Button, Col, Container, Row, Table } from 'reactstrap'
import Loader from 'views/common/Loader';

const DEALS = 'https://frenzi-native.s3.ap-south-1.amazonaws.com/analytics_data/deals_lifetime.csv';

const COMING_SOON = 'https://frenzi-native.s3.ap-south-1.amazonaws.com/analytics_data/media_coming_soon.csv';

const NEW_RELEASE = 'https://frenzi-native.s3.ap-south-1.amazonaws.com/analytics_data/media_new_releases.csv';

const SIGNUP_USERS = 'https://frenzi-native.s3.ap-south-1.amazonaws.com/analytics_data/weekly_signup_users.csv';

const downloads = [
    {
        id: 1,
        src: DEALS,
        label: 'Deals Lifetime',
        size: 'sm',
        color: 'success'
    },
    {
        id: 2,
        src: COMING_SOON,
        label: 'Coming Soon Medias',
        size: 'sm',
        color: 'warning'
    },
    {
        id: 3,
        src: NEW_RELEASE,
        label: 'New Releases Medias',
        size: 'sm',
        color: 'info'
    },
    {
        id: 4,
        src: SIGNUP_USERS,
        label: 'Weekly Signup Usesrs',
        size: 'sm',
        color: 'danger'
    }
]

export default function AnalyticsData() {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState(downloads);


    const handleDownload = async (src) => {
        setIsLoading(true);
        let path = await helpers.getPresignedUrl(src);
        if (path) {
            const aTag = document.createElement("a");
            aTag.href = path;
            aTag.target = '_blank';
            document.body.appendChild(aTag);
            aTag.click();
            setIsLoading(false);
            // setTimeout(() => {
            //     handleDeleteKey(exportFileUrl);
            // }, 3000)
        } else {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (files && files.length) {
            setData(files);
        }
    }, [])

    const setData = async (myArray) => {
        const newArray = await Promise.all(myArray.map(async (item) => {
            try {
                const value = await getFileUrl(item.src);
                return { ...item, date: value };
            } catch (error) {
                console.error('Error:', error);
                return null; // or handle the error in some way
            }
        }));
        setFiles(newArray);

    }

    const getFileUrl = async (src) => {
        // let path = await helpers.getPresignedUrl(src);
        let date = helpers.getS3FileDate(src);
        // debugger
        return date;
    }


    return (
        <>
            <Header />
            <ToastContainer />
            {isLoading &&
                <Loader />
            }
            <div>
                <Container className='custom-link' fluid >
                    <h3 className='mb-4'>Download Analytics CSV files:-</h3>
                    <div>
                        <Table className="align-items-center table-flush mt-3" bordered>
                            <tr>
                                <th className='table_heading'>Sr No.</th>
                                <th className='table_heading'>File Name</th>
                                <th className='table_heading'>Last Modified</th>
                                <th className='table_heading'>Action</th>
                            </tr>
                            {files.map((x, i) => {
                                return <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{x.label}</td>
                                    <td>{x?.date}</td>
                                    <td>
                                        <Button
                                            color={x.color}
                                            size={x.size}
                                            onClick={() => handleDownload(x.src)}
                                        >Download File</Button>
                                    </td>
                                </tr>
                            })}
                        </Table>
                    </div>
                </Container>
            </div>
        </>
    )
}
