import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, Nav, NavItem, NavLink, TabContent, TabPane, CardText, CardTitle, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import './../subscription/_subscriptions.scss';
import './_admin.scss';
import CustomPagination from 'components/Pagination/CustomPagination';
import AdminList from './AdminList';
import Roles from './Roles';
import ReactGA from 'react-ga';


export default function AdminManagement() {
    const [activeTab, setActiveTab] = React.useState('admins')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])
    const inputFileRef = React.useRef(null);

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    <div>
                        <Nav tabs>
                            {/* <NavItem>
                                <NavLink
                                    className={`${activeTab == 'roles' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('roles')}
                                    style={{cursor:'pointer'}}
                                >
                                    Roles
                                </NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink
                                    className={`${activeTab == 'admins' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('admins')}
                                    style={{cursor:'pointer'}}
                                >
                                    Admin
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {/* <TabPane tabId="roles">
                                <Roles />
                            </TabPane> */}
                            <TabPane tabId="admins">
                                <AdminList />
                            </TabPane>
                        </TabContent>
                    </div>
                </Card>
            </Container>
        </>
    )
}

