import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import './_subscriptions.scss';
import CustomPagination from 'components/Pagination/CustomPagination';
import { getSubscriptionRateList, addSubscriptionRate, updateSubscriptionRate, deleteSubscriptionRate, clearMessage } from '../../redux/actions/subscription.action';
import { useSelector, useDispatch } from 'react-redux'
import { currencyList } from './currency'
import Loader from 'views/common/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

export default function RateManagement() {
    const dispatch = useDispatch();
    const [frenziPoints, setFrenziPoints] = React.useState('');
    const [currency, setCurrency] = React.useState('INR');

    const [isEditOrAdd, setIsEditOrAdd] = React.useState(false);
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [editId, setEditId] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false)
    const [actionType, setActionType] = React.useState('');
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);

    const inputFileRef = React.useRef(null);

    const handleEdit = (data) => {
        setEditId(data.uuid);
        setCurrency(data.currency);
        setFrenziPoints(data.frenzi_points_rate);
        setActionType('edit');
        setIsEditOrAdd(true);
        ReactGA.modalview(window.location.pathname + '?modal=Edit Rate');
    }

    const handleDelete = (id) => {
        if (window.confirm("Do you really want to delete it ?")) {
            eventReporting('Subscription', 'Rate deleted  - ', id);
            dispatch(deleteSubscriptionRate(id));
        }
    }

    const message = useSelector((state) => state.subscription && state.subscription.message ? state.subscription.message : '')
    const rateList = useSelector((state) => state.subscription && state.subscription.subscriptionRateListResult ? state.subscription.subscriptionRateListResult : {})
    const isLoading = useSelector((state) => state.subscription && state.subscription.isLoading ? state.subscription.isLoading : false)
    const status = useSelector((state) => state.subscription && state.subscription.status ? state.subscription.status : '')
    const errMesg = useSelector((state) => state.subscription && state.subscription.errMesg ? state.subscription.errMesg : '')

    React.useEffect(() => {
        dispatch(getSubscriptionRateList(searchText, currentPage, pageLimit));
    }, [currentPage, pageLimit, message])

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(getSubscriptionRateList(searchText, 1, pageLimit));
    }

    const handleSave = (type) => {
        const body = {
            "currency": currency,
            "frenzi_points_rate": frenziPoints,
        }
        if (type == 'Save') {
            eventReporting('Subscription', 'Rate added  - ' + currency, frenziPoints);
            dispatch(addSubscriptionRate(body));
        } else {
            eventReporting('Subscription', 'Rate updated  - ' + frenziPoints, editId);
            dispatch(updateSubscriptionRate(editId, body));
        }
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            setIsEditOrAdd(false);
            setActionType('');
            dispatch(getSubscriptionRateList(searchText, currentPage, pageLimit));
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>Rate Management</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <Button color="info" onClick={() => {
                            setActionType('add');
                            setEditId('');
                            setCurrency('');
                            setFrenziPoints('');
                            setIsEditOrAdd(true);
                            ReactGA.modalview(window.location.pathname + '?modal=Add Rate');
                        }} >Add Rate</Button>
                    </Row>
                    <Table hover className='mt-3'>
                        <thead>
                            <tr>
                                <th width="9%">
                                    S. No.
                                </th>
                                <th>
                                    Currency
                                </th>
                                <th>
                                    Frenzi Points Rate
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rateList.data && rateList.data.results && rateList.data.results.length > 0 ? rateList.data.results.map((x, i) => {
                                return <tr key={i}>
                                    <th scope="row">
                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                    </th>
                                    <td>
                                        {x.currency}
                                    </td>
                                    <td>
                                        {x.frenzi_points_rate}
                                    </td>
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{ marginRight: 10 }}
                                            onClick={() => handleEdit(x)}
                                        >
                                            <i className="fas fa-pen pt-1"></i>
                                        </Button>

                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            onClick={() => handleDelete(x.uuid)}
                                        >
                                            <i className="fas fa-trash pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })
                                :
                                <tr>
                                    <td colSpan={4}><h2 className='text-center'>No Data Available</h2></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    {rateList.data && rateList.data.pagination && (rateList.data.pagination.is_next_page || rateList.data.pagination.is_previous_page) &&
                        <CustomPagination
                            pagination={rateList.data && rateList.data.pagination && rateList.data.pagination ? rateList.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={rateList.data && rateList.data.results ? rateList.data.results.length : 20}
                        />
                    }
                    <Modal
                        isOpen={isEditOrAdd}
                        toggle={() => setIsEditOrAdd(!isEditOrAdd)}
                    >
                        <ModalHeader toggle={() => setIsEditOrAdd(!isEditOrAdd)}>
                            <h3>{actionType == 'edit' ? 'Edit' : 'Add'} Rate</h3>
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 w-100 mt-3 add-rate'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Currency</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                {currency && currency != "" ? currencyList[currency].name : 'Select Currency'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {Object.keys(currencyList).map((y, j) => {
                                                    return <DropdownItem onClick={() => setCurrency(y)}>
                                                        {currencyList[y].name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Frenzi Points Rate</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Input value={frenziPoints} onChange={(e) => setFrenziPoints(e.target.value)} type="number" />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                onClick={() => handleSave(actionType == 'edit' ? 'Upddate' : 'Save')}
                            >
                                {actionType == 'edit' ? 'Upddate' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
