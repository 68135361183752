import React, { useEffect, useState } from 'react'
import Header from "components/Headers/Header.js";
import { useSelector, useDispatch } from 'react-redux'
import {
  Row, Col, Button, Container, Form, FormGroup, Input,
  Card, CardBody, Table, Modal, ModalHeader, ModalFooter, ModalBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { personSearch } from "../../redux/actions/search.action"
import { deletePerson, clearMessage } from "../../redux/actions/person.action"
import {clearMessage as clearMessageMedia} from '../../redux/actions/media.action'
import CustomPagination from 'components/Pagination/CustomPagination';
import Loader from 'views/common/Loader';
import common from '../../helpers/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditCastCrew from './EditCastCrew';
import ReactGA from 'react-ga';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { syncPerson } from '../../redux/actions/media.action'

export default function CastCrew() {
  const dispatch = useDispatch()

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(20);
  const [searchText, setSearchText] = React.useState('');
  const [editId, setEditId] = React.useState('');
  const [isEdit, setIsEdit] = React.useState(false);
  const [lightImage, setLightImage] = React.useState('');
  const [lightBoxOpen, setLightBoxOpen] = React.useState(false);
  const [tmdb_idPerson, setTmdb_idPerson] = useState('')

  const personSearchResult = useSelector((state) => state.commonSearch && state.commonSearch.personSearchResult ? state.commonSearch.personSearchResult : {})
  const isLoading = useSelector((state) => state.commonSearch && state.commonSearch.isLoading ? state.commonSearch.isLoading : false)
  const errMesg = useSelector((state) => state.person && state.person.errMesg ? state.person.errMesg : '')
  const status = useSelector((state) => state.person && state.person.status ? state.person.status : '')
  const status1 = useSelector((state) => state.media && state.media.status ? state.media.status : '')

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const eventReporting = (category, action, val) => {
    ReactGA.event({
      category: category,
      action: action,
      value: val,
      label: val
    });
  }

  React.useEffect(() => {
    dispatch(personSearch(searchText, currentPage, pageLimit,));
  }, [currentPage, pageLimit,])

  const handleSearch = () => {
    setCurrentPage(1);
    dispatch(personSearch(searchText, 1, pageLimit,));
  }

  const handleDelete = (id) => {
    eventReporting('Media', 'Person deleted - ', id);
    dispatch(deletePerson(id));
  }

  React.useEffect(() => {
    if (status && status != "") {
      toast.success((status), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearMessage());
      dispatch(personSearch(searchText, currentPage, pageLimit));
    }
    if (status1 && status1 != "") {
      toast.success((status1), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearMessageMedia());
      
    }

    if (errMesg && errMesg != "") {
      toast.error((errMesg), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearMessage());
    }
  }, [errMesg, status1 , status])

  const handleEdit = (id) => {
    setEditId(id);
    setIsEdit(true);
  }

  const handleNoEdit = () => {
    setEditId('');
    setIsEdit(false);
  }
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const Submit_tmdb_id = () => {
    dispatch(syncPerson({ tmdb_id: tmdb_idPerson }));
    toggle();
    setTmdb_idPerson('');
  };

  const isDisable = () => {
    if(setTmdb_idPerson.length > 0) {

    }
  }
  const isInputEmpty = tmdb_idPerson === '';


  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <ToastContainer />
      <div className='mt-4'>
        <Container>
          {isEdit && editId != "" ?
            <EditCastCrew personId={editId} handleNoEdit={handleNoEdit} />
            :
            <Card>
              <CardBody >
                <div className='media'>
                  <h3>
                    Cast & Crew - Listing
                  </h3>
                  <Col xs="12" lg="4">
                    <Button color="success" className='w-100 mb-2'
                      onClick={toggle}
                    >
                      Sync Person From TMDB
                    </Button>
                  </Col>
                  <Col sm="5" className='pb-2'>
                    <Row className='p-0 m-0'>
                      <Col sm="10">
                        <Col sm={12} className="p-0 search-icon-box">
                          <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                          <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                      </Col>
                      <Col sm="2">
                        <Link to={`/admin/addperson`} >
                          <Button color='success' >Add</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </div>
                <Container className='table'>
                  <Table hover className="align-items-center table-flush">
                    <thead className='' >
                      <tr>
                        <th className='table_heading'>S No.</th>
                        <th className='table_heading'>
                          Image
                        </th>
                        <th className='table_heading'>
                          Department
                        </th>
                        <th className='table_heading'>
                          Name
                        </th>
                        <th className='table_heading'>
                          Birthday
                        </th>
                        <th className='table_heading'>
                          Artwork
                        </th>
                        <th className='table_heading'>
                          TMDB ID
                        </th>
                        <th className='table_heading'>
                          TMDB Popularity
                        </th>
                        <th className='table_heading'>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {personSearchResult && personSearchResult.data && personSearchResult.data.results && personSearchResult.data.results.length > 0 ? personSearchResult.data.results.map((x, i) => {
                        return <tr key={i}>
                          <td>
                            {(pageLimit * (currentPage - 1)) + (i + 1)}
                          </td>
                          <td className='crewImg'>
                            {x.profile_image && x.profile_image != "" && x.profile_image != null ?
                              <img
                                alt="..."
                                className="rounded shadow-lg pointer"
                                src={x.profile_image ? common.getFullUrl(x.profile_image) : ''}
                                onClick={() => {
                                  setLightImage(common.getFullUrl(x.profile_image));
                                  setTimeout(() => {
                                    setLightBoxOpen(true);
                                  }, 100)
                                }}
                              ></img>
                              :
                              <div className='d-img'>
                                <i className='fas fa-image'></i>
                              </div>
                            }
                          </td>
                          <td>
                            {x.department}
                          </td>
                          <td>
                            {x.name}
                          </td>
                          <td>
                            {x.birthday && x.birthday ? x.birthday : 'N/A'}
                          </td>
                          <td className='crewImg'>
                            {x.artwork && x.artwork != "" && x.artwork != null ?
                              <img
                                alt="..."
                                className="rounded shadow-lg pointer"
                                src={x.artwork ? common.getFullUrl(x.artwork) : ''}
                                onClick={() => {
                                  setLightImage(common.getFullUrl(x.artwork));
                                  setTimeout(() => {
                                    setLightBoxOpen(true);
                                  }, 100)
                                }}
                              ></img>
                              :
                              <div className='d-img'>
                                <i className='fas fa-image'></i>
                              </div>
                            }
                          </td>
                          <td>
                            {x.tmdb_id}
                          </td>
                          <td>
                            {x.tmdb_popularity}
                          </td>
                          <td >
                            <Row>
                              {/* <Link to={`/admin/editperson/${x.uuid}`} > */}
                              <Button
                                className=" btn-icon btn-simple"
                                color="info"
                                size="sm"
                                type="button"
                                style={{ marginRight: 10 }}
                                onClick={() => handleEdit(x.uuid)}
                              >
                                <i className="fas fa-pen pt-1"></i>
                              </Button>
                              {/* </Link> */}
                              <Button
                                className=" btn-icon btn-simple"
                                color="danger"
                                size="sm"
                                type="button"
                                onClick={() => { if (window.confirm("Are you sure want to delete this ?")) { handleDelete(x.uuid) } }}
                              >
                                <i className="fas fa-trash pt-1"></i>
                              </Button>
                            </Row>
                          </td>
                        </tr>
                      })
                        :
                        <tr>
                          <td colSpan={10} className='text-center'>
                            <h3>
                              No Data Available
                            </h3>
                          </td>
                        </tr>
                      }

                    </tbody>
                  </Table>
                  {personSearchResult && personSearchResult.data && personSearchResult.data.pagination &&
                    <CustomPagination
                      pagination={personSearchResult.data && personSearchResult.data.pagination && personSearchResult.data.pagination ? personSearchResult.data.pagination : {}}
                      setCurrentPage={(val) => setCurrentPage(val)}
                      setPageLimit={(val) => setPageLimit(val)}
                      defaultPageList={personSearchResult.data && personSearchResult.data.results ? personSearchResult.data.results.length : 10}
                    />
                  }
                </Container>
              </CardBody>
            </Card>
          }
          {lightBoxOpen &&
            <Lightbox
              mainSrc={[lightImage]}
              onCloseRequest={() => setLightBoxOpen(false)}
              mainSrcThumbnail={[lightImage]}
            />
          }
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader style={{ padding: '0.5rem' }}  >
            <Col xs="12" style={{ display: 'flex' }}>
              <p className="h3">Sync Person From TMDB</p>
              <button onClick={toggle} style={{marginLeft:'230px', border:'0px', backgroundColor:'white', fontSize:'20px'}}>&times;</button>
            </Col>
          </ModalHeader>
          <ModalBody style={{ padding: '1rem', paddingTop: 0 }}>
            <Col xs="12">
              <Input onChange={(e) => setTmdb_idPerson(e.target.value)} value={tmdb_idPerson} type="text" name="tmdb_id" id="exampleSddearch" placeholder="Enter TMDB id" />
            </Col>
            <Col className='mt-20'>
              <Button onClick={Submit_tmdb_id} style={{ width: '100%' }} color="primary">
                Submit
              </Button>
            </Col>
          </ModalBody>
        </Modal>
      </div>
    </>
  )
}
