import React from 'react'
import Header from "components/Headers/Header.js";
import { Container, Card, Row, Col, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, Input, Spinner, Pagination, PaginationItem, PaginationLink, CustomInput, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'
import { userSearch } from "../../redux/actions/search.action"
import { getPointsList, updateFrenziPoints } from "../../redux/actions/frenziPoint.action"
import { activateDeactivateUsers, clearMessage, reportUser } from "../../redux/actions/user.action"
import times from "lodash/times";
import CustomPagination from 'components/Pagination/CustomPagination';
import { ToastContainer, toast } from 'react-toastify';
// import UserProfile from './UserProfile';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

const list = [1, 2, 3, 4, 5, 6];
const style1 = { width: "80px", height: "70px" };

export default function FrenziPoints() {
    const dispatch = useDispatch()
    const [frenziPointList, setFrenziPointList] = React.useState([])
    const [isOpen, setIsOpen] = React.useState(false)
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [checked, setChecked] = React.useState(true);
    const [isShowProfile, setIsShowProfile] = React.useState(false);
    const [profileId, setProfileId] = React.useState('');
    const [searchString, setSearchString] = React.useState('');
    const [sortType, setSortType] = React.useState('');
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const [isEditPoints, setIsEditPoints] = React.useState(false);
    const [editPointsId, setEditPointsId] = React.useState('');
    const [editPoints, setEditPoints] = React.useState('');


    const frenziPointListData = useSelector((state) => state.frenziPoint && state.frenziPoint.frenziPointList ? state.frenziPoint.frenziPointList : {})
    const isLoading = useSelector((state) => state.frenziPoint && state.frenziPoint.isLoading ? state.frenziPoint.isLoading : false)
    const errMesg = useSelector((state) => state.frenziPoint && state.frenziPoint.errMesg ? state.frenziPoint.errMesg : '')
    const status = useSelector((state) => state.frenziPoint && state.frenziPoint.status ? state.frenziPoint.status : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        dispatch(getPointsList(searchString, currentPage, pageLimit));
    }, [currentPage, pageLimit, searchString])

    React.useEffect(() => {
        if (frenziPointListData && frenziPointListData.data && frenziPointListData.data.results && frenziPointListData.data.results.length > 0) {
            setFrenziPointList(removeDuplicate([...frenziPointList, ...frenziPointListData.data.results]))
        }
    }, [frenziPointListData])

    const handleActiveInactive = (id, val) => {
        var body = {
            is_active: val
        }
        dispatch(activateDeactivateUsers(id, body));
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            setIsEditPoints(false);
            dispatch(getPointsList(searchString, sortType, currentPage, pageLimit));
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])


    const removeDuplicate = (array) => {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }

    const handleEditPoints = (x) => {
        ReactGA.modalview(window.location.pathname + "?modal=Edit Points");
        setIsEditPoints(true);
        setEditPointsId(x.uuid);
        setEditPoints(x.frenzi_points);
    }

    const handleSavePoints = () => {
        const body = {
            frenzi_points: editPoints
        }
        eventReporting('Frenzi Points', 'Frenzi Points updated - ' + editPoints, editPointsId);
        dispatch(updateFrenziPoints(editPointsId, body));
    }

    return (
        <>
            <Header />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLoading && <Loader />}
            <Container>
                {isShowProfile && profileId != "" ?
                    <Card className='mt-4 p-4 rounded-lg'>
                        <Col sm={4} className="p-0 pl-3 search-icon-box">
                            <Row>
                                <Button
                                    className="btn-icon btn-simple text-center"
                                    color="secondary"
                                    size="sm"
                                    type="button"
                                    style={{ minWidth: 40 }}
                                    onClick={() => {
                                        setProfileId('');
                                        setIsShowProfile(false);
                                    }}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                                <Row className='h-100 justify-content-center p-0 m-0'>
                                    <h3 className='p-0 m-0'>User Detail</h3>
                                </Row>
                            </Row>
                        </Col>
                        {/* <UserProfile id={profileId} /> */}
                    </Card>
                    :
                    <Card className='mt-4 p-4 rounded-lg'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <h3 className='mb-3'>Users List</h3>
                        </Col>
                        <Row className='justify-content-between m-0 '>
                            <Col sm={6} className="p-0 search-icon-box">
                                <Row className='justify-content-between m-0 '>
                                    <Col className="p-0 search-icon-box">
                                        <Input onChange={(e) => setSearchString(e.target.value)} placeholder='Search' />
                                        <i className="fas fa-search"></i>
                                    </Col>
                                    <div style={{ marginLeft: 20 }}>
                                        {/* <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                Sort By
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                <DropdownItem>Frenzi Points</DropdownItem>
                                                <DropdownItem>Deactivate Users</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown> */}
                                        {(sortType != "" || searchString != "") &&
                                            <Button onClick={() => {
                                                setCurrentPage(1);
                                                setSortType('');
                                                setSearchString('');
                                            }} color='primary'>Reset</Button>
                                        }
                                    </div>
                                </Row>
                            </Col>
                            <Button color="info" onClick={() => setIsAddPlatform(!isAddPlatform)} >Download CSV</Button>

                        </Row>
                        <Container className='table' style={{ overflowY: 'hidden' }}>
                            <Table hover className='mt-5'>
                                <thead>
                                    <tr>
                                        <th className='table_heading' width="7%">
                                            S.No.
                                        </th>
                                        <th className='table_heading'>
                                            Profile
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "username" ? "-username" : 'username');
                                        }} className='table_heading pointer'>
                                            Name
                                            {sortType == "username" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-username" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "email" ? "-email" : 'email');
                                        }} className='table_heading pointer'>
                                            Email ID
                                            {sortType == "email" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-email" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th className='table_heading'>
                                            User ID
                                        </th>
                                        <th className='table_heading'>
                                            View
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "frenzi_points" ? "-frenzi_points" : 'frenzi_points');
                                        }} className='table_heading pointer'>
                                            Frenzi Points
                                            {sortType == "frenzi_points" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-frenzi_points" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th className='table_heading'>
                                            Record Points
                                        </th>
                                        <th className='table_heading'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='vertical-middle'>
                                    {isLoading === true && (!frenziPointList || !frenziPointList.length) ?
                                        <tr>
                                            <td colSpan={9} className='text-center'>
                                                <Spinner color="info" />
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {frenziPointListData.data && frenziPointListData.data.results && frenziPointListData.data.results.length > 0 ? frenziPointListData.data.results.map((x, i) => {
                                                return <tr>
                                                    <td scope="row">
                                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        <img style={{ maxWidth: 70, borderRadius: 7 }} src={x.profile_image ? x.profile_image : ''} />
                                                    </td>
                                                    <td>
                                                        {x.username}
                                                    </td>
                                                    <td>
                                                        {x.email}
                                                    </td>
                                                    <td>
                                                        {x.unique_name}
                                                    </td>
                                                    <td>
                                                        <Link to={`/admin/points-details/${x.uuid}`}>
                                                            <Button size='sm' color='success'>View</Button>
                                                        </Link>
                                                    </td>
                                                    <td className='text-center'>
                                                        {/* <input type="number" name="frenzi_points" value={x.frenzi_points} style={{ width: '70px' }} /> */}
                                                        {x.frenzi_points}
                                                    </td>
                                                    <td className='text-center'>
                                                        {/* <input type="number" name="frenzi_points" value={x.frenzi_points} style={{ width: '70px' }} /> */}
                                                        {x.points_in_record}
                                                    </td>
                                                    <td>
                                                        <Button size='sm' onClick={() => handleEditPoints(x)} color='danger'>
                                                            <i className='fas fa-pen pt-1'></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })
                                                :
                                                <tr>
                                                    <td colSpan={9} className='text-center'>
                                                        <h3>
                                                            No Data Available
                                                        </h3>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                    }
                                </tbody>
                            </Table>
                            <div className='text-right mt-2'>
                                {frenziPointListData.data && frenziPointListData.data.pagination &&
                                    <CustomPagination
                                        pagination={frenziPointListData.data && frenziPointListData.data.pagination && frenziPointListData.data.pagination ? frenziPointListData.data.pagination : {}}
                                        setCurrentPage={(val) => setCurrentPage(val)}
                                        setPageLimit={(val) => setPageLimit(val)}
                                        defaultPageList={frenziPointListData.data && frenziPointListData.data.results ? frenziPointListData.data.results.length : 20}
                                    />
                                }
                            </div>
                        </Container>
                    </Card>
                }

                <Modal
                    isOpen={isEditPoints}
                    toggle={() => setIsEditPoints(!isEditPoints)}
                >
                    <ModalHeader toggle={() => setIsEditPoints(!isEditPoints)}>
                        Edit points
                    </ModalHeader>
                    <ModalBody>
                        <div className='w-100'>
                            <Input
                                type="number"
                                className='w-100'
                                placeholder='Frenzi Points'
                                value={editPoints}
                                onChange={(e) => setEditPoints(e.target.value)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            onClick={() => handleSavePoints()}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    )
}
