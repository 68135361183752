import Header from 'components/Headers/Header'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Button, Card, CardText, CardTitle, Col, Container, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap'
import Loader from 'views/common/Loader'
import BlogContent from './BlogContent'
import Categories from './Categories'
import CreateBlog from './CreateBlog'
import EditBlog from './EditBlog'
import SubCategories from './SubCategories'

const TABS = {
    CATEGORY: 'CATEGORY',
    SUBCATEGORY: 'SUBCATEGORY',
    BLOGS: 'BLOGS',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
}

export default function Blogs() {
    const [activeTab, setActiveTab] = useState(TABS.CATEGORY);
    const [tabName, setTabName] = useState('Edit');
    const [editId, setEditId] = useState('');

    const handleTabs = (tab) => {
        setActiveTab(tab);
    }

    const handleEdit = (name, id) => {
        setEditId(id);
        setActiveTab(TABS.EDIT);
        setTabName(name);
    }

    return (
        <>
            <Header />
            <ToastContainer />
            {/* {isVideoLoading && <Loader />} */}
            <div>
                <Container>
                    <Card className='mt-4 p-3 rounded-lg'>
                        <div>
                            <Nav tabs>
                                <NavItem className={`pointer my-tab-link ${TABS.CATEGORY === activeTab ? 'active' : ''} p-2`} onClick={() => handleTabs(TABS.CATEGORY)}>
                                    Category
                                </NavItem>
                                <NavItem className={`pointer my-tab-link ${TABS.SUBCATEGORY === activeTab ? 'active' : ''} p-2`} onClick={() => handleTabs(TABS.SUBCATEGORY)}>
                                    Subcategory
                                </NavItem>
                                <NavItem className={`pointer my-tab-link ${TABS.BLOGS === activeTab ? 'active' : ''} p-2`} onClick={() => handleTabs(TABS.BLOGS)}>
                                    Blogs
                                </NavItem>
                                <NavItem className={`pointer my-tab-link ${TABS.CREATE === activeTab ? 'active' : ''} p-2`} onClick={() => handleTabs(TABS.CREATE)}>
                                    Create New Blog
                                </NavItem>
                                {TABS.EDIT === activeTab &&
                                    <NavItem className={`pointer my-tab-link ${TABS.EDIT === activeTab ? 'active' : ''} p-2`} onClick={() => handleTabs(TABS.EDIT)}>
                                        {tabName}
                                    </NavItem>
                                }
                            </Nav>
                            <TabContent className='pt-3' activeTab={activeTab}>
                                <TabPane tabId={TABS.CATEGORY}>
                                    <Categories />
                                </TabPane>
                                <TabPane tabId={TABS.SUBCATEGORY}>
                                    <SubCategories />
                                </TabPane>
                                <TabPane tabId={TABS.BLOGS}>
                                    <BlogContent handleCreate={() => setActiveTab(TABS.CREATE)} handleEdit={(name, id) => handleEdit(name, id)} />
                                </TabPane>
                                <TabPane tabId={TABS.CREATE}>
                                    <CreateBlog tabId={TABS.CREATE + parseInt(Math.random() * 100000)} handleDone={() => setActiveTab(TABS.BLOGS)} />
                                </TabPane>
                                {TABS.EDIT === activeTab &&
                                    <TabPane tabId={TABS.EDIT}>
                                        <EditBlog editId={editId} />
                                    </TabPane>
                                }
                            </TabContent>
                        </div>
                    </Card>
                </Container>
            </div>
        </>
    )
}
