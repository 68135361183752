import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, CustomInput, Table, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import './_subscriptions.scss';
import CustomPagination from 'components/Pagination/CustomPagination';
import ReactGA from 'react-ga';

const list = [
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/altbalaji.png",
        name: "ALT Balaji"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/amazo_pay.png",
        name: "Amazon Pay"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Amazon Prime"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Book My Show"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Amazon Prime"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Book My Show"
    }

]

export default function PlatformPriceManagement() {
    const [isEditOrAdd, setIsEditOrAdd] = React.useState(false);
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [editId, setEditId] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false)
    const [isOpen1, setIsOpen1] = React.useState(false)
    const [isOpen2, setIsOpen2] = React.useState(false)
    const [actionType, setActionType] = React.useState('');


    const inputFileRef = React.useRef(null);

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleEdit = (id) => {
        setEditId(id);
        setActionType('edit')
        setIsEditOrAdd(true);
        ReactGA.modalview(window.location.pathname + '?modal=Edit Platform Price');
    }

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>Platform Price Management</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' />
                            <i className="fas fa-search"></i>
                        </Col>
                        <Button color="info" onClick={() => {
                            setIsEditOrAdd(!isEditOrAdd);
                            setActionType('add');
                            ReactGA.modalview(window.location.pathname + '?modal=Add Platform Price');
                        }} >Add Platform Price</Button>
                    </Row>
                    <div className='overflow-auto'>
                        <Table hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th width="7%">
                                        S. No.
                                    </th>
                                    <th>
                                        Logo
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Plan Type
                                    </th>
                                    <th>
                                        Currency
                                    </th>
                                    <th>
                                        Price
                                    </th>
                                    <th>
                                        Discount
                                    </th>
                                    <th>
                                        Frenzi Points
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((x, i) => {
                                    return <tr>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img className='platform-img' src={x.image} />
                                        </td>
                                        <td>
                                            {x.name}
                                        </td>
                                        <td>
                                            Monthly
                                        </td>
                                        <td>
                                            INR
                                        </td>
                                        <td>
                                            100
                                        </td>
                                        <td>
                                            0
                                        </td>
                                        <td>
                                            4000
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    className=''
                                                    label="Active"
                                                // onChange={(e) => console.log(e.target.checked) }
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                style={{ marginRight: 10 }}
                                                onClick={() => handleEdit(i)}
                                            >
                                                <i className="fas fa-pen pt-1"></i>
                                            </Button>

                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <CustomPagination total={list.length} result={1} />
                    <Modal
                        isOpen={isEditOrAdd}
                        toggle={() => setIsEditOrAdd(!isEditOrAdd)}
                    >
                        <ModalHeader toggle={() => setIsEditOrAdd(!isEditOrAdd)}>
                            <h3>{actionType == 'edit' ? 'Edit' : 'Add'} Platfrom Price</h3>
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Plan Type</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                Option
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {list.map((y, j) => {
                                                    return <DropdownItem>
                                                        {y.name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Currency</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen1} toggle={() => setIsOpen1(!isOpen1)}>
                                            <DropdownToggle className='w-100' caret>
                                                Option
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {list.map((y, j) => {
                                                    return <DropdownItem>
                                                        {y.name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen2} toggle={() => setIsOpen2(!isOpen2)}>
                                            <DropdownToggle className='w-100' caret>
                                                Option
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {list.map((y, j) => {
                                                    return <DropdownItem>
                                                        {y.name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Price</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Input />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Discount</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Input />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Frenzi Points</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Input />
                                    </div>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                onClick={() => setIsEditOrAdd(!isEditOrAdd)}
                            >
                                {actionType == 'edit' ? 'Upddate' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
