import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import { changeAdminPassword, clearMessage } from "../../redux/actions/admin.action"
import { logout } from '../../redux/actions/auth.action'
import ReactGA from 'react-ga';


export default function ChangePassword(props) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [cnfPassword, setCnfPassword] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const status = useSelector((state) => state.adminAccess && state.adminAccess.status ? state.adminAccess.status : '')
    const isLoading = useSelector((state) => state.adminAccess && state.adminAccess.isLoading ? state.adminAccess.isLoading : false)
    const errMesg = useSelector((state) => state.adminAccess && state.adminAccess.errMesg ? state.adminAccess.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }


    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOldPassword('');
            setNewPassword('');
            setCnfPassword('');
            dispatch(clearMessage());
            setTimeout(() => {
                dispatch(logout(props.history));
            }, 1000)
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    const handleSave = () => {
        var body = {
            confirm_password: cnfPassword,
            new_password: newPassword,
            old_password: oldPassword
        }
        if (newPassword === cnfPassword) {
            eventReporting('Change Password', "Password changed -", 0);
            dispatch(changeAdminPassword(body));
        } else {
            toast.error(("New password and Confirm password doesn't match"), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <>
            <ToastContainer />
            <Container>
                <Card className='mt-2 pb-3 p-3 '>
                    <Row className='justify-content-between m-0'>
                        <Col className="p-0 search-icon-box">
                            <h3 className='mb-3'>Change Password</h3>
                        </Col>
                        <Col className='text-right'>
                            <Button color="darker" onClick={() => history.goBack()} >
                                <i className="fas fa-arrow-left" />
                            </Button>
                        </Col>
                    </Row>
                    <CardBody className='mt-2 mb-2 p-0'>
                        <Col sm={6}>
                            <Row>
                                <Input className='mb-3'
                                    value={oldPassword}
                                    type={'password'}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    placeholder='Old Password' />
                                <Input className='mb-3'
                                    value={newPassword}
                                    type={'password'}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder='New Password' />
                                <Input className='mb-3'
                                    value={cnfPassword}
                                    type={'password'}
                                    onChange={(e) => setCnfPassword(e.target.value)}
                                    placeholder='Confirm Password' />
                                <Button
                                    onClick={() => handleSave()}
                                    color='success' >Change Password</Button>
                            </Row>
                        </Col>
                    </CardBody>
                </Card>
            </Container>
        </>
    )
}
