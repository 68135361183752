import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    bundleListResult: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
};

export const bundleReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.BUNDLE_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BUNDLE_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.BUNDLE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BUNDLE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                bundleListResult: payload,
            };
        case WEB.BUNDLE_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.BUNDLE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BUNDLE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Bundle Added",
            };
        case WEB.BUNDLE_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.BUNDLE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BUNDLE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Bundle Deleted",
            };
        case WEB.BUNDLE_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        default:
            return state;

    }
}