import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { mediaLanguageSelect } from "../../redux/actions/media.action"
import { inBetweenFeed, inBetweenFeedType, updateMediaNews, clearMessage, addMediaNews, addFeed } from "../../redux/actions/managePage.action"
import AWS from 'aws-sdk'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import helpers from 'helpers/common';
import Select, { components } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

export default function AddEditNews(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();

    const [isOpen, setIsOpen] = React.useState(false)
    const [isAddFeed, setIsAddFeed] = React.useState(false)
    const [newFeedName, setNewFeedName] = React.useState('')
    const [editId, setEditId] = React.useState('')
    const [dopDownTitle, setDropDownTitle] = React.useState('')
    const [isEdit, setIsEdit] = React.useState(false)
    const [inBetweenFeedDetails, setInBetweenFeedDetails] = React.useState({})

    const inputFileRef = React.useRef(null);
    const [languageSelect, setLanguageSelect] = React.useState('')

    const languageDropdownResult = useSelector((state) => state.media && state.media.languageDropdownResult ? state.media.languageDropdownResult : {})
    const inBetweenFeedTypeData = useSelector((state) => state.media && state.managePage.inBetweenFeedType ? state.managePage.inBetweenFeedType : {})
    const inBetweenFeedData = useSelector((state) => state.media && state.managePage.inBetweenFeed ? state.managePage.inBetweenFeed : {})
    const isLoading = useSelector((state) => state.media && state.managePage.isLoading ? state.managePage.isLoading : false)
    const status = useSelector((state) => state.media && state.managePage.status ? state.managePage.status : '')
    const errMesg = useSelector((state) => state.media && state.managePage.errMesg ? state.managePage.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            if (status == "News Added") {
                history.goBack();
            }
            if (status == "In Between Feed Data for news") {
                dispatch(inBetweenFeedType());
            }
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    React.useEffect(() => {
        var editId = props && props.match && props.match.params && props.match.params.id ? props.match.params.id : '';
        if (editId != "") {
            setEditId(editId);
            setIsEdit(true)
        } else {
            setEditId('');
            setIsEdit(false)
        }
    }, [])

    React.useEffect(() => {
        dispatch(mediaLanguageSelect());
        dispatch(inBetweenFeedType());
        if (id && id != "") {
            dispatch(inBetweenFeed(id));
        }
    }, [])

    React.useEffect(() => {
        if (inBetweenFeedData && inBetweenFeedData.data && inBetweenFeedData.data.uuid && inBetweenFeedData.data.uuid == id) {
            setInBetweenFeedDetails({ ...inBetweenFeedData.data });
            var fD = inBetweenFeedTypeData && inBetweenFeedTypeData.data && inBetweenFeedTypeData.data.length > 0 && inBetweenFeedTypeData.data.filter((item) => item.uuid == inBetweenFeedData.data.bw_feed_data)
            setDropDownTitle(fD && fD[0] && fD[0].bw_feed_title ? fD[0].bw_feed_title : '');
        }
    }, [inBetweenFeedData, inBetweenFeedTypeData])

    const getLangOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: x.code,
                label: x.lang ? x.lang : x.language ? x.language : 'N/A',
            });
        })
        return temp;
    }

    const getLangOptionValue = (mainData, ottArr) => {
        var result = [];
        if (ottArr && ottArr.length > 0) {
            ottArr.map((x, i) => {
                var data = mainData.data && mainData.data.length > 0 && mainData.data.filter(item => item.code == x)
                result.push({
                    value: x,
                    label: data && data[0] && data[0].lang ? data[0].lang : data[0].language && data[0].language ? data[0].language : x,
                });
            })
        }
        return result;
    }

    const handlePosterChange = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "news_media_poster/news-media-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Manage Page', "Uploaded file to s3 bucket -" + keyName, data.Location);
                setInBetweenFeedDetails({
                    ...inBetweenFeedDetails,
                    ['poster']: data.Location
                })
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setInBetweenFeedDetails({
            ...inBetweenFeedDetails,
            [name]: value
        })
    }

    const handleUpdateSave = (type) => {
        if (type == 'Save') {
            dispatch(addMediaNews(inBetweenFeedDetails));
            eventReporting('Manage Page', 'News added - ', inBetweenFeedDetails.name);
        } else {
            if (inBetweenFeedDetails.uuid == id) {
                eventReporting('Manage Page', 'News updated - ' + inBetweenFeedDetails.name, id);
                dispatch(updateMediaNews(id, inBetweenFeedDetails));
            }
        }
    }

    const handleSaveFeed = () => {
        const body = {
            bw_feed_title: newFeedName
        }
        eventReporting('Manage Page', 'News title updated - ' + newFeedName, newFeedName);
        dispatch(addFeed(body))
        if (!isLoading) {
            setIsAddFeed(false);
            dispatch(inBetweenFeedType());
        }
    }


    return (
        <>
            <Header />
            <ToastContainer />
            {isLoading && <Loader />}
            <Container>
                <Card className='mt-4 p-3'>
                    <Row className='justify-content-between pt-3 px-3'>
                        <h3 className='mb-3'>News</h3>
                        <div>
                            <Link to="/admin/admin">
                                <Button color="darker" onClick={() => history.goBack()} >
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            </Link>
                        </div>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Feed Data</p>
                        </Col>
                        <Col sm={3}>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                    <DropdownToggle className='w-100' caret>
                                        {dopDownTitle ? dopDownTitle : 'Select Feed'}
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {inBetweenFeedTypeData && inBetweenFeedTypeData.data && inBetweenFeedTypeData.data.length > 0 && inBetweenFeedTypeData.data.map((y, j) => {
                                            return <DropdownItem onClick={() => {
                                                setInBetweenFeedDetails({
                                                    ...inBetweenFeedDetails,
                                                    ['bw_feed_data']: y.uuid
                                                })
                                                setDropDownTitle(y.bw_feed_title)
                                            }}>
                                                {y.bw_feed_title}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col sm={7} style={{ textAlign: 'right' }}>
                            <Button color="info" onClick={() => {
                                setIsAddFeed(true);
                                ReactGA.modalview(window.location.pathname + "?modal=AddFeed");
                            }}>Add Feed Data</Button>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Title</p>
                        </Col>
                        <Col sm={7}>
                            <Input className='w-100' value={inBetweenFeedDetails.news_title ? inBetweenFeedDetails.news_title : ''} name="news_title" onChange={handleChangeInput} />
                        </Col>
                        <Col sm={3} >
                            <Row className='text-right pl-3 pr-3 justify-content-end' >
                                <span>Is Blog</span>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className='ml-2'
                                    checked={inBetweenFeedDetails.is_blog}
                                    onChange={(e) => {
                                        setInBetweenFeedDetails({
                                            ...inBetweenFeedDetails,
                                            ['is_blog']: e.target.checked
                                        })
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Url</p>
                        </Col>
                        <Col sm={7}>
                            <Input className='w-100' value={inBetweenFeedDetails.news_url ? inBetweenFeedDetails.news_url : ''} name="news_url" onChange={handleChangeInput} />
                        </Col>
                        <Col sm={3} >
                            <Row className='text-right pl-3 pr-3 justify-content-end' >
                                <span>Status</span>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className='ml-2'
                                    checked={inBetweenFeedDetails.is_active}
                                    onChange={(e) => {
                                        setInBetweenFeedDetails({
                                            ...inBetweenFeedDetails,
                                            ['is_active']: e.target.checked
                                        })
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Description</p>
                        </Col>
                        <Col sm={7}>
                            <textarea className='w-100' value={inBetweenFeedDetails.news_summary ? inBetweenFeedDetails.news_summary : ''} name="news_summary" onChange={handleChangeInput} />
                        </Col>
                        <Col sm={3} >
                            <Row className='text-right pl-3 pr-3 justify-content-end' >
                                <span>Append User Id</span>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch2"
                                    name="customSwitch2"
                                    className='ml-2'
                                    checked={inBetweenFeedDetails.add_user_uuid}
                                    onChange={(e) => {
                                        setInBetweenFeedDetails({
                                            ...inBetweenFeedDetails,
                                            ['add_user_uuid']: e.target.checked
                                        })
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Poster</p>
                        </Col>
                        <Col sm={1}>
                            <input ref={inputFileRef} onChange={(e) => handlePosterChange(e)} className='d-none' type={'file'} />
                            <Button
                                className=" btn-icon btn-simple"
                                color="info"
                                size="sm"
                                type="button"
                                onClick={() => inputFileRef.current.click()}
                            >
                                <i className="fas fa-upload pt-1"></i>
                            </Button>
                        </Col>
                        <Col sm={3} >
                            {isEdit &&
                                <img className='border' style={{ width: 200 }} src={inBetweenFeedDetails.poster ? helpers.getFullUrl(inBetweenFeedDetails.poster) : ''} />
                            }
                        </Col>
                    </Row>

                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Language</p>
                        </Col>
                        <Col sm={10}>
                            <div className="w-100">
                                <Select
                                    isMulti
                                    // closeMenuOnSelect={false}
                                    // components={animatedComponents}
                                    options={
                                        getLangOptions(languageDropdownResult)
                                    }
                                    value={
                                        getLangOptionValue(languageDropdownResult, inBetweenFeedDetails.language)
                                    }
                                    onChange={(e) => {
                                        setInBetweenFeedDetails({
                                            ...inBetweenFeedDetails,
                                            ['language']: e.length > 0 ? e.map(x => x.value) : []
                                        })
                                    }

                                    }
                                    // onChange={(e) => handleSelectOnchangeFormaData(e, 'data')}
                                    components={components}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-5 mb-3'>
                        <Link to="/admin/media_news">
                            <Button color="danger" className='mr-3' >Cancel</Button>
                        </Link>
                        <Button onClick={() => handleUpdateSave(isEdit ? 'Update' : 'Save')} color="success" >{isEdit ? 'Update' : 'Save'}</Button>
                    </Row>
                </Card>

                <Modal
                    isOpen={isAddFeed}
                    toggle={() => setIsAddFeed(!isAddFeed)}
                >
                    <ModalHeader toggle={() => setIsAddFeed(!isAddFeed)}>
                        Add Feed
                    </ModalHeader>
                    <ModalBody>
                        <div className='w-100'>
                            <Input
                                type="text"
                                className='w-100'
                                placeholder='Feed Name'
                                onChange={(e) => setNewFeedName(e.target.value)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            onClick={() => handleSaveFeed()}
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    )
}
