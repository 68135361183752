import React from "react";
import Header from "components/Headers/Header.js"
import { useHistory } from "react-router-dom";

import {
    Form,
    FormGroup,
    Input,
    CardBody,
    Col,
    Card,
    Row,
    Container,
    Button

} from "reactstrap";

const Newplaylist = () => {

    const history = useHistory();
  
    const routeChange = () =>{ 
      let path = `/admin/curatemanually`; 
      history.push(path);
    }

    return (
        <>
            <Header />
            <Container className="mt-5">
                <Card>
                    <Form className="mt-3">
                        <Col xl="4">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-thumbnail"
                                >
                                    Thumbnail
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-thumbnail"
                                    type="file"
                                />
                            </FormGroup>
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-playlist-name"
                                >
                                    Playlist Name
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-thumbnail"
                                    type="text"
                                    placeholder="Playlist Name"
                                />
                            </FormGroup>
                            <FormGroup>

                                <label
                                    className="form-control-label"
                                    htmlFor="input-playlist-name"
                                >
                                    Curated Media
                                </label>
                                <p> 'X' media curated automatically </p>
                                <Button
                                    color="secondary"
                                    //href="#pablo"
                                    onClick={routeChange}
                                    size="sm"
                                >
                                    Edit Media
                                </Button>
                            </FormGroup>
                            <Button
                                    color="primary"
                                    href="#pablo"
                                    //onClick={(e) => e.preventDefault()}
                                    size="lg"
                                >
                                    Publish Playlist
                                </Button>
                        </Col>
                    </Form>
                </Card>
            </Container>
        </>
    )
}
export default Newplaylist;