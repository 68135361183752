import Header from 'components/Headers/Header'
import React, { useState, useEffect } from 'react'
import { Button, Card, Container, Table, Modal, ModalHeader, CustomInput, Row, Col, Label, Input } from 'reactstrap'
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { getAdvList } from 'redux/actions/advertisement.action';
import { deleteAdvType } from 'redux/actions/advertisement.action';
import { addAdvType } from 'redux/actions/advertisement.action';
import { ToastContainer, toast } from 'react-toastify';
import { clearMessage } from 'redux/actions/advertisement.action';
import Loader from 'views/common/Loader';
import { updateAdvType } from 'redux/actions/advertisement.action';
import Select, { components } from 'react-select';

const defaultFormData = {
    adv_type: '',
    is_active: true,
    is_web: false,
}

export default function ToggleAds() {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [advType, setAdvType] = useState({ label: 'All', value: 'all' });
    const [saveFormData, setSaveFormData] = useState(defaultFormData);

    const advStatList = useSelector((state) => state.advertisement && state.advertisement.advStatList ? state.advertisement.advStatList : {});

    const isLoading = useSelector((state) => state.advertisement && state.advertisement.isLoading ? state.advertisement.isLoading : false)
    const status = useSelector((state) => state.advertisement && state.advertisement.status ? state.advertisement.status : '')
    const errMesg = useSelector((state) => state.advertisement && state.advertisement.errMesg ? state.advertisement.errMesg : '')


    useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(getAdvList());
            setIsOpen(false);
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg])


    useEffect(() => {
        dispatch(getAdvList());
    }, [])

    const handleSave = () => {
        if (saveFormData.adv_type != '') {
            dispatch(addAdvType(saveFormData));
        }
    }

    const handleAdd = () => {
        setSaveFormData(defaultFormData);
        setIsOpen(true);
    }

    const handleDelete = (type) => {
        if (window.confirm('Do you really want to delete this type?')) {
            dispatch(deleteAdvType(type));
        }
    }

    const handleUpdate = (value, id, name, is_web) => {
        const body = { [name]: value, is_web: is_web }
        dispatch(updateAdvType(id, body));
    }

    const handleUpdateIsWeb = (value, id, name, active) => {
        const body = { [name]: value, is_active: active }
        dispatch(updateAdvType(id, body));
    }

    return (
        <>
            {isLoading &&
                <Loader />
            }
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-4 pt-3 pb-3'>
                    <Container className='table'>
                        <h3 className='mb-3'>Toggle Ads</h3>
                        <Row className='mb-3'>
                            <Col xs={6} sm={3}>
                                <Select
                                    placeholder={"Adv Platform Type"}
                                    components={components}
                                    options={[
                                        { label: 'All', value: 'all' },
                                        { label: 'Mobile', value: 'mobile' },
                                        { label: 'Web', value: 'web' },
                                    ]}
                                    value={advType}
                                    onChange={(e) => {
                                        setAdvType(e);
                                    }}
                                />
                            </Col>
                            <Col>
                                <Button onClick={() => handleAdd()} color='info'>Add New</Button>
                            </Col>
                        </Row>
                        <Table hover className="align-items-center table-flush">
                            <thead>
                                <tr>
                                    <th width="9%">
                                        S. No.
                                    </th>
                                    <th>
                                        Adv Type
                                    </th>
                                    <th>
                                        Platform Type
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {advStatList.data && advStatList.data.length > 0 && advStatList.data.map((x, i) => {
                                    if (advType.value != 'all') {
                                        if (advType.value == 'mobile' && x?.is_web) {
                                            return false
                                        }
                                        if (advType.value == 'web' && !x?.is_web) {
                                            return false
                                        }
                                    }
                                    return <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{x?.adv_type}</td>
                                        <td>
                                            <Row>
                                                <Col style={{ maxWidth: '90px' }}>
                                                    {x?.is_web ? 'Web' : 'Mobile'}
                                                </Col>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustmWebSwitch-${x?.adv_type}`}
                                                    name="customSwitch"
                                                    className=''
                                                    checked={x?.is_web}
                                                    onChange={(e) => handleUpdateIsWeb(e.target.checked, x?.adv_type, 'is_web', x?.is_active)}
                                                />
                                                <span className='text-warning'>IS WEB</span>
                                            </Row>
                                        </td>
                                        <td>
                                            <Row>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCusActiveSwitch-${x?.adv_type}`}
                                                    name="customSwitch"
                                                    className=''
                                                    checked={x?.is_active}
                                                    onChange={(e) => handleUpdate(e.target.checked, x?.adv_type, 'is_active', x?.is_web)}
                                                />
                                                {x?.is_active ? <span className='text-success'>Active</span> : <span className='text-danger'>In-Active</span>}
                                            </Row>
                                        </td>
                                        <td>
                                            {/* <Button size='sm' color='success'>
                                                Edit
                                            </Button> */}
                                            <Button onClick={() => handleDelete(x?.adv_type)} size='sm' color='danger'>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </Container>
                </Card>

                <Modal
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                >
                    <ModalHeader className='p-3' toggle={() => setIsOpen(!isOpen)}>
                        Add New Advertisement Type
                    </ModalHeader>
                    <div className='pb-3'>
                        <Row className='pl-3 mt-0 pr-3 w-100'>
                            <Col xs={12} className='mb-3'>
                                <Label size='sm' className='m-0' >
                                    Adv Type
                                </Label>
                                <Input
                                    id="title"
                                    size={'sm'}
                                    placeholder='Title'
                                    onChange={(e) => setSaveFormData({ ...saveFormData, adv_type: e.target.value })}
                                    value={saveFormData.adv_type}
                                />
                            </Col>

                            <Col xs={3}>
                                <Label size='sm' className='m-0' >
                                    Is Active
                                </Label>
                                <CustomInput
                                    type="switch"
                                    id={`exampleCustomActiveSwitch`}
                                    name="customSwitch"
                                    className=''
                                    checked={saveFormData.is_active}
                                    onChange={(e) => setSaveFormData({ ...saveFormData, is_active: e.target.checked })}
                                />
                            </Col>
                            <Col xs={3}>
                                <Label size='sm' className='m-0' >
                                    Is Web
                                </Label>
                                <CustomInput
                                    type="switch"
                                    id={`exampleCustomWebSwitch`}
                                    name="customSwitch"
                                    className=''
                                    checked={saveFormData.is_web}
                                    onChange={(e) => setSaveFormData({ ...saveFormData, is_web: e.target.checked })}
                                />
                            </Col>

                        </Row>
                        <div style={{ textAlign: 'center' }} className='mt-4'>
                            <Button disabled={saveFormData.adv_type == ''} color="success" onClick={() => handleSave()} >Save</Button>
                        </div>
                    </div>
                </Modal>

            </Container>
        </>
    )
}
