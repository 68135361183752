import React from 'react'
import './_editMedia.scss'
import Header from "components/Headers/Header.js";
import {
    Card, Container, Form, FormGroup, Label, Col, Input,
    Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button,
    Modal, ModalHeader, ModalFooter, ModalBody, Spinner, CustomInput
} from 'reactstrap';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import MoviePoster from "../../assets/img/theme/team-2-800x800.jpg"
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mediaGenreSelect, mediaLanguageSelect, mediaOttSelect, mediaList, getMediaDetails, updateMediaDetails, clearMessage, getTagList, getMediaTagList, addRemoveTags, getMediaCastList, getMediaCrewList, getMediaProvidersList, getMediaCertificatesValues } from "../../redux/actions/media.action"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AWS from 'aws-sdk'
import { WithContext as ReactTags } from 'react-tag-input';
import common from '../../helpers/common';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';
import helpers from '../../helpers/common';
import EditMediaProviders from './EditMediaProviders';
import { deleteCXRecord } from 'redux/actions/operation.action';


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 10
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 8
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 6
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 4
    }
};

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const booleanOption = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
]

const booleanOption2 = [
    { value: '1', label: 'True' },
    { value: '0', label: 'False' },
]

const canWatchWithOption = [
    { value: 'kids', label: 'Kids' },
    { value: 'family', label: 'Family' },
]

const MultiValue = props => {
    const { data } = props;
    return <components.MultiValue {...props}>
        <img
            style={{
                width: 30,
                height: 25,
                borderRadius: 3,
                border: '0.5px solid #ddd'
            }}
            alt=''
            src={data.value.image ? data.value.image : ''}
        />
    </components.MultiValue>
};

export default function EditMedia() {
    const [tagSearchText, setTagSearchText] = React.useState('');
    const [tagSearchPage, setTagSearchPage] = React.useState(1);
    const [alreadySelectedTags, setAlreadySelectedTags] = React.useState([]);
    const [tagsList, setTagsList] = React.useState([]);
    const [currentMediaTagPage, setCurrentMediaTagPage] = React.useState(1);

    const [currentMediaCastPage, setCurrentMediaCastPage] = React.useState(1);
    const [currentMediaCrewPage, setCurrentMediaCrewPage] = React.useState(1);
    const [mediaCastDetails, setMediaCastDetails] = React.useState([]);
    const [mediaCrewDetails, setMediaCrewDetails] = React.useState([]);

    const animatedComponents = makeAnimated();
    const [mediaData, setMediaData] = React.useState({});
    const [addOrEdit, setAddOrEdit] = React.useState(false);
    const [moreBtnAdded, setMoreBtnAdded] = React.useState(false);
    const params = useParams();
    const dispatch = useDispatch();
    const components = { MultiValue };
    const posterInputChange = React.useRef(null);
    const history = useHistory();
    const location = useLocation();

    const mediaReducerData = useSelector((state) => state.media && state.media)
    const { mediaProvidersListData, certificatesList } = mediaReducerData;

    const mediaDetails = useSelector((state) => state.media && state.media.mediaDetails ? state.media.mediaDetails : {})
    const genreDropdownResult = useSelector((state) => state.media && state.media.genreDropdownResult ? state.media.genreDropdownResult : {})
    const languageDropdownResult = useSelector((state) => state.media && state.media.languageDropdownResult ? state.media.languageDropdownResult : {})
    const ottDropdownResult = useSelector((state) => state.media && state.media.ottDropdownResult ? state.media.ottDropdownResult : {})
    const tagDropdownResult = useSelector((state) => state.media && state.media.tagDropdownResult ? state.media.tagDropdownResult : {})
    const tagMediaResult = useSelector((state) => state.media && state.media.tagMediaResult ? state.media.tagMediaResult : {})
    const mediaCastList = useSelector((state) => state.media && state.media.mediaCastList ? state.media.mediaCastList : {})
    const mediaCrewList = useSelector((state) => state.media && state.media.mediaCrewList ? state.media.mediaCrewList : {})
    const status = useSelector((state) => state.media && state.media.status ? state.media.status : '')
    const errMesg = useSelector((state) => state.media && state.media.errMesg ? state.media.errMesg : '')
    const isLoading = useSelector((state) => state.media && state.media.isLoading ? state.media.isLoading : false)
    const isTagLoading = useSelector((state) => state.media && state.media.isTagLoading ? state.media.isTagLoading : false)

    const getCertificateOptions = (data) => {
        if (data && data.data && data.data.length > 0) {
            return data.data.map((x) => { return { value: x, label: x } });
        } else {
            return [];
        }
    }

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
        dispatch(getMediaCertificatesValues());
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            var id = params.id;
            if (id && id != "") {
                dispatch(getMediaDetails(id));
            }
            dispatch(clearMessage());
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    React.useEffect(() => {
        dispatch(mediaGenreSelect());
        dispatch(mediaLanguageSelect());
        dispatch(mediaOttSelect());
    }, [])

    React.useEffect(() => {
        dispatch(getTagList(tagSearchText, tagSearchPage));
    }, [tagSearchPage])

    React.useEffect(() => {
        var id = params.id;
        setAlreadySelectedTags([]);
        setMediaCastDetails([]);
        setMediaCrewDetails([]);
        setMediaData({});
        if (id && id != "") {
            dispatch(getMediaDetails(id));
        }
    }, [])
    React.useEffect(() => {
        var id = params.id;
        dispatch(getMediaTagList(id, currentMediaTagPage));
    }, [currentMediaTagPage])

    React.useEffect(() => {
        var mediaId = params.id;
        if (mediaId && mediaId != "") {
            dispatch(getMediaProvidersList(mediaId, 'IN', '', '', 1, 10));
        }
    }, [])

    React.useEffect(() => {
        var id = params.id;
        dispatch(getMediaCastList(id, currentMediaCastPage));
    }, [currentMediaCastPage])

    React.useEffect(() => {
        var id = params.id;
        dispatch(getMediaCrewList(id, currentMediaCrewPage));
    }, [currentMediaCrewPage])

    React.useEffect(() => {
        if (mediaCastList && mediaCastList.data && mediaCastList.data.results && mediaCastList.data.results.length > 0) {
            if (currentMediaCastPage == 1) {
                setMediaCastDetails(removeDuplicate([...mediaCastList.data.results]));
            } else {
                setMediaCastDetails(removeDuplicate([...mediaCastDetails, ...mediaCastList.data.results]));
            }
        } else {
            setMediaCastDetails([])
        }
    }, [mediaCastList])

    React.useEffect(() => {
        if (mediaCrewList && mediaCrewList.data && mediaCrewList.data.results && mediaCrewList.data.results.length > 0) {
            if (currentMediaCrewPage == 1) {
                setMediaCrewDetails(removeDuplicate([...mediaCrewList.data.results]));
            } else {
                setMediaCrewDetails(removeDuplicate([...mediaCrewDetails, ...mediaCrewList.data.results]));
            }
        } else {
            setMediaCrewDetails([])
        }
    }, [mediaCrewList])

    React.useEffect(() => {
        var id = params.id;
        if (mediaDetails && mediaDetails.data && mediaDetails.data.uuid && (!mediaData || !mediaData.uuid) && id === mediaDetails.data.uuid) {
            setMediaData(mediaDetails.data);
        }
    }, [mediaDetails])

    const handleAfterChange = (id) => {
        var elem = document.getElementById(id);
        if (isInViewport(elem.firstChild.firstChild.lastChild) && !isLoading) {
            if (id == 'mediaCast') {
                if (mediaCastList && mediaCastList.data && mediaCastList.data.pagination && mediaCastList.data.pagination.is_next_page) {
                    setCurrentMediaCastPage(currentMediaCastPage + 1);
                }
            } else if (id == 'mediaCrew') {
                if (mediaCrewList && mediaCrewList.data && mediaCrewList.data.pagination && mediaCrewList.data.pagination.is_next_page) {
                    setCurrentMediaCrewPage(currentMediaCrewPage + 1);
                }
            }
        }
    }

    React.useEffect(() => {
        if (currentMediaTagPage == 1) {
            if (tagMediaResult && tagMediaResult.data && tagMediaResult.data.results && tagMediaResult.data.results.length > 0) {
                setAlreadySelectedTags(removeDuplicate([...tagMediaResult.data.results.map((item) => { return { id: item.uuid, text: item.tag } })]));
                setTimeout(() => {
                    addMoreBtn();
                }, 300);
            }
        } else {
            if (tagMediaResult && tagMediaResult.data && tagMediaResult.data.results && tagMediaResult.data.results.length > 0) {
                setAlreadySelectedTags(removeDuplicate([...alreadySelectedTags, ...tagMediaResult.data.results.map((item) => { return { id: item.uuid, text: item.tag } })]));
                setTimeout(() => {
                    addMoreBtn();
                }, 300);
            }
        }
    }, [tagMediaResult])

    React.useEffect(() => {
        if (tagDropdownResult && tagDropdownResult.data && tagDropdownResult.data.results && tagDropdownResult.data.results.length > 0) {
            setTagsList(removeDuplicate([...tagsList, ...tagDropdownResult.data.results.map((item) => { return { id: item.uuid, text: item.tag } })]));
        }
    }, [tagDropdownResult])

    React.useEffect(() => {
        console.log("data loaded-", mediaData);
    }, [mediaData])

    const getGenreOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: x.genre_id ? x.genre_id : x.id,
                label: x.genre ? x.genre : x.name,
            });
        })
        return temp;
    }

    const getGenreOptionsValue = (genres) => {
        var result = [];
        if (genres && genres.length > 0) {
            genres.map((x, i) => {
                result.push({
                    value: x.id ? parseInt(x.id) : parseInt(x.genre_id),
                    label: x.name ? x.name : x.genre,
                });
            })
        }
        return result;
    }

    const getLanguageOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: x.code ? x.code : '',
                label: x.lang ? x.lang : x.language ? x.language : 'N/A',
            });
        })
        return temp;
    }

    const getOttOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: {
                    image: x.image ? x.image : '',
                    og_link: x.url ? x.url : '',
                    ott_name: x.ott_name ? x.ott_name : '',
                },
                label: x.ott_name ? x.ott_name : '',
            });
        })
        return temp;
    }

    const getOttOptionsValue = (ottArr) => {
        var result = [];
        if (ottArr && ottArr.length > 0) {
            ottArr.map((x, i) => {
                result.push({
                    value: x,
                    label: x.image,
                });
            })
        }
        return result;
    }

    const getCanWatchWithValue = (value) => {
        var result = [];
        if (value && value != "") {
            var tempArr = value.split(",");
            if (canWatchWithOption && canWatchWithOption.length > 0) {
                canWatchWithOption.map((x, i) => {
                    if (tempArr.includes(x.value)) {
                        result.push(x);
                    }
                })
            }
        }
        return result;
    }

    const handleSelectOnchangeFormaData = (data, name) => {
        var tempData = mediaData;
        if (name === 'data') {
            var tempArr2 = [];
            if (data && data.length > 0) {
                data.map((x, i) => {
                    tempArr2.push(x.value);
                })
            }
            tempData.data = tempArr2;
        } else if (name === 'genres') {
            var tempArr = [];
            if (data && data.length > 0) {
                data.map((x, i) => {
                    tempArr.push({
                        id: x.value,
                        name: x.label,
                    });
                })
            }
            tempData.genres = tempArr;
        } else if (name === 'can_watch_with') {
            tempData.can_watch_with = data.length > 0 ? data.map(item => item.value).join() : null;
        } else {
            tempData[name] = data.value;
        }
        setMediaData({ ...tempData });
    }

    console.log("mediaData--", mediaData);

    const handleOnchangeFormaData = (value, name) => {
        setMediaData({ ...mediaData, [name]: value });
    }

    const addNewVideo = () => {
        var tempData = mediaData;
        var tempArr = tempData && tempData.media_videos && tempData.media_videos[0] && tempData.media_videos[0].results ? tempData.media_videos[0].results : [];
        tempArr.push({
            key: '',
            name: tempData.title ? tempData.title : '',
            site: 'YouTube',
            type: 'Trailer'
        })

        if (tempData.media_videos && tempData.media_videos[0]) {
            tempData.media_videos[0].results = tempArr;
        } else {
            tempData.media_videos.push({ results: tempArr })
        }
        setMediaData({ ...tempData });
    }

    const handleRemoveVideo = (index) => {
        var tempData = mediaData;
        var tempArr = tempData && tempData.media_videos && tempData.media_videos[0] && tempData.media_videos[0].results ? tempData.media_videos[0].results : [];
        tempArr.splice(index, 1);
        tempData.media_videos[0].results = tempArr;
        setMediaData({ ...tempData });
    }

    const handleOnVideoChangeInput = (value, name, id) => {
        var tempData = mediaData;
        var tempArr = tempData && tempData.media_videos && tempData.media_videos[0] && tempData.media_videos[0].results ? tempData.media_videos[0].results : [];
        tempArr[id][name] = value;
        tempData.media_videos[0].results = tempArr;
        setMediaData({ ...tempData });
    }

    const handleOttInputnchangeFormaData = (value, id) => {
        var tempData = mediaData;
        var tempArr = tempData && tempData.data ? tempData.data : [];
        if (tempArr && tempArr.length > 0) {
            tempArr[id].og_link = value
        }
        setMediaData({ ...tempData });
    }

    const handleOttDataChangeFormaData = (value, id, name) => {
        var tempData = mediaData;
        var tempArr = tempData && tempData.data ? tempData.data : [];
        if (tempArr && tempArr.length > 0) {
            tempArr[id][name] = value
        }
        setMediaData({ ...tempData });
    }

    const onPosterChangeClick = () => {
        posterInputChange.current.click();
    }

    const handlePosterChange = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "media_poster/movie-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Media', "Uploaded poster_path file to s3 bucket -" + keyName, data.Location);
                handleOnchangeFormaData(data.Location, 'poster_path');
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const saveMediaDetails = () => {
        eventReporting('Media', 'Media updated - ', mediaData.uuid);
        dispatch(updateMediaDetails(mediaData.uuid, mediaData));
        const queryParams = new URLSearchParams(location.search);
        const type = queryParams.get('type');
        if (type === 'cx-update') {
            setTimeout(() => {
                eventReporting('Media', 'Media CX record updated and deleted - ', mediaData.uuid);
                dispatch(deleteCXRecord(mediaData.uuid));
                setTimeout(() => {
                    history.goBack();
                }, 1000)
            }, 500)
        }
    }

    const handleDelete = (i) => {
        var curentTag = alreadySelectedTags.filter((tag, index) => index == i);
        if (curentTag && curentTag[0]) {
            var uuid = params.id;
            var body = {
                media: uuid,
                action: 'remove',
                tag: curentTag[0].id
            }
            eventReporting('Media', 'Tag removed - ' + uuid, curentTag[0].id);
            dispatch(addRemoveTags(uuid, body));
        }
        setAlreadySelectedTags(alreadySelectedTags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        var uuid = params.id;
        var body = {
            media: uuid,
            action: 'add',
            tag: tag.id
        }
        eventReporting('Media', 'Tag added - ' + uuid, tag.id);
        dispatch(addRemoveTags(uuid, body));
        setAlreadySelectedTags([...alreadySelectedTags, tag]);
        setTimeout(() => {
            addMoreBtn();
        }, 500)
    };

    const handleInputChange = (val) => {
        if (val && val != "") {
            setTagSearchText(val);
            setTagsList([]);
            dispatch(getTagList(val, 1));
        }
    }

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = alreadySelectedTags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        // re-render
        setAlreadySelectedTags(newTags);
    };

    const handleTagClick = (index, e) => {
        e.stopPropagation();
        return;
    };

    const removeDuplicate = (array) => {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }

    const addMoreBtn = () => {
        if (document.getElementById("loadMoreTagsBtn")) {
            document.getElementById("loadMoreTagsBtn").remove();
            setMoreBtnAdded(false);
        }
        if (document.getElementsByClassName("ReactTags__selected") && document.getElementsByClassName("ReactTags__selected")[0]) {
            var moreBtn = document.createElement("span");
            moreBtn.className = "tag-wrapper more-btn btn-success ReactTags__tag pointer";
            moreBtn.innerHTML = "More...";
            moreBtn.id = "loadMoreTagsBtn";
            document.getElementsByClassName("ReactTags__selected")[0].appendChild(moreBtn);
            setMoreBtnAdded(true);
            document.getElementById("loadMoreTagsBtn").onclick = function () {
                if (tagMediaResult && tagMediaResult.data && tagMediaResult.data.pagination && tagMediaResult.data.pagination.is_next_page) {
                    setCurrentMediaTagPage(currentMediaTagPage + 1);
                } else {
                    document.getElementById("loadMoreTagsBtn").remove();
                }
            }
        }
    }

    React.useEffect(() => {
        if (isTagLoading) {
            if (document.getElementsByClassName("ReactTags__tagInput") && document.getElementsByClassName("ReactTags__tagInput")[0]) {
                var moreBtn = document.createElement("span");
                moreBtn.className = "tag-loader-spinner spinner-border";
                moreBtn.id = "tagLoader";
                document.getElementsByClassName("ReactTags__tagInput")[0].appendChild(moreBtn);
            }
        } else {
            if (document.getElementById("tagLoader")) {
                document.getElementById("tagLoader").remove();
            }
        }
    }, [isTagLoading])

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }


    return (
        <>
            <Header />
            <ToastContainer />
            {isLoading && <Loader />}
            <Container>
                <Card className='mt-3 mb-3' >
                    <Row className='justify-content-between pt-3 px-3'>
                        <h3 className='ml-2' >Edit Media</h3>
                        <div>
                            <Link to="/admin/media">
                                <Button color="darker" onClick={() => history.goBack()} className='mr-3'>
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            </Link>
                        </div>
                    </Row>
                    <Row className='media_detail_container px-2'>
                        <Col sm="auto" className='position-relative pr-0'>
                            {mediaData.poster_path && mediaData.poster_path != "" ?
                                <img src={mediaData.poster_path ? helpers.getFullUrl(mediaData.poster_path) : ''} className='media_image' alt='' />
                                :
                                <div style={{ width: 125, height: 150 }} className='default-img'>
                                    <i className='fas fa-image'></i>
                                </div>
                            }
                            <i onClick={() => onPosterChangeClick()} className='fas position-absolute fa-edit pointer' style={{ top: 5, right: 5, zIndex: 9, background: '#fff', color: '#f5365c', borderRadius: '50%', padding: 5 }} ></i>
                            <input
                                style={{ display: 'none' }}
                                ref={posterInputChange}
                                type="file"
                                onChange={(e) => handlePosterChange(e)}
                                accept="image/*"
                            />
                        </Col>
                        <Col xs="12" sm="6" className='ml-3'>
                            <p className='movie_name'>{mediaData.title ? mediaData.title : ''}</p>
                            <p className='relese_txt'>
                                Release Date : <span className=''>
                                    {mediaData.release_date ? mediaData.release_date : ''}
                                </span>
                            </p>
                            <div className='rating_containrer'>
                                <p className='relese_txt'>
                                    Rating : <span className=''>
                                        {mediaData.rating ? mediaData.rating : 0}
                                    </span>
                                </p>
                                <p className='relese_txt'>
                                    , Frexpert Rating :  <span className=''>
                                        {mediaData.frexpert_rating ? mediaData.frexpert_rating : 0}
                                    </span>
                                </p>
                            </div>
                            <div className='rating_containrer'>
                                <p className='relese_txt'>
                                    Likes : <span className=''>
                                        {mediaData.likes ? mediaData.likes : 0}
                                    </span>
                                </p>
                                <p className='relese_txt'>
                                    , Dislikes : <span className=''>
                                        {mediaData.dislikes ? mediaData.dislikes : 0}
                                    </span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                    {/* <div className='mt-2'>
                        <div className='edit_media_subheading'>
                            <p style={{ width: '17%' }} className='edit_media_subheading_txt'>Providers</p>
                            <Link to={`/admin/editmedia-providers/${params.id}`}>
                                <Button className='relative' color="success" >Add/Edit
                                    <span
                                        className='bg-danger'
                                        style={{
                                            fontWeight: 300,
                                            width: 20,
                                            height: 20,
                                            borderRadius: '50%',
                                            position: 'absolute',
                                            top: '-5px',
                                            right: '-5px',
                                            textAlign: 'center'
                                        }}
                                    >{mediaProvidersListData.data && mediaProvidersListData.data.pagination ? mediaProvidersListData.data.pagination?.total_entries : ''}</span>
                                </Button>
                            </Link>
                        </div>
                    </div> */}
                    <div className='mt-2 bg-danger'>
                        <EditMediaProviders />
                    </div>
                    <div className='mt-2'>
                        {/* <div className='edit_media_subheading mb-2'>
                            <p style={{ width: '17%' }} className='edit_media_subheading_txt'>OTT</p>
                            {mediaData && mediaData.data && mediaData.data.length > 0 ?
                                <>
                                    <Row>
                                        {mediaData.data.map((y, j) => {
                                            return <Col key={j}>
                                                <a target={'_blank'} rel='noreferrer' href={y.og_link ? y.og_link : '#'}>
                                                    <img
                                                        style={{
                                                            width: 50,
                                                            height: 50,
                                                            borderRadius: 7,
                                                            border: '0.5px solid #ddd'
                                                        }}
                                                        src={y.image ? y.image : ''}
                                                        alt=""
                                                    />
                                                </a>
                                            </Col>
                                        })}
                                        <Button color="info" onClick={() => setAddOrEdit(!addOrEdit)} >Edit</Button>
                                    </Row>
                                </>
                                :
                                <Button color="info" onClick={() => setAddOrEdit(!addOrEdit)}>Add</Button>
                            }
                        </div> */}
                        {addOrEdit &&
                            <div className='edit_media_subheading pt-4 mb-2' style={{ backgroundColor: '#b1ffa6' }}>
                                <p style={{ width: '20%' }} className='edit_media_subheading_txt'>Add/Edit Platforms</p>
                                <div className='w-75'>
                                    <Form className='edit_media_input w-100 mb-2'>
                                        <Select
                                            isMulti
                                            // closeMenuOnSelect={false}
                                            // components={animatedComponents}
                                            options={
                                                getOttOptions(ottDropdownResult)
                                            }
                                            value={
                                                getOttOptionsValue(mediaData.data)
                                            }
                                            onChange={(e) => handleSelectOnchangeFormaData(e, 'data')}
                                            components={components}
                                        />
                                    </Form>
                                    <ol className='pl-3'>
                                        {mediaData && mediaData.data && mediaData.data.length > 0 && mediaData.data.map((y, j) => {
                                            return <li key={j + 1}>
                                                <Row className='mb-2 m-0 p-0'>
                                                    <Col sm={8} className="mb-2">
                                                        <Input value={y.og_link ? y.og_link : ''} onChange={(e) => handleOttInputnchangeFormaData(e.target.value, j)} />
                                                    </Col>
                                                    <Col sm={4}>
                                                        <img style={{ width: 40, height: 40, borderRadius: 3 }} src={y.image ? y.image : ''} />
                                                    </Col>
                                                    {/* <Col sm={4}>
                                                        <Form className='edit_media_input w-100'>
                                                            <Select
                                                                closeMenuOnSelect={true}
                                                                components={animatedComponents}
                                                                isMulti
                                                                className="w-100"
                                                                options={getLanguageOptions(languageDropdownResult)}
                                                                value={
                                                                    getLanguageOptions(languageDropdownResult).filter(option =>
                                                                        option.value === mediaData.og_lang)
                                                                }
                                                                onChange={(e) => handleSelectOnchangeFormaData(e, 'og_lang')}
                                                            />
                                                        </Form>
                                                    </Col> */}
                                                    <Col sm={4}>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            // isMulti
                                                            placeholder="Select Plan Type"
                                                            className="w-100"
                                                            options={[
                                                                {
                                                                    label: 'None',
                                                                    value: null
                                                                },
                                                                {
                                                                    label: 'Subscription',
                                                                    value: 'subscription'
                                                                },
                                                                {
                                                                    label: 'Free',
                                                                    value: 'free'
                                                                },
                                                                {
                                                                    label: 'Rent',
                                                                    value: 'rent'
                                                                }
                                                            ]}
                                                            value={y.plan_type ? [{ label: y.plan_type.charAt(0).toUpperCase() + y.plan_type.slice(1), value: y.plan_type }] : [{ label: 'None', value: null }]}
                                                            onChange={(e) => handleOttDataChangeFormaData(e.value, j, 'plan_type')}
                                                        />
                                                    </Col>
                                                </Row>
                                            </li>
                                        })}
                                    </ol>
                                </div>
                            </div>
                        }
                        <Row className='p-0 m-0'>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>IMDB ID</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input disabled={false} type="search" name="search" id="exampleSearch" placeholder="IMDB ID" value={mediaData.imdb_id ? mediaData.imdb_id : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'imdb_id')} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>IMDB Rating</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input disabled={false} type="number" name="search" id="exampleSearch" placeholder="IMDB Rating" value={mediaData.imdb_rating ? mediaData.imdb_rating : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'imdb_rating')} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>TMDB ID</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input disabled={true} type="search" name="search" id="exampleSearch" placeholder="TMDB ID" value={mediaData.tmdb_id ? mediaData.tmdb_id : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'tmdb_id')} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>UUID</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input disabled={true} type="search" name="search" id="exampleSearch" placeholder="UUID" value={mediaData.uuid ? mediaData.uuid : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'tmdb_id')} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p style={{ fontSize: 14 }} className='edit_media_subheading_txt'>Total Searches(60 days)</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input disabled={true} type="search" name="search" id="exampleSearch" placeholder="Total Searches" value={mediaData.total_searches ? mediaData.total_searches : ''} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p style={{ fontSize: 14 }} className='edit_media_subheading_txt'>Total Searches Lifetime</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input disabled={true} type="search" name="search" id="exampleSearch" placeholder="Total title search" value={mediaData.total_searches_lifetime ? mediaData.total_searches_lifetime : ''} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Title</p>
                                <Form className='edit_media_input'>
                                    <FormGroup>
                                        <Input type="search" name="search" id="exampleSearch" placeholder="Title" value={mediaData.title ? mediaData.title : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'title')} />
                                    </FormGroup>
                                </Form>
                            </Col>

                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Og Language</p>
                                <Form className='edit_media_input'>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        // isMulti
                                        options={getLanguageOptions(languageDropdownResult)}
                                        value={
                                            getLanguageOptions(languageDropdownResult).filter(option =>
                                                option.value === mediaData.og_lang)
                                        }
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'og_lang')}

                                    />
                                </Form>
                            </Col>

                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Free</p>

                                <Form className='edit_media_input'>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={booleanOption}
                                        value={
                                            booleanOption.filter(option =>
                                                option.value === mediaData.is_free)
                                        }
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'is_free')}
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Adult </p>
                                <Form className='edit_media_input'>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={booleanOption}
                                        value={
                                            booleanOption.filter(option =>
                                                option.value === mediaData.is_adult)
                                        }
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'is_adult')}
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Trailer Trending </p>
                                <Form className='edit_media_input'>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={booleanOption2}
                                        value={
                                            booleanOption2.filter(option =>
                                                option.value == mediaData.trailer_trending)
                                        }
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'trailer_trending')}
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Certificate </p>
                                <Form className='edit_media_input'>
                                    <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={getCertificateOptions(certificatesList)}
                                        value={
                                            getCertificateOptions(certificatesList).filter(option =>
                                                option.value == mediaData.certificate)
                                        }
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'certificate')}
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Can Watch With </p>
                                <Form className='edit_media_input'>
                                    <Select
                                        closeMenuOnSelect={true}
                                        isMulti={true}
                                        components={animatedComponents}
                                        options={canWatchWithOption}
                                        value={
                                            getCanWatchWithValue(mediaData.can_watch_with)
                                        }
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'can_watch_with')}
                                    />
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Streaming Date </p>
                                <Form className='edit_media_input'>
                                    <FormGroup className='m-0'>
                                        <Input type="date" name="date" id="exampleDate" placeholder="To" value={mediaData.release_date ? mediaData.release_date : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'release_date')} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p style={{ fontSize: 14 }} className='edit_media_subheading_txt'>Theatrical Release Date </p>
                                <Form className='edit_media_input'>
                                    <FormGroup className='m-0'>
                                        <Input type="date" name="date" id="exampleDate" placeholder="To" value={mediaData.theatrical_release_date ? mediaData.theatrical_release_date : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'theatrical_release_date')} />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>In Theatre </p>
                                <FormGroup switch>
                                    <CustomInput
                                        type="switch"
                                        id={`exampleCustomSwitch1`}
                                        name="customSwitch1"
                                        className=''
                                        checked={mediaData.in_theater}
                                        onChange={(e) => {
                                            handleOnchangeFormaData(e.target.checked, 'in_theater')
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Add RunTime </p>
                                <Form className='edit_media_input'>
                                    <FormGroup className='m-0'>
                                        <Input
                                            value={mediaData.movie_runtime}
                                            type="number"
                                            onChange={(e) => {
                                                handleOnchangeFormaData(e.target.value, 'movie_runtime')
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                            {/* <Col xs={12} sm={6} className=' edit_media_subheading'>
                                <p className='edit_media_subheading_txt'>Season Episodes </p>
                                <Form className='edit_media_input'>
                                    <FormGroup className='m-0'>
                                        <Input type="select" name="select" id="exampleSelect">
                                            <option value="javascript">Episode 1</option>
                                            <option value="php">Episode 2</option>
                                            <option value="java">Episode 3</option>
                                            <option value="golang">Episode 4</option>
                                            <option value="python">Episode 5</option>
                                            <option value="c#">Episode 6</option>
                                            <option value="C++">Episode 7</option>
                                            <option value="erlang">Episode 8</option>
                                        </Input>
                                        <input placeholder='Add Runtime' className='mt-2' />
                                        <input type="submit" value="Submit" className='ml-3'></input>
                                    </FormGroup>
                                </Form>
                            </Col> */}
                        </Row>
                        <div className='edit_media_subheading mt-2' >
                            <p className='tag_heading'>Add TAGS </p>
                            <Form style={{ maxWidth: '83%' }}>
                                <FormGroup>
                                    <ReactTags
                                        tags={alreadySelectedTags}
                                        suggestions={tagsList}
                                        delimiters={delimiters}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        handleInputChange={(va) => handleInputChange(va)}
                                        // handleDrag={handleDrag}
                                        // handleTagClick={handleTagClick}
                                        editable={false}
                                        inputFieldPosition="bottom"
                                        autocomplete
                                        allowDragDrop={false}
                                        minQueryLength={1}
                                    />
                                </FormGroup>
                            </Form>
                        </div>
                        <div className='edit_media_subheading mt-2' >
                            <p className='tag_heading'>Media Cast </p>
                            <div id={'mediaCast'} style={{ minWidth: '83%', maxWidth: '83%' }}>
                                {mediaCastDetails && mediaCastDetails.length > 0 &&
                                    <Carousel afterChange={() => handleAfterChange('mediaCast')} responsive={responsive} swipeable={true} draggable={true} >
                                        {mediaCastDetails.map((m, j) => {
                                            return <div key={j} className='pb-3'>
                                                <div className='text-center'>
                                                    <div className='position-relative d-inline-block'>
                                                        {m.poster && m.poster != "" ?
                                                            <img style={{ width: 80, height: 87, borderRadius: 3 }} src={m.poster ? common.getFullUrl(m.poster) : ''} />
                                                            :
                                                            <div style={{ width: 80, height: 87, borderRadius: 3 }} className='default-img'>
                                                                <i className='fas fa-image'></i>
                                                            </div>
                                                        }
                                                        <i className='fas close-plus-icon'>+</i>
                                                    </div>
                                                </div>
                                                <p className='p-0 m-0 text-center text-dark' style={{ fontSize: 11 }}>{m.name ? m.name : ''}</p>
                                                {m.character && <p className='p-0 m-0 text-center' style={{ fontSize: 10 }}>({m.character ? m.character : ''})</p>}
                                            </div>
                                        })}
                                    </Carousel>
                                }
                            </div>
                        </div>
                        <div className='edit_media_subheading mt-2' >
                            <p className='tag_heading'>Media Crew </p>
                            <div id={'mediaCrew'} style={{ minWidth: '83%', maxWidth: '83%' }}>
                                {mediaCrewDetails && mediaCrewDetails.length > 0 &&
                                    <Carousel afterChange={() => handleAfterChange('mediaCrew')} responsive={responsive} swipeable={true} draggable={true} >
                                        {mediaCrewDetails.map((m, j) => {
                                            return <div key={j} className='pb-3' >
                                                <div className='text-center'>
                                                    <div className='position-relative d-inline-block'>
                                                        {m.poster && m.poster != "" ?
                                                            <img style={{ width: 80, height: 87, borderRadius: 3 }} src={m.poster ? common.getFullUrl(m.poster) : ''} />
                                                            :
                                                            <div style={{ width: 80, height: 87, borderRadius: 3 }} className='default-img'>
                                                                <i className='fas fa-image'></i>
                                                            </div>
                                                        }
                                                        <i className='fas close-plus-icon'>+</i>
                                                    </div>
                                                </div>
                                                <p className='p-0 m-0 text-center text-dark' style={{ fontSize: 11 }}>{m.name ? m.name : ''}</p>
                                                {m.role && <p className='p-0 m-0 text-center' style={{ fontSize: 10 }}>({m.role})</p>}
                                            </div>
                                        })}
                                    </Carousel>
                                }
                            </div>
                        </div>
                        <div className=' edit_media_subheading mt-2' >
                            <p className='tag_heading'>Media Overview </p>
                            <Form className='w-75'>
                                <FormGroup>
                                    <Input rows={5} type="textarea" name="text" id="exampleText" value={mediaData.media_overview ? mediaData.media_overview : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'media_overview')} />
                                </FormGroup>
                            </Form>
                        </div>
                        <div className=' edit_media_subheading'>
                            <p className='tag_heading'>Genres </p>
                            <Form className='edit_media_input w-75'>
                                <Select
                                    isMulti
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    options={
                                        getGenreOptions(genreDropdownResult)
                                    }
                                    value={
                                        getGenreOptionsValue(mediaData.genres)
                                    }
                                    onChange={(e) => handleSelectOnchangeFormaData(e, 'genres')}
                                />
                            </Form>
                        </div>
                        <div className='mt-2 edit_media_subheading'>
                            <p className='tag_heading'>Media Videos </p>
                            <Form className='edit_media_input'>
                                <Button color="info" onClick={() => addNewVideo()} >Add Video</Button>
                            </Form>
                        </div>
                        <Row className='mt-3 m-0' >
                            {mediaData && mediaData.media_videos && mediaData.media_videos[0] && mediaData.media_videos[0].results && mediaData.media_videos[0].results.length > 0 && mediaData.media_videos[0].results.map((data, i) => {
                                const id = i + 1;
                                return <Col sm={6} key={id}>
                                    <div className='show-form w-100 mb-4'>
                                        <Row className='justify-content-center'>
                                            <Col>
                                                <Row className='m-0'>
                                                    <h3 className='mr-5'># Video {id ? id : ''}</h3>
                                                    {data.key && data.key != "" &&
                                                        <a href={`https://www.youtube.com/watch?v=${data.key}`} target={'_blank'}>
                                                            <i className="fas fa-external-link-alt pt-1"></i>
                                                        </a>
                                                    }
                                                </Row>
                                            </Col>
                                            <Col>
                                                <div className='show-form-delete'>
                                                    <i className="fa fa-trash text-danger" aria-hidden="true" onClick={() => handleRemoveVideo(id - 1)}  ></i>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Form>
                                            <FormGroup row>
                                                <Label for="exampleEmail2" sm={2}>Key</Label>
                                                <Col sm={4} className="show-form-input">
                                                    <Input placeholder="" value={data.key ? data.key : ''} onChange={(e) => handleOnVideoChangeInput(e.target.value, 'key', id - 1)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="exampleEmail2" sm={2}>Name</Label>
                                                <Col sm={4} className="show-form-input">
                                                    <Input placeholder="" value={data.name ? data.name : ''} onChange={(e) => handleOnVideoChangeInput(e.target.value, 'name', id - 1)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="exampleEmail2" sm={2}>Site</Label>
                                                <Col sm={4} className="show-form-input">
                                                    <Input placeholder="" value={data.site ? data.site : ''} onChange={(e) => handleOnVideoChangeInput(e.target.value, 'site', id - 1)} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="exampleEmail2" sm={2}>Type</Label>
                                                {/* <Col sm={4} className="show-form-input">
                                                    <Input placeholder="" value={data.type ? data.type : ''} onChange={(e) => handleOnVideoChangeInput(e.target.value, 'type', id - 1)} />
                                                </Col> */}
                                                <Col sm={4}>
                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        components={animatedComponents}
                                                        // isMulti
                                                        className="w-100"
                                                        options={[
                                                            {
                                                                label: 'Trailer',
                                                                value: 'Trailer'
                                                            },
                                                            {
                                                                label: 'Teaser',
                                                                value: 'Teaser'
                                                            },
                                                            {
                                                                label: 'Announcement',
                                                                value: 'Announcement'
                                                            }
                                                        ]}
                                                        value={[{ label: data.type, value: data.type }]}
                                                        onChange={(e) => handleOnVideoChangeInput(e.value, 'type', id - 1)}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </div>
                                </Col>
                            })}
                        </Row>
                        <div style={{ textAlign: 'center' }} className='mt-2 mb-4'>
                            <Button onClick={() => saveMediaDetails()} color="info" >Save</Button>
                        </div>
                    </div>
                </Card>
            </Container>
        </>
    )
}

