import axiosInstance from 'helpers/axios';
import React from 'react'
import { Button, Col, Input, Modal, ModalHeader, Row } from 'reactstrap'

export default function RelatedBlogs(props) {
    const { relatedBlogs, isEdit, editId } = props;
    const [addBlog, setAddBlog] = React.useState(false);
    const [blogDataList, setBlogDataList] = React.useState([]);
    const [blogs, setBlogs] = React.useState([]);

    const handleOnclose = () => {
        props?.updateBlogPosts(blogs.map(x => { return x?.uuid }));
        setAddBlog(false);
    }

    const checkBlogAdded = (id) => {
        if (blogs.length > 0) {
            if (blogs[0] && blogs[0].uuid) {
                let filteredData = blogs.filter(x => x.uuid == id);
                if (filteredData && filteredData[0]) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return blogs.includes(id);
            }
        } else {
            return false;
        }
    }

    const handleOpenModal = () => {
        if (relatedBlogs && relatedBlogs.length > 0) {
            if (isEdit) {
                if (relatedBlogs && relatedBlogs[0] && relatedBlogs[0].uuid) {
                    setBlogs([...relatedBlogs]);
                } else {
                    let url = `blog/bloglist?search=&page=1&pagelimit=100`;
                    axiosInstance
                        .get(url)
                        .then((res) => {
                            if (res.status === 200) {
                                const payload = res.data.data;
                                setBlogDataList([...payload.results] || []);

                                blogDataList.map((x) => {
                                    let temp = payload.results.filter((x) => x.uuid == x);
                                    if (temp && temp[0]) {
                                        setBlogs([...blogs, temp[0]]);
                                    }
                                })
                            }
                        })
                        .catch((err) => {
                            if (err.status === 400) {
                                console.log(err);
                            }
                        })
                }
            } else {
                let url = `blog/bloglist?search=&page=1&pagelimit=100`;
                axiosInstance
                    .get(url)
                    .then((res) => {
                        if (res.status === 200) {
                            const payload = res.data.data;
                            setBlogDataList([...payload.results] || []);

                            blogDataList.map((x) => {
                                let temp = payload.results.filter((x) => x.uuid == x);
                                if (temp && temp[0]) {
                                    setBlogs([...blogs, temp[0]]);
                                }
                            })
                        }
                    })
                    .catch((err) => {
                        if (err.status === 400) {
                            console.log(err);
                        }
                    })
            }
        } else {
            setBlogs([]);
        }
        setAddBlog(true);
    }

    const handleDeleteBlogs = (index) => {
        blogs.splice(index, 1);
        setBlogs([...blogs]);
    }

    const handleOnTextChange = (text) => {
        let url = `blog/bloglist?search=${text}&page=1&pagelimit=50`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    setBlogDataList([...payload.results] || []);
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    console.log(err);
                }
            })
    }

    return (
        <div>
            <Button className='w-100 mt-3'
                onClick={() => handleOpenModal()}
                color='warning'>{isEdit ? 'Update' : 'Add'} Related Blog Posts</Button>

            <Modal
                isOpen={addBlog}
                toggle={() => handleOnclose()}
                size="lg"
            >
                <ModalHeader className='p-3' toggle={() => handleOnclose()}>
                    <h3>Related Blog Posts</h3>
                </ModalHeader>
                <div className='p-4'>
                    <Input
                        id={`search-bbox`}
                        size={'sm'}
                        placeholder='Type Blog Title...'
                        className='mb-0'
                        onChange={(e) => handleOnTextChange(e.target.value)}
                    />
                </div>
                <div className='pl-4 pr-4' style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                    {blogs.map((x, i) => {
                        if (isEdit && editId === x.uuid) {
                            return true;
                        }
                        return <div key={i}>
                            <Row className='m-0 pb-3'>
                                <span>{i + 1}.</span>
                                <Col style={{ maxWidth: '120px', paddingRight: '20px' }}>
                                    <img style={{ maxWidth: '100px' }} alt="" src={x?.banner} />
                                </Col>
                                <Col>
                                    <p>{x?.title}</p>
                                </Col>
                                <Col style={{ maxWidth: '90px', paddingLeft: '20px' }}>
                                    <Button
                                        style={{ minWidth: '70px' }}
                                        className=" btn-icon btn-simple"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => {
                                            handleDeleteBlogs(i);
                                        }}
                                    >
                                        Remove
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    })}
                    {blogDataList.map((x, i) => {
                        if (isEdit && editId === x.uuid) {
                            return true;
                        }
                        if (!checkBlogAdded(x.uuid)) {
                            return <div key={i}>
                                <Row className='m-0 pb-3'>
                                    <span>{i + 1}.</span>
                                    <Col style={{ maxWidth: '120px', paddingRight: '20px' }}>
                                        <img style={{ maxWidth: '100px' }} alt="" src={x?.banner} />
                                    </Col>
                                    <Col>
                                        <p>{x?.title}</p>
                                    </Col>
                                    <Col style={{ maxWidth: '90px', paddingLeft: '20px' }}>
                                        <Button
                                            style={{ minWidth: '70px' }}
                                            className=" btn-icon btn-simple"
                                            color="success"
                                            size="sm"
                                            type="button"
                                            onClick={() => {
                                                setBlogs([...blogs, x]);
                                            }}
                                        >
                                            Add
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    })}
                </div>
                <div className='pl-4 pr-4 pb-4'>
                    <div style={{ textAlign: 'center' }} className='mt-2'>
                        <Button color="success" onClick={() => handleOnclose()} >Done</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
