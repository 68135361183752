import { WEB } from "../constants/constants";


const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
    frenziActivityResult: {},
    frenziPointList: {},
    frenziPointListDetails: {}
};

export const frenziPointReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.FRENZI_POINTS_PAGE_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.FRENZI_POINTS_PAGE_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
            };
        case WEB.UPDATE_BULK_FRENZI_POINT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_BULK_FRENZI_POINT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Bulk Point Uploaded',
            };
        case WEB.UPDATE_BULK_FRENZI_POINT_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_FRENZI_ACTIVITY_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_FRENZI_ACTIVITY_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                frenziActivityResult: payload,
            };
        case WEB.GET_FRENZI_ACTIVITY_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_POINTS_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_POINTS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                frenziPointList: payload,
            };
        case WEB.GET_POINTS_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_POINTS_LIST_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_POINTS_LIST_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                frenziPointListDetails: payload,
            };
        case WEB.GET_POINTS_LIST_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_FRENZI_POINT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_FRENZI_POINT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Points Updated',
            };
        case WEB.UPDATE_FRENZI_POINT_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_ACTIVITY_POINT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_ACTIVITY_POINT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Activity Updated',
            };
        case WEB.UPDATE_ACTIVITY_POINT_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.DELETE_FRENZI_POINT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_FRENZI_POINT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Points Removed',
            };
        case WEB.DELETE_FRENZI_POINT_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPLOAD_FRENZI_POINTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPLOAD_FRENZI_POINTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'points Updated',
            };
        case WEB.UPLOAD_FRENZI_POINTS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };



        default:
            return state;
    }

}