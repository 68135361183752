import CustomPagination from 'components/Pagination/CustomPagination';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { Button, Card, Container, Row, Table } from 'reactstrap'
import { getPointsListDetails, deleteFrenziPoints, clearMessage } from "../../redux/actions/frenziPoint.action"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

export default function PointsDetails(props) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);

    const dispatch = useDispatch();
    const id = useParams();
    const history = useHistory();
    const frenziPointListDetails = useSelector((state) => state.frenziPoint && state.frenziPoint.frenziPointListDetails ? state.frenziPoint.frenziPointListDetails : {})
    const isLoading = useSelector((state) => state.frenziPoint && state.frenziPoint.isLoading ? state.frenziPoint.isLoading : false)
    const status = useSelector((state) => state.frenziPoint && state.frenziPoint.status ? state.frenziPoint.status : '')
    const errMesg = useSelector((state) => state.frenziPoint && state.frenziPoint.errMesg ? state.frenziPoint.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            var editId = props && props.match && props.match.params && props.match.params.id ? props.match.params.id : '';
            if (editId && editId != "") {
                dispatch(getPointsListDetails(editId, currentPage, pageLimit));
            }
            dispatch(clearMessage());
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    React.useEffect(() => {
        var editId = props && props.match && props.match.params && props.match.params.id ? props.match.params.id : '';
        if (editId && editId != "") {
            dispatch(getPointsListDetails(editId, currentPage, pageLimit));
        }
    }, [currentPage, pageLimit])

    const handleDelete = (id) => {
        if (window.confirm("Are sure want to delete ?")) {
            eventReporting('Frenzi Points', 'Frenzi Points deleted', id);
            dispatch(deleteFrenziPoints(id))
        }
    }

    return (
        <Container>
            <ToastContainer />
            {isLoading && <Loader />}
            <Card className='mt-4 p-3'>
                <Row className='justify-content-between m-0 align-items-center'>
                    <h3 className='mb-3'>Point Details</h3>
                    <Button color='dark' onClick={() => history.goBack()} >Back</Button>
                </Row>
                <div>
                    <Table hover className='mt-2'>
                        <thead>
                            <tr>
                                <th className='table_heading' width="7%">
                                    S.No.
                                </th>
                                <th className='table_heading'>
                                    Poster
                                </th>
                                <th className='table_heading'>
                                    Title
                                </th>
                                <th className='table_heading'>
                                    Media Type
                                </th>
                                <th className='table_heading'>
                                    Activity
                                </th>
                                <th className='table_heading'>
                                    Points
                                </th>
                                <th className='table_heading'>
                                    Date
                                </th>
                                <th className='table_heading'>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {frenziPointListDetails && frenziPointListDetails.data && frenziPointListDetails.data.results && frenziPointListDetails.data.results.length > 0 && frenziPointListDetails.data.results.map((x, i) => {
                                return <tr key={i}>
                                    <td>
                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                    </td>
                                    <td>

                                    </td>
                                    <td>{x.title ? x.title : ''}</td>
                                    <td></td>
                                    <td>{x.activity ? x.activity : ''}</td>
                                    <td>{x.points ? x.points : ''}</td>
                                    <td>{x.created_at ? x.created_at : ''}</td>
                                    <td>
                                        <Button size='sm' color='danger' onClick={() => handleDelete(x.uuid)}>
                                            <i className='fas fa-trash'></i>
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    {frenziPointListDetails.data && frenziPointListDetails.data.pagination &&
                        <CustomPagination
                            pagination={frenziPointListDetails.data && frenziPointListDetails.data.pagination && frenziPointListDetails.data.pagination ? frenziPointListDetails.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={frenziPointListDetails.data && frenziPointListDetails.data.results ? frenziPointListDetails.data.results.length : 20}
                        />
                    }
                </div>
            </Card>
        </Container>
    )
}
