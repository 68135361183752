import React from 'react';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { S3Client, GetObjectCommand, PutObjectCommand, HeadObjectCommand } from "@aws-sdk/client-s3";
import AWS from 'aws-sdk'
import ReactGA from 'react-ga';

const clientParams = {
    endPoint: "",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    signatureVersion: 2,
    region: process.env.REACT_APP_AWS_REGION
}

const region = clientParams.region;
const client = new S3Client({
    region,
    credentials: clientParams
});

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

const eventReporting = (category, action, val) => {
    ReactGA.event({
        category: category,
        action: action,
        value: val,
        label: val
    });
}

const getKeyFileName = (url) => {
    if (url.includes("?")) {
        if (!url.includes("amazonaws.com/frenzi-native/")) {
            return url.substring(
                url.indexOf("amazonaws.com/") + 14,
                url.lastIndexOf("?")
            );
        } else {
            return url.substring(
                url.indexOf("frenzi-native/") + 14,
                url.lastIndexOf("?")
            );
        }
    } else {
        var splitStr = url.substring(url.indexOf('amazonaws.com/') + 14);
        return splitStr;
    }
}

const downloadBlob = (blob, name, fileLocation = '') => {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    eventReporting('Common', "Downloaded file from s3 bucket -" + name, fileLocation);
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );
    document.body.removeChild(link);
}

const helpers = {
    getFileKeyName: function (url) {
        if (url.includes("?")) {
            if (!url.includes("amazonaws.com/frenzi-native/")) {
                return url.substring(
                    url.indexOf("amazonaws.com/") + 14,
                    url.lastIndexOf("?")
                );
            } else {
                return url.substring(
                    url.indexOf("frenzi-native/") + 14,
                    url.lastIndexOf("?")
                );
            }
        } else {
            var splitStr = url.substring(url.indexOf('amazonaws.com/') + 14);
            return splitStr;
        }
    },
    getPresignedUrl: async function (filePath) {
        var fileLocation = getKeyFileName(filePath);
        if (fileLocation && fileLocation != "") {
            const getObjectParams = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                Key: fileLocation
            }
            const command = new GetObjectCommand(getObjectParams);
            const url = await getSignedUrl(client, command);
            if (url) {
                return url
            }
        }
    },
    getS3FileDate: async function (filePath) {
        var fileLocation = getKeyFileName(filePath);

        const s3Client = new S3Client({
            region: process.env.REACT_APP_AWS_REGION,
            credentials: {
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            },
        });

        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
            Key: fileLocation, // Replace with the actual file path
        };

        try {
            const response = await s3Client.send(new HeadObjectCommand(params));
            const lastModified = response.LastModified; // This will be a Date object
            console.log('Last Modified Date:', lastModified);
            return lastModified;
        } catch (error) {
            console.error('Error getting last modified date:', error);
            return 'N/A';
        }

        // const urlParams = new URLSearchParams(filePath);
        // const dateString = urlParams.get('X-Amz-Date');
        // // const dateString = '20231027T093905';
        // const year = dateString.slice(0, 4);
        // const month = dateString.slice(4, 6);
        // const day = dateString.slice(6, 8);
        // const hour = dateString.slice(9, 11);
        // const minute = dateString.slice(11, 13);
        // const second = dateString.slice(13, 15);

        // const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

        // const dateObj = new Date(formattedDate);
        // const localDateString = dateObj.toLocaleString();

        // X-Amz-Date=20231027T093905Z&X-Amz-Expires=900&
        // 2023-10-27T09:39:05Z
        // return localDateString

    },
    getTempPresignedUrl: async function (filePath) {
        var fileLocation = getKeyFileName(filePath);
        if (fileLocation && fileLocation != "") {
            const getObjectParams = {
                Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME,
                Key: fileLocation
            }
            const command = new GetObjectCommand(getObjectParams);
            const url = await getSignedUrl(client, command);
            if (url) {
                return url
            }
        }
    },

    downloadFileFromBucket: async function (filePath, filename) {
        var fileLocation = getKeyFileName(filePath);
        if (fileLocation && fileLocation != "") {
            const getObjectParams = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                Key: fileLocation
            }

            const s3 = new AWS.S3({
                params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
                region: process.env.REACT_APP_AWS_REGION,
            })
            s3.getObject(getObjectParams, function (error, data) {
                if (error) {
                    console.log(error, error.stack);
                } else {
                    let blobData = new Blob([data.Body], { type: data.ContentType });
                    var tempExt = data.ContentType;
                    var ext = "";
                    if (tempExt.includes("audio")) {
                        ext = '.mp3';
                    } else if (tempExt.includes("video")) {
                        ext = '.mp4';
                    }
                    if (ext != "") {
                        let fileName = filename + ext;
                        downloadBlob(blobData, fileName, fileLocation);
                    } else {
                        alert("File not found !!!")
                    }
                }
            });

        }
    },

    getUploadedPresignedUrl: async function (filePath) {
        if (filePath && filePath != "") {
            const getObjectParams = {
                Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                Key: filePath,
            }
            const command = new PutObjectCommand(getObjectParams);
            const url = await getSignedUrl(client, command);
            if (url) {
                return url
            }
        }
    },

    getUploadedUrl: async function (e, bucket, key) {
        var file = e.target.files[0];
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = key + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                throw err;
            }
            return data;
        });
    },

    isEmptyObject: function (obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    },

    getFullUrl: function (path) {
        if (path.includes("http")) {
            return path;
        } else {
            return process.env.REACT_APP_TMDB_BASE_URL + path;
        }
    },

}

export default helpers;