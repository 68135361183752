import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link,useLocation } from "react-router-dom";

const SubMenu = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => setCollapsed(!collapsed);
    const location = useLocation();
    const { icon, title, items } = props;
    // console.log("pppp--",location)

    return (
        <div>
            <NavItem
                onClick={toggle}
                className={classNames({ "menu-open": !collapsed })}
            >
                <NavLink className={`dropdown-toggle ${!collapsed && 'active'}`} >
                    <i className={icon} />
                    <span>{title}</span>
                </NavLink>
            </NavItem>
            <Collapse
                isOpen={!collapsed}
                navbar
                className={classNames("items-menu", { "mb-1": !collapsed })}
            >
                {items.map((item, index) => (
                    <NavItem key={index} className="pl-3 child-item">
                        <NavLink className={`${location.pathname == item.layout + item.path ? 'active' : ''} pr-0`}  tag={Link} to={item.layout + item.path}>
                            <span className="child-menu-icon"></span>
                            {item.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Collapse>
        </div>
    );
};

export default SubMenu;
