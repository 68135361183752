import React, { useEffect, useState } from 'react'
import Header from "components/Headers/Header.js";
import { Dropdown, Modal, DropdownMenu, DropdownToggle, DropdownItem, ModalHeader, ModalBody, Input, Container, Table, CustomInput, Card, Row, Col, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getWatchList, createWatchList, clearMessage, deleteWatchList, updateWatchList, getWatchListMemberList, getWatchListMediaList, addOrRemoveMembers, addOrRemoveMedia } from '../../redux/actions/watchlist.action';
import { userSearch } from '../../redux/actions/search.action';
import { categorySearchList } from '../../redux/actions/categories.action';

import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from 'components/Pagination/CustomPagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './watchlist.scss';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';
import AWS from 'aws-sdk'
import { CopyToClipboard } from 'react-copy-to-clipboard';

// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


function WatchList() {
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isAddMember, setIsAddMember] = React.useState(false);
    const [isNestedModal, setIsNestedModal] = React.useState(false);
    const [isMediaModal, setIsMediaModal] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [isComment, setIsComment] = React.useState(false)
    const [isReply, setIsReply] = React.useState(false)

    const dispatch = useDispatch();

    const [searchText, setSearchText] = React.useState('')
    const [isSearching, setIsSearching] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentMemberPage, setCurrentMemberPage] = React.useState(1);
    const [currentMediaPage, setCurrentMediaPage] = React.useState(1);
    const [currentMemberSearchPage, setCurrentMemberSearchPage] = React.useState(1);
    const [currentMediaSearchPage, setCurrentMediaSearchPage] = React.useState(1);
    const [memberSearchtext, setMemberSearchtext] = React.useState('');
    const [mediaSearchtext, setMediaSearchtext] = React.useState('');
    const [pageLimit, setPageLimit] = React.useState(20);
    const [watchListName, setWatchListName] = React.useState('')
    const [watchListPrivacy, setWatchListPrivacy] = React.useState(false)
    const [thumbnail, setThumbnail] = React.useState('')
    const [isEdit, setIsEdit] = React.useState(false);
    const [editId, setEditId] = React.useState('');
    const [activeTitle, setActiveTitle] = React.useState('');
    const [activeCategory, setActiveCategory] = React.useState('');
    const [watchlistMemberList, setWatchlistMemberList] = React.useState([]);
    const [searchMemberList, setSearchMemberList] = React.useState([]);
    const [watchlistMediaList, setWatchlistMediaList] = React.useState([]);
    const [searchMediaList, setSearchMediaList] = React.useState([]);
    const [order, setOrder] = useState(0)


    const watchlistData = useSelector((state) => state.watchlist && state.watchlist.watchlistData ? state.watchlist.watchlistData : {})
    const watchlistMemberData = useSelector((state) => state.watchlist && state.watchlist.watchlistMemberData ? state.watchlist.watchlistMemberData : {})
    const watchlistMediaData = useSelector((state) => state.watchlist && state.watchlist.watchlistMediaData ? state.watchlist.watchlistMediaData : {})
    const errMesg = useSelector((state) => state.watchlist && state.watchlist.errMesg ? state.watchlist.errMesg : '')
    const status = useSelector((state) => state.watchlist && state.watchlist.status ? state.watchlist.status : '')
    const isLoading = useSelector((state) => state.watchlist && state.watchlist.isLoading ? state.watchlist.isLoading : false)
    const userSearchResult = useSelector((state) => state.commonSearch && state.commonSearch.userSearchResult ? state.commonSearch.userSearchResult : {})
    const mediaSearchList = useSelector((state) => state.categories && state.categories.categoriesSearchList ? state.categories.categoriesSearchList : {})
    const isMediaLoading = useSelector((state) => state.categories && state.categories.isLoading ? state.categories.isLoading : false)
    const isMemberLoading = useSelector((state) => state.commonSearch && state.commonSearch.isLoading ? state.commonSearch.isLoading : false)



    // console.log(setOrder)
    // console.log(watchlistData.data.results.order)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            setIsAddPlatform(false);
            setWatchListName("");
            setThumbnail("");
            setOrder("");
            setWatchListPrivacy(false);
            if (status == 'member added' || status == 'member removed') {
                if (activeCategory && activeCategory != "") {
                    dispatch(getWatchListMemberList(activeCategory, currentMemberPage));
                }
            } else if (status == "removed" || status == "added") {
                if (activeCategory && activeCategory != "") {
                    dispatch(getWatchListMediaList(activeCategory, currentMediaPage));
                }
            } else {
                getResults();
            }

        }

        if (errMesg && errMesg != "") {
            toastMesgErr(errMesg);
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const toastMesgErr = (mesg) => {
        toast.error((mesg), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    React.useEffect(() => {
        if (activeCategory && activeCategory != "") {
            dispatch(getWatchListMemberList(activeCategory, currentMemberPage));
        }
    }, [currentMemberPage]);

    React.useEffect(() => {
        if (activeCategory && activeCategory != "") {
            dispatch(getWatchListMemberList(activeCategory, currentMediaPage));
        }
    }, [currentMediaPage]);

    React.useEffect(() => {
        dispatch(categorySearchList(mediaSearchtext, 'id', '', currentMediaSearchPage));
    }, [currentMediaSearchPage]);

    React.useEffect(() => {
        dispatch(userSearch(memberSearchtext, '', currentMemberSearchPage));
    }, [currentMemberSearchPage]);

    // member data

    React.useEffect(() => {
        if (watchlistMemberData && watchlistMemberData.data && watchlistMemberData.data.results && watchlistMemberData.data.results.length > 0) {
            setWatchlistMemberList([...watchlistMemberList, ...watchlistMemberData.data.results])
        }
    }, [watchlistMemberData])

    React.useEffect(() => {
        if (userSearchResult && userSearchResult.data && userSearchResult.data.results && userSearchResult.data.results.length > 0) {
            setSearchMemberList(removeDuplicate([...searchMemberList, ...userSearchResult.data.results]))
        } else {
            setSearchMemberList([]);
        }
    }, [userSearchResult])

    // media data
    React.useEffect(() => {
        if (watchlistMediaData && watchlistMediaData.data && watchlistMediaData.data.results && watchlistMediaData.data.results.length > 0) {
            setWatchlistMediaList([...watchlistMediaData.data.results])
        } else {
            setWatchlistMediaList([]);
        }
    }, [watchlistMediaData])

    React.useEffect(() => {
        if (mediaSearchList && mediaSearchList.data && mediaSearchList.data.results && mediaSearchList.data.results.length > 0) {
            setSearchMediaList(removeDuplicate([...searchMediaList, ...mediaSearchList.data.results]))
        }
    }, [mediaSearchList])


    React.useEffect(() => {
        getResults();
    }, [searchText, currentPage, pageLimit]);

    const getResults = () => {
        dispatch(getWatchList(searchText, currentPage, pageLimit));

    }

    const handleUpDown = (isUp, data) => {
        if (isUp) {
            let newOrder = data?.order - 1;
            var body = {
                order: newOrder
            }
            eventReporting('Watchlist', 'Watchlist order changed - ' + data?.uuid, newOrder);
            dispatch(updateWatchList(data?.uuid, body));
        } else {
            let newOrder = data?.order + 1;
            var body1 = {
                order: newOrder
            }
            eventReporting('Watchlist', 'Watchlist order changed - ' + data?.uuid, newOrder);
            dispatch(updateWatchList(data?.uuid, body1));
        }
    }


    const handleCreateWatchlist = () => {
        if (watchListName && watchListName != "") {
            if (isEdit && editId && editId != "") {
                var body = {
                    is_public: watchListPrivacy,
                    category_name: watchListName,
                    order: order,
                    thumbnail: thumbnail && thumbnail != "" ? thumbnail : null
                }
                eventReporting('Watchlist', 'Watchlist updated - ' + watchListName, editId);
                handleUpdate(editId, body);
            } else {
                var body = {
                    is_public: watchListPrivacy,
                    category_name: watchListName,
                    thumbnail: thumbnail && thumbnail != "" ? thumbnail : null,
                    order: order,
                }
                eventReporting('Watchlist', 'Watchlist created - ' + watchListName, watchListName);
                dispatch(createWatchList(body));
            }
        } else {
            toastMesgErr("Name is required");
        }
    }

    const handleUpdate = (id, data) => {
        eventReporting('Watchlist', 'Watchlist status changed - ' + data.is_public, id);
        dispatch(updateWatchList(id, data));
    }

    const handleAddOrRemoveMembers = (mid, wid, type) => {
        var body = {
            user: mid,
            watchlist: wid,
            action: type
        }
        eventReporting('Watchlist', 'Watchlist member ' + type + ' - ' + mid, wid);
        dispatch(addOrRemoveMembers(body));
    }

    const handleAddOrRemoveMedia = (mid, wid, type) => {
        var body = {
            media: mid,
            watchlist: wid
        }
        eventReporting('Watchlist', 'Watchlist media updated - ' + mid, wid);
        dispatch(addOrRemoveMedia(body));
    }

    const handleOnMemberScroll = (e) => {
        if (isInViewport(e.target.firstChild.lastChild.lastChild)) {
            if (isSearching) {
                if (!isMemberLoading && userSearchResult && userSearchResult.data && userSearchResult.data.pagination && userSearchResult.data.pagination.is_next_page) {
                    setCurrentMemberSearchPage(currentMemberSearchPage + 1);
                }
            } else {
                if (!isLoading && watchlistMemberData && watchlistMemberData.data && watchlistMemberData.data.pagination && watchlistMemberData.data.pagination.is_next_page) {
                    setCurrentMemberPage(currentMemberPage + 1);
                }
            }
        }
    }

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleOnMediaScroll = (e) => {
        if (isInViewport(e.target.firstChild.lastChild.lastChild)) {
            if (isSearching) {
                if (!isMediaLoading && mediaSearchList && mediaSearchList.data && mediaSearchList.data.pagination && mediaSearchList.data.pagination.is_next_page) {
                    setCurrentMediaSearchPage(currentMediaSearchPage + 1)
                }
            } else {
                if (!isLoading && watchlistMediaData && watchlistMediaData.data && watchlistMediaData.data.pagination && watchlistMediaData.data.pagination.is_next_page) {
                    setCurrentMediaPage(currentMediaPage + 1);
                }
            }
        }
    }

    const handlePosterUpdate = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "frenzi_watchlist/watchlist-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Watchlist', "Uploaded thumbnail file to s3 bucket -" + keyName, data.Location);
                setThumbnail(data.Location);
                // setCurrentCollectionData({
                //     ...currentCollectionData,
                //     image: data.Location
                // })
                // const body = {
                //     image: data.Location
                // }
                // dispatch(updateCategory(id, body));
            }
        });
    }
    

    const removeDuplicate = (array) => {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }

    useEffect(() => {
        if (watchlistData.data && watchlistData.data.pagination && watchlistData.data.pagination?.total_entries) {
            setOrder(watchlistData.data.pagination?.total_entries + 1);
        }
    }, [watchlistData])

    return (
        <>
            <Header />
            {isLoading &&
                <Loader />
            }
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-3'>
                    Watchlist
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} />
                            <i className="fas fa-search"></i>
                        </Col>
                        <Button onClick={() => {
                            setEditId('');
                            setIsEdit(false);
                            setWatchListName('');
                            setWatchListPrivacy(false);
                            setIsAddPlatform(true);
                            ReactGA.modalview(window.location.pathname + "?modal=Create watchlist");
                        }} color="info" >Create</Button>
                    </Row>
                    <div className='overflow-auto'>
                        <Table hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th width="7%">
                                        S.No.
                                    </th>
                                    <th>
                                        Watchlist Name
                                    </th>
                                    <th>
                                        Thumbnail
                                    </th>
                                    <th>
                                        Is Public
                                    </th>
                                    <th >
                                        Move
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                    <th>
                                        Members
                                    </th>
                                    <th>
                                        Media
                                    </th>
                                    {/* <th>
                                        Comment
                                    </th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {watchlistData && watchlistData.data && watchlistData.data.results && watchlistData.data.results.length > 0 && watchlistData.data.results.map((x, i) => {
                                    return <tr key={i + 1}>
                                        <th scope="row">
                                            {(pageLimit * (currentPage - 1)) + (i + 1)}
                                        </th>
                                        <td>
                                            {x.category_name}
                                        </td>
                                        <td>
                                            {x.thumbnail && x.thumbnail != "" ?
                                                <img style={{ width: 50, height: 70, borderRadius: 7 }} src={x.thumbnail ? x.thumbnail : ''} />
                                                :
                                                <div style={{ width: 50, height: 70, borderRadius: 7 }} className='default-img'>
                                                    <i className='fas fa-image'></i>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <div style={{ marginLeft: 70 }}>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    className='pl-0'
                                                    checked={x.is_public}
                                                    onChange={(e) => {
                                                        var body = {
                                                            is_public: e.target.checked,
                                                            category_name: x.category_name
                                                        }
                                                        handleUpdate(x.uuid, body);
                                                    }}
                                                // label={x.is_public ? 'Public':'Private'}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                key={x.order}

                                            >
                                                {x?.order > 1 ?
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="warning"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 5, }}
                                                        onClick={() => handleUpDown(true, x)}
                                                    >
                                                        <i className="fas fa-arrow-circle-up pt-1"></i>
                                                    </Button>
                                                    : ''
                                                }
                                                <br />
                                                {x?.order < watchlistData.data.results.length ?
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="warning"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 5, marginTop: 5 }}
                                                        onClick={() => handleUpDown(false, x)}

                                                    >
                                                        <i className="fas fa-arrow-circle-down pt-1"></i>
                                                    </Button>
                                                    : ''
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <CopyToClipboard text={x?.uuid}
                                                onCopy={() => {
                                                    toast.success(('watchlist uuid copied'), {
                                                        position: "top-right",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    });
                                                }}>
                                                <span className=" pointer">
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="success"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 10 }}
                                                    >
                                                        <i className="fas fa-copy pt-1"></i>
                                                    </Button>
                                                </span>
                                            </CopyToClipboard>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    setEditId(x.uuid);
                                                    setIsEdit(true);
                                                    setWatchListName(x.category_name);
                                                    setWatchListPrivacy(x.is_public);
                                                    setThumbnail(x.thumbnail);
                                                    setOrder(x?.order || 0);
                                                    setIsAddPlatform(true);
                                                    ReactGA.modalview(window.location.pathname + "?modal=Edit watchlist");
                                                }}
                                            >
                                                <i className="fas fa-pen pt-1"></i>
                                            </Button>

                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    if (window.confirm("Are you sure want to delete this ?")) {
                                                        eventReporting('Watchlist', 'Watchlist deleted - ', x.uuid);
                                                        dispatch(deleteWatchList(x.uuid));
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                        <td>
                                            <Button onClick={() => {
                                                setWatchlistMemberList([]);
                                                dispatch(getWatchListMemberList(x.uuid, 1));
                                                setActiveCategory(x.uuid);
                                                setActiveTitle(x.category_name);
                                                setIsAddMember(!isAddMember);
                                                ReactGA.modalview(window.location.pathname + "?modal=Add watchlist member");
                                            }
                                            } size='sm' color="info" >Add</Button>
                                        </td>
                                        <td>
                                            <Button size='sm' onClick={() => {
                                                setWatchlistMediaList([]);
                                                dispatch(getWatchListMediaList(x.uuid, 1));
                                                setActiveCategory(x.uuid);
                                                setActiveTitle(x.category_name);
                                                setIsMediaModal(!isMediaModal);
                                                ReactGA.modalview(window.location.pathname + "?modal=Add watchlist media");
                                            }} color="info" >Add</Button>
                                        </td>
                                        {/* <td>
                                            <Button size='sm' onClick={() => setIsComment(!isComment)} color="warning" >View Comment</Button>
                                        </td> */}
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <div className='text-right mt-2'>
                            {watchlistData.data && watchlistData.data.pagination &&
                                <CustomPagination
                                    pagination={watchlistData.data && watchlistData.data.pagination && watchlistData.data.pagination ? watchlistData.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={watchlistData.data && watchlistData.data.results ? watchlistData.data.results.length : 20}
                                />
                            }
                        </div>
                    </div>
                </Card>
                <Modal
                    isOpen={isAddPlatform}
                    toggle={() => setIsAddPlatform(!isAddPlatform)}
                >
                    <ModalHeader className='px-3' toggle={() => setIsAddPlatform(!isAddPlatform)}>
                        {isEdit ? "Update" : 'Create'}
                    </ModalHeader>
                    <div className='px-3'>
                        <Row className='pl-3 pr-3 w-100'>
                            <Col sm={5} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Watchlist Name</p>
                            </Col>
                            <Col sm={7}>
                                <Input
                                    value={watchListName}
                                    onChange={(e) => setWatchListName(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className='px-3 pb-3'>
                        <Row className='pl-3 mt-3 pr-3 w-100'>
                            <Col sm={5} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Public / Private</p>
                            </Col>
                            <Col sm={7}>
                                <CustomInput
                                    type="switch"
                                    id={`exampleCustomSwitch`}
                                    name="customSwitch"
                                    className=''
                                    checked={watchListPrivacy}
                                    onChange={(e) => setWatchListPrivacy(e.target.checked)}
                                />
                            </Col>
                        </Row>
                        <Row className='pl-3 pt-2 pr-3 w-100'>
                            <Col sm={5} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Order</p>
                            </Col>
                            <Col sm={7}>
                                <Input
                                    type='number'
                                    value={order}
                                    onChange={(e) => setOrder(e.target.value)}
                                />

                            </Col>
                        </Row>
                        <Row className='pl-3 pr-3 mt-3 w-100 '>
                            <Col sm={5} className="p-0">
                                <p className="pt-2">Upload File</p>
                            </Col>
                            <Col sm={7} className="mt-2">
                                <Input type="file" accept='image/*' onChange={(e) => handlePosterUpdate(e)} />
                                {thumbnail && thumbnail != "" &&
                                    <img src={thumbnail} className="mt-2" style={{ width: 70, height: 100, borderRadius: 3 }} />
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className='text-center mb-3'>
                        <Button
                            color="info"
                            onClick={() => handleCreateWatchlist()}
                        >
                            {isEdit ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </Modal>
                <Modal
                    isOpen={isAddMember}
                    toggle={() => setIsAddMember(!isAddMember)}
                >
                    <ModalHeader className='p-3' toggle={() => setIsAddMember(!isAddMember)}>
                        Add Member - <span className='text-danger'>{activeTitle}</span>
                    </ModalHeader>
                    <div className='px-3'>
                        <Row className='justify-content-between m-0'>
                            <Col sm={7} className="p-0 search-icon-box">
                                <Input placeholder='Search' onChange={(e) => {
                                    setSearchMemberList([]);
                                    setMemberSearchtext(e.target.value);
                                    dispatch(userSearch(e.target.value, '', 1));
                                    if (e.target.value != "") {
                                        setIsSearching(true);
                                    } else {
                                        setIsSearching(false);
                                    }
                                }}
                                    value={memberSearchtext}
                                />
                                <i className="fas fa-search"></i>
                            </Col>
                            {isSearching &&
                                <Button onClick={() => {
                                    setMemberSearchtext("");
                                    setIsSearching(false);
                                }} color="info" >Done</Button>
                            }

                        </Row>
                    </div>
                    <div className='responsive-table' onScroll={(e) => handleOnMemberScroll(e)}>
                        <Table hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th width="7%">
                                        S.No.
                                    </th>
                                    <th>
                                        Image
                                    </th>
                                    <th>
                                        Member
                                    </th>
                                    <th>
                                        Role
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isSearching && watchlistMemberList.length > 0 ? watchlistMemberList.map((x, i) => {
                                    return <tr key={i + 1}>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img style={{ width: 40, height: 40, borderRadius: 3 }} src={x.profile_image ? x.profile_image : ''} />
                                        </td>
                                        <td>
                                            {x.username}
                                        </td>
                                        <td>
                                            {x.role}
                                        </td>
                                        <td>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleAddOrRemoveMembers(x.uuid, activeCategory, 'remove')}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                }) : !isSearching &&
                                <tr>
                                    <th colSpan={5} className='text-center' >No Data</th>
                                </tr>
                                }
                                {isLoading && !isSearching &&
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            <Spinner
                                                color="success"
                                                type="border"
                                                style={{ alignSelf: 'center' }}
                                            >
                                                Loading...
                                            </Spinner>
                                        </td>
                                    </tr>
                                }

                                {isSearching && searchMemberList.length > 0 ? searchMemberList.map((x, i) => {
                                    return <tr key={i + 1}>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img style={{ width: 40, height: 40, borderRadius: 3 }} src={x.profile_image ? x.profile_image : ''} />
                                        </td>
                                        <td>
                                            {x.username}
                                        </td>
                                        <td>
                                            {x.profile_role ? x.profile_role : ''}
                                        </td>
                                        <td>
                                            {watchlistMemberList.length > 0 && watchlistMemberList.filter((item) => item.uuid === x.uuid).length > 0 ?
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleAddOrRemoveMembers(x.uuid, activeCategory, 'remove')}
                                                >
                                                    Remove
                                                </Button>
                                                :
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleAddOrRemoveMembers(x.uuid, activeCategory, 'add')}
                                                >
                                                    Add
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }) : isSearching &&
                                <tr>
                                    <th colSpan={5} className='text-center' >No Data</th>
                                </tr>
                                }
                                {isMemberLoading && isSearching &&
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            <Spinner
                                                color="success"
                                                type="border"
                                                style={{ alignSelf: 'center' }}
                                            >
                                                Loading...
                                            </Spinner>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </Modal>

                <Modal
                    isOpen={isNestedModal}
                    toggle={() => setIsNestedModal(!isNestedModal)}
                    style={{ maxWidth: '34%' }}
                >
                    <ModalHeader toggle={() => setIsNestedModal(!isNestedModal)}>
                        Add Role
                    </ModalHeader>
                    <div className='p-3'>
                        <Col sm={10} className="p-0 search-icon-box">
                            <Input placeholder='Search' />
                            <i className="fas fa-search"></i>
                        </Col>
                        <Row className='pl-3 pr-3 w-100 mt-3'>
                            <Col sm={5} className="p-0">
                                Role
                            </Col>
                            <Col sm={7}>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                        <DropdownToggle className='w-100' caret>
                                            Option
                                        </DropdownToggle>
                                        <DropdownMenu
                                        >
                                            <DropdownItem>Owner</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        <div className='text-center mt-3'>
                            <Button
                                color="info"
                                onClick={() => setIsNestedModal(!isNestedModal)}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={isMediaModal}
                    toggle={() => setIsMediaModal(!isAddMember)}
                >
                    <ModalHeader className='p-3' toggle={() => setIsMediaModal(!isMediaModal)}>
                        Add Media  - <span className='text-danger'>{activeTitle}</span>
                    </ModalHeader>
                    <div className='px-3'>
                        <Row className='justify-content-between m-0'>
                            <Col sm={7} className="p-0 search-icon-box">
                                <Input placeholder='Search' onChange={(e) => {
                                    setSearchMediaList([]);
                                    setMediaSearchtext(e.target.value);
                                    dispatch(categorySearchList(e.target.value, 'id', '', 1));
                                    if (e.target.value != "") {
                                        setIsSearching(true);
                                    } else {
                                        setIsSearching(false);
                                    }
                                }}
                                    value={mediaSearchtext}
                                />
                                <i className="fas fa-search"></i>
                            </Col>
                            {isSearching &&
                                <Button onClick={() => {
                                    setIsSearching(false);
                                    setMediaSearchtext('');
                                }} color="info" >Done</Button>
                            }
                            {/* <Link to="WatchListCategory">
                                <Button color="info" onClick={() => setIsAddPlatform(!isAddPlatform)} >Add Category</Button>
                            </Link> */}

                        </Row>
                    </div>
                    <div className='responsive-table' onScroll={(e) => handleOnMediaScroll(e)}>
                        <Table hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th width="7%">
                                        S.No.
                                    </th>
                                    <th>
                                        Postrer
                                    </th>
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isSearching && watchlistMediaList.length > 0 ? watchlistMediaList.map((x, i) => {
                                    return <tr key={i + 1}>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img className='platform-img' src={x.poster ? x.poster : ''} />
                                        </td>
                                        <td>
                                            {x.title}
                                        </td>
                                        <td>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleAddOrRemoveMedia(x.media, activeCategory)}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                }) : !isSearching &&
                                <tr>
                                    <th colSpan={4} className='text-center' >No Data</th>
                                </tr>
                                }
                                {isLoading && !isSearching &&
                                    <tr>
                                        <td colSpan={4} className='text-center'>
                                            <Spinner
                                                color="success"
                                                type="border"
                                                style={{ alignSelf: 'center' }}
                                            >
                                                Loading...
                                            </Spinner>
                                        </td>
                                    </tr>
                                }

                                {isSearching && searchMediaList.length > 0 ? searchMediaList.map((x, i) => {
                                    return <tr key={i + 1}>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img className='platform-img' src={x.poster_path ? x.poster_path : ''} />
                                        </td>
                                        <td>
                                            {x.title}
                                        </td>
                                        <td>
                                            {watchlistMediaList.length > 0 && watchlistMediaList.filter((item) => item.media === x.uuid).length > 0 ?
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleAddOrRemoveMedia(x.uuid, activeCategory)}
                                                >
                                                    Remove
                                                </Button>
                                                :
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleAddOrRemoveMedia(x.uuid, activeCategory)}
                                                >
                                                    Add
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                }) : isSearching &&
                                <tr>
                                    <th colSpan={4} className='text-center' >No Data</th>
                                </tr>
                                }
                                {isMediaLoading && isSearching &&
                                    <tr>
                                        <td colSpan={4} className='text-center'>
                                            <Spinner
                                                color="success"
                                                type="border"
                                                style={{ alignSelf: 'center' }}
                                            >
                                                Loading...
                                            </Spinner>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </Modal>
                <Modal
                    isOpen={isComment}
                    toggle={() => setIsComment(!isComment)}
                    style={{ minWidth: '70%', marginRight: '10%' }}
                >
                    <ModalHeader className='p-3' toggle={() => setIsComment(!isComment)}>
                        Comment
                    </ModalHeader>
                    <Table hover className="align-items-center table-flush">
                        <thead>
                            <tr>
                                <th className='table_heading' width="7%">
                                    S.No.
                                </th>
                                <th className='table_heading'>
                                    User Name / @Unique Name

                                </th>
                                <th className='table_heading'>
                                    Role
                                </th>
                                <th className='table_heading'>
                                    Like
                                </th>
                                <th className='table_heading'>
                                    Replies
                                </th>
                                <th className='table_heading'>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {[1, 2, 3, 4, 5].map((x, i) => {
                                return <tr key={i + 1}>
                                    <th scope="row">
                                        {i + 1}
                                    </th>
                                    <td>
                                        Bajrang
                                    </td>
                                    <td>
                                        None
                                    </td>
                                    <td>  <Button
                                        className=" btn-icon btn-simple"
                                        color="danger"
                                        size="sm"
                                        type="button"
                                    >
                                        <i className="fas fa-thumbs-up"> {i * 10 + 85}</i>
                                    </Button></td>
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            onClick={() => setIsReply(!isReply)}
                                        >
                                            <i className="fas fa-reply"> {i * 10 + 85}</i>
                                        </Button>
                                    </td>
                                    {/* <td>{i * 8 + 23}</td> */}
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fas fa-trash pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <div className='text-center mb-3'>
                        <Button
                            color="success"
                            onClick={() => setIsComment(!isComment)}
                        >
                            Add
                        </Button>
                    </div>
                </Modal>
                <Modal
                    isOpen={isReply}
                    toggle={() => setIsReply(!isReply)}
                >
                    <ModalHeader className='p-3' toggle={() => setIsReply(!isReply)}>
                        Like/Replies
                    </ModalHeader>
                    <div className='p-3'>
                        <Row className='pl-3 mt-3 pr-3 w-100'>
                            <Col style={{ border: "2px solid grey" }}>
                                <p>HTML is the foundation of webpages, is used for webpage development by structuring websites and web apps HTML is the foundation of webpages, is used for webpage development by structuring websites and web apps.</p>
                                <div style={{ marginBottom: '5px' }}>
                                    <i className="fas fa-thumbs-up"> 999+  </i>
                                    <i style={{ marginLeft: '10px' }} className="fas fa-reply"> 58</i>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </Container>
        </>
    )
}

export default WatchList