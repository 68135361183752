import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    errMesg: "",
    status: "",
    message: '',
    personDetails: {},
    req: {},
};


export const personReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.PERSON_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PERSON_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.PERSON_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PERSON_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload && payload.message ? payload.message : 'Person Deleted',
                message: payload && payload.message ? payload.message : 'Person Deleted',
            };
        case WEB.PERSON_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.PERSON_GET_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PERSON_GET_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
                personDetails: payload
            };
        case WEB.PERSON_GET_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PERSON_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PERSON_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload && payload.message ? payload.message : 'Person details updated',
                message: payload && payload.message ? payload.message : 'Person details updated',
            };
        case WEB.PERSON_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PERSON_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PERSON_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload && payload.message ? payload.message : 'Person added',
                message: payload && payload.message ? payload.message : 'Person added',
            };
        case WEB.PERSON_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        default:
            return state;

    }
}