import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { categorySearchList, updateCategory, getDetailsByCategory, getCollectionsMedia, updateCollectionMedia } from '../../redux/actions/categories.action'
import AWS from 'aws-sdk'
import CustomPagination from 'components/Pagination/CustomPagination';
import common from '../../helpers/common'
import ReactGA from 'react-ga';

export default function AddCategory() {
    const { id } = useParams();
    const [selectById, setSelectByID] = React.useState(false);
    const [activeBtn, setActiveBtn] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [isShowList, setIsShowList] = React.useState(false);
    const [mediaSearchList, setMediaSearchList] = React.useState([]);

    const [currentPageMedia, setCurrentPageMedia] = React.useState(1);
    const [pageLimitMedia, setPageLimitMedia] = React.useState(20);

    const [allSelect, setAllSelect] = React.useState('');
    const [idSelect, setIdSelect] = React.useState('Search by Title');
    const [searchText, setSearchText] = React.useState('');
    const [categoryData, setCategoryData] = React.useState({});
    const [currentCollectionData, setCurrentCollectionData] = React.useState({});
    const posterInputChange = React.useRef(null);
    const wrapperRef = React.useRef(null);

    const dispatch = useDispatch()

    const categoryList = useSelector((state) => state.categories && state.categories.categoriesSearchList ? state.categories.categoriesSearchList : {})

    const detailsByCategory = useSelector((state) => state.categories && state.categories.detailsByCategory ? state.categories.detailsByCategory : {})

    const collectionsMedias = useSelector((state) => state.categories && state.categories.collectionsMedias ? state.categories.collectionsMedias : {})
    const status = useSelector((state) => state.categories && state.categories.status ? state.categories.status : '')
    const errMesg = useSelector((state) => state.categories && state.categories.errMesg ? state.categories.errMesg : '')
    const isLoading = useSelector((state) => state.categories && state.categories.isLoading ? state.categories.isLoading : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(getCollectionsMedia(id, currentPageMedia, pageLimitMedia));
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [status, errMesg]);

    React.useEffect(() => {
        var type = idSelect == 'Search by Title' ? 'title' : 'id';
        dispatch(categorySearchList(searchText, type, allSelect, currentPage, pageLimit));
    }, [currentPage, pageLimit, allSelect])

    React.useEffect(() => {
        if (categoryList && categoryList.data && categoryList.data.results && categoryList.data.results.length > 0) {
            setMediaSearchList(removeDuplicate([...mediaSearchList, ...categoryList.data.results]));
        }
    }, [categoryList])

    const handleCategorySearch = (e) => {
        setSearchText(e.target.value);
        setCurrentPage(1);
        if (e.target.value && e.target.value != "") {
            dispatch(categorySearchList(e.target.value, idSelect == 'Search by Title' ? 'title' : 'id', allSelect));
        } else {
            dispatch(categorySearchList(e.target.value, 'id', allSelect));
        }
    }

    const onPosterChangeClick = () => {
        posterInputChange.current.click();
    }

    React.useEffect(() => {
        dispatch(getDetailsByCategory(id));
    }, [id])

    React.useEffect(() => {
        dispatch(getCollectionsMedia(id, currentPageMedia, pageLimitMedia));
    }, [id, pageLimitMedia, currentPageMedia])

    React.useEffect(() => {
        if (detailsByCategory && detailsByCategory.data && detailsByCategory.data.uuid && detailsByCategory.data.uuid == id) {
            setCurrentCollectionData(detailsByCategory.data);
        }
    }, [detailsByCategory])

    const handlePosterUpdate = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "collection_poster/category-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Collection', "Uploaded file to s3 bucket -" + keyName, data.Location);
                eventReporting('Collection', "Poster updated - " + id, keyName);

                setCurrentCollectionData({
                    ...currentCollectionData,
                    image: data.Location
                })
                const body = {
                    image: data.Location
                }
                dispatch(updateCategory(id, body));
            }
        });
    }

    const handleAddRemoveMedia = (uuid, action) => {
        if (uuid && uuid != "" && uuid.length > 8) {
            const body = {
                collection: id,
                media: uuid,
                action: action
            }
            dispatch(updateCollectionMedia(body));
        }
    }

    const handleStatusChange = () => {
        eventReporting('Collection', 'Collection updated - ' + currentCollectionData.name, id);
        dispatch(updateCategory(id, { ...currentCollectionData }))
    }

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsShowList(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleOnMediaScroll = (e) => {
        if (isInViewport(e.target.lastChild) && !isLoading) {
            setCurrentPage(currentPage + 1);
        }
    }
    const isExistMedia = (mid) => {
        let flag = false;
        var tempArr = collectionsMedias && collectionsMedias.data && collectionsMedias.data.results && collectionsMedias.data.results.length > 0 ? collectionsMedias.data.results : [];
        var check = tempArr.filter(x => x.media === mid);
        if (check && check.length > 0) {
            flag = true;
        }
        return flag;
    }

    const removeDuplicate = (array) => {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }

    return (
        <>
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-2 pb-6 p-3 '>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <h3 className='mb-3'>Edit Category</h3>
                        </Col>
                        <div>
                            <Link to="/admin/categories">
                                <Button color="darker" ><i className='fas fa-arrow-left mr-3' />Back</Button>
                            </Link>
                        </div>
                    </Row>
                    <CardBody className='bg-gradient-pink mb-2 mt-2'>
                        <Row>
                            <Col sm={4} className="p-0 ">
                                <Input placeholder='Enter Category Name'
                                    value={currentCollectionData.name}
                                    onChange={(e) => {
                                        setCurrentCollectionData({
                                            ...currentCollectionData,
                                            name: e.target.value
                                        })
                                    }}
                                    onBlur={(e) => handleStatusChange()}
                                />
                                <div className='mt-2'>
                                    <Input placeholder='Enter Category Description'
                                        value={currentCollectionData.description ? currentCollectionData.description : ''}
                                        onChange={(e) => {
                                            setCurrentCollectionData({
                                                ...currentCollectionData,
                                                description: e.target.value
                                            })
                                        }}
                                        onBlur={(e) => handleStatusChange()}
                                    />
                                </div>
                                <div className="mt-2">
                                    <Row className='m-0'>
                                        <h4>In Section</h4>
                                        <FormGroup check style={{ marginLeft: 20 }}>
                                            <Input
                                                id="exampleCheck"
                                                name="trending"
                                                type="checkbox"
                                                checked={currentCollectionData.in_section == 'trending' ? true : false}
                                                onChange={(e) => {
                                                    setCurrentCollectionData({
                                                        ...currentCollectionData,
                                                        in_section: e.target.checked ? 'trending' : null
                                                    });
                                                    setActiveBtn(true);
                                                }}
                                            />
                                            <Label
                                                check
                                                for="exampleCheck"
                                            >
                                                Trending
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check style={{ marginLeft: 20 }}>
                                            <Input
                                                id="exampleCheck2"
                                                name="free"
                                                type="checkbox"
                                                checked={currentCollectionData.in_section == 'free' ? true : false}
                                                onChange={(e) => {
                                                    setCurrentCollectionData({
                                                        ...currentCollectionData,
                                                        in_section: e.target.checked ? 'free' : null
                                                    });
                                                    setActiveBtn(true);
                                                }}
                                            />
                                            <Label
                                                check
                                                for="exampleCheck"
                                            >
                                                Free
                                            </Label>
                                        </FormGroup>
                                        {activeBtn &&
                                            <Button color='success' onClick={() => handleStatusChange()} style={{ marginLeft: 20 }} size='sm'>Update</Button>
                                        }
                                    </Row>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='mt-2'>
                                    <Row>
                                        <Col>
                                            <h4>
                                                Is Public
                                            </h4>
                                        </Col>
                                        <Col >
                                            <CustomInput
                                                type="switch"
                                                id={`exampleCustomSwitch`}
                                                name="customSwitch"
                                                className=''
                                                checked={currentCollectionData.is_active}
                                                onChange={(e) => {
                                                    setCurrentCollectionData({
                                                        ...currentCollectionData,
                                                        is_active: e.target.checked
                                                    })
                                                }}
                                                onBlur={() => handleStatusChange()}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-2">
                                    {currentCollectionData && currentCollectionData.image && currentCollectionData.image != "" ?
                                        <div className='position-relative'>
                                            <img style={{ width: 70, height: 75, borderRadius: 3 }} src={currentCollectionData.image} />
                                            <i onClick={() => onPosterChangeClick()} className='fas position-absolute fa-edit pointer' style={{ top: 5, left: 5, zIndex: 9, background: '#fff', color: '#f5365c', borderRadius: '50%', padding: 5 }} ></i>
                                            <input
                                                style={{ display: 'none' }}
                                                ref={posterInputChange}
                                                type="file"
                                                onChange={(e) => handlePosterUpdate(e)}
                                                accept="image/*"
                                            />
                                        </div>
                                        :
                                        <Input type="file" onChange={(e) => handlePosterUpdate(e)} />
                                    }
                                </div>
                            </Col>
                            <Col sm={5}>
                                <Dropdown color="black" mt={4} toggle={() => { setSelectByID(!selectById) }}
                                    isOpen={selectById} style={{ width: '100%' }}>
                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                        {idSelect && idSelect != "" ? idSelect : 'Select ID/Title'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem value="Search by Title" onClick={(e) => {
                                            setIdSelect(e.target.value);
                                        }}>Search by Title</DropdownItem>
                                        <DropdownItem value="Search by Ids" onClick={(e) => {
                                            setIdSelect(e.target.value);
                                        }}>Search by Ids</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <div className="mt-3 position-relative">
                                    <Input placeholder='Search Movies/Web Series'
                                        onChange={(e) => {
                                            setMediaSearchList([]);
                                            setCurrentPage(1);
                                            handleCategorySearch(e);
                                        }}
                                        onClick={() => setIsShowList(true)}
                                    />
                                    {isShowList && categoryList && categoryList.data && categoryList.data.results && categoryList.data.results.length > 0 &&
                                        <div onScroll={(e) => handleOnMediaScroll(e)} ref={wrapperRef} className='category-list position-absolute'>
                                            {categoryList.data.results.map((x, i) => {
                                                return <Row className='align-items-center media-item'>
                                                    <div>
                                                        <Col>
                                                            {(x.poster && x.poster != "" && x.poster != null) || (x.poster_path && x.poster_path != "" && x.poster_path != null) ?
                                                                <img
                                                                    alt="..."
                                                                    className="category_img rounded shadow-lg"
                                                                    src={x.poster ? x.poster : x.poster_path ? x.poster_path : ''}
                                                                ></img>
                                                                :
                                                                <div className='d-img'>
                                                                    <i className='fas fa-image'></i>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </div>
                                                    <Col>
                                                        <h5 className="dt p-0 m-0">{x.title}</h5>
                                                        <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Release Date : </b>{x.release_date}</p>
                                                        <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Release Year : </b>{x.release_year ? x.release_year : ''}</p>
                                                        <p style={{ fontSize: 12 }} className="p-0 m-0"><b>TMDB ID : </b>{x.tmdb_id ? x.tmdb_id : ''}</p>
                                                        <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Media Type : </b>{x.media_type ? x.media_type : ''}</p>
                                                    </Col>
                                                    <FormGroup className="mr-2">
                                                        <Col >
                                                            {isExistMedia(x.media ? x.media : x.uuid ? x.uuid : '') ?
                                                                <Button color='danger' size='sm'
                                                                    onClick={() => handleAddRemoveMedia(x.media ? x.media : x.uuid ? x.uuid : '', 'remove')}
                                                                >Remove</Button>
                                                                :
                                                                <Button color='success' size='sm'
                                                                    onClick={() => handleAddRemoveMedia(x.media ? x.media : x.uuid ? x.uuid : '', 'add')}
                                                                >Add</Button>
                                                            }
                                                        </Col>
                                                    </FormGroup>
                                                </Row>
                                            })
                                            }
                                        </div>
                                    }
                                </div>

                            </Col>

                        </Row>
                    </CardBody>

                    {id && id != "" &&
                        <>
                            <h4 className='mt-3'>Selected Movies And Webseries</h4>
                            <div className='table'>
                                <Table hover className="align-items-center table-flush">
                                    <thead className='' >
                                        <tr>
                                            <th className='table_heading'>S No.</th>
                                            <th className='table_heading'>
                                                Title
                                            </th>
                                            <th className='table_heading'>
                                                Poster
                                            </th>
                                            <th className='table_heading'>
                                                Media Type
                                            </th>
                                            <th className='table_heading'>
                                                Release Date
                                            </th>

                                            <th className='table_heading'>
                                                Release Year
                                            </th>
                                            <th className='table_heading'>
                                                TMDB ID
                                            </th>
                                            <th className='table_heading'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {collectionsMedias && collectionsMedias.data && collectionsMedias.data.results && collectionsMedias.data.results.length > 0 ? collectionsMedias.data.results.map((x, i) => {
                                            return <tr key={i}>
                                                <td>
                                                    {(pageLimitMedia * (currentPageMedia - 1)) + (i + 1)}
                                                </td>
                                                <td>
                                                    {x.title ? x.title : ''}
                                                </td>
                                                <td className='crewImg'>
                                                    {x.poster && x.poster != "" && x.poster != null ?
                                                        <img
                                                            style={{ width: 40, height: 45, borderRadius: 3 }}
                                                            alt="..."
                                                            className="rounded shadow-lg"
                                                            src={x.poster ? common.getFullUrl(x.poster) : ''}
                                                        ></img>
                                                        :
                                                        <div className='d-img'>
                                                            <i className='fas fa-image'></i>
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    {x.media_type ? x.media_type : ''}
                                                </td>
                                                <td>
                                                    {x.release_date ? x.release_date : ''}
                                                </td>
                                                <td>
                                                    {x.release_year ? x.release_year : 'N/A'}
                                                </td>
                                                <td>
                                                    {x.tmdb_id ? x.tmdb_id : ''}
                                                </td>
                                                <td>
                                                    <Button color='danger' onClick={() => handleAddRemoveMedia(x.media ? x.media : '', 'remove')} size='sm' >Remove</Button>
                                                </td>
                                            </tr>
                                        })
                                            :
                                            <tr>
                                                <td colSpan={8} className='text-center'>
                                                    <h3>
                                                        No Data Available
                                                    </h3>
                                                </td>
                                            </tr>
                                        }

                                    </tbody>
                                </Table>
                                {collectionsMedias && collectionsMedias.data && collectionsMedias.data.pagination &&
                                    <CustomPagination
                                        pagination={collectionsMedias.data && collectionsMedias.data.pagination && collectionsMedias.data.pagination ? collectionsMedias.data.pagination : {}}
                                        setCurrentPage={(val) => setCurrentPageMedia(val)}
                                        setPageLimit={(val) => setPageLimitMedia(val)}
                                        defaultPageList={collectionsMedias.data && collectionsMedias.data.results ? collectionsMedias.data.results.length : 10}
                                    />
                                }
                            </div>
                        </>

                    }
                </Card>
            </Container>
        </>
    )
}
