export const User = {
  ERROR: "ERROR",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  SEARCH_MEDIA_REQUEST: "SEARCH_MEDIA_REQUEST",
  SEARCH_MEDIA_SUCCESS: "SEARCH_MEDIA_SUCCESS",
  SEARCH_MEDIA_FAILED: "SEARCH_MEDIA_FAILED",
  SEARCH_USER_REQUEST: "SEARCH_USER_REQUEST",
  SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",
  SEARCH_USER_FAILED: "SEARCH_USER_FAILED",
  MEDIA_TYPE_REQUEST: "MEDIA_TYPE_REQUEST",
  MEDIA_TYPE_SUCCESS: "MEDIA_TYPE_SUCCESS",
  MEDIA_TYPE_FAILED: "MEDIA_TYPE_FAILED",
  GET_REPORTED_USERS_REQUEST: "GET_REPORTED_USERS_REQUEST",
  GET_REPORTED_USERS_SUCCESS: "GET_REPORTED_USERS_SUCCESS",
  GET_REPORTED_USERS_FAILED: "GET_REPORTED_USERS_FAILED",
  GET_USER_PROFILE_DETAILS_REQUEST: "GET_USER_PROFILE_DETAILS_REQUEST",
  GET_USER_PROFILE_DETAILS_SUCCESS: "GET_USER_PROFILE_DETAILS_SUCCESS",
  GET_USER_PROFILE_DETAILS_FAILED: "GET_USER_PROFILE_DETAILS_FAILED",

  REVIEW_CLEAR_MESSAGE_REQUEST: "REVIEW_CLEAR_MESSAGE_REQUEST",
  REVIEW_CLEAR_MESSAGE_SUCCESS: "REVIEW_CLEAR_MESSAGE_SUCCESS",

  ACTIVATE_DEACTIVATE_REQUEST: "ACTIVATE_DEACTIVATE_REQUEST",
  ACTIVATE_DEACTIVATE_SUCCESS: "ACTIVATE_DEACTIVATE_SUCCESS",
  ACTIVATE_DEACTIVATE_FAILED: "ACTIVATE_DEACTIVATE_FAILED",

  REPORT_USER_REQUEST: "REPORT_USER_REQUEST",
  REPORT_USER_SUCCESS: "REPORT_USER_SUCCESS",
  REPORT_USER_FAILED: "REPORT_USER_FAILED",

  DELETE_USER_REPORT_REQUEST: "DELETE_USER_REPORT_REQUEST",
  DELETE_USER_REPORT_SUCCESS: "DELETE_USER_REPORT_SUCCESS",
  DELETE_USER_REPORT_FAILED: "DELETE_USER_REPORT_FAILED",

  GET_FRENZI_POINTS_REQUEST: "GET_FRENZI_POINTS_REQUEST",
  GET_FRENZI_POINTS_SUCCESS: "GET_FRENZI_POINTS_SUCCESS",
  GET_FRENZI_POINTS_FAILED: "GET_FRENZI_POINTS_FAILED",

  GET_BLOCKED_BY_REQUEST: "GET_BLOCKED_BY_REQUEST",
  GET_BLOCKED_BY_SUCCESS: "GET_BLOCKED_BY_SUCCESS",
  GET_BLOCKED_BY_FAILED: "GET_BLOCKED_BY_FAILED",

  GET_REPORTED_BY_REQUEST: "GET_REPORTED_BY_REQUEST",
  GET_REPORTED_BY_SUCCESS: "GET_REPORTED_BY_SUCCESS",
  GET_REPORTED_BY_FAILED: "GET_REPORTED_BY_FAILED",

  GET_INACTIVE_USERS_REQUEST: "GET_INACTIVE_USERS_REQUEST",
  GET_INACTIVE_USERS_SUCCESS: "GET_INACTIVE_USERS_SUCCESS",
  GET_INACTIVE_USERS_FAILED: "GET_INACTIVE_USERS_FAILED",

  AUTH_CLEAR_MESSAGE_REQUEST: 'AUTH_CLEAR_MESSAGE_REQUEST',
  AUTH_CLEAR_MESSAGE_SUCCESS: 'AUTH_CLEAR_MESSAGE_SUCCESS',

  GET_BLOCKED_TO_REQUEST: "GET_BLOCKED_TO_REQUEST",
  GET_BLOCKED_TO_SUCCESS: "GET_BLOCKED_TO_SUCCESS",
  GET_BLOCKED_TO_FAILED: "GET_BLOCKED_TO_FAILED",

  GET_USER_WATCHLIST_REQUEST: "GET_USER_WATCHLIST_REQUEST",
  GET_USER_WATCHLIST_SUCCESS: "GET_USER_WATCHLIST_SUCCESS",
  GET_USER_WATCHLIST_FAILED: "GET_USER_WATCHLIST_FAILED",

  GET_USER_WATCHLIST_MEMBERS_REQUEST: "GET_USER_WATCHLIST_MEMBERS_REQUEST",
  GET_USER_WATCHLIST_MEMBERS_SUCCESS: "GET_USER_WATCHLIST_MEMBERS_SUCCESS",
  GET_USER_WATCHLIST_MEMBERS_FAILED: "GET_USER_WATCHLIST_MEMBERS_FAILED",

  GET_USER_WATCHLIST_MEDIA_REQUEST: "GET_USER_WATCHLIST_MEDIA_REQUEST",
  GET_USER_WATCHLIST_MEDIA_SUCCESS: "GET_USER_WATCHLIST_MEDIA_SUCCESS",
  GET_USER_WATCHLIST_MEDIA_FAILED: "GET_USER_WATCHLIST_MEDIA_FAILED",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",

  OTP_VALIDATION_REQUEST: "OTP_VALIDATION_REQUEST",
  OTP_VALIDATION_SUCCESS: "OTP_VALIDATION_SUCCESS",
  OTP_VALIDATION_FAILED: "OTP_VALIDATION_FAILED",

  PASSWORD_MATCH_REQUEST: "PASSWORD_MATCH_REQUEST",
  PASSWORD_MATCH_SUCCESS: "PASSWORD_MATCH_SUCCESS",
  PASSWORD_MATCH_FAILED: "PASSWORD_MATCH_FAILED",
};

export const WEB = {
  ADVISOR_GET_REQUEST: "ADVISOR_GET_REQUEST",
  ADVISOR_GET_SUCCESS: "ADVISOR_GET_SUCCESS",
  ADVISOR_GET_FAILED: "ADVISOR_GET_FAILED",
  PLATFORM_GET_REQUEST: "PLATFORM_GET_REQUEST",
  PLATFORM_GET_SUCCESS: "PLATFORM_GET_SUCCESS",
  PLATFORM_GET_FAILED: "PLATFORM_GET_FAILED",
  PLATFORM_UPDATE_REQUEST: "PLATFORM_UPDATE_REQUEST",
  PLATFORM_UPDATE_SUCCESS: "PLATFORM_UPDATE_SUCCESS",
  PLATFORM_UPDATE_FAILED: "PLATFORM_UPDATE_FAILED",
  PLATFORM_DELETE_REQUEST: "PLATFORM_DELETE_REQUEST",
  PLATFORM_DELETE_SUCCESS: "PLATFORM_DELETE_SUCCESS",
  PLATFORM_DELETE_FAILED: "PLATFORM_DELETE_FAILED",
  PLATFORM_ADD_REQUEST: "PLATFORM_ADD_REQUEST",
  PLATFORM_ADD_SUCCESS: "PLATFORM_ADD_SUCCESS",
  PLATFORM_ADD_FAILED: "PLATFORM_ADD_FAILED",
  PLATFORM_PLAN_TYPES_GET_REQUEST: "PLATFORM_PLAN_TYPES_GET_REQUEST",
  PLATFORM_PLAN_TYPES_GET_SUCCESS: "PLATFORM_PLAN_TYPES_GET_SUCCESS",
  PLATFORM_PLAN_TYPES_GET_FAILED: "PLATFORM_PLAN_TYPES_GET_FAILED",
  PLATFORM_PRICE_DROPDOWN_GET_REQUEST: "PLATFORM_PRICE_DROPDOWN_GET_REQUEST",
  PLATFORM_PRICE_DROPDOWN_GET_SUCCESS: "PLATFORM_PRICE_DROPDOWN_GET_SUCCESS",
  PLATFORM_PRICE_DROPDOWN_GET_FAILED: "PLATFORM_PRICE_DROPDOWN_GET_FAILED",
  PLATFORM_PLAN_DROPDOWN_GET_REQUEST: "PLATFORM_PLAN_DROPDOWN_GET_REQUEST",
  PLATFORM_PLAN_DROPDOWN_GET_SUCCESS: "PLATFORM_PLAN_DROPDOWN_GET_SUCCESS",
  PLATFORM_PLAN_DROPDOWN_GET_FAILED: "PLATFORM_PLAN_DROPDOWN_GET_FAILED",
  // Coupon
  COUPON_GET_REQUEST: "COUPON_GET_REQUEST",
  COUPON_GET_SUCCESS: "COUPON_GET_SUCCESS",
  COUPON_GET_FAILED: "COUPON_GET_FAILED",
  COUPON_DELETE_REQUEST: "COUPON_DELETE_REQUEST",
  COUPON_DELETE_SUCCESS: "COUPON_DELETE_SUCCESS",
  COUPON_DELETE_FAILED: "COUPON_DELETE_FAILED",
  COUPON_UPDATE_REQUEST: "COUPON_UPDATE_REQUEST",
  COUPON_UPDATE_SUCCESS: "COUPON_UPDATE_SUCCESS",
  COUPON_UPDATE_FAILED: "COUPON_UPDATE_FAILED",
  COUPON_ADD_REQUEST: "COUPON_ADD_REQUEST",
  COUPON_ADD_SUCCESS: "COUPON_ADD_SUCCESS",
  COUPON_ADD_FAILED: "COUPON_ADD_FAILED",
  COUPON_BULK_UPLOAD_REQUEST: "COUPON_BULK_UPLOAD_REQUEST",
  COUPON_BULK_UPLOAD_SUCCESS: "COUPON_BULK_UPLOAD_SUCCESS",
  COUPON_BULK_UPLOAD_FAILED: "COUPON_BULK_UPLOAD_FAILED",
  COUPON_CLEAR_MESSAGE_REQUEST: "COUPON_CLEAR_MESSAGE_REQUEST",
  COUPON_CLEAR_MESSAGE_SUCCESS: "COUPON_CLEAR_MESSAGE_SUCCESS",

  PLATFORM_GET_DETAIL_REQUEST: "PLATFORM_GET_DETAIL_REQUEST",
  PLATFORM_GET_DETAIL_SUCCESS: "PLATFORM_GET_DETAIL_SUCCESS",
  PLATFORM_GET_DETAIL_FAILED: "PLATFORM_GET_DETAIL_FAILED",

  SUBSCRIPTION_CATEGORY_GET_REQUEST: "SUBSCRIPTION_CATEGORY_GET_REQUEST",
  SUBSCRIPTION_CATEGORY_GET_SUCCESS: "SUBSCRIPTION_CATEGORY_GET_SUCCESS",
  SUBSCRIPTION_CATEGORY_GET_FAILED: "SUBSCRIPTION_CATEGORY_GET_FAILED",

  SUBSCRIPTTION_CLEAR_MESSAGE_REQUEST: 'SUBSCRIPTTION_CLEAR_MESSAGE_REQUEST',
  SUBSCRIPTTION_CLEAR_MESSAGE_SUCCESS: 'SUBSCRIPTTION_CLEAR_MESSAGE_SUCCESS',

  // Rate Managment

  GET_SUBSCRIPTION_RATE_LIST_REQUEST: "GET_SUBSCRIPTION_RATE_LIST_REQUEST",
  GET_SUBSCRIPTION_RATE_LIST_SUCCESS: "GET_SUBSCRIPTION_RATE_LIST_SUCCESS",
  GET_SUBSCRIPTION_RATE_LIST_FAILED: "GET_SUBSCRIPTION_RATE_LIST_FAILED",

  POST_ADD_SUBSCRIPTION_RATE_REQUEST: "POST_ADD_SUBSCRIPTION_RATE_REQUEST",
  POST_ADD_SUBSCRIPTION_RATE_SUCCESS: "POST_ADD_SUBSCRIPTION_RATE_SUCCESS",
  POST_ADD_SUBSCRIPTION_RATE_FAILED: "POST_ADD_SUBSCRIPTION_RATE_FAILED",

  UPDATE_SUBSCRIPTION_RATE_REQUEST: "UPDATE_SUBSCRIPTION_RATE_REQUEST",
  UPDATE_SUBSCRIPTION_RATE_SUCCESS: "UPDATE_SUBSCRIPTION_RATE_SUCCESS",
  UPDATE_SUBSCRIPTION_RATE_FAILED: "UPDATE_SUBSCRIPTION_RATE_FAILED",

  DELETE_SUBSCRIPTION_RATE_REQUEST: "DELETE_SUBSCRIPTION_RATE_REQUEST",
  DELETE_SUBSCRIPTION_RATE_SUCCESS: "DELETE_SUBSCRIPTION_RATE_SUCCESS",
  DELETE_SUBSCRIPTION_RATE_FAILED: "DELETE_SUBSCRIPTION_RATE_FAILED",

  // Review Managment
  USER_REVIEW_REQUEST: "USER_REVIEW_REQUEST",
  USER_REVIEW_SUCCESS: "USER_REVIEW_SUCCESS",
  USER_REVIEW_FAILED: "USER_REVIEW_FAILED",

  REPORTED_REVIEW_REQUEST: "REPORTED_REVIEW_REQUEST",
  REPORTED_REVIEW_SUCCESS: "REPORTED_REVIEW_SUCCESS",
  REPORTED_REVIEW_FAILED: "REPORTED_REVIEW_FAILED",

  REPORT_REVIEW_BY_CLICK_REQUEST: "REPORTED_REVIEW_BY_CLICK_REQUEST",
  REPORT_REVIEW_BY_CLICK_SUCCESSS: "REPORTED_REVIEW_BY_CLICK_SUCCESSS",
  REPORT_REVIEW_BY_CLICK_FAILED: "REPORTED_REVIEW_BY_CLICK_FAILED",

  DELETE_REVIEW_REQUEST: "DELETE_REVIEW_REQUEST",
  DELETE_REVIEW_SUCCESS: "DELETE_REVIEW_SUCCESS",
  DELETE_REVIEW_FAILED: "DELETE_REVIEW_FAILED",

  REVIEW_APPROVAL_LIST_REQUEST: "REVIEW_APPROVAL_LIST_REQUEST",
  REVIEW_APPROVAL_LIST_SUCCESS: "REVIEW_APPROVAL_LIST_SUCCESS",
  REVIEW_APPROVAL_LIST_FAILED: "REVIEW_APPROVAL_LIST_FAILED",

  APPROVE_REVIEW_REQUEST: "APPROVE_REVIEW_REQUEST",
  APPROVE_REVIEW_SUCCESS: "APPROVE_REVIEW_SUCCESS",
  APPROVE_REVIEW_FAILED: "APPROVE_REVIEW_FAILED",

  REJECT_REVIEW_REQUEST: "REJECT_REVIEW_REQUEST",
  REJECT_REVIEW_SUCCESS: "REJECT_REVIEW_SUCCESS",
  REJECT_REVIEW_FAILED: "REJECT_REVIEW_FAILED",

  ADD_TEXT_REVIEW_REQUEST: "ADD_TEXT_REVIEW_REQUEST",
  ADD_TEXT_REVIEW_SUCCESS: "ADD_TEXT_REVIEW_SUCCESS",
  ADD_TEXT_REVIEW_FAILED: "ADD_TEXT_REVIEW_FAILED",

  ADD_AUDIO_VIDEO_REVIEW_REQUEST: "ADD_AUDIO_VIDEO_REVIEW_REQUEST",
  ADD_AUDIO_VIDEO_REVIEW_SUCCESS: "ADD_AUDIO_VIDEO_REVIEW_SUCCESS",
  ADD_AUDIO_VIDEO_REVIEW_FAILED: "ADD_AUDIO_VIDEO_REVIEW_FAILED",

  DELETE_MULTIPLE_REVIEWS_REQUEST: "DELETE_MULTIPLE_REVIEWS_REQUEST",
  DELETE_MULTIPLE_REVIEWS_SUCCESS: "DELETE_MULTIPLE_REVIEWS_SUCCESS",
  DELETE_MULTIPLE_REVIEWS_FAILED: "DELETE_MULTIPLE_REVIEWS_FAILED",

  REVIEW_CLEAR_MESSAGE_REQUEST: "REVIEW_CLEAR_MESSAGE_REQUEST",
  REVIEW_CLEAR_MESSAGE_SUCCESS: "REVIEW_CLEAR_MESSAGE_SUCCESS",

  FRENZI_OFFICIAL_REVIEW_LIST_REQUEST: "FRENZI_OFFICIAL_REVIEW_LIST_REQUEST",
  FRENZI_OFFICIAL_REVIEW_LIST_SUCCESS: "FRENZI_OFFICIAL_REVIEW_LIST_SUCCESS",
  FRENZI_OFFICIAL_REVIEW_LIST_FAILED: "FRENZI_OFFICIAL_REVIEW_LIST_FAILED",


  GET_SPECIFIC_REVIEW_REQUEST: "GET_SPECIFIC_REVIEW_REQUEST",
  GET_SPECIFIC_REVIEW_SUCCESS: "GET_SPECIFIC_REVIEW_SUCCESS",
  GET_SPECIFIC_REVIEW_FAILED: "GET_SPECIFIC_REVIEW_FAILED",

  UPDATE_REVIEW_REQUEST: "UPDATE_REVIEW_REQUEST",
  UPDATE_REVIEW_SUCCESS: "UPDATE_REVIEW_SUCCESS",
  UPDATE_REVIEW_FAILED: "UPDATE_REVIEW_FAILED",

  POST_UNREPORTED_REVIEW_REQUEST: "POST_UNREPORTED_REVIEW_REQUEST",
  POST_UNREPORTED_REVIEW_SUCCESS: "POST_UNREPORTED_REVIEW_SUCCESS",
  POST_UNREPORTED_REVIEW_FAILED: "POST_UNREPORTED_REVIEW_FAILED",

  REQUESTED_REVIEW_REQUEST: "REQUESTED_REVIEW_REQUEST",
  REQUESTED_REVIEW_SUCCESS: "REQUESTED_REVIEW_SUCCESS",
  REQUESTED_REVIEW_FAILED: "REQUESTED_REVIEW_FAILED",

  // Platform price 
  PLATFORM_PRICE_GET_REQUEST: "PLATFORM_PRICE_GET_REQUEST",
  PLATFORM_PRICE_GET_SUCCESS: "PLATFORM_PRICE_GET_SUCCESS",
  PLATFORM_PRICE_GET_FAILED: "PLATFORM_PRICE_GET_FAILED",
  PLATFORM_PRICE_UPDATE_REQUEST: "PLATFORM_PRICE_UPDATE_REQUEST",
  PLATFORM_PRICE_UPDATE_SUCCESS: "PLATFORM_PRICE_UPDATE_SUCCESS",
  PLATFORM_PRICE_UPDATE_FAILED: "PLATFORM_PRICE_UPDATE_FAILED",
  PLATFORM_PRICE_GET_DETAIL_REQUEST: "PLATFORM_PRICE_GET_DETAIL_REQUEST",
  PLATFORM_PRICE_GET_DETAIL_SUCCESS: "PLATFORM_PRICE_GET_DETAIL_SUCCESS",
  PLATFORM_PRICE_GET_DETAIL_FAILED: "PLATFORM_PRICE_GET_DETAIL_FAILED",
  PLATFORM_PRICE_DELETE_REQUEST: "PLATFORM_PRICE_DELETE_REQUEST",
  PLATFORM_PRICE_DELETE_SUCCESS: "PLATFORM_PRICE_DELETE_SUCCESS",
  PLATFORM_PRICE_DELETE_FAILED: "PLATFORM_PRICE_DELETE_FAILED",
  PLATFORM_PRICE_ADD_REQUEST: "PLATFORM_PRICE_ADD_REQUEST",
  PLATFORM_PRICE_ADD_SUCCESS: "PLATFORM_PRICE_ADD_SUCCESS",
  PLATFORM_PRICE_ADD_FAILED: "PLATFORM_PRICE_ADD_FAILED",
  PLATFORM_CLEAR_MESSAGE_REQUEST: "PLATFORM_CLEAR_MESSAGE_REQUEST",
  PLATFORM_CLEAR_MESSAGE_SUCCESS: "PLATFORM_CLEAR_MESSAGE_SUCCESS",

  // Bundle
  BUNDLE_GET_REQUEST: "BUNDLE_GET_REQUEST",
  BUNDLE_GET_SUCCESS: "BUNDLE_GET_SUCCESS",
  BUNDLE_GET_FAILED: "BUNDLE_GET_FAILED",
  BUNDLE_ADD_REQUEST: "BUNDLE_ADD_REQUEST",
  BUNDLE_ADD_SUCCESS: "BUNDLE_ADD_SUCCESS",
  BUNDLE_ADD_FAILED: "BUNDLE_ADD_FAILED",
  BUNDLE_DELETE_REQUEST: "BUNDLE_DELETE_REQUEST",
  BUNDLE_DELETE_SUCCESS: "BUNDLE_DELETE_SUCCESS",
  BUNDLE_DELETE_FAILED: "BUNDLE_DELETE_FAILED",
  BUNDLE_CLEAR_MESSAGE_REQUEST: "BUNDLE_CLEAR_MESSAGE_REQUEST",
  BUNDLE_CLEAR_MESSAGE_SUCCESS: "BUNDLE_CLEAR_MESSAGE_SUCCESS",

  // Media Management

  MEIDIA_GENRE_DROPDOWN_GET_REQUEST: "MEIDIA_GENRE_DROPDOWN_GET_REQUEST",
  MEDIA_GENRE_DROPDOWN_GET_SUCCESS: "MEDIA_GENRE_DROPDOWN_GET_SUCCESS",
  MEDIA_GENRE_DROPDOWN_GET_FAILED: "MEDIA_GENRE_DROPDOWN_GET_FAILED",

  MEDIA_LANGUAGE_DROPDOWN_GET_REQUEST: "MEDIA_LANGUAGE_DROPDOWN_GET_REQUEST",
  MEDIA_LANGUAGE_DROPDOWN_GET_SUCCESS: "MEDIA_LANGUAGE_DROPDOWN_GET_SUCCESS",
  MEDIA_LANGUAGE_DROPDOWN_GET_FAILED: "MEDIA_LANGUAGE_DROPDOWN_GET_FAILED",

  BULK_OTT_DEEPLINK_UPLOAD_REQUEST: "BULK_OTT_DEEPLINK_UPLOAD_REQUEST",
  BULK_OTT_DEEPLINK_UPLOAD_SUCCESS: "BULK_OTT_DEEPLINK_UPLOAD_SUCCESS",
  BULK_OTT_DEEPLINK_UPLOAD_FAILED: "BULK_OTT_DEEPLINK_UPLOAD_FAILED",

  NEW_BULK_OTT_DEEPLINK_UPLOAD_REQUEST: "NEW_BULK_OTT_DEEPLINK_UPLOAD_REQUEST",
  NEW_BULK_OTT_DEEPLINK_UPLOAD_SUCCESS: "NEW_BULK_OTT_DEEPLINK_UPLOAD_SUCCESS",
  NEW_BULK_OTT_DEEPLINK_UPLOAD_FAILED: "NEW_BULK_OTT_DEEPLINK_UPLOAD_FAILED",

  BULK_MEDIA_SYNC_TMDB_REQUEST: "BULK_MEDIA_SYNC_TMDB_REQUEST",
  BULK_MEDIA_SYNC_TMDB_SUCCESS: "BULK_MEDIA_SYNC_TMDB_SUCCESS",
  BULK_MEDIA_SYNC_TMDB_FAILED: "BULK_MEDIA_SYNC_TMDB_FAILED",

  MEDIA_CLEAR_MESSAGE_REQUEST: "MEDIA_CLEAR_MESSAGE_REQUEST",
  MEDIA_CLEAR_MESSAGE_SUCCESS: "MEDIA_CLEAR_MESSAGE_SUCCESS",

  MEDIA_OTT_DROPDOWN_GET_REQUEST: "MEDIA_OTT_DROPDOWN_GET_REQUEST",
  MEDIA_OTT_DROPDOWN_GET_SUCCESS: "MEDIA_OTT_DROPDOWN_GET_SUCCESS",
  MEDIA_OTT_DROPDOWN_GET_FAILED: "MEDIA_OTT_DROPDOWN_GET_FAILED",

  MEDIA_LIST_GET_REQUEST: "MEDIA_LIST_GET_REQUEST",
  MEDIA_LIST_GET_SUCCESS: "MEDIA_LIST_GET_SUCCESS",
  MEDIA_LIST_GET_FAILED: "MEDIA_LIST_GET_FAILED",

  ADD_MEDIA_LIST_GET_REQUEST: "ADD_MEDIA_LIST_GET_REQUEST",
  ADD_MEDIA_LIST_GET_SUCCESS: "ADD_MEDIA_LIST_GET_SUCCESS",
  ADD_MEDIA_LIST_GET_FAILED: "ADD_MEDIA_LIST_GET_FAILED",

  ADD_MEDIA_SEARCH_POST_REQUEST: "ADD_MEDIA_SEARCH_POST_REQUEST",
  ADD_MEDIA_SEARCH_POST_SUCCESS: "ADD_MEDIA_SEARCH_POST_SUCCESS",
  ADD_MEDIA_SEARCH_POST_FAILED: "ADD_MEDIA_SEARCH_POST_FAILED",

  GET_MEDIA_DETAILS_REQUEST: "GET_MEDIA_DETAILS_REQUEST",
  GET_MEDIA_DETAILS_SUCCESS: "GET_MEDIA_DETAILS_SUCCESS",
  GET_MEDIA_DETAILS_FAILED: "GET_MEDIA_DETAILS_FAILED",

  UPDATE_MEDIA_DETAILS_REQUEST: "UPDATE_MEDIA_DETAILS_REQUEST",
  UPDATE_MEDIA_DETAILS_SUCCESS: "UPDATE_MEDIA_DETAILS_SUCCESS",
  UPDATE_MEDIA_DETAILS_FAILED: "UPDATE_MEDIA_DETAILS_FAILED",

  ADD_NEW_MEDIA_REQUEST: "ADD_NEW_MEDIA_REQUEST",
  ADD_NEW_MEDIA_SUCCESS: "ADD_NEW_MEDIA_SUCCESS",
  ADD_NEW_MEDIA_FAILED: "ADD_NEW_MEDIA_FAILED",

  DELETE_MEDIA_REQUEST: "DELETE_MEDIA_REQUEST",
  DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
  DELETE_MEDIA_FAILED: "DELETE_MEDIA_FAILED",

  SYNC_PERSON_REQUEST : "SYNC_PERSON_REQUEST ",
  SYNC_PERSON_SUCCESS :"SYNC_PERSON_SUCCESS",
  SYNC_PERSON_FAILED :"SYNC_PERSON_FAILED ",

  MEDIA_RELATED_DETAIL_REQUEST:"MEDIA_RELATED_DETAIL_REQUEST",
  MEDIA_RELATED_DETAIL_SUCCESS:"MEDIA_RELATED_DETAIL_SUCCESS",
  MEDIA_RELATED_DETAIL_FAILED:"MEDIA_RELATED_DETAIL_FAILED",

  GET_SEARCH_TAG_LIST_REQUEST: "GET_SEARCH_TAG_LIST_REQUEST",
  GET_SEARCH_TAG_LIST_SUCCESS: "GET_SEARCH_TAG_LIST_SUCCESS",
  GET_SEARCH_TAG_LIST_FAILED: "GET_SEARCH_TAG_LIST_FAILED",

  GET_MEDIA_TAG_LIST_REQUEST: "GET_MEDIA_TAG_LIST_REQUEST",
  GET_MEDIA_TAG_LIST_SUCCESS: "GET_MEDIA_TAG_LIST_SUCCESS",
  GET_MEDIA_TAG_LIST_FAILED: "GET_MEDIA_TAG_LIST_FAILED",

  // Providers

  GET_PROVIDERS_REQUEST: "GET_PROVIDERS_REQUEST",
  GET_PROVIDERS_SUCCESS: "GET_PROVIDERS_SUCCESS",
  GET_PROVIDERS_FAILED: "GET_PROVIDERS_FAILED",

  GET_PROVIDERS_LANG_REQUEST: "GET_PROVIDERS_LANG_REQUEST",
  GET_PROVIDERS_LANG_SUCCESS: "GET_PROVIDERS_LANG_SUCCESS",
  GET_PROVIDERS_LANG_FAILED: "GET_PROVIDERS_LANG_FAILED",

  MEDIA_PROVIDERS_GET_REQUEST: "MEDIA_PROVIDERS_GET_REQUEST",
  MEDIA_PROVIDERS_GET_SUCCESS: "MEDIA_PROVIDERS_GET_SUCCESS",
  MEDIA_PROVIDERS_GET_FAILED: "MEDIA_PROVIDERS_GET_FAILED",

  UPDATE_MEDIA_PROVIDERS_REQUEST: "UPDATE_MEDIA_PROVIDERS_REQUEST",
  UPDATE_MEDIA_PROVIDERS_SUCCESS: "UPDATE_MEDIA_PROVIDERS_SUCCESS",
  UPDATE_MEDIA_PROVIDERS_FAILED: "UPDATE_MEDIA_PROVIDERS_FAILED",

  GET_MEDIA_CERTIFICATES_REQUEST: "GET_MEDIA_CERTIFICATES_REQUEST",
  GET_MEDIA_CERTIFICATES_SUCCESS: "GET_MEDIA_CERTIFICATES_SUCCESS",
  GET_MEDIA_CERTIFICATES_FAILED: "GET_MEDIA_CERTIFICATES_FAILED",

  // CATEGORIES MANAGMENT

  CATEGORY_LIST_GET_REQUEST: "CATEGORY_LIST_GET_REQUEST",
  CATEGORY_LIST_GET_SUCCESS: "CATEGORY_LIST_GET_SUCCESS",
  CATEGORY_LIST_GET_FAILED: "CATEGORY_LIST_GET_FAILED",

  CATEGORY_SEARCH_LIST_REQUEST: "CATEGORY_SEARCH_LIST_REQUEST",
  CATEGORY_SEARCH_LIST_SUCCESS: "CATEGORY_SEARCH_LIST_SUCCESS",
  CATEGORY_SEARCH_LIST_FAILED: "CATEGORY_SEARCH_LIST_FAILED",

  GET_CATEGORY_LISTING_DETAILS_REQUEST: "GET_CATEGORY_LISTING_DETAILS_REQUEST",
  GET_CATEGORY_LISTING_DETAILS_SUCCESS: "GET_CATEGORY_LISTING_DETAILS_SUCCESS",
  GET_CATEGORY_LISTING_DETAILS_FAILED: "GET_CATEGORY_LISTING_DETAILS_FAILED",


  DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILED: "DELETE_CATEGORY_FAILED",


  CATEGORY_CLEAR_MESSAGE_REQUEST: "CATEGORY_CLEAR_MESSAGE_REQUEST",
  CATEGORY_CLEAR_MESSAGE_SUCCESS: "CATEGORY_CLEAR_MESSAGE_SUCCESS",
  CATEGORY_CLEAR_MESSAGE_FAILED: "CATEGORY_CLEAR_MESSAGE_FAILED",

  POST_ADD_CATEGORY_REQUEST: "POST_ADD_CATEGORY_REQUEST",
  POST_ADD_CATEGORY_SUCCESS: "POST_ADD_CATEGORY_SUCCESS",
  POST_ADD_CATEGORY_FAILED: "POST_ADD_CATEGORY_FAILED",

  UPDATE_CATEGORY_REQUEST: "UPDATE_CATEGORY_REQUEST",
  UPDATE_CATEGORY_SUCCESS: "UPDATE_CATEGORY_SUCCESS",
  UPDATE_CATEGORY_FAILED: "UPDATE_CATEGORY_FAILED",

  GET_DETAILS_BY_CATEGORY_REQUEST: "GET_DETAILS_BY_CATEGORY_REQUEST",
  GET_DETAILS_BY_CATEGORY_SUCCESS: "GET_DETAILS_BY_CATEGORY_SUCCESS",
  GET_DETAILS_BY_CATEGORY_FAILED: "GET_DETAILS_BY_CATEGORY_FAILED",

  GET_COLLECTIONS_MEDIA_REQUEST: "GET_COLLECTIONS_MEDIA_REQUEST",
  GET_COLLECTIONS_MEDIA_SUCCESS: "GET_COLLECTIONS_MEDIA_SUCCESS",
  GET_COLLECTIONS_MEDIA_FAILED: "GET_COLLECTIONS_MEDIA_FAILED",

  UPDATE_COLLECTIONS_MEDIA_REQUEST: "UPDATE_COLLECTIONS_MEDIA_REQUEST",
  UPDATE_COLLECTIONS_MEDIA_SUCCESS: "UPDATE_COLLECTIONS_MEDIA_SUCCESS",
  UPDATE_COLLECTIONS_MEDIA_FAILED: "UPDATE_COLLECTIONS_MEDIA_FAILED",

  YTD_COLLECTION_LIST_GET_REQUEST: "YTD_COLLECTION_LIST_GET_REQUEST",
  YTD_COLLECTION_LIST_GET_SUCCESS: "YTD_COLLECTION_LIST_GET_SUCCESS",
  YTD_COLLECTION_LIST_GET_FAILED: "YTD_COLLECTION_LIST_GET_FAILED",

  POST_ADD_YTB_COLLECTION_REQUEST: "POST_ADD_YTB_COLLECTION_REQUEST",
  POST_ADD_YTB_COLLECTION_SUCCESS: "POST_ADD_YTB_COLLECTION_SUCCESS",
  POST_ADD_YTB_COLLECTION_FAILED: "POST_ADD_YTB_COLLECTION_FAILED",

  UPDATE_YTB_COLLECTION_REQUEST: "UPDATE_YTB_COLLECTION_REQUEST",
  UPDATE_YTB_COLLECTION_SUCCESS: "UPDATE_YTB_COLLECTION_SUCCESS",
  UPDATE_YTB_COLLECTION_FAILED: "UPDATE_YTB_COLLECTION_FAILED",

  DELETE_YTB_COLLECTION_REQUEST: "DELETE_YTB_COLLECTION_REQUEST",
  DELETE_YTB_COLLECTION_SUCCESS: "DELETE_YTB_COLLECTION_SUCCESS",
  DELETE_YTB_COLLECTION_FAILED: "DELETE_YTB_COLLECTION_FAILED",

  GET_DETAILS_BY_COLLECTION_ID_REQUEST: "GET_DETAILS_BY_COLLECTION_ID_REQUEST",
  GET_DETAILS_BY_COLLECTION_ID_SUCCESS: "GET_DETAILS_BY_COLLECTION_ID_SUCCESS",
  GET_DETAILS_BY_COLLECTION_ID_FAILED: "GET_DETAILS_BY_COLLECTION_ID_FAILED",

  GET_COLLECTIONS_YTB_LINKS_REQUEST: "GET_COLLECTIONS_YTB_LINKS_REQUEST",
  GET_COLLECTIONS_YTB_LINKS_SUCCESS: "GET_COLLECTIONS_YTB_LINKS_SUCCESS",
  GET_COLLECTIONS_YTB_LINKS_FAILED: "GET_COLLECTIONS_YTB_LINKS_FAILED",

  UPDATE_YTB_LINK_REQUEST: "UPDATE_YTB_LINK_REQUEST",
  UPDATE_YTB_LINK_SUCCESS: "UPDATE_YTB_LINK_SUCCESS",
  UPDATE_YTB_LINK_FAILED: "UPDATE_YTB_LINK_FAILED",

  DELETE_YTB_LINK_REQUEST: "DELETE_YTB_LINK_REQUEST",
  DELETE_YTB_LINK_SUCCESS: "DELETE_YTB_LINK_SUCCESS",
  DELETE_YTB_LINK_FAILED: "DELETE_YTB_LINK_FAILED",

  // FRENZI POINTS


  UPDATE_BULK_FRENZI_POINT_REQUEST: "UPDATE_BULK_FRENZI_POINT_REQUEST",
  UPDATE_BULK_FRENZI_POINT_SUCCESS: "UPDATE_BULK_FRENZI_POINT_SUCCESS",
  UPDATE_BULK_FRENZI_POINT_FAILED: "UPDATE_BULK_FRENZI_POINT_FAILED",

  GET_FRENZI_ACTIVITY_LIST_REQUEST: "GET_FRENZI_ACTIVITY_LIST_REQUEST",
  GET_FRENZI_ACTIVITY_LIST_SUCCESS: "GET_FRENZI_ACTIVITY_LIST_SUCCESS",
  GET_FRENZI_ACTIVITY_LIST_FAILED: "GET_FRENZI_ACTIVITY_LIST_FAILED",

  GET_POINTS_LIST_REQUEST: "GET_POINTS_LIST_REQUEST",
  GET_POINTS_LIST_SUCCESS: "GET_POINTS_LIST_SUCCESS",
  GET_POINTS_LIST_FAILED: "GET_POINTS_LIST_FAILED",

  GET_POINTS_LIST_DETAILS_REQUEST: "GET_POINTS_LIST_DETAILS_REQUEST",
  GET_POINTS_LIST_DETAILS_SUCCESS: "GET_POINTS_LIST_DETAILS_SUCCESS",
  GET_POINTS_LIST_DETAILS_FAILED: "GET_POINTS_LIST_DETAILS_FAILED",

  UPDATE_FRENZI_POINT_REQUEST: "UPDATE_FRENZI_POINT_REQUEST",
  UPDATE_FRENZI_POINT_SUCCESS: "UPDATE_FRENZI_POINT_SUCCESS",
  UPDATE_FRENZI_POINT_FAILED: "UPDATE_FRENZI_POINT_FAILED",

  UPDATE_ACTIVITY_POINT_REQUEST: "UPDATE_ACTIVITY_POINT_REQUEST",
  UPDATE_ACTIVITY_POINT_SUCCESS: "UPDATE_ACTIVITY_POINT_SUCCESS",
  UPDATE_ACTIVITY_POINT_FAILED: "UPDATE_ACTIVITY_POINT_FAILED",

  FRENZI_POINTS_PAGE_CLEAR_MESSAGE_REQUEST: "FRENZI_POINTS_PAGE_CLEAR_MESSAGE_REQUEST",
  FRENZI_POINTS_PAGE_CLEAR_MESSAGE_SUCCESS: "FRENZI_POINTS_PAGE_CLEAR_MESSAGE_SUCCESS",

  DELETE_FRENZI_POINT_REQUEST: "DELETE_FRENZI_POINT_REQUEST",
  DELETE_FRENZI_POINT_SUCCESS: "DELETE_FRENZI_POINT_SUCCESS",
  DELETE_FRENZI_POINT_FAILED: "DELETE_FRENZI_POINT_FAILED",

  UPLOAD_FRENZI_POINTS_REQUEST: "UPLOAD_FRENZI_POINTS_REQUEST",
  UPLOAD_FRENZI_POINTS_SUCCESS: "UPLOAD_FRENZI_POINTS_SUCCESS",
  UPLOAD_FRENZI_POINTS_FAILED: "UPLOAD_FRENZI_POINTS_FAILED",

  // frenzi key value
  FRENZI_KEY_GET_REQUEST: "FRENZI_KEY_GET_REQUEST",
  FRENZI_KEY_GET_SUCCESS: "FRENZI_KEY_GET_SUCCESS",
  FRENZI_KEY_GET_FAILED: "FRENZI_KEY_GET_FAILED",

  FRENZI_KEY_CLEAR_MESSAGE_REQUEST: "FRENZI_KEY_CLEAR_MESSAGE_REQUEST",
  FRENZI_KEY_CLEAR_MESSAGE_SUCCESS: "FRENZI_KEY_CLEAR_MESSAGE_SUCCESS",

  FRENZI_KEY_POST_REQUEST: "FRENZI_KEY_POST_REQUEST",
  FRENZI_KEY_POST_SUCCESS: "FRENZI_KEY_POST_SUCCESS",
  FRENZI_KEY_POST_FAILED: "FRENZI_KEY_POST_FAILED",

  FRENZI_KEY_DELETE_REQUEST: "FRENZI_KEY_DELETE_REQUEST",
  FRENZI_KEY_DELETE_SUCCESS: "FRENZI_KEY_DELETE_SUCCESS",
  FRENZI_KEY_DELETE_FAILED: "FRENZI_KEY_DELETE_FAILED",

  FRENZI_KEY_UPDATE_REQUEST: "FRENZI_KEY_UPDATE_REQUEST",
  FRENZI_KEY_UPDATE_SUCCESS: "FRENZI_KEY_UPDATE_SUCCESS",
  FRENZI_KEY_UPDATE_FAILED: "FRENZI_KEY_UPDATE_FAILED",

  // watchlist
  WATCHLIST_GET_REQUEST: "WATCHLIST_GET_REQUEST",
  WATCHLIST_GET_SUCCESS: "WATCHLIST_GET_SUCCESS",
  WATCHLIST_GET_FAILED: "WATCHLIST_GET_FAILED",

  CREATE_WATCHLIST_REQUEST: "CREATE_WATCHLIST_REQUEST",
  CREATE_WATCHLIST_SUCCESS: "CREATE_WATCHLIST_SUCCESS",
  CREATE_WATCHLIST_FAILED: "CREATE_WATCHLIST_FAILED",

  WATCHLIST_CLEAR_MESSAGE_REQUEST: "WATCHLIST_CLEAR_MESSAGE_REQUEST",
  WATCHLIST_CLEAR_MESSAGE_SUCCESS: "WATCHLIST_CLEAR_MESSAGE_SUCCESS",
  WATCHLIST_CLEAR_MESSAGE_FAILED: "WATCHLIST_CLEAR_MESSAGE_FAILED",

  DELETE_WATCHLIST_REQUEST: "DELETE_WATCHLIST_REQUEST",
  DELETE_WATCHLIST_SUCCESS: "DELETE_WATCHLIST_SUCCESS",
  DELETE_WATCHLIST_FAILED: "DELETE_WATCHLIST_FAILED",

  UPDATE_WATCHLIST_REQUEST: "UPDATE_WATCHLIST_REQUEST",
  UPDATE_WATCHLIST_SUCCESS: "UPDATE_WATCHLIST_SUCCESS",
  UPDATE_WATCHLIST_FAILED: "UPDATE_WATCHLIST_FAILED",

  WATCHLIST_MEMBER_GET_REQUEST: "WATCHLIST_MEMBER_GET_REQUEST",
  WATCHLIST_MEMBER_GET_SUCCESS: "WATCHLIST_MEMBER_GET_SUCCESS",
  WATCHLIST_MEMBER_GET_FAILED: "WATCHLIST_MEMBER_GET_FAILED",

  WATCHLIST_MEDIA_GET_REQUEST: "WATCHLIST_MEDIA_GET_REQUEST",
  WATCHLIST_MEDIA_GET_SUCCESS: "WATCHLIST_MEDIA_GET_SUCCESS",
  WATCHLIST_MEDIA_GET_FAILED: "WATCHLIST_MEDIA_GET_FAILED",

  ADD_REMOVE_MEMBERS_REQUEST: "ADD_REMOVE_MEMBERS_REQUEST",
  ADD_REMOVE_MEMBERS_SUCCESS: "ADD_REMOVE_MEMBERS_SUCCESS",
  ADD_REMOVE_MEMBERS_FAILED: "ADD_REMOVE_MEMBERS_FAILED",

  ADD_REMOVE_MEDIA_REQUEST: "ADD_REMOVE_MEDIA_REQUEST",
  ADD_REMOVE_MEDIA_SUCCESS: "ADD_REMOVE_MEDIA_SUCCESS",
  ADD_REMOVE_MEDIA_FAILED: "ADD_REMOVE_MEDIA_FAILED",

  ADD_REMOVE_MEDIA_TAGS_REQUEST: "ADD_REMOVE_MEDIA_TAGS_REQUEST",
  ADD_REMOVE_MEDIA_TAGS_SUCCESS: "ADD_REMOVE_MEDIA_TAGS_SUCCESS",
  ADD_REMOVE_MEDIA_TAGS_FAILED: "ADD_REMOVE_MEDIA_TAGS_FAILED",

  GET_MEDIA_CAST_REQUEST: "GET_MEDIA_CAST_REQUEST",
  GET_MEDIA_CAST_SUCCESS: "GET_MEDIA_CAST_SUCCESS",
  GET_MEDIA_CAST_FAILED: "GET_MEDIA_CAST_FAILED",

  GET_MEDIA_CREW_REQUEST: "GET_MEDIA_CREW_REQUEST",
  GET_MEDIA_CREW_SUCCESS: "GET_MEDIA_CREW_SUCCESS",
  GET_MEDIA_CREW_FAILED: "GET_MEDIA_CREW_FAILED",

  GET_MEDIA_CSV_FILE_REQUEST: "GET_MEDIA_CSV_FILE_REQUEST",
  GET_MEDIA_CSV_FILE_SUCCESS: "GET_MEDIA_CSV_FILE_SUCCESS",
  GET_MEDIA_CSV_FILE_FAILED: "GET_MEDIA_CSV_FILE_FAILED",

  GET_TRAILER_CSV_FILE_REQUEST: "GET_TRAILER_CSV_FILE_REQUEST",
  GET_TRAILER_CSV_FILE_SUCCESS: "GET_TRAILER_CSV_FILE_SUCCESS",
  GET_TRAILER_CSV_FILE_FAILED: "GET_TRAILER_CSV_FILE_FAILED",


  // CAST&CREW 

  GET_PERSON_SEARCH_LIST_REQUEST: "GET_PERSON_SEARCH_LIST_REQUEST",
  GET_PERSON_SEARCH_LIST_SUCCESS: "GET_PERSON_SEARCH_LIST_SUCCESS",
  GET_PERSON_SEARCH_LIST_FAILED: "GET_PERSON_SEARCH_LIST_FAILED",

  PERSON_DELETE_REQUEST: "PERSON_DELETE_REQUEST",
  PERSON_DELETE_SUCCESS: "PERSON_DELETE_SUCCESS",
  PERSON_DELETE_FAILED: "PERSON_DELETE_FAILED",

  PERSON_CLEAR_MESSAGE_REQUEST: "PERSON_CLEAR_MESSAGE_REQUEST",
  PERSON_CLEAR_MESSAGE_SUCCESS: "PERSON_CLEAR_MESSAGE_SUCCESS",

  PERSON_GET_DETAILS_REQUEST: "PERSON_GET_DETAILS_REQUEST",
  PERSON_GET_DETAILS_SUCCESS: "PERSON_GET_DETAILS_SUCCESS",
  PERSON_GET_DETAILS_FAILED: "PERSON_GET_DETAILS_FAILED",

  PERSON_UPDATE_REQUEST: "PERSON_UPDATE_REQUEST",
  PERSON_UPDATE_SUCCESS: "PERSON_UPDATE_SUCCESS",
  PERSON_UPDATE_FAILED: "PERSON_UPDATE_FAILED",

  PERSON_ADD_REQUEST: "PERSON_ADD_REQUEST",
  PERSON_ADD_SUCCESS: "PERSON_ADD_SUCCESS",
  PERSON_ADD_FAILED: "PERSON_ADD_FAILED",

  // OPERATIONS
  NOTIFY_GET_REQUEST: "NOTIFY_GET_REQUEST",
  NOTIFY_GET_SUCCESS: "NOTIFY_GET_SUCCESS",
  NOTIFY_GET_FAILED: "NOTIFY_GET_FAILED",

  GET_ERROR_REPORT_LIST_REQUEST: "GET_ERROR_REPORT_LIST_REQUEST",
  GET_ERROR_REPORT_LIST_SUCCESS: "GET_ERROR_REPORT_LIST_SUCCESS",
  GET_ERROR_REPORT_LIST_FAILED: "GET_ERROR_REPORT_LIST_FAILED",

  POST_ERROR_REPORT_STATUS_REQUEST: "POST_ERROR_REPORT_STATUS_REQUEST",
  POST_ERROR_REPORT_STATUS_SUCCESS: "POST_ERROR_REPORT_STATUS_SUCCESS",
  POST_ERROR_REPORT_STATUS_FAILED: "POST_ERROR_REPORT_STATUS_FAILED",

  POST_ERROR_REPORT_STATUS_UPDATE_REQUEST: "POST_ERROR_REPORT_STATUS_UPDATE_REQUEST",
  POST_ERROR_REPORT_STATUS_UPDATE_SUCCESS: "POST_ERROR_REPORT_STATUS_UPDATE_SUCCESS",
  POST_ERROR_REPORT_STATUS_UPDATE_FAILED: "POST_ERROR_REPORT_STATUS_UPDATE_FAILED",

  GET_FEEDBACK_LIST_REQUEST: "GET_FEEDBACK_LIST_REQUEST",
  GET_FEEDBACK_LIST_SUCCESS: "GET_FEEDBACK_LIST_SUCCESS",
  GET_FEEDBACK_LIST_FAILED: "GET_FEEDBACK_LIST_FAILED",

  OPERATIONS_CLEAR_MESSAGE_REQUEST: "OPERATIONS_CLEAR_MESSAGE_REQUEST",
  OPERATIONS_CLEAR_MESSAGE_SUCCESS: "OPERATIONS_CLEAR_MESSAGE_SUCCESS",

  GET_CX_ENGINE_LIST_REQUEST: "GET_CX_ENGINE_LIST_REQUEST",
  GET_CX_ENGINE_LIST_SUCCESS: "GET_CX_ENGINE_LIST_SUCCESS",
  GET_CX_ENGINE_LIST_FAILED: "GET_CX_ENGINE_LIST_FAILED",

  GET_CX_DEEPLINK_LIST_REQUEST: "GET_CX_DEEPLINK_LIST_REQUEST",
  GET_CX_DEEPLINK_LIST_SUCCESS: "GET_CX_DEEPLINK_LIST_SUCCESS",
  GET_CX_DEEPLINK_LIST_FAILED: "GET_CX_DEEPLINK_LIST_FAILED",

  DELETE_CX_RECORD_REQUEST: "DELETE_CX_RECORD_REQUEST",
  DELETE_CX_RECORD_SUCCESS: "DELETE_CX_RECORD_SUCCESS",
  DELETE_CX_RECORD_FAILED: "DELETE_CX_RECORD_FAILED",


  GET_CX_REMARK_UPDATE_REQUEST: "GET_CX_REMARK_UPDATE_REQUEST",
  GET_CX_REMARK_UPDATE_SUCCESS: "GET_CX_REMARK_UPDATE_SUCCESS",
  GET_CX_REMARK_UPDATE_FAILED: "GET_CX_REMARK_UPDATE_FAILED",

  EXPORT_CX_CSV_REQUEST: "EXPORT_CX_CSV_REQUEST",
  EXPORT_CX_CSV_SUCCESS: "EXPORT_CX_CSV_SUCCESS",
  EXPORT_CX_CSV_FAILED: "EXPORT_CX_CSV_FAILED",
  
  CLEAR_EXPORT_DATA: "CLEAR_EXPORT_DATA",

  // MANAGE PAGE

  GET_NEWS_ARTICLE_REQUEST: "GET_NEWS_ARTICLE_REQUEST",
  GET_NEWS_ARTICLE_SUCCESS: "GET_NEWS_ARTICLE_SUCCESS",
  GET_NEWS_ARTICLE_FAILED: "GET_NEWS_ARTICLE_FAILED",

  GET_INBETWEEN_FEEDS_REQUEST: "GET_INBETWEEN_FEEDS_REQUEST",
  GET_INBETWEEN_FEEDS_SUCCESS: "GET_INBETWEEN_FEEDS_SUCCESS",
  GET_INBETWEEN_FEEDS_FAILED: "GET_INBETWEEN_FEEDS_FAILED",

  GET_INBETWEEN_FEEDS_TYPE_REQUEST: "GET_INBETWEEN_FEEDS_TYPE_REQUEST",
  GET_INBETWEEN_FEEDS_TYPE_SUCCESS: "GET_INBETWEEN_FEEDS_TYPE_SUCCESS",
  GET_INBETWEEN_FEEDS_TYPE_FAILED: "GET_INBETWEEN_FEEDS_TYPE_FAILED",

  UPDATE_NEWS_REQUEST: "UPDATE_NEWS_REQUEST",
  UPDATE_NEWS_SUCCESS: "UPDATE_NEWS_SUCCESS",
  UPDATE_NEWS_FAILED: "UPDATE_NEWS_FAILED",

  ADD_NEWS_REQUEST: "ADD_NEWS_REQUEST",
  ADD_NEWS_SUCCESS: "ADD_NEWS_SUCCESS",
  ADD_NEWS_FAILED: "ADD_NEWS_FAILED",

  ADD_FEED_REQUEST: "ADD_FEED_REQUEST",
  ADD_FEED_SUCCESS: "ADD_FEED_SUCCESS",
  ADD_FEED_FAILED: "ADD_FEED_FAILED",

  DELETE_NEWS_REQUEST: "DELETE_NEWS_REQUEST",
  DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
  DELETE_NEWS_FAILED: "DELETE_NEWS_FAILED",

  GET_FAQ_REQUEST: "GET_FAQ_REQUEST",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILED: "GET_FAQ_FAILED",

  GET_FAQ_EDIT_REQUEST: "GET_FAQ_EDIT_REQUEST",
  GET_FAQ_EDIT_SUCCESS: "GET_FAQ_EDIT_SUCCESS",
  GET_FAQ_EDIT_FAILED: "GET_FAQ_EDIT_FAILED",

  UPDATE_FAQ_REQUEST: "UPDATE_FAQ_REQUEST",
  UPDATE_FAQ_SUCCESS: "UPDATE_FAQ_SUCCESS",
  UPDATE_FAQ_FAILED: "UPDATE_FAQ_FAILED",

  ADD_FAQ_REQUEST: "ADD_FAQ_REQUEST",
  ADD_FAQ_SUCCESS: "ADD_FAQ_SUCCESS",
  ADD_FAQ_FAILED: "ADD_FAQ_FAILED",

  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILED: "DELETE_FAQ_FAILED",

  GET_TNC_REQUEST: "GET_TNC_REQUEST",
  GET_TNC_SUCCESS: "GET_TNC_SUCCESS",
  GET_TNC_FAILED: "GET_TNC_FAILED",

  UPDATE_TNC_REQUEST: "UPDATE_TNC_REQUEST",
  UPDATE_TNC_SUCCESS: "UPDATE_TNC_SUCCESS",
  UPDATE_TNC_FAILED: "UPDATE_TNC_FAILED",

  MANAGE_PAGE_CLEAR_MESSAGE_REQUEST: 'MANAGE_PAGE_CLEAR_MESSAGE_REQUEST',
  MANAGE_PAGE_CLEAR_MESSAGE_SUCCESS: 'MANAGE_PAGE_CLEAR_MESSAGE_SUCCESS',

  GET_FAQ_REQUEST: "GET_FAQ_REQUEST",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILED: "GET_FAQ_FAILED",

  // ADMIN ACCESS

  GET_ADMIN_LIST_REQUEST: "GET_ADMIN_LIST_REQUEST",
  GET_ADMIN_LIST_SUCCESS: "GET_ADMIN_LIST_SUCCESS",
  GET_ADMIN_LIST_FAILED: "GET_ADMIN_LIST_FAILED",

  POST_ADD_ADMIN_REQUEST: "POST_ADD_ADMIN_REQUEST",
  POST_ADD_ADMIN_SUCCESS: "POST_ADD_ADMIN_SUCCESS",
  POST_ADD_ADMIN_FAILED: "POST_ADD_ADMIN_FAILED",


  UPDATE_ADMIN_LIST_REQUEST: "UPDATE_ADMIN_LIST_REQUEST",
  UPDATE_ADMIN_LIST_SUCCESS: "UPDATE_ADMIN_LIST_SUCCESS",
  UPDATE_ADMIN_LIST_FAILED: "UPDATE_ADMIN_LIST_FAILED",


  GET_ADMIN_PERMISSION_DROPDOWN_REQUEST: "GET_ADMIN_PERMISSION_DROPDOWN_REQUEST",
  GET_ADMIN_PERMISSION_DROPDOWN_SUCCESS: "GET_ADMIN_PERMISSION_DROPDOWN_SUCCESS",
  GET_ADMIN_PERMISSION_DROPDOWN_FAILED: "GET_ADMIN_PERMISSION_DROPDOWN_FAILED",

  DELETE_ADMIN_REQUEST: "DELETE_ADMIN_REQUEST",
  DELETE_ADMIN_SUCCESS: "DELETE_ADMIN_SUCCESS",
  DELETE_ADMIN_FAILED: "DELETE_ADMIN_FAILED",

  GET_RETRIVE_ADMIN_DETAIL_REQUEST: "GET_RETRIVE_ADMIN_DETAIL_REQUEST",
  GET_RETRIVE_ADMIN_DETAIL_SUCCESS: "GET_RETRIVE_ADMIN_DETAIL_SUCCESS",
  GET_RETRIVE_ADMIN_DETAIL_FAILED: "GET_RETRIVE_ADMIN_DETAIL_FAILED",

  ADMIN_CLEAR_MESSAGE_REQUEST: 'ADMIN_CLEAR_MESSAGE_REQUEST',
  ADMIN_CLEAR_MESSAGE_SUCCESS: 'ADMIN_CLEAR_MESSAGE_SUCCESS',

  CHANGE_ADMIN_PASSWORD_REQUEST: "CHANGE_ADMIN_PASSWORD_REQUEST",
  CHANGE_ADMIN_PASSWORD_SUCCESS: "CHANGE_ADMIN_PASSWORD_SUCCESS",
  CHANGE_ADMIN_PASSWORD_FAILED: "CHANGE_ADMIN_PASSWORD_FAILED",


  // dynamic feed 
  DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_REQUEST: 'DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_REQUEST',
  DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_SUCCESS: 'DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_SUCCESS',

  GET_COLLECTIONS_REQUEST: "GET_COLLECTIONS_REQUEST",
  GET_COLLECTIONS_SUCCESS: "GET_COLLECTIONS_SUCCESS",
  GET_COLLECTIONS_FAILED: "GET_COLLECTIONS_FAILED",

  PUSH_DYNAMIC_POST_REQUEST: "PUSH_DYNAMIC_POST_REQUEST",
  PUSH_DYNAMIC_POST_SUCCESS: "PUSH_DYNAMIC_POST_SUCCESS",
  PUSH_DYNAMIC_POST_FAILED: "PUSH_DYNAMIC_POST_FAILED",

  GET_ACTOR_DROPDOWN_REQUEST: "GET_ACTOR_DROPDOWN_REQUEST",
  GET_ACTOR_DROPDOWN_SUCCESS: "GET_ACTOR_DROPDOWN_SUCCESS",
  GET_ACTOR_DROPDOWN_FAILED: "GET_ACTOR_DROPDOWN_FAILED",

  // polls & quizes

  GET_POLLS_REQUEST: "GET_POLLS_REQUEST",
  GET_POLLS_SUCCESS: "GET_POLLS_SUCCESS",
  GET_POLLS_FAILED: "GET_POLLS_FAILED",

  UPDATE_POLL_REQUEST: "UPDATE_POLL_REQUEST",
  UPDATE_POLL_SUCCESS: "UPDATE_POLL_SUCCESS",
  UPDATE_POLL_FAILED: "UPDATE_POLL_FAILED",

  DELETE_POLL_REQUEST: "DELETE_POLL_REQUEST",
  DELETE_POLL_SUCCESS: "DELETE_POLL_SUCCESS",
  DELETE_POLL_FAILED: "DELETE_POLL_FAILED",

  CREATE_POLL_REQUEST: "CREATE_POLL_REQUEST",
  CREATE_POLL_SUCCESS: "CREATE_POLL_SUCCESS",
  CREATE_POLL_FAILED: "CREATE_POLL_FAILED",

  POLLS_CLEAR_MESSAGE_REQUEST: "POLLS_CLEAR_MESSAGE_REQUEST",
  POLLS_CLEAR_MESSAGE_SUCCESS: "POLLS_CLEAR_MESSAGE_SUCCESS",

  GET_QUIZ_REQUEST: "GET_QUIZ_REQUEST",
  GET_QUIZ_SUCCESS: "GET_QUIZ_SUCCESS",
  GET_QUIZ_FAILED: "GET_QUIZ_FAILED",

  GET_QUIZ_DATA_REQUEST: "GET_QUIZ_DATA_REQUEST",
  GET_QUIZ_DATA_SUCCESS: "GET_QUIZ_DATA_SUCCESS",
  GET_QUIZ_DATA_FAILED: "GET_QUIZ_DATA_FAILED",

  UPDATE_QUIZ_REQUEST: "UPDATE_QUIZ_REQUEST",
  UPDATE_QUIZ_SUCCESS: "UPDATE_QUIZ_SUCCESS",
  UPDATE_QUIZ_FAILED: "UPDATE_QUIZ_FAILED",

  DELETE_QUIZ_REQUEST: "DELETE_QUIZ_REQUEST",
  DELETE_QUIZ_SUCCESS: "DELETE_QUIZ_SUCCESS",
  DELETE_QUIZ_FAILED: "DELETE_QUIZ_FAILED",

  CREATE_QUIZ_REQUEST: "CREATE_QUIZ_REQUEST",
  CREATE_QUIZ_SUCCESS: "CREATE_QUIZ_SUCCESS",
  CREATE_QUIZ_FAILED: "CREATE_QUIZ_FAILED",


  // discovery

  DISCOVERY_GET_REQUEST: "DISCOVERY_GET_REQUEST",
  DISCOVERY_GET_SUCCESS: "DISCOVERY_GET_SUCCESS",
  DISCOVERY_GET_FAILED: "DISCOVERY_GET_FAILED",

  DISCOVERY_CLEAR_MESSAGE_REQUEST: "DISCOVERY_CLEAR_MESSAGE_REQUEST",
  DISCOVERY_CLEAR_MESSAGE_SUCCESS: "DISCOVERY_CLEAR_MESSAGE_SUCCESS",

  DISCOVERY_POST_REQUEST: "DISCOVERY_POST_REQUEST",
  DISCOVERY_POST_SUCCESS: "DISCOVERY_POST_SUCCESS",
  DISCOVERY_POST_FAILED: "DISCOVERY_POST_FAILED",

  DISCOVERY_DELETE_REQUEST: "DISCOVERY_DELETE_REQUEST",
  DISCOVERY_DELETE_SUCCESS: "DISCOVERY_DELETE_SUCCESS",
  DISCOVERY_DELETE_FAILED: "DISCOVERY_DELETE_FAILED",

  DISCOVERY_UPDATE_REQUEST: "DISCOVERY_UPDATE_REQUEST",
  DISCOVERY_UPDATE_SUCCESS: "DISCOVERY_UPDATE_SUCCESS",
  DISCOVERY_UPDATE_FAILED: "DISCOVERY_UPDATE_FAILED",


  // blogs

  BLOGS_CLEAR_MESSAGE_REQUEST: "BLOGS_CLEAR_MESSAGE_REQUEST",
  BLOGS_CLEAR_MESSAGE_SUCCESS: "BLOGS_CLEAR_MESSAGE_SUCCESS",

  BLOGS_CLEAR_STORY_DATA_REQUEST: "BLOGS_CLEAR_STORY_DATA_REQUEST",
  BLOGS_CLEAR_STORY_DATA_SUCCESS: "BLOGS_CLEAR_STORY_DATA_SUCCESS",

  CATEGORY_GET_REQUEST: "CATEGORY_GET_REQUEST",
  CATEGORY_GET_SUCCESS: "CATEGORY_GET_SUCCESS",
  CATEGORY_GET_FAILED: "CATEGORY_GET_FAILED",

  CATEGORY_UPDATE_REQUEST: "CATEGORY_UPDATE_REQUEST",
  CATEGORY_UPDATE_SUCCESS: "CATEGORY_UPDATE_SUCCESS",
  CATEGORY_UPDATE_FAILED: "CATEGORY_UPDATE_FAILED",

  CATEGORY_DELETE_REQUEST: "CATEGORY_DELETE_REQUEST",
  CATEGORY_DELETE_SUCCESS: "CATEGORY_DELETE_SUCCESS",
  CATEGORY_DELETE_FAILED: "CATEGORY_DELETE_FAILED",

  CATEGORY_CREATE_REQUEST: "CATEGORY_CREATE_REQUEST",
  CATEGORY_CREATE_SUCCESS: "CATEGORY_CREATE_SUCCESS",
  CATEGORY_CREATE_FAILED: "CATEGORY_CREATE_FAILED",

  SUBCATEGORY_GET_REQUEST: "SUBCATEGORY_GET_REQUEST",
  SUBCATEGORY_GET_SUCCESS: "SUBCATEGORY_GET_SUCCESS",
  SUBCATEGORY_GET_FAILED: "SUBCATEGORY_GET_FAILED",

  SUBCATEGORY_UPDATE_REQUEST: "SUBCATEGORY_UPDATE_REQUEST",
  SUBCATEGORY_UPDATE_SUCCESS: "SUBCATEGORY_UPDATE_SUCCESS",
  SUBCATEGORY_UPDATE_FAILED: "SUBCATEGORY_UPDATE_FAILED",

  SUBCATEGORY_DELETE_REQUEST: "SUBCATEGORY_DELETE_REQUEST",
  SUBCATEGORY_DELETE_SUCCESS: "SUBCATEGORY_DELETE_SUCCESS",
  SUBCATEGORY_DELETE_FAILED: "SUBCATEGORY_DELETE_FAILED",

  SUBCATEGORY_CREATE_REQUEST: "SUBCATEGORY_CREATE_REQUEST",
  SUBCATEGORY_CREATE_SUCCESS: "SUBCATEGORY_CREATE_SUCCESS",
  SUBCATEGORY_CREATE_FAILED: "SUBCATEGORY_CREATE_FAILED",

  BLOG_GET_REQUEST: "BLOG_GET_REQUEST",
  BLOG_GET_SUCCESS: "BLOG_GET_SUCCESS",
  BLOG_GET_FAILED: "BLOG_GET_FAILED",

  BLOG_UPDATE_REQUEST: "BLOG_UPDATE_REQUEST",
  BLOG_UPDATE_SUCCESS: "BLOG_UPDATE_SUCCESS",
  BLOG_UPDATE_FAILED: "BLOG_UPDATE_FAILED",

  BLOG_DELETE_REQUEST: "BLOG_DELETE_REQUEST",
  BLOG_DELETE_SUCCESS: "BLOG_DELETE_SUCCESS",
  BLOG_DELETE_FAILED: "BLOG_DELETE_FAILED",

  BLOG_CREATE_REQUEST: "BLOG_CREATE_REQUEST",
  BLOG_CREATE_SUCCESS: "BLOG_CREATE_SUCCESS",
  BLOG_CREATE_FAILED: "BLOG_CREATE_FAILED",

  BLOG_LANG_GET_REQUEST: "BLOG_LANG_GET_REQUEST",
  BLOG_LANG_GET_SUCCESS: "BLOG_LANG_GET_SUCCESS",
  BLOG_LANG_GET_FAILED: "BLOG_LANG_GET_FAILED",

  BLOG_DETAIL_GET_REQUEST: "BLOG_DETAIL_GET_REQUEST",
  BLOG_DETAIL_GET_SUCCESS: "BLOG_DETAIL_GET_SUCCESS",
  BLOG_DETAIL_GET_FAILED: "BLOG_DETAIL_GET_FAILED",

  // advertisementReducer
  ADVSTATE_CLEAR_MESSAGE_REQUEST: "ADVSTATE_CLEAR_MESSAGE_REQUEST",
  ADVSTATE_CLEAR_MESSAGE_SUCCESS: "ADVSTATE_CLEAR_MESSAGE_SUCCESS",

  ADVSTATE_LIST_GET_REQUEST: "ADVSTATE_LIST_GET_REQUEST",
  ADVSTATE_LIST_GET_SUCCESS: "ADVSTATE_LIST_GET_SUCCESS",
  ADVSTATE_LIST_GET_FAILED: "ADVSTATE_LIST_GET_FAILED",

  ADVSTATE_LIST_CREATE_REQUEST: "ADVSTATE_LIST_CREATE_REQUEST",
  ADVSTATE_LIST_CREATE_SUCCESS: "ADVSTATE_LIST_CREATE_SUCCESS",
  ADVSTATE_LIST_CREATE_FAILED: "ADVSTATE_LIST_CREATE_FAILED",

  ADV_TYPE_UPDATE_REQUEST: "ADV_TYPE_UPDATE_REQUEST",
  ADV_TYPE_UPDATE_SUCCESS: "ADV_TYPE_UPDATE_SUCCESS",
  ADV_TYPE_UPDATE_FAILED: "ADV_TYPE_UPDATE_FAILED",

  ADV_TYPE_DELETE_REQUEST: "ADV_TYPE_DELETE_REQUEST",
  ADV_TYPE_DELETE_SUCCESS: "ADV_TYPE_DELETE_SUCCESS",
  ADV_TYPE_DELETE_FAILED: "ADV_TYPE_DELETE_FAILED",


  // student

  STU_DATA_GET_REQUEST: "STU_DATA_GET_REQUEST",
  STU_DATA_GET_SUCCESS: "STU_DATA_GET_SUCCESS",
  STU_DATA_GET_FAILED: "STU_DATA_GET_FAILED",


  // Get QR LINK
  QR_DATA_GET_REQUEST: "QR_DATA_GET_REQUEST",
  QR_DATA_GET_SUCCESS: "QR_DATA_GET_SUCCESS",
  QR_DATA_GET_FAILED: "QR_DATA_GET_FAILED",

  QR_DATA_UPDATE_REQUEST: "QR_DATA_UPDATE_REQUEST",
  QR_DATA_UPDATE_SUCCESS: "QR_DATA_UPDATE_SUCCESS",
  QR_DATA_UPDATE_FAILED: "QR_DATA_UPDATE_FAILED",

  // Update Watch On Tv
  WATCH_ON_TV_UPDATE_REQUEST: "WATCH_ON_TV_UPDATE_REQUEST",
  WATCH_ON_TV_UPDATE_SUCCESS: "WATCH_ON_TV_UPDATE_SUCCESS",
  WATCH_ON_TV_UPDATE_FAILED: "WATCH_ON_TV_UPDATE_FAILED",

  // SYNC media from tmdb
  SYNC_MEDIA_REQUEST: "SYNC_MEDIA_REQUEST",
  SYNC_MEDIA_SUCCESS: "SYNC_MEDIA_SUCCESS",
  SYNC_MEDIA_FAILED: "SYNC_MEDIA_FAILED",

   // Webstories
   WEBSTORIES_GET_REQUEST: "WEBSTORIES_GET_REQUEST",
   WEBSTORIES_GET_SUCCESS: "WEBSTORIES_GET_SUCCESS",
   WEBSTORIES_GET_FAILED: "WEBSTORIES_GET_FAILED",
 
   WEBSTORIES_CREATE_REQUEST: "WEBSTORIES_CREATE_REQUEST",
   WEBSTORIES_CREATE_SUCCESS: "WEBSTORIES_CREATE_SUCCESS",
   WEBSTORIES_CREATE_FAILED: "WEBSTORIES_CREATE_FAILED",
 
   WEBSTORIES_DELETE_REQUEST: "WEBSTORIES_DELETE_REQUEST",
   WEBSTORIES_DELETE_SUCCESS: "WEBSTORIES_DELETE_SUCCESS",
   WEBSTORIES_DELETE_FAILED: "WEBSTORIES_DELETE_FAILED",
 
   WEBSTORY_DETAIL_GET_REQUEST: "WEBSTORY_DETAIL_GET_REQUEST",
   WEBSTORY_DETAIL_GET_SUCCESS: "WEBSTORY_DETAIL_GET_SUCCESS",
   WEBSTORY_DETAIL_GET_FAILED: "WEBSTORY_DETAIL_GET_FAILED",
 
   WEBSTORY_UPDATE_REQUEST: "WEBSTORY_UPDATE_REQUEST",
   WEBSTORY_UPDATE_SUCCESS: "WEBSTORY_UPDATE_SUCCESS",
   WEBSTORY_UPDATE_FAILED: "WEBSTORY_UPDATE_FAILED",
 
   // Webstory reels
   WEBSTORYREELS_GET_REQUEST: "WEBSTORYREELS_GET_REQUEST",
   WEBSTORYREELS_GET_SUCCESS: "WEBSTORYREELS_GET_SUCCESS",
   WEBSTORYREELS_GET_FAILED: "WEBSTORYREELS_GET_FAILED",
 
   WEBSTORYREELS_CREATE_REQUEST: "WEBSTORYREELS_CREATE_REQUEST",
   WEBSTORYREELS_CREATE_SUCCESS: "WEBSTORYREELS_CREATE_SUCCESS",
   WEBSTORYREELS_CREATE_FAILED: "WEBSTORYREELS_CREATE_FAILED",

   WEBSTORYREELS_UPDATE_REQUEST: "WEBSTORYREELS_UPDATE_REQUEST",
   WEBSTORYREELS_UPDATE_SUCCESS: "WEBSTORYREELS_UPDATE_SUCCESS",
   WEBSTORYREELS_UPDATE_FAILED: "WEBSTORYREELS_UPDATE_FAILED",
 
   WEBSTORYREELS_DELETE_REQUEST: "WEBSTORYREELS_DELETE_REQUEST",
   WEBSTORYREELS_DELETE_SUCCESS: "WEBSTORYREELS_DELETE_SUCCESS",
   WEBSTORYREELS_DELETE_FAILED: "WEBSTORYREELS_DELETE_FAILED",
}
