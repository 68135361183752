import { WEB } from "../constants/constants";


const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
    categoryListResult: {},
    categoriesSearchList: {},
    detailsByCategory: {},
    detailsByCollectionId: {},
    collectionsMedias: {},
    collectionsLinks: {},
    ytdCollectionListResult: {}

};

export const categoriesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.CATEGORY_LIST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_LIST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                categoryListResult: payload,
            };
        case WEB.CATEGORY_LIST_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.CATEGORY_SEARCH_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                categoriesSearchList: payload,
            };
        case WEB.CATEGORY_SEARCH_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.DELETE_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Category deleted',
            };
        case WEB.DELETE_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.CATEGORY_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.POST_ADD_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POST_ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Category updated',
            };
        case WEB.POST_ADD_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Category Updated',
            };
        case WEB.UPDATE_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_DETAILS_BY_CATEGORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_DETAILS_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                detailsByCategory: payload,
            };
        case WEB.GET_DETAILS_BY_CATEGORY_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_COLLECTIONS_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_COLLECTIONS_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                collectionsMedias: payload,
            };
        case WEB.GET_COLLECTIONS_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_COLLECTIONS_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_COLLECTIONS_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Collection Updated',
            };
        case WEB.UPDATE_COLLECTIONS_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.YTD_COLLECTION_LIST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.YTD_COLLECTION_LIST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                ytdCollectionListResult: payload,
            };
        case WEB.YTD_COLLECTION_LIST_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.POST_ADD_YTB_COLLECTION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POST_ADD_YTB_COLLECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Collection updated',
            };
        case WEB.POST_ADD_YTB_COLLECTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_YTB_COLLECTION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_YTB_COLLECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Collection Updated',
            };
        case WEB.UPDATE_YTB_COLLECTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.DELETE_YTB_COLLECTION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_YTB_COLLECTION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Collection deleted',
            };
        case WEB.DELETE_YTB_COLLECTION_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_DETAILS_BY_COLLECTION_ID_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_DETAILS_BY_COLLECTION_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                detailsByCollectionId: payload,
            };
        case WEB.GET_DETAILS_BY_COLLECTION_ID_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_COLLECTIONS_YTB_LINKS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_COLLECTIONS_YTB_LINKS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                collectionsLinks: payload,
            };
        case WEB.GET_COLLECTIONS_YTB_LINKS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_YTB_LINK_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_YTB_LINK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Collection Link Updated',
            };
        case WEB.UPDATE_YTB_LINK_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.DELETE_YTB_LINK_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_YTB_LINK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Collection Link Deleted',
            };
        case WEB.DELETE_YTB_LINK_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        default:
            return state;

    }
}