import CustomPagination from 'components/Pagination/CustomPagination';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Col, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, Table } from 'reactstrap'
import { deleteBlogSubCategoryData } from 'redux/actions/blogs.action';
import { createBlogSubCategoryData } from 'redux/actions/blogs.action';
import { getBlogSubCategoryData } from 'redux/actions/blogs.action';
import { clearMessage } from 'redux/actions/blogs.action';
import { updateBlogSubCategoryData } from 'redux/actions/blogs.action';
import { getBlogCategoryData } from 'redux/actions/blogs.action';

export default function SubCategories() {
    const [categoryName, setCategoryName] = useState('');
    const [inputShow, setInputShow] = useState('');
    const [inputOrderShow, setInputOrderShow] = useState('');
    const [inputText, setInputText] = useState('');
    const [inputNumber, setInputNumber] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [toggleFilter, setToggleFilter] = React.useState(false);
    const [toggleIdFilter, setToggleIdFilter] = React.useState(false);
    const [activeCategory, setActiveCategory] = React.useState('');
    const [activeCategoryName, setActiveCategoryName] = React.useState('');

    const dispatch = useDispatch();

    const categoryList = useSelector((state) => state.blogs && state.blogs.categoryList ? state.blogs.categoryList : {})

    const subCategoryList = useSelector((state) => state.blogs && state.blogs.subCategoryList ? state.blogs.subCategoryList : {})

    const isLoading = useSelector((state) => state.blogs && state.blogs.isLoading ? state.blogs.isLoading : false)
    const status = useSelector((state) => state.blogs && state.blogs.status ? state.blogs.status : '')
    const errMesg = useSelector((state) => state.blogs && state.blogs.errMesg ? state.blogs.errMesg : '')

    useEffect(() => {
        if (status && status != "") {
            setCategoryName('');
            dispatch(getBlogSubCategoryData(activeCategory));
        }
    }, [errMesg, status])

    useEffect(() => {
        if (!categoryList.data || categoryList.data.length == 0) {
            dispatch(getBlogCategoryData());
        } else {
            if (activeCategory == '') {
                setActiveCategory(categoryList.data && categoryList.data[0] && categoryList.data[0].uuid);
                setActiveCategoryName(categoryList.data && categoryList.data[0] && categoryList.data[0].category);
            }
        }
    }, [categoryList])

    useEffect(() => {
        if (activeCategory && activeCategory != '') {
            dispatch(getBlogSubCategoryData(activeCategory));
        }
    }, [activeCategory])

    const handleCreate = () => {
        if (categoryName && categoryName != '') {
            dispatch(createBlogSubCategoryData({ category: activeCategory, sub_category: categoryName }));
        } else {
            toast.error(("Category name can't be empty"), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDeleteKey = (data) => {
        dispatch(deleteBlogSubCategoryData(data?.uuid));
    }
    const handleUpdate = (val, prevVal, data, name, id) => {
        if (!prevVal || val != prevVal) {
            const body = { ...data, [name]: val }
            dispatch(updateBlogSubCategoryData(id, body));
        }
        setInputShow('');
        setInputText('');
        setInputOrderShow('');
    }

    return (
        <div>
            <Row>
                <Col sm={3} className="">
                    <Dropdown color="black" mt={4} toggle={() => { setToggleFilter(!toggleFilter) }}
                        isOpen={toggleFilter} style={{ width: '100%' }}>
                        <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                            {activeCategoryName}
                        </DropdownToggle>
                        <DropdownMenu>
                            {categoryList.data && categoryList.data.length > 0 && categoryList.data.map((x, i) => {
                                return <DropdownItem key={i} value={x?.uuid} onClick={(e) => {
                                    setActiveCategory(x?.uuid);
                                    setActiveCategoryName(x?.category);
                                }}>{x?.category}</DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col sm={3} className="">
                    <Input placeholder='Sub-Category Name' onChange={(e) => setCategoryName(e.target.value)} value={categoryName} />
                </Col>
                <Col sm="auto">
                    <Button color="success" onClick={() => handleCreate()}>Create</Button>
                </Col>
                <Col xs={12}>
                    <Table className="align-items-center mt-3 table-flush " >
                        <thead >
                            <tr>
                                <th className='table_heading'>Sr No.</th>
                                <th className='table_heading'>Category</th>
                                <th className='table_heading'>Sub-Category</th>
                                <th className='table_heading'>Order</th>
                                <th className='table_heading'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subCategoryList.data?.length > 0 &&
                                subCategoryList.data.map((x, i) => {
                                    return <tr>
                                        <th scope="row">{(pageLimit * (currentPage - 1)) + (i + 1)}</th>
                                        <td>
                                            <Dropdown size='sm' color="black" mt={4} toggle={() => { setToggleIdFilter(toggleIdFilter == x?.uuid ? '' : x?.uuid) }}
                                                isOpen={toggleIdFilter == x?.uuid} style={{ width: 'auto' }}>
                                                <DropdownToggle caret style={{ width: 'auto', display: 'block' }}>
                                                    {activeCategoryName}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {categoryList.data && categoryList.data.length > 0 && categoryList.data.map((y, i) => {
                                                        return <DropdownItem key={i} value={y?.uuid} onClick={(e) => {
                                                            // setActiveCategory(y?.uuid);
                                                            // setActiveCategoryName(y?.category);
                                                            handleUpdate(y?.uuid, x?.category, x, 'category', x.uuid);
                                                        }}>{y?.category}</DropdownItem>
                                                    })}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </td>
                                        <td>
                                            {inputShow === x.uuid ?
                                                <Input
                                                    style={{ width: 'auto' }}
                                                    size={'sm'}
                                                    autoFocus={true}
                                                    placeholder='Sub-Category Name'
                                                    onChange={(e) => setInputText(e.target.value)} onBlur={(e) => handleUpdate(e.target.value, x?.sub_category, x, 'sub_category', x.uuid)}
                                                    value={inputText} />
                                                :
                                                <span className='pointer' onClick={() => {
                                                    setInputText(x?.sub_category)
                                                    setInputShow(x.uuid);
                                                }}>{x?.sub_category}</span>
                                            }
                                        </td>
                                        <td>
                                            {inputOrderShow === x.uuid ?
                                                <Input
                                                    style={{ width: 'auto' }}
                                                    size={'sm'}
                                                    type='number'
                                                    autoFocus={true}
                                                    placeholder='Order'
                                                    onChange={(e) => setInputNumber(e.target.value)} onBlur={(e) => handleUpdate(e.target.value, parseInt(x?.order), x, 'order', x.uuid)}
                                                    value={inputNumber} />
                                                :
                                                <span className='pointer' onClick={() => {
                                                    setInputNumber(x?.order)
                                                    setInputOrderShow(x.uuid);
                                                }}>{x?.order}</span>
                                            }
                                        </td>

                                        <td style={{ minWidth: '160px' }} className="td-actions text-left">
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    if (window.confirm("Do you really want to delete this sub-category ?")) {
                                                        handleDeleteKey(x);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>

                                    </tr>
                                })
                            }
                            {subCategoryList.data?.length == 0 &&
                                <tr><td colSpan={4} className="text-center">No Data Found</td></tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}
