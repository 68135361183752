import React from 'react'
import Header from "components/Headers/Header.js";
import { Container, Card, Form, FormGroup, Label, CustomInput, Col, Input, Row, Dropdown, DropdownToggle, DropdownMenu, Button, DropdownItem } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getPlanPriceDropDown } from '../../redux/actions/platform.action';
import { bulkCouponUpload, clearMessage } from '../../redux/actions/coupon.action';
import { useSelector, useDispatch } from 'react-redux'
import ReactGA from 'react-ga';
import Loader from '../common/Loader'
import store from 'redux/store';
import axios from 'axios';

export default function BulkUploadCoupon() {
    const [isPlan, setIsPlan] = React.useState(false)
    const [platform, setPlatform] = React.useState('');
    const [date, setDate] = React.useState('');
    const [file, setFile] = React.useState('');
    const [platformName, setPlatformName] = React.useState('');
    const dispatch = useDispatch();


    const platformPlanDropdownList = useSelector((state) => state.platform && state.platform.platformPlanDropdownListResult ? state.platform.platformPlanDropdownListResult : {})

    const status = useSelector((state) => state.coupon && state.coupon.status ? state.coupon.status : '')
    const isLoading = useSelector((state) => state.coupon && state.coupon.isLoading ? state.coupon.isLoading : false)
    const errMesg = useSelector((state) => state.coupon && state.coupon.errMesg ? state.coupon.errMesg : '')

    React.useEffect(() => {
        dispatch(getPlanPriceDropDown(10000));
    }, [])

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }


    const handleUpload = () => {
        var formData = new FormData();
        var blobFile = new Blob([file[0]],{type: 'text/csv'});
        formData.append('coupon_codes', blobFile,file[0].name);
        formData.append('platform_pd', platform);
        formData.append('expiry_date', date);
        eventReporting('Subscription', 'Bulk coupon uploaded  - ' + platform, file[0].name ? file[0].name : '');
        dispatch(bulkCouponUpload(formData));
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            // setTimeout(() => {
            //     window.location.reload();
            // }, 3000);
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    console.log("platformPlanDropdownList", file)

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-4 rounded-lg'>
                    <Row className='main_heading'>
                        <Col>
                            <h3 className='mb-3'>Bulk Upload Coupons</h3>
                        </Col>
                    </Row>
                    <div className='inner_div'>
                        <Col >
                            <Form >
                                <FormGroup>
                                    <Label for="exampleCustomFileBrowser">Coupon File</Label>
                                    <CustomInput type="file" accept='.csv' id="exampleCustomFileBrowser" name="customFile" onChange={(e) => setFile(e.target.files)} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup>
                                    <Label for="exampleDatetime">Expiry Date</Label>
                                    <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" onChange={(e) => setDate(e.target.value)} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Col sm={5} className="p-0">
                                <Label className='mr-3 mb-3 v-middle'>Platform</Label>
                            </Col>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isPlan} toggle={() => setIsPlan(!isPlan)}>
                                    <DropdownToggle className='w-100' caret>
                                        {platformName && platformName != "" ? platformName : 'Select Platform'}
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {platformPlanDropdownList && platformPlanDropdownList.data && platformPlanDropdownList.data.results && platformPlanDropdownList.data.results.length > 0 && platformPlanDropdownList.data.results.map((y, j) => {
                                            return <DropdownItem onClick={() => {
                                                setPlatform(y.uuid);
                                                setPlatformName(`${y.platform_name} - ${y.price} - ${y.currency}`)
                                            }}>
                                                {y.platform_name} - {y.price} - {y.currency}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col className='text-right mt-4' width="7%">
                            <Button color="info" width="10%" onClick={() => handleUpload()} >Upload</Button>
                        </Col>
                    </div>
                </Card>
            </Container>
        </>
    )
}
