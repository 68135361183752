import CustomPagination from 'components/Pagination/CustomPagination';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Nav, NavLink, Row, Spinner, TabContent, Table, NavItem, TabPane, Container, Button, CustomInput, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getUserProfileDetails, getUserFrenziPoints, getBlockedBy, getBlockedTo, getReportedBy, getUserWatchList, getUserWatchListMembers, getUserWatchListMedia } from "../../redux/actions/user.action"
import common from '../../helpers/common';
import Loader from 'views/common/Loader';
import helpers from '../../helpers/common';
import ReactGA from 'react-ga';

export default function UserProfile(props) {
    const [user, setUser] = React.useState({});
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [activeWatchlistId, setActiveWatchlistId] = React.useState('');
    const [isActiveMember, setIsActiveMember] = React.useState(false);
    const [isActiveMedia, setIsActiveMedia] = React.useState(false);

    const [blockedByCurrentPage, setBlockedByCurrentPage] = React.useState(1);
    const [blockedByPageLimit, setBlockedByPageLimit] = React.useState(20);

    const [blockedToCurrentPage, setBlockedToCurrentPage] = React.useState(1);
    const [blockedToPageLimit, setBlockedToPageLimit] = React.useState(20);

    const [reportedByCurrentPage, setReportedByCurrentPage] = React.useState(1);
    const [reportedByPageLimit, setReportedByPageLimit] = React.useState(20);

    const [userWatchlistCurrentPage, setUserWatchlistCurrentPage] = React.useState(1);
    const [userWatchlistPageLimit, setUserWatchlistPageLimit] = React.useState(20);

    const [userWatchlistMembersCurrentPage, setUserWatchlistMembersCurrentPage] = React.useState(1);
    const [userWatchlistMembersPageLimit, setUserWatchlistMembersPageLimit] = React.useState(20);

    const [userWatchlistMediaCurrentPage, setUserWatchlistMediaCurrentPage] = React.useState(1);
    const [userWatchlistMediaPageLimit, setUserWatchlistMediaPageLimit] = React.useState(20);

    const [activeTabPane, setActiveTabPane] = React.useState('points');
    const dispatch = useDispatch();

    const userProfileDetails = useSelector((state) => state.user && state.user.userProfileDetails ? state.user.userProfileDetails : {});
    const frenziPointsDetails = useSelector((state) => state.user && state.user.frenziPointsDetails ? state.user.frenziPointsDetails : {});
    const blockedByDetails = useSelector((state) => state.user && state.user.blockedByDetails ? state.user.blockedByDetails : {});
    const reportedByDetails = useSelector((state) => state.user && state.user.reportedByDetails ? state.user.reportedByDetails : {});
    const userWatchlistData = useSelector((state) => state.user && state.user.userWatchlistData ? state.user.userWatchlistData : {});
    const blockedToDetails = useSelector((state) => state.user && state.user.blockedToDetails ? state.user.blockedToDetails : {});
    const isLoading = useSelector((state) => state.user && state.user.isLoading ? state.user.isLoading : false)

    const userWatchlistMembersData = useSelector((state) => state.user && state.user.userWatchlistMembersData ? state.user.userWatchlistMembersData : {});
    const userWatchlistMediaData = useSelector((state) => state.user && state.user.userWatchlistMediaData ? state.user.userWatchlistMediaData : {});

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname + "?section=userdetails");
    }, [])

    React.useEffect(() => {
        setUser({});
        if (props.id && props.id != "") {
            dispatch(getUserProfileDetails(props.id));
        }
    }, [props.id])

    React.useEffect(() => {
        if (userProfileDetails && userProfileDetails.data && userProfileDetails.data.uuid && props.id == userProfileDetails.data.uuid) {
            setUser(userProfileDetails.data);
        }
    }, [userProfileDetails])

    React.useEffect(() => {
        if (props.id && props.id != "") {
            dispatch(getUserFrenziPoints(props.id, currentPage, pageLimit));
        }
    }, [currentPage, pageLimit])

    React.useEffect(() => {
        if (props.id && props.id != "") {
            dispatch(getBlockedBy(props.id, blockedByCurrentPage, blockedByPageLimit));
        }
    }, [blockedByCurrentPage, blockedByPageLimit])

    React.useEffect(() => {
        if (props.id && props.id != "") {
            dispatch(getBlockedTo(props.id, blockedToCurrentPage, blockedToPageLimit));
        }
    }, [blockedToCurrentPage, blockedToPageLimit])

    React.useEffect(() => {
        if (props.id && props.id != "") {
            dispatch(getReportedBy(props.id, reportedByCurrentPage, reportedByPageLimit));
        }
    }, [reportedByCurrentPage, reportedByPageLimit])

    React.useEffect(() => {
        if (props.id && props.id != "") {
            dispatch(getUserWatchList(props.id, userWatchlistCurrentPage, userWatchlistPageLimit));
        }
    }, [userWatchlistCurrentPage, userWatchlistPageLimit])

    React.useEffect(() => {
        if (activeWatchlistId && activeWatchlistId != "") {
            dispatch(getUserWatchListMembers(activeWatchlistId, userWatchlistMembersCurrentPage, userWatchlistMembersPageLimit));
        }
    }, [userWatchlistMembersCurrentPage, userWatchlistMembersPageLimit])

    React.useEffect(() => {
        if (activeWatchlistId && activeWatchlistId != "") {
            dispatch(getUserWatchListMedia(activeWatchlistId, userWatchlistMediaCurrentPage, userWatchlistMediaPageLimit));
        }
    }, [userWatchlistMediaCurrentPage, userWatchlistMediaPageLimit])

    const handleMembersView = (id) => {
        setActiveWatchlistId(id);
        setUserWatchlistMembersCurrentPage(1);
        dispatch(getUserWatchListMembers(id, 1, userWatchlistMembersPageLimit));
        setIsActiveMember(true);
    }

    const handleMediaView = (id) => {
        setActiveWatchlistId(id);
        setUserWatchlistMediaCurrentPage(1);
        dispatch(getUserWatchListMedia(id, 1, userWatchlistMediaPageLimit));
        setIsActiveMedia(true);
    }

    return (
        <div className='mt-4'>
            {isLoading && <Loader />}
            {user && user.uuid ?
                <div>
                    <Row className='flex-wrap'>
                        <Col sm={4} className="pb-4">
                            {user.profile_image && user.profile_image != "" ?
                                <img style={{ maxHeight: 70, borderRadius: 3 }} src={user.profile_image} />
                                :
                                <div style={{ maxHeight: 70, width: 70 }} className='default-img'>
                                    <i className='fas fa-image'></i>
                                </div>
                            }
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Name</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.username}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Email</p>
                            <h4 className='p-0 m-0'>{user.email}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Created At</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.created_at ? new Date(user.created_at).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' }) : ''}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Phone No.</p>
                            <h4 className='p-0 m-0'>{user.mobile}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Date Of Birth</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.dob}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Gender</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.gender}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Location Details</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.location_details ? user.location_details : 'N/A'}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Content Type</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.content_type}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Total Groups</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.total_groups}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Total Followers</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.total_followers}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Total Following</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.total_following}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Total Watched</p>
                            <h4 className='text-capitalize p-0 m-0'>{(user.total_watched_tv + user.total_watched_movie)}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Total Watchlist</p>
                            <h4 className='text-capitalize p-0 m-0'>{(user.total_watchlist_movie + user.total_watchlist_tv)}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Languages</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.language_of_content ? user.language_of_content.join(",") : ''}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Subscription</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.total_following}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Frenzi Points</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.frenzi_points}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Last Login</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.last_login ? new Date(user.last_login).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' }) : ''}</h4>
                        </Col>
                        <Col sm={4} className="pb-4">
                            <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Last Activity</p>
                            <h4 className='text-capitalize p-0 m-0'>{user.last_activity_at ? new Date(user.last_activity_at).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' }) : ''}</h4>
                        </Col>
                        {user.genre_preference && user.genre_preference.length > 0 &&
                            <Col sm={12} className="pb-4">
                                <p className='text-capitalize p-0 fs-11 fw-500 m-0'>Genre Preference</p>
                                <Row className='w-100 justify-content-start flex-wrap'>
                                    {user.genre_preference.map((x, i) => {
                                        return <Col xs="auto mt-2 mb-3" key={i}>
                                            <img className='d-block' style={{ width: 50, height: 60 }} src={x.image} alt={x.genre} />
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        }
                    </Row>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "points" ? 'active pointer' : 'pointer'}
                                    onClick={() => setActiveTabPane("points")}
                                >
                                    Points Summary
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "blocked_by" ? 'active pointer' : 'pointer'}
                                    onClick={() => setActiveTabPane("blocked_by")}
                                >
                                    Blocked By
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "blocked_to" ? 'active pointer' : 'pointer'}
                                    onClick={() => setActiveTabPane("blocked_to")}
                                >
                                    Blocked To
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "reported_by" ? 'active pointer' : 'pointer'}
                                    onClick={() => setActiveTabPane("reported_by")}
                                >
                                    Reported By
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "watchlist_created" ? 'active pointer' : 'pointer'}
                                    onClick={() => setActiveTabPane("watchlist_created")}
                                >
                                    WatchList Created
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTabPane}>
                            <TabPane tabId="points">
                                <div>
                                    <Table hover className=''>
                                        <thead>
                                            <tr>
                                                <th className='table_heading border-top-0' width="7%">
                                                    S.No.
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Title
                                                </th>
                                                <th className='table_heading border-top-0 text-center'>
                                                    Poster
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Activity
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Points
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {frenziPointsDetails && frenziPointsDetails.data && frenziPointsDetails.data.results && frenziPointsDetails.data.results.length > 0 ? frenziPointsDetails.data.results.map((x, i) => {
                                                return <tr key={i}>
                                                    <td>
                                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.title ? x.title : ''}
                                                    </td>
                                                    <td className='text-center'>
                                                        {x.poster && x.poster != "" ?
                                                            <img style={{ width: 25, height: 30, borderRadius: 3 }} src={x.poster ? common.getFullUrl(x.poster) : ''} />
                                                            :
                                                            <div style={{ width: 25, height: 30, borderRadius: 3 }} className='default-img d-inline-block'>
                                                                <i className='fas fa-image'></i>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {x.activity ? x.activity : ''}
                                                    </td>
                                                    <td>
                                                        {x.points ? x.points : ''}
                                                    </td>
                                                    <td>
                                                        {x.created_at ? x.created_at : ''}
                                                    </td>
                                                </tr>
                                            })
                                                : !isLoading &&
                                                <tr>
                                                    <td colSpan={6} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {frenziPointsDetails && frenziPointsDetails.data && frenziPointsDetails.data.results && frenziPointsDetails.data.results.length > 0 && frenziPointsDetails.data && frenziPointsDetails.data.pagination &&
                                            <CustomPagination
                                                pagination={frenziPointsDetails.data && frenziPointsDetails.data.pagination && frenziPointsDetails.data.pagination ? frenziPointsDetails.data.pagination : {}}
                                                setCurrentPage={(val) => setCurrentPage(val)}
                                                setPageLimit={(val) => setPageLimit(val)}
                                                defaultPageList={frenziPointsDetails.data && frenziPointsDetails.data.results ? frenziPointsDetails.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="blocked_by">
                                <div>
                                    <Table hover className=''>
                                        <thead>
                                            <tr>
                                                <th className='table_heading border-top-0' width="7%">
                                                    S.No.
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Name
                                                </th>
                                                <th className='table_heading border-top-0 text-center'>
                                                    Profile Image
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Email
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Role
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {blockedByDetails && blockedByDetails.data && blockedByDetails.data.results && blockedByDetails.data.results.length > 0 ? blockedByDetails.data.results.map((x, i) => {
                                                return <tr key={i}>
                                                    <td>
                                                        {(blockedByPageLimit * (blockedByCurrentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.username ? x.username : x.unique_name ? x.unique_name : ''}
                                                    </td>
                                                    <td className='text-center'>
                                                        {x.profile_image && x.profile_image != "" ?
                                                            <img style={{ width: 25, height: 30, borderRadius: 3 }} src={x.profile_image ? common.getFullUrl(x.profile_image) : ''} />
                                                            :
                                                            <div style={{ width: 25, height: 30, borderRadius: 3 }} className='default-img'>
                                                                <i className='fas fa-image'></i>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {x.email ? x.email : ''}
                                                    </td>
                                                    <td>
                                                        {x.profile_role ? x.profile_role : ''}
                                                    </td>
                                                </tr>
                                            })
                                                : !isLoading &&
                                                <tr>
                                                    <td colSpan={5} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {blockedByDetails && blockedByDetails.data && blockedByDetails.data.results && blockedByDetails.data.results.length > 0 && blockedByDetails.data && blockedByDetails.data.pagination &&
                                            <CustomPagination
                                                pagination={blockedByDetails.data && blockedByDetails.data.pagination && blockedByDetails.data.pagination ? blockedByDetails.data.pagination : {}}
                                                setCurrentPage={(val) => setBlockedByCurrentPage(val)}
                                                setPageLimit={(val) => setBlockedByPageLimit(val)}
                                                defaultPageList={blockedByDetails.data && blockedByDetails.data.results ? blockedByDetails.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="blocked_to">
                                <div >
                                    <Table hover className=''>
                                        <thead>
                                            <tr>
                                                <th className='table_heading border-top-0' width="7%">
                                                    S.No.
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Name
                                                </th>
                                                <th className='table_heading border-top-0 text-center'>
                                                    Profile Image
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Email
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Role
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {blockedToDetails && blockedToDetails.data && blockedToDetails.data.results && blockedToDetails.data.results.length > 0 ? blockedToDetails.data.results.map((x, i) => {
                                                return <tr key={i}>
                                                    <td>
                                                        {(blockedToPageLimit * (blockedToCurrentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.username ? x.username : x.unique_name ? x.unique_name : ''}
                                                    </td>
                                                    <td className='text-center'>
                                                        {x.profile_image && x.profile_image != "" ?
                                                            <img style={{ width: 25, height: 30, borderRadius: 3 }} src={x.profile_image ? common.getFullUrl(x.profile_image) : ''} />
                                                            :
                                                            <div style={{ width: 25, height: 30, borderRadius: 3 }} className='default-img'>
                                                                <i className='fas fa-image'></i>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {x.email ? x.email : ''}
                                                    </td>
                                                    <td>
                                                        {x.profile_role ? x.profile_role : ''}
                                                    </td>
                                                </tr>
                                            })
                                                : !isLoading &&
                                                <tr>
                                                    <td colSpan={5} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {blockedToDetails && blockedToDetails.data && blockedToDetails.data.results && blockedToDetails.data.results.length > 0 && blockedToDetails.data && blockedToDetails.data.pagination &&
                                            <CustomPagination
                                                pagination={blockedToDetails.data && blockedToDetails.data.pagination && blockedToDetails.data.pagination ? blockedToDetails.data.pagination : {}}
                                                setCurrentPage={(val) => setBlockedToCurrentPage(val)}
                                                setPageLimit={(val) => setBlockedToPageLimit(val)}
                                                defaultPageList={blockedToDetails.data && blockedToDetails.data.results ? blockedToDetails.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </div >
                            </TabPane>
                            <TabPane tabId="reported_by">
                                <div>
                                    <Table hover className=''>
                                        <thead>
                                            <tr>
                                                <th className='table_heading border-top-0' width="7%">
                                                    S.No.
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Name
                                                </th>
                                                <th className='table_heading border-top-0 text-center'>
                                                    Profile Image
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Email
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Role
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reportedByDetails && reportedByDetails.data && reportedByDetails.data.results && reportedByDetails.data.results.length > 0 ? reportedByDetails.data.results.map((x, i) => {
                                                return <tr key={i}>
                                                    <td>
                                                        {(reportedByPageLimit * (reportedByCurrentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.username ? x.username : x.unique_name ? x.unique_name : ''}
                                                    </td>
                                                    <td className='text-center'>
                                                        {x.profile_image && x.profile_image != "" ?
                                                            <img style={{ width: 25, height: 30, borderRadius: 3 }} src={x.profile_image ? common.getFullUrl(x.profile_image) : ''} />
                                                            :
                                                            <div style={{ width: 25, height: 30, borderRadius: 3 }} className='default-img'>
                                                                <i className='fas fa-image'></i>
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {x.email ? x.email : ''}
                                                    </td>
                                                    <td>
                                                        {x.profile_role ? x.profile_role : ''}
                                                    </td>
                                                </tr>
                                            })
                                                : !isLoading &&
                                                <tr>
                                                    <td colSpan={5} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {reportedByDetails && reportedByDetails.data && reportedByDetails.data.results && reportedByDetails.data.results.length > 0 && reportedByDetails.data && reportedByDetails.data.pagination &&
                                            <CustomPagination
                                                pagination={reportedByDetails.data && reportedByDetails.data.pagination && reportedByDetails.data.pagination ? reportedByDetails.data.pagination : {}}
                                                setCurrentPage={(val) => setReportedByCurrentPage(val)}
                                                setPageLimit={(val) => setReportedByPageLimit(val)}
                                                defaultPageList={reportedByDetails.data && reportedByDetails.data.results ? reportedByDetails.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="watchlist_created">
                                <div >
                                    <Table hover className=''>
                                        <thead>
                                            <tr>
                                                <th className='table_heading border-top-0' width="7%">
                                                    S.No.
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Watchlist Name
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Created At
                                                </th>
                                                <th className='table_heading border-top-0 text-center'>
                                                    Is Public
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Members
                                                </th>
                                                <th className='table_heading border-top-0'>
                                                    Media
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userWatchlistData && userWatchlistData.data && userWatchlistData.data.results && userWatchlistData.data.results.length > 0 ? userWatchlistData.data.results.map((x, i) => {
                                                return <tr key={i}>
                                                    <td>
                                                        {(userWatchlistPageLimit * (userWatchlistCurrentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.category_name ? x.category_name : ''}
                                                    </td>
                                                    <td>
                                                        {x.created_at ? new Date(x.created_at).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' }) : ''}
                                                    </td>
                                                    <td className='text-center' >
                                                        <CustomInput
                                                            type="switch"
                                                            id="exampleCustomSwitch"
                                                            name="customSwitch"
                                                            checked={x.is_public}
                                                            disabled={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button size='sm' onClick={() => handleMembersView(x.uuid)} color='success'>View</Button>
                                                    </td>
                                                    <td>
                                                        <Button size='sm' onClick={() => handleMediaView(x.uuid)} color='primary'>View</Button>
                                                    </td>
                                                </tr>
                                            })
                                                : !isLoading &&
                                                <tr>
                                                    <td colSpan={5} className="text-center">No Data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {userWatchlistData && userWatchlistData.data && userWatchlistData.data.results && userWatchlistData.data.results.length > 0 && userWatchlistData.data && userWatchlistData.data.pagination &&
                                            <CustomPagination
                                                pagination={userWatchlistData.data && userWatchlistData.data.pagination && userWatchlistData.data.pagination ? userWatchlistData.data.pagination : {}}
                                                setCurrentPage={(val) => setUserWatchlistCurrentPage(val)}
                                                setPageLimit={(val) => setUserWatchlistPageLimit(val)}
                                                defaultPageList={userWatchlistData.data && userWatchlistData.data.results ? userWatchlistData.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </div >
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                :
                <div>
                    <Spinner />
                </div>
            }
            <Modal
                isOpen={isActiveMember}
                toggle={() => setIsActiveMember(!isActiveMember)}
                size={'lg'}
            >
                <ModalHeader toggle={() => setIsActiveMember(!isActiveMember)}>
                    Members
                </ModalHeader>
                <ModalBody className='pt-0'>
                    <div >
                        <Table hover className=''>
                            <thead>
                                <tr>
                                    <th className='table_heading border-top-0' width="7%">
                                        S.No.
                                    </th>
                                    <th className='table_heading border-top-0'>
                                        Name
                                    </th>
                                    <th className='table_heading border-top-0 text-center'>
                                        Profile
                                    </th>
                                    <th className='table_heading border-top-0'>
                                        Unique Name
                                    </th>
                                    <th className='table_heading border-top-0'>
                                        Role
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userWatchlistMembersData && userWatchlistMembersData.data && userWatchlistMembersData.data.results && userWatchlistMembersData.data.results.length > 0 ? userWatchlistMembersData.data.results.map((x, i) => {
                                    return <tr key={i}>
                                        <td>
                                            {(userWatchlistMembersPageLimit * (userWatchlistMembersCurrentPage - 1)) + (i + 1)}
                                        </td>
                                        <td>
                                            {x.username ? x.username : ''}
                                        </td>
                                        <td className='text-center' >

                                            {x.profile_image && x.profile_image != "" ?
                                                <img style={{ width: 70, borderRadius: 7 }} src={x.profile_image ? x.profile_image : ''} />
                                                :
                                                <div style={{ width: 70 }} className='default-img'>
                                                    <i className='fas fa-image'></i>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            {x.unique_name ? x.unique_name : ''}
                                        </td>
                                        <td>
                                            {x.role ? x.role : ''}
                                        </td>
                                    </tr>
                                })
                                    : !isLoading &&
                                    <tr>
                                        <td colSpan={5} className="text-center">No Data</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        <div className='text-right mt-2'>
                            {userWatchlistMembersData && userWatchlistMembersData.data && userWatchlistMembersData.data.results && userWatchlistMembersData.data.results.length > 0 && userWatchlistMembersData.data && userWatchlistMembersData.data.pagination && (userWatchlistMembersData.data.pagination.is_next_page || userWatchlistMembersData.data.pagination.is_previous_page) &&
                                <CustomPagination
                                    pagination={userWatchlistMembersData.data && userWatchlistMembersData.data.pagination && userWatchlistMembersData.data.pagination ? userWatchlistMembersData.data.pagination : {}}
                                    setCurrentPage={(val) => setUserWatchlistMembersCurrentPage(val)}
                                    setPageLimit={(val) => setUserWatchlistMembersPageLimit(val)}
                                    defaultPageList={userWatchlistMembersData.data && userWatchlistMembersData.data.results ? userWatchlistMembersData.data.results.length : 20}
                                />
                            }
                        </div>
                    </div >
                </ModalBody>
            </Modal>

            <Modal
                isOpen={isActiveMedia}
                toggle={() => setIsActiveMedia(!isActiveMedia)}
                size={'lg'}
            >
                <ModalHeader toggle={() => setIsActiveMedia(!isActiveMedia)}>
                    Medias
                </ModalHeader>
                <ModalBody className='pt-0'>
                    <div >
                        <Table hover className=''>
                            <thead>
                                <tr>
                                    <th className='table_heading border-top-0' width="7%">
                                        S.No.
                                    </th>
                                    <th className='table_heading border-top-0'>
                                        Title
                                    </th>
                                    <th className='table_heading border-top-0 text-center'>
                                        Poster
                                    </th>
                                    <th className='table_heading border-top-0'>
                                        Release Year
                                    </th>
                                    <th className='table_heading border-top-0'>
                                        Type
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userWatchlistMediaData && userWatchlistMediaData.data && userWatchlistMediaData.data.results && userWatchlistMediaData.data.results.length > 0 ? userWatchlistMediaData.data.results.map((x, i) => {
                                    return <tr key={i}>
                                        <td>
                                            {(userWatchlistMediaPageLimit * (userWatchlistMediaCurrentPage - 1)) + (i + 1)}
                                        </td>
                                        <td>
                                            {x.title ? x.title : ''}
                                        </td>
                                        <td className='text-center' >
                                            <img style={{ width: 70, borderRadius: 7 }} src={x.poster ? helpers.getFullUrl(x.poster) : ''} />
                                        </td>
                                        <td>
                                            {x.release_year ? x.release_year : ''}
                                        </td>
                                        <td>
                                            {x.media_type ? x.media_type : ''}
                                        </td>
                                    </tr>
                                })
                                    : !isLoading &&
                                    <tr>
                                        <td colSpan={5} className="text-center">No Data</td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        <div className='text-right mt-2'>
                            {userWatchlistMediaData && userWatchlistMediaData.data && userWatchlistMediaData.data.results && userWatchlistMediaData.data.results.length > 0 && userWatchlistMediaData.data && userWatchlistMediaData.data.pagination && (userWatchlistMediaData.data.pagination.is_next_page || userWatchlistMediaData.data.pagination.is_previous_page) &&
                                <CustomPagination
                                    pagination={userWatchlistMediaData.data && userWatchlistMediaData.data.pagination && userWatchlistMediaData.data.pagination ? userWatchlistMediaData.data.pagination : {}}
                                    setCurrentPage={(val) => setUserWatchlistMediaCurrentPage(val)}
                                    setPageLimit={(val) => setUserWatchlistMediaPageLimit(val)}
                                    defaultPageList={userWatchlistMediaData.data && userWatchlistMediaData.data.results ? userWatchlistMediaData.data.results.length : 20}
                                />
                            }
                        </div>
                    </div >
                </ModalBody>
            </Modal>
        </div>
    )
}
