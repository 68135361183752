import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
    categoryList: {},
    subCategoryList: {},
    blogList: {},
    blogDetails: {},
    blogLangList: {},
    webstoriesList: {},
    createdWebstory: {},
    webstoryReelsList: {}
};


export const blogsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.BLOGS_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOGS_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.CATEGORY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                categoryList: payload,
            };
        case WEB.CATEGORY_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };

        case WEB.CATEGORY_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Category Updated Successfully',
            };
        case WEB.CATEGORY_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.CATEGORY_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Category Deleted Successfully',
            };
        case WEB.CATEGORY_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.CATEGORY_CREATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CATEGORY_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Category Created Successfully',
            };
        case WEB.CATEGORY_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.SUBCATEGORY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SUBCATEGORY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                subCategoryList: payload,
            };
        case WEB.SUBCATEGORY_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.SUBCATEGORY_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SUBCATEGORY_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Sub-Category Updated Successfully',
            };
        case WEB.SUBCATEGORY_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.SUBCATEGORY_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SUBCATEGORY_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Sub-Category Deleted Successfully',
            };
        case WEB.SUBCATEGORY_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.SUBCATEGORY_CREATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SUBCATEGORY_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Sub-Category Created Successfully',
            };
        case WEB.SUBCATEGORY_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.BLOG_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOG_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                blogList: payload,
            };
        case WEB.BLOG_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.BLOG_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOG_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data?.hideMesg ? '' : 'Blog Updated Successfully',
            };
        case WEB.BLOG_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.BLOG_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOG_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Blog Deleted Successfully',
            };
        case WEB.BLOG_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.BLOG_CREATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOG_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Blog Created Successfully',
            };
        case WEB.BLOG_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.BLOG_DETAIL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOG_DETAIL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                blogDetails: payload,
            };
        case WEB.BLOG_DETAIL_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.BLOG_LANG_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOG_LANG_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                blogLangList: payload,
            };
        case WEB.BLOG_LANG_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        // web story

        case WEB.WEBSTORIES_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORIES_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                webstoriesList: payload,
            };
        case WEB.WEBSTORIES_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.WEBSTORIES_CREATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORIES_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message
                    ? payload.message
                    : "Webstory Created Successfully",
                createdWebstory: payload?.data,
            };
        case WEB.WEBSTORIES_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.WEBSTORIES_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORIES_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message
                    ? payload.message
                    : "Webstory Deleted Successfully",
            };
        case WEB.WEBSTORIES_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.WEBSTORY_DETAIL_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORY_DETAIL_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                webstoryDetails: payload,
            };
        case WEB.WEBSTORY_DETAIL_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.WEBSTORY_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORY_UPDATE_SUCCESS:
            if (!payload.showMesg) {
                return {
                    ...state,
                    isLoading: false,
                    isAuthenticated: true,
                    errMesg: "",
                    status: ""
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    isAuthenticated: true,
                    errMesg: "",
                    status: payload.message
                        ? payload.message
                        : "Webstory Updated Successfully",
                };
            }
        case WEB.WEBSTORY_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.WEBSTORYREELS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORYREELS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                webstoryReelsList: payload,
            };
        case WEB.WEBSTORYREELS_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.WEBSTORYREELS_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORYREELS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                // status: payload.message
                // ? payload.message
                // : "Webstory reel Updated Successfully",
            };
        case WEB.WEBSTORYREELS_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };


        case WEB.WEBSTORYREELS_CREATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORYREELS_CREATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message
                    ? payload.message
                    : "Webstory reel Created Successfully",
            };
        case WEB.WEBSTORYREELS_CREATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.WEBSTORYREELS_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WEBSTORYREELS_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message
                    ? payload.message
                    : "Webstory reel Deleted Successfully",
            };
        case WEB.WEBSTORYREELS_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.BLOGS_CLEAR_STORY_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BLOGS_CLEAR_STORY_DATA_SUCCESS:
            return {
                ...state,
                createdWebstory: {},
            };
        default:
            return state;
    }
}