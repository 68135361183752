import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    CustomInput,
    Input,
    Label,
    Row,
} from "reactstrap";
import { clearCreatedStoryData } from "redux/actions/blogs.action";
import { createWebstoryReelsData } from "redux/actions/blogs.action";
import { createWebstoriesData } from "redux/actions/blogs.action";
import AWS from 'aws-sdk'


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

export default function CreateWebStories(props) {
    const [webstoriesFormData, setwebstoriesFormData] = useState({});
    const [reelFormData, setReelFormData] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [isBannerImage, setIsBannerImage] = React.useState(false);

    const dispatch = useDispatch();
    const createdWebstory = useSelector((state) => {
        return state.blogs && state.blogs.createdWebstory
            ? state.blogs.createdWebstory
            : {};
    });

    const handleWebstoryFormChange = (val, name) => {
        setwebstoriesFormData({ ...webstoriesFormData, [name]: val });
    };
    const handleReelFormChange = (val, name, i) => {
        const tempData = reelFormData;
        tempData[i][name] = val;
        setReelFormData([...tempData]);
    };

    const saveReels = () => {
        let isFormValid = true;
        reelFormData.forEach((x, i) => {
            if (
                !x.title ||
                !x.s3_image_url
            ) {
                isFormValid = false;
            }
        });
        if (!isFormValid) {
            alert("Title and image are required");
        } else {
            reelFormData.forEach((x, i) => {
                dispatch(
                    createWebstoryReelsData({
                        title: x?.title,
                        description: x?.description || null,
                        s3_s3_image_url: x?.s3_image_url,
                        redirection_url: x?.redirection_url || null,
                        web_story: createdWebstory.uuid,
                    })
                );
            });
            setDisabled(false);
            setwebstoriesFormData({ title: '', description: '' });
            setReelFormData([{
                title: "",
                description: "",
                redirection_url: "",
                s3_s3_image_url: "",
            }]);
            props?.handleDone();
        }
    };

    const handleReset = () => {
        setDisabled(false);
        setwebstoriesFormData({ title: '', description: '' });
        setReelFormData([{
            title: "",
            description: "",
            redirection_url: "",
            s3_s3_image_url: "",
        }]);
        dispatch(clearCreatedStoryData());
    }

    const handleCreate = () => {
        if (webstoriesFormData?.title) {
            const body = {
                title: webstoriesFormData?.title,
                description: webstoriesFormData?.description || null,
            };
            dispatch(createWebstoriesData(body));
            setReelFormData([{
                title: "",
                description: "",
                redirection_url: "",
                s3_s3_image_url: "",
            }]);
            setDisabled(true);
        } else {
            alert("Title can't be empty");
        }
    };

    const deleteReelRow = (index) => {
        const tempData = reelFormData;
        tempData.splice(index, 1);
        setReelFormData([...tempData]);
    };

    const addReel = () => {
        const tempData = reelFormData;
        tempData.push({
            title: "",
            description: "",
            redirection_url: "",
            s3_image_url: "",
        });
        setReelFormData([...tempData]);
    };


    const handleUploadBanner = (event, i) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }

        let s3_path = `Stories/banner-`

        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = s3_path + timeStamp + "-" + randomNo + "-" + file.name;

        const s3 = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (data && data.Location) {
                handleReelFormChange(data.Location, 's3_image_url', i);
            }
            if (err) {
                return err;
            }
        });
    }

    return (
        <>
            <div>
                <Row style={{ justifyContent: 'space-between' }} className="m-0">
                    <h2>Create Webstory</h2>
                    <Button onClick={() => handleReset()} color="danger" size="sm" >Reset</Button>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Label size="sm" className="m-0">
                            Title
                        </Label>
                        <Input
                            id="title"
                            size={"sm"}
                            placeholder="Title"
                            onChange={(e) =>
                                handleWebstoryFormChange(e.target.value, "title")
                            }
                            disabled={disabled}
                            value={webstoriesFormData.title}
                        />
                    </Col>
                    <Col xs={6}>
                        <Label size="sm" className="m-0">
                            Description
                        </Label>
                        <Input
                            id="description"
                            size={"sm"}
                            placeholder="Description"
                            onChange={(e) =>
                                handleWebstoryFormChange(e.target.value, "description")
                            }
                            disabled={disabled}
                            value={webstoriesFormData.description}
                        />
                    </Col>
                    <Col xs={12} className="mt-3">
                        <Row className="justify-content-center m-0">
                            <Button onClick={() => handleCreate()} color="success">
                                Create Webstory
                            </Button>
                        </Row>
                    </Col>
                </Row>
                {Object.keys(createdWebstory).length > 0 && (
                    <>
                        <hr />
                        <h3>Add Reels</h3>
                        {reelFormData &&
                            reelFormData.length > 0 &&
                            reelFormData.map((x, i) => {
                                return (
                                    <div
                                        style={{
                                            border: "1px solid grey",
                                            padding: "10px",
                                            marginTop: "10px",
                                            position: "relative",
                                        }}
                                        key={i}
                                    >
                                        {i > 0 && (
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    deleteReelRow(i);
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    right: "3px",
                                                    top: 0,
                                                    zIndex: 100,
                                                }}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        )}
                                        <Row>
                                            <Col xs={6}>
                                                <Label size="sm" className="m-0">
                                                    Reel Title
                                                </Label>
                                                <Input
                                                    id="title"
                                                    size={"sm"}
                                                    placeholder="Reel Title"
                                                    onChange={(e) =>
                                                        handleReelFormChange(
                                                            e.target.value,
                                                            "title",
                                                            i
                                                        )
                                                    }
                                                    value={x?.title || ''}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Label size="sm" className="m-0">
                                                    Reel Description
                                                </Label>
                                                <Input
                                                    id="description"
                                                    size={"sm"}
                                                    placeholder="Reel Description"
                                                    onChange={(e) =>
                                                        handleReelFormChange(
                                                            e.target.value,
                                                            "description",
                                                            i
                                                        )
                                                    }
                                                    value={x?.description || ''}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Label size="sm" className="m-0">
                                                    Redirect URL
                                                </Label>
                                                <Input
                                                    id="redirection_url"
                                                    size={"sm"}
                                                    placeholder="Redirect URL"
                                                    onChange={(e) =>
                                                        handleReelFormChange(
                                                            e.target.value,
                                                            "redirection_url",
                                                            i
                                                        )
                                                    }
                                                    value={x.redirection_url || ''}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Label size='sm' className='m-0' >
                                                    <Row style={{ alignItems: 'center' }} className='m-0'>
                                                        <span>Banner {isBannerImage ? 'Image' : 'Url'}</span>
                                                        <CustomInput
                                                            type="switch"
                                                            id={`exadmpleCustomFeaturedSwitchCreate`}
                                                            name="customSswitch"
                                                            className='ml-3'
                                                            checked={isBannerImage}
                                                            onChange={(e) => setIsBannerImage(e.target.checked)}
                                                        />
                                                    </Row>
                                                </Label>
                                                {isBannerImage ?
                                                    <Row className='m-0' style={{ alignItems: 'center' }}>
                                                        <img style={{ width: 200, height: 100, marginRight: 20 }} src={x.s3_image_url} alt="" />
                                                        <Input
                                                            style={{ width: 'auto' }}
                                                            id="title"
                                                            size={'sm'}
                                                            type='file'
                                                            placeholder='Title'
                                                            onChange={(e) => handleUploadBanner(e, i)}
                                                        />
                                                    </Row>
                                                    :
                                                    <Row className='m-0' style={{ alignItems: 'center' }}>
                                                        <Input
                                                            id="imgurl"
                                                            size={'sm'}
                                                            placeholder='banner url'
                                                            onChange={(e) => handleReelFormChange(e.target.value, 's3_image_url', i)}
                                                            value={x.s3_image_url}
                                                        />
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        <Row>
                            <Col xs={12} className="mt-3">
                                <Row className="justify-content-end m-0">
                                    <Button onClick={() => addReel()} color="primary">
                                        Add reel
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="mt-3">
                                <Row className="justify-content-center m-0">
                                    <Button onClick={() => saveReels()} color="success">
                                        Create Reels
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </>
    );
}