import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    pollsListResult: {},
    quizListResult: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
};

export const pollsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.POLLS_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POLLS_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.GET_POLLS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_POLLS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                pollsListResult: payload,
            };
        case WEB.GET_POLLS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.CREATE_POLL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CREATE_POLL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Poll created',
                message: payload.message ? payload.message : 'Poll created'
            };
        case WEB.CREATE_POLL_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.DELETE_POLL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_POLL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Poll Deleted',
                message: payload.message ? payload.message : 'Poll Deleted'
            };
        case WEB.DELETE_POLL_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_POLL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_POLL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Poll Updated',
                message: payload.message ? payload.message : 'Poll Updated'
            };
        case WEB.UPDATE_POLL_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        // quize reducer
        case WEB.GET_QUIZ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_QUIZ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                quizListResult: payload,
            };
        case WEB.GET_QUIZ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.CREATE_QUIZ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CREATE_QUIZ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Quiz created',
                message: payload.message ? payload.message : 'Quiz created'
            };
        case WEB.CREATE_QUIZ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.DELETE_QUIZ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_QUIZ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Quiz Deleted',
                message: payload.message ? payload.message : 'Quiz Deleted'
            };
        case WEB.DELETE_QUIZ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_QUIZ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_QUIZ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Quiz Updated',
                message: payload.message ? payload.message : 'Quiz Updated'
            };
        case WEB.UPDATE_QUIZ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        default:
            return state;

    }
}