import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.POLLS_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.POLLS_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getPollsList = (page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_POLLS_REQUEST });

        axiosInstance
            .get(`native_main/polllist?page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_POLLS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_POLLS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addPoll = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.CREATE_POLL_REQUEST });

        axiosInstance
            .post(`native_main/polllist`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.CREATE_POLL_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status >= 400) {
                    dispatch({
                        type: WEB.CREATE_POLL_FAILED,
                        payload: {
                            error: err.data.message ? err.data.message : 'Server Error !!!',
                        },
                    });
                }
            })
    }

}

export const deletePoll = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_POLL_REQUEST });

        axiosInstance
            .delete(`native_main/polldetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_POLL_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_POLL_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updatePoll = (uuid, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_POLL_REQUEST });

        axiosInstance
            .put(`native_main/polldetail/${uuid}`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_POLL_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status >= 400) {
                    dispatch({
                        type: WEB.UPDATE_POLL_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

// quizes apis

export const getQuizList = (page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_QUIZ_REQUEST });

        axiosInstance
            .get(`native_main/quizlist?page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_QUIZ_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_QUIZ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getQuizListData = (uuid) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_QUIZ_DATA_REQUEST });

        axiosInstance
            .get(`native_main/quizdetail/${uuid}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_QUIZ_DATA_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_QUIZ_DATA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const addQuiz = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.CREATE_QUIZ_REQUEST });

        axiosInstance
            .post(`native_main/quizlist`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.CREATE_QUIZ_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status >= 400) {
                    dispatch({
                        type: WEB.CREATE_QUIZ_FAILED,
                        payload: {
                            error: err.data.message ? err.data.message : 'Server Error !!!',
                        },
                    });
                }
            })
    }

}

export const deleteQuiz = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_QUIZ_REQUEST });

        axiosInstance
            .delete(`native_main/quizdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_QUIZ_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_QUIZ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateQuiz = (uuid, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_QUIZ_REQUEST });

        axiosInstance
            .put(`native_main/quizdetail/${uuid}`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_QUIZ_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status >= 400) {
                    dispatch({
                        type: WEB.UPDATE_QUIZ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}