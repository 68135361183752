import React from 'react'
import Header from "components/Headers/Header.js";
import { Input, DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Container, Card, Col, Button, Row, Table, Modal, ModalBody, ModalHeader } from 'reactstrap';
import CustomPagination from 'components/Pagination/CustomPagination';
import ReactGA from 'react-ga';

const list = [1, 2, 3, 4, 5,];

export default function RateManagment() {
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isCurrency, setIsCurrency] = React.useState(false)

    const handleEdit = (e) => {

    }

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <h3 className='mb-3'>Rate Management</h3>
                        </Col>
                        <Button color="info" onClick={() => setIsAddPlatform(!isAddPlatform)} >Add Rate</Button>
                    </Row>
                    <Table hover className='mt-3'>
                        <thead>
                            <tr>
                                <th className='table_heading' width="7%">
                                    S. No.
                                </th>
                                <th className='table_heading'>
                                    Currency
                                </th>
                                <th className='table_heading'>
                                    Frenzi Points Rate
                                </th>
                                <th className='table_heading'>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((x, i) => {
                                return <tr>
                                    <th scope="row">
                                        {i + 1}
                                    </th>
                                    <td>
                                        INR
                                    </td>
                                    <td>
                                        0.025
                                    </td>
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{ marginRight: 10 }}
                                            onClick={() => handleEdit(i)}
                                        >
                                            <i className="fas fa-pen pt-1"></i>
                                        </Button>

                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fas fa-trash pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    <div className='mt-4'>
                        <CustomPagination total={list.length} result={1} />
                    </div>
                </Card>
            </Container>
            <Modal
                isOpen={isAddPlatform}
                toggle={() => setIsAddPlatform(!isAddPlatform)}
            >
                <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                    <h3>Add Rate</h3>
                </ModalHeader>
                <ModalBody>
                    <Row className='pl-3 mt-3 pr-3 w-100'>
                        <Col sm={5} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Currency</p>
                        </Col>
                        <Col sm={7}>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isCurrency} toggle={() => setIsCurrency(!isCurrency)}>
                                    <DropdownToggle className='w-100' caret>
                                        Choose Currency
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>US Dollar</DropdownItem>
                                        <DropdownItem>Canadain Dollar</DropdownItem>
                                        <DropdownItem>Euro</DropdownItem>
                                        <DropdownItem>INR</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pl-3 mt-3 pr-3 w-100'>
                        <Col sm={5} className="p-0">
                            <p className='mr-3 mb-0 v-middle'>Frenzi Points Rate</p>
                        </Col>
                        <Col sm={7}>
                            <Input
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <div className='text-center mb-3'>
                    <Button
                        color="info"
                        onClick={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        Save
                    </Button>
                </div>
            </Modal>
        </>
    )
}
