import React, { useEffect } from 'react'
import Header from "components/Headers/Header.js";
import { Container, Card, Col, Row, Input, Table } from 'reactstrap';
import { getRequestedReviewList, clearMessage } from "../../redux/actions/review.action"
import { useSelector, useDispatch } from 'react-redux'
import CustomPagination from 'components/Pagination/CustomPagination';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

export default function RequestReview() {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [searchText, setSearchText] = React.useState('');

    const dispatch = useDispatch();

    const requestedReviewList = useSelector((state) => state.userReview && state.userReview.requestedReviewList ? state.userReview.requestedReviewList : {})
    const isLoading = useSelector((state) => state.userReview && state.userReview.isLoading ? state.userReview.isLoading : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    useEffect(() => {
        dispatch(getRequestedReviewList('', currentPage, pageLimit));
    }, [currentPage, pageLimit])

    const handleSearch = () => {
        dispatch(getRequestedReviewList(searchText, 1, pageLimit));
        setCurrentPage(1);
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <div>
                <Container>
                    <Card className='mt-4 p-3 rounded-lg'>
                        <h3 className='mb-3'>Requested Review</h3>
                        <Row className='justify-content-between pl-3 m-0'>
                            <Col sm={4} className="p-0 search-icon-box">
                                <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                                <i className="fas fa-search" onClick={() => handleSearch()} ></i>
                            </Col>
                        </Row>
                        <Container className='table'>
                            <Table className="align-items-center mt-3 table-flush" hover>
                                <thead>
                                    <tr>
                                        <th className='table_heading'>Sr No.</th>
                                        <th className='table_heading'>Media</th>
                                        <th className='table_heading'>TMDB ID</th>
                                        <th className='table_heading'>Requested By</th>
                                        <th className='table_heading'>Username</th>
                                        <th className='table_heading'>Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requestedReviewList && requestedReviewList.data && requestedReviewList.data.results && requestedReviewList.data.results.length > 0 && requestedReviewList.data.results.map((x, i) => {
                                        return <tr key={i}>
                                            <th >
                                                {(pageLimit * (currentPage - 1)) + (i + 1)}
                                            </th>
                                            <td>{x.media__title ? x.media__title : ''}</td>
                                            <td>{x.media__tmdb_id ? x.media__tmdb_id : ''}</td>
                                            <td>{x.user__username ? x.user__username : ''}</td>
                                            <td>{x.user__unique_name ? x.user__unique_name : ''}</td>
                                            <td>{x.created_at ? x.created_at : ''}</td>
                                        </tr>
                                    })}

                                </tbody>
                            </Table>
                            {requestedReviewList.data && requestedReviewList.data.pagination &&
                                <CustomPagination
                                    pagination={requestedReviewList.data && requestedReviewList.data.pagination && requestedReviewList.data.pagination ? requestedReviewList.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={requestedReviewList.data && requestedReviewList.data.results ? requestedReviewList.data.results.length : 20}
                                />
                            }
                        </Container>
                    </Card>
                </Container>
            </div>
        </>
    )
}
