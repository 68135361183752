import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADVSTATE_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.ADVSTATE_CLEAR_MESSAGE_SUCCESS });
        }
    }
}

export const getAdvList = () => {
    return (dispatch) => {
        dispatch({ type: WEB.ADVSTATE_LIST_GET_REQUEST });
        let url = `native_main/advstate`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADVSTATE_LIST_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADVSTATE_LIST_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const addAdvType = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADVSTATE_LIST_CREATE_REQUEST });
        let url = `native_main/advstate`;

        axiosInstance
            .post(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADVSTATE_LIST_CREATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADVSTATE_LIST_CREATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateAdvType = (id,body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADV_TYPE_UPDATE_REQUEST });
        let url = `native_main/advstate/${id}`;

        axiosInstance
            .put(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADV_TYPE_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADV_TYPE_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteAdvType = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADV_TYPE_DELETE_REQUEST });
        let url = `native_main/advstate/${id}`;

        axiosInstance
            .delete(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADV_TYPE_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADV_TYPE_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}