const Timefilter = () => {
    return (
        <>
            <div className="col">
                <ul className="nav nav-pills">
                    <li className="nav-item">
                        <a href="#pablo" className="py-2 px-3 active nav-link">
                            <span className="d-none d-md-block">Today</span>
                            <span className="d-md-none">M</span>
                        </a></li>
                    <li className="nav-item">
                        <a data-toggle="tab" href="#pablo" className="py-2 px-3 nav-link">
                            <span className="d-none d-md-block">Last 7 days</span>
                            <span className="d-md-none">W</span></a>
                    </li>
                    <li className="nav-item">
                        <a data-toggle="tab" href="#pablo" className="py-2 px-3 nav-link">
                            <span className="d-none d-md-block">This month</span>
                            <span className="d-md-none">W</span></a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Timefilter;