import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";


export const getSubscriptionCategoryList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.SUBSCRIPTION_CATEGORY_GET_REQUEST });

        axiosInstance
            .get(`subscription/category?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.SUBSCRIPTION_CATEGORY_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SUBSCRIPTION_CATEGORY_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getSubscriptionRateList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_SUBSCRIPTION_RATE_LIST_REQUEST });

        axiosInstance
            .get(`subscription/ratelist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_SUBSCRIPTION_RATE_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_SUBSCRIPTION_RATE_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addSubscriptionRate = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.POST_ADD_SUBSCRIPTION_RATE_REQUEST });

        axiosInstance
            .post(`subscription/ratelist`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.POST_ADD_SUBSCRIPTION_RATE_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.POST_ADD_SUBSCRIPTION_RATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateSubscriptionRate = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_SUBSCRIPTION_RATE_REQUEST });
        axiosInstance
            .put(`subscription/ratedetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_SUBSCRIPTION_RATE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_SUBSCRIPTION_RATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const deleteSubscriptionRate = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_SUBSCRIPTION_RATE_REQUEST });
        axiosInstance
            .delete(`subscription/ratedetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_SUBSCRIPTION_RATE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_SUBSCRIPTION_RATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.SUBSCRIPTTION_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.SUBSCRIPTTION_CLEAR_MESSAGE_SUCCESS });
        }
    }
}