import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

function WatchListCategory() {
    const { id } = useParams();

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 pb-6 p-3'>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <h3 className='mb-3'>Add Category</h3>
                        </Col>
                        <div>
                            <Link to="WatchList">
                                <Button color="darker" ><i className='fas fa-arrow-left mr-3' />Back</Button>
                            </Link>
                        </div>
                    </Row>
                    <div className='text-right mt-6'>
                        <CustomInput
                            type="switch"
                            id={`exampleCustomSwitch`}
                            name="customSwitch"
                            className=''
                        // onChange={(e) => console.log(e.target.checked) }
                        />
                    </div>
                    <Row className='justify-content-between m-0 mt-3'>
                        <Col sm={4} className="p-0 ">
                            <Input placeholder='Enter Category Name' />
                        </Col>
                        <Col sm={4} className="p-0 text-center">
                            <input type="file" />
                        </Col>
                        <Col sm={4} className="p-0 ">
                            <Input placeholder='Search Movies/Web Series' />
                        </Col>
                    </Row>
                    {id && id != "" &&
                        <h4 className='mt-3'>Selected Movies And Webseries</h4>
                    }
                    <div className='mt-6 text-center'>
                        <Button color="success" >Save</Button>
                    </div>
                </Card>
            </Container>
        </>
    )
}
export default WatchListCategory
