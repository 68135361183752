import React, { useEffect } from 'react'
import Header from "components/Headers/Header.js";
import { Card, Container, Row, Form, FormGroup, Label, Col, Input, Button, Table, Dropdown, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import MediaType from 'components/MediaType/MediaType';
import { useSelector, useDispatch } from 'react-redux'
import { reportedReview, clearMessage, unReportedReview, deleteReview, deleteMultipleReview, reportReviewByClick } from "../../redux/actions/review.action"
import CustomPagination from 'components/Pagination/CustomPagination';
import Loader from '../common/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

const list = [
    {
        name: "Duplicate"
    },
    {
        name: "Irrelevant to content"
    },
    {
        name: "Offensive"
    },
    {
        name: "Spam"
    },
    {
        name: "Opinion, not review"
    }
]

const defaultReason = {
    uuid: '',
    reason: ''
}

export default function ReportedReview() {
    const dispatch = useDispatch()
    const [value, setValue] = React.useState('');
    const [todate, setToDate] = React.useState('');
    const [fromdate, setFromDate] = React.useState('');
    const [section, setSection] = React.useState("user_reported")

    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [viewModal, setViewModal] = React.useState(false);
    const [viewModalCommon, setViewModalCommon] = React.useState(false);
    const [viewText, setViewText] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false)
    const [isReview, setIsReview] = React.useState(false)
    const [reviewType, setReviewType] = React.useState(false)
    const [commentType, setCommentType] = React.useState(null)
    const [selectedReason, setSelectedReason] = React.useState(defaultReason)
    const [allCheckedIds, setAllCheckedIds] = React.useState([]);
    const [allChecked, setAllChecked] = React.useState(false);
    const [selectedReasonCommon, setSelectedReasonCommon] = React.useState('');
    const [searchType, setSearchType] = React.useState('media_only')
    const [openType, setOpenType] = React.useState(false);


    const reportedReviewResult = useSelector((state) => state.userReview && state.userReview.reportedReviewResult ? state.userReview.reportedReviewResult : {})

    const message = useSelector((state) => state.userReview && state.userReview.message ? state.userReview.message : '')
    const isLoading = useSelector((state) => state.userReview && state.userReview.isLoading ? state.userReview.isLoading : false)
    const errMesg = useSelector((state) => state.userReview && state.userReview.errMesg ? state.userReview.errMesg : false)
    const apiStatus = useSelector((state) => state.userReview && state.userReview.status ? state.userReview.status : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (message && message != "") {
            if (apiStatus) {
                toast.success((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getReportedList();
            } else {
                toast.error((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
            getReportedList();
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    useEffect(() => {
        getReportedList();
    }, [section, commentType, currentPage, pageLimit]);

    const getReportedList = () => {
        var date = '';
        if (todate || fromdate) {
            date = todate + "," + fromdate;
        }
        dispatch(reportedReview(section, value, commentType, date, currentPage, pageLimit));
    }

    const handleSearch = () => {
        setCurrentPage(1);
        var date = '';
        if (todate || fromdate) {
            date = todate + "," + fromdate;
        }
        dispatch(reportedReview(section, value, commentType, date, 1, pageLimit, searchType));
    }

    const handleUnreportReview = (id, type) => {
        if (type == "user_reported") {
            const body = {
                "review": id,
            }
            eventReporting('Review', 'Unreport review id  - ', id);
            dispatch(unReportedReview(body));
        } else {
            const body = {
                "review": id,
                "admin_reported": false
            }
            eventReporting('Review', 'Unreport review id  - ', id);
            dispatch(reportReviewByClick(body));
        }
    }
    const handleSingleReviewDelete = (id) => {
        eventReporting('Review', 'Reported Review deleted  - ' + selectedReason.reason, id);
        dispatch(deleteReview(id, selectedReason.reason))
    }

    const getDropDownList = (type) => {
        setReviewType(type);
        dispatch(reportedReview(type));
    }

    const getReviewType = (type) => {
        var reviewType = reportedReviewResult.data && reportedReviewResult.data.reviewType;
        if (reviewType && reviewType.length > 0) {
            var result = reviewType.filter(item => item.comment_type == type);
        }
        return result && result[0] ? result[0].comment_type : 'N/A';
    }


    const handleReason = (id, reason) => {
        setSelectedReason({ uuid: id, reason: reason });
    }

    const handleAllInputCheck = (e) => {
        setAllChecked(e.target.checked);
        var checkBoxArr = document.getElementsByClassName('checkbox-common');
        if (checkBoxArr && checkBoxArr.length > 0) {
            for (var i = 0; i < checkBoxArr.length; i++) {
                checkBoxArr[i].checked = e.target.checked;
            }
        }

        if (e.target.checked) {
            if (checkBoxArr && checkBoxArr.length > 0) {
                for (var i = 0; i < checkBoxArr.length; i++) {
                    var tempArr = allCheckedIds;
                    var id = checkBoxArr[i].getAttribute('data-id');
                    if (e.target.checked && !tempArr.includes(id)) {
                        tempArr.push(id);
                    }
                    setAllCheckedIds([...tempArr]);
                }
            }
        } else {
            setAllCheckedIds([]);
        }
    }

    const handleInputCheck = (e, id) => {
        var tempArr = allCheckedIds;
        if (e.target.checked) {
            tempArr.push(id);
        } else {
            var index = tempArr.indexOf(id);
            if (index => 0) {
                tempArr.splice(index, 1);
            }
        }
        setAllCheckedIds([...tempArr]);
    }

    const handleDelete = () => {
        if (allCheckedIds && allCheckedIds.length > 0) {
            const body = {
                "reviews": allCheckedIds
            }
            if (selectedReasonCommon && selectedReasonCommon != "") {
                eventReporting('Review', 'Multiple User reported Review deleted  - ' + selectedReasonCommon, JSON.stringify(allCheckedIds));
                dispatch(deleteMultipleReview(body, selectedReasonCommon));
                setViewModalCommon(!viewModalCommon);
                setSelectedReasonCommon("");
            } else {
                toast.error("Please select an reason to delete", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Please select atleast one review", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    return (
        <>
            <Header />
            <Container>
                <Card style={{ padding: '20px', marginTop: "15px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className='heading'>
                        <h4>
                            Reported Reviews
                        </h4>
                    </div>
                    <Row className='mt-4 marg-bot-minus'>
                        <Col sm={2}>
                            <Dropdown className='w-100' isOpen={openType} toggle={() => setOpenType(!openType)}>
                                <DropdownToggle className='w-100' caret>
                                    {searchType && searchType == 'user_only' ? 'User Name' : 'Media Title'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setSearchType('user_only')}>User Name</DropdownItem>
                                    <DropdownItem onClick={() => setSearchType('media_only')}>Media Title</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col sm="2" className='pb-3 p-0 m-0'>
                            <Col className="p-0 search-icon-box">
                                <Input onChange={(e) => setValue(e.target.value)} placeholder='Search' onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                                <i className="fas fa-search" onClick={() => handleSearch()}></i>
                            </Col>
                        </Col>
                        <Col sm="3">
                            <Form>
                                <FormGroup>
                                    {/* <Label for="exampleDate">Date</Label> */}
                                    <Input onChange={(e) => setToDate(e.target.date)} type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm="3">
                            <Form>
                                <FormGroup>
                                    {/* <Label for="exampleDate">Date</Label> */}
                                    <Input onChange={(e) => setFromDate(e.target.date)} type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col sm={2} className="success-btn">
                            <Button className='w-100' onClick={(e) => handleSearch()} color="darker" >Search</Button>
                        </Col>
                    </Row>
                    <Row className='mb-3' sm={8} >
                        <Col sm={2}>
                            <Button onClick={() => {
                                setCurrentPage(1);
                                setSection('user_reported');

                            }} style={{ padding: '7px' }} color={section == "user_reported" ? 'success' : 'secondary'}>User Reported</Button>
                        </Col>
                        <Col sm={2} >
                            <Button onClick={() => {
                                setCurrentPage(1);
                                setSection('admin_reported')
                            }} style={{ padding: '7px' }} color={section == "admin_reported" ? 'success' : 'secondary'}>Admin Reported</Button>
                        </Col>
                        <Col sm={5}>
                            <Row className='pl-3 pr-3 w-100' >
                                <Col className="">
                                    <h5 className=' mb-0 v-middle'>Comment Type :</h5>
                                </Col>
                                <Col className='pr-3'>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isReview} toggle={() => setIsReview(!isReview)}>
                                            <DropdownToggle className='w-100' caret>
                                                {commentType ? commentType : 'All'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                <DropdownItem onClick={() => setCommentType(null)}>All</DropdownItem>
                                                <DropdownItem onClick={() => setCommentType('text')}>Text</DropdownItem>
                                                <DropdownItem onClick={() => setCommentType('audio')}>Audio</DropdownItem>
                                                <DropdownItem onClick={() => setCommentType('video')}>Video</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} className="success-btn text-right">
                            <Button
                                disabled={allCheckedIds.length > 0 ? false : true}
                                onClick={() => setViewModalCommon(true)}
                                color="danger">Delete Selected</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Container className='table'>
                            <Table hover className="align-items-center table-flush">
                                <thead>
                                    <tr>
                                        <th width='100px'>
                                            <Row>
                                                <span style={{ marginTop: 5 }}>S.No.</span>
                                                <Form>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                checked={allChecked}
                                                                onChange={(e) => handleAllInputCheck(e)}
                                                                type="checkbox" className='checkbox' />
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Row>
                                        </th>
                                        <th className='table_heading' style={{ minWidth: 180 }}>
                                            Date & Time
                                        </th>
                                        <th className='table_heading'>
                                            Media Title
                                        </th>
                                        <th className='table_heading'>
                                            Username
                                        </th>
                                        <th className='table_heading ellipsis'>
                                            Review
                                        </th>
                                        {/* <th style={{ minWidth: 140 }} className='table_heading'>
                                            Reported By: <span className=''>Name</span><br /><span className=''>Unique Name</span>
                                        </th> */}
                                        {section == "user_reported" ?
                                            <th style={{ minWidth: 140 }} className='table_heading'>
                                                Reported By: <span className=''>Name</span>/<span className=''>Unique Name</span>
                                            </th>
                                            :
                                            null
                                        }
                                        <th className='table_heading text-center' width='220px'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportedReviewResult && reportedReviewResult.data && reportedReviewResult.data.results && reportedReviewResult.data.results.length > 0 ? reportedReviewResult.data.results.map((x, i) => {
                                        return <tr>
                                            <th scope="row">
                                                <Row>
                                                    <span style={{ marginTop: 2, paddingRight: 2 }}>{(pageLimit * (currentPage - 1)) + (i + 1)}. </span>
                                                    <Form>
                                                        <FormGroup check>
                                                            <Label check>
                                                                <Input
                                                                    // checked={allChecked}
                                                                    checked={allCheckedIds.length > 0 && allCheckedIds.indexOf(x.uuid) != -1 ? true : false}
                                                                    data-id={x.uuid}
                                                                    onChange={(e) => handleInputCheck(e, x.uuid)}
                                                                    type="checkbox" className='checkbox checkbox-common' />
                                                            </Label>
                                                        </FormGroup>
                                                    </Form>
                                                </Row>
                                            </th>
                                            <td>
                                                {x.created_at}
                                            </td>
                                            <td>
                                                {x.title}
                                            </td>
                                            <td>
                                                <h5>{x.username}</h5>
                                            </td>
                                            <td className='ellipsis'>
                                                {x.comment_type == 'text' ?
                                                    x.comment.length > 50 ? <span className='pointer ellipsis' onClick={() => {
                                                        setViewModal(true);
                                                        setViewText(x.comment);
                                                    }}>{x.comment.substring(0, 50)} ...</span> : x.comment
                                                    :
                                                    <MediaType type={x.comment_type} src={x.comment_url} />
                                                }
                                            </td>

                                            {section == "user_reported" ?
                                                <td>
                                                    {x.reportedBy__username} <br />
                                                    <h3>{x.reportedBy__unique_name}</h3>
                                                </td>
                                                :
                                                null
                                            }
                                            <td>
                                                <Col className='p-0 m-0'>
                                                    {section == 'user_reported' ?
                                                        <div>
                                                            <Col>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle caret>
                                                                        {selectedReason && selectedReason.reason && x.uuid === selectedReason.uuid && selectedReason.reason != "" ? selectedReason.reason : 'Reason to delete'}
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {list.map((y, j) => {
                                                                            return <DropdownItem onClick={() => handleReason(x.uuid, y.name)}>
                                                                                {y.name}
                                                                                <DropdownItem divider />
                                                                            </DropdownItem>
                                                                        })}
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </Col>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Input
                                                                value={x.report_reason}
                                                                disabled={true}
                                                                style={{ fontSize: 12 }}
                                                                className={'w-100'}
                                                            />
                                                        </div>
                                                    }
                                                </Col>
                                                <div style={{ textAlign: 'center', marginTop: '7px' }}>
                                                    <Button size="sm" color="info"
                                                        onClick={() => handleUnreportReview(x.uuid, section)}
                                                    >Unreport</Button>
                                                    <Button
                                                        disabled={section == 'user_reported' && !(x.uuid === selectedReason.uuid)}
                                                        onClick={() => handleSingleReviewDelete(x.uuid)}
                                                        size="sm" color="danger" >Delete</Button>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                        :
                                        <tr>
                                            <td colSpan={9} className='text-center'>
                                                <h3>
                                                    No Data Available
                                                </h3>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Container>
                    </Row>
                    {reportedReviewResult && reportedReviewResult.data && reportedReviewResult.data.pagination && (reportedReviewResult.data.pagination.is_next_page || reportedReviewResult.data.pagination.is_previous_page) &&
                        <CustomPagination
                            pagination={reportedReviewResult.data && reportedReviewResult.data.pagination && reportedReviewResult.data.pagination ? reportedReviewResult.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={reportedReviewResult.data && reportedReviewResult.data.results ? reportedReviewResult.data.results.length : 20}
                        />
                    }
                    <Col sm={12} className="success-btn text-right">
                        <Button
                            disabled={allCheckedIds.length > 0 ? false : true}
                            onClick={() => setViewModalCommon(true)}
                            color="danger">Delete Selected</Button>
                    </Col>
                </Card>
                <Modal
                    isOpen={viewModalCommon}
                    toggle={() => setViewModalCommon(!viewModalCommon)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModalCommon(!viewModalCommon)}>
                        Select reason to delete
                    </ModalHeader>
                    <ModalBody className='pt-0'>
                        <Row>
                            <Col className='pb-3'>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret>
                                        {selectedReasonCommon && selectedReasonCommon != "" ? selectedReasonCommon : 'Reason to delete'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {list.map((y, j) => {
                                            return <DropdownItem onClick={() => setSelectedReasonCommon(y.name)}>
                                                {y.name}
                                                <DropdownItem divider />
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() => setViewModalCommon(!viewModalCommon)} color='danger'>Cancel</Button>
                            </Col>
                            <Col className='text-right'>
                                <Button onClick={() => handleDelete()} color='success'>Submit</Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={viewModal}
                    toggle={() => setViewModal(!viewModal)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModal(!viewModal)}>
                        View Comment
                    </ModalHeader>
                    <ModalBody className='pt-0'>
                        {viewText}
                    </ModalBody>
                </Modal>
            </Container>

        </>
    )
}
