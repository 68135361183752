import Header from 'components/Headers/Header'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Button, Card, Container, Table } from 'reactstrap'
import ReactGA from 'react-ga';

export default function AdvPlatformUsers() {


    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <>
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-4 pt-3 pb-3'>
                    <Container className='table'>
                        <h3 className='mb-3'>Advertisement Platform Users</h3>
                        <Table hover className="align-items-center table-flush">
                            <thead>
                                <tr>
                                    <th width="9%">
                                        S. No.
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Email
                                    </th>
                                    <th>
                                        Company Name
                                    </th>
                                    <th>
                                        Skype Id
                                    </th>
                                    <th>
                                        Currency
                                    </th>
                                    <th>
                                        Address
                                    </th>
                                    <th>
                                        Gst No.
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                <tr>
                                    <td>1</td>
                                    <td>John</td>
                                    <td>john@test.com</td>
                                    <td>XYZ Pvt. Ltd.</td>
                                    <td>john@live.com</td>
                                    <td>INR</td>
                                    <td>India</td>
                                    <td>9876</td>
                                    <td>Active</td>
                                    <td>
                                        <Button size='sm' className='mb-2' color='success'>
                                            Approve
                                        </Button>
                                        <Button size='sm' color='danger'>
                                            Reject
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                </Card>
            </Container>
        </>
    )
}
