import React, { useEffect } from 'react'
import Header from "components/Headers/Header.js";
import { Container, Card, Row, Input, Col, Table, Button, Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Label } from 'reactstrap';
import CustomPagination from 'components/Pagination/CustomPagination';
import { useSelector, useDispatch } from 'react-redux'
import { reviewApprovalList, approveReview, rejectReview, clearMessage, reportReviewByClick, deleteMultipleReview } from "../../redux/actions/review.action"
import MediaType from 'components/MediaType/MediaType';
import Loader from '../common/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { CSVLink, CSVDownload } from "react-csv";
const list = [1, 2, 3, 4, 5, 6];

const headingArr = ['S.No.', 'UUID', 'Date & Time', 'Username', 'Unique Name', 'Title', 'Type', 'Comment'];

export default function ReviewManagment() {

    const [searchText, setSearchText] = React.useState('');

    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [viewModal, setViewModal] = React.useState(false);
    const [viewText, setViewText] = React.useState('');
    const [commentType, setCommentType] = React.useState(null);
    const [isReview, setIsReview] = React.useState(false);
    const [allCheckedIds, setAllCheckedIds] = React.useState([]);
    const [allChecked, setAllChecked] = React.useState(false);
    const [csvData, setCsvData] = React.useState([headingArr]);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(reviewApprovalList(searchText, commentType, currentPage, pageLimit));
    }, [currentPage, pageLimit, commentType]);

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(reviewApprovalList(searchText, commentType, 1, pageLimit));
    }

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            label: val
        });
    }

    const reviewSearchResult = useSelector((state) => state.userReview && state.userReview.reviewApprovalResult ? state.userReview.reviewApprovalResult : {})

    const message = useSelector((state) => state.userReview && state.userReview.message ? state.userReview.message : '')
    const isLoading = useSelector((state) => state.userReview && state.userReview.isLoading ? state.userReview.isLoading : false)
    const errMesg = useSelector((state) => state.userReview && state.userReview.errMesg ? state.userReview.errMesg : false)
    const apiStatus = useSelector((state) => state.userReview && state.userReview.status ? state.userReview.status : false)


    React.useEffect(() => {
        if (message && message != "") {
            if (apiStatus) {
                toast.success((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
            setTimeout(() => {
                dispatch(reviewApprovalList(searchText, commentType, currentPage, pageLimit));
            }, 1000)

        }
    }, [message])


    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    const handleApproveReview = (id) => {
        const body = {
            "is_approved": true
        }
        eventReporting('Review', 'Approved review  - ', id);
        dispatch(approveReview(id, body));
    }

    const handleReviewDelete = (id) => {
        const body = {
            "is_approved": false
        }
        eventReporting('Review', 'Reject review  - ', id);
        dispatch(rejectReview(id, body));
    }

    const handleReportReview = (id) => {
        var body = {
            "review": id,
            "admin_reported": true,
            "report_reason": ''
        }
        dispatch(reportReviewByClick(body))
    }

    const handleAllInputCheck = (e) => {
        setAllChecked(e.target.checked);
        var checkBoxArr = document.getElementsByClassName('checkbox-common');
        if (checkBoxArr && checkBoxArr.length > 0) {
            for (var i = 0; i < checkBoxArr.length; i++) {
                checkBoxArr[i].checked = e.target.checked;
            }
        }

        if (e.target.checked) {
            if (checkBoxArr && checkBoxArr.length > 0) {
                for (var i = 0; i < checkBoxArr.length; i++) {
                    var tempArr = allCheckedIds;
                    var id = checkBoxArr[i].getAttribute('data-id');
                    if (e.target.checked && !tempArr.includes(id)) {
                        tempArr.push(id);
                    }
                    setAllCheckedIds([...tempArr]);
                }
            }

            setCsvData([]);
            let records = reviewSearchResult && reviewSearchResult.data && reviewSearchResult.data.results.length > 0 ? reviewSearchResult.data.results : []
            var tempArr = [];
            if (records.length > 0) {
                records.map((j, k) => {
                    let csvArr = [
                        (pageLimit * (currentPage - 1)) + (k + 1),
                        j.uuid,
                        j.created_at,
                        j.username,
                        j.unique_name,
                        j.title,
                        j.comment_type,
                        j.comment_type == 'text' ? `${j.comment}` : j.comment_url,
                    ]
                    tempArr.push(csvArr);
                })
                setCsvData([headingArr, ...tempArr]);
            }
        } else {
            setCsvData([headingArr]);
            setAllCheckedIds([]);
        }
    }

    const handleInputCheck = (e, data, rowIndex = 0) => {
        let id = data.uuid;
        var tempArr = allCheckedIds;
        if (e.target.checked) {
            tempArr.push(id);
        } else {
            var index = tempArr.indexOf(id);
            if (index => 0) {
                tempArr.splice(index, 1);
            }
        }
        let csvArr = [
            rowIndex,
            data.uuid,
            data.created_at,
            data.username,
            data.unique_name,
            data.title,
            data.comment_type,
            data.comment_type == 'text' ? `${data.comment}` : data.comment_url,
        ]
        setCsvData([...csvData, csvArr]);
        setAllCheckedIds([...tempArr]);
    }

    const handleDelete = () => {
        if (allCheckedIds && allCheckedIds.length > 0) {
            const body = {
                "reviews": allCheckedIds
            }
            if (window.confirm('Are you sure want to delete all ?')) {
                eventReporting('Review', 'Multiple Review deleted  - ', JSON.stringify(allCheckedIds));
                dispatch(deleteMultipleReview(body));
            }
        } else {
            toast.error("Please select atleast one review", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <h3 className='mb-3'>AI Reported Review</h3>
                    <Row className='pl-3 m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input onChange={(e) => setSearchText(e.target.value)} placeholder='Search' onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <Col sm={2}>
                            <Dropdown className='w-100' isOpen={isReview} toggle={() => setIsReview(!isReview)}>
                                <DropdownToggle className='w-100' caret>
                                    {commentType ? commentType : 'All'}
                                </DropdownToggle>
                                <DropdownMenu
                                >
                                    <DropdownItem onClick={() => setCommentType(null)}>All</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('text')}>Text</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('audio')}>Audio</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('video')}>Video</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col sm={3} className="success-btn">
                            <Button
                                disabled={allCheckedIds.length > 0 ? false : true}
                                onClick={() => handleDelete()}
                                color="danger">Delete Selected</Button>
                        </Col>
                        <CSVLink data={csvData}
                            onClick={() => {
                                if (allCheckedIds.length <= 0) {
                                    toast.error(('No data selected to export'), {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                    return false;
                                } else {
                                    eventReporting('Review', 'Ai reported review data exported to csv  - ' + `Ai-Reported-Review-${pageLimit * (currentPage - 1) + 1} - ${pageLimit * (currentPage - 1) + pageLimit}.csv`, JSON.stringify(allCheckedIds));
                                }
                            }} filename={`Ai-Reported-Review-${pageLimit * (currentPage - 1) + 1} - ${pageLimit * (currentPage - 1) + pageLimit}.csv`} className="btn btn-primary">Export Selected</CSVLink>
                    </Row>
                    <Container className='table'>
                        <Table hover className='mt-3 align-items-center table-flush'>
                            <thead>
                                <tr>
                                    <th className='table_heading' width="7%">
                                        <Row>
                                            <span style={{ marginTop: 5 }}>S.No.</span>
                                            <Form>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input
                                                            checked={allChecked}
                                                            onChange={(e) => handleAllInputCheck(e)}
                                                            type="checkbox" className='checkbox' />
                                                    </Label>
                                                </FormGroup>
                                            </Form>
                                        </Row>
                                    </th>
                                    <th className='table_heading' style={{ minWidth: 140 }}>
                                        Date & Time
                                    </th>
                                    <th className='table_heading' >
                                        UserName
                                    </th>
                                    <th className='table_heading'>
                                        Title
                                    </th>
                                    <th className='table_heading'>
                                        Comment Type
                                    </th>
                                    {/* <th className='table_heading'>
                                        Reported Date
                                    </th>
                                    <th className='table_heading'>
                                        Reported By
                                    </th> */}
                                    <th className='table_heading'>
                                        Comment
                                    </th>
                                    <th className='table_heading'>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {reviewSearchResult && reviewSearchResult.data && reviewSearchResult.data.results && reviewSearchResult.data.results.length > 0 ? reviewSearchResult.data.results.map((x, i) => {
                                    return <tr key={i}>
                                        <th scope="row">
                                            <Row style={{ minWidth: 65 }}>
                                                <span style={{ marginTop: 2 }} className='mr-1'>{(pageLimit * (currentPage - 1)) + (i + 1)}. </span>
                                                <Form>
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                // checked={allChecked}
                                                                checked={allCheckedIds.length > 0 && allCheckedIds.indexOf(x.uuid) != -1 ? true : false}
                                                                data-id={x.uuid}
                                                                onChange={(e) => handleInputCheck(e, x, (pageLimit * (currentPage - 1)) + (i + 1))}
                                                                type="checkbox" className='checkbox checkbox-common' />
                                                        </Label>
                                                    </FormGroup>
                                                </Form>
                                            </Row>
                                        </th>
                                        <td>
                                            <h5>{x.created_at}</h5>
                                        </td>
                                        <td>
                                            <h5>{x.username}</h5>
                                        </td>
                                        <td>
                                            <h5>{x.title}</h5>
                                        </td>
                                        {/* <td>24-05-2022</td>
                                        <td>Arjun</td> */}
                                        <td>{x.comment_type ? x.comment_type : ''}</td>
                                        <td>
                                            {x.comment_type == 'text' ?
                                                x.comment.length > 50 ? <span className='pointer' onClick={() => {
                                                    setViewModal(true);
                                                    setViewText(x.comment);
                                                }}>{x.comment.substring(0, 50)} ...</span> : x.comment
                                                :
                                                <MediaType type={x.comment_type} src={x.comment_url} />
                                            }
                                        </td>
                                        <td>
                                            <Row style={{ minWidth: '200px' }}>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleApproveReview(x.uuid)}
                                                >
                                                    <i className="fas fa fa-check"></i>
                                                </Button>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleReviewDelete(x.uuid)}

                                                >
                                                    <i className="fas fa fa-times"></i>
                                                </Button>
                                                {x.comment_type != 'text' &&
                                                    <MediaType type={'download'} src={x.comment_url} unique={x.unique_name} />
                                                }
                                            </Row>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr>
                                        <td colSpan={9} className='text-center'>
                                            <h3>
                                                No Data Available
                                            </h3>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {/* <CustomPagination total={list.length} result={1} /> */}
                        {reviewSearchResult.data && reviewSearchResult.data.pagination &&
                            <CustomPagination
                                pagination={reviewSearchResult.data && reviewSearchResult.data.pagination && reviewSearchResult.data.pagination ? reviewSearchResult.data.pagination : {}}
                                setCurrentPage={(val) => setCurrentPage(val)}
                                setPageLimit={(val) => setPageLimit(val)}
                                defaultPageList={reviewSearchResult.data && reviewSearchResult.data.results ? reviewSearchResult.data.results.length : 20}
                            />
                        }
                    </Container>
                </Card>
                <Modal
                    isOpen={viewModal}
                    toggle={() => setViewModal(!viewModal)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModal(!viewModal)}>
                        View Comment
                    </ModalHeader>
                    <ModalBody className='pt-0'>
                        {viewText}
                    </ModalBody>
                </Modal>
            </Container>
        </>
    )
}
