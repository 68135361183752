import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    userReviewResult: {},
    reportedReviewResult: {},
    reviewApprovalResult: {},
    addTextReviewResult: {},
    audioVideoReviewResult: {},
    multipleReviewDeleteResult: {},
    frenziOfficialReviewListResult: {},
    getSpecificReviewDetail: {},
    message: '',
    errMesg: "",
    status: "",
    req: {},
    requestedReviewList :{},
};


export const commonReviewReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.REVIEW_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.REVIEW_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.USER_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.USER_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                userReviewResult: payload,
            };
        case WEB.USER_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.REPORTED_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
                reportedReviewResult: {},
            };
        case WEB.REPORTED_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                reportedReviewResult: payload,
            };
        case WEB.REPORTED_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.REPORT_REVIEW_BY_CLICK_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.REPORT_REVIEW_BY_CLICK_SUCCESSS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Review reported',
                message: payload.message ? payload.message : 'Review reported',
            };
        case WEB.REPORT_REVIEW_BY_CLICK_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.DELETE_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data && payload.data.message ? payload.data.message : 'Review deleted'
            };
        case WEB.DELETE_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.REVIEW_APPROVAL_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.REVIEW_APPROVAL_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                reviewApprovalResult: payload,
            };
        case WEB.REVIEW_APPROVAL_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.APPROVE_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.APPROVE_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: 'Review Approved',
            };
        case WEB.APPROVE_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.REJECT_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.REJECT_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: 'Review Rejected',
            };
        case WEB.REJECT_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.ADD_TEXT_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_TEXT_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data && payload.data.message ? payload.data.message : 'Review added'
            };
        case WEB.ADD_TEXT_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_AUDIO_VIDEO_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_AUDIO_VIDEO_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data && payload.data.message ? payload.data.message : 'Review added'
            };
        case WEB.ADD_AUDIO_VIDEO_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.FRENZI_OFFICIAL_REVIEW_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.FRENZI_OFFICIAL_REVIEW_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                frenziOfficialReviewListResult: payload,
            };
        case WEB.FRENZI_OFFICIAL_REVIEW_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.DELETE_MULTIPLE_REVIEWS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_MULTIPLE_REVIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: true,
                message: 'Review Deleted',
            };
        case WEB.DELETE_MULTIPLE_REVIEWS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };

        case WEB.GET_SPECIFIC_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_SPECIFIC_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                getSpecificReviewDetail: payload,
            };
        case WEB.GET_SPECIFIC_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.UPDATE_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data && payload.data.message ? payload.data.message : 'Review updated'
            };
        case WEB.UPDATE_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.POST_UNREPORTED_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POST_UNREPORTED_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data && payload.data.message ? payload.data.message : 'Review UnReported'
            };
        case WEB.POST_UNREPORTED_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.REQUESTED_REVIEW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.REQUESTED_REVIEW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
                requestedReviewList: payload,
            };
        case WEB.REQUESTED_REVIEW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        default:
            return state;

    }
}
