import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AWS from 'aws-sdk'
// import { FullPage } from '@ckeditor/ckeditor5-html-support';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

export default function MyCkEditor(props) {

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        var timeStamp = new Date().getTime();
                        var randomNo = parseInt(Math.random() * 100000);
                        var keyName = props.s3_path + timeStamp + "-" + randomNo + "-" + file.name;

                        const s3 = new AWS.S3({
                            params: { Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME },
                            region: process.env.REACT_APP_AWS_REGION,
                        })
                        const params = {
                            Key: keyName,
                            Body: file,
                        }
                        s3.upload(params, async function (err, data) {
                            if (data && data.Location) {
                                resolve({ default: data.Location });
                            }
                            if (err) {
                                reject(err);
                            }
                        });
                    })
                })
            }
        }
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }

    return (
        <div>
            <CKEditor
                editor={ClassicEditor}
                data={props?.data}
                // config={{ extraPlugins: [uploadPlugin], plugins: [HtmlEmbed], toolbar: ['htmlEmbed'] }}
                config={{
                    extraPlugins: [uploadPlugin],
                    // plugins: [FullPage],
                    // toolbar: {
                    //     items: [
                    //         'heading',
                    //         '|',
                    //         'bold',
                    //         'italic',
                    //         'link',
                    //         'bulletedList',
                    //         'numberedList',
                    //         '|',
                    //         'outdent',
                    //         'indent',
                    //         '|',
                    //         'uploadImage',
                    //         'blockQuote',
                    //         'insertTable',
                    //         'mediaEmbed',
                    //         'undo',
                    //         'redo',
                    //         'htmlEmbed'
                    //     ]
                    // },
                }}
                onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                }}
                onChange={props?.onChange}
                onBlur={props?.onBlur}
                onFocus={props?.onFocus}
            />

            {/* <CKEditor
                initData={props?.data}
            /> */}
        </div>
    )
}
