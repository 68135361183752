import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';


const tempArr = [1, 2, 3, 4, 5, 6];

export default class DynamicFeed extends Component {
    constructor(props) {
        super(props);
        this.inputFileRef = React.createRef(null);
        this.handleToggle = this.handleToggle.bind(this);

        this.state = {
            count: 0,
            isEditOrCreate: false,
            allDropDown: {
                isPostType: false,
                isRedirectType: false,
                isLanguageType: false,
                isSecType: false
            },
        };

    }

    handleToggle(name) {
        let newForm = {
            ...this.state.allDropDown,
            [name]: !this.state.allDropDown[name]
        }
        this.setState({
            allDropDown: newForm
        })
    }


    render() {

        return (
            <>
                <Header />
                <div>
                    {!this.state.isEditOrCreate ?
                        <Container>
                            <div className='media'>
                                <h3>
                                    FEED - List
                                </h3>
                                <Button color="primary" onClick={() => this.setState({ isEditOrCreate: !this.state.isEditOrCreate })}>{this.props.buttonLabel}Create Feed</Button>
                            </div>
                            <div className='media'>
                                <Col sm="12">
                                    <Row className='search_sort'>
                                        <Col sm="1">
                                            <Dropdown color="black" mt={4} isOpen={this.state.allDropDown['isOpenAll']} toggle={() => this.handleToggle('isOpenAll')}>
                                                <DropdownToggle caret>
                                                    ALL
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Movies</DropdownItem>
                                                    <DropdownItem>Shows</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>

                                        <Col sm="5">
                                            <Form>
                                                <FormGroup>
                                                    <Col sm={10} className="p-0 search-icon-box">
                                                        <Input placeholder='Search' />
                                                        <i className="fas fa-search"></i>
                                                    </Col>
                                                </FormGroup>
                                            </Form>
                                        </Col>
                                        <Col sm="6">
                                            <Row className='ml-22'>
                                                <Col>
                                                    <h4 className="mt-2 mr-2 text-right">
                                                        Sort :
                                                    </h4>
                                                </Col>
                                                <Col>
                                                    <Dropdown color="black" mt={4} isOpen={this.state.allDropDown['isOpenSort']} toggle={() => this.handleToggle('isOpenSort')}>
                                                        <DropdownToggle caret>
                                                            Alphabatically(A-Z)
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem>Alpabetically(Z-A)</DropdownItem>
                                                            <DropdownItem>Latest Release</DropdownItem>
                                                            <DropdownItem>Most searched</DropdownItem>
                                                            <DropdownItem>Rating(Highest to lowest)</DropdownItem>
                                                            <DropdownItem>Popularity(High to low)</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                            <Container>
                                <Table className="align-items-center table-flush " >
                                    <thead >
                                        <tr>
                                            <th className='table_heading'>Sr No.</th>
                                            <th className='table_heading'>Title</th>
                                            <th className='table_heading'>Type</th>
                                            <th className='table_heading'>Status</th>
                                            <th className='table_heading'>Information
                                            </th>
                                            <th className='table_heading'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tempArr.map((x, i) => {
                                            return <tr>
                                                <th scope="row">1</th>
                                                <td>Content Title</td>
                                                <td>Movie</td>
                                                <td>Status </td>
                                                <td>7.8</td>
                                                <td className=" td-actions text-left">
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="info"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => this.setState({ isEditOrCreate: !this.state.isEditOrCreate })}
                                                    >
                                                        <i className="fas fa-pen pt-1"></i>
                                                    </Button>

                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                    >
                                                        <i className="fas fa-trash pt-1"></i>
                                                    </Button>
                                                </td>

                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Container>
                        </Container>
                        :
                        <Container>
                            <div className='media'>
                                <h3>
                                    Manage Page Management
                                </h3>

                            </div>
                            <Card className='mt-3 pt-2 mb-3 pb-3' >
                                <Button
                                    className="btn-icon mb-3 ml-2 btn-simple"
                                    color="darker"
                                    size="md"
                                    type="button"
                                    style={{ maxWidth: 100 }}
                                    onClick={() => this.setState({ isEditOrCreate: !this.state.isEditOrCreate })}
                                >
                                    <i className="fas fa-arrow-left" />
                                </Button>
                                <Col style={{ height: '50px' }}>
                                    <Form>
                                        <FormGroup row>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Section Type</h3></Label>
                                            <Col sm={4}>
                                                <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isSecType']} toggle={() => this.handleToggle('isSecType')} >
                                                    <DropdownToggle caret>
                                                        Feed
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>Feed</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>

                                <Row className={'m-0 mt-3 mb-3'} >
                                    <Col sm={3}>
                                        <h3 >
                                            Poster
                                        </h3>
                                    </Col>
                                    <Row >
                                        <Col className='mx-3'>
                                            <input ref={this.inputFileRef} className='d-none' type={'file'} />
                                            <Button
                                                className=" btn-icon btn-simple "
                                                color="darker"
                                                size="sm"
                                                type="button"
                                                onClick={() => this.inputFileRef.current.click()}
                                            >
                                                <i className=" fas fa-upload pt-1"></i>
                                                {/* <CustomInput type="file" id="exampleCustomFileBrowser" name="customFile" label="Yo, pick a file!" /> */}
                                            </Button>
                                        </Col>
                                        <Row style={{ marginLeft: '200%' }}>
                                            <Col>
                                                <h3>Pinned</h3>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomSwitch`}
                                                    name="customSwitch"
                                                // className=''
                                                // label="In-Active"
                                                // onChange={(e) => console.log(e.target.checked) }
                                                />
                                            </Col>
                                        </Row>
                                    </Row>
                                </Row>
                                <Col sm={12}>
                                    <Form>
                                        <FormGroup row className='my-0'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Post Type</h3></Label>
                                            <Col sm={4}>
                                                <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isPostType']} toggle={() => this.handleToggle('isPostType')} >
                                                    <DropdownToggle caret>
                                                        Image
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>Image</DropdownItem>
                                                        <DropdownItem>Video</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Caption</h3></Label>
                                            <Col sm={7}>
                                                <Input type="email" name="email" id="exampleEmail" placeholder="" bsSize="lg" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Description</h3></Label>
                                            <Col sm={7}>
                                                <Input type="textarea" name="text" id="exampleText" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-0 '>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Button Type</h3></Label>
                                            <Col sm={4}>
                                                <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isRedirectType']} toggle={() => this.handleToggle('isRedirectType')} >
                                                    <DropdownToggle caret>

                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>Media</DropdownItem>
                                                        <DropdownItem>New Release</DropdownItem>
                                                        <DropdownItem>Trailers</DropdownItem>
                                                        <DropdownItem>Free</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Button Text</h3></Label>
                                            <Col sm={4}>
                                                <Input type="email" name="email" id="exampleEmail" placeholder="" bsSize="lg" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleCustomSelectDisabled" sm={3} size="lg"><h3>Deep Link</h3></Label>
                                            <Col sm={4}>
                                                <Input type="email" name="email" id="exampleEmail" placeholder="" bsSize="lg" />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Set Prefrence Language</h3></Label>
                                            <Col sm={4}>
                                                <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isLanguageType']} toggle={() => this.handleToggle('isLanguageType')} >
                                                    <DropdownToggle caret>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>All</DropdownItem>
                                                        <DropdownItem>English </DropdownItem>
                                                        <DropdownItem>Hindi</DropdownItem>
                                                        <DropdownItem>Bhojpuri</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                {/* <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Curated</h3></Label>
                                            <Col sm={4} className="curated-form">
                                                <Input type="radio" className='radio-button' />{' Automatically'}
                                                <FormGroup className='mt-2'>
                                                    <Label for="exampleEmail">Deep Link</Label>
                                                    <Input type="email" id="exampleEmail" placeholder="" />
                                                </FormGroup>
                                                <Row className='media-count'>
                                                    <h4>Media Count</h4>
                                                    <input type={'number'} className='input-type' />
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"></Label>
                                            <Col sm={4} className="curated-form">
                                                <Input type="radio" className='radio-button' />{' Manually'}
                                                <FormGroup className='mt-2'>
                                                    <Label for="exampleEmail">20 Media Selected</Label>
                                                    <Input type="email" id="exampleEmail" placeholder="" />
                                                </FormGroup>
                                                <Button size="sm" style={{ backgroundColor: '#b4a7d6', borderWidth: 1 }}>Edit Media</Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col> */}
                                <Row>
                                    <Col sm={3} ></Col>
                                    <Col sm={4} className="success-btn">
                                        <Button color="darker" className='btn-width'>Save</Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Container>
                    }
                </div>
            </>
        )
    }
}

