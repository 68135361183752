import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Row,
  Table,
} from "reactstrap";
import CustomPagination from "components/Pagination/CustomPagination";
import AWS from "aws-sdk";
import { getWebstoriesData } from "redux/actions/blogs.action";
import { deleteWebstoryData } from "redux/actions/blogs.action";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

export const getSelectOptions = (arr) => {
  var temp = [];
  arr.length > 0 &&
    arr.map((x, i) => {
      temp.push({
        label: x.sub_category || x.category || x.label,
        value: x.uuid || x.value,
      });
    });
  return temp;
};

export default function WebStoriesContent(props) {
  const { activeTab } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const dispatch = useDispatch();

  const webstoryList = useSelector((state) => {
    return state.blogs && state.blogs.webstoriesList
      ? state.blogs.webstoriesList
      : {};
  });

  const status = useSelector((state) =>
    state.blogs && state.blogs.status ? state.blogs.status : ""
  );
  const errMesg = useSelector((state) =>
    state.blogs && state.blogs.errMesg ? state.blogs.errMesg : ""
  );

  useEffect(() => {
    if (status && status != "") {
      dispatch(getWebstoriesData(currentPage, pageLimit));
    }
  }, [errMesg, status]);
  useEffect(() => {
    dispatch(getWebstoriesData(currentPage, pageLimit));
  }, [currentPage, pageLimit, activeTab]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pageLimit]);

  const handleDeleteKey = (uuid) => {
    dispatch(deleteWebstoryData(uuid));
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Table size="sm" className="align-items-center mt-3 table-flush ">
            <thead>
              <tr>
                <th className="table_heading">Sr No.</th>
                <th className="table_heading">Title</th>
                <th className="table_heading">Description</th>
                <th className="table_heading">Action</th>
              </tr>
            </thead>
            <tbody>
              {webstoryList.data?.results?.length > 0 &&
                webstoryList.data?.results.map((x, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        {pageLimit * (currentPage - 1) + (i + 1)}
                      </th>
                      <td>{x?.title}</td>
                      <td>{x?.description}</td>

                      <td
                        style={{ minWidth: "160px" }}
                        className="td-actions text-left"
                      >
                        <Row>
                          <Button
                            className=" btn-icon btn-simple"
                            color="success"
                            size="sm"
                            type="button"
                            onClick={() => props.handleEdit(x.uuid)}
                          >
                            <i className="fas fa-pen pt-1"></i>
                          </Button>
                          <Button
                            className=" btn-icon btn-simple"
                            color="danger"
                            size="sm"
                            type="button"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Do you really want to delete this webstory ?"
                                )
                              ) {
                                handleDeleteKey(x?.uuid);
                              }
                            }}
                          >
                            <i className="fas fa-trash pt-1"></i>
                          </Button>
                        </Row>
                      </td>
                    </tr>
                  );
                })}
              {webstoryList.data?.results?.length == 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pagination={
              webstoryList.data &&
              webstoryList.data.pagination &&
              webstoryList.data.pagination
                ? webstoryList.data.pagination
                : {}
            }
            setCurrentPage={(val) => setCurrentPage(val)}
            setPageLimit={(val) => setPageLimit(val)}
            defaultPageList={
              webstoryList.data && webstoryList.data.results
                ? webstoryList.data.results.length
                : 10
            }
          />
        </Col>
      </Row>
    </div>
  );
}