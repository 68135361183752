import Header from 'components/Headers/Header';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Card, Col, Container, CustomInput, Row, Table } from 'reactstrap';
import { clearMessage, newBulkOttDeeplinkUpload } from "../../redux/actions/media.action"
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import BulkCsvFileSample from '../../assets/bulk_upload_template_cx.csv';

export default function NewBulkDeepLinkUpload() {
    const [csvFile, setCsvFile] = React.useState("");

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.media && state.media.isLoading ? state.media.isLoading : {})
    const errMesg = useSelector((state) => state.media && state.media.errMesg ? state.media.errMesg : "")
    const status = useSelector((state) => state.media && state.media.status ? state.media.status : "")
    const uploadResponse = useSelector((state) => state.media && state.media.uploadResponse ? state.media.uploadResponse : {})

    console.log("uploadResponse", uploadResponse)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status]);

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg]);


    const handleFileUpload = () => {
        let formData = new FormData();
        formData.append('file', csvFile);
        ReactGA.event({
            category: 'Media',
            action: 'New Bulk ott deeplink uploaded',
            value: csvFile.name ? csvFile.name : '',
            label: csvFile.name ? csvFile.name : ''
        });
        dispatch(newBulkOttDeeplinkUpload(formData));
    }

    const downloadFormat = () => {
        const a = document.createElement('a');
        a.href = BulkCsvFileSample;
        a.download = 'bulk_upload_template_cx.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }


    return (
        <>
            <Header />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>
                <Card className='mt-4 p-3 pb-4'>
                    <h3 className='mb-3'>New Bulk Deeplink Upload</h3>
                    <div>
                        <p>Choose CSV Deeplinks File</p>
                        <Row>
                            <Col xs={4}>
                                <CustomInput
                                    type='file'
                                    accept=".csv"
                                    onChange={(e) => setCsvFile(e.target.files[0])}
                                    invalid={csvFile && csvFile != "" ? false : true}
                                    valid={csvFile && csvFile != "" ? true : false}
                                />
                            </Col>
                            <Col xs={4}>
                                <Button onClick={() => handleFileUpload()} disabled={csvFile && csvFile != "" ? false : true} color="info" >Upload</Button>
                            </Col>
                            <Col xs={4}>
                                <Button onClick={() => downloadFormat()} color="warning" >Download Sample File</Button>
                            </Col>
                        </Row>
                        <Table hover className='mt-5'>
                            <thead>
                                <tr>
                                    <th className='table_heading' width="7%">
                                        S.No.
                                    </th>
                                    <th className='table_heading'>
                                        Title
                                    </th>
                                    <th className='table_heading'>
                                        UUID
                                    </th>
                                    <th className='table_heading'>
                                        Deeplink
                                    </th>
                                    <th className='table_heading'>
                                        Error
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {uploadResponse && uploadResponse.data && uploadResponse.data.length > 0 && uploadResponse.data.map((x, i) => {
                                    return <tr>
                                        <td>{i + 1}</td>
                                        <td>{x.title ? x.title : ''}</td>
                                        <td>{x.uuid ? x.uuid : ''}</td>
                                        <td>{x.deeplink ? x.deeplink : ''}</td>
                                        <td>{x.error ? x.error : ''}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        {/* <div style={{ marginBottom: -20 }} className='text-right mt-2'>
                            {userSearchResult.data && userSearchResult.data.pagination &&
                                <CustomPagination
                                    pagination={userSearchResult.data && userSearchResult.data.pagination && userSearchResult.data.pagination ? userSearchResult.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={userSearchResult.data && userSearchResult.data.results ? userSearchResult.data.results.length : 20}
                                />
                            }
                        </div> */}
                    </div>
                </Card>
            </Container>
        </>
    )
}
