import React, { useState } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useSelector, useDispatch } from 'react-redux'
import { isUserLoggedIn, logout } from '../redux/actions/auth.action'

import routes from "routes.js";
import Unauthorized from "views/auth/Unauthorized";

const Admin = (props) => {
  const [permissionsArray, setPermissionsArray] = React.useState([]);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth && state.auth.isAuthenticated)
  const _token = useSelector((state) => state.auth && state.auth.token)
  const userData = useSelector((state) => state.auth && state.auth.userData)
  const dispatch = useDispatch();
  const [isCollapsedToLeft, setIsCollapsedToLeft] = useState(false);


  // React.useEffect(() => {
  //   dispatch(isUserLoggedIn());
  // }, [])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  React.useEffect(() => {
    var permissions = getCookie('frenzi_admin_permissions');
    if (permissions && permissions != "") {
      var tempArr = permissions.split(",");
      setPermissionsArray(tempArr);
    } else {
      dispatch(logout());
    }
  }, []);

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const getRoutes = (routes) => {
    return routes.map((prop, index) => {
      if (prop.layout === "/admin") {
        if (isAuthenticated && _token && _token != "") {
          // if ((prop.key && permissionsArray.includes(prop.key)) || prop.key === "") {
          return (
            <Route
              // path={permissionsArray.includes(prop.key) ?  prop.layout + prop.path : '/admin/unauthorized'}
              path={prop.layout + prop.path}
              component={permissionsArray.includes(prop.key) || prop.key == 'public_key' ? prop.component : Unauthorized}
              key={index}
            />
          );
          // } else {
          //   return (<>
          //     <Route
          //       path={'/admin/unauthorized'}
          //       component={Unauthorized}
          //       key={'unauthorized'}
          //     />
          //     <Redirect from="*" to="/admin/unauthorized" />
          //   </>
          //   );
          // }
        } else {
          return <Redirect from="/" to="/auth/login" />
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        permissionsArray={permissionsArray}
        setIsCollapsedToLeft={(val) => setIsCollapsedToLeft(val)}
        isCollapsedToLeft={isCollapsedToLeft}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/frenzi-white-bg.jpg").default,
          imgAlt: "...",
        }}
      />
      <div className={`main-content ${isCollapsedToLeft ? 'menu-collapsed-to-left' : ''}`} ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
