import Header from 'components/Headers/Header';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Card, Col, Container, Form, Input, Modal, ModalHeader, Row, Table } from 'reactstrap';
import { getMediaProvidersList } from 'redux/actions/media.action';
import { getProvidersLangDropdown } from 'redux/actions/media.action';
import { getProvidersDropdown } from 'redux/actions/media.action';
import Loader from 'views/common/Loader';
import { countries } from 'country-list-json';
import Select, { components } from 'react-select';
import { updateMediaProvidersList } from 'redux/actions/media.action';
import { clearMessage } from 'redux/actions/media.action';
import CustomPagination from 'components/Pagination/CustomPagination';
import './_editMedia.scss';
import axiosInstance from 'helpers/axios';

const defaultCountry = {
    country: "",
    country_label: ''
}

const defaultLanguage = {
    language: "",
    language_label: ''
}

const defaultPlanType = {
    plan_type: "",
    plan_type_label: ''
}

const defaultFilterCountry = { country: "", country_label: '' }
const defaultFilterCountry2 = { country: "IN", country_label: '🇮🇳 India' }

export default function EditMediaProviders() {
    const [providerAddData, setProviderAddData] = useState([{
        ott_platform: '',
        deep_link: null,
        android_tv_deep_link: null,
        country: "IN",
        country_label: '🇮🇳 India',
        language: null,
        plan_type: null
    }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [removedProviders, setRemovedProviders] = useState([]);
    const [editableData, setEditableData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [pageLimit, setPageLimit] = useState(20);

    // filter data
    const [filterText, setFilterText] = useState('');
    const [filterLang, setFilterLang] = useState(defaultLanguage);
    const [filterCountry, setFilterCountry] = useState(defaultFilterCountry2);
    const [filterPlan, setFilterPlan] = useState(defaultPlanType);

    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.media && state.media.isLoading ? state.media.isLoading : false)
    const mediaData = useSelector((state) => state.media && state.media)
    const { status, errMesg, providersListData, providersLangListData, mediaProvidersListData } = mediaData

    useEffect(() => {
        dispatch(getProvidersDropdown('', 1, 20));
        dispatch(getProvidersLangDropdown());
    }, [])


    useEffect(() => {
        var mediaId = params.id;
        let lang = filterLang?.language || '';
        let country = filterCountry?.country || 'IN';
        let plan_type = filterPlan?.plan_type || '';

        if (mediaId && mediaId != "" && filterCountry && filterCountry?.country && filterCountry?.country != '') {
            dispatch(getMediaProvidersList(mediaId, country, plan_type, lang, currentPage, pageLimit));
        }
    }, [currentPage, pageLimit, filterText, filterLang, filterCountry, filterPlan])

    const handleOttSearch = (val) => {
        dispatch(getProvidersDropdown(val, 1, 20));
    }

    useEffect(() => {
        if (status && status != "") {
            // toast.success((status), {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });
            dispatch(clearMessage());
            setProviderAddData([{
                ott_platform: '',
                deep_link: '',
                android_tv_deep_link: '',
                country: "IN",
                country_label: '🇮🇳 India',
                language: null,
                plan_type: null
            }])
            setRemovedProviders([]);
            setEditableData({});
            setIsEdit(false);
            var mediaId = params.id;
            let lang = filterLang?.language || '';
            let country = filterCountry?.country || '';
            let plan_type = filterPlan?.plan_type || '';
            if (mediaId && mediaId != "") {
                dispatch(getMediaProvidersList(mediaId, country, plan_type, lang, currentPage, pageLimit));
            }
        }

        // if (errMesg && errMesg != "") {
        //     toast.error((errMesg), {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     dispatch(clearMessage());
        // }
    }, [errMesg, status])

    const addMoreProviders = () => {
        const tempData = providerAddData;
        tempData.push(
            {
                ott_platform: '',
                deep_link: '',
                android_tv_deep_link: '',
                country: "IN",
                country_label: '🇮🇳 India',
                language: null,
                plan_type: null
            }
        )
        setProviderAddData([...tempData]);
    }

    const duplicateLastItem = () => {
        const tempData = providerAddData;
        const newData = providerAddData[providerAddData.length - 1];
        tempData.push({ ...newData });
        setProviderAddData([...tempData]);
    }

    const removeMoreProviders = (index) => {
        const tempData = providerAddData;
        tempData.splice(index, 1);
        setProviderAddData([...tempData]);
    }

    const getOttOptions = (d) => {
        var opt = d && d.data && d.data.results.filter(x => x.is_active);
        var temp = [];
        opt && opt.length > 0 && opt.map((x, i) => {
            temp.push({
                value: x.uuid,
                label: x.platform_name ? x.platform_name : '',
            });
        })
        return temp;
    }

    const getOttLangOptions = (d) => {
        var opt = d && d.data && d.data;
        var temp = [];
        opt && opt.length > 0 && opt.map((x, i) => {
            temp.push({
                value: x.uuid,
                label: x.language ? x.language : '',
            });
        })
        return temp;
    }

    const getOttOptionsCountry = (opt) => {
        var temp = [];
        opt && opt.length > 0 && opt.map((x, i) => {
            temp.push({
                value: x.code,
                label: x.name ? x?.flag + ' ' + x.name : '',
            });
        })
        return temp;
    }

    const getOttOptionsValue = (ottArr) => {
        debugger
        var result = [];
        if (ottArr && ottArr.length > 0) {
            ottArr.map((x, i) => {
                result.push({
                    value: x,
                    label: x.image,
                });
            })
        }
        return result;
    }

    const handleSelectOnchangeFormaData = (value, name, index) => {
        const tempData = providerAddData;
        tempData[index][name] = value;
        setProviderAddData([...tempData]);
    }

    const handleSelectOnchangeFormaDataEdit = (value, name, index) => {
        if (editableData && editableData.providers && editableData.providers[index]) {
            const tempData = editableData.providers;
            tempData[index][name] = value;
            setProviderAddData([...tempData]);
        }
    }

    const handleSaveProviders = () => {
        var mediaId = params.id;
        if (providerAddData && providerAddData.length > 0 && mediaId && mediaId != '') {
            providerAddData.forEach(function (v) { delete v?.country_label });
            providerAddData.forEach(function (v) { delete v?.ott_platform_label });
            providerAddData.forEach(function (v) { delete v?.plan_type_label });
            providerAddData.forEach(function (v) { delete v?.language_label });

            const nestedArray = providerAddData.map(x => {
                if (x?.language?.length == 1) {
                    return { ...x, language: x.language[0].value };
                } else if (x?.language?.length > 1) {
                    return x.language.map(y => {
                        return { ...x, language: y.value }
                    })
                } else {
                    return x;
                }
            })

            const mergedArray = [].concat(...nestedArray);

            const body = {
                providers: mergedArray
            }
            dispatch(updateMediaProvidersList(mediaId, body))
        }
    }

    const handleDelete = (data) => {
        var mediaId = params.id;
        if (mediaId && mediaId != '') {
            if (window.confirm('Are you sure want to delete this provider ? ')) {
                const body = {
                    removed_providers: [
                        {
                            ott_platform: data?.ott_platform,
                            deep_link: data?.deep_link,
                            android_tv_deep_link: data?.android_tv_deep_link,
                            country: data?.country,
                            language: data?.language,
                            plan_type: data?.plan_type
                        },
                    ]
                }
                dispatch(updateMediaProvidersList(mediaId, body))
            }
        }
    }

    const handleMultiDelete = () => {
        var mediaId = params.id;
        if (mediaId && mediaId != '') {
            if (window.confirm('Are you sure want to delete these providers ? ')) {
                const body = {
                    removed_providers: removedProviders
                }
                dispatch(updateMediaProvidersList(mediaId, body))
            }
        }
    }

    const isObjectsAreSame = (x, y) => {
        var objectsAreSame = true;
        for (var propertyName in x) {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
        return objectsAreSame;
    }

    const isContains = (data) => {
        var tempArr = removedProviders;
        const newData = {
            ott_platform: data?.ott_platform,
            deep_link: data?.deep_link,
            android_tv_deep_link: data?.android_tv_deep_link,
            country: data?.country,
            language: data?.language,
            plan_type: data?.plan_type
        }
        var flag = false;
        tempArr.map((x, i) => {
            if (isObjectsAreSame(newData, x)) {
                flag = true;
                return true;
            }
        })
        return flag;
    }

    const handleSetDeleteData = (e, data) => {
        var tempArr = removedProviders;
        if (e.target.checked) {
            tempArr.push({
                ott_platform: data?.ott_platform,
                deep_link: data?.deep_link,
                android_tv_deep_link: data?.android_tv_deep_link,
                country: data?.country,
                language: data?.language,
                plan_type: data?.plan_type
            })
        } else {
            var index = -1;
            const newData = {
                ott_platform: data?.ott_platform,
                deep_link: data?.deep_link,
                android_tv_deep_link: data?.android_tv_deep_link,
                country: data?.country,
                language: data?.language,
                plan_type: data?.plan_type
            }
            tempArr.map((x, i) => {
                if (isObjectsAreSame(newData, x)) {
                    index = i;
                    return true;
                }
            })
            if (index != -1) {
                tempArr.splice(index, 1);
            }
        }
        setRemovedProviders([...tempArr])
    }

    const handleEdit = (data) => {
        const body = {
            providers: [
                {
                    ott_platform: data?.ott_platform,
                    deep_link: data?.deep_link,
                    android_tv_deep_link: data?.android_tv_deep_link,
                    country: data?.country,
                    country_label: countries.filter(x => x.code == data?.country) && countries.filter(x => x.code == data?.country)[0] && countries.filter(x => x.code == data?.country)[0]?.flag + " " + countries.filter(x => x.code == data?.country)[0]?.name,
                    // language_label: data?.language_name,
                    ott_platform_label: data?.ott_platform_name,
                    language: [{ value: data?.language, label: data?.language_name }],
                    plan_type: data?.plan_type,
                    plan_type_label: data?.plan_type
                },
            ],
            removed_providers: [
                {
                    ott_platform: data?.ott_platform,
                    deep_link: data?.deep_link,
                    android_tv_deep_link: data?.android_tv_deep_link,
                    country: data?.country,
                    language: data?.language,
                    plan_type: data?.plan_type
                },
            ]
        }
        setEditableData({ ...body });
        setIsEdit(true);
    }

    const handleUpdateProviders = () => {
        if (isEdit && editableData) {
            var mediaId = params.id;
            if (editableData.providers && editableData.providers.length > 0 && mediaId && mediaId != '') {
                editableData.providers.forEach(function (v) { delete v?.country_label });
                editableData.providers.forEach(function (v) { delete v?.ott_platform_label });
                editableData.providers.forEach(function (v) { delete v?.plan_type_label });
                editableData.providers.forEach(function (v) { delete v?.language_label });

                let tempProviderData = editableData.providers;
                let tempRemovedProviderData = editableData.removed_providers;

                const nestedArray = tempProviderData.map(x => {
                    if (x?.language?.length == 1) {
                        return { ...x, language: x.language[0].value };
                    } else if (x?.language?.length > 1) {
                        return x.language.map(y => {
                            return { ...x, language: y.value }
                        })
                    } else {
                        return x;
                    }
                })

                const mergedArray = [].concat(...nestedArray);

                const newEditableData = {
                    providers: [mergedArray[0]],
                    removed_providers: tempRemovedProviderData
                }

                const lhs = mergedArray[0];
                const rhs = tempRemovedProviderData[0];

                if (lhs?.android_tv_deep_link != rhs?.android_tv_deep_link || lhs?.country != rhs?.country || lhs?.deep_link != rhs?.deep_link || lhs?.language != rhs?.language || lhs?.ott_platform != rhs?.ott_platform || lhs?.plan_type != rhs?.plan_type) {
                    dispatch(updateMediaProvidersList(mediaId, newEditableData));
                } else {
                    if (mergedArray.length > 1) {
                        const body = {
                            providers: mergedArray.filter(x => x.language != mergedArray[0].language),
                        }
                        dispatch(updateMediaProvidersList(mediaId, body));
                    }
                }

                if (mergedArray.length > 1) {
                    setIsEdit(false);
                    setTimeout(() => {
                        const body = {
                            providers: mergedArray.filter(x => x.language != mergedArray[0].language),
                        }
                        dispatch(updateMediaProvidersList(mediaId, body));
                    }, 2000)
                }
            }
        }
    }

    const handleAutoOttSelect = (val, i, isEdit = false) => {
        if (val && val.length > 10) {
            let query = getHostNameWithoutSubdomain(val);
            let url = `adminpanel/ottplatformlist?search=${query}&page=1&pagelimit=20&is_active=true`;

            axiosInstance
                .get(url)
                .then((res) => {
                    if (res.status === 200) {
                        const results = res.data.data.results;

                        if (results.length > 0) {
                            const first = results[0];
                            if (isEdit) {
                                handleSelectOnchangeFormaDataEdit(first?.uuid, 'ott_platform', i);
                                handleSelectOnchangeFormaDataEdit(first?.platform_name, 'ott_platform_label', i);
                            } else {
                                handleSelectOnchangeFormaData(first?.uuid, 'ott_platform', i);
                                handleSelectOnchangeFormaData(first?.platform_name, 'ott_platform_label', i);
                            }
                        }
                    }
                })
                .catch((err) => {
                    if (err.status === 400) {
                        console.log(err);
                    }
                })

        }
    }

    const getHostNameWithoutSubdomain = (urlString) => {
        const url = new URL(urlString);
        let hostname = url.hostname;

        // Remove 'www.' if it exists
        if (hostname.startsWith('www.')) {
            hostname = hostname.slice(4);
        }

        // Remove top-level domain
        const parts = hostname.split('.');
        if (parts.length > 1) {
            parts.pop(); // Remove the last part (top-level domain)
        }

        return parts.join('.');
    }


    return (
        <div>
            <Header />
            <ToastContainer />
            {isLoading && <Loader />}
            <Container>
                <Card className='mt-2 mb-2' >
                    <Row className='justify-content-between m-0 pt-3 px-0 mb-2 align-items-center'>
                        <h2 className='ml-2' >Add/Edit Media Providers</h2>
                        {/* <div>
                            <Link to="/admin/media">
                                <Button color="darker" onClick={() => history.goBack()} className='mr-3'>
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            </Link>
                        </div> */}
                    </Row>
                    <Row className='media_detail_container px-2 m-0'>
                        <Col xs={12} className="p-2 m-0" style={{ border: '1px solid green' }}>
                            {providerAddData && providerAddData.length > 0 && providerAddData.map((x, i) => {
                                return <Row className='m-0 provider-form pb-2 pt-2' style={{ borderBottom: '1px solid #000' }}>
                                    <Col xs={12} className="mb-2">
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Select Providers</h3>
                                            </Col>
                                            <Col xs={7}>
                                                <Form className='edit_media_input w-100'>
                                                    <Select
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            getOttOptions(providersListData)
                                                        }
                                                        value={
                                                            { value: x?.ott_platform, label: x?.ott_platform_label }
                                                        }
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaData(e.value, 'ott_platform', i);
                                                            handleSelectOnchangeFormaData(e.label, 'ott_platform_label', i);
                                                        }}
                                                        onInputChange={(e) => handleOttSearch(e)}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                            {i > 0 &&
                                                <Col xs={1}>
                                                    <Button size='sm' onClick={() => removeMoreProviders(i)} color='danger'>Remove</Button>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Language</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Form className='edit_media_input w-100 mb-2'>
                                                    <Select
                                                        isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            getOttLangOptions(providersLangListData)
                                                        }
                                                        value={x?.language}
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaData(e, 'language', i);
                                                        }}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Country</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Form className='edit_media_input w-100 mb-2'>
                                                    <Select
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            getOttOptionsCountry([defaultFilterCountry, ...countries])
                                                        }
                                                        // value={
                                                        //     getOttOptionsValue(x)
                                                        // }
                                                        value={
                                                            { value: x?.country, label: x?.country_label }
                                                        }
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaData(e.value, 'country', i);
                                                            handleSelectOnchangeFormaData(e.label, 'country_label', i);
                                                        }}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <Row className='align-items-center' >
                                            <Col xs={5}>
                                                <h3>Plan Type</h3>
                                            </Col>
                                            <Col xs={7}>
                                                <Form className='edit_media_input w-100 mb-2'>
                                                    <Select
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            [
                                                                {
                                                                    value: null,
                                                                    label: 'None'
                                                                },
                                                                {
                                                                    value: 'subscription',
                                                                    label: 'Subscription'
                                                                },
                                                                {
                                                                    value: 'rent',
                                                                    label: 'Rented'
                                                                },
                                                                {
                                                                    value: 'free',
                                                                    label: 'Free'
                                                                }
                                                            ]
                                                        }
                                                        // value={
                                                        //     getOttOptionsValue(x)
                                                        // }
                                                        value={
                                                            { value: x?.plan_type, label: x?.plan_type_label }
                                                        }
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaData(e.value, 'plan_type', i);
                                                            handleSelectOnchangeFormaData(e.label, 'plan_type_label', i);
                                                        }}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Enter Deeplink</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Input
                                                    value={x?.deep_link}
                                                    onChange={(e) => {
                                                        handleSelectOnchangeFormaData(e.target.value, 'deep_link', i);
                                                        handleAutoOttSelect(e.target.value, i)
                                                    }}
                                                    placeholder="Enter deeplink..."
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className="mt-2">
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Enter TV Deeplink</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Input
                                                    value={x?.android_tv_deep_link}
                                                    onChange={(e) => handleSelectOnchangeFormaData(e.target.value, 'android_tv_deep_link', i)}
                                                    placeholder="Enter tv deeplink..."
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })}
                            <Row className='mt-2 align-items-end justify-content-space-between'>
                                {/* <h3 className='m-0 pl-2'>Media Providers :- </h3> */}
                                <Col>
                                    <Button onClick={() => handleSaveProviders()} color='success'>Save</Button>
                                    {removedProviders.length > 0 &&
                                        <Button onClick={() => handleMultiDelete()} color='danger'>Delete Selected</Button>
                                    }
                                </Col>
                                <Col>
                                    <Row className='text-right ml-0 mr-0 justify-content-end'>
                                        <Button onClick={() => addMoreProviders()} color='primary'>Add New</Button>
                                        <Button onClick={() => duplicateLastItem()} color='warning'>Duplicate</Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        <Row className='m-0 pt-2 align-items-center justify-content-space-between'>
                            {/* <Col xs={3}>
                                <Input
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                    placeholder="Search here..."
                                />
                            </Col> */}
                            <Col xs={3}>
                                <Row className='align-items-center' >
                                    <Col xs={5}>
                                        <h4>Language</h4>
                                    </Col>
                                    <Col xs={7}>
                                        <Form className='edit_media_input w-100 mb-2'>
                                            <Select
                                                options={
                                                    getOttLangOptions(providersLangListData)
                                                }
                                                value={
                                                    { value: filterLang.language, label: filterLang.language_label }
                                                }
                                                onChange={(e) => {
                                                    setFilterLang({ language: e.value, language_label: e.label });
                                                }}
                                                components={components}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3}>
                                <Row className='align-items-center' >
                                    <Col xs={4}>
                                        <h4>Country</h4>
                                    </Col>
                                    <Col xs={8}>
                                        <Form className='edit_media_input w-100 mb-2'>
                                            <Select
                                                options={
                                                    getOttOptionsCountry(countries)
                                                }
                                                value={
                                                    { value: filterCountry?.country, label: filterCountry?.country_label }
                                                }
                                                onChange={(e) => {
                                                    setFilterCountry({ country: e.value, country_label: e.label });
                                                }}
                                                components={components}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3}>
                                <Row className='align-items-center' >
                                    <Col xs={5}>
                                        <h4>Plan Type</h4>
                                    </Col>
                                    <Col xs={7}>
                                        <Form className='edit_media_input w-100 mb-2'>
                                            <Select
                                                options={
                                                    [
                                                        {
                                                            value: null,
                                                            label: 'None'
                                                        },
                                                        {
                                                            value: 'subscription',
                                                            label: 'Subscription'
                                                        },
                                                        {
                                                            value: 'rent',
                                                            label: 'Rented'
                                                        },
                                                        {
                                                            value: 'free',
                                                            label: 'Free'
                                                        }
                                                    ]
                                                }
                                                value={
                                                    { value: filterPlan?.plan_type, label: filterPlan?.plan_type_label }
                                                }
                                                onChange={(e) => {
                                                    setFilterPlan({ plan_type: e.value, plan_type_label: e.label });
                                                }}
                                                components={components}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3}>
                                <Button size='md'
                                    color='danger'
                                    onClick={() => {
                                        setFilterLang(defaultLanguage);
                                        setFilterCountry(defaultFilterCountry);
                                        setFilterPlan(defaultPlanType);
                                        var mediaId = params.id;
                                        let lang = filterLang?.language || '';
                                        let country = '';
                                        let plan_type = filterPlan?.plan_type || '';

                                        if (mediaId && mediaId != "") {
                                            dispatch(getMediaProvidersList(mediaId, country, plan_type, lang, currentPage, pageLimit));
                                        }
                                    }}
                                >
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                        <Table hover className='mt-2'>
                            <thead>
                                <tr>
                                    <th className='table_heading' width="7%">
                                        S.No.
                                    </th>
                                    <th className='table_heading'>
                                        OTT Name
                                    </th>
                                    <th className='table_heading'>
                                        Plan Type
                                    </th>
                                    <th className='table_heading'>
                                        country
                                    </th>
                                    <th className='table_heading'>
                                        Language
                                    </th>
                                    <th className='table_heading'>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {mediaProvidersListData && mediaProvidersListData.data && mediaProvidersListData.data.results && mediaProvidersListData.data.results.length > 0 && mediaProvidersListData.data.results.map((x, i) => {
                                    return <tr key={i}>
                                        <td>
                                            {(pageLimit * (currentPage - 1)) + (i + 1)}
                                        </td>
                                        <td>{x.ott_platform_name ? x.ott_platform_name : 'N/A'}</td>
                                        <td>{x.plan_type ? x.plan_type : 'N/A'}</td>
                                        <td>{x.country ? x.country : 'N/A'}</td>
                                        <td>{x.language_name ? x.language_name : 'N/A'}</td>
                                        <td>
                                            <Input
                                                type='checkbox'
                                                onChange={(e) => handleSetDeleteData(e, x)} checked={isContains(x)}
                                            />
                                            <Button size='sm' color='danger' onClick={() => handleDelete(x)}>
                                                <i className='fas fa-trash'></i>
                                            </Button>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="warning"
                                                size="sm"
                                                type="button"
                                                style={{ marginRight: 10 }}
                                                onClick={() => handleEdit(x)}
                                            >
                                                <i className="fas fa-pen pt-1"></i>
                                            </Button>

                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        {mediaProvidersListData.data && mediaProvidersListData.data.pagination &&
                            <CustomPagination
                                pagination={mediaProvidersListData.data && mediaProvidersListData.data.pagination && mediaProvidersListData.data.pagination ? mediaProvidersListData.data.pagination : {}}
                                setCurrentPage={(val) => setCurrentPage(val)}
                                setPageLimit={(val) => setPageLimit(val)}
                                defaultPageList={mediaProvidersListData.data && mediaProvidersListData.data.results ? mediaProvidersListData.data.results.length : 20}
                            />
                        }
                    </div>
                </Card>
            </Container>
            <Modal
                isOpen={isEdit}
                toggle={() => setIsEdit(!isEdit)}
                size="lg"
            >
                <ModalHeader className='p-3' toggle={() => setIsEdit(!isEdit)}>
                    <h3>Edit Providers</h3>
                </ModalHeader>
                <div className='pb-3'>
                    <Row className='media_detail_container px-2'>
                        <Col xs={12} className="pb-2">
                            {editableData && editableData.providers && editableData.providers.length > 0 && editableData.providers.map((x, i) => {
                                return <Row className='m-0 provider-form pb-2 pt-2' style={{ borderBottom: '1px solid #000' }}>
                                    <Col xs={12} className="mb-2">
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Select Providers</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Form className='edit_media_input w-100'>
                                                    <Select
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            getOttOptions(providersListData)
                                                        }
                                                        value={
                                                            { value: x?.ott_platform, label: x?.ott_platform_label }
                                                        }
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaDataEdit(e.value, 'ott_platform', i);
                                                            handleSelectOnchangeFormaDataEdit(e.label, 'ott_platform_label', i);
                                                        }}
                                                        onInputChange={(e) => handleOttSearch(e)}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Language</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Form className='edit_media_input w-100 mb-2'>
                                                    <Select
                                                        isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            getOttLangOptions(providersLangListData)
                                                        }
                                                        value={x?.language}
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaDataEdit(e, 'language', i);
                                                        }}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Country</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Form className='edit_media_input w-100 mb-2'>
                                                    <Select
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            getOttOptionsCountry(countries)
                                                        }
                                                        // value={
                                                        //     getOttOptionsValue(x)
                                                        // }
                                                        value={
                                                            { value: x?.country, label: x?.country_label }
                                                        }
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaDataEdit(e.value, 'country', i);
                                                            handleSelectOnchangeFormaDataEdit(e.label, 'country_label', i);
                                                        }}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Plan Type</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Form className='edit_media_input w-100 mb-2'>
                                                    <Select
                                                        // isMulti
                                                        // closeMenuOnSelect={false}
                                                        // components={animatedComponents}
                                                        options={
                                                            [
                                                                {
                                                                    value: null,
                                                                    label: 'None'
                                                                },
                                                                {
                                                                    value: 'subscription',
                                                                    label: 'Subscription'
                                                                },
                                                                {
                                                                    value: 'rent',
                                                                    label: 'Rented'
                                                                },
                                                                {
                                                                    value: 'free',
                                                                    label: 'Free'
                                                                }
                                                            ]
                                                        }
                                                        // value={
                                                        //     getOttOptionsValue(x)
                                                        // }
                                                        value={
                                                            { value: x?.plan_type, label: x?.plan_type_label }
                                                        }
                                                        onChange={(e) => {
                                                            handleSelectOnchangeFormaDataEdit(e.value, 'plan_type', i);
                                                            handleSelectOnchangeFormaDataEdit(e.label, 'plan_type_label', i);
                                                        }}
                                                        components={components}
                                                    />
                                                </Form>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12}>
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Enter Deeplink</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Input
                                                    value={x?.deep_link}
                                                    onChange={(e) => {
                                                        handleSelectOnchangeFormaDataEdit(e.target.value, 'deep_link', i);
                                                        handleAutoOttSelect(e.target.value, i, true)
                                                    }}
                                                    placeholder="Enter deeplink..."
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} className="mt-2">
                                        <Row className='align-items-center' >
                                            <Col xs={4}>
                                                <h3>Enter TV Deeplink</h3>
                                            </Col>
                                            <Col xs={8}>
                                                <Input
                                                    value={x?.android_tv_deep_link}
                                                    onChange={(e) => handleSelectOnchangeFormaDataEdit(e.target.value, 'android_tv_deep_link', i)}
                                                    placeholder="Enter tv deeplink..."
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            })}
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'center' }} className='mt-2'>
                        <Button color="info" onClick={() => handleUpdateProviders()} >Update</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
