import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOGS_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.BLOGS_CLEAR_MESSAGE_SUCCESS });
        }
    }
}


export const clearCreatedStoryData = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOGS_CLEAR_STORY_DATA_REQUEST });
        if (clear) {
            dispatch({ type: WEB.BLOGS_CLEAR_STORY_DATA_SUCCESS });
        }
    }
}

export const getBlogCategoryData = () => {
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_GET_REQUEST });
        let url = `blog/blogcategorylist`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.CATEGORY_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CATEGORY_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateBlogCategoryData = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_UPDATE_REQUEST });
        let url = `blog/blogcategorydetail/${id}`;

        axiosInstance
            .put(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.CATEGORY_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CATEGORY_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteBlogCategoryData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_DELETE_REQUEST });
        let url = `blog/blogcategorydetail/${id}`;

        axiosInstance
            .delete(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.CATEGORY_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CATEGORY_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const createBlogCategoryData = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_CREATE_REQUEST });
        let url = `blog/blogcategorylist`;

        axiosInstance
            .post(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.CATEGORY_CREATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CATEGORY_CREATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

// subcategory action

export const getBlogSubCategoryData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.SUBCATEGORY_GET_REQUEST });
        let url = `blog/blogsubcategorylist?category=${id}`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.SUBCATEGORY_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SUBCATEGORY_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateBlogSubCategoryData = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.SUBCATEGORY_UPDATE_REQUEST });
        let url = `blog/blogsubcategorydetail/${id}`;

        axiosInstance
            .put(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.SUBCATEGORY_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SUBCATEGORY_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteBlogSubCategoryData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.SUBCATEGORY_DELETE_REQUEST });
        let url = `blog/blogsubcategorydetail/${id}`;

        axiosInstance
            .delete(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.SUBCATEGORY_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SUBCATEGORY_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const createBlogSubCategoryData = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.SUBCATEGORY_CREATE_REQUEST });
        let url = `blog/blogsubcategorylist`;

        axiosInstance
            .post(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.SUBCATEGORY_CREATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SUBCATEGORY_CREATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

// blogs action

export const getBlogData = (query = '', page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOG_GET_REQUEST });
        let url = `blog/bloglist?search=${query}&page=${page}&pagelimit=${pagelimit}`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BLOG_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BLOG_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateBlogData = (id, body, hideMesg = false) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOG_UPDATE_REQUEST });
        let url = `blog/blogdetail/${id}`;

        axiosInstance
            .put(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    if (!hideMesg) {
                        console.log("hideMesg11-", hideMesg)
                        dispatch({
                            type: WEB.BLOG_UPDATE_SUCCESS,
                            payload: {
                                data: payload,
                            },
                        })
                    } else {
                        console.log("hideMesg22-", hideMesg)
                        dispatch({
                            type: WEB.BLOG_UPDATE_SUCCESS,
                            payload: {
                                data: { ...payload, hideMesg: true },
                            },
                        })
                    }
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BLOG_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteBlogData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOG_DELETE_REQUEST });
        let url = `blog/blogdetail/${id}`;

        axiosInstance
            .delete(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BLOG_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BLOG_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const createBlogData = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOG_CREATE_REQUEST });
        let url = `blog/bloglist`;

        axiosInstance
            .post(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BLOG_CREATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BLOG_CREATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getBlogDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOG_DETAIL_GET_REQUEST });
        let url = `blog/blogdetail/${id}`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BLOG_DETAIL_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BLOG_DETAIL_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getBlogLanguage = () => {
    return (dispatch) => {
        dispatch({ type: WEB.BLOG_LANG_GET_REQUEST });
        let url = `native_main/languagelist`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BLOG_LANG_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BLOG_LANG_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getWebstoriesData = (page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORIES_GET_REQUEST });
        let url = `blog/webstorylist?&page=${page}&pagelimit=${pagelimit}`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORIES_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORIES_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const createWebstoriesData = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORIES_CREATE_REQUEST });
        let url = `blog/webstorylist`;

        axiosInstance
            .post(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORIES_CREATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORIES_CREATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const deleteWebstoryData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORIES_DELETE_REQUEST });
        let url = `blog/webstorydetail/${id}`;

        axiosInstance
            .delete(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORIES_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORIES_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const getWebstoryDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORY_DETAIL_GET_REQUEST });
        let url = `blog/webstorydetail/${id}`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORY_DETAIL_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORY_DETAIL_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const updateWebstoryData = (id, body, showMesg) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORY_UPDATE_REQUEST });
        let url = `blog/webstorydetail/${id}`;

        axiosInstance
            .put(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORY_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                            showMesg: showMesg
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORY_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const getWebstoryReelsData = (webstoryId) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORYREELS_GET_REQUEST });
        let url = `blog/webstoryreellist?web_story=${webstoryId}&page=1&pagelimit=50`;

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORYREELS_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORYREELS_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const updateWebstoryReelsData = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORYREELS_UPDATE_REQUEST });
        let url = `blog/webstoryreeldetail/${id}`;

        axiosInstance
            .put(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORYREELS_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORYREELS_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const createWebstoryReelsData = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORYREELS_CREATE_REQUEST });
        let url = `blog/webstoryreellist`;

        axiosInstance
            .post(url, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORYREELS_CREATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORYREELS_CREATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};

export const deleteWebstoryReelsData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.WEBSTORYREELS_DELETE_REQUEST });
        let url = `blog/webstoryreeldetail/${id}`;

        axiosInstance
            .delete(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.WEBSTORYREELS_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    });
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WEBSTORYREELS_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });
    };
};