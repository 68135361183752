import helpers from 'helpers/common';
import React, { useState } from 'react'
import { Button, Col, CustomInput, Form, FormGroup, Input, Row } from 'reactstrap';
import ReactGA from 'react-ga';
import { addPoll } from '../../redux/actions/polls.action';
import AWS from 'aws-sdk'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

const defaultObj = {
    image: "",
    question: "",
    scheduled_at: "",
    expiry: "",
    frenzi_points: 0,
    is_active: false,
    options: []
}

export default function AddPoll() {

    const [pollData, setPollData] = useState(defaultObj);
    const posterInputChange = React.useRef(null);

    const dispatch = useDispatch();

    React.useEffect(() => {
        setPollData({...defaultObj,options : []});
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleOnchangeFormaData = (value, name) => {
        setPollData({ ...pollData, [name]: value });
    }

    const handleOnchangeFormaOptData = (value, index) => {
        var tempArr = pollData.options;
        tempArr[index].option = value;
        tempArr[index].order = index + 1;
        pollData.options = tempArr;
        setPollData({ ...pollData });
    }

    const onPosterChangeClick = () => {
        posterInputChange.current.click();
    }

    const handleDeleteOpt = (index) => {
        var tempArr = pollData.options;
        tempArr.splice(index, 1);
        pollData.options = tempArr;
        setPollData({ ...pollData });
    }

    const handleAddOption = () => {
        var tempArr = pollData.options;
        tempArr.push({
            option: '',
            order: tempArr.length + 1
        })
        pollData.options = tempArr;
        setPollData({ ...pollData });
    }

    const handlePosterChange = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "polls_quizes/poll-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Poll', "Uploaded poll image file to s3 bucket -" + keyName, data.Location);
                handleOnchangeFormaData(data.Location, 'image');
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const getDateFormat = (date) => {
        // time data '2022-11-11' does not match format '%d/%m/%Y %H:%M:%S'
        // DD/MM/YYYY
        var con = new Date(date);
        let m = con.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        let d = con.getDate();
        d = d < 10 ? '0' + d : d;
        let y = con.getFullYear();
        let h = con.getHours();
        h = h < 10 ? '0' + h : h;
        let min = con.getMinutes();
        min = min < 10 ? '0' + min : min;
        let mil = con.getMilliseconds();
        mil = mil < 10 ? '0' + mil : mil;
        const time = d + "/" + m + "/" + y + " " + h + ":" + min + ":" + mil;
        return time;
    }

    const handleCreate = () => {
        const body = {
            ...pollData,
            scheduled_at: pollData.scheduled_at && pollData.scheduled_at != "" ? getDateFormat(pollData.scheduled_at) : '',
            expiry: pollData.expiry && pollData.expiry != "" ? getDateFormat(pollData.expiry) : '',
        }
        eventReporting('Poll', "Poll created -", pollData.question ? pollData.question : '');
        dispatch(addPoll(body));
    }

    return (
        <div>
            <Row className='media_detail_container'>
                <Col sm="auto" className='position-relative pr-0'>
                    {pollData.image && pollData.image != "" ?
                        <img src={pollData.image ? helpers.getFullUrl(pollData.image) : ''} className='media_image' />
                        :
                        <div style={{ width: 125, height: 150 }} className='default-img'>
                            <i className='fas fa-image'></i>
                        </div>
                    }
                    <i onClick={() => onPosterChangeClick()} className='fas position-absolute fa-edit pointer' style={{ top: 5, right: 5, zIndex: 9, background: '#fff', color: '#f5365c', borderRadius: '50%', padding: 5 }} ></i>
                    <input
                        style={{ display: 'none' }}
                        ref={posterInputChange}
                        type="file"
                        onChange={(e) => handlePosterChange(e)}
                        accept="image/*"
                    />
                </Col>
            </Row>
            <Row className='media_detail_container'>
                <Col xs={12} lg={12} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Question</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="search" placeholder="Enter a question" value={pollData.question ? pollData.question : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'question')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Scheduled At</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="datetime-local" placeholder="Enter a question" value={pollData.scheduled_at ? pollData.scheduled_at : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'scheduled_at')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Expiry</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="datetime-local" placeholder="Enter a question" value={pollData.expiry ? pollData.expiry : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'expiry')} />
                        </FormGroup>
                    </Form>
                </Col>
                {/* <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Frenzi Points</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="text" placeholder="Frenzi Points" value={pollData.frenzi_points ? pollData.frenzi_points : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'frenzi_points')} />
                        </FormGroup>
                    </Form>
                </Col> */}
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Status</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id={`exampleCustomSwitch-3`}
                                name="customSwitch2"
                                className='p-0'
                                checked={pollData.is_active ? pollData.is_active : false}
                                onChange={(e) => handleOnchangeFormaData(e.target.checked, 'is_active')}
                            />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Add Options</p>
                    <Form className='edit_media_input'>
                        <Button onClick={() => handleAddOption()} color='primary'>Add</Button>
                    </Form>
                </Col>
            </Row>
            <div>
                <ol>
                    {pollData.options && pollData.options.length > 0 && pollData.options.map((opt, j) => {
                        return <li style={{ marginTop: 20 }} key={j}>
                            <Row>
                                <Col>
                                    <Input disabled={false} type="text" placeholder={`Option ${j + 1}`} value={opt.option ? opt.option : ''} onChange={(e) => handleOnchangeFormaOptData(e.target.value, j)} />
                                </Col>
                                <Col>
                                    <Button
                                        className="btn-icon btn-simple"
                                        style={{ marginTop: 7 }}
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => handleDeleteOpt(j)}
                                    >
                                        <i className="fas fa-trash pt-1"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </li>
                    })}
                </ol>
            </div>
            <div className='text-center'>
                <Button onClick={() => handleCreate()} color='success'>Create</Button>
            </div>
        </div>
    )
}
