import Header from 'components/Headers/Header';
import React from 'react';
import { Card, Container } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactGA from 'react-ga';

function PrivacyPolicy() {

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Card className='mt-4 p-3'>
          <h3 className='mb-3'>Privacy Policy</h3>
          <div>
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data=""
                onReady={editor => {
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </div>
          </div>
        </Card>
      </Container>
    </>
  )
}

export default PrivacyPolicy