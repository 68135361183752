import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    platformListResult: {},
    platformDataResult: {},
    platformPriceListResult: {},
    platformPriceDataResult: {},
    platformPlanTypeListResult: {},
    platformPriceDropdownListResult: {},
    platformPlanDropdownListResult: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
};


export const platformReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.PLATFORM_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.PLATFORM_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformListResult: payload,
            };
        case WEB.PLATFORM_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Platform Updated",
            };
        case WEB.PLATFORM_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Platform Deleted",
            };
        case WEB.PLATFORM_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Platform Added",
            };
        case WEB.PLATFORM_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_GET_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_GET_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformDataResult: payload,
            };
        case WEB.PLATFORM_GET_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PRICE_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PRICE_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformPriceListResult: payload,
            };
        case WEB.PLATFORM_PRICE_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PRICE_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PRICE_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Platform Price Updated",
            };
        case WEB.PLATFORM_PRICE_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PRICE_GET_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PRICE_GET_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformPriceDataResult: payload,
            };
        case WEB.PLATFORM_PRICE_GET_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PRICE_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PRICE_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Platform Price Deleted",
            };
        case WEB.PLATFORM_PRICE_DELETE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PRICE_ADD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PRICE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.data.status,
                message: payload.data.message ? parseInt(Math.random() * 1000) + '=>' + payload.data.message : parseInt(Math.random() * 1000) + "=>Platform Price Added",
            };
        case WEB.PLATFORM_PRICE_ADD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PLAN_TYPES_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PLAN_TYPES_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformPlanTypeListResult: payload,
            };
        case WEB.PLATFORM_PLAN_TYPES_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PRICE_DROPDOWN_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PRICE_DROPDOWN_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformPriceDropdownListResult: payload,
            };
        case WEB.PLATFORM_PRICE_DROPDOWN_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.PLATFORM_PLAN_DROPDOWN_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PLATFORM_PLAN_DROPDOWN_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                platformPlanDropdownListResult: payload,
            };
        case WEB.PLATFORM_PLAN_DROPDOWN_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        // Update Watch on TV
        case WEB.WATCH_ON_TV_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WATCH_ON_TV_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                message: payload.data?.message || 'updated',
                status: payload.data?.message || 'updated',
            };
        case WEB.WATCH_ON_TV_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        default:
            return state;

    }
}