import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { useSelector, useDispatch } from 'react-redux'
import ReactGA from 'react-ga';
import { isUserLoggedIn } from './redux/actions/auth.action'
import './_style.scss'

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    debug: false
});

export default function App() {

    const isAuthenticated = useSelector((state) => state.auth && state.auth.isAuthenticated)
    const _token = useSelector((state) => state.auth && state.auth.token)
    const dispatch = useDispatch()

    React.useEffect(() => {
        var userData = JSON.parse(localStorage.getItem('frenzi_admin_access'));
        if (userData && userData.uuid && userData.uuid != "") {
            ReactGA.set({ userId: userData.uuid });
        }
    }, [_token])

    return (
        <BrowserRouter>
            <Switch>
                {isAuthenticated && _token && _token != "" ?
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                    :
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                }
                {isAuthenticated && _token && _token != "" ?
                    <Redirect from="/" to="/admin/index" />
                    :
                    <Redirect from="/" to="/auth/login" />
                }
            </Switch>
        </BrowserRouter>
    )
}
