import CustomPagination from 'components/Pagination/CustomPagination';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Col, CustomInput, Input, Row, Table } from 'reactstrap'
import { deleteBlogCategoryData } from 'redux/actions/blogs.action';
import { createBlogCategoryData } from 'redux/actions/blogs.action';
import { clearMessage } from 'redux/actions/blogs.action';
import { updateBlogCategoryData } from 'redux/actions/blogs.action';
import { getBlogCategoryData } from 'redux/actions/blogs.action';

export default function Categories() {
    const [categoryName, setCategoryName] = useState('');
    const [inputShow, setInputShow] = useState('');
    const [inputOrderShow, setInputOrderShow] = useState('');
    const [inputText, setInputText] = useState('');
    const [inputNumber, setInputNumber] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(10);

    const dispatch = useDispatch();

    const categoryList = useSelector((state) => state.blogs && state.blogs.categoryList ? state.blogs.categoryList : {})

    const isLoading = useSelector((state) => state.blogs && state.blogs.isLoading ? state.blogs.isLoading : false)
    const status = useSelector((state) => state.blogs && state.blogs.status ? state.blogs.status : '')
    const errMesg = useSelector((state) => state.blogs && state.blogs.errMesg ? state.blogs.errMesg : '')


    useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setCategoryName('');
            dispatch(clearMessage());
            dispatch(getBlogCategoryData());
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    useEffect(() => {
        dispatch(getBlogCategoryData());
    }, [])

    const handleCreate = () => {
        if (categoryName && categoryName != '') {
            dispatch(createBlogCategoryData({ category: categoryName }));
        } else {
            toast.error(("Category name can't be empty"), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDeleteKey = (data) => {
        dispatch(deleteBlogCategoryData(data?.uuid));
    }
    const handleUpdate = (val, prevVal, name, id) => {
        if (!prevVal || val != prevVal) {
            const body = { [name]: val }
            dispatch(updateBlogCategoryData(id, body));
        }
        setInputShow('');
        setInputText('');
        setInputOrderShow('');
    }

    return (
        <div>
            <Row>
                <Col sm={3} className=" search-icon-box">
                    <Input placeholder='Category Name' onChange={(e) => setCategoryName(e.target.value)} value={categoryName} />
                </Col>
                <Col sm="auto">
                    <Button color="success" onClick={() => handleCreate()}>Create</Button>
                </Col>
                <Col xs={12}>
                    <Table className="align-items-center mt-3 table-flush " >
                        <thead >
                            <tr>
                                <th className='table_heading'>Sr No.</th>
                                <th className='table_heading'>Category</th>
                                <th className='table_heading'>Order</th>
                                <th className='table_heading'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categoryList.data?.length > 0 &&
                                categoryList.data.map((x, i) => {
                                    return <tr>
                                        <th scope="row">{(pageLimit * (currentPage - 1)) + (i + 1)}</th>
                                        <td>
                                            {inputShow === x.uuid ?
                                                <Input
                                                    style={{ width: 'auto' }}
                                                    size={'sm'}
                                                    autoFocus={true}
                                                    placeholder='Category Name'
                                                    onChange={(e) => setInputText(e.target.value)} onBlur={(e) => handleUpdate(e.target.value, x?.category, 'category', x.uuid)}
                                                    value={inputText} />
                                                :
                                                <span className='pointer' onClick={() => {
                                                    setInputText(x?.category)
                                                    setInputShow(x.uuid);
                                                }}>{x?.category}</span>
                                            }
                                        </td>
                                        <td>
                                            {inputOrderShow === x.uuid ?
                                                <Input
                                                    style={{ width: 'auto' }}
                                                    size={'sm'}
                                                    type='number'
                                                    autoFocus={true}
                                                    placeholder='Order'
                                                    onChange={(e) => setInputNumber(e.target.value)} onBlur={(e) => handleUpdate(e.target.value, parseInt(x?.order), 'order', x.uuid)}
                                                    value={inputNumber} />
                                                :
                                                <span className='pointer' onClick={() => {
                                                    setInputNumber(x?.order)
                                                    setInputOrderShow(x.uuid);
                                                }}>{x?.order}</span>
                                            }
                                        </td>

                                        <td style={{ minWidth: '160px' }} className="td-actions text-left">
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    if (window.confirm("Do you really want to delete this category ?")) {
                                                        handleDeleteKey(x);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>

                                    </tr>
                                })
                            }
                            {categoryList.data?.length == 0 &&
                                <tr><td colSpan={4} className="text-center">No Data Found</td></tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}
