import { WEB } from "../constants/constants";

const initialState = {
  isLoading: false,
  isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
  token: localStorage.getItem("frenzi_admin_access")
    ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access
    : "",
  user: {},
  errMesg: "",
  status: "",
  message: "",
  req: {},
  studentList: {},
  qRList: {},
  QRDetails: {},
};

export const studentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case WEB.STU_DATA_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case WEB.STU_DATA_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMesg: "",
        status: "",
        studentList: payload,
      };
    case WEB.STU_DATA_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMesg: payload.error,
      };
      case WEB.QR_DATA_GET_REQUEST:
      return{
      ...state,
      isLoading: true,
      };
      case WEB.QR_DATA_GET_SUCCESS:
      return{
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMesg: "",
        status: "",
        qRList: payload,
      }
      case WEB.QR_DATA_GET_FAILED:
      return{
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMesg: payload.error,
      }
      case WEB.QR_DATA_UPDATE_REQUEST:
      return{
      ...state,
      isLoading: true,
      };
      case WEB.QR_DATA_UPDATE_SUCCESS:
      return{
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMesg: "",
        status: "",
        QRDetails: payload,
      }
      case WEB.QR_DATA_UPDATE_FAILED:
      return{
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMesg: payload.error,
      }

    default:
      return { ...state };
    
  }
};
