import { User } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    reportedUsers: {},
    userProfileDetails: {},
    errMess: "",
    status: "",
    req: {},
    frenziPointsDetails: {},
    blockedByDetails: {},
    reportedByDetails: {},
    inactiveUserList: {},
    blockedToDetails: {},
    userWatchlistData: {},
    userWatchlistMembersData: {},
    userWatchlistMediaData: {}
};

export const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case User.REVIEW_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.REVIEW_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
            };
        case User.GET_REPORTED_USERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_REPORTED_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                reportedUsers: payload,
            };
        case User.GET_REPORTED_USERS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_USER_PROFILE_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_USER_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                userProfileDetails: payload,
            };
        case User.GET_USER_PROFILE_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.ACTIVATE_DEACTIVATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.ACTIVATE_DEACTIVATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: payload.message ? payload.message : 'User status updated',
            };
        case User.ACTIVATE_DEACTIVATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.REPORT_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.REPORT_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: payload.message ? payload.message : 'User report status changed',
            };
        case User.REPORT_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.DELETE_USER_REPORT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.DELETE_USER_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: payload.message ? payload.message : 'User report deleted',
            };
        case User.DELETE_USER_REPORT_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_FRENZI_POINTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_FRENZI_POINTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                frenziPointsDetails: payload,
            };
        case User.GET_FRENZI_POINTS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_BLOCKED_BY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_BLOCKED_BY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                blockedByDetails: payload,
            };
        case User.GET_BLOCKED_BY_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_REPORTED_BY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_REPORTED_BY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                reportedByDetails: payload,
            };
        case User.GET_REPORTED_BY_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_INACTIVE_USERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_INACTIVE_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                inactiveUserList: payload,
            };
        case User.GET_INACTIVE_USERS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_BLOCKED_TO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_BLOCKED_TO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                blockedToDetails: payload,
            };
        case User.GET_BLOCKED_TO_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_USER_WATCHLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_USER_WATCHLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                userWatchlistData: payload,
            };
        case User.GET_USER_WATCHLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_USER_WATCHLIST_MEMBERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_USER_WATCHLIST_MEMBERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                userWatchlistMembersData: payload,
            };
        case User.GET_USER_WATCHLIST_MEMBERS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        case User.GET_USER_WATCHLIST_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.GET_USER_WATCHLIST_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                userWatchlistMediaData: payload,
            };
        case User.GET_USER_WATCHLIST_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,
            };
        default:
            return state;

    }
}

