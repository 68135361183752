import Header from 'components/Headers/Header';
import React from 'react';
import { Button, Card, Container } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { tncDetail, updateTncDetail, clearMessage } from "../../redux/actions/managePage.action"
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

function TermCondition() {
  const [tncText, setTncText] = React.useState('');
  const dispatch = useDispatch();

  const tncResult = useSelector((state) => state.managePage && state.managePage.tncResult)
  const status = useSelector((state) => state.managePage && state.managePage.status)
  const errMesg = useSelector((state) => state.managePage && state.managePage.errMesg)

  React.useEffect(() => {
    dispatch(tncDetail(1));
  }, [])

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  React.useEffect(() => {
    if (status && status != "") {
      toast.success((status), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (errMesg && errMesg != "") {
      toast.error((errMesg), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearMessage());
    }
  }, [status, errMesg]);

  React.useEffect(() => {
    if (tncResult && tncResult.data && tncResult.data.tnc && tncResult.data.tnc != "") {
      setTncText(tncResult.data.tnc);
    }
  }, [tncResult])

  const handleDataSave = () => {
    const body = {
      tnc: tncText
    }
    ReactGA.event({
      category: 'Manage Page',
      action: 'Terms and condition updated',
      value: '',
      label: ''
  });
    dispatch(updateTncDetail(1, body));
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <Container>
        <Card className='mt-4 p-3'>
          <h3 className='mb-3'>Terms & Conditions</h3>
          <div>
            <div className="App">
              <CKEditor
                editor={ClassicEditor}
                data={tncText}
                onReady={editor => {
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTncText(data);
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log('Blur.', editor);
                }}
                onFocus={(event, editor) => {
                  console.log('Focus.', editor);
                }}
              />
            </div>
            <div className='text-center mt-3'>
              <Button onClick={() => handleDataSave()} color='success'>Save</Button>
            </div>
          </div>
        </Card>
      </Container>
    </>
  )
}

export default TermCondition