import React from "react";
import Header from "components/Headers/Header.js";
import Timefilter from "components/Subheaders/Timefilter";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import {chartdata} from "variables/chartsData";
import {
  Row,
  Col,
  Container,
  CardHeader,
  CardBody,
  Card
} from "reactstrap";
import { getCombinedModifierFlags } from "typescript";


const Analytics = () => {
  return (
    <>
      <Header />
      <Timefilter />
      <div>
        <Container className="" fluid>
          <Row className="mb-5">
            <Col xl="8">
              <Card>
                <CardHeader>
                  <div>
                    <h2>OTT Platforms - Overall</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <Bar data={chartdata[0]} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
            
                <Card>
                  <CardHeader>
                    <div>
                      <h2>Media Type - Overall</h2>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Pie data={chartdata[2]} />
                  </CardBody>
                </Card>
             
                <Card>
                  <CardHeader>
                    <div>
                      <h2>Content Type - Overall</h2>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Pie data={chartdata[3]} />
                  </CardBody>
                </Card>
              

            </Col>

          </Row>
          <Row className="mb-5">
            <Col>
              <Card>
                <CardHeader>
                  <div >
                    <h2>Years - Media</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <Bar data={chartdata[1]}  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <div>
                    <h2>Generes - Overall</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <Doughnut data={chartdata[0]} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
            <Card>
                <CardHeader>
                  <div>
                    <h2>Languages - Overall</h2>
                  </div>
                </CardHeader>
                <CardBody>
                <Bar data={chartdata[0]}  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <Card>
                <CardHeader>
                  <div >
                    <h2>Tags - Media Count</h2>
                  </div>
                </CardHeader>
                <CardBody>
                  <Bar data={chartdata[1]}  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>


    </>
  );
};

export default Analytics;