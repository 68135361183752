import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";
import axios from "axios";
import store from "../../redux/store";

export const getPointsList = (value, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_POINTS_LIST_REQUEST });
        axiosInstance
            .get(`tracking/pointslist?search=${value}&pagelimit=${pagelimit}&page=${page}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_POINTS_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_POINTS_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const bulkFrenziPoints = (formData) => {
    const { auth } = store.getState();
    if (!auth.token) {
        return true;
    }
    return (dispatch) => {
        dispatch({ type: WEB.UPLOAD_FRENZI_POINTS_REQUEST });
        var config = {
            method: 'post',
            url: process.env.REACT_APP_BASE_URL+'subscription/bulkpoints',
            // url: process.env.REACT_APP_STAGING_BASE_URL+'subscription/bulkpoints',
            headers: {
                'Authorization': `Bearer ${auth.token}`,
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        };

        axios(config)
            .then(function (res) {
                console.log(JSON.stringify(res.data));
                const payload = res.data;
                    dispatch({
                        type: WEB.UPLOAD_FRENZI_POINTS_SUCCESS,
                        payload: payload,
                    })
            })
            .catch(function (err) {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPLOAD_FRENZI_POINTS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });

    }

}

export const frenziActivityList = () => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_FRENZI_ACTIVITY_LIST_REQUEST });
        axiosInstance
            .get(`native_main/frenzipoints`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_FRENZI_ACTIVITY_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_FRENZI_ACTIVITY_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getPointsListDetails = (id, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_POINTS_LIST_DETAILS_REQUEST });
        axiosInstance
            .get(`tracking/pointsdetail/${id}?pagelimit=${pagelimit}&page=${page}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_POINTS_LIST_DETAILS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_POINTS_LIST_DETAILS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateFrenziPoints = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_FRENZI_POINT_REQUEST });
        axiosInstance
            .put(`tracking/pointsdetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_FRENZI_POINT_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_FRENZI_POINT_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateActivityPoints = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_ACTIVITY_POINT_REQUEST });
        axiosInstance
            .put(`native_main/frenzipointsdetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_ACTIVITY_POINT_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_ACTIVITY_POINT_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const deleteFrenziPoints = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_FRENZI_POINT_REQUEST });
        axiosInstance
            .delete(`tracking/pointsdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_FRENZI_POINT_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_FRENZI_POINT_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.FRENZI_POINTS_PAGE_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.FRENZI_POINTS_PAGE_CLEAR_MESSAGE_SUCCESS });
        }
    }
}