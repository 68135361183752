import React from 'react'
import { Dropdown, CustomInput, DropdownToggle, DropdownMenu, DropdownItem, Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Card, FormGroup, Label, Input, Form, Col, Table, Row } from 'reactstrap';
import Header from "components/Headers/Header.js";
import MediaType from 'components/MediaType/MediaType';
import { useSelector, useDispatch } from 'react-redux'
import { addTextReview, addAudioVideoReview, getSpecificReview, deleteReview, clearMessage, frenziOfficalReviewList, updateReview } from "../../redux/actions/review.action"
import { getMediaTypeSearchList } from "../../redux/actions/search.action"
import AWS from 'aws-sdk'
import Loader from '../common/Loader'
import { ToastContainer, toast } from 'react-toastify';
import CustomPagination from 'components/Pagination/CustomPagination';
import helpers from '../../helpers/common';
import 'react-toastify/dist/ReactToastify.css';
import './_review.scss'
import ReactGA from 'react-ga';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})


function UploadReview() {
    const dispatch = useDispatch()

    const [isEdit, setIsEdit] = React.useState(false);
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isCurrentLoading, setIsCurrentLoading] = React.useState(false);
    const [textReview, setTextReview] = React.useState('');
    const [isOpenMediaTypeDropDown, setIsOpenMediaTypeDropDown] = React.useState(false);
    const [mediaTypeSearchText, setMediaTypeSearchText] = React.useState('');
    const [mediaTypeClickedItem, setMediaTypeClickedItem] = React.useState({});
    const [tmdbId, setTmdbId] = React.useState('');
    const [media, setMedia] = React.useState('');
    const [selectedFileType, setSelectedFileType] = React.useState('');
    const [commentOpen, setCommentOpen] = React.useState(false);
    const [commentType, setCommentType] = React.useState('');
    const [commentUrlType, setCommentUrlType] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [reason, setReason] = React.useState({});
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [specificReviewDetail, setSpecificReviewDetail] = React.useState({});
    const [viewModal, setViewModal] = React.useState(false);
    const [viewText, setViewText] = React.useState('');
    const [searchCommentType, setSearchCommentType] = React.useState(null)
    const [isReview, setIsReview] = React.useState(false)

    const inputFileRef = React.useRef(null);
    const wrapperRef = React.useRef(null);

    const textReviewResult = useSelector((state) => state.userReview && state.userReview.addTextReviewResult ? state.userReview.addTextReviewResult : {})

    const audioVideoReview = useSelector((state) => state.userReview && state.userReview.audioVideoReviewResult ? state.userReview.audioVideoReviewResult : {})

    const specificReviewDetailData = useSelector((state) => state.userReview && state.userReview.getSpecificReviewDetail ? state.userReview.getSpecificReviewDetail : {})

    const frenziOfficalReview = useSelector((state) => state.userReview && state.userReview.frenziOfficialReviewListResult ? state.userReview.frenziOfficialReviewListResult : {})

    const mediaTypeSearchResultsList = useSelector((state) => state.commonSearch && state.commonSearch.mediaTypeSearchResults ? state.commonSearch.mediaTypeSearchResults : {})

    const message = useSelector((state) => state.userReview && state.userReview.message ? state.userReview.message : '')
    const isLoading = useSelector((state) => state.userReview && state.userReview.isLoading ? state.userReview.isLoading : false)
    const errMesg = useSelector((state) => state.userReview && state.userReview.errMesg ? state.userReview.errMesg : false)
    const apiStatus = useSelector((state) => state.userReview && state.userReview.status ? state.userReview.status : false)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        setSpecificReviewDetail(specificReviewDetailData)
    }, [specificReviewDetailData])

    React.useEffect(() => {
        if (message && message != "") {
            if (apiStatus) {
                toast.success((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error((message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    React.useEffect(() => {
        setReason({});
        dispatch(frenziOfficalReviewList(searchText, searchCommentType, currentPage, pageLimit));
    }, [message, currentPage, pageLimit, searchCommentType])

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(frenziOfficalReviewList(searchText, searchCommentType, 1, pageLimit));
    }

    // React.useEffect(() => {
    //     var tempData = [];
    //     if (frenziOfficalReview && frenziOfficalReview.data && frenziOfficalReview.data.results && frenziOfficalReview.data.results.length > 0) {
    //         frenziOfficalReview.data.results.map(async (x, i) => {
    //             tempData.push({
    //                 ...x,
    //                 comment_url : await getPresignedUrl(x.comment_url)
    //             })
    //         })
    //     }

    //     var final = {
    //         data:{
    //             ...frenziOfficalReview.data,
    //             results : tempData
    //         }
    //     }
    //     console.log("frenziOfficalReview----", final);
    //     console.log("original data----", frenziOfficalReview);
    // }, [frenziOfficalReview])

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setTimeout(() => {
                    setIsOpenMediaTypeDropDown(false)
                }, 200)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);


    const handleAddReview = () => {
        if (mediaTypeClickedItem && !helpers.isEmptyObject(mediaTypeClickedItem)) {
            if (commentType != "") {
                if (commentType == "text") {
                    if (textReview != "") {
                        const body = {
                            "tmdb_id": mediaTypeClickedItem.tmdb_id ? mediaTypeClickedItem.tmdb_id : '',
                            "media_type": mediaTypeClickedItem.media_type ? mediaTypeClickedItem.media_type : '',
                            "comment_type": commentType,
                            "comment": textReview
                        }
                        eventReporting('Review', 'Text Review added  - ' + mediaTypeClickedItem.tmdb_id, textReview);
                        dispatch(addTextReview(body));
                    } else {
                        toast.error('The comment can not be blank.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return true;
                    }
                } else {
                    if (selectedFileType != "") {
                        if (selectedFileType == commentType) {
                            const body = {
                                "tmdb_id": mediaTypeClickedItem.tmdb_id ? mediaTypeClickedItem.tmdb_id : '',
                                "media_type": mediaTypeClickedItem.media_type ? mediaTypeClickedItem.media_type : '',
                                "comment_type": commentType,
                                "comment_url": commentUrlType,
                                "duration": duration,
                            }
                            eventReporting('Review', 'Audio/Video Review added  - ' + mediaTypeClickedItem.tmdb_id, commentUrlType);
                            dispatch(addAudioVideoReview(body));
                        } else {
                            toast.error('Comment type and selected file type mismatched.', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            return true;
                        }
                    } else {
                        toast.error('Please select any audio/video file.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return true;
                    }
                }
                if (!isLoading) {
                    setIsAddPlatform(!isAddPlatform);
                    clearState();
                }
            } else {
                toast.error('Please select comment type.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return true;
            }
        } else {
            var msg = "Please select a Movie / WebSeries from media search."
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleUpdateReview = () => {
        if (specificReviewDetail && specificReviewDetail.data) {
            eventReporting('Review', 'Review updated  - ', specificReviewDetail.data.uuid);
            dispatch(updateReview(specificReviewDetail.data.uuid, specificReviewDetail.data));
            setIsEdit(!isEdit);
        }
    }

    const clearState = () => {
        setMediaTypeSearchText('');
        setMediaTypeClickedItem({});
        setTmdbId('');
        setMedia('');
        setSelectedFileType('');
        setCommentType('');
        setCommentUrlType('');
        setDuration('');
        setReason({});
    }

    const handleMediaTypeSearch = (e) => {
        setMediaTypeSearchText(e.target.value);
        dispatch(getMediaTypeSearchList(e.target.value))
        if (e.target.value != "") {
            setIsOpenMediaTypeDropDown(true)
        } else {
            setIsOpenMediaTypeDropDown(false)
        }
    }

    const handleMediaTypeClick = (object, isEdit = false) => {
        if (!isEdit) {
            setMediaTypeClickedItem(object);
            setIsOpenMediaTypeDropDown(false);
        } else {
            handleEditFormDetails(object.poster ? object.poster : '', 'poster')
            setTimeout(() => {
                handleEditFormDetails(object.title ? object.title : '', 'title')
                setTimeout(() => {
                    handleEditFormDetails(object.media ? object.media : '', 'media')
                }, 200);
            }, 200);
            handleEditFormDetails(object.tmdb_id ? object.tmdb_id : '', 'tmdb_id')
            setIsOpenMediaTypeDropDown(false);
        }
    }

    function getDuration(file, type = 'video') {
        window.URL = window.URL || window.webkitURL;
        var video = document.createElement(type);
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            setDuration(getMinutes(video.duration));
        }
        video.src = URL.createObjectURL(file);
    }

    const getMinutes = (seconds) => {
        var tempMin = seconds / 60;
        var min = parseInt(tempMin);
        var sec = parseInt(tempMin.toString().split(".")[1]) * 60;
        sec = sec.toString().split('')[0] + sec.toString().split('')[1];
        return min + ":" + sec;
    }

    const handleFileChange = async (e, isEdit = false) => {
        if (e.target && e.target.files) {
            var file = e.target.files[0];
            var type = "";
            if (file.type.includes("video")) {
                type = "video";
                getDuration(file, 'video');
                setSelectedFileType('video')
            } else if (file.type.includes("audio")) {
                type = "audio";
                getDuration(file, 'audio');
                setSelectedFileType('audio')
            } else {
                toast.error('Please select audio/video file.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return true;
            }
            setIsCurrentLoading(true);
            var timeStamp = new Date().getTime();
            var randomNo = parseInt(Math.random() * 100000);
            if (type === "video") {
                var key = 'Video/';
            } else if (type === "audio") {
                var key = 'Audio/';
            } else {
                toast.error('Please select audio/video file.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return true;
            }
            var keyName = key + timeStamp + "-" + randomNo + "-" + file.name;

            const s3 = new AWS.S3({
                params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME },
                region: process.env.REACT_APP_AWS_REGION,
            })
            const params = {
                Key: keyName,
                Body: file,
            }
            s3.upload(params, async function (err, data) {
                if (err) {
                    throw err;
                }
                if (data && data.Location) {
                    var customPath = "https://s3.ap-south-1.amazonaws.com/" + process.env.REACT_APP_S3_BUCKET_NAME + "/" + keyName + "?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211029T081337Z&X-Amz-SignedHeaders=host&X-Amz-Expires=899&X-Amz-Credential=AKIASQRFSGGNKQCYZHUO%2F20211029%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=fe1abe7e3a83577cde085bdb1681b9f92b0d2789165b7d81f2e569287ba1076b"
                    if (isEdit) {
                        handleEditFormDetails(customPath, 'comment_url')
                    } else {
                        setCommentUrlType(customPath);
                    }
                    setIsCurrentLoading(false);
                    console.log("oo=", data)
                }
            });
        }
    }

    const handleReviewDelete = (id, index) => {
        if (!helpers.isEmptyObject(reason) && reason[index] != "" && reason[index] != undefined) {
            eventReporting('Review', 'Review deleted  - ', id);
            dispatch(deleteReview(id, reason[index]))
        } else {
            toast.error('Please enter a reason.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleEditFormDetails = (val, name) => {
        var newData = {
            data: {
                ...specificReviewDetail.data,
                [name]: val
            }
        }
        setSpecificReviewDetail(newData);
    }

    const handleReason = (val, id) => {
        var tempVal = reason;
        tempVal = {
            ...reason,
            [id]: val
        }
        setReason(tempVal)
    }

    const handleEdit = (id) => {
        dispatch(getSpecificReview(id));
        if (!isLoading) {
            setIsEdit(true);
        }
    }

    return (
        <>
            <Header />
            <Container>
                <Card style={{ padding: '20px', marginTop: "15px" }}>
                    {isLoading &&
                        <Loader />
                    }
                    {isCurrentLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div style={{}}>
                        <div>
                            <h3>
                                Reviews List
                            </h3>
                        </div>
                        <Row className='p-0 m-0'>
                            <Col sm='4' className='pb-3'>
                                <Row>
                                    <Col sm={8} className="p-0 search-icon-box">
                                        <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search here.." onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                                        <i className="fas fa-search" onClick={() => handleSearch()}></i>
                                    </Col>
                                    <Col sm={4}>
                                        <Dropdown className='w-100' isOpen={isReview} toggle={() => setIsReview(!isReview)}>
                                            <DropdownToggle className='w-100' caret>
                                                {searchCommentType ? searchCommentType : 'All'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                <DropdownItem onClick={() => setSearchCommentType(null)}>All</DropdownItem>
                                                <DropdownItem onClick={() => setSearchCommentType('text')}>Text</DropdownItem>
                                                <DropdownItem onClick={() => setSearchCommentType('audio')}>Audio</DropdownItem>
                                                <DropdownItem onClick={() => setSearchCommentType('video')}>Video</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='m-0 p-0'>
                                <div className='text-right'>
                                    <Button color="info" onClick={() => {
                                        setIsAddPlatform(!isAddPlatform);
                                        ReactGA.modalview(window.location.pathname + "?modal=AddReview");
                                    }}>Add Review</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Container className='table'>
                                <Table hover className="align-items-center table-flush">
                                    <thead>
                                        <tr>
                                            <th className='table_heading'>
                                                S.No.
                                            </th>
                                            <th className='table_heading'>
                                                Date & Time
                                            </th>
                                            <th className='table_heading'>
                                                Media Title
                                            </th>
                                            <th className='table_heading'>Comment Type</th>
                                            {/* <th className='table_heading'>
                                                Username
                                            </th> */}
                                            <th className='table_heading'>
                                                Review Comment
                                            </th>
                                            <th className='table_heading text-center'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {frenziOfficalReview && frenziOfficalReview.data && frenziOfficalReview.data.results && frenziOfficalReview.data.results.length > 0 ? frenziOfficalReview.data.results.map((x, i) => {
                                            return <tr>
                                                <td>1</td>
                                                <td>
                                                    {x.created_at}
                                                </td>
                                                <td>
                                                    {x.title}
                                                </td>
                                                <td>
                                                    {x.comment_type}
                                                </td>
                                                {/* <td>
                                                    Rakshitha Reddy
                                                </td> */}
                                                <td>
                                                    {x.comment_type == 'text' ?
                                                        x.comment.length > 50 ? <span className='pointer' onClick={() => {
                                                            setViewModal(true);
                                                            setViewText(x.comment);
                                                        }}>{x.comment.substring(0, 50)} ...</span> : x.comment
                                                        :
                                                        <MediaType type={x.comment_type} src={x.comment_url} />
                                                    }
                                                </td>
                                                <td>
                                                    <div style={{ minWidth: '200px' }}>
                                                        <Col>
                                                            <div>
                                                                <Input
                                                                    value={!helpers.isEmptyObject(reason) && reason[i + 1] ? reason[i + 1] : ''}
                                                                    placeholder='Enter Reason'
                                                                    onChange={(e) => handleReason(e.target.value, i + 1)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col style={{ minWidth: '50px' }}>
                                                            <Row className='justify-content-center mt-2'>
                                                                <Button onClick={() => handleEdit(x.uuid)} size="sm" color="info" >Edit</Button>
                                                                <Button onClick={() => handleReviewDelete(x.uuid, i + 1)} size="sm" color="danger" >Delete</Button>
                                                            </Row>
                                                        </Col>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                            :
                                            <tr>
                                                <td colSpan={9} className='text-center'>
                                                    <h3>
                                                        No Data Available
                                                    </h3>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                {frenziOfficalReview.data && frenziOfficalReview.data.pagination &&
                                    <CustomPagination
                                        pagination={frenziOfficalReview.data && frenziOfficalReview.data.pagination && frenziOfficalReview.data.pagination ? frenziOfficalReview.data.pagination : {}}
                                        setCurrentPage={(val) => setCurrentPage(val)}
                                        setPageLimit={(val) => setPageLimit(val)}
                                        defaultPageList={frenziOfficalReview.data && frenziOfficalReview.data.results ? frenziOfficalReview.data.results.length : 20}
                                    />
                                }
                            </Container>
                        </Row>
                    </div>
                </Card>
                <Modal isOpen={isAddPlatform}
                    toggle={() => setIsAddPlatform(!isAddPlatform)}>
                    <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>Add/Update Review</ModalHeader>
                    <div className='modal_body'>
                        <div>
                            <Form>
                                <FormGroup>
                                    <Col sm={12} className="p-0 search-icon-box">
                                        <Input value={mediaTypeSearchText} placeholder="Search Movie / WebSeries" onChange={(e) => handleMediaTypeSearch(e)} />
                                        <i className="fas fa-search"></i>
                                    </Col>
                                    {isOpenMediaTypeDropDown &&
                                        <div className='relative'>
                                            <div className='media_type_list_box'>
                                                {mediaTypeSearchResultsList && mediaTypeSearchResultsList.data && mediaTypeSearchResultsList.data.results && mediaTypeSearchResultsList.data.results.length > 0 && mediaTypeSearchResultsList.data.results.map((x, i) => {
                                                    return <div ref={wrapperRef} className='media_type_list' onClick={() => handleMediaTypeClick(x)}>
                                                        <Row className='m-0'>
                                                            <Col>
                                                                <img alt={x.media_type ? x.media_type : 'N/A'} style={{ width: 100, height: 100 }} src={x.poster ? x.poster : ''} />
                                                            </Col>
                                                            <Col>
                                                                <p className='p-0 m-0'>{x.title ? x.title : ''}</p>
                                                                <p style={{ fontSize: 12 }} className='p-0 m-0'>{x.media_type ? x.media_type : ''}</p>
                                                                {/* <p style={{fontSize:12}} className='p-0 m-0'>{x.language ? 'Language: '+x.language : ''}</p> */}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                    {mediaTypeClickedItem && !helpers.isEmptyObject(mediaTypeClickedItem) &&
                                        <Col className='mt-2 bg-success p-2'>
                                            <Row className='m-0'>
                                                <Col>
                                                    <img alt={mediaTypeClickedItem.media_type ? mediaTypeClickedItem.media_type : 'N/A'} style={{ width: 50, height: 50 }} src={mediaTypeClickedItem.poster ? mediaTypeClickedItem.poster : ''} />
                                                </Col>
                                                <Col>
                                                    <p className='p-0 m-0'>{mediaTypeClickedItem.title ? mediaTypeClickedItem.title : ''}</p>
                                                    <p style={{ fontSize: 12 }} className='p-0 m-0'>{mediaTypeClickedItem.media_type ? mediaTypeClickedItem.media_type : ''}</p>
                                                    {/* <p style={{fontSize:12}} className='p-0 m-0'>{x.language ? 'Language: '+x.language : ''}</p> */}
                                                </Col>
                                                <Col className='text-right'>
                                                    <i onClick={() =>
                                                        setMediaTypeClickedItem({})
                                                    } style={{ fontSize: 20 }} className="fas p-2 mt-2 pointer fa-window-close text-danger"></i>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </FormGroup>
                            </Form>
                        </div>
                        <div className='mb-4'>
                            <Dropdown isOpen={commentOpen} toggle={() => setCommentOpen(!commentOpen)} >
                                <DropdownToggle caret>
                                    {commentType && commentType != "" ? commentType.charAt(0).toUpperCase() + commentType.slice(1) : 'Select Comment'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => setCommentType('text')} >Text</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('audio')} >Audio</DropdownItem>
                                    <DropdownItem onClick={() => setCommentType('video')} >Video</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div>
                            <FormGroup>
                                <Input value={textReview} onChange={(e) => setTextReview(e.target.value)} type="textarea" name="text" id="exampleText" placeholder="Enter comment" />
                            </FormGroup>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <h3>OR</h3>
                        </div>
                        <div className='mt-3'>
                            <FormGroup>
                                <CustomInput ref={inputFileRef} onChange={(e) => handleFileChange(e)} type="file" id="exampleCustomFileBrowser" name="customFile" />
                            </FormGroup>
                        </div>
                        <div className='text-right '>
                            <CustomInput
                                type="switch"
                                id={`exampleCustomSwitch`}
                                name="customSwitch"
                                className=''
                            // onChange={(e) => console.log(e.target.checked) }
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <Button float color="primary" onClick={() => handleAddReview()}
                        >Save</Button>{' '}
                    </div>
                </Modal>

                <Modal isOpen={isEdit}
                    toggle={() => setIsEdit(!isEdit)}>
                    <ModalHeader toggle={() => setIsEdit(!isEdit)}>Add/Update Review</ModalHeader>
                    <div className='modal_body'>
                        <div>
                            <Form>
                                <FormGroup>
                                    <Col sm={12} className="p-0 search-icon-box">
                                        <Input value={mediaTypeSearchText} placeholder="Search Movie / WebSeries" onChange={(e) => handleMediaTypeSearch(e)} />
                                        <i className="fas fa-search"></i>
                                    </Col>
                                    {isOpenMediaTypeDropDown &&
                                        <div className='relative'>
                                            <div className='media_type_list_box'>
                                                {mediaTypeSearchResultsList && mediaTypeSearchResultsList.data && mediaTypeSearchResultsList.data.results && mediaTypeSearchResultsList.data.results.length > 0 && mediaTypeSearchResultsList.data.results.map((x, i) => {
                                                    return <div ref={wrapperRef} className='media_type_list' onClick={() => handleMediaTypeClick(x, true)}>
                                                        <Row className='m-0'>
                                                            <Col>
                                                                <img alt={x.media_type ? x.media_type : 'N/A'} style={{ width: 100, height: 100 }} src={x.poster ? x.poster : ''} />
                                                            </Col>
                                                            <Col>
                                                                <p className='p-0 m-0'>{x.title ? x.title : ''}</p>
                                                                <p style={{ fontSize: 12 }} className='p-0 m-0'>{x.media_type ? x.media_type : ''}</p>
                                                                {/* <p style={{fontSize:12}} className='p-0 m-0'>{x.language ? 'Language: '+x.language : ''}</p> */}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                    {specificReviewDetail && specificReviewDetail.data && specificReviewDetail.data.title &&
                                        <Col className='mt-2 bg-success p-2'>
                                            <Row className='m-0'>
                                                <Col>
                                                    <img alt={specificReviewDetail && specificReviewDetail.data.comment_type ? specificReviewDetail.data.comment_type : 'N/A'} style={{ width: 50, height: 50 }} src={specificReviewDetail && specificReviewDetail.data.poster ? specificReviewDetail.data.poster : ''} />
                                                </Col>
                                                <Col>
                                                    <p className='p-0 m-0'>{specificReviewDetail && specificReviewDetail.data.title ? specificReviewDetail && specificReviewDetail.data.title : ''}</p>
                                                </Col>
                                                <Col className='text-right'>
                                                    <i onClick={() => {
                                                        handleEditFormDetails('', 'title');
                                                        handleEditFormDetails('', 'poster');
                                                    }} style={{ fontSize: 20 }} className="fas p-2 mt-2 pointer fa-window-close text-danger"></i>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </FormGroup>
                            </Form>
                        </div>
                        <div className='mb-4'>
                            <Dropdown isOpen={commentOpen} toggle={() => setCommentOpen(!commentOpen)} >
                                <DropdownToggle caret>
                                    {specificReviewDetail && specificReviewDetail.data && specificReviewDetail.data.comment_type != "" ? specificReviewDetail.data.comment_type.charAt(0).toUpperCase() + specificReviewDetail.data.comment_type.slice(1) : 'Select Comment'}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleEditFormDetails('text', 'comment_type')} >Text</DropdownItem>
                                    <DropdownItem onClick={() => handleEditFormDetails('audio', 'comment_type')} >Audio</DropdownItem>
                                    <DropdownItem onClick={() => handleEditFormDetails('video', 'comment_type')} >Video</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div>
                            <FormGroup>
                                <Input value={specificReviewDetail && specificReviewDetail.data && specificReviewDetail.data.comment != "" ? specificReviewDetail.data.comment : ''} onChange={(e) => handleEditFormDetails(e.target.value, 'comment')} type="textarea" name="text" id="exampleText" placeholder="Enter comment" />
                            </FormGroup>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <h3>OR</h3>
                        </div>
                        <div className='mt-3'>
                            <FormGroup>
                                <CustomInput ref={inputFileRef} onChange={(e) => handleFileChange(e, true)} type="file" id="exampleCustomFileBrowser" name="customFile" />
                            </FormGroup>
                            {specificReviewDetail && specificReviewDetail.data && specificReviewDetail.data.comment_url != "" &&
                                <MediaType type={specificReviewDetail.data.comment_type} src={specificReviewDetail.data.comment_url} />
                            }
                        </div>
                        <div className='text-right '>
                            <CustomInput
                                type="switch"
                                id={`exampleCustomSwitch`}
                                name="customSwitch"
                                className=''
                            // onChange={(e) => console.log(e.target.checked) }
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                        <Button float color="primary" onClick={() => handleUpdateReview()}
                        >Save</Button>{' '}
                    </div>
                </Modal>
                <Modal
                    isOpen={viewModal}
                    toggle={() => setViewModal(!viewModal)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModal(!viewModal)}>
                        View Comment
                    </ModalHeader>
                    <ModalBody className='pt-0'>
                        {viewText}
                    </ModalBody>
                </Modal>
            </Container>
        </>
    )
}

export default UploadReview