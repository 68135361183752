import { User } from "../constants/constants";

const initialState = {
  isLoading: false,
  isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
  token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
  user: {},
  errMess: "",
  status: "",
  req: {},
  userData: {},
};

export const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case User.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case User.LOGIN_SUCCESS:
      if (payload && payload.data && payload.data.admin_permissions) {
        setCookie('frenzi_admin_permissions', payload.data.admin_permissions, 2)
      }
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMess: "",
        status: "",
        token: payload.token,
        userData: payload && payload.data ? payload.data : state.userData,
      };
    case User.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMess: payload.error,
      };
    case User.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        errMess: "",
        status: "",
      };
    case User.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        status: 'Logout Success',
        token: '',
        userData: {}
      };
    case User.AUTH_CLEAR_MESSAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        errMess: "",
        status: "",
      };
    case User.AUTH_CLEAR_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errMess: '',
        status: "",
      };
    case User.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case User.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMess: "",
        status: payload.message ? payload.message : 'Reset email sent',
      };
    case User.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMess: payload.error,
      };
    case User.OTP_VALIDATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case User.OTP_VALIDATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMess: "",
        status: payload.message ? payload.message : '',
      };
    case User.OTP_VALIDATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMess: payload.error,
      };
    case User.PASSWORD_MATCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case User.PASSWORD_MATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        errMess: "",
        status: payload.message ? payload.message : '',
      };
    case User.PASSWORD_MATCH_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        errMess: payload.error,
      };
    default:
      return state;
  }
};


const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}