import React from 'react'
import Header from "components/Headers/Header.js";
import { Modal, ModalHeader, ModalBody, Container, Card, Row, Col, Table, Button, Input, Nav, NavLink, Spinner, TabContent, NavItem, TabPane } from 'reactstrap';
import CustomPagination from 'components/Pagination/CustomPagination';
import { getReportedUsers, clearMessage, deleteReport, activateDeactivateUsers, getInactiveUsers } from "../../redux/actions/user.action"
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import common from '../../helpers/common'
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

const list = [1, 2, 3, 4, 5, 6];

export default function ReportedUsers() {
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [searchString, setSearchString] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [inactiveSearchString, setInactiveSearchString] = React.useState('');
    const [inactiveCurrentPage, setInactiveCurrentPage] = React.useState(1);
    const [inactivePageLimit, setInactivePageLimit] = React.useState(20);
    const [currentMessage, setCurrentMessage] = React.useState('');
    const [userDetails, setUserDetails] = React.useState({});
    const [activeTabPane, setActiveTabPane] = React.useState('reported');

    const dispatch = useDispatch();

    const reportedUsers = useSelector((state) => state.user && state.user.reportedUsers ? state.user.reportedUsers : {})
    const inactiveUserList = useSelector((state) => state.user && state.user.inactiveUserList ? state.user.inactiveUserList : {})
    const errMesg = useSelector((state) => state.user && state.user.errMesg ? state.user.errMesg : '')
    const status = useSelector((state) => state.user && state.user.status ? state.user.status : '')
    const isLoading = useSelector((state) => state.user && state.user.isLoading ? state.user.isLoading : false)

    React.useEffect(() => {
        var data = localStorage.getItem("frenzi_admin_access");
        if (data) {
            var userData = JSON.parse(data);
            setUserDetails(userData);
        }
    }, [])

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleActiveInactive = (id, val) => {
        var body = {
            is_active: val
        }
        eventReporting('User', 'User status changed to  - ' + val, id);
        dispatch(activateDeactivateUsers(id, body));
    }

    React.useEffect(() => {
        dispatch(getReportedUsers(searchString, currentPage, pageLimit));
    }, [currentPage, pageLimit])

    const handleSearch = () => {
        dispatch(getReportedUsers(searchString, 1, pageLimit));
        setCurrentPage(1);
    }

    React.useEffect(() => {
        dispatch(getInactiveUsers(inactiveSearchString, inactiveCurrentPage, inactivePageLimit));
    }, [inactiveCurrentPage, inactivePageLimit])

    const handleSearchInactive = () => {
        dispatch(getInactiveUsers(inactiveSearchString, 1, inactivePageLimit));
        setInactiveCurrentPage(1);
    }

    React.useEffect(() => {
        if (status && status != "") {
            toastSuccess(status);
            dispatch(clearMessage());
            dispatch(getReportedUsers(searchString, currentPage, pageLimit));
            dispatch(getInactiveUsers(inactiveSearchString, inactiveCurrentPage, inactivePageLimit));
        }

        if (errMesg && errMesg != "") {
            toastError(errMesg);
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const toastError = (mesg) => {
        toast.error((mesg), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const toastSuccess = (mesg) => {
        toast.success((mesg), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const handleDeleteReport = (id) => {
        if (window.confirm("Are you sure want to delete this report ?")) {
            dispatch(deleteReport(id));
        }
    }

    const reportedBystyle = { fontWeight: 'bold', color: 'green' }
    const reportedTostyle = { fontWeight: 'bold', color: 'red' }


    return (
        <>
            <Header />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isLoading && <Loader />}
            <Container>
                <Card className='mt-4 p-4 rounded-lg'>
                    <Row className='sub_heading'>
                        <Col>
                            <h3 className='mb-3'>Reported User</h3>
                        </Col>
                    </Row>
                    <Row className='justify-content-between m-0 '>
                        {activeTabPane == "reported" ?
                            <Col xs={12} sm={6} md={4} className="p-0 mt-4 search-icon-box">
                                <Input onChange={(e) => setSearchString(e.target.value)} placeholder='Search' value={searchString} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                                <i className="fas fa-search" onClick={() => handleSearch()}></i>
                            </Col>
                            :
                            <Col xs={12} sm={6} md={4} className="p-0 mt-4 search-icon-box">
                                <Input onChange={(e) => setInactiveSearchString(e.target.value)} placeholder='Search' value={inactiveSearchString} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearchInactive() } }} />
                                <i className="fas fa-search" onClick={() => handleSearchInactive()}></i>
                            </Col>
                        }
                    </Row>
                    <div className='mt-3'>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "reported" ? 'active pointer' : 'pointer'}
                                    onClick={() => {
                                        setActiveTabPane('reported');
                                    }}
                                >
                                    Reported Users
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={activeTabPane == "inactive" ? 'active pointer' : 'pointer'}
                                    onClick={() => {
                                        setActiveTabPane('inactive');
                                    }}
                                >
                                    Deactivated Users
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTabPane}>
                            <TabPane tabId="reported">
                                <Container className='table mt-2' style={{ overflowY: 'hidden' }}>
                                    <Table hover className='align-items-center fixed-table table-flush' >
                                        <thead>
                                            <tr>
                                                <th rowspan="2" className='table_heading' width="7%">
                                                    S.No
                                                </th>
                                                <th colspan="2" className='table_heading' width="35%" >
                                                    Report By
                                                </th>
                                                <th colspan="2" className='table_heading' width="35%">
                                                    Report To
                                                </th>
                                                <th rowspan="2" className='table_heading'>
                                                    Reason
                                                </th>
                                                <th rowspan="2" className='table_heading'>
                                                    Action
                                                </th>
                                                {/* <th className='table_heading'>
                                                    Action
                                                </th> */}
                                            </tr>
                                            <tr>
                                                <th style={reportedBystyle}>
                                                    Name
                                                </th>
                                                <th style={reportedBystyle}  >
                                                    E-mail
                                                </th>
                                                <th style={reportedTostyle}>
                                                    Name
                                                </th>
                                                <th style={reportedTostyle} >
                                                    E-mail
                                                </th>
                                            </tr>

                                        </thead>
                                        <tbody className='vertical-middle'>
                                            {reportedUsers && reportedUsers.data && reportedUsers.data.results && reportedUsers.data.results.length > 0 && reportedUsers.data.results.map((x, i) => {
                                                return <tr>
                                                    <td scope="row">
                                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.source_user__username ? x.source_user__username : <h3>Admin</h3>}
                                                    </td>
                                                    <td>{x.source_user__email ? x.source_user__email : "Not Avialable"}</td>
                                                    <td>
                                                        {x.target_user__username ? x.target_user__username : ''}
                                                    </td>
                                                    <td>{x.target_user__email ? x.target_user__email : 'Not Available'}</td>
                                                    <td
                                                        onClick={() => {
                                                            if (x.report_reason && x.report_reason != '' && x.report_reason != null && x.report_reason.length > 20) {
                                                                setIsAddPlatform(!isAddPlatform);
                                                                setCurrentMessage(x.report_reason);
                                                                ReactGA.modalview(window.location.pathname + '?modal=Reason view');
                                                            }
                                                        }}
                                                        className="pointer">
                                                        {x.report_reason ? x.report_reason.length > 20 ? x.report_reason.substring(0, 20) + " ..." : x.report_reason : ''}
                                                    </td>
                                                    <td>
                                                        {x.target_user__is_active ?
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="danger"
                                                                size="sm"
                                                                type="button"
                                                                disabled={x.source_user__uuid == userDetails.uuid}
                                                                onClick={(e) => handleActiveInactive(x.target_user__uuid, !x.target_user__is_active)}
                                                            >
                                                                Deactivate
                                                            </Button>
                                                            :
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="success"
                                                                size="sm"
                                                                type="button"
                                                                disabled={x.source_user__uuid == userDetails.uuid}
                                                                onClick={(e) => handleActiveInactive(x.target_user__uuid, !x.target_user__is_active)}
                                                            >
                                                                Activate
                                                            </Button>
                                                        }
                                                    </td>
                                                    {/* <td>
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="info"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => {
                                                                if (x.report_reason && x.report_reason != '' && x.report_reason != null) {
                                                                    setIsAddPlatform(!isAddPlatform);
                                                                    setCurrentMessage(x.report_reason);
                                                                } else {
                                                                    toastError("Nothing to show");
                                                                }
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="danger"
                                                            size="sm"
                                                            type="button"
                                                            onClick={() => handleDeleteReport(x.uuid)}
                                                            disabled={x.source_user__uuid == userDetails.uuid}
                                                        >
                                                            <i className="fas fa-trash pt-1"></i>
                                                        </Button>
                                                    </td> */}
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {reportedUsers.data && reportedUsers.data.pagination &&
                                            <CustomPagination
                                                pagination={reportedUsers.data && reportedUsers.data.pagination && reportedUsers.data.pagination ? reportedUsers.data.pagination : {}}
                                                setCurrentPage={(val) => setCurrentPage(val)}
                                                setPageLimit={(val) => setPageLimit(val)}
                                                defaultPageList={reportedUsers.data && reportedUsers.data.results ? reportedUsers.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </Container>
                            </TabPane>
                            <TabPane tabId="inactive">
                                <Container className='table mt-2' style={{ overflowY: 'hidden' }}>
                                    <Table hover className='align-items-center table-flush' >
                                        <thead>
                                            <tr>
                                                <th className='table_heading' width="7%">
                                                    S. No.
                                                </th>
                                                <th className='table_heading'>
                                                    Name
                                                </th>
                                                <th className='table_heading' width="20%">
                                                    Profile Image
                                                </th>
                                                <th className='table_heading'>
                                                    Email
                                                </th>
                                                <th className='table_heading'>
                                                    Role
                                                </th>
                                                <th className='table_heading'>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='vertical-middle'>
                                            {inactiveUserList && inactiveUserList.data && inactiveUserList.data.results && inactiveUserList.data.results.length > 0 && inactiveUserList.data.results.map((x, i) => {
                                                return <tr>
                                                    <td scope="row">
                                                        {(inactivePageLimit * (inactiveCurrentPage - 1)) + (i + 1)}
                                                    </td>
                                                    <td>
                                                        {x.username ? x.username : ''}
                                                    </td>
                                                    <td>
                                                        <img style={{ width: 40, height: 40, borderRadius: 3 }} src={x.profile_image ? x.profile_image : ''} />
                                                    </td>
                                                    <td>
                                                        {x.email ? x.email : ''}
                                                    </td>
                                                    <td>
                                                        {x.profile_role ? x.profile_role : ''}
                                                    </td>
                                                    <td>
                                                        {x.is_active ?
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="danger"
                                                                size="sm"
                                                                type="button"
                                                                disabled={x.source_user__uuid == userDetails.uuid}
                                                                onClick={(e) => handleActiveInactive(x.uuid, !x.is_active)}
                                                            >
                                                                Deactivate
                                                            </Button>
                                                            :
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="success"
                                                                size="sm"
                                                                type="button"
                                                                disabled={x.source_user__uuid == userDetails.uuid}
                                                                onClick={(e) => handleActiveInactive(x.uuid, !x.is_active)}
                                                            >
                                                                Activate
                                                            </Button>
                                                        }
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    <div className='text-right mt-2'>
                                        {inactiveUserList.data && inactiveUserList.data.pagination &&
                                            <CustomPagination
                                                pagination={inactiveUserList.data && inactiveUserList.data.pagination && inactiveUserList.data.pagination ? inactiveUserList.data.pagination : {}}
                                                setCurrentPage={(val) => setInactiveCurrentPage(val)}
                                                setPageLimit={(val) => setInactivePageLimit(val)}
                                                defaultPageList={inactiveUserList.data && inactiveUserList.data.results ? inactiveUserList.data.results.length : 20}
                                            />
                                        }
                                    </div>
                                </Container>
                            </TabPane>
                        </TabContent>
                    </div>




                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            <h3>Reason</h3>
                        </ModalHeader>
                        <ModalBody className='pt-0'>
                            <Row className='pl-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>{currentMessage}</p>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
