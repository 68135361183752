const chartdata=[
  //donut chart data
  {
    labels: ["Comedy", "Horror", "SciFi", "Drama"],
    datasets: [{
      data: [12, 19, 3, 5],
      backgroundColor: [
        'rgb(3, 4, 94)',
        'rgb(0, 180, 216)',
        'rgb(144, 224, 239)',
        'rgb(202, 240, 248)'
      ]
    }]
  },
  //hbar chart data
  {

    labels: ["Amazon", "Netflix", "BigFlix", "HBO", "Netflix", "BigFlix", "HBO"],
    datasets: [
      {
        axis: 'y',
        label: 'OTT platforms',
        data: [12, 34, 22, 56,12, 34, 22, 56],
        backgroundColor: [
          'rgb(3, 4, 94)',
          'rgb(0, 180, 216)',
          'rgb(144, 224, 239)',
          'rgb(202, 240, 248)',
          'rgb(3, 4, 94)',
          'rgb(0, 180, 216)',
          'rgb(144, 224, 239)',
          'rgb(202, 240, 248)',
         
        ]
      },
    ],
  },
  //pie chart data 1
  {
    labels: ['Web Series', 'Movies'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [34, 66],
        backgroundColor: [
          'rgb(3, 4, 94)',
          'rgb(0, 180, 216)'

        ]
      }
    ]
  },
    //pie chart data 1
  {
    labels: ['Free', 'Paid'],
    datasets: [
      {
        label: 'Dataset 1',
        data: [66, 34],
        backgroundColor: [
          'rgb(3, 4, 94)',
          'rgb(0, 180, 216)'

        ]
      }
    ]
  }

]




  export {
    chartdata
  };
 