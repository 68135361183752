import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    subscriptionCategoryListResult: {},
    errMess: "",
    status: "",
    message: '',
    req: {},
    subscriptionRateListResult: {},

};

export const subscriptionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.SUBSCRIPTION_CATEGORY_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SUBSCRIPTION_CATEGORY_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                subscriptionCategoryListResult: payload,
            };
        case WEB.SUBSCRIPTION_CATEGORY_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,

            };

        case WEB.GET_SUBSCRIPTION_RATE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_SUBSCRIPTION_RATE_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                subscriptionRateListResult: payload
            };
        case WEB.GET_SUBSCRIPTION_RATE_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,

            };
        case WEB.POST_ADD_SUBSCRIPTION_RATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.POST_ADD_SUBSCRIPTION_RATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Rate updated',
            };
        case WEB.POST_ADD_SUBSCRIPTION_RATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_SUBSCRIPTION_RATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_SUBSCRIPTION_RATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Rate Updated',
            };
        case WEB.UPDATE_SUBSCRIPTION_RATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.DELETE_SUBSCRIPTION_RATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_SUBSCRIPTION_RATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Rate Deleted',
            };
        case WEB.DELETE_SUBSCRIPTION_RATE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.SUBSCRIPTTION_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SUBSCRIPTTION_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
            };
        default:
            return state;

    }
}