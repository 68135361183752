import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, CustomInput, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from 'reactstrap';
import { getBlogDetails } from 'redux/actions/blogs.action';
import Select, { components } from 'react-select';
import { getSelectOptions } from './BlogContent';
import { WithContext as ReactTags } from 'react-tag-input';
import { getBlogLanguage } from 'redux/actions/blogs.action';
import { categorySearchList } from 'redux/actions/categories.action';
import { getWatchList } from 'redux/actions/watchlist.action';
import MyCkEditor from './MyCkEditor';
import AWS from 'aws-sdk'
import { updateBlogData } from 'redux/actions/blogs.action';
import { getBlogSubCategoryData } from 'redux/actions/blogs.action';
import { getBlogCategoryData } from 'redux/actions/blogs.action';
// import MyEditor from './MyEditor';
import FAQ from './FAQ';
import RelatedBlogs from './RelatedBlogs';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function EditBlog(props) {
    const { editId } = props;

    const dispatch = useDispatch();
    const [currentFormData, setCurrentFormData] = useState({});
    const [toggleFilter, setToggleFilter] = useState(false);
    const [isOpen4, setIsOpen4] = React.useState(false);
    const [searchType, setSearchType] = React.useState('media');
    const [selectById, setSelectByID] = React.useState(false);
    const [idSelect, setIdSelect] = React.useState('Search by Title');
    const [isShowList, setIsShowList] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [currentPageMedia, setCurrentPageMedia] = React.useState(1);
    const [allSelect, setAllSelect] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [currentMedia, setCurrentMedia] = React.useState({});
    const [currentWatclist, setCurrentWatchlist] = React.useState({});
    const [isBannerImage, setIsBannerImage] = React.useState(false);
    const [addFaq, setAddFaq] = React.useState(false);

    const [activeCategory, setActiveCategory] = React.useState('');
    const [activeCategoryName, setActiveCategoryName] = React.useState('');

    const wrapperRef = React.useRef(null);

    const blogDetails = useSelector((state) => state.blogs && state.blogs.blogDetails ? state.blogs.blogDetails : {})

    const blogLangList = useSelector((state) => state.blogs && state.blogs.blogLangList ? state.blogs.blogLangList : {})

    const blogCategoryList = useSelector((state) => state.blogs && state.blogs.categoryList ? state.blogs.categoryList : {})

    const categoryList = useSelector((state) => state.categories && state.categories.categoriesSearchList ? state.categories.categoriesSearchList : {})

    const subCategoryList = useSelector((state) => state.blogs && state.blogs.subCategoryList ? state.blogs.subCategoryList : {})

    const watchlistData = useSelector((state) => state.watchlist && state.watchlist.watchlistData ? state.watchlist.watchlistData : {})


    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsShowList(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    useEffect(() => {
        if (blogDetails && blogDetails.data && blogDetails.data.uuid) {
            setCurrentFormData({ ...blogDetails.data });
        }
    }, [blogDetails])

    useEffect(() => {
        dispatch(getBlogLanguage());
        if (editId && editId != '') {
            setCurrentFormData({});
            dispatch(getBlogDetails(editId));
        }
    }, [editId])

    const handleDelete = (i) => {
        if (currentFormData.tags && currentFormData.tags.length > 0) {
            var tempTags = currentFormData.tags;
            tempTags.splice(i, 1);
            handleFormChange(tempTags, 'tags');
        }
    };

    const handleAddition = (tag) => {
        var tempTags = currentFormData.tags ? currentFormData.tags : [];
        tempTags.push(tag?.text);
        handleFormChange(tempTags, 'tags');
    };


    const handleFormChange = (val, name) => {
        setCurrentFormData({ ...currentFormData, [name]: val })
    }

    useEffect(() => {
        if (editId) {
            handleUpdate(editId, true);
        }
    }, [currentFormData])

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleOnMediaScroll = (e) => {
        if (isInViewport(e.target.lastChild)) {
            setCurrentPageMedia(currentPage + 1);
        }
    }

    const handleCategorySearch = (e) => {
        setSearchText(e.target.value);
        setCurrentPageMedia(1);
        if (e.target.value && e.target.value != "") {
            dispatch(categorySearchList(e.target.value, idSelect == 'Search by Title' ? 'title' : 'id', allSelect));
        } else {
            dispatch(categorySearchList(e.target.value, 'id', allSelect));
        }
    }

    const handleCategorySearchWatchlist = (e) => {
        setSearchText(e.target.value);
        dispatch(getWatchList(e.target.value, 1, 30));
    }

    const handleselectMedia = (data) => {
        setIsShowList(false);
        var media = {
            image: data?.poster ? data?.poster : data?.poster_path,
            title: data?.title,
            media: data.media && data.media != "" && data.media.length > 15 ? data.media : data?.uuid
        }
        setCurrentMedia(media);
        handleFormChange(data.media && data.media != "" && data.media.length > 15 ? data.media : data?.uuid, 'media');
    }

    const handleselectWatchlist = (data) => {
        setIsShowList(false);
        var media = {
            image: data?.thumbnail || '',
            title: data?.category_name,
            media: data?.uuid || ''
        }
        setCurrentWatchlist(media);
        handleFormChange(data?.uuid, 'watchlist');
    }

    const handleUploadBanner = (event, s3_path) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }

        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = s3_path + timeStamp + "-" + randomNo + "-" + file.name;

        const s3 = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (data && data.Location) {
                handleFormChange(data.Location, 'banner');
            }
            if (err) {
                return err;
            }
        });
    }

    const handleUpdate = (id, hideMesg = false) => {
        if (currentFormData?.sub_categories && currentFormData?.sub_categories.length > 0) {
            const body = {
                "title": currentFormData?.title,
                "banner": currentFormData?.banner,
                "description": currentFormData?.description || null,
                "date_published": currentFormData?.date_published,
                "author": currentFormData?.author,
                "blog_content": currentFormData?.blog_content || null,
                "media": currentFormData?.media || null,
                "person": currentFormData?.person || null,
                "watchlist": currentFormData?.watchlist || null,
                "language": currentFormData?.language,
                "is_featured": currentFormData?.is_featured,
                "is_active": currentFormData?.is_active,
                "tags": currentFormData?.tags,
                "url": currentFormData?.url,
                "meta_title": currentFormData?.meta_title,
                "meta_description": currentFormData?.meta_description,
                "schema": currentFormData?.schema,
                "faQs": currentFormData?.faQs,
                "related_blogs": currentFormData?.related_blogs && currentFormData?.related_blogs[0] && currentFormData?.related_blogs[0].uuid ? currentFormData?.related_blogs.map(x => { return x.uuid }) : currentFormData?.related_blogs,
                "sub_categories": currentFormData?.sub_categories.map(item => item.uuid || item.value) || []
            }
            dispatch(updateBlogData(id, body, hideMesg));
        }
    }

    const getFormattedDateAgain = (value) => {
        let newDate = new Date(value);
        let d = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
        let m = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
        let y = newDate.getFullYear();
        let res = d + "-" + m + "-" + y;
        return res;
    }

    const getFormattedDate = (value) => {
        let date = [value.split("-")[1], value.split("-")[0], value.split("-")[2]].join("-");
        let newDate = new Date(date);
        let d = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
        let m = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
        let y = newDate.getFullYear();
        let res = y + "-" + m + "-" + d;
        return res;
    }

    useEffect(() => {
        if (!blogCategoryList.data || blogCategoryList.data.length == 0) {
            dispatch(getBlogCategoryData());
        } else {
            if (activeCategory == '') {
                setActiveCategory(blogCategoryList.data && blogCategoryList.data[0] && blogCategoryList.data[0].uuid);
                setActiveCategoryName(blogCategoryList.data && blogCategoryList.data[0] && blogCategoryList.data[0].category);
            }
        }
    }, [blogCategoryList])

    useEffect(() => {
        if (activeCategory && activeCategory != '') {
            dispatch(getBlogSubCategoryData(activeCategory));
        }
    }, [activeCategory])


    return (
        <div>
            {currentFormData.uuid &&
                <Row>
                    <Col xs={4}>
                        <Label size='sm' className='m-0' >
                            Categories
                        </Label>
                        <Select
                            placeholder={"Categories"}
                            components={components}
                            options={
                                getSelectOptions(blogCategoryList?.data || [])
                            }
                            value={{ label: activeCategoryName, value: activeCategory }}
                            onChange={(e) => {
                                setActiveCategory(e.value);
                                setActiveCategoryName(e.label);
                            }}
                        />
                    </Col>
                    <Col xs={4}>
                        <Label size='sm' className='m-0' >
                            Sub Categories
                        </Label>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            placeholder={"Sub-Categories"}
                            components={components}
                            options={
                                getSelectOptions(subCategoryList?.data || [])
                            }
                            value={
                                getSelectOptions(currentFormData?.sub_categories || [])
                            }
                            onChange={(e) => handleFormChange(e, 'sub_categories')}
                        />
                    </Col>
                    <Col xs={4} className="mt-3">
                        <Row className='justify-content-center m-0'>
                            <Button className='w-100 mt-3' onClick={() => handleUpdate(editId)} size='sm' color='success'>Update</Button>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Label size='sm' className='m-0' >
                            Title
                        </Label>
                        <Input
                            id="title"
                            size={'sm'}
                            placeholder='Title'
                            onChange={(e) => handleFormChange(e.target.value, 'title')}
                            value={currentFormData.title}
                        />
                    </Col>
                    <Col xs={6}>
                        <Label size='sm' className='m-0' >
                            Redirect URL
                        </Label>
                        <Input
                            id="url"
                            size={'sm'}
                            placeholder='url'
                            onChange={(e) => handleFormChange(e.target.value, 'url')}
                            value={currentFormData.url}
                        />
                    </Col>
                    <Col xs={6}>
                        <FAQ faqs={currentFormData?.faQs || []} updateFaq={(data) => handleFormChange(data, 'faQs')} isEdit={true} />
                    </Col>

                    {/* related_blogs */}

                    <Col xs={6}>
                        <RelatedBlogs
                            relatedBlogs={currentFormData?.related_blogs || []}
                            updateBlogPosts={(data) => handleFormChange(data, 'related_blogs')}
                            isEdit={true} editId={editId} />
                    </Col>

                    {/* SEO Fields */}

                    <Col xs={12} className="mt-3 mb-3">
                        <div className='bg-info pt-3 pb-3' style={{ borderRadius: '7px' }}>
                            <Col xs={12}>
                                <Label size='md' className='m-0' >
                                    SEO Fields :
                                </Label>
                                <br />
                                <Label size='sm' className='m-0' >
                                    Meta Title
                                </Label>
                                <Input
                                    id="meta_title"
                                    size={'sm'}
                                    placeholder='meta title'
                                    onChange={(e) => handleFormChange(e.target.value, 'meta_title')}
                                    value={currentFormData?.meta_title}
                                />
                            </Col>
                            <Col xs={12}>
                                <Label size='sm' className='m-0' >
                                    Meta Title
                                </Label>
                                <Input
                                    id="meta_description"
                                    size={'sm'}
                                    placeholder='meta description'
                                    onChange={(e) => handleFormChange(e.target.value, 'meta_description')}
                                    value={currentFormData?.meta_description}
                                />
                            </Col>
                            <Col xs={12}>
                                <Label size='sm' className='m-0' >
                                    Schema
                                </Label>
                                <Input
                                    id="schema"
                                    size={'sm'}
                                    type="textarea"
                                    placeholder='schema'
                                    onChange={(e) => handleFormChange(e.target.value, 'schema')}
                                    value={currentFormData?.schema}
                                />
                            </Col>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <Label size='sm' className='m-0' >
                            <Row style={{ alignItems: 'center' }} className='m-0'>
                                <span>Banner {isBannerImage ? 'Image' : 'Url'}</span>
                                <CustomInput
                                    type="switch"
                                    id={`exadmplezCustomFeaturedSwitchCreate`}
                                    name="customSswzitch"
                                    className='ml-3'
                                    checked={isBannerImage}
                                    onChange={(e) => setIsBannerImage(e.target.checked)}
                                />
                            </Row>
                        </Label>
                        {isBannerImage ?
                            <Row className='m-0' style={{ alignItems: 'center' }}>
                                <img style={{ width: 200, height: 100, marginRight: 20 }} src={currentFormData.banner} />
                                <Input
                                    style={{ width: 'auto' }}
                                    id="title"
                                    size={'sm'}
                                    type='file'
                                    placeholder='Title'
                                    onChange={(e) => handleUploadBanner(e, `Blogs/${currentFormData?.s3_path}/banner-`)}
                                />
                            </Row>
                            :
                            <Row className='m-0' style={{ alignItems: 'center' }}>
                                <Input
                                    id="imgurl"
                                    size={'sm'}
                                    placeholder='banner url'
                                    onChange={(e) => handleFormChange(e.target.value, 'banner')}
                                    value={currentFormData.banner}
                                />
                            </Row>
                        }
                    </Col>

                    <Col xs={6}>
                        <Label size='sm' className='m-0' >
                            Tags
                        </Label>
                        <ReactTags
                            tags={currentFormData.tags ? currentFormData?.tags.map((x) => {
                                return { id: x, text: x }
                            }) : []}
                            // suggestions={tagsList}
                            delimiters={delimiters}
                            handleDelete={handleDelete}
                            handleAddition={handleAddition}
                            // handleInputChange={(va) => handleInputChange(va)}
                            // handleDrag={handleDrag}
                            // handleTagClick={handleTagClick}
                            editable={false}
                            inputFieldPosition="bottom"
                            autocomplete
                            allowDragDrop={false}
                            minQueryLength={1}
                            placeholder="Type and press enter to add new tag"
                            classNames={{ tags: 'w-100', tagInput: 'w-100 border-0 ReactTags__tagInput' }}
                        />
                    </Col>

                    <Col xs={2}>
                        <Label size='sm' className='m-0' >
                            Date
                        </Label>
                        <Input
                            style={{ width: 'auto' }}
                            id="title"
                            size={'sm'}
                            type='date'
                            placeholder='Date'
                            onChange={(e) => handleFormChange(getFormattedDateAgain(e.target.value), 'date_published')}
                            value={getFormattedDate(currentFormData.date_published)}
                        />
                    </Col>
                    <Col xs={2}>
                        <Label size='sm' className='m-0' >
                            Is Active
                        </Label>
                        <CustomInput
                            type="switch"
                            id={`exampleCustomFeaturedSwitch-${editId}`}
                            name="customSwitch"
                            className=''
                            checked={currentFormData.is_active}
                            onChange={(e) => handleFormChange(e.target.checked, 'is_active')}
                        />
                    </Col>
                    <Col xs={2}>
                        <Label size='sm' className='m-0' >
                            Is Featured
                        </Label>
                        <CustomInput
                            type="switch"
                            id={`exampleCustomActiveSwitch-${editId}`}
                            name="customSwitch"
                            className=''
                            checked={currentFormData.is_featured}
                            onChange={(e) => handleFormChange(e.target.checked, 'is_featured')}
                        />
                    </Col>
                    <Col xs={6}>
                        <Label size='sm' className='m-0' >
                            Language
                        </Label>
                        <Dropdown size='sm' color="black" mt={4} toggle={() => { setToggleFilter(!toggleFilter) }}
                            isOpen={toggleFilter} style={{ width: '100%' }}>
                            <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                {currentFormData?.language__language}
                            </DropdownToggle>
                            <DropdownMenu>
                                {blogLangList.data && blogLangList.data.languages && blogLangList.data.languages.length > 0 && blogLangList.data.languages.map((x, i) => {
                                    return <DropdownItem key={i} value={x?.uuid} onClick={(e) => {
                                        handleFormChange(x?.uuid, 'language');
                                        handleFormChange(x?.language, 'language__language');
                                    }}>{x?.language}</DropdownItem>
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col xs={3}>
                        <Label size='sm' className='m-0' >
                            Attach Type (Media/Watchlist)
                        </Label>
                        <Dropdown className='w-100' isOpen={isOpen4} toggle={() => setIsOpen4(!isOpen4)}>
                            <DropdownToggle className='w-100' caret>
                                {searchType ? searchType : 'Select Search Type'}
                            </DropdownToggle>
                            <DropdownMenu
                            >
                                <DropdownItem
                                    onClick={() => {
                                        setSearchType('media');
                                    }}
                                >
                                    Media
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        setSearchType('watchlist');
                                    }}
                                >
                                    Watchlist
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        setSearchType('person');
                                    }}
                                >
                                    Person
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    {searchType === 'media' &&
                        <>
                            <Col xs={3}>
                                <Label size='sm' className='m-0' >
                                    Search By
                                </Label>
                                <Dropdown color="black" mt={4} toggle={() => { setSelectByID(!selectById) }}
                                    isOpen={selectById} style={{ width: '100%' }}>
                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                        {idSelect && idSelect != "" ? idSelect : 'Select ID/Title'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem value="Search by Title" onClick={(e) => {
                                            setIdSelect(e.target.value);
                                        }}>Search by Title</DropdownItem>
                                        <DropdownItem value="Search by Ids" onClick={(e) => {
                                            setIdSelect(e.target.value);
                                        }}>Search by Ids</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col xs={6}>
                                <Label size='sm' className='m-0' >
                                    Search Media
                                </Label>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <div className="w-100 position-relative">
                                        <Input placeholder='Search Movies/Web Series'
                                            onChange={(e) => {
                                                handleCategorySearch(e);
                                            }}
                                            onClick={() => setIsShowList(true)}
                                        />
                                        {isShowList && categoryList && categoryList.data && categoryList.data.results && categoryList.data.results.length > 0 &&
                                            <div onScroll={(e) => handleOnMediaScroll(e)} ref={wrapperRef} className='category-list position-absolute'>
                                                {categoryList.data.results.map((x, i) => {
                                                    return <Row onClick={() => handleselectMedia(x)} className='align-items-center media-item pointer' key={i}>
                                                        <div>
                                                            <Col>
                                                                {(x.poster && x.poster != "" && x.poster != null) || (x.poster_path && x.poster_path != "" && x.poster_path != null) ?
                                                                    <img
                                                                        alt="..."
                                                                        className="category_img rounded shadow-lg"
                                                                        src={x.poster ? x.poster : x.poster_path ? x.poster_path : ''}
                                                                    ></img>
                                                                    :
                                                                    <div className='d-img'>
                                                                        <i className='fas fa-image'></i>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </div>
                                                        <Col>
                                                            <h5 className="dt p-0 m-0">{x.title}</h5>
                                                            <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Release Date : </b>{x.release_date}</p>
                                                            <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Release Year : </b>{x.release_year ? x.release_year : ''}</p>
                                                            <p style={{ fontSize: 12 }} className="p-0 m-0"><b>TMDB ID : </b>{x.tmdb_id ? x.tmdb_id : ''}</p>
                                                            <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Media Type : </b>{x.media_type ? x.media_type : ''}</p>
                                                        </Col>
                                                    </Row>
                                                })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            {currentFormData.media && currentFormData.media != '' &&
                                <Col xs={12}>
                                    <Label size='sm' className='m-0' >
                                        Media
                                    </Label>
                                    <Row style={{ alignItems: 'center' }} className='m-0'>
                                        <Input
                                            value={`${currentFormData.media ? currentFormData.media : ''} ${currentMedia?.title ? `(${currentMedia?.title})` : ''}`}
                                            disabled
                                            className='mr-2'
                                            required
                                            style={{ width: '75%' }}
                                        />
                                        {currentMedia && currentMedia.title &&
                                            <>
                                                <img src={currentMedia?.image} style={{ width: 45, height: 60, borderRadius: 7 }} alt='' />
                                            </>
                                        }
                                    </Row>
                                </Col>
                            }
                        </>
                    }

                    {searchType === 'watchlist' &&
                        <>
                            <Col xs={4}>
                                <Label size='sm' className='m-0' >
                                    Search Watchlist
                                </Label>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <div className="w-100 position-relative">
                                        <Input placeholder='Search Watchlist'
                                            onChange={(e) => {
                                                handleCategorySearchWatchlist(e);
                                            }}
                                            onClick={() => setIsShowList(true)}
                                        />
                                        {isShowList && watchlistData && watchlistData.data && watchlistData.data.results && watchlistData.data.results.length > 0 &&
                                            <div onScroll={(e) => handleOnMediaScroll(e)} ref={wrapperRef} className='category-list position-absolute'>
                                                {watchlistData.data.results.map((x, i) => {
                                                    return <Row onClick={() => handleselectWatchlist(x)} className='align-items-center media-item pointer' key={i}>
                                                        <div>
                                                            <Col>
                                                                {(x.thumbnail && x.thumbnail != "" && x.thumbnail != null) ?
                                                                    <img
                                                                        alt="..."
                                                                        className="category_img rounded shadow-lg"
                                                                        src={x.thumbnail ? x.thumbnail : ''}
                                                                    ></img>
                                                                    :
                                                                    <div className='d-img'>
                                                                        <i className='fas fa-image'></i>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </div>
                                                        <Col>
                                                            <h5 className="dt p-0 m-0">{x?.category_name}</h5>
                                                            <p style={{ fontSize: 12 }} className="p-0 m-0"><b>{x?.is_public ? 'Sharelist' : 'Watchlist'}</b></p>
                                                        </Col>
                                                    </Row>
                                                })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Col>
                            {currentFormData.watchlist && currentFormData.watchlist != '' &&
                                <Col xs={12}>
                                    <Label size='sm' className='m-0' >
                                        Watchlist
                                    </Label>
                                    <Row style={{ alignItems: 'center' }} className='m-0'>
                                        <Input
                                            value={`${currentFormData.watchlist ? currentFormData.watchlist : ''} ${currentWatclist?.title ? `(${currentWatclist?.title})` : ''}`}
                                            disabled
                                            className='mr-2'
                                            required
                                            style={{ width: '75%' }}
                                        />
                                        {currentWatclist && currentWatclist.title &&
                                            <img src={currentWatclist?.image} style={{ width: 45, height: 60, borderRadius: 7 }} />
                                        }
                                    </Row>
                                </Col>
                            }
                        </>
                    }

                    <Col xs={12} className="mt-3">
                        <Label size='sm' className='m-0' >
                            Description
                        </Label>
                        <MyCkEditor
                            data={currentFormData?.description}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });
                                handleFormChange(data, 'description');
                            }}
                            s3_path={`Blogs/${currentFormData?.s3_path}/description-`}
                        />
                        {/* <MyEditor
                            s3_path={`Blogs/${currentFormData?.s3_path}/description-`}
                            onChange={(data) => handleFormChange(data, 'description')}
                            value={currentFormData?.description || ''}
                        /> */}
                    </Col>

                    <Col xs={12} className="mt-3">
                        <Label size='sm' className='m-0' >
                            Content
                        </Label>
                        {/* <CustomInput
                            type="switch"
                            id={`exampletmlctiveSwitch-${editId}`}
                            name="customSwitch"
                            className=''
                            // checked={currentFormData.is_featured}
                            // onChange={(e) => handleFormChange(e.target.checked, 'is_featured')}
                        /> */}
                        <MyCkEditor
                            data={currentFormData?.blog_content}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                // console.log({ event, editor, data });
                                handleFormChange(data, 'blog_content');
                            }}
                            s3_path={`Blogs/${currentFormData?.s3_path}/blog_content-`}
                        />
                        {/* <MyEditor
                            s3_path={`Blogs/${currentFormData?.s3_path}/blog_content-`}
                            onChange={(data) => handleFormChange(data, 'blog_content')}
                            value={currentFormData?.blog_content || ''}
                        /> */}
                    </Col>
                    <Col xs={12} className="mt-3">
                        <Row className='justify-content-center m-0'>
                            <Button onClick={() => handleUpdate(editId)} color='success'>Update</Button>
                        </Row>
                    </Col>
                </Row>
            }

        </div>
    )
}
