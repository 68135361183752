import Header from 'components/Headers/Header'
import React, { useEffect, useMemo, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Button, Card, Col, Container, Input, Row, Table } from 'reactstrap'
import Loader from 'views/common/Loader'
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux'
import { clearMessage } from 'redux/actions/operation.action'
import { getCXDeeplinkList } from 'redux/actions/operation.action'
import { getCXEngineList } from 'redux/actions/operation.action'
import CustomPagination from 'components/Pagination/CustomPagination'
import { useHistory, useLocation } from 'react-router-dom'
import { deleteCXRecord } from 'redux/actions/operation.action'
import { updateCXRemark } from 'redux/actions/operation.action'
import { exportCXcsvList } from 'redux/actions/operation.action'
import helpers from 'helpers/common'
import { clearExportData } from 'redux/actions/operation.action'

// const defaultRemark = [
//     {
//         label: 'deeplink updated',
//         value: 'deeplink updated'
//     },
//     {
//         label: 'meta data updated',
//         value: 'meta data updated'
//     },
//     {
//         label: 'not available on TMDB',
//         value: 'not available on TMDB'
//     },
//     {
//         label: 'not available on IMDB/TMDB',
//         value: 'not available on IMDB/TMDB'
//     }
// ]

const defaultRemark = [
    {
        label: 'None',
        value: 'None'
    },
    {
        label: 'data updated',
        value: 'data updated'
    },
    {
        label: 'title not found',
        value: 'title not found'
    }
]

const defaultComment = [
    {
        label: 'deeplink populated',
        value: 'deeplink populated'
    },
    {
        label: 'more than one result',
        value: 'more than one result'
    },
    {
        label: 'deeplink populated, need to check',
        value: 'deeplink populated, need to check'
    },
    {
        label: 'title not found',
        value: 'title not found'
    }
]

const defaultSort = [
    {
        label: 'Newest First',
        value: 'n'
    },
    {
        label: 'Oldest First',
        value: 'o'
    }
]

export default function CXList() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const dateR = queryParams.get('dateRange');
    const fromD = queryParams.get('from');
    const toD = queryParams.get('to');
    const page = queryParams.get('page');
    const cT = queryParams.get('commentText');
    const rT = queryParams.get('remarkText');
    const eng = queryParams.get('engine');
    const newEng = eng && eng != '' ? {
        label: eng,
        value: eng,
    } : '';

    const st = queryParams.get('sort');
    const newSort = st && st !== '' ? defaultSort.filter(x => x.value === st)[0] : defaultSort[0];


    const [searchText, setSearchText] = useState('');
    const [commentText, setCommentText] = useState(cT);
    const [sort, setSort] = useState(newSort);
    const [remarkText, setRemarkText] = useState(rT);
    const [engine, setEngine] = useState(newEng);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(20);

    const [dateRange, setDateRange] = useState(dateR);
    const [fromDate, setFromDate] = useState(fromD);
    const [toDate, setToDate] = useState(toD);


    const dispatch = useDispatch();
    const history = useHistory();


    const status = useSelector((state) => state.operation && state.operation.status ? state.operation.status : '')
    const errMesg = useSelector((state) => state.operation && state.operation.errMesg ? state.operation.errMesg : '')

    const isLoading = useSelector((state) => state.operation && state.operation.isLoading ? state.operation.isLoading : false)

    const cxEngineList = useSelector((state) => state.operation && state.operation.cxEngineList ? state.operation.cxEngineList : {})

    const cxDeeplinkList = useSelector((state) => state.operation && state.operation.cxDeeplinkList ? state.operation.cxDeeplinkList : {})

    const cxCsvData = useSelector((state) => state.operation && state.operation.cxCsvData ? state.operation.cxCsvData : {})

    useEffect(() => {
        if (cxEngineList && cxEngineList.data && eng && eng != '') {
            let val = getEngine(eng);
            if (val) {
                setEngine(val);
            }
        }
    }, [cxEngineList, eng]);

    const getEngine = (enG) => {
        let arr = cxEngineList.data && cxEngineList.data.results ? cxEngineList.data.results : [];
        var temp = {};
        arr.length > 0 && arr.map((x, i) => {
            if (x.uuid === enG) {
                temp = {
                    label: (x.provider_id__platform_name || x.label) + " - " + (x?.media_type),
                    value: x.uuid || x.value,
                }
            }
        })
        return temp;
    };


    useEffect(() => {
        dispatch(getCXEngineList(searchText, 1, 50));
    }, [searchText])

    useEffect(() => {
        if (cxCsvData && cxCsvData.data) {
            let exportFileUrl = cxCsvData.data;
            setTimeout(async () => {
                let path = await helpers.getPresignedUrl(exportFileUrl);
                if (path) {
                    const aTag = document.createElement("a");
                    aTag.href = path;
                    aTag.target = '_blank';
                    document.body.appendChild(aTag);
                    aTag.click();
                    dispatch(clearExportData());
                }
            }, [1000])
        }
    }, [cxCsvData])

    useEffect(() => {
        setCurrentPage(page);
    }, [page])

    useEffect(() => {
        let rt = remarkText === 'None' ? '' : remarkText;
        if (page > currentPage) {
            history.push('/admin/cx?page=' + (page || '') + '&commentText=' + (commentText || '') + '&remarkText=' + rt + '&engine=' + (engine?.value || '') + '&sort=' + (sort?.value || '') + '&dateRange=' + (dateRange || '') + '&from=' + (fromDate || '') + '&to=' + (toDate || ''));
            setCurrentPage(page);
            dispatch(getCXDeeplinkList(engine?.value || '', commentText, rt, sort?.value, dateRange, page, pageLimit));
        } else {
            history.push('/admin/cx?page=' + (currentPage || 1) + '&commentText=' + commentText + '&remarkText=' + rt + '&engine=' + (engine?.value || '') + '&sort=' + (sort?.value || '') + '&dateRange=' + (dateRange || '') + '&from=' + (fromDate || '') + '&to=' + (toDate || ''));
            dispatch(getCXDeeplinkList(engine?.value || '', commentText, rt, sort?.value, dateRange, currentPage, pageLimit));
        }
    }, [engine, commentText, remarkText, currentPage, fromDate, toDate, dateRange, pageLimit, sort])

    useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            dispatch(getCXDeeplinkList(engine?.value || '', commentText, remarkText === 'None' ? '' : remarkText, sort?.value, dateRange, currentPage, pageLimit));
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const getSelectOptions = (resp) => {
        let arr = resp.data && resp.data.results ? resp.data.results : [];
        var temp = [];
        arr.length > 0 && arr.map((x, i) => {
            if (x?.total_count && x?.total_count > 0) {
                temp.push({
                    label: (x.provider_id__platform_name || x.label) + " - " + (x?.media_type) + " (" + (x?.total_count || 0) + ")",
                    value: x.uuid || x.value,
                });
            }
        })
        return temp;
    }

    const handleEdit = (id) => {
        history.push(`/admin/editmedia/${id}?type=cx-update`);
    }

    const handleDelete = (id) => {
        dispatch(deleteCXRecord(id));
    }

    const updateRemark = (data, value) => {
        if (data.uuid) {
            dispatch(updateCXRemark({ remark: value === 'None' ? null : value }, data.uuid));
        }
    }

    useEffect(() => {
        if ((fromDate && fromDate != '') || (toDate && toDate != '')) {
            let temp = [fromDate || '', toDate || ''];
            setDateRange(temp.join(','));
        } else {
            setDateRange('');
        }
    }, [fromDate, toDate])

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <Container>
                <Card className='mt-4 pt-4 pb-4 rounded-lg'>
                    <Row className='justify-content-between align-items-center w-100 m-0'>
                        <Col>
                            <h3 className='mb-3'>CX Daily Update</h3>
                        </Col>
                        <Col>
                            <Select
                                placeholder={"Comment"}
                                components={components}
                                options={defaultComment}
                                value={commentText ? { label: commentText, value: commentText } : ''}
                                onChange={(e) => setCommentText(e.value)}
                            />
                        </Col>
                        <Col>
                            <Select
                                placeholder={"Remark"}
                                components={components}
                                options={defaultRemark}
                                value={remarkText ? { label: remarkText, value: remarkText } : ''}
                                onChange={(e) => setRemarkText(e.value)}
                            />
                        </Col>
                        <Col>
                            <Select
                                placeholder={"Platform"}
                                components={components}
                                options={
                                    getSelectOptions(cxEngineList || [])
                                }
                                value={engine}
                                onInputChange={(e) => setSearchText(e)}
                                onChange={(e) => setEngine(e)}
                            />
                        </Col>
                    </Row>
                    <Row className='justify-content-between align-items-center w-100 ml-0 mr-0 mt-2'>
                        <Col sm="auto">
                            From:
                        </Col>
                        <Col sm="2">
                            <Col sm={12} className="p-0">
                                <Input
                                    type='date'
                                    placeholder='From'
                                    onChange={(e) => setFromDate(e.target.value)}
                                    value={fromDate}
                                />
                            </Col>
                        </Col>
                        <Col sm="auto">
                            To:
                        </Col>
                        <Col sm="2">
                            <Col sm={12} className="p-0">
                                <Input
                                    type='date'
                                    placeholder='To'
                                    onChange={(e) => setToDate(e.target.value)}
                                    value={toDate}
                                />
                            </Col>
                        </Col>
                        <Col>
                            <Select
                                placeholder={"Sort"}
                                components={components}
                                options={defaultSort}
                                value={sort}
                                onChange={(e) => setSort(e)}
                            />
                        </Col>
                        <Button
                            className="mr-3 btn-icon btn-simple"
                            color="danger"
                            size="md"
                            type="button"
                            onClick={() => {
                                setEngine('');
                                setRemarkText('');
                                setCommentText('');
                                setSort(defaultSort[0]);
                                setFromDate('');
                                setDateRange('');
                                setToDate('');
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            className="mr-3 btn-icon btn-simple"
                            color="success"
                            size="md"
                            type="button"
                            disabled={!engine.value}
                            onClick={() => {
                                dispatch(exportCXcsvList(engine.value))
                            }}
                        >
                            Export To CSV
                        </Button>
                    </Row>
                    <Container style={{ overflowY: 'auto' }} className='table'>
                        <Table hover className='mt-3 align-items-center table-flush'>
                            <thead>
                                <tr>
                                    <th className='table_heading' style={{ maxWidth: '80px' }}>
                                        S.No
                                    </th>
                                    <th className='table_heading'>
                                        Title
                                    </th>
                                    <th className='table_heading'>
                                        Extracted Title
                                    </th>
                                    <th className='table_heading'>
                                        Language
                                    </th>
                                    <th className='table_heading'>
                                        Comment
                                    </th>
                                    <th className='table_heading'>
                                        Date
                                    </th>
                                    <th className='table_heading' style={{ minWidth: '220px' }}>
                                        Remark
                                    </th>
                                    <th className='table_heading' style={{ minWidth: '130px' }}>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {cxDeeplinkList && cxDeeplinkList.data && cxDeeplinkList.data.results && cxDeeplinkList.data.results.length > 0 ? cxDeeplinkList.data.results.map((x, i) => {
                                    return <tr key={i}>
                                        <td scope="row">
                                            {(pageLimit * (currentPage - 1)) + (i + 1)}
                                        </td>
                                        <td>
                                            {x?.title}&nbsp;&nbsp;&nbsp;&nbsp;
                                            <a href={x?.link} target={'_blank'}>
                                                <i className="fas fa-external-link-alt pt-1"></i>
                                            </a>
                                        </td>
                                        <td>
                                            {x?.extracted_title}
                                        </td>
                                        <td>
                                            {x?.language}
                                        </td>
                                        <td>
                                            {x?.comment}
                                        </td>
                                        <td>
                                            {x.created_at ? new Date(x.created_at).toLocaleDateString(undefined, { day: "numeric", month: "long", year: "numeric", hour: 'numeric', minute: 'numeric', second: 'numeric' }) : ''}
                                        </td>
                                        <td>
                                            <Select
                                                placeholder={"Update Remark"}
                                                components={components}
                                                options={defaultRemark}
                                                value={x?.remark ? { label: x?.remark, value: x?.remark } : ''}
                                                onChange={(e) => updateRemark(x, e.value)}
                                            />
                                        </td>
                                        <td>
                                            {x?.media && x?.media != '' ?
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleEdit(x?.media)}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>
                                                : ''
                                            }
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleDelete(x.uuid)}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr>
                                        <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {cxDeeplinkList.data && cxDeeplinkList.data.pagination &&
                            <CustomPagination
                                pagination={cxDeeplinkList.data && cxDeeplinkList.data.pagination && cxDeeplinkList.data.pagination ? cxDeeplinkList.data.pagination : {}}
                                setCurrentPage={(val) => {
                                    history.push('/admin/cx?page=' + val);
                                }}
                                setPageLimit={(val) => setPageLimit(val)}
                                defaultPageList={cxDeeplinkList.data && cxDeeplinkList.data.results ? cxDeeplinkList.data.results.length : 10}
                            />
                        }
                    </Container>
                </Card>
            </Container>
        </>
    )
}
