import React from 'react'
import { Button, Col, Input, Modal, ModalHeader, Row } from 'reactstrap'

const defaultFaq = [
    {
        question: '',
        answer: ''
    }
]

export default function FAQ(props) {
    const { faqs, isEdit } = props;
    const [addFaq, setAddFaq] = React.useState(false);
    const [faq, setFaq] = React.useState([]);

    const handleOnclose = () => {
        if ((faq.length > 0 || isEdit)) {
            if (JSON.stringify(faq[0]) !== JSON.stringify(defaultFaq[0])) {
                props?.updateFaq(faq);
            }
        }
        setAddFaq(false);
    }

    const handleFaqChange = (value, name, index) => {
        let tempArr = faq;
        const newFaq = tempArr.map((x, i) => {
            if (i === index) {
                return {
                    ...x,
                    [name]: value,
                }
            } else {
                return x
            }
        });
        setFaq([...newFaq]);
    }

    const handleDeleteFaq = (index) => {
        faq.splice(index, 1);
        setFaq([...faq]);
    }

    const handleAddMore = () => {
        setFaq([...faq, { question: '', answer: '' }])
    }

    const handleOpenModal = () => {
        if (isEdit) {
            if (faqs && faqs.length > 0) {
                setFaq([...faqs]);
            } else {
                setFaq(defaultFaq);
            }
        } else {
            setFaq(defaultFaq);
        }
        setAddFaq(true);
    }

    return (
        <div>
            <Button className='w-100 mt-3' onClick={() => handleOpenModal()} color='danger'>{isEdit ? 'Update' : 'Add'} FAQs</Button>

            <Modal
                isOpen={addFaq}
                toggle={() => handleOnclose()}
                size="lg"
            >
                <ModalHeader className='p-3' toggle={() => handleOnclose()}>
                    <h3>FAQs</h3>
                </ModalHeader>
                <div className='p-4' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {faq.map((x, i) => {
                        return <div key={i}>
                            <Row className='m-0 pb-3'>
                                <span>{i + 1}.</span>
                                <Col>
                                    <Input
                                        id={`question-${i}`}
                                        size={'sm'}
                                        placeholder='Type Question'
                                        className='mb-3'
                                        onChange={(e) => handleFaqChange(e.target.value, 'question', i)}
                                        value={x.question}
                                    />
                                    <Input
                                        id={`answer-${i}`}
                                        size={'sm'}
                                        placeholder='Type Answer'
                                        onChange={(e) => handleFaqChange(e.target.value, 'answer', i)}
                                        value={x.answer}
                                    />
                                </Col>
                                <Button
                                    className=" btn-icon btn-simple"
                                    color="danger"
                                    size="sm"
                                    type="button"
                                    onClick={() => {
                                        if (window.confirm("Do you really want to delete this FAQ ?")) {
                                            handleDeleteFaq(i);
                                        }
                                    }}
                                >
                                    <i className="fas fa-trash pt-1"></i>
                                </Button>
                            </Row>
                        </div>
                    })}
                </div>
                <div className='pl-4 pr-4 pb-4'>
                    <Row className='m-0 pt-3'>
                        <Button color="info" size='sm' onClick={() => handleAddMore(!addFaq)} >Add more</Button>
                    </Row>

                    <div style={{ textAlign: 'center' }} className='mt-2'>
                        <Button color="success" onClick={() => handleOnclose()} >Done</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
