import React, { useState } from 'react'
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Row, Col, Container, Nav, NavItem, NavLink, Form, FormGroup,
    Input, Button, UncontrolledDropdown, Table, InputGroup, InputGroupAddon, InputGroupText, Badge,
    Label, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Card, CardTitle, CardText
} from 'reactstrap';
import Header from "components/Headers/Header.js";
import './_operation.scss'
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifyList, getErrorList, getFeedbackList, errorReportStatus, clearMessage, updateReportStatus } from "../../redux/actions/operation.action"
import CustomPagination from 'components/Pagination/CustomPagination';
import Loader from 'views/common/Loader';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

export default function Operation() {
    const dispatch = useDispatch();

    const [currentActiveTab, setCurrentActiveTab] = useState('1');
    const [searchText, setSearchText] = React.useState('');
    const [errorSearchText, setErrorSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [isOpen, setIsOpen] = React.useState(false);
    const [viewData, setViewData] = React.useState({});
    const [toggleFilter, setToggleFilter] = useState(false);
    const [filter, setFilter] = useState('');

    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        dispatch(getNotifyList(searchText, currentPage, pageLimit));
    }, [searchText, currentPage, pageLimit])

    React.useEffect(() => {
        dispatch(getErrorList(errorSearchText, filter, currentPage, pageLimit));
    }, [errorSearchText, filter, currentPage, pageLimit])

    React.useEffect(() => {
        dispatch(getFeedbackList('', currentPage, pageLimit));
    }, [currentPage, pageLimit])

    const notifyList = useSelector((state) => state.operation && state.operation.notifyListResult ? state.operation.notifyListResult : {})

    const isLoading = useSelector((state) => state.operation && state.operation.isLoading ? state.operation.isLoading : false)

    const errorReportList = useSelector((state) => state.operation && state.operation.errorReportResult ? state.operation.errorReportResult : {})

    const feedbackList = useSelector((state) => state.operation && state.operation.feedbackResult ? state.operation.feedbackResult : {})

    const status = useSelector((state) => state.operation && state.operation.status ? state.operation.status : '')
    const errMesg = useSelector((state) => state.operation && state.operation.errMesg ? state.operation.errMesg : '')

    const handleDataView = (data) => {
        setViewData({ ...data });
        setIsOpen(true);
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            dispatch(getErrorList(searchText, filter, currentPage, pageLimit));
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    // console.log(notifyList, "fnjfniewnc8u")
    const handleReportError = (id, type) => {
        eventReporting('Operations', 'Error report status updated to ' + type + ' of id' + id + ' - ', id);
        const body = {
            report_id: id,
            resolution_type: type
        }
        dispatch(updateReportStatus(body));
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <div>
                <Container className="custom-link" fluid>
                    <div>
                        <Nav tabs >
                            <NavItem >

                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '1'
                                    })}
                                    onClick={() => { toggle('1'); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Notify Content

                                </NavLink>
                            </NavItem>
                            <NavItem>

                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '2'
                                    })}
                                    onClick={() => { toggle('2'); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Error Report

                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '3'
                                    })}
                                    onClick={() => { toggle('3'); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Feedback

                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({
                                        active:
                                            currentActiveTab === '4'
                                    })}
                                    onClick={() => { toggle('4'); }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Clips error report
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={currentActiveTab}>
                            <TabPane tabId="1">
                                <Row className='search_div'>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="4">
                                                <Col sm={12} className="p-0 search-icon-box">
                                                    <Input placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                                    <i className="fas fa-search"></i>
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Container className='table'>
                                            <Table className="align-items-center table-flush mt-3" hover>
                                                <thead>
                                                    <tr>
                                                        <th className='table_heading'>Sr No.</th>
                                                        <th className='table_heading'>Media Name</th>
                                                        <th className='table_heading'>Type</th>
                                                        <th className='table_heading'>By User</th>
                                                        <th className='table_heading'>Submitted on</th>
                                                        <th className='table_heading'>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {notifyList.data && notifyList.data.results && notifyList.data.results.length > 0 ? notifyList.data.results.map((x, i) => {
                                                        return <tr key={i}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>
                                                                <Link to={`/admin/editmedia/${x.media ? x.media : ''}`} >
                                                                    {x.media__title}
                                                                </Link>
                                                            </td>
                                                            <td>{x.media__media_type}</td>
                                                            <td>{x.user__username}</td>
                                                            <td>{x.created_at}</td>
                                                            <td>
                                                                {x.is_notified ?
                                                                    <p className='text-success p-0 m-0'>Notified</p>
                                                                    :
                                                                    <p className='text-danger p-0 m-0'>Notify</p>
                                                                }
                                                            </td>
                                                        </tr>
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {notifyList.data && notifyList.data.pagination &&
                                                <CustomPagination
                                                    pagination={notifyList.data && notifyList.data.pagination && notifyList.data.pagination ? notifyList.data.pagination : {}}
                                                    setCurrentPage={(val) => setCurrentPage(val)}
                                                    setPageLimit={(val) => setPageLimit(val)}
                                                    defaultPageList={notifyList.data && notifyList.data.results ? notifyList.data.results.length : 20}
                                                />
                                            }
                                        </Container>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row className='search_div'>
                                    <Col sm="12">
                                        <Row >
                                            <Col sm="4">
                                                <Col sm={12} className="p-0 search-icon-box">
                                                    <Input placeholder='Search' value={errorSearchText} onChange={(e) => setErrorSearchText(e.target.value)} />
                                                    <i className="fas fa-search"></i>
                                                </Col>
                                            </Col>
                                            <Col sm="auto">
                                                <Label size='sm' className='m-0' >
                                                    Resolution Type
                                                </Label>
                                            </Col>
                                            <Col sm="auto">
                                                <Dropdown size='sm' color="black" mt={4} toggle={() => { setToggleFilter(!toggleFilter) }}
                                                    isOpen={toggleFilter} style={{ width: '100%' }}>
                                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                                        {filter || 'All'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setFilter('all')}>All</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('pending')}>Pending</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('resolved')}>Resolved</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('updated')}>Updated</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('declined')}>Declined</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        <Container className='table mt-3'>
                                            <Table className="align-items-center table-flush" hover>
                                                <thead>
                                                    <tr>
                                                        <th style={{ minWidth: '90px' }} className='table_heading'>Sr No.</th>
                                                        <th className='table_heading'>Media Name</th>
                                                        <th className='table_heading'>TMDB ID</th>
                                                        <th style={{ minWidth: '110px' }} className='table_heading'>Submitted on</th>
                                                        <th style={{ minWidth: '200px' }} className='table_heading'>Description</th>
                                                        <th className='table_heading'>User</th>
                                                        <th className='table_heading'>Submitted For</th>
                                                        <th style={{ minWidth: '145px' }} className='table_heading'>Report Type</th>
                                                        <th style={{ minWidth: '145px' }} className='table_heading'>Resolution Type</th>
                                                        <th style={{ minWidth: '220px' }} className='table_heading text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {errorReportList.data && errorReportList.data.results && errorReportList.data.results.length > 0 ? errorReportList.data.results.map((x, i) => {
                                                        return <tr key={i}>
                                                            <th scope="row">{i + 1}
                                                                <Button size='sm' className='ml-3' color='primary' onClick={() => handleDataView(x)} >
                                                                    <i className="fas fa-eye pt-1"></i>
                                                                </Button>
                                                            </th>
                                                            <td>{x.title}</td>
                                                            <td>{x?.tmdb_id}</td>
                                                            <td>{x.submitted_on}</td>
                                                            <td style={{ minWidth: '200px', wordBreak: 'break-all' }}>{x.description}</td>
                                                            <td>{x.username}</td>
                                                            <td>{x.device_type || 'N/A'}</td>
                                                            <td>{x.report_type || 'N/A'}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {x.resolution_type ?
                                                                    x.resolution_type == 'declined' ?
                                                                        <span className='text-danger'>declined</span>
                                                                        :
                                                                        <span className='text-success'>updated</span>
                                                                    : 'N/A'
                                                                }
                                                            </td>
                                                            <td className='text-center'>
                                                                <Button disabled={(x.resolution_type || x.resolution_type != null)} size='sm' color='success' onClick={() => handleReportError(x.uuid, 'updated')} >Update</Button>
                                                                <Button disabled={(x.resolution_type || x.resolution_type != null)} size='sm' color='danger' onClick={() => handleReportError(x.uuid, 'declined')} >Decline</Button>
                                                            </td>
                                                        </tr>
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {errorReportList.data && errorReportList.data.pagination &&
                                                <CustomPagination
                                                    pagination={errorReportList.data && errorReportList.data.pagination && errorReportList.data.pagination ? errorReportList.data.pagination : {}}
                                                    setCurrentPage={(val) => setCurrentPage(val)}
                                                    setPageLimit={(val) => setPageLimit(val)}
                                                    defaultPageList={errorReportList.data && errorReportList.data.results ? errorReportList.data.results.length : 20}
                                                />
                                            }
                                        </Container>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="3">
                                <Row className='search_div'>
                                    <Col sm="12">
                                        <Container className='table'>
                                            <Table className="align-items-center table-flush" bordered >
                                                <thead >
                                                    <tr>
                                                        <th className='table_heading'>Sr No.</th>
                                                        <th className='table_heading'>Users</th>
                                                        <th className='table_heading'>Comment</th>
                                                        <th className='table_heading'>Submitted on</th>
                                                        <th className='table_heading'>Rating</th>
                                                        {/* <th className='table_heading'>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {feedbackList.data && feedbackList.data.results && feedbackList.data.results.length > 0 ? feedbackList.data.results.map((x, i) => {
                                                        return <tr key={i}>
                                                            <th scope="row">{i + 1}</th>
                                                            <td>{x.username}</td>
                                                            <td>{x.additional_comments}</td>
                                                            <td>{x.submitted_on}</td>
                                                            <td>{x.feedback_rating}</td>
                                                        </tr>
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {feedbackList.data && feedbackList.data.pagination &&
                                                <CustomPagination
                                                    pagination={feedbackList.data && feedbackList.data.pagination && feedbackList.data.pagination ? feedbackList.data.pagination : {}}
                                                    setCurrentPage={(val) => setCurrentPage(val)}
                                                    setPageLimit={(val) => setPageLimit(val)}
                                                    defaultPageList={feedbackList.data && feedbackList.data.results ? feedbackList.data.results.length : 10}
                                                />
                                            }
                                        </Container>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId={'4'}>
                                <Row className='search_div'>
                                    <Col sm="12">
                                        <Row >
                                            <Col sm="4">
                                                <Col sm={12} className="p-0 search-icon-box">
                                                    <Input placeholder='Search' value={errorSearchText} onChange={(e) => setErrorSearchText(e.target.value)} />
                                                    <i className="fas fa-search"></i>
                                                </Col>
                                            </Col>
                                            <Col sm="auto">
                                                <Label size='sm' className='m-0' >
                                                    Resolution Type
                                                </Label>
                                            </Col>
                                            <Col sm="auto">
                                                <Dropdown size='sm' color="black" mt={4} toggle={() => { setToggleFilter(!toggleFilter) }}
                                                    isOpen={toggleFilter} style={{ width: '100%' }}>
                                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                                        {filter || 'All'}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => setFilter('all')}>All</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('pending')}>Pending</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('resolved')}>Resolved</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('updated')}>Updated</DropdownItem>
                                                        <DropdownItem onClick={() => setFilter('declined')}>Declined</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                        <Container className='table mt-3'>
                                            <Table className="align-items-center table-flush" hover>
                                                <thead>
                                                    <tr>
                                                        <th style={{ minWidth: '90px' }} className='table_heading'>Sr No.</th>
                                                        <th className='table_heading'>Media Name</th>
                                                        <th className='table_heading'>TMDB ID</th>
                                                        <th style={{ minWidth: '110px' }} className='table_heading'>Submitted on</th>
                                                        <th style={{ minWidth: '200px' }} className='table_heading'>Description</th>
                                                        <th className='table_heading'>User</th>
                                                        <th className='table_heading'>Submitted For</th>
                                                        <th style={{ minWidth: '145px' }} className='table_heading'>Report Type</th>
                                                        <th style={{ minWidth: '145px' }} className='table_heading'>Resolution Type</th>
                                                        <th style={{ minWidth: '220px' }} className='table_heading text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {errorReportList.data && errorReportList.data.results && errorReportList.data.results.length === 0 ? errorReportList.data.results.map((x, i) => {
                                                        return <tr key={i}>
                                                            <th scope="row">{i + 1}
                                                                <Button size='sm' className='ml-3' color='primary' onClick={() => handleDataView(x)} >
                                                                    <i className="fas fa-eye pt-1"></i>
                                                                </Button>
                                                            </th>
                                                            <td>{x.title}</td>
                                                            <td>{x?.tmdb_id}</td>
                                                            <td>{x.submitted_on}</td>
                                                            <td style={{ minWidth: '200px', wordBreak: 'break-all' }}>{x.description}</td>
                                                            <td>{x.username}</td>
                                                            <td>{x.device_type || 'N/A'}</td>
                                                            <td>{x.report_type || 'N/A'}</td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {x.resolution_type ?
                                                                    x.resolution_type == 'declined' ?
                                                                        <span className='text-danger'>declined</span>
                                                                        :
                                                                        <span className='text-success'>updated</span>
                                                                    : 'N/A'
                                                                }
                                                            </td>
                                                            <td className='text-center'>
                                                                <Button disabled={(x.resolution_type || x.resolution_type != null)} size='sm' color='success' onClick={() => handleReportError(x.uuid, 'updated')} >Update</Button>
                                                                <Button disabled={(x.resolution_type || x.resolution_type != null)} size='sm' color='danger' onClick={() => handleReportError(x.uuid, 'declined')} >Decline</Button>
                                                            </td>
                                                        </tr>
                                                            })
                                                    :
                                                        <tr>
                                                            <td colSpan={10}><h2 className='text-center'>No Data Available</h2></td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                            {errorReportList.data && errorReportList.data.pagination &&
                                                <CustomPagination
                                                    pagination={errorReportList.data && errorReportList.data.pagination && errorReportList.data.pagination ? !errorReportList.data.pagination : {}}
                                                    setCurrentPage={(val) => setCurrentPage(val)}
                                                    setPageLimit={(val) => setPageLimit(val)}
                                                    defaultPageList={errorReportList.data && errorReportList.data.results ? errorReportList.data.results.length : 20}
                                                />
                                            }
                                        </Container>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </div>
                </Container>
                <Modal
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                    size="lg"
                >
                    <ModalHeader className='p-3' toggle={() => setIsOpen(!isOpen)}>
                        <h3>Detailed Report - <span className='text-success'>{viewData?.title || ''}</span></h3>
                    </ModalHeader>

                    <div className='pb-3'>
                        <Row className='pl-3 mt-3 pr-3 w-100'>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Media Name</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.title || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>TMDB ID</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.tmdb_id || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Submitted On</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.submitted_on || 'N/A'}</h4>
                            </Col>
                            <Col sm={12} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Description</p>
                                <h4 style={{ wordBreak: 'break-all' }} className='text-capitalize p-0 m-0'>{viewData?.description || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>User</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.username || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Media Type</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.media_type || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Report Type</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.report_type || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Submitted For (Device)</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.device_type || 'N/A'}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Resolution Type</p>
                                <h4 className='text-capitalize p-0 m-0'>
                                    {viewData.resolution_type ?
                                        viewData.resolution_type == 'declined' ?
                                            <span className='text-danger'>declined</span>
                                            :
                                            <span className='text-success'>updated</span>
                                        : 'N/A'
                                    }
                                </h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Frenzi Points</p>
                                <h4 className='text-capitalize p-0 m-0'>{viewData?.frenzi_points || 0}</h4>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Action</p>
                                <Button disabled={(viewData?.resolution_type || viewData?.resolution_type != null)} size='sm' color='danger' onClick={() => handleReportError(viewData?.uuid, 'updated')} >Update</Button>
                            </Col>
                            <Col sm={4} className="pb-4">
                                <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Action</p>
                                <Button disabled={(viewData?.resolution_type || viewData?.resolution_type != null)} size='sm' color='success' onClick={() => handleReportError(viewData?.uuid, 'declined')} >Decline</Button>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        </>
    );
}
