import React from 'react'
import {
    Spinner
} from 'reactstrap';

export default function Loader() {
    return (
        <div style={{ position: 'absolute', right: 0, top: 0, left: 0, bottom: 0, background: 'rgba(255,255,255,0.7)', width: '100%', height: '100%', zIndex: 99999 }}>
            <div style={{ width: '100%', height: '100vh', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Spinner
                    color="success"
                    type="border"
                    style={{ alignSelf: 'center' }}
                >
                    Loading...
                </Spinner>
            </div>
        </div>
    )
}
