import React from 'react'
import {
    Input
} from 'reactstrap';

export default function CustomMyInput(props) {
    const [error, setError] = React.useState('');

    const handleOnChange = (e) => {
        props.onChange(e);
        checkError(e.target.value);
    }

    const handleOnBlur = (e) => {
        props.onBlur && props.onBlur(e);
        checkError(e.target.value);
    }

    const checkError = (val) => {
        if (props.required) {
            if (val && val != "") {
                setError('')
                handleOtherValidation(val)
            }
            else {
                setError("This Field Is Required.")
            }
        } else {
            if (val && val != "") {
                handleOtherValidation(val);
            }
        }
    }

    const handleOtherValidation = (val) => {
        if(props.validationType && props.validationType == 'price'){
            const pattern = /^\d*\.?(?:\d{1,3})?$/;
            if(!pattern.test(val)){
                setError("Enter a valid price format.")
            }else{
                setError('')
            }
        }

        if(props.validationType && props.validationType == 'number'){
            const pattern = /^[0-9]+$/;
            if(!pattern.test(val)){
                setError("Enter number only.")
            }else{
                setError('')
            }
        }
    }

    return (
        <div>
            <Input value={props.value} placeholder={props.placeholder} onChange={(e) => handleOnChange(e)} disabled={props.disabled} onBlur={(e) => handleOnBlur(e)} required={props.required} />
            {error && error != "" &&
                <span style={{ fontSize: '14px' }} className='text-danger'>{error}</span>
            }
        </div>
    )
}
