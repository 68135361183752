import axios from "axios";
import { User } from "../redux/constants/constants";
import store from "../redux/store";

const getToken = () => {
  var data = localStorage.getItem("frenzi_admin_access");
  var token = data && JSON.parse(data).access;
  return token;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: process.env.REACT_APP_STAGING_BASE_URL,
  // baseURL: 'https://vkaqft43bk.execute-api.ap-south-1.amazonaws.com/stage_server/frenzee/',
  headers: {
    Authorization: getToken() ? `Bearer ${getToken()}` : "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  const { auth } = store.getState();
  if (auth.token) {
    req.headers.Authorization = `Bearer ${auth.token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    // console.log(err.response);
    const { status } = err.response;
    if (status === 401 || status === 403) {
      localStorage.clear();
      store.dispatch({ type: User.LOGOUT_SUCCESS });
      window.location.href = "http://localhost:3000/login";
    }
    return Promise.reject(err.response);
  }
);

export default axiosInstance;
