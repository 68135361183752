import React from 'react'
import PropTypes from 'prop-types'
import helpers from '../../helpers/common';
import ReactPlayer from 'react-player'
import { Button } from 'reactstrap';

function MediaType(props) {
  const [currentPath, setCurrentPath] = React.useState('');
  const [currentLightPath, setCurrentLightPath] = React.useState('');

  React.useEffect(() => {
    if (props.src && props.src != "") {
      getPresignedUrl(props.src)
    }
  }, [props.src])

  React.useEffect(() => {
    if (props.light && props.light != "") {
      getPresignedUrlLight(props.light)
    }
  }, [props.light])

  const getPresignedUrlLight = async (path) => {
    if (path && path != "") {
      if (props.bucket && props.bucket == 'temp') {
        const url = await helpers.getTempPresignedUrl(path);
        if (url) {
          setCurrentLightPath(url)
        }
      } else {
        const url = await helpers.getPresignedUrl(path);
        if (url) {
          setCurrentLightPath(url)
        }
      }
    }
  }

  const getPresignedUrl = async (path) => {
    if (path && path != "") {
      if (props.bucket && props.bucket == 'temp') {
        const url = await helpers.getTempPresignedUrl(path);
        if (url) {
          setCurrentPath(url)
        }
      } else {
        const url = await helpers.getPresignedUrl(path);
        if (url) {
          setCurrentPath(url)
        }
      }
    }
  }

  const downloadFile = async (path, name = "untitled") => {
    if (path && path != "") {
      const url = await helpers.downloadFileFromBucket(path, name);
    }
  }


  return (
    <div>
      {
        props.type == "text" &&
        <h6 >
          {props.src}
        </h6>
      }
      {(props.type == "video" || props.type == "audio") &&
        <>
          {props.videoType && props.videoType == 'normal' ?
            <video style={{ maxWidth: 120, maxHeight: props.type == "audio" ? 50 : 100 }} controls={true} src={currentPath} />
            :
            <ReactPlayer style={{ maxWidth: 120, maxHeight: props.type == "audio" ? 50 : 100 }} controls={true} url={currentPath} light={currentLightPath} />
          }
        </>
      }
      {props.type == 'download' &&
        <Button
          className=" btn-icon btn-simple"
          color="info"
          size="sm"
          type="button"
          onClick={() => downloadFile(props.src, props.unique)}
        >
          <i class="fa fa-download" aria-hidden="true"></i>
        </Button>
      }
    </div>
  )
}

export default MediaType
