import Header from 'components/Headers/Header';
import CustomPagination from 'components/Pagination/CustomPagination';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Card, Col, Container, CustomInput, Modal, ModalBody, ModalHeader, Row, Spinner, Table } from 'reactstrap';
import { bulkOttDeeplinkUpload, clearMessage } from "../../redux/actions/media.action"
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import Papa from "papaparse";

const allowedExtensions = ["csv"];

export default function FreeDeeplinkUpload() {
    const [csvFile, setCsvFile] = React.useState("");
    const [csvData, setCsvData] = React.useState([]);
    const [csvColumn, setCsvColumn] = React.useState([]);
    const [viewModal, setViewModal] = React.useState(false);

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.media && state.media.isLoading ? state.media.isLoading : {})

    const errMesg = useSelector((state) => state.media && state.media.errMesg ? state.media.errMesg : "")
    const status = useSelector((state) => state.media && state.media.status ? state.media.status : "")
    const uploadResponse = useSelector((state) => state.media && state.media.uploadResponse ? state.media.uploadResponse : {})

    console.log("uploadResponse", uploadResponse)

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status]);

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg]);


    const handleParse = () => {
        if (!csvFile) {
            toast.error(("Enter a valid file"), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return true;
        }
        const reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            const columns = Object.keys(parsedData[0]);
            setCsvColumn(columns);
            setCsvData(parsedData);
        };
        reader.readAsText(csvFile);
    };

    const readCsvFile = (e) => {
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                toast.error(("Please input a csv file"), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            setCsvFile(inputFile);
        }
    }

    const handleUpload = () => {
        setViewModal(true);
    }


    return (
        <>
            <Header />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>
                <Card className='mt-4 p-3 pb-4'>
                    <h3 className='mb-3'>Free Bulk Deeplink Upload</h3>
                    <div>
                        <p>Choose CSV Deeplinks File</p>
                        <Row>
                            <Col xs={4}>
                                <CustomInput
                                    type='file'
                                    accept=".csv"
                                    onChange={(e) => readCsvFile(e)}
                                    invalid={csvFile && csvFile != "" ? false : true}
                                    valid={csvFile && csvFile != "" ? true : false}
                                />
                            </Col>
                            <Col xs={4}>
                                <Button onClick={() => handleParse()} disabled={csvFile && csvFile != "" ? false : true} color="info" >Parse</Button>
                            </Col>
                        </Row>
                        <Col className='text-right mt-2 w-100'>
                            <Button onClick={() => handleUpload()} disabled={csvData && csvData.length > 0 ? false : true} color="success" >Upload</Button>
                        </Col>
                        <Table hover className='mt-2'>
                            <thead>
                                <tr>
                                    <th className='table_heading' width="7%">
                                        S.No.
                                    </th>
                                    {csvColumn && csvColumn.length > 0 && csvColumn.map((x, i) => {
                                        return <th className='table_heading'>
                                            {x && x != "" ? x : `Unknown-${i + 1}`}
                                        </th>
                                    })}
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {csvData && csvData.length > 0 && csvData.map((x, i) => {
                                    return <tr>
                                        <td>{i + 1}</td>
                                        {csvColumn && csvColumn.length > 0 && csvColumn.map((y, j) => {
                                            return <td className='table_heading'>
                                                {x[y] && x[y] != "" ? x[y] : 'N/A'}
                                            </td>
                                        })}
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <Col className='text-right mt-2 w-100'>
                        <Button onClick={() => handleUpload()} disabled={csvData && csvData.length > 0 ? false : true} color="success" >Upload</Button>
                    </Col>
                </Card>
                <Modal
                    isOpen={viewModal}
                    toggle={() => setViewModal(!viewModal)}
                >
                    <ModalHeader className='pb-0' toggle={() => setViewModal(!viewModal)}>
                        Match column first
                    </ModalHeader>
                    <ModalBody className='pt-0' style={{ wordBreak: 'break-all' }}>
                        
                    </ModalBody>
                </Modal>
            </Container>
        </>
    )
}
