import React from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomPagination from 'components/Pagination/CustomPagination';
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../common/Loader'
import { getPlatformPriceList, getPlatformList, clearMessage, updatePlatformPrice, getPlatformPriceData, deletePlatformPrice, addPlatformPrice, getPlanTypes } from '../../redux/actions/platform.action';
import CustomMyInput from 'views/common/CustomMyInput';
import ReactGA from 'react-ga';
import AWS from 'aws-sdk'


export default function PlatformPriceManagment() {
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpen3, setIsOpen3] = React.useState(false);
    const [isCurrency, setIsCurrency] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isView, setIsView] = React.useState(false);

    // selected values
    const [selectedPlatformName, setSelectedPlatformName] = React.useState('');
    const [selectedPlatform, setSelectedPlatform] = React.useState('');
    const [planType, setPlanType] = React.useState('');
    const [currency, setCurrency] = React.useState('INR');
    const [price, setPrice] = React.useState('');
    const [discount, setDiscount] = React.useState('');
    const [order, setOrder] = React.useState(0);
    const [frenziPoints, setFrenziPoints] = React.useState('');
    const [isActive, setIsActive] = React.useState(false);
    const [isActiveFeatured, setIsActiveFeatured] = React.useState(false);
    const [featuredImage, setFeaturedImage] = React.useState('');

    const [platformPriceChangedDetailData, setPlatformPriceChangedDetailData] = React.useState({});



    const dispatch = useDispatch();

    const platfromPriceList = useSelector((state) => state.platform && state.platform.platformPriceListResult ? state.platform.platformPriceListResult : {})
    const platfromList = useSelector((state) => state.platform && state.platform.platformListResult ? state.platform.platformListResult : {})
    const platformPriceDetailDataResult = useSelector((state) => state.platform && state.platform.platformPriceDataResult ? state.platform.platformPriceDataResult : {})

    const platformPlanType = useSelector((state) => state.platform && state.platform.platformPlanTypeListResult ? state.platform.platformPlanTypeListResult : {})

    const message = useSelector((state) => state.platform && state.platform.message ? state.platform.message : false)
    const errMesg = useSelector((state) => state.platform && state.platform.errMesg ? state.platform.errMesg : false)
    const apiStatus = useSelector((state) => state.platform && state.platform.status ? state.platform.status : false)
    const isLoading = useSelector((state) => state.platform && state.platform.isLoading ? state.platform.isLoading : false)

    const viewData = platformPriceDetailDataResult.data && platformPriceDetailDataResult.data;


    React.useEffect(() => {
        dispatch(getPlatformPriceList(searchText, currentPage, pageLimit));
        dispatch(getPlatformList("", 1, "20"));
    }, [currentPage, pageLimit, message])

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(getPlatformPriceList(searchText, 1, pageLimit));
    }

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        dispatch(getPlanTypes());
    }, [])

    React.useEffect(() => {
        setPlatformPriceChangedDetailData(platformPriceDetailDataResult)
    }, [platformPriceDetailDataResult])


    React.useEffect(() => {
        if (message && message != "") {
            if (apiStatus) {
                toast.success(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    const getMessage = (value) => {
        return value.split('=>')[1];
    }

    const handlePlatformSelect = (name, val) => {
        if (!isEdit) {
            setSelectedPlatformName(name);
            setSelectedPlatform(val);
        } else {
            setSelectedPlatformName(name);
            handleEditDetailsChange(val, 'platform_id')
            handleEditDetailsChange(val, 'platform_name')
        }
    }

    const handleEdit = (id) => {
        dispatch(getPlatformPriceData(id));
        if (!isLoading) {
            setIsEdit(true);
            ReactGA.modalview(window.location.pathname + '?modal=Edit Platform Price')
        }
    }

    const handleView = (id) => {
        dispatch(getPlatformPriceData(id));
        if (!isLoading) {
            setIsView(true);
            ReactGA.modalview(window.location.pathname + '?modal=View Platform Price Details')
        }
    }

    const handleStatusChange = (e, id) => {
        const body = {
            is_active: e.target.checked
        }
        eventReporting('Subscription', 'Platform price status changed to  - ' + e.target.checked, id);
        dispatch(updatePlatformPrice(id, body));
    }

    const handleStatusChangeFeature = (e, data) => {
        let id = data.uuid;
        if (data.featured_image && data.featured_image != "") {
            const body = {
                is_featured: e.target.checked
            }
            eventReporting('Subscription', 'Platform price featured status changed to  - ' + e.target.checked, id);
            dispatch(updatePlatformPrice(id, body));
        } else {
            toastImageError();
            handleEdit(id);
        }
    }

    const toastImageError = (mesg = "") => {
        toast.error(mesg && mesg != "" ? mesg : "Featured Image can't be empty", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const handleEditDetailsChange = (val, name) => {
        var newData = {
            data: {
                ...platformPriceChangedDetailData.data,
                [name]: val
            }
        }
        setPlatformPriceChangedDetailData(newData);
    }

    React.useEffect(() => {
        if (platformPriceChangedDetailData.data && (!platformPriceChangedDetailData.data.featured_image || platformPriceChangedDetailData.data.featured_image == "")) {
            handleEditDetailsChange(false, 'is_featured');
        }
        return true;
    }, [platformPriceChangedDetailData?.data?.featured_image])

    const getPlanName = (type) => {
        var plan_types = platformPlanType.data && platformPlanType.data.plan_types;
        if (plan_types && plan_types.length > 0) {
            var result = plan_types.filter(item => item.plan_type == type);
        }
        return result && result[0] ? result[0].plan_name : 'N/A';
    }

    const handleEditSubmit = () => {
        if (platformPriceChangedDetailData && platformPriceChangedDetailData.data) {
            if (platformPriceChangedDetailData.data.is_featured && platformPriceChangedDetailData.data.featured_image == '') {
                toastImageError();
                return true;
            }
            eventReporting('Subscription', 'Platform price updated  - ', platformPriceChangedDetailData.data.uuid);
            dispatch(updatePlatformPrice(platformPriceChangedDetailData.data.uuid, { ...platformPriceChangedDetailData.data, order: parseInt(platformPriceChangedDetailData.data.order) || 0 }));
            setIsEdit(!isEdit);
        }
    }

    const handleFeaturedImageUpload = async (event, type = "") => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "platform/featured-image-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                if (type == 'edit') {
                    eventReporting('Collection', "Updated new featured image to s3 bucket -" + keyName, data.Location);
                    handleEditDetailsChange(data.Location, 'featured_image')
                } else {
                    eventReporting('Collection', "Uploaded featured image to s3 bucket -" + keyName, data.Location);
                    setFeaturedImage(data.Location);
                }
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleSave = () => {
        const body = {
            "platform": selectedPlatform,
            "price": price,
            "currency": currency,
            "discount": discount,
            "order": parseInt(order) || 0,
            "frenzi_points": frenziPoints,
            "plan_type": planType,
            "is_active": isActive,
            "is_featured": isActiveFeatured,
            "featured_image": featuredImage
        }
        if (isActiveFeatured && featuredImage == '') {
            toastImageError();
            return true;
        }
        if (selectedPlatform && price && currency && planType) {
            eventReporting('Subscription', 'Platform price added  - ' + price, selectedPlatform);
            dispatch(addPlatformPrice(body));
            if (!isLoading) {
                setIsAddPlatform(false);
            }
        } else {
            toastImageError('Please select required details');
        }
    }


    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <h3 className='mb-3'>Platform Price Management</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <Button color="info" onClick={() => {
                            setIsAddPlatform(!isAddPlatform);
                            ReactGA.modalview(window.location.pathname + '?modal=Add Platform Price')
                        }} >Add Platform Price</Button>
                    </Row>
                    <Container className='table'>
                        <Table hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th className='table_heading' width="7%">
                                        S. No.
                                    </th>
                                    <th className='table_heading'>
                                        Logo
                                    </th>
                                    <th className='table_heading'>
                                        Name
                                    </th>
                                    <th className='table_heading'>
                                        Plan Type
                                    </th>
                                    {/* <th className='table_heading'>
                                        Currency
                                    </th>
                                    <th className='table_heading'>
                                        Price
                                    </th> */}
                                    {/* <th className='table_heading'>
                                        Discount
                                    </th> */}
                                    {/* <th className='table_heading'>
                                        Frenzi Points
                                    </th> */}
                                    <th className='table_heading'>
                                        Status
                                    </th>
                                    <th className='table_heading'>
                                        Order
                                    </th>
                                    <th className='table_heading'>
                                        Is featured
                                    </th>
                                    <th className='table_heading'>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {platfromPriceList.data && platfromPriceList.data.results && platfromPriceList.data.results.length > 0 ? platfromPriceList.data.results.map((x, i) => {
                                    return <tr>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            <img className='platform-img' alt='N/A' src={x.platform_logo} />
                                        </td>
                                        <td>
                                            {x.platform_name ? x.platform_name : 'N/A'}
                                        </td>
                                        <td>
                                            {x.plan_type ? x.plan_type : 'N/A'}
                                        </td>
                                        {/* <td>
                                            {x.currency ? x.currency : 'N/A'}
                                        </td>
                                        <td>
                                            {x.price ? x.price : 'N/A'}
                                        </td> */}
                                        {/* <td>
                                            {x.discount ? x.discount : 'N/A'}
                                        </td> */}
                                        {/* <td>
                                            {x.frenzi_points ? x.frenzi_points : 'N/A'}
                                        </td> */}
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    checked={x.is_active ? x.is_active : false}
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    className=''
                                                    onChange={(e) => handleStatusChange(e, x.uuid)}
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>{x?.order}</td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    checked={x.is_featured ? x.is_featured : false}
                                                    type="switch"
                                                    id={`exampleCustomSwitchf-${i}`}
                                                    name="customSwitchf"
                                                    className=''
                                                    onChange={(e) => handleStatusChangeFeature(e, x)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ minWidth: 110 }}>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="success"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleView(x.uuid)}
                                                >
                                                    <i className="fas fa-eye pt-1"></i>
                                                </Button>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="info"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleEdit(x.uuid)}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure you want to delete this item ?")) {
                                                            eventReporting('Subscription', 'Platform price deleted  - ' + x.price, x.uuid);
                                                            dispatch(deletePlatformPrice(x.uuid))
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr>
                                        <td colSpan={10}><h2 className='text-center'>No Data Available</h2></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Container>
                    {platfromPriceList.data && platfromPriceList.data.pagination && (platfromPriceList.data.pagination.is_next_page || platfromPriceList.data.pagination.is_previous_page) &&
                        <CustomPagination
                            pagination={platfromPriceList.data && platfromPriceList.data.pagination && platfromPriceList.data.pagination ? platfromPriceList.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={platfromPriceList.data && platfromPriceList.data.results ? platfromPriceList.data.results.length : 20}
                        />
                    }
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            Add Platfrom Price
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-right pl-3 pr-3'>
                                <CustomInput
                                    type="switch"
                                    checked={isActive}
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className=''
                                    onChange={(e) => setIsActive(e.target.checked)}
                                />
                            </div>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Plan Type</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                {planType != "" ? getPlanName(planType) : 'Select Plan Type'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {platformPlanType && platformPlanType.data && platformPlanType.data.plan_types && platformPlanType.data.plan_types.length > 0 && platformPlanType.data.plan_types.map((y, j) => {
                                                    return <DropdownItem onClick={() => setPlanType(y.plan_type)}>
                                                        {y.plan_name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Currency</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isCurrency} toggle={() => setIsCurrency(!isCurrency)}>
                                            <DropdownToggle className='w-100' caret>
                                                {currency != "" ? currency : 'Select Currency'}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => setCurrency('INR')}>INR</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen3} toggle={() => setIsOpen3(!isOpen3)}>
                                            <DropdownToggle className='w-100' caret>
                                                {selectedPlatformName ? selectedPlatformName : 'Select Platform'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {platfromList.data && platfromList.data.results && platfromList.data.results.length > 0 && platfromList.data.results.map((y, j) => {
                                                    return <DropdownItem key={y.uuid} onClick={() => handlePlatformSelect(y.platform_name, y.uuid)}>
                                                        {y.platform_name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Price</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => setPrice(e.target.value)} required={true} validationType={'price'} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Discount</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => setDiscount(e.target.value)} required={true} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Order</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => setOrder(e.target.value, 'order')} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Frenzi Points</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => setFrenziPoints(e.target.value)} required={true} validationType={'number'} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Featured Image</p>
                                </Col>
                                <Col sm={7}>
                                    {featuredImage && featuredImage != "" ?
                                        <img className='platform-img' alt='N/A' src={featuredImage} />
                                        :
                                        <CustomInput
                                            type="file"
                                            id="exampleCustomSwitchimage"
                                            name="customSwitch"
                                            className=''
                                            onChange={(e) => handleFeaturedImageUpload(e)}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Is Featured</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomInput
                                        type="switch"
                                        checked={isActiveFeatured}
                                        id="exampleCustomSwitchfet"
                                        name="customSwitchfet"
                                        className=''
                                        onChange={(e) => {
                                            if (featuredImage && featuredImage != "") {
                                                setIsActiveFeatured(e.target.checked);
                                            } else {
                                                toastImageError();
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='text-center mb-3'>
                            <Button
                                color="info"
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isEdit}
                        toggle={() => setIsEdit(!isEdit)}
                    >
                        <ModalHeader toggle={() => setIsEdit(!isEdit)}>
                            Update Platform Price
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-right pl-3 pr-3'>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className=''
                                    checked={platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.is_active : false}
                                    onChange={(e) => handleEditDetailsChange(e.target.checked, 'is_active')}
                                />
                            </div>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Plan Type</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                {platformPriceChangedDetailData.data ? getPlanName(platformPriceChangedDetailData.data.plan_type) : 'Select Plan Type'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {platformPlanType && platformPlanType.data && platformPlanType.data.plan_types && platformPlanType.data.plan_types.length > 0 && platformPlanType.data.plan_types.map((y, j) => {
                                                    return <DropdownItem onClick={() => handleEditDetailsChange(y.plan_type, 'plan_type')}>
                                                        {y.plan_name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Currency</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isCurrency} toggle={() => setIsCurrency(!isCurrency)}>
                                            <DropdownToggle className='w-100' caret>
                                                {platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.currency : 'Select Currency'}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => handleEditDetailsChange('INR', 'currency')} >INR</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen3} toggle={() => setIsOpen3(!isOpen3)}>
                                            <DropdownToggle className='w-100' caret>
                                                {platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.platform_name : 'Select Platform'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {platfromList.data && platfromList.data.results && platfromList.data.results.length > 0 && platfromList.data.results.map((y, j) => {
                                                    return <DropdownItem key={y.uuid} onClick={() => handlePlatformSelect(y.platform_name, y.uuid)}>
                                                        {y.platform_name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Price</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => handleEditDetailsChange(e.target.value, 'price')} value={platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.price : 'N/A'} required={true} validationType={'price'} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Discount</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => handleEditDetailsChange(e.target.value, 'discount')} value={platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.discount : 'N/A'} required={true} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Order</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => handleEditDetailsChange(e.target.value, 'order')} value={platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.order : 0} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Frenzi Points</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomMyInput className='w-100' onChange={(e) => handleEditDetailsChange(e.target.value, 'frenzi_points')} value={platformPriceChangedDetailData.data ? platformPriceChangedDetailData.data.frenzi_points : 'N/A'} required={true} validationType={'number'} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Featured Image</p>
                                </Col>
                                <Col sm={7}>
                                    {platformPriceChangedDetailData.data && platformPriceChangedDetailData.data?.featured_image && platformPriceChangedDetailData.data?.featured_image != "" ?
                                        <div className='relative d-inline-block' style={{ position: 'relative' }}>
                                            <img className='platform-img' alt='N/A' src={platformPriceChangedDetailData.data?.featured_image} />
                                            <i title='Remove file'
                                                className='ni ni-fat-delete text-light pointer bg-danger'
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    right: '-5px',
                                                    borderRadius: '50%'
                                                }}
                                                onClick={() => {
                                                    handleEditDetailsChange('', 'featured_image')
                                                }}
                                            />
                                        </div>
                                        :
                                        <CustomInput
                                            type="file"
                                            id="exampleCustomSwitchimagefe"
                                            name="customSwitch"
                                            className=''
                                            onChange={(e) => handleFeaturedImageUpload(e, 'edit')}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Is Featured</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomInput
                                        type="switch"
                                        checked={platformPriceChangedDetailData.data && platformPriceChangedDetailData.data.is_featured ? platformPriceChangedDetailData.data.is_featured : false}
                                        id="exampleCustomSwitchfetredddd"
                                        name="customSwitchfet"
                                        className=''
                                        onChange={(e) => {
                                            if (platformPriceChangedDetailData.data?.featured_image && platformPriceChangedDetailData.data?.featured_image != "") {
                                                handleEditDetailsChange(e.target.checked, 'is_featured');
                                            } else {
                                                handleEditDetailsChange(false, 'is_featured');
                                                toastImageError();
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='text-center mb-3'>
                            <Button
                                color="info"
                                onClick={() => handleEditSubmit()}
                            >
                                Update
                            </Button>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={isView}
                        toggle={() => setIsView(!isView)}
                        size="lg"
                    >
                        <ModalHeader toggle={() => setIsView(!isView)}>
                            Platform Price Detail
                        </ModalHeader>
                        <ModalBody className='pt-0'>
                            <Row className='flex-wrap'>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Platform Logo</p>
                                    <img className='platform-img' alt='N/A' src={viewData?.platform_logo} />
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Platform Name</p>
                                    <h4 className='text-capitalize p-0 m-0'>{viewData?.platform_name}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Category Title</p>
                                    <h4 className='p-0 m-0'>{viewData?.category__title}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Plan Type</p>
                                    <h4 className='p-0 m-0'>{viewData?.plan_type}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Price</p>
                                    <h4 className='p-0 m-0'>{viewData?.price}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Currency</p>
                                    <h4 className='p-0 m-0'>{viewData?.currency}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Frenzi Points</p>
                                    <h4 className='p-0 m-0'>{viewData?.frenzi_points}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Discount</p>
                                    <h4 className='p-0 m-0'>{viewData?.discount}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Order</p>
                                    <h4 className='p-0 m-0'>{viewData?.order}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Is Active</p>
                                    <h4 className={`p-0 m-0 ${viewData?.is_active ? 'text-success' : 'text-danger'}`}>{viewData?.is_active ? 'True' : 'False'}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Is Featured</p>
                                    <h4 className={`p-0 m-0 ${viewData?.is_featured ? 'text-success' : 'text-danger'}`}>{viewData?.is_featured ? 'True' : 'False'}</h4>
                                </Col>
                                <Col sm={4} className="pb-4">
                                    <p className='text-capitalize fs-11 fw-500 p-0 m-0'>Featured Image</p>
                                    <img className='platform-img' alt='N/A' src={viewData?.featured_image} />
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
