import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    errMesg: "",
    status: "",
    req: {},
    newsArticleList: {},
    faqList: {},
    faqEditResult: {},
    tncResult: {},
    inBetweenFeed: {},
    inBetweenFeedType: {},

};


export const managePageReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case WEB.GET_NEWS_ARTICLE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_NEWS_ARTICLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                newsArticleList: payload,
            };
        case WEB.GET_NEWS_ARTICLE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_FAQ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                faqListResult: payload,
            };
        case WEB.GET_FAQ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_FAQ_EDIT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_FAQ_EDIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                faqEditResult: payload,
            };
        case WEB.GET_FAQ_EDIT_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_FAQ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'FAQ Updated',

            };
        case WEB.UPDATE_FAQ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.ADD_FAQ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'FAQ Added',

            };
        case WEB.ADD_FAQ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_TNC_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_TNC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                tncResult: payload,
            };
        case WEB.GET_TNC_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_TNC_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_TNC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Updated',
            };
        case WEB.UPDATE_TNC_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.DELETE_FAQ_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_FAQ_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'FAQ Deleted',
            };
        case WEB.DELETE_FAQ_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_INBETWEEN_FEEDS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_INBETWEEN_FEEDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                inBetweenFeed: payload,
            };
        case WEB.GET_INBETWEEN_FEEDS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_INBETWEEN_FEEDS_TYPE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_INBETWEEN_FEEDS_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                inBetweenFeedType: payload,
            };
        case WEB.GET_INBETWEEN_FEEDS_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_NEWS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_NEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'News Updated',
            };
        case WEB.UPDATE_NEWS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_NEWS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_NEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'News Added',
            };
        case WEB.ADD_NEWS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_FEED_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_FEED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Feed Added',
            };
        case WEB.ADD_FEED_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };


        case WEB.DELETE_NEWS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_NEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'News deleted',
            };
        case WEB.DELETE_NEWS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };


        case WEB.MANAGE_PAGE_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MANAGE_PAGE_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
            };



        default:
            return state;
    }
}