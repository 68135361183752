import Header from "components/Headers/Header";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Card, Container, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import WebStoriesContent from "./WebStoriesContent";
import CreateWebStories from "./CreateWebStories";
import EditWebStories from "./EditWebStories";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "redux/actions/blogs.action";
import { clearCreatedStoryData } from "redux/actions/blogs.action";

const TABS = {
    WEBSTORIES: "WEBSTORIES",
    REELS: "REELS",
    CREATE_WEBSTORIES: "CREATE_WEBSTORIES",
    CREATE_REELS: "CREATE_REELS",
    EDIT_WEBSTORIES: "EDIT_WEBSTORIES",
    EDIT_REELS: "EDIT_REELS",
};

export default function Webstories() {
    const [activeTab, setActiveTab] = useState(TABS.WEBSTORIES);
    const [tabName, setTabName] = useState("Edit");
    const [editId, setEditId] = useState("");
    const dispatch = useDispatch();

    const isLoading = useSelector((state) =>
        state.blogs && state.blogs.isLoading ? state.blogs.isLoading : false
    );
    const status = useSelector((state) =>
        state.blogs && state.blogs.status ? state.blogs.status : ""
    );
    const errMesg = useSelector((state) =>
        state.blogs && state.blogs.errMesg ? state.blogs.errMesg : ""
    );

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const handleTabs = (tab) => {
        setActiveTab(tab);
        dispatch(clearCreatedStoryData());
    };

    const handleEdit = (id) => {
        setEditId(id);
        setActiveTab(TABS.EDIT_WEBSTORIES);
        setTabName("Edit Webstory");
    };

    return (
        <>
            <Header />
            <ToastContainer />
            <div>
                <Container>
                    <Card className="mt-4 p-3 rounded-lg">
                        <div>
                            <Nav tabs>
                                <NavItem
                                    className={`pointer my-tab-link ${TABS.WEBSTORIES === activeTab ? "active" : ""
                                        } p-2`}
                                    onClick={() => handleTabs(TABS.WEBSTORIES)}
                                >
                                    Webstories
                                </NavItem>
                                <NavItem
                                    className={`pointer my-tab-link ${TABS.CREATE_WEBSTORIES === activeTab ? "active" : ""
                                        } p-2`}
                                    onClick={() => handleTabs(TABS.CREATE_WEBSTORIES)}
                                >
                                    Create new webstory
                                </NavItem>
                                {TABS.EDIT_WEBSTORIES === activeTab && (
                                    <NavItem
                                        className={`pointer my-tab-link ${TABS.EDIT_WEBSTORIES === activeTab ? "active" : ""
                                            } p-2`}
                                        onClick={() => handleTabs(TABS.EDIT_WEBSTORIES)}
                                    >
                                        {tabName}
                                    </NavItem>
                                )}
                            </Nav>
                            <TabContent className="pt-3" activeTab={activeTab}>
                                <TabPane tabId={TABS.WEBSTORIES}>
                                    <WebStoriesContent
                                        handleDone={() => setActiveTab(TABS.WEBSTORIES)}
                                        handleEdit={handleEdit}
                                        activeTab={activeTab}
                                    />
                                </TabPane>
                                <TabPane tabId={TABS.CREATE_WEBSTORIES}>
                                    <CreateWebStories
                                        tabId={
                                            TABS.CREATE_WEBSTORIES + parseInt(Math.random() * 100000)
                                        }
                                        handleDone={() => setActiveTab(TABS.WEBSTORIES)}
                                    />
                                </TabPane>
                                {TABS.EDIT_WEBSTORIES === activeTab && (
                                    <TabPane tabId={TABS.EDIT_WEBSTORIES}>
                                        <EditWebStories editId={editId}
                                            handleDone={() => setActiveTab(TABS.WEBSTORIES)}
                                        />
                                    </TabPane>
                                )}
                            </TabContent>
                        </div>
                    </Card>
                </Container>
            </div>
        </>
    );
}