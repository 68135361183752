import React from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Container, Nav, NavItem, NavLink, Button, Table, TabContent, TabPane, Card, CustomInput
} from 'reactstrap';
import './_advertisment.scss'

const list = [1, 2, 3]

export default function AdvertismentManagment() {
    const [activeTab, setActiveTab] = React.useState('roles')


    return (
        <>
            <Header />
            <Container>
                <div>
                    <Nav tabs className="custom-link" fluid>
                        <NavItem >
                            <NavLink
                                className={`nav_link${activeTab == 'roles' ? 'active' : ''}`}
                                onClick={() => setActiveTab('roles')}
                                style={{ cursor: 'pointer' }}
                            >
                                Advertisement list
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`nav_link${activeTab == 'admins' ? 'active' : ''}`}
                                onClick={() => setActiveTab('admins')}
                                style={{ cursor: 'pointer' }}
                            >
                                Advertisement state
                            </NavLink>
                        </NavItem>

                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="roles">
                            <Card className='mt-4 p-3'>
                                <Row className='adv-list-container'>
                                    <Col sm={4} className="p-0 search-icon-box">
                                        <h3 className='mb-3'> Advertisement list</h3>
                                    </Col>
                                    <div>
                                        <Button color="info" >Download CSV</Button>
                                        <Button color="info" >Add Advertisement</Button>
                                    </div>
                                </Row>
                                <div>
                                    <Table hover className='mt-3'>
                                        <thead>
                                            <tr>
                                                <th width="7%">
                                                    S.No.
                                                </th>
                                                <th>
                                                    Page Name
                                                </th>
                                                <th>

                                                    Section Name
                                                </th>
                                                <th>
                                                    Device
                                                </th>
                                                <th>
                                                    Server Type
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Advt. Location
                                                </th>
                                                <th>
                                                    Advt. Type
                                                </th>
                                                <th>
                                                    Is Skipable
                                                </th>
                                                <th>
                                                    `Skip Time (secs)`
                                                </th>
                                                <th>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((x, i) => {
                                                return <tr>
                                                    <th scope="row">
                                                        {i + 1}
                                                    </th>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        Banner
                                                    </td>
                                                    <td>
                                                        Video
                                                    </td>
                                                    <td>
                                                        False
                                                    </td>
                                                    <td>
                                                        8 sec
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="danger"
                                                            size="sm"
                                                            type="button"
                                                        >
                                                            <i className="fas fa-trash pt-1"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </TabPane>
                        <TabPane tabId="admins">
                            <Card className='mt-4 p-3'>
                                <Row className='adv-list-container'>
                                    <Col sm={4} className="p-0 search-icon-box">
                                        <h3 className='mb-3'>Advertisement state</h3>
                                    </Col>
                                    <div>
                                        <Button color="info" >Add Advertisement state</Button>
                                    </div>
                                </Row>
                                <div>
                                    <Table hover className='mt-3'>
                                        <thead>
                                            <tr>
                                                <th width="7%">
                                                    S.No.
                                                </th>
                                                <th>
                                                    Advertisement Type
                                                </th>
                                                <th>
                                                    Status
                                                </th>
                                                <th>
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((x, i) => {
                                                return <tr>
                                                    <th scope="row">
                                                        {i + 1}
                                                    </th>
                                                    <td>
                                                        feed_media_between_native
                                                    </td>
                                                    <td>
                                                        <div className='text-center'>
                                                            <CustomInput
                                                                type="switch"
                                                                id={`exampleCustomSwitch-${i}`}
                                                                name="customSwitch"
                                                                className='p-0'
                                                                label="In-Active"
                                                            // onChange={(e) => console.log(e.target.checked) }
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="danger"
                                                            size="sm"
                                                            type="button"
                                                        >
                                                            <i className="fas fa-trash pt-1"></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </>
    )
}
