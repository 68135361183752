import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { Card, Container, CardBody, Table } from "reactstrap";
import data from "./mock-data.json";
import "./student.scss";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import helpers from "../../helpers/common";
import { getStudentdata, getQRdata } from "redux/actions/students.action";
import { useDispatch, useSelector } from "react-redux";
import { updateQRdata } from "redux/actions/students.action";
import QrUpdation from "./QrUpdation";

export default function CollegeInteractions() {
  // const [students, setStudents] = useState(data);
  const [toggleState, settoggleState] = useState(1);

  const dispatch = useDispatch();

  const toggleTab = (index) => {
    settoggleState(index);
  };

  const studentList = useSelector((state) =>
    state.students && state.students.studentList
      ? state.students.studentList
      : {}
  );
  const qRList = useSelector((state) =>
    state.students && state.students.qRList ? state.students.qRList : {}
  );
  const QRDetails = useSelector((state) =>
    state.students && state.students.QRDetails ? state.students.QRDetails : {}
  );
  const [input, setUrl] = useState("");
  // console.log(studentList);
  // console.log("STU_LIST", studentList.data.results);
  // console.log("QR_LIST", qRList.data.results);
  // console.log(QRDetails)

  const defaultData = {
    id: "",
    original_url: "",
    shortened_url: "",
  };

  // get Studentdata
  React.useEffect(() => {
    getResults();
  }, []);

  const getResults = () => {
    dispatch(getStudentdata());
  };

  // get QR data
  React.useEffect(() => {
    getQResults();
  }, []);

  const getQResults = () => {
    dispatch(getQRdata());
  };

  // update QR Datas

  // React.useEffect(()=>
  // {
  //   updateQRDetails();
  // },[])

  function handleInput(event) {
    console.log(event.target.value);
    setUrl({ input: event.target.value });
  }

  function updateQRDetails(id, original_url) {
    const body = {
      id: id,
      original_url: original_url,
    };
    console.log(id, original_url);

    dispatch(updateQRdata(body));
  }

  async function downloadFileAtURL(urls, name) {
    let blobs = [];

    var extension = "";
    console.log(urls);

    //Get image data
    const zip = new JSZip();
    var img = zip.folder("images");

    for (let i = 0; i < urls.length; i++) {
      extension = urls[i].file_link.substring(
        urls[i].file_link.lastIndexOf(".")
      );

      console.log(extension);

      const url = await helpers.getPresignedUrl(urls[i].file_link);
      img.file(
        "image" + i + extension,
        new File(
          [await fetch(url).then((response) => response.blob())],
          "filename" + i + extension
        ),
        { base64: true }
      );
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, name + ".zip");
    });
  }
  function downloadZip(file) {
    const a = document.createElement(a);
    a.download = "test.zip";
    const url = URL.createObjectURL(file);
    console.log(url);
    a.href = url;

    a.style.display = "none";
    document.body.appendChild(a);

    a.click();
    a.remove();

    URL.revokeObjectURL(url);
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <div id="college">
        <Container>
          <Card className="mt-4 p-3 rounded-lg">
            <CardBody>
              <div className="student-list">
                <Nav tabs>
                  <NavItem
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                  >
                    Student - Listing
                  </NavItem>
                  <NavItem
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                  >
                    URL - Mapping
                  </NavItem>
                </Nav>

                <TabContent className="pt-3" activeTab={toggleState}>
                  <TabPane tabId={1}>
                    <Container className="student_table">
                      <Table>
                        <thead>
                          <tr>
                            <th className="Stu_table_heading">UUID</th>
                            <th className="Stu_table_heading">College ID</th>
                            <th className="Stu_table_heading">User Name</th>
                            <th className="Stu_table_heading">Email Address</th>
                            <th className="Stu_table_heading">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentList &&
                            studentList.data &&
                            studentList.data.results &&
                            studentList.data.results.length > 0 &&
                            studentList.data.results.map((x, i) => {
                              return (
                                <tr key={i + 1}>
                                  <td>{x.user}</td>
                                  <td>{x.college_id}</td>
                                  <td>{x.user__username}</td>
                                  <td>{x.user__email}</td>
                                  <td>
                                    <div>
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          downloadFileAtURL(
                                            x.file,
                                            x.user__username
                                          );
                                        }}
                                      >
                                        Download File
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Container>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Container className="student_table">
                      <Table>
                        <thead>
                          <tr>
                            <th className="Stu_table_heading">S.No.</th>
                            <th className="Stu_table_heading">QR Link</th>
                            <th className="Stu_table_heading">Redirect Link</th>
                          </tr>
                        </thead>
                        <tbody>
                          {qRList &&
                            qRList.data &&
                            qRList.data.results &&
                            qRList.data.results.length > 0 &&
                            qRList.data.results.map((x, i) => {
                              return (
                                <tr key={i + 1}>
                                  <td>{x.id}</td>
                                  <td>{x.shortened_url}</td>

                                  <td>
                                    <div>
                                      {/* <input placeholder="" defaultValue={x.original_url}
                                     onChange={handleInput}></input>
                                    <button type="submit" onClick={() =>{
                                     updateQRDetails(x.id,input.input)
                                    }}>Update</button> */}
                                      <QrUpdation
                                        defaultValue={x.original_url}
                                        onChange={handleInput}
                                        onClick={(e) =>
                                          updateQRDetails(x.id, input.input)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Container>
                  </TabPane>
                </TabContent>
                {/* <h3 variant={"button"}>Student - Listing</h3> */}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
}
