import { WEB } from "../constants/constants";

const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    errMesg: "",
    status: "",
    req: {},
    watchlistData: {},
    watchlistMemberData: {},
    watchlistMediaData: {}
};

export const watchlistReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.WATCHLIST_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WATCHLIST_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                message: "",
            };
        case WEB.WATCHLIST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WATCHLIST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                watchlistData: payload,
            };
        case WEB.WATCHLIST_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.CREATE_WATCHLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.CREATE_WATCHLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Watchlist Created',
            };
        case WEB.CREATE_WATCHLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.DELETE_WATCHLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_WATCHLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Watchlist deleted',
            };
        case WEB.DELETE_WATCHLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.UPDATE_WATCHLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_WATCHLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Watchlist updated',
            };
        case WEB.UPDATE_WATCHLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.WATCHLIST_MEMBER_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WATCHLIST_MEMBER_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                watchlistMemberData: payload,
            };
        case WEB.WATCHLIST_MEMBER_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.WATCHLIST_MEDIA_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.WATCHLIST_MEDIA_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                watchlistMediaData: payload,
            };
        case WEB.WATCHLIST_MEDIA_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_REMOVE_MEMBERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_REMOVE_MEMBERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Members updated',
            };
        case WEB.ADD_REMOVE_MEMBERS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_REMOVE_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_REMOVE_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                status: payload.message ? payload.message : 'Media updated',
            };
        case WEB.ADD_REMOVE_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        default:
            return state;
    }
};
