import { combineReducers } from "redux";
import { authReducer } from "./auth.reducer";
import { commonSearchReducer } from "./search.reducer";
import { platformReducer } from "./platform.reducer";
import { couponReducer } from "./coupon.reducer";
import { subscriptionReducer } from "./subscription.reducer";
import { commonReviewReducer } from "./review.reducer";
import { bundleReducer } from "./bundle.reducer";
import { mediaReducer } from "./media.reducer";
import { userReducer } from "./user.reducer";
import { categoriesReducer } from "./categories.reducer";
import { frenziPointReducer } from "./frenziPoint.reducer"
import { frenziKeysReducer } from "./frenziKeys.reducer"
import { watchlistReducer } from "./watchlist.reducer";
import { personReducer } from "./person.reducer";
import { operationReducer } from "./operation.reducer";
import { managePageReducer } from "./managePage.reducer"
import { adminReducer } from "./admin.reducer"
import { dynamicFeedReducer } from "./dynamicFeed.reducer";
import { pollsReducer } from "./polls.reducer";
import { discoveryReducer } from "./discovery.reducer";
import { blogsReducer } from "./blogs.reducer";
import { advertisementReducer } from "./advertisement.reducer";
import { studentReducer } from "./students.reducer";

const reducers = {
  auth: authReducer,
  commonSearch: commonSearchReducer,
  platform: platformReducer,
  coupon: couponReducer,
  subscription: subscriptionReducer,
  userReview: commonReviewReducer,
  bundle: bundleReducer,
  media: mediaReducer,
  user: userReducer,
  categories: categoriesReducer,
  frenziPoint: frenziPointReducer,
  frenziValues: frenziKeysReducer,
  watchlist: watchlistReducer,
  person: personReducer,
  operation: operationReducer,
  managePage: managePageReducer,
  adminAccess: adminReducer,
  dynamicFeed: dynamicFeedReducer,
  polls: pollsReducer,
  discovery: discoveryReducer,
  blogs: blogsReducer,
  advertisement: advertisementReducer,
  students: studentReducer
};

export default reducers;
