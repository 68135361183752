import Header from 'components/Headers/Header'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { Button, Card, Col, Container, Row, Table } from 'reactstrap'
import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import helpers from 'helpers/common';
import ReactGA from 'react-ga';
import AWS from 'aws-sdk'
import { getTrailerCsvFile } from 'redux/actions/media.action';

export default function ExportTrailer() {
    const dispatch = useDispatch()

    const [dateRangeState, setDateRangeState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const trailerExportData = useSelector((state) => state.media && state.media.trailerExportData ? state.media.trailerExportData : {})

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }


    const downloadCsvFile = () => {
        let startDate = dateRangeState[0].startDate;
        let endDate = dateRangeState[0].endDate;

        let stm = (startDate.getMonth() + 1) > 9 ? (startDate.getMonth() + 1) : "0" + (startDate.getMonth() + 1);
        let sd = startDate.getFullYear() + "-" + stm + "-" + (startDate.getDate() > 9 ? startDate.getDate() : "0" + startDate.getDate());

        let etm = (endDate.getMonth() + 1) > 9 ? (endDate.getMonth() + 1) : "0" + (endDate.getMonth() + 1);
        let ed = endDate.getFullYear() + "-" + etm + "-" + (endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate());
        let date = sd + "," + ed;
        dispatch(getTrailerCsvFile(date));
    }

    useEffect(() => {
        if (trailerExportData && trailerExportData.data) {
            let exportFileUrl = trailerExportData.data;
            setTimeout(async () => {
                let path = await helpers.getPresignedUrl(exportFileUrl);
                if (path) {
                    const aTag = document.createElement("a");
                    aTag.href = path;
                    aTag.target = '_blank';
                    document.body.appendChild(aTag);
                    aTag.click();
                    setTimeout(() => {
                        handleDeleteKey(exportFileUrl);
                    }, 3000)
                }
            }, [1000])
        }
    }, [trailerExportData])

    const getKeyFileName = (url) => {
        if (url.includes("?")) {
            if (!url.includes("amazonaws.com/frenzi-native/")) {
                return url.substring(
                    url.indexOf("amazonaws.com/") + 14,
                    url.lastIndexOf("?")
                );
            } else {
                return url.substring(
                    url.indexOf("frenzi-native/") + 14,
                    url.lastIndexOf("?")
                );
            }
        } else {
            var splitStr = url.substring(url.indexOf('amazonaws.com/') + 14);
            return splitStr;
        }
    }

    const handleDeleteKey = (file_path) => {
        let keyName = getKeyFileName(file_path);
        eventReporting('Exported Media csv ', 'Media csv deleted');

        var bucket = process.env.REACT_APP_S3_BUCKET_NAME;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Bucket: bucket,
        }
        s3.deleteObject(params, async function (err, data) {
            if (err) {
                console.log(err);
                return err;
            }
            if (data) {
                console.log("deleted-", JSON.stringify(data));
            }
        });
    }

    return (
        <>
            <Header />
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Container>
                <Card className='mt-4 p-3 pb-4'>
                    <h3 className='mb-3'>Export Trailer</h3>
                    <div className='bg-warning p-4'>
                        <Row>
                            <Col>
                                <DateRangePicker
                                    onChange={item => setDateRangeState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={dateRangeState}
                                    // minDate={addDays(new Date(dateRangeState[0].startDate), -60)}
                                    maxDate={addDays(new Date(dateRangeState[0].startDate), 180)}
                                    direction="horizontal"
                                />
                            </Col>
                            <Col className='text-right mt-2 w-100'>
                                <Button onClick={() => downloadCsvFile()} color="success" >Export</Button>
                            </Col>
                        </Row>
                    </div>

                </Card>
            </Container>
        </>
    )
}
