import React from 'react'
import Header from "components/Headers/Header.js";
import "./_frenziPoint.scss"
import { Container, Card, Row, Col, Label, CustomInput, Table, Input, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
// import CustomPagination from 'components/Pagination/CustomPagination';
import { useSelector, useDispatch } from 'react-redux'
import { frenziActivityList, updateActivityPoints, clearMessage } from '../../redux/actions/frenziPoint.action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

const list = [1, 2, 3, 4, 5, 6];


export default function PointsActivity() {
    const dispatch = useDispatch()

    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [editId, setEditId] = React.useState('');
    const [editPoints, setEditPoints] = React.useState('');
    const [activeStatus, setActiveStatus] = React.useState(false);

    const activityList = useSelector((state) => state.frenziPoint && state.frenziPoint.frenziActivityResult ? state.frenziPoint.frenziActivityResult : {})
    const isLoading = useSelector((state) => state.frenziPoint && state.frenziPoint.isLoading ? state.frenziPoint.isLoading : false)
    const status = useSelector((state) => state.frenziPoint && state.frenziPoint.status ? state.frenziPoint.status : '')
    const errMesg = useSelector((state) => state.frenziPoint && state.frenziPoint.errMesg ? state.frenziPoint.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        dispatch(frenziActivityList());
    }, [])

    const handleEdit = (data) => {
        setEditId(data.uuid);
        setEditPoints(data.points);
        setActiveStatus(data.is_active);
        setIsAddPlatform(!isAddPlatform);
    }

    const handleSave = () => {
        const body = {
            points: editPoints,
            is_active: activeStatus
        }
        eventReporting('Frenzi Points', 'Frenzi Points updated - ' + editPoints, editId);
        dispatch(updateActivityPoints(editId, body));
    }


    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(frenziActivityList());
            setIsAddPlatform(false);
            dispatch(clearMessage());
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    return (
        <>
            <Header />
            <ToastContainer />
            {isLoading && <Loader />}
            <Container>
                <Card className='mt-4 p-4 rounded-lg'>
                    <Row className='sub_heading'>
                        <Col>
                            <h3 className='mb-3'>Frenzi Activity List</h3>
                        </Col>
                    </Row>
                    <Table hover>
                        <thead>
                            <tr>
                                <th className='table_heading' width="15%">
                                    S. No.
                                </th>
                                <th className='table_heading'>
                                    Activity
                                </th>
                                <th className='table_heading'>
                                    Point
                                </th>
                                <th className='table_heading'>
                                    Status
                                </th>
                                <th className='table_heading'>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className='vertical-middle'>
                            {activityList.data && activityList.data.length > 0 ? activityList.data.map((x, i) => {
                                return <tr>
                                    <td scope="row">
                                        {i + 1}
                                    </td>
                                    <td>
                                        {x.activity}
                                    </td>
                                    <td>
                                        {x.points}
                                    </td>
                                    <td>
                                        {x.is_active ?

                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    const body = {
                                                        points: x.points,
                                                        is_active: !x.is_active
                                                    }
                                                    eventReporting('Frenzi Points', 'Frenzi Points status changed to - ' + !x.is_active, x.uuid);
                                                    dispatch(updateActivityPoints(x.uuid, body));
                                                }}
                                            >
                                                Activate
                                            </Button>
                                            :

                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    const body = {
                                                        points: x.points,
                                                        is_active: !x.is_active
                                                    }
                                                    eventReporting('Frenzi Points', 'Frenzi Points status changed to - ' + !x.is_active, x.uuid);
                                                    dispatch(updateActivityPoints(x.uuid, body));
                                                }}
                                            >
                                                Deactivate
                                            </Button>
                                        }

                                    </td>
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            onClick={() => handleEdit(x)}
                                        >
                                            <i className="fas fa-pen pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })

                                :
                                <tr>
                                    <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    {/* <div className='mt-4'>
                        <CustomPagination total={list.length} result={1} />
                    </div> */}
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            Edit Points
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    {/* <p className='mr-3 mb-0 v-middle'>Points</p> */}
                                    <Label for="exampleEmail" size="lg"><h3>Points</h3></Label>
                                </Col>
                                <Col sm={7}>
                                    <Input className='w-100'
                                        value={editPoints}
                                        onChange={(e) => setEditPoints(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <div className=' pl-3 pr-3 ml-3'>
                                    <Row  >
                                        <Label for="exampleEmail" size="lg"><h3>Status</h3></Label>
                                        <CustomInput
                                            // label="Exhausted"
                                            type="switch"
                                            id="exampleCustomSwitch"
                                            name="customSwitch"
                                            className='ml-8 mt-3 left'
                                            checked={activeStatus}
                                            onChange={(e) => setActiveStatus(e.target.checked)}
                                        />
                                    </Row>
                                </div>

                            </Row>

                        </ModalBody>
                        <div className='text-center mb-3'>
                            <Button
                                color="info"
                                onClick={() => handleSave()}
                            >
                                Save
                            </Button>
                            {/* <Button
                                color="danger"
                            >
                                Delete Point
                            </Button> */}
                        </div>
                    </Modal>

                </Card>
            </Container>
        </>
    )
}
