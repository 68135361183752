import React from 'react'
import Header from 'components/Headers/Header'
import { Container, Card, Row, Col, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput } from 'reactstrap'
import { Link } from 'react-router-dom';

const list = [
    {
        name: 'Feeds'
    },
    {
        name: 'Feeds Option 2'
    },
    {
        name: 'Feeds Option 3'
    }
]


const list2 = [
    {
        name: 'Text'
    },
    {
        name: 'Audio'
    },
    {
        name: 'Video'
    }
]
const list3 = [
    {
        name: 'Media'
    },
    {
        name: 'Trailer'
    },
    {
        name: 'News'
    }
]
const list4 = [
    {
        name: 'English'
    },
    {
        name: 'Hindi'
    },
    {
        name: 'Telugu'
    }
]

export default function AddInternalAds() {
    const [isOpen, setIsOpen] = React.useState(false)
    const [isOpen2, setIsOpen2] = React.useState(false)
    const [isOpen3, setIsOpen3] = React.useState(false)
    const [isOpen4, setIsOpen4] = React.useState(false)
    const [isEdit, setIsEdit] = React.useState(false)


    const inputFileRef = React.useRef(null);

    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>Add</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            {/* <Input placeholder='Search' />
                            <i className="fas fa-search"></i> */}
                        </Col>
                        <div>
                            <Link to="InternalAdsSection">
                                <Button color="darker" >
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            </Link>
                        </div>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Section Type</h3>
                        </Col>
                        <Col sm={3}>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                    <DropdownToggle className='w-100' caret>
                                        Section Type
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {list.map((y, j) => {
                                            return <DropdownItem>
                                                {y.name}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                        {/* <Col sm={7} style={{ textAlign: 'right' }}>
                            <Button color="info" >Add Feed Data</Button>
                        </Col> */}
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Poster</h3>
                        </Col>
                        <Col sm={1}>
                            <input ref={inputFileRef} className='d-none' type={'file'} />
                            <Button
                                className=" btn-icon btn-simple"
                                color="info"
                                size="sm"
                                type="button"
                                onClick={() => inputFileRef.current.click()}
                            >
                                <i className="fas fa-upload pt-1"></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Post Type</h3>
                        </Col>
                        <Col sm={3}>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isOpen2} toggle={() => setIsOpen2(!isOpen2)}>
                                    <DropdownToggle className='w-100' caret>
                                        Post Type
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {list2.map((y, j) => {
                                            return <DropdownItem>
                                                {y.name}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>

                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Caption</h3>
                        </Col>
                        <Col sm={7}>
                            <Input className='w-100' />
                        </Col>
                        <Col sm={3} >
                            <Row className='text-right pl-3 pr-3 justify-content-end' >
                                <span><h3>Status</h3></span>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className='ml-2'
                                />
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Description</h3>
                        </Col>
                        <Col sm={7}>
                            <textarea className='w-100' />
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Button Type</h3>
                        </Col>
                        <Col sm={3}>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isOpen3} toggle={() => setIsOpen3(!isOpen3)}>
                                    <DropdownToggle className='w-100' caret>
                                        Choose
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {list3.map((y, j) => {
                                            return <DropdownItem>
                                                {y.name}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Button Text</h3>
                        </Col>
                        <Col sm={7}>
                            <Input className='w-100' />
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Deep Link</h3>
                        </Col>
                        <Col sm={7}>
                            <Input className='w-100' />
                        </Col>
                    </Row>
                    <Row className='pl-3 pr-3 mt-3 w-100'>
                        <Col sm={2} className="p-0">
                            <h3 className='mr-3 mb-0 v-middle'>Set Prefrence Language</h3>
                        </Col>
                        <Col sm={3}>
                            <div className="d-flex m-100 justify-content-center">
                                <Dropdown className='w-100' isOpen={isOpen4} toggle={() => setIsOpen4(!isOpen4)}>
                                    <DropdownToggle className='w-100' caret>
                                        All
                                    </DropdownToggle>
                                    <DropdownMenu
                                    >
                                        {list4.map((y, j) => {
                                            return <DropdownItem>
                                                {y.name}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center mt-5 mb-3'>
                        <Link to="InternalAdsSection">
                            <Button color="danger" className='mr-3' >Cancel</Button>
                        </Link>
                        <Button color="success" >{isEdit ? 'Update' : 'Save'}</Button>
                    </Row>
                </Card>
            </Container>
        </>
    )
}
