import { User } from "../constants/constants";
import axiosInstance from "../../helpers/axios";
import ReactGA from 'react-ga';

export const login = (user, history) => {
  return (dispatch) => {
    dispatch({ type: User.LOGIN_REQUEST });

    axiosInstance
      .post(`main/adminlogin`, {
        ...user,
      })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          localStorage.setItem("frenzi_admin_access", JSON.stringify(payload));
          ReactGA.set({ userId: payload.uuid });

          ReactGA.event({
            category: 'Auth',
            action: 'User logged in',
            value: payload.uuid,
            label: payload.uuid
          });
          dispatch({
            type: User.LOGIN_SUCCESS,
            payload: {
              token: payload.access,
              data: payload,
            },
          });
          history.push(`/`);
        }
      })
      .catch((err) => {
        if (err.status > 200) {
          dispatch({
            type: User.LOGIN_FAILED,
            payload: {
              error: err && err.data && err.data.message ? err.data.message : 'Something went wrong!!!',
            },
          });
        }
      });
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    dispatch({
      type: User.LOGIN_REQUEST,
    });

    var data = localStorage.getItem("frenzi_admin_access");
    const token = data && JSON.parse(data).access;
    if (token) {
      // await getProfile();
      dispatch({
        type: User.LOGIN_SUCCESS,
        payload: {
          token,
        },
      });
    } else {
      dispatch({
        type: User.LOGIN_FAILED,
        payload: {
          error: "",
        },
      });
    }
  };
};

export const logout = (history) => {
  return (dispatch) => {
    dispatch({ type: User.LOGOUT_REQUEST });
    ReactGA.event({
      category: 'Auth',
      action: 'User logged out',
      value: 0,
      label: 0
    });

    localStorage.clear();
    deleteCookie('frenzi_admin_permissions');
    dispatch({ type: User.LOGOUT_SUCCESS });
    history.push("/");
  };
};

export const getProfile = () => {
  return (dispatch) => {
    dispatch({ type: User.PROFILE_REQUEST });

    axiosInstance
      .get(`main/profile`)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: User.PROFILE_SUCCESS,
            payload: {
              user: payload,
            },
          });
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: User.PROFILE_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};


const deleteCookie = (name) => {
  const d = new Date();
  d.setTime(d.getTime() + (0 * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = name + "=;" + expires + ";path=/";
}


export const clearMessage = (clear = true) => {
  return (dispatch) => {
    dispatch({ type: User.AUTH_CLEAR_MESSAGE_REQUEST });
    if (clear) {
      dispatch({ type: User.AUTH_CLEAR_MESSAGE_SUCCESS });
    }
  }
}


export const forgotPassword = (body) => {
  return (dispatch) => {
    dispatch({ type: User.FORGOT_PASSWORD_REQUEST });

    axiosInstance
      .post(`main/forgotpasswordotp`, body)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: User.FORGOT_PASSWORD_SUCCESS,
            payload: payload,
          });
        }
      })
      .catch((err) => {
        if (err.status === 400 || err.status === 404 || err.status === 500) {
          dispatch({
            type: User.FORGOT_PASSWORD_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};


export const otpValidation = (body) => {
  return (dispatch) => {
    dispatch({ type: User.OTP_VALIDATION_REQUEST });

    axiosInstance
      .post(`main/otpvalidation`, body)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: User.OTP_VALIDATION_SUCCESS,
            payload: payload,
          });
        }
      })
      .catch((err) => {
        if (err.status === 400 || err.status === 404 || err.status === 500) {
          dispatch({
            type: User.OTP_VALIDATION_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};


export const passwordMatch = (body) => {
  return (dispatch) => {
    dispatch({ type: User.PASSWORD_MATCH_REQUEST });

    axiosInstance
      .post(`native_main/passwordmatch`, body)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: User.PASSWORD_MATCH_SUCCESS,
            payload: payload,
          });
        }
      })
      .catch((err) => {
        if (err.status === 400 || err.status === 404 || err.status === 500) {
          dispatch({
            type: User.PASSWORD_MATCH_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      });
  };
};