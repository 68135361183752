import React from 'react'
import Header from "components/Headers/Header.js";
import { Row, Col, Modal, ModalHeader, Container, Table, CustomInput, Button, Card } from 'reactstrap';


const list = [
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/altbalaji.png",
        name: "Ramesh",
        watchlist: "Anaconda",
        contactNo: "9987654321"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/amazo_pay.png",
        name: "Suresh",
        watchlist: "Billo Barber",
        contactNo: "9987654321"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Harish",
        watchlist: "de Dana Dan",
        contactNo: "9987654321"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Shubhash",
        watchlist: "Godzilla",
        contactNo: "9987654321"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Hari",
        watchlist: "Hum Tum",
        contactNo: "9987654321"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Satish",
        watchlist: "Baazigar",
        contactNo: "9987654321"
    }

]

export default function UserContact() {
    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>User Contacts</h3>
                    <div className='overflow-auto'>
                        <Table hover className='mt-3'>
                            <thead>
                                <tr>
                                    <th width="7%">
                                        S.No.
                                    </th>
                                    <th>
                                        User Name/@Unique Name
                                    </th>
                                    <th>
                                        Contact Name
                                    </th>
                                    <th>
                                        Contact Number
                                    </th>
                                    <th>
                                        Contact Image
                                    </th>
                                    <th>
                                        On Frenzi
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((x, i) => {
                                    return <tr>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <td>
                                            {x.name}
                                        </td>
                                        <td>
                                            {x.watchlist}
                                        </td>
                                        <td>
                                            {x.contactNo}

                                        </td>
                                        <td>
                                            <img className='platform-img' src={x.image} />
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    className=''
                                                // onChange={(e) => console.log(e.target.checked) }
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                // onClick={() => handleEdit(i)}
                                            >
                                                <i className="fas fa-pen pt-1"></i>
                                            </Button>

                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Card>
            </Container>
        </>
    )
}
