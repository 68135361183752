import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getMediaNews, updateMediaNews, deleteMediaNews, clearMessage } from "../../redux/actions/managePage.action"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

import CustomPagination from 'components/Pagination/CustomPagination';

const list = [
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/altbalaji.png",
        name: "Puneeth Rajkumar Passes Away due to Heart Attack",
        desc: "Kannada superstar Puneeth Rajkumar passed away due to a fatal cardiac arrest on Friday, the 29th of October"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/amazo_pay.png",
        name: "Sustainable Planet Stories",
        desc: "Together for Our Planet: A New Collection of Sustainability Stories on Netflix"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Naveen Kumar",
        desc: "Engage & Win Contest Winner 14/07/2021"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Dhananjay Kumar Sinha",
        desc: "Kannada superstar Puneeth Rajkumar passed away due to a fatal cardiac arrest on Friday, the 29th of October"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Nikhil Singla",
        desc: "Engage & Win Contest Winner 14/07/2021"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Rohit Nigam",
        desc: "Together for Our Planet: A New Collection of Sustainability Stories on Netflix"
    }

]


export default function MediaNews() {
    const dispatch = useDispatch();

    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [searchText, setSearchText] = React.useState('');

    const inputFileRef = React.useRef(null);

    React.useEffect(() => {
        dispatch(getMediaNews(searchText,));
    }, [searchText,])

    const newsArticleList = useSelector((state) => state.managePage && state.managePage.newsArticleList ? state.managePage.newsArticleList : {})
    const status = useSelector((state) => state.managePage && state.managePage.status ? state.managePage.status : '')
    const errMesg = useSelector((state) => state.managePage && state.managePage.errMesg ? state.managePage.errMesg : '')
    const isLoading = useSelector((state) => state.managePage && state.managePage.isLoading ? state.managePage.isLoading : false)

    // console.log(newsArticleList, "fnjfniewnc8u")

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const handleStatusChange = (e, id) => {
        const body = {
            is_active: e.target.checked
        }
        ReactGA.event({
            category: 'Manage Page',
            action: 'News status changed to - ' + e.target.checked,
            value: id,
            label: id
        });
        dispatch(updateMediaNews(id, body))
    }

    const handleStatusChangeBlog = (e, id) => {
        const body = {
            is_blog: e.target.checked
        }
        ReactGA.event({
            category: 'Manage Page',
            action: 'News status changed for blog to - ' + e.target.checked,
            value: id,
            label: id
        });
        dispatch(updateMediaNews(id, body))
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this news ?")) {
            ReactGA.event({
                category: 'Manage Page',
                action: 'News deleted',
                value: id,
                label: id
            });
            dispatch(deleteMediaNews(id));
            if (!isLoading) {
                dispatch(getMediaNews(searchText));
            }
        }
    }


    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(getMediaNews());
            dispatch(clearMessage());
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    return (
        <>
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>News</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            {/* <Input placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} /> */}
                            {/* <i className="fas fa-search" ></i> */}
                        </Col>
                        <div>
                            <Link to="/admin/media_news_add">
                                <Button color="info" >Add News</Button>
                            </Link>
                        </div>
                    </Row>
                    <Container className='table mt-4'>
                        <Table hover className="align-items-center table-flush">
                            <thead>
                                <tr>
                                    <th width="9%">
                                        S. No.
                                    </th>
                                    <th >
                                        Title
                                    </th>
                                    <th >
                                        Description
                                    </th>
                                    <th>
                                        Is Blog
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {newsArticleList.data && newsArticleList.data.length > 0 ? newsArticleList.data.map((x, i) => {
                                    return <tr>
                                        <td scope="row">
                                            {i + 1}
                                        </td>
                                        <td>
                                            {x.news_title}
                                        </td>
                                        <td>
                                            {x.news_summary}
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    checked={x.is_blog ? x.is_blog : false}
                                                    type="switch"
                                                    id={`exampleCustomSwitchBlog-${i}`}
                                                    name="customSwitchBlog"
                                                    // className='p-0'
                                                    // label="In-Active"
                                                    onChange={(e) => handleStatusChangeBlog(e, x.uuid)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='text-center'>
                                                <CustomInput
                                                    checked={x.is_active ? x.is_active : false}
                                                    type="switch"
                                                    id={`exampleCustomSwitch-${i}`}
                                                    name="customSwitch"
                                                    // className='p-0'
                                                    // label="In-Active"
                                                    onChange={(e) => handleStatusChange(e, x.uuid)}
                                                />
                                            </div>
                                        </td>
                                        <td width="15%">
                                            <Link to={`/admin/media_news_edit/${x.uuid}`}>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="info"
                                                    size="sm"
                                                    type="button"
                                                    style={{ marginRight: 10 }}
                                                // onClick={() => handleEdit(i)}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>
                                            </Link>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => handleDelete(x.uuid)}

                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr>
                                        <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Container>
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            Add Coupon
                        </ModalHeader>
                        <ModalBody>
                            <div className='text-right pl-3 pr-3'>
                                <CustomInput
                                    type="switch"
                                    id="exampleCustomSwitch"
                                    name="customSwitch"
                                    className=''
                                // onChange={(e) => console.log(e.target.checked) }
                                />
                            </div>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Coupon code</p>
                                </Col>
                                <Col sm={7}>
                                    <Input className='w-100' />
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Plan Type</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                Monthly
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {list.map((y, j) => {
                                                    return <DropdownItem>
                                                        {y.name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Price</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                Select Platform Price
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {list.map((y, j) => {
                                                    return <DropdownItem>
                                                        {y.name}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Expiry Date</p>
                                </Col>
                                <Col sm={7}>
                                    <Input
                                        id="exampleDate"
                                        name="date"
                                        placeholder="date placeholder"
                                        type="date"
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                onClick={() => setIsAddPlatform(!isAddPlatform)}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Container >
        </>
    )
}
