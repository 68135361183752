import React, { Component, useEffect } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Spinner, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import './_subscriptions.scss';
import CustomPagination from 'components/Pagination/CustomPagination';
import { useSelector, useDispatch } from 'react-redux'
import { getPlatformList, getPlatformData, addPlatform, clearMessage, deletePlatform, updatePlatform } from '../../redux/actions/platform.action';
import { getSubscriptionCategoryList } from '../../redux/actions/subscription.action';
import Loader from '../common/Loader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from 'aws-sdk'
import CustomMyInput from 'views/common/CustomMyInput';
import ReactGA from 'react-ga';
import { getProvidersDropdown } from 'redux/actions/media.action';
import Select, { components } from 'react-select';

AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
})

export default function PlatformMangement() {
    const [isEdit, setIsEdit] = React.useState(false);
    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [platformDetailData, setPlatformDetailData] = React.useState({});
    const [isImageUploading, setIsImageUploading] = React.useState(false);

    // value to update
    const [platformName, setPlatformName] = React.useState('');
    const [platformLink, setPlatformLink] = React.useState('');
    const [componentName, setComponentName] = React.useState('');
    const [platformDesc, setPlatformDesc] = React.useState('');
    const [platformCategory, setPlatformCategory] = React.useState('');
    const [platformCategoryName, setPlatformCategoryName] = React.useState('');
    const [platformLogo, setPlatformLogo] = React.useState('');
    const [providersSearchText, setProvidersSearchText] = React.useState('');
    const [selectedOption, setSelectedOption] = React.useState({});


    const inputFileRef = React.useRef(null);
    const inputFileRef2 = React.useRef(null);
    const dispatch = useDispatch();
    const platfromList = useSelector((state) => state.platform && state.platform.platformListResult ? state.platform.platformListResult : {})
    const platformDetailDataResult = useSelector((state) => state.platform && state.platform.platformDataResult ? state.platform.platformDataResult : {})
    const subscriptionCategoryList = useSelector((state) => state.subscription && state.subscription.subscriptionCategoryListResult ? state.subscription.subscriptionCategoryListResult : {})
    const isLoading = useSelector((state) => state.platform && state.platform.isLoading ? state.platform.isLoading : false)
    const message = useSelector((state) => state.platform && state.platform.message ? state.platform.message : false)
    const errMesg = useSelector((state) => state.platform && state.platform.errMesg ? state.platform.errMesg : false)
    const apiStatus = useSelector((state) => state.platform && state.platform.status ? state.platform.status : false)

    const providersListData = useSelector((state) => state.media && state.media.providersListData ? state.media.providersListData : false)


    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    useEffect(() => {
        dispatch(getProvidersDropdown(providersSearchText, 1, 20));
    }, [providersSearchText])

    React.useEffect(() => {
        dispatch(getPlatformList(searchText, currentPage, pageLimit));
    }, [currentPage, pageLimit, message])

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(getPlatformList(searchText, 1, pageLimit));
    }

    React.useEffect(() => {
        dispatch(getSubscriptionCategoryList('', 'all'));
    }, [])

    React.useEffect(() => {
        setPlatformDetailData(platformDetailDataResult)
    }, [platformDetailDataResult])

    React.useEffect(() => {
        if (message && message != "") {
            if (apiStatus) {
                toast.success(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error(getMessage(message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(clearMessage());
        }
    }, [message])

    React.useEffect(() => {
        if (errMesg && errMesg != "") {
            toast.error(errMesg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg])

    const getMessage = (value) => {
        return value.split('=>')[1];
    }

    const handleEdit = (id) => {
        dispatch(getPlatformData(id));
        setSelectedOption({});
        setProvidersSearchText('');
        if (!isLoading) {
            setIsEdit(true);
        }
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this item ?")) {
            eventReporting('Subscription', 'Platform deleted  - ', id);
            dispatch(deletePlatform(id));
            if (!isLoading) {
                dispatch(getPlatformList(searchText, currentPage, pageLimit));
            }
        }
    }

    const handleDropDownClick = (id, name) => {
        if (!isEdit) {
            setPlatformCategory(id)
            setPlatformCategoryName(name)
        } else {
            setPlatformCategoryName(name)
            handleEditFormDetails(id, "category")
        }
    }

    const handleFileChange = async (e) => {
        if (e.target && e.target.files) {
            setIsImageUploading(true);
            var file = e.target.files[0];
            var timeStamp = new Date().getTime();
            var randomNo = parseInt(Math.random() * 100000);
            var key = 'Subscription_uploads/platforms/';
            var keyName = key + timeStamp + "-" + randomNo + "-" + file.name;
            const s3 = new AWS.S3({
                params: { Bucket: process.env.REACT_APP_S3_TEMP_BUCKET_NAME },
                region: process.env.REACT_APP_AWS_REGION,
            })
            const params = {
                Key: keyName,
                Body: file,
            }
            s3.upload(params, async function (err, data) {
                if (err) {
                    throw err;
                }
                if (data && data.Location) {
                    setPlatformLogo(data.Location);
                    if (isEdit) {
                        handleEditFormDetails(data.Location, "platform_logo")
                    }
                    setIsImageUploading(false);
                }
            });
        }
    }
    console.log(handleFileChange, "file data")

    const handleAddPlatform = () => {
        if (platformName != "" && platformLogo != "" && platformLink != "" && platformCategory != "") {
            var body = {
                "platform_name": platformName,
                "platform_logo": platformLogo,
                "platform_link": platformLink,
                "component_name": componentName,
                "platform_description": platformDesc,
                "category": platformCategory,
                "ott_platform": selectedOption.value || ''
            }
            eventReporting('Subscription', 'Platform added  - ', platformName);
            dispatch(addPlatform(body));
            if (!isLoading) {
                setIsAddPlatform(!isAddPlatform);
                dispatch(getPlatformList(searchText, currentPage, pageLimit));
            }
        }
    }

    const handleEditFormDetails = (val, name) => {
        var newData = {
            data: {
                ...platformDetailData.data,
                [name]: val
            }
        }
        setPlatformDetailData(newData);
    }

    const handleEditPlatform = () => {
        if (platformDetailData && platformDetailData.data) {
            eventReporting('Subscription', 'Platform updated  - ', platformDetailData.data.uuid);
            dispatch(updatePlatform(platformDetailData.data.uuid, platformDetailData.data));
            setIsEdit(!isEdit);
        }
    }

    const getSelectOptions = (resp) => {
        let arr = resp.data && resp.data.results ? resp.data.results : [];
        var temp = [];
        arr.length > 0 && arr.map((x, i) => {
            if (x.is_active) {
                temp.push({
                    label: x.platform_name || x.label,
                    value: x.uuid || x.value,
                });
            }
        })
        return temp;
    }

    const handleMultiSelectChange = (e, edit = false) => {
        setSelectedOption(e);
        if (edit) {
            handleEditFormDetails(e.value, 'ott_platform');
        }
    }


    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    {isLoading &&
                        <Loader />
                    }
                    <ToastContainer
                        position="bottom-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <h3 className='mb-3'>Platform Management</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }} />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <Button color="info" onClick={() => {
                            setIsAddPlatform(!isAddPlatform);
                            setSelectedOption({});
                            setProvidersSearchText('');
                            ReactGA.modalview(window.location.pathname + '?modal=Add Platform')
                        }} >Add Platform</Button>
                    </Row>
                    <Table hover className='mt-3'>
                        <thead>
                            <tr>
                                <th width="7%">
                                    S. No.
                                </th>
                                <th>
                                    Platform Logo
                                </th>
                                <th>
                                    Platform Name
                                </th>
                                <th>
                                    OTT Platform Name
                                </th>
                                {/* <th>
                                    Description
                                </th> */}
                                <th>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {platfromList.data && platfromList.data.results && platfromList.data.results.length > 0 ? platfromList.data.results.map((x, i) => {
                                return <tr key={x.uuid}>
                                    <th scope="row">
                                        {i + 1}
                                    </th>
                                    <td>
                                        <img alt='N/A' className='platform-img' src={x.platform_logo ? x.platform_logo : ''} />
                                    </td>
                                    <td>
                                        {x.platform_name ? x.platform_name : 'N/A'}
                                    </td>
                                    <td>
                                        {x.ott_platform__platform_name ? x.ott_platform__platform_name : 'N/A'}
                                    </td>
                                    {/* <td>
                                        {x.platform_description ? x.platform_description : 'N/A'}
                                    </td> */}
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                handleEdit(x.uuid);
                                                ReactGA.modalview(window.location.pathname + '?modal=Edit Platform');
                                            }}
                                        >
                                            <i className="fas fa-pen pt-1"></i>
                                        </Button>

                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            onClick={() => handleDelete(x.uuid)}

                                        >
                                            <i className="fas fa-trash pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })
                                :
                                <tr>
                                    <td colSpan={4}><h2 className='text-center'>No Data Available</h2></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    {platfromList.data && platfromList.data.pagination && (platfromList.data.pagination.is_next_page || platfromList.data.pagination.is_previous_page) &&
                        <CustomPagination
                            pagination={platfromList.data && platfromList.data.pagination && platfromList.data.pagination ? platfromList.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={platfromList.data && platfromList.data.results ? platfromList.data.results.length : 20}
                        />
                    }
                    <Modal
                        isOpen={isEdit}
                        toggle={() => setIsEdit(!isEdit)}
                    >
                        <ModalHeader toggle={() => setIsEdit(!isEdit)}>
                            Edit Platform
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Name</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Name" value={platformDetailData.data && platformDetailData.data.platform_name ? platformDetailData.data.platform_name : ''} onChange={(e) => handleEditFormDetails(e.target.value, "platform_name")} required={true} />
                                        {platformDetailData.data && platformDetailData.data.platform_name && platformDetailData.data.platform_name == "" && alert('ssss')}
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Link</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Link" value={platformDetailData.data && platformDetailData.data.platform_link ? platformDetailData.data.platform_link : ''} onChange={(e) => handleEditFormDetails(e.target.value, "platform_link")} required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Component Name</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Component Name" value={platformDetailData.data && platformDetailData.data.component_name ? platformDetailData.data.component_name : ''} onChange={(e) => handleEditFormDetails(e.target.value, "component_name")} required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Ott Platform</p>
                                </Col>
                                <Col sm={7}>
                                    <Select
                                        value={selectedOption}
                                        isSearchable={true}
                                        className='w-100'
                                        components={components}
                                        onInputChange={(val) => setProvidersSearchText(val)}
                                        onChange={(e) => handleMultiSelectChange(e, true)}
                                        options={getSelectOptions(providersListData)}
                                    />
                                </Col>
                            </Row>

                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Description</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Description" value={platformDetailData.data && platformDetailData.data.platform_description ? platformDetailData.data.platform_description : ''} onChange={(e) => handleEditFormDetails(e.target.value, "platform_description")} required={false} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Category</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                {platformDetailData.data && subscriptionCategoryList.data && subscriptionCategoryList.data.data && subscriptionCategoryList.data.data.length > 0 ? subscriptionCategoryList.data.data.find(e => e.uuid === platformDetailData.data.category).title : 'Select Category'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {subscriptionCategoryList.data && subscriptionCategoryList.data.data && subscriptionCategoryList.data.data.length > 0 && subscriptionCategoryList.data.data.map((y, j) => {
                                                    return <DropdownItem className={`${y.uuid == platformDetailData.category && 'active'}`} onClick={() => handleDropDownClick(y.uuid, y.title)}>
                                                        {y.title}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0'>Platform Logo</p>
                                </Col>
                                <Col sm={3}>
                                    <input ref={inputFileRef2} onChange={(e) => handleFileChange(e)} className='d-none' type={'file'} />
                                    <Button
                                        className=" btn-icon btn-simple"
                                        color="info"
                                        size="sm"
                                        type="button"
                                        onClick={() => inputFileRef2.current.click()}
                                    >
                                        <i className="fas fa-upload pt-1"></i>
                                    </Button>
                                </Col>
                                <Col sm={3}>
                                    {!isImageUploading ?
                                        platformDetailData.data && platformDetailData.data.platform_logo &&
                                        <img className='platform-img' alt="N/A" src={platformDetailData.data && platformDetailData.data.platform_logo ? platformDetailData.data.platform_logo : ''} />
                                        :
                                        <div className='relative'>
                                            <Spinner
                                                color="success"
                                                type="border"
                                                style={{ alignSelf: 'center' }}
                                            >
                                                Loading...
                                            </Spinner>
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={() => handleEditPlatform()}
                            >
                                Update
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            Add Platform
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Name</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Name" onChange={(e) => setPlatformName(e.target.value)} required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Link</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Link" onChange={(e) => setPlatformLink(e.target.value)} required={true} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Component Name</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Component Name" onChange={(e) => setComponentName(e.target.value)} required={true} />
                                    </div>
                                </Col>
                            </Row>

                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Ott Platform</p>
                                </Col>
                                <Col sm={7}>
                                    <Select
                                        value={selectedOption}
                                        isSearchable={true}
                                        className='w-100'
                                        components={components}
                                        onInputChange={(val) => setProvidersSearchText(val)}
                                        onChange={(e) => handleMultiSelectChange(e)}
                                        options={getSelectOptions(providersListData)}
                                    />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Description</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <CustomMyInput placeholder="Enter Description" onChange={(e) => setPlatformDesc(e.target.value)} required={false} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 w-100 mt-3'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Category</p>
                                </Col>
                                <Col sm={7}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <Dropdown className='w-100' isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                                            <DropdownToggle className='w-100' caret>
                                                {platformCategoryName && platformCategoryName != "" ? platformCategoryName : 'Select Category'}
                                            </DropdownToggle>
                                            <DropdownMenu
                                            >
                                                {subscriptionCategoryList.data && subscriptionCategoryList.data.data && subscriptionCategoryList.data.data.length && subscriptionCategoryList.data.data.map((y, j) => {
                                                    return <DropdownItem className={`${y.uuid == platformCategory && 'active'}`} onClick={() => handleDropDownClick(y.uuid, y.title)}>
                                                        {y.title}
                                                    </DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Platform Logo</p>
                                </Col>
                                <Col sm={3}>
                                    <input ref={inputFileRef} onChange={(e) => handleFileChange(e)} className='d-none' type={'file'} />
                                    {!isImageUploading ?
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            onClick={() => inputFileRef.current.click()}
                                        >
                                            <i className="fas fa-upload pt-1"></i>
                                        </Button>
                                        :
                                        <div className='relative'>
                                            <Spinner
                                                color="success"
                                                type="border"
                                                style={{ alignSelf: 'center' }}
                                            >
                                                Loading...
                                            </Spinner>
                                        </div>
                                    }
                                </Col>
                                <Col sm={3}>
                                    {platformLogo && platformLogo != "" &&
                                        <img className='platform-img' alt="N/A" src={platformLogo ? platformLogo : ''} />
                                    }
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                onClick={() => handleAddPlatform()}
                            >
                                Save
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
