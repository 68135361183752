import { WEB } from "../constants/constants";


const initialState = {
    isLoading: false,
    exportLoading: false,
    isTagLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
    genreDropdownResult: {},
    languageDropdownResult: {},
    ottDropdownResult: {},
    uploadResponse: {},
    newUploadResponse: {},
    mediaSyncResponse: {},
    mediaList: {},
    addMediaList: {},
    addMediaSearchResult: {},
    mediaDetails: {},
    tagDropdownResult: {},
    tagMediaResult: {},
    mediaCastList: {},
    mediaCrewList: {},
    mediaExportData: {},
    trailerExportData: {},
    providersListData: {},
    providersLangListData: {},
    mediaProvidersListData: {},
    certificatesList: {},
    androidPackageList: {},
    mediaRelatedDetail: {},
};
export const mediaReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.MEIDIA_GENRE_DROPDOWN_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_GENRE_DROPDOWN_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                genreDropdownResult: payload,
            };
        case WEB.MEDIA_GENRE_DROPDOWN_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };


        case WEB.MEDIA_LANGUAGE_DROPDOWN_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_LANGUAGE_DROPDOWN_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                languageDropdownResult: payload,
            };
        case WEB.MEDIA_LANGUAGE_DROPDOWN_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.MEDIA_OTT_DROPDOWN_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_OTT_DROPDOWN_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                ottDropdownResult: payload,
            };
        case WEB.MEDIA_OTT_DROPDOWN_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };


        case WEB.BULK_OTT_DEEPLINK_UPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BULK_OTT_DEEPLINK_UPLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                status: "Deeplink Updated",
                uploadResponse: payload,
            };
        case WEB.BULK_OTT_DEEPLINK_UPLOAD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.NEW_BULK_OTT_DEEPLINK_UPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.NEW_BULK_OTT_DEEPLINK_UPLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                status: payload?.data?.message || "New Deeplink Updated",
                newUploadResponse: payload,
            };
        case WEB.NEW_BULK_OTT_DEEPLINK_UPLOAD_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.BULK_MEDIA_SYNC_TMDB_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.BULK_MEDIA_SYNC_TMDB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                status: payload?.data?.message || "Media Synced",
                mediaSyncResponse: payload,
            };
        case WEB.BULK_MEDIA_SYNC_TMDB_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.MEDIA_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
            };
        case WEB.MEDIA_LIST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_LIST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                mediaList: payload,
            };
        case WEB.MEDIA_LIST_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.ADD_MEDIA_SEARCH_POST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_MEDIA_SEARCH_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                addMediaSearchResult: payload,
            };
        case WEB.ADD_MEDIA_SEARCH_POST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.GET_MEDIA_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_MEDIA_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                mediaDetails: payload,
            };
        case WEB.GET_MEDIA_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.UPDATE_MEDIA_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_MEDIA_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Media Updated',
            };
        case WEB.UPDATE_MEDIA_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.ADD_MEDIA_LIST_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_MEDIA_LIST_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                addMediaList: payload,
            };
        case WEB.ADD_MEDIA_LIST_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_NEW_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_NEW_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Media Added',
            };
        case WEB.ADD_NEW_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.DELETE_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DELETE_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Media Deleted Successfully',
            };
        case WEB.DELETE_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error ? payload.error : 'Something went wrong !!!',
            };

        case WEB.GET_SEARCH_TAG_LIST_REQUEST:
            return {
                ...state,
                isTagLoading: true,
            };
        case WEB.GET_SEARCH_TAG_LIST_SUCCESS:
            return {
                ...state,
                isTagLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                tagDropdownResult: payload,
            };
        case WEB.GET_SEARCH_TAG_LIST_FAILED:
            return {
                ...state,
                isTagLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_MEDIA_TAG_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_MEDIA_TAG_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                tagMediaResult: payload,
            };
        case WEB.GET_MEDIA_TAG_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.ADD_REMOVE_MEDIA_TAGS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.ADD_REMOVE_MEDIA_TAGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Tag updated Successfully',
            };
        case WEB.ADD_REMOVE_MEDIA_TAGS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.GET_MEDIA_CAST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_MEDIA_CAST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                mediaCastList: payload,
            };
        case WEB.GET_MEDIA_CAST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_MEDIA_CREW_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_MEDIA_CREW_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                mediaCrewList: payload,
            };
        case WEB.GET_MEDIA_CREW_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_MEDIA_CSV_FILE_REQUEST:
            return {
                ...state,
                exportLoading: true,
            };
        case WEB.GET_MEDIA_CSV_FILE_SUCCESS:
            return {
                ...state,
                exportLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                mediaExportData: payload,
            };
        case WEB.GET_MEDIA_CSV_FILE_FAILED:
            return {
                ...state,
                exportLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.GET_TRAILER_CSV_FILE_REQUEST:
            return {
                ...state,
                exportLoading: true,
            };
        case WEB.GET_TRAILER_CSV_FILE_SUCCESS:
            return {
                ...state,
                exportLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                trailerExportData: payload,
            };
        case WEB.GET_TRAILER_CSV_FILE_FAILED:
            return {
                ...state,
                exportLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        // providers

        case WEB.GET_PROVIDERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                providersListData: payload,
            };
        case WEB.GET_PROVIDERS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_PROVIDERS_LANG_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_PROVIDERS_LANG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                providersLangListData: payload,
            };
        case WEB.GET_PROVIDERS_LANG_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.MEDIA_PROVIDERS_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_PROVIDERS_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                mediaProvidersListData: payload,
            };
        case WEB.MEDIA_PROVIDERS_GET_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        case WEB.UPDATE_MEDIA_PROVIDERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.UPDATE_MEDIA_PROVIDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Providers updated Successfully',
            };
        case WEB.UPDATE_MEDIA_PROVIDERS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.GET_MEDIA_CERTIFICATES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_MEDIA_CERTIFICATES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: "",
                certificatesList: payload,
            };
        case WEB.GET_MEDIA_CERTIFICATES_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.SYNC_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SYNC_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Sync is in progress',
            };
        case WEB.SYNC_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,

            };
        case WEB.SYNC_PERSON_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.SYNC_PERSON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Sync Person Successfully',
            };
        case WEB.SYNC_PERSON_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.MEDIA_RELATED_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.MEDIA_RELATED_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'success',
                mediaRelatedDetail: payload,
            };
        case WEB.MEDIA_RELATED_DETAIL_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: '',
            };

        default:
            return state;

    }
}