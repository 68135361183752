import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import EditAddFeed from './EditAddFeed';
import ReactGA from 'react-ga';


const tempArr = [1, 2, 3, 4, 5, 6];

export default function DynamicFeedList() {
    const [isEditOrCreate, setIsEditOrCreate] = React.useState(false);
    const [allDropDown, setAllDropDown] = React.useState(false);
    const [allDropDownSort, setAllDropDownSort] = React.useState(false);

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    return (
        <>
            <Header />
            <div>
                {!isEditOrCreate ?
                    <Container>
                        <div className='media'>
                            <h3>
                                FEED - List
                            </h3>
                            <Button color="primary" onClick={() => setIsEditOrCreate(true)}>Create Feed</Button>
                        </div>
                        <div className='media'>
                            <Col sm="12">
                                <Row className='search_sort'>
                                    <Col sm="1">
                                        <Dropdown color="black" mt={4} isOpen={allDropDown} toggle={() => setAllDropDown(!allDropDown)}>
                                            <DropdownToggle caret>
                                                ALL
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Movies</DropdownItem>
                                                <DropdownItem>Shows</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>

                                    <Col sm="5">
                                        <Form>
                                            <FormGroup>
                                                <Col sm={10} className="p-0 search-icon-box">
                                                    <Input placeholder='Search' />
                                                    <i className="fas fa-search"></i>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col sm="6">
                                        <Row className='ml-22'>
                                            <Col>
                                                <h4 className="mt-2 mr-2 text-right">
                                                    Sort :
                                                </h4>
                                            </Col>
                                            <Col>
                                                <Dropdown color="black" mt={4} isOpen={allDropDownSort} toggle={() => setAllDropDownSort(!allDropDownSort)}>
                                                    <DropdownToggle caret>
                                                        Alphabatically(A-Z)
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>Alpabetically(Z-A)</DropdownItem>
                                                        <DropdownItem>Latest Release</DropdownItem>
                                                        <DropdownItem>Most searched</DropdownItem>
                                                        <DropdownItem>Rating(Highest to lowest)</DropdownItem>
                                                        <DropdownItem>Popularity(High to low)</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                        <Container>
                            <Table className="align-items-center table-flush " >
                                <thead >
                                    <tr>
                                        <th className='table_heading'>Sr No.</th>
                                        <th className='table_heading'>Title</th>
                                        <th className='table_heading'>Type</th>
                                        <th className='table_heading'>Status</th>
                                        <th className='table_heading'>Information
                                        </th>
                                        <th className='table_heading'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tempArr.map((x, i) => {
                                        return <tr>
                                            <th scope="row">1</th>
                                            <td>Content Title</td>
                                            <td>Movie</td>
                                            <td>Status </td>
                                            <td>7.8</td>
                                            <td className=" td-actions text-left">
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="info"
                                                    size="sm"
                                                    type="button"
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => setIsEditOrCreate(true)}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>

                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </td>

                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </Container>
                    </Container>
                    :
                    <EditAddFeed setIsEditOrCreate={(val) => setIsEditOrCreate(val)} />
                }
            </div>
        </>
    )
}

