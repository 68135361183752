import React from 'react'
import {
    Row, Button, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Pagination, PaginationItem
} from 'reactstrap';
// import times from "lodash/times";
import "./_pagination.scss";

export default function CustomPagination(props) {
    const [isOpen, setIsOpen] = React.useState(false)
    // const [isOpen2, setIsOpen2] = React.useState(false)
    const [goToPage, setGoToPage] = React.useState(1)
    const [currentPageLimit, setCurrentPageLimit] = React.useState(props.defaultPageList)

    const getArray = (val) => {
        var tempArr = [];
        for (var i = 1; i <= val; i++) {
            tempArr.push(i);
        }
        return tempArr;
    }

    const getArray2 = (val, startIndex = 1, maxPage) => {
        var tempArr = [];
        if (startIndex == maxPage) {
            tempArr.push(maxPage);
        } else {
            for (var i = startIndex; i <= val + startIndex; i++) {
                if (i < maxPage) {
                    tempArr.push(i);
                }
            }
        }
        return tempArr;
    }

    const getArrayPrev2 = (val, startIndex = 1, maxPage) => {
        var tempArr = [];
        var count = startIndex > 3  ? startIndex - val : startIndex > 2 ? startIndex - 2 : startIndex > 1 ? startIndex - 1 : startIndex;
        if(startIndex > 1){
            for (var i = startIndex - 1; i >= count; i--) {
                if (i < maxPage) {
                    tempArr.push(i);
                }
            }
        }
        return tempArr.reverse();
    }

    const handlePageLimitChange = (val) => {
        props.setPageLimit(val)
        setCurrentPageLimit(val)
    }


    return (<>
        {props.pagination &&
            <div>
                <Row className='justify-content-center p-2'>
                    <Pagination
                        aria-label="Page navigation example"
                        size="sm"
                        className='mr-2 custom-pagination'
                    >
                        <PaginationItem>
                            <Button
                                className='pagination-btn'
                                href="#"
                                previous
                                disabled={!props.pagination.is_previous_page}
                                onClick={() => props.setCurrentPage(props.pagination.page - 1)}
                            >
                                Prev
                            </Button>
                        </PaginationItem>

                        {props.pagination && props.pagination.total_pages && props.pagination.total_pages < 4 ?

                            props.pagination.total_pages > 1 ? getArray(props.pagination.total_pages).map((x, i) => {
                                return <PaginationItem key={x}>
                                    <Button
                                        className={`${props.pagination.page == x && 'active-page-btn'} pagination-btn`}
                                        onClick={() => props.setCurrentPage(x)}>
                                        {x}
                                    </Button>
                                </PaginationItem>
                            })
                                :
                                <PaginationItem>
                                    <Button className='pagination-btn active-page-btn' onClick={() => props.setCurrentPage(1)}>
                                        1
                                    </Button>
                                </PaginationItem>
                            :
                            <>
                                {props.pagination.page > 1 &&
                                    <PaginationItem key={1}>
                                        <Button
                                            className={`${props.pagination.page == 1 && 'active-page-btn'} pagination-btn`}
                                            onClick={() => props.setCurrentPage(1)}>
                                            First
                                        </Button>
                                    </PaginationItem>
                                }
                                {getArrayPrev2(3, props.pagination.page, props.pagination.total_pages).map((x, i) => {
                                    return <PaginationItem key={x}>
                                        <Button
                                            className={`${props.pagination.page == x && 'active-page-btn'} pagination-btn`}
                                            onClick={() => props.setCurrentPage(x)}>
                                            {x}
                                        </Button>
                                    </PaginationItem>
                                })}
                                {getArray2(3, props.pagination.page, props.pagination.total_pages).map((x, i) => {
                                    return <PaginationItem key={x}>
                                        <Button
                                            className={`${props.pagination.page == x && 'active-page-btn'} pagination-btn`}
                                            onClick={() => props.setCurrentPage(x)}>
                                            {x}
                                        </Button>
                                    </PaginationItem>
                                })}
                                {props.pagination.page != props.pagination.total_pages &&
                                    <>
                                        <PaginationItem>
                                            <Button
                                                className='pagination-btn success'
                                                href="#"
                                                // disabled={!props.pagination.is_next_page}
                                                onClick={() => props.setCurrentPage(props.pagination.total_pages)}
                                            >
                                                Last
                                            </Button>
                                        </PaginationItem>
                                    </>
                                }
                            </>
                        }
                        <PaginationItem>
                            <Button
                                className='pagination-btn success'
                                href="#"
                                disabled={!props.pagination.is_next_page}
                                onClick={() => props.setCurrentPage(props.pagination.page + 1)}
                            >
                                Next
                            </Button>
                        </PaginationItem>
                    </Pagination>
                    {/* <div className='v-middle'>
                    <p className='mb-0 mr-4'>Go to page:</p>
                </div>
                <div className="d-flex mr-4 custom-pagination justify-content-center">
                    <Dropdown isOpen={isOpen2} toggle={() => setIsOpen2(!isOpen2)}>
                        <DropdownToggle caret>
                            {props.pagination.page}
                        </DropdownToggle>
                        <DropdownMenu
                        >
                            {times(props.pagination.total_pages, Number).map((item, id) => {
                                return <DropdownItem className={`${props.pagination.page == item + 1 && 'active'}`} onClick={() => props.setCurrentPage(item + 1)}>
                                    {item + 1}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </div> */}
                </Row>
                <Row className='justify-content-center p-2'>
                    <div className='v-middle'>
                        <p className='mb-0 mr-4'>Items per page:</p>
                    </div>
                    <div className="d-flex mr-4 custom-pagination justify-content-center">
                        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                            <DropdownToggle caret>
                                {currentPageLimit}
                            </DropdownToggle>
                            <DropdownMenu
                            >
                                <DropdownItem className={`${currentPageLimit == 10 && 'active'}`} onClick={() => handlePageLimitChange(10)}>
                                    10
                                </DropdownItem>
                                <DropdownItem className={`${currentPageLimit == 20 && 'active'}`} onClick={() => handlePageLimitChange(20)}>
                                    20
                                </DropdownItem>
                                <DropdownItem className={`${currentPageLimit == 50 && 'active'}`} onClick={() => handlePageLimitChange(50)}>
                                    50
                                </DropdownItem>
                                <DropdownItem className={`${currentPageLimit == 100 && 'active'}`} onClick={() => handlePageLimitChange(100)}>
                                    100
                                </DropdownItem>
                                <DropdownItem className={`${currentPageLimit == 500 && 'active'}`} onClick={() => handlePageLimitChange(500)}>
                                    500
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='mr-4 v-middle'>
                        <p className='mb-0'>
                            Showing {props.pagination && props.pagination.start_index ? props.pagination.start_index : 0} - {props.pagination && props.pagination.end_index ? props.pagination.end_index : 0} of {props.pagination && props.pagination.total_entries ? props.pagination.total_entries : 0}</p>
                    </div>
                    <div className='v-middle'>
                        <Input min={1} onKeyDown={(e) => {
                            if (e.code == "Enter") {
                                props.setCurrentPage(goToPage);
                            }
                        }} max={props.pagination.total_pages} style={{ minWidth: 100, height: 40 }} value={goToPage} type='number' onChange={(e) => setGoToPage(e.target.value)} placeholder='Page No.' />
                    </div>
                    <div className="d-flex mr-4 custom-pagination justify-content-center">
                        <Button color='success' onClick={() => props.setCurrentPage(goToPage)} className='pagination-btn success'>Go</Button>
                    </div>
                </Row>
            </div>
        }
    </>)
}
