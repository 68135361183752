import helpers from 'helpers/common';
import React, { useEffect, useState } from 'react'
import { Button, Col, CustomInput, Form, FormGroup, Input, Row } from 'reactstrap';
import ReactGA from 'react-ga';
import { addQuiz } from '../../redux/actions/polls.action';
import AWS from 'aws-sdk'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

// 2022-11-09

const defaultObj = {
    image: "",
    title: "",
    scheduled_at: "",
    expiry: "",
    is_active: false,
    questions: []
}

export default function AddQuize(props) {

    const [pollData, setPollData] = useState(defaultObj);
    const posterInputChange = React.useRef(null);

    const getDate = (date) => {
        var first = date.split(" ")[0];
        const d = first.split("/")[0];
        const m = first.split("/")[1];
        const y = first.split("/")[2];
        return y + "-" + m + "-" + d;
    }
    const status = useSelector((state) => state.polls && state.polls.status ? state.polls.status : '');
    const dispatch = useDispatch();

    React.useEffect(() => {
        setPollData({...defaultObj,questions : []});
        ReactGA.pageview(window.location.pathname);
    }, [])

    console.log("pollData--",pollData);

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }


    const handleOnchangeFormaData = (value, name) => {
        setPollData({ ...pollData, [name]: value });
    }

    console.log("pollData-", pollData)

    const handleOnchangeFormaOptData = (value, index) => {
        var tempArr = pollData.options;
        tempArr[index].option = value;
        tempArr[index].order = index + 1;
        pollData.options = tempArr;
        setPollData({ ...pollData });
    }

    const onPosterChangeClick = () => {
        posterInputChange.current.click();
    }

    const handleDeleteOpt = (qIndex, index) => {
        var tempArr = pollData.questions[qIndex] && pollData.questions[qIndex].options && pollData.questions[qIndex].options;
        if (tempArr && tempArr.length) {
            tempArr.splice(index, 1);
            pollData.questions[qIndex].options = tempArr;
            setPollData({ ...pollData });
        }
    }

    const handleDeleteQuestion = (index) => {
        var tempArr = pollData.questions;
        tempArr.splice(index, 1);
        pollData.questions = tempArr;
        setPollData({ ...pollData });
    }

    const handleAddOption = (qIndex) => {
        var tempArr = pollData.questions[qIndex] && pollData.questions[qIndex].options ? pollData.questions[qIndex].options : [];
        tempArr.push({
            is_correct: tempArr.length == 0 ? true : false,
            option: '',
            order: tempArr.length + 1
        })
        pollData.questions[qIndex].options = tempArr;
        setPollData({ ...pollData });
    }

    const handleAddQuestion = () => {
        var tempArr = pollData.questions;
        tempArr.push({
            frenzi_points: 0,
            image: '',
            options: [],
            question: ''
        })
        pollData.questions = tempArr;
        setPollData({ ...pollData });
    }

    const handleOnCorrectChange = (e, qIndex, index) => {
        if (e.target.checked) {
            const newData = { ...pollData };
            var tempData = newData.questions[qIndex].options.map((x, ji) => {
                if (index === ji) {
                    return {
                        ...x,
                        is_correct: true
                    }
                } else {
                    return {
                        ...x,
                        is_correct: false
                    }
                }
            })
            newData.questions[qIndex].options = tempData;
            setPollData({ ...newData });
        }
    }

    const handlePosterChange = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "polls_quizes/quiz-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Media', "Uploaded poster_path file to s3 bucket -" + keyName, data.Location);
                handleOnchangeFormaData(data.Location, 'image');
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handlePosterChangeQues = async (event, qIndex) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "polls_quizes/quiz-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('Media', "Uploaded poster_path file to s3 bucket -" + keyName, data.Location);
                handleOnchangeFormaDataQues(data.Location, qIndex, 'image');
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleOnchangeFormaDataQues = (val, qIndex, name) => {
        var tempData = pollData.questions && pollData.questions[qIndex] ? pollData.questions[qIndex] : {};
        if (tempData) {
            tempData = { ...tempData, [name]: val };
            pollData.questions[qIndex] = tempData;
            setPollData({ ...pollData });
        }
    }

    const handleOnchangeFormaDataQuesOpt = (val, qIndex, index, name) => {
        var tempData = pollData.questions[qIndex] && pollData.questions[qIndex].options && pollData.questions[qIndex].options[index] ? pollData.questions[qIndex].options[index] : {};
        if (tempData) {
            tempData = { ...tempData, [name]: val };
            pollData.questions[qIndex].options[index] = tempData;
            setPollData({ ...pollData });
        }
    }

    const getDateFormat = (date) => {
        // time data '2022-11-11' does not match format '%d/%m/%Y %H:%M:%S'
        // DD/MM/YYYY
        var con = new Date(date);
        let m = con.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        let d = con.getDate();
        d = d < 10 ? '0' + d : d;
        let y = con.getFullYear();
        let h = con.getHours();
        h = h < 10 ? '0' + h : h;
        let min = con.getMinutes();
        min = min < 10 ? '0' + min : min;
        let mil = con.getMilliseconds();
        mil = mil < 10 ? '0' + mil : mil;
        const time = d + "/" + m + "/" + y + " " + h + ":" + min + ":" + mil;
        return time;
    }

    const handleCreate = () => {
        const body = {
            ...pollData,
            scheduled_at: pollData.scheduled_at && pollData.scheduled_at != "" ? getDateFormat(pollData.scheduled_at) : '',
            expiry: pollData.expiry && pollData.expiry != "" ? getDateFormat(pollData.expiry) : '',
        }
        eventReporting('Quiz', "Added quiz details -", pollData.title ? pollData.title : '');
        dispatch(addQuiz(body));
    }

    return (
        <div>
            <Row className='media_detail_container'>
                <Col sm="auto" className='position-relative pr-0'>
                    {pollData.image && pollData.image != "" ?
                        <img src={pollData.image ? helpers.getFullUrl(pollData.image) : ''} style={{ maxHeight: 150 }} />
                        :
                        <div style={{ width: 300, height: 150 }} className='default-img'>
                            <i className='fas fa-image'></i>
                        </div>
                    }
                    <i onClick={() => onPosterChangeClick()} className='fas position-absolute fa-edit pointer' style={{ top: 5, right: 5, zIndex: 9, background: '#fff', color: '#f5365c', borderRadius: '50%', padding: 5 }} ></i>
                    <input
                        style={{ display: 'none' }}
                        ref={posterInputChange}
                        type="file"
                        onChange={(e) => handlePosterChange(e)}
                        accept="image/*"
                    />
                </Col>
            </Row>
            <Row className='media_detail_container'>
                <Col xs={12} lg={12} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Title</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="search" placeholder="Enter title" value={pollData.title ? pollData.title : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'title')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={12} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Subtitle</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="search" placeholder="Enter subtitle" value={pollData.subtitle ? pollData.subtitle : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'subtitle')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Scheduled At</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="datetime-local" placeholder="Enter a question" value={pollData.scheduled_at ? pollData.scheduled_at : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'scheduled_at')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Expiry</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="datetime-local" placeholder="Enter a question" value={pollData.expiry ? pollData.expiry : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'expiry')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Time (In Seconds)</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <Input disabled={false} type="text" placeholder="Timer" value={pollData.timer_in_seconds ? pollData.timer_in_seconds : ''} onChange={(e) => handleOnchangeFormaData(e.target.value, 'timer_in_seconds')} />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Status</p>
                    <Form className='edit_media_input'>
                        <FormGroup>
                            <CustomInput
                                type="switch"
                                id={`exampleCustomSwitch-3`}
                                name="customSwitch2"
                                className='p-0'
                                checked={pollData.is_active ? pollData.is_active : false}
                                onChange={(e) => handleOnchangeFormaData(e.target.checked, 'is_active')}
                            />
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs={12} lg={6} className='edit_media_subheading'>
                    <p className='edit_media_subheading_txt'>Add Question</p>
                    <Form className='edit_media_input'>
                        <Button onClick={() => handleAddQuestion()} color='primary'>Add</Button>
                    </Form>
                </Col>
            </Row>
            <div>
                <ol>
                    {pollData.questions && pollData.questions.length > 0 && pollData.questions.map((ques, i) => {
                        return <li style={{ marginTop: 20 }} key={i}>
                            <Row>
                                <Col xs={10} lg={8} className='edit_media_subheading pl-3'>
                                    <Form className='edit_media_input w-100'>
                                        <FormGroup>
                                            <Input disabled={false} type="text" placeholder="Enter a question" value={ques.question ? ques.question : ''} onChange={(e) => handleOnchangeFormaDataQues(e.target.value, i, 'question')} />
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col xs={2} lg={2} className='edit_media_subheading pl-2'>
                                    <Input disabled={false} type="number" placeholder="Points" value={ques.frenzi_points ? ques.frenzi_points : ''} onChange={(e) => handleOnchangeFormaDataQues(e.target.value, i, 'frenzi_points')} />
                                </Col>
                                <Col xs={2} lg={2} className='edit_media_subheading pl-2'>
                                    <Button onClick={() => handleAddOption(i)} color='primary'>Add Opt</Button>
                                    <Button
                                        className="btn-icon btn-simple"
                                        style={{ marginTop: 3 }}
                                        color="danger"
                                        size="sm"
                                        type="button"
                                        onClick={() => handleDeleteQuestion(i)}
                                    >
                                        <i className="fas fa-trash pt-1"></i>
                                    </Button>
                                </Col>
                                {ques.options && ques.options.length > 0 && ques.options.map((opt, j) => {
                                    return <Col xs={12} sm={6} key={j}>
                                        <Row className='mt-2'>
                                            <Col>
                                                <Input disabled={false} type="text" placeholder={`Option ${j + 1}`} value={opt.option ? opt.option : ''} onChange={(e) => handleOnchangeFormaDataQuesOpt(e.target.value, i, j, 'option')} />
                                            </Col>
                                            <Col>
                                                <Input type='checkbox' onChange={(e) => handleOnCorrectChange(e, i, j)} checked={opt.is_correct} />
                                                <Button
                                                    className="btn-icon btn-simple"
                                                    style={{ marginTop: 7 }}
                                                    color="warning"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => handleDeleteOpt(i, j)}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                })}
                                <Col xs={12} className='position-relative mt-2 pr-0'>
                                    {ques.image && ques.image != "" &&
                                        <img src={ques.image ? helpers.getFullUrl(ques.image) : ''} style={{ maxHeight: 150 }} />
                                    }
                                    <input
                                        type="file"
                                        onChange={(e) => handlePosterChangeQues(e, i)}
                                        accept="image/*"
                                        className='ml-5'
                                    />
                                </Col>
                            </Row>
                        </li>
                    })}
                </ol>
            </div>
            <div className='text-center'>
                <Button onClick={() => handleCreate()} color='success'>Create</Button>
            </div>
        </div>
    )
}
