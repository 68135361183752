import { User } from "../constants/constants";
import { WEB } from "../constants/constants";


const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    mediaSearchResults: {},
    userSearchResult: {},
    mediaTypeSearchResults: {},
    personSearchResult: {},
    errMess: "",
    status: "",
    req: {},
};

export const commonSearchReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case User.SEARCH_MEDIA_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.SEARCH_MEDIA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                mediaSearchResults: payload,
            };
        case User.SEARCH_MEDIA_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,

            };
        case User.SEARCH_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.SEARCH_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                userSearchResult: payload,
            };
        case User.SEARCH_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,

            };
        case User.MEDIA_TYPE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case User.MEDIA_TYPE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                mediaTypeSearchResults: payload,
            };
        case User.MEDIA_TYPE_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,

            };
        case WEB.GET_PERSON_SEARCH_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_PERSON_SEARCH_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMess: "",
                status: "",
                personSearchResult: payload,
            };
        case WEB.GET_PERSON_SEARCH_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMess: payload.error,

            };

        default:
            return state;

    }
}


