import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.DISCOVERY_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.DISCOVERY_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getDiscoveryData = (value = '', userSection = 'all', section = "admin", dateRange = '', isTrending, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.DISCOVERY_GET_REQUEST });

        let url = `native_main/discoverpostlist?search_keyword=${value}&user_section=${userSection}&section=${section}&page=${page}&pagelimit=${pagelimit}`;

        if (isTrending != 'all') {
            url += `&is_trending=${isTrending}`
        }
        if (dateRange && dateRange != '') {
            url += `&date=${dateRange}`;
        }

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.DISCOVERY_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DISCOVERY_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const createDiscoveryPost = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.DISCOVERY_POST_REQUEST });

        axiosInstance
            .post(`native_main/discoverpostlist`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DISCOVERY_POST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DISCOVERY_POST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deleteDiscoveryPost = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DISCOVERY_DELETE_REQUEST });

        axiosInstance
            .delete(`native_main/discoverpostdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DISCOVERY_DELETE_SUCCESS,
                        payload: payload,
                    })
                } else if (res.status === 204) {
                    const payload = { message: 'Post deleted succesfully' }
                    dispatch({
                        type: WEB.DISCOVERY_DELETE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DISCOVERY_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const updateDiscoveryPost = (uuid, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.DISCOVERY_UPDATE_REQUEST });

        axiosInstance
            .put(`native_main/discoverpostdetail/${uuid}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DISCOVERY_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DISCOVERY_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}
