import React from 'react'
import Header from "components/Headers/Header.js";
import { Container, Row, Col, Form, FormGroup, Button, Input, Card, Table, Modal, ModalBody, ModalHeader, Label, ModalFooter } from 'reactstrap';
import { addFrenziKey, clearMessage, deleteFrenziKey, getFrenziValuesList, updateFrenziKey } from '../../redux/actions/frenziKeys.action'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

const tempArr = [1, 2, 3, 4, 5, 6];

export default function FrenziKeys() {
    const [addFrenziKeyPopup, setAddFrenziKeyPopup] = React.useState(false);
    const [frenziKeyInput, setFrenziKeyInput] = React.useState("")
    const [inputSearchText, setInputSearchText] = React.useState("")
    const [isEdit, setIsEdit] = React.useState(false)
    const [editId, setEditId] = React.useState('')
    const [frenziValueInput, setFrenziValueInput] = React.useState("")
    const dispatch = useDispatch();

    const frnziKeyValuesList = useSelector((state) => state.frenziValues && state.frenziValues.frnziKeyValuesList ? state.frenziValues.frnziKeyValuesList : {})

    const status = useSelector((state) => state.frenziValues && state.frenziValues.status ? state.frenziValues.status : '')
    const errMesg = useSelector((state) => state.frenziValues && state.frenziValues.errMesg ? state.frenziValues.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }


    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            dispatch(getFrenziValuesList(inputSearchText));
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    React.useEffect(() => {
        dispatch(getFrenziValuesList(inputSearchText));
    }, [inputSearchText])

    const handleSubmit = () => {
        if (frenziKeyInput && frenziKeyInput != "" && frenziValueInput && frenziValueInput != "") {
            const data = {
                frenzi_key: frenziKeyInput,
                frenzi_value: frenziValueInput
            }
            if (isEdit && editId && editId != "") {
                eventReporting('Frenzi Keys', 'Frenzi Key updated - ' + frenziValueInput, editId);
                dispatch(updateFrenziKey(editId, data));
            } else {
                eventReporting('Frenzi Keys', 'Frenzi Key added - ' + frenziValueInput, frenziKeyInput);
                dispatch(addFrenziKey(data));
            }
            setAddFrenziKeyPopup(false);
            setFrenziKeyInput('');
            setFrenziValueInput('');
        } else {
            toast.error(('Missing required parameters.'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDeleteKey = (id) => {
        eventReporting('Frenzi Keys', 'Frenzi Key deleted', id);
        dispatch(deleteFrenziKey(id));
    }

    return (
        <>
            <Header />
            <ToastContainer />
            <div>
                <Container>
                    <Card className='mt-4 p-3 rounded-lg'>
                        <Row className='mt-2'>
                            <Col sm="4" >
                                <Form>
                                    <FormGroup>
                                        <Col sm={12} className="p-0 search-icon-box">
                                            <Input placeholder='Search' onChange={(e) => setInputSearchText(e.target.value)} />
                                            <i className="fas fa-search"></i>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col sm="2">
                                <Button color="success" onClick={() => {
                                    setIsEdit(false);
                                    setEditId('');
                                    setFrenziKeyInput('');
                                    setFrenziValueInput('');
                                    setAddFrenziKeyPopup(!addFrenziKeyPopup);
                                    ReactGA.modalview(window.location.pathname + '?modal=Add Key')
                                }} >Add Key</Button>
                            </Col>
                        </Row>
                        <Table className="align-items-center table-flush " >
                            <thead >
                                <tr>
                                    <th className='table_heading'>Sr No.</th>
                                    <th className='table_heading'>Key Name</th>
                                    <th className='table_heading'>value</th>
                                    <th className='table_heading'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {frnziKeyValuesList.data?.results.length > 0 &&
                                    frnziKeyValuesList.data.results?.map((x, i) => {
                                        return <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td>{x.frenzi_key}</td>
                                            <td>{x.frenzi_value}</td>
                                            <td className=" td-actions text-left">
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="info"
                                                    size="sm"
                                                    type="button"
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        setIsEdit(true);
                                                        setEditId(x.uuid);
                                                        setFrenziKeyInput(x.frenzi_key);
                                                        setFrenziValueInput(x.frenzi_value);
                                                        setAddFrenziKeyPopup(!addFrenziKeyPopup);
                                                    }}
                                                >
                                                    <i className="fas fa-pen pt-1"></i>
                                                </Button>

                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        if (window.confirm("Do you really want to delete this key ?")) {
                                                            handleDeleteKey(x.uuid);
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </td>

                                        </tr>
                                    })
                                }
                                {frnziKeyValuesList.data?.results.length == 0 &&
                                    <tr><td colSpan={4} className="text-center">No Data Found</td></tr>
                                }
                            </tbody>
                        </Table>
                    </Card>
                </Container>
                <Modal isOpen={addFrenziKeyPopup} toggle={() => setAddFrenziKeyPopup(!addFrenziKeyPopup)}  >
                    <ModalHeader toggle={() => setAddFrenziKeyPopup(!addFrenziKeyPopup)} ></ModalHeader>
                    <ModalBody >
                        <Row className='pl-3 pr-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Frenzi Key</p>
                            </Col>
                            <Col sm={9}>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <Input value={frenziKeyInput ? frenziKeyInput : ''} onChange={(e) => setFrenziKeyInput(e.target.value)} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Value</p>
                            </Col>
                            <Col sm={9}>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <Input value={frenziValueInput ? frenziValueInput : ''} onChange={(e) => setFrenziValueInput(e.target.value)} />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <div style={{ textAlign: 'center' }} className='mb-2'>
                        <Button color="info" className='text-center' onClick={handleSubmit}>Send</Button>
                    </div>
                </Modal>
            </div>
        </>
    )
}
