import React, { useState } from 'react'
import Header from "components/Headers/Header.js";

import {
    Card, Container, Form, FormGroup, Label, Col, Input,
    Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button,
    Modal, ModalHeader, ModalFooter, ModalBody, CustomInput
} from 'reactstrap';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import { getPersonDetails, clearMessage, updatePersonDetails } from "../../redux/actions/person.action"
import { useDispatch, useSelector } from 'react-redux';
import common from '../../helpers/common'
import AWS from 'aws-sdk'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WithContext as ReactTags } from 'react-tag-input';
import ReactGA from 'react-ga';
import { getAddMediaList } from 'redux/actions/media.action';


const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
var checkFor = ["name", "tmdb_id", "imdb_id", "birthday", "department", "also_known_as", "profile_image", "biography"]

export default function EditCastCrew(props) {
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const posterInputChange = React.useRef(null);
    const artworkInputChange = React.useRef(null);
    const [personData, setPersonData] = React.useState({});
    const [alreadySelectedTags, setAlreadySelectedTags] = React.useState([]);
    const [modal, setModal] = useState(false);
    const [addSelectById, setAddSelectById] = useState(false);
    const [addIdSelect, setAddIdSelect] = useState('Global Search');
    const [addMediaSearchText, setAddMediaSearchText] = React.useState('')
    const [addMediaSearchList, setAddMediaSearchList] = React.useState([]);
    const [finalId, setFinalId] = React.useState('');

    const onPosterChangeClick = () => {
        posterInputChange.current.click();
    }

    const onArtworkChangeClick = () => {
        artworkInputChange.current.click();
    }

    const personDetails = useSelector((state) => state.person && state.person.personDetails ? state.person.personDetails : {})
    const isLoading = useSelector((state) => state.person && state.person.isLoading ? state.person.isLoading : false)
    const errMesg = useSelector((state) => state.person && state.person.errMesg ? state.person.errMesg : '')
    const status = useSelector((state) => state.person && state.person.status ? state.person.status : '')
    const addMediaListData = useSelector((state) => state.media && state.media.addMediaList ? state.media.addMediaList : {})

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        setPersonData({});
        if (props.personId && props.personId != "") {
            dispatch(getPersonDetails(props.personId));
        }
    }, [])

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    const showErr = (msg) => {
        toast.error((msg), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    React.useEffect(() => {
        var id = props.personId;
        if (personDetails && personDetails.data && personDetails.data.uuid && personDetails.data.uuid == id) {
            setPersonData(personDetails.data);
            if (personDetails.data.also_known_as && personDetails.data.also_known_as.length > 0) {
                setAlreadySelectedTags(personDetails.data.also_known_as.map((val) => {
                    return {
                        id: val,
                        text: val
                    }
                }
                ))
            }
        }
    }, [personDetails])


    const handlePosterChange = async (event, typeName = "") => {
        if (typeName && typeName != "") {
            const file = event.target.files && event.target.files[0];
            if (!file) {
                return;
            }
            var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
            var timeStamp = new Date().getTime();
            var randomNo = parseInt(Math.random() * 100000);
            var keyName = "cast_poster/actor-profile-" + timeStamp + "-" + randomNo + "-" + file.name;
            const s3 = new AWS.S3({
                params: { Bucket: bucket },
                region: process.env.REACT_APP_AWS_REGION,
            })
            const params = {
                Key: keyName,
                Body: file,
            }
            s3.upload(params, async function (err, data) {
                if (err) {
                    return err;
                }
                if (data && data.Location) {
                    eventReporting('Media', "Uploaded person " + typeName + " file to s3 bucket -" + keyName, data.Location);
                    handleOnchangeFormaData(data.Location, typeName);
                    toast.warning(('Click on save button to update the changes'), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        } else {
            toast.error(('Something went wrong !!!'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDelete = (i) => {
        setAlreadySelectedTags(alreadySelectedTags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        setAlreadySelectedTags([...alreadySelectedTags, tag]);
    };

    React.useEffect(() => {
        if (addMediaListData && addMediaListData.data && addMediaListData.data.results && addMediaListData.data.results.length > 0) {
            setAddMediaSearchList([...addMediaSearchList, ...addMediaListData.data.results])
        }
    }, [addMediaListData])

    React.useEffect(() => {
        var result = [];
        if (alreadySelectedTags && alreadySelectedTags.length > 0) {
            result = alreadySelectedTags.map((item) => { return item.id; });
            handleOnchangeFormaData(result, 'also_known_as')
        } else {
            handleOnchangeFormaData([], 'also_known_as')
        }
    }, [alreadySelectedTags])

    const handleOnchangeFormaData = (val, name) => {
        setPersonData({
            ...personData,
            [name]: val
        })
    }

    const handleUpdate = () => {
        // if (checkError(personData)) {
        eventReporting('Media', 'Person updated - ', personData.uuid);
        dispatch(updatePersonDetails(personData.uuid, personData));
        // }
    }

    const checkError = (data) => {
        var flag = true;
        Object.keys(data).map((key) => {
            if (checkFor.includes(key)) {
                if (!data[key] || data[key] == '' || data[key].length == 0) {
                    flag = false;
                    var msg = key + " cannot be empty"
                    showErr(msg);
                }
            }
        })
        return flag;
    }

    const toggle = () => setModal(!modal);

    const handleAddMediaSearch = (val) => {
        setAddMediaSearchText(val);
        setAddMediaSearchList([]);
        dispatch(getAddMediaList(val, 'title', '', 1))
    }

    const handleAddMedia = (data) => {
        if (data.tmdb_id && data.tmdb_id != "" && data.media_type && data.media_type != "") {
            eventReporting('Media', 'Media added for person - ' + data.media_type, data.tmdb_id);
            setFinalId(data.tmdb_id);
            setAddMediaSearchList([data]);
            // dispatch(addNewMedia(data.tmdb_id, data.media_type));
        } else {
            toast.error(('Failed !!! - Required data is missing'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleLoadMore = (nextPage) => {
        dispatch(getAddMediaList(addMediaSearchText, 'title', '', nextPage))
    }

    return (
        <div>
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-3 mb-3'>
                    <Row className='justify-content-between px-3 pt-3'>
                        <h3 className='ml-2 pl-2' >Edit Cast & Crew</h3>
                        <div>
                            <Button color="darker" onClick={() => props.handleNoEdit()} className='mr-3'>
                                <i className="fas fa-arrow-left" />
                            </Button>
                        </div>
                    </Row>
                    <div className='mb-4 pl-3'>
                        <Row>
                            <Col sm={2}>
                                <h3 className='' >Poster</h3>
                                <div className='position-relative d-inline-block'>
                                    {personData.profile_image && personData.profile_image != "" ?
                                        <img
                                            alt="..."
                                            src={personData.profile_image ? common.getFullUrl(personData.profile_image) : ''}
                                            className='media_image'
                                        />
                                        :
                                        <div className='default-img'>
                                            <i className='fas fa-image'></i>
                                        </div>
                                    }
                                    <i onClick={() => onPosterChangeClick()} className='fas position-absolute fa-edit pointer' style={{ top: 5, right: 5, zIndex: 9, background: '#fff', color: '#f5365c', borderRadius: '50%', padding: 5 }} ></i>
                                    <input
                                        style={{ display: 'none' }}
                                        ref={posterInputChange}
                                        type="file"
                                        onChange={(e) => handlePosterChange(e, 'profile_image')}
                                        accept="image/*"
                                    />
                                </div>
                            </Col>
                            <Col sm={4}>
                                <h3 className='' >Artwork</h3>
                                <div className='position-relative d-inline-block'>
                                    {personData.artwork && personData.artwork != "" ?
                                        <img
                                            alt="..."
                                            src={personData.artwork ? common.getFullUrl(personData.artwork) : ''}
                                            className='artwork_image'
                                        />
                                        :
                                        <div className='default-img artwork_image'>
                                            <i className='fas fa-image'></i>
                                        </div>
                                    }
                                    <i onClick={() => onArtworkChangeClick()} className='fas position-absolute fa-edit pointer' style={{ top: 5, right: 5, zIndex: 9, background: '#fff', color: '#f5365c', borderRadius: '50%', padding: 5 }} ></i>
                                    <input
                                        style={{ display: 'none' }}
                                        ref={artworkInputChange}
                                        type="file"
                                        onChange={(e) => handlePosterChange(e, 'artwork')}
                                        accept="image/*"
                                    />
                                </div>
                            </Col>
                            {/* <Col sm={4}>
                                <Button color="success" className='w-100 mb-2'
                                    onClick={toggle}
                                >
                                    Sync Media For this Person
                                </Button>
                            </Col> */}
                        </Row>


                        <Row>
                            <Col sm={12} md={6}>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>Name</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='Change Name'
                                            value={personData.name ? personData.name : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'name')}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>DOB</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            type='date'
                                            placeholder='Date of Birthday'
                                            value={personData.birthday ? personData.birthday : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'birthday')}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>DOD</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='Date of Deathday'
                                            type='date'
                                            value={personData.deathday ? personData.deathday : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'deathday')}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>TMDB ID</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='Change TMDB ID'
                                            value={personData.tmdb_id ? personData.tmdb_id : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'tmdb_id')}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>TMDB Popularity</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='Change Popularity'
                                            type='number'
                                            value={personData.tmdb_popularity ? personData.tmdb_popularity : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'tmdb_popularity')}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                            <Col sm={12} md={6}>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>Department</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='Department'
                                            value={personData.department ? personData.department : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'department')}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>Place of Birth</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='Place of Birth'
                                            value={personData.place_of_birth ? personData.place_of_birth : ''}
                                            onChange={(e) => handleOnchangeFormaData(e.target.value, 'place_of_birth')}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>Also Known As</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <ReactTags
                                            tags={alreadySelectedTags}
                                            // suggestions={tagsList}
                                            delimiters={delimiters}
                                            handleDelete={handleDelete}
                                            handleAddition={handleAddition}
                                            // handleInputChange={(va) => handleInputChange(va)}
                                            // handleDrag={handleDrag}
                                            // handleTagClick={handleTagClick}
                                            editable={false}
                                            placeholder="Enter known as"
                                            inputFieldPosition="bottom"
                                            // autocomplete
                                            allowDragDrop={false}
                                            minQueryLength={1}
                                        />
                                    </Col>
                                </Row>
                                <Row className='p-0 m-0 mt-3 w-100'>
                                    <Col sm={4} className="p-0">
                                        <h3 className='mr-3 mb-0 v-middle'>UUID</h3>
                                    </Col>
                                    <Col sm={8}>
                                        <Input className='w-100'
                                            placeholder='UUID'
                                            disabled
                                            value={personData.uuid ? personData.uuid : ''}
                                        // onChange={(e) => handleOnchangeFormaData(e.target.value, 'tmdb_id')}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='pr-4 mt-2'>
                                <h3 >Biography</h3>
                                <Input rows={15} type="textarea" name="text" id="exampleText" value={personData.biography ? personData.biography : ''}
                                    onChange={(e) => handleOnchangeFormaData(e.target.value, 'biography')}
                                />
                            </Col>
                        </Row>


                        <Row className='justify-content-center mt-5 mb-3'>
                            <Button onClick={() => handleUpdate()} color="success" >Save</Button>
                        </Row>
                    </div>
                </Card>
            </Container>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader style={{ padding: '0.5rem', position: 'relative', width: '100%' }}  >
                    <Col xs="12" style={{ display: 'flex' }}>
                        <p className="h3">Sync Media For this Person</p>
                    </Col>
                    <button
                        onClick={toggle}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            border: '0px', backgroundColor: 'white', fontSize: '20px'
                        }}
                    >&times;</button>
                </ModalHeader>
                <ModalBody style={{ padding: '1rem', paddingTop: 0 }}>
                    <Col xs="12">
                        <Row>
                            <Col sm={5}>
                                <Dropdown color="black" toggle={() => { setAddSelectById(!addSelectById) }}
                                    isOpen={addSelectById} style={{ width: '100%' }}>
                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                        {addIdSelect && addIdSelect != "" ? addIdSelect : 'Select ID/Title'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem value="Global Search" onClick={(e) => {
                                            setAddIdSelect(e.target.value);
                                        }}>Global Search</DropdownItem>
                                        <DropdownItem value="DB Search" onClick={(e) => {
                                            setAddIdSelect(e.target.value);
                                        }}>DB Search</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col className='mb-3'>
                                <Input type="search" name="search" id="exampleSearch" placeholder="Search" onChange={(e) => handleAddMediaSearch(e.target.value)} value={addMediaSearchText} />
                            </Col>
                        </Row>
                        <Row style={{ maxHeight: '70vh', overflowY: 'auto' }} className="add-media-list">
                            {addMediaSearchList && addMediaSearchList.length > 0 && addMediaSearchList.map((x, i) => {
                                return <Col className="pointer mt-5 mt-sm-0" sm="3" xs="6" onClick={() => handleAddMedia(x)}>
                                    {x.poster && x.poster != "" && x.poster != null ?
                                        <img
                                            alt="..."
                                            className=" img-fluid rounded shadow-lg"
                                            src={x.poster ? x.poster : ''}
                                        ></img>
                                        :
                                        <div className='d-img'>
                                            <i className='fas fa-image'></i>
                                        </div>
                                    }
                                    <large className=" d-block mb-4 text-center mt-10">
                                        {x.title ? x.title : ''}
                                    </large>
                                </Col>
                            })}
                            {addMediaListData && addMediaListData.data && addMediaListData.data.is_next_page &&
                                <Col>
                                    <Button color='success' onClick={() => handleLoadMore(addMediaListData.data.page + 1)} >Load More ...</Button>
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col className='mt-20'>
                        <Button
                            // onClick={Submit_tmdb_id}
                            disabled={!(finalId && finalId !== '')}
                            style={{ width: '100%' }} color="primary">
                            Submit
                        </Button>
                    </Col>
                </ModalBody>
            </Modal>
        </div>
    )
}
