import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
// import CustomPagination from 'components/Pagination/CustomPagination';
import { getFaqList, editFaq, updateFaq, deleteFaq, clearMessage, addFaq } from "../../redux/actions/managePage.action"
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from 'components/Pagination/CustomPagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

export default function Faq() {
    const dispatch = useDispatch();

    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [editId, setEditId] = React.useState('')
    const [actionType, setActionType] = React.useState('')
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [searchText, setSearchText] = React.useState('');

    // edit/update data
    const [question, setQuestion] = React.useState('');
    const [answer, setAnswer] = React.useState('');
    const [order, setOrder] = React.useState(1);

    const faqList = useSelector((state) => state.managePage && state.managePage.faqListResult ? state.managePage.faqListResult : {});
    const status = useSelector((state) => state.managePage && state.managePage.status)
    const errMesg = useSelector((state) => state.managePage && state.managePage.errMesg)

    const inputFileRef = React.useRef(null);

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    const handleEdit = (data) => {
        setActionType('edit')
        setEditId(data.uuid)
        setQuestion(data.question);
        setAnswer(data.answer);
        setOrder(data.order);
        setIsAddPlatform(!isAddPlatform);
        ReactGA.modalview(window.location.pathname+"?modal=Edit Faq");
    }

    const handleAddFaq = () => {
        setEditId('')
        setActionType('add');
        setQuestion('');
        setAnswer('');
        setOrder(1);
        setIsAddPlatform(!isAddPlatform);
        ReactGA.modalview(window.location.pathname+"?modal=Add Faq");
    }


    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(getFaqList(searchText, currentPage, pageLimit));
            setIsAddPlatform(false);
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);
    React.useEffect(() => {
        dispatch(getFaqList(searchText, currentPage, pageLimit));
    }, [searchText, currentPage, pageLimit])

    const handleUpdate = (type) => {
        if (type == 'Save') {
            const body = {
                question: question,
                answer: answer,
                order: order
            }
            eventReporting('Manage Page', 'Faq added - ', question);
            dispatch(addFaq(body));
        } else {
            const body = {
                question: question,
                answer: answer,
                order: order
            }
            eventReporting('Manage Page', 'Faq updated - ' + question, editId);
            dispatch(updateFaq(editId, body));
        }
    }

    return (
        <>
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>FAQ</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' onChange={(e)=> setSearchText(e.target.value)} />
                            <i className="fas fa-search"></i>
                        </Col>
                        <div>
                            <Button color="info" onClick={() => handleAddFaq()} >Add FAQ</Button>
                        </div>
                    </Row>
                    <Container style={{ overflowY: 'auto' }}>
                        <Table hover className="align-items-center table-flush mt-2">
                            <thead>
                                <tr>
                                    <th width="9%">
                                        S. No.
                                    </th>
                                    <th width="30%">
                                        Question
                                    </th >
                                    <th width="35%">
                                        Answer
                                    </th>
                                    <th>
                                        Order
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {faqList.data && faqList.data.results && faqList.data.results.length > 0 ? faqList.data.results.map((x, i) => {
                                    return <tr>
                                        <td scope="row">
                                            {i + 1}
                                        </td>
                                        <td>
                                            {x.question}
                                        </td>
                                        <td>
                                            {x.answer}
                                        </td>
                                        <td>
                                            {x.order}
                                        </td>
                                        <td>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="info"
                                                size="sm"
                                                type="button"
                                                style={{ marginRight: 10 }}
                                                onClick={() => handleEdit(x)}
                                            >
                                                <i className="fas fa-pen pt-1"></i>
                                            </Button>
                                            <Button
                                                className=" btn-icon btn-simple"
                                                color="danger"
                                                size="sm"
                                                type="button"
                                                onClick={() => {
                                                    if (window.confirm("Are you sure want to delete ?")) {
                                                        dispatch(deleteFaq(x.uuid))
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-trash pt-1"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                })
                                    :
                                    <tr>
                                        <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                    </tr>
                                }
                            </tbody>

                        </Table>
                        {faqList.data && faqList.data.pagination &&
                            <CustomPagination
                                pagination={faqList.data && faqList.data.pagination && faqList.data.pagination ? faqList.data.pagination : {}}
                                setCurrentPage={(val) => setCurrentPage(val)}
                                setPageLimit={(val) => setPageLimit(val)}
                                defaultPageList={faqList.data && faqList.data.results ? faqList.data.results.length : 10}
                            />
                        }
                    </Container>
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            {actionType == 'edit' ? 'Update' : 'Add'} FAQ
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={2} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Question</p>
                                </Col>
                                <Col sm={10}>
                                    <Input onChange={(e) => setQuestion(e.target.value)} value={question} className='w-100' />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={2} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Answer</p>
                                </Col>
                                <Col sm={10}>
                                    <Input onChange={(e) => setAnswer(e.target.value)} value={answer} className='w-100' />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={2} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Order</p>
                                </Col>
                                <Col sm={3}>
                                    <Input onChange={(e) => setOrder(e.target.value)} value={order} className='w-100' type='number' />
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="success"
                                onClick={() => handleUpdate(actionType == 'edit' ? 'Update' : 'Save')}
                            >
                                {actionType == 'edit' ? 'Update' : 'Save'}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
