import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';

const list = [1, 2, 3, 4, 5];

const style = { width: "150px" };
const style1 = { width: "50px" };


export default class Media extends Component {
    constructor(props) {
        super(props);

        this.modal = this.modal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleToggle = this.handleToggle.bind(this);

        this.state = {
            allDropDown: {
                isOpenAll: false,
                isOpenSort: false,
                isOpenLanguage: false,
                isOpenTags: false,
                isOpenCountry: false,
                isOpenGenr: false,
                isOpenOtt: false,
                isOpenOttType: false,
                isRating: false,
                isCertification: false,
                isRuntime: false,
                isRelease: false

            },
            modal: false,
        };
    }
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    handleToggle(name) {
        let newForm = {
            ...this.state.allDropDown,
            [name]: !this.state.allDropDown[name]
        }
        this.setState({
            allDropDown: newForm
        })
    }

    modal() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        console.log("dshfjdshfjsd", this.state.allDropDown)

        return (
            <>
                <Header />
                <div>
                    <Container>
                        <div className='media'>
                            <h3>
                                MEDIA - List
                            </h3>
                            <Button color="info" onClick={this.modal}>{this.props.buttonLabel}Add Media</Button>
                        </div>
                        <div className='media'>
                            <Col sm="12">
                                <Row className='search_sort'>
                                    <Col sm="1">
                                        <Dropdown color="black" mt={4} isOpen={this.state.allDropDown['isOpenAll']} toggle={() => this.handleToggle('isOpenAll')}>
                                            <DropdownToggle caret>
                                                ALL
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Movies</DropdownItem>
                                                <DropdownItem>Shows</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>

                                    <Col sm="5">
                                        <Form>
                                            <FormGroup>
                                                <Col sm={12} className="p-0 search-icon-box">
                                                    <Input placeholder='Search' />
                                                    <i className="fas fa-search"></i>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col sm="6">
                                        <Row>
                                            <Row className='ml-22'>
                                                <Col>
                                                    <h4 className="mt-2 mr-2">
                                                        Sort :
                                                    </h4>
                                                </Col>
                                                <Dropdown color="black" mt={4} isOpen={this.state.allDropDown['isOpenSort']} toggle={() => this.handleToggle('isOpenSort')}>
                                                    <DropdownToggle caret>
                                                        Alphabatically(A-Z)
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem>Alpabetically(Z-A)</DropdownItem>
                                                        <DropdownItem>Latest Release</DropdownItem>
                                                        <DropdownItem>Most searched</DropdownItem>
                                                        <DropdownItem>Rating(Highest to lowest)</DropdownItem>
                                                        <DropdownItem>Popularity(High to low)</DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </Row>
                                            <Col >
                                                <Button className='btn-h-100' size='sm' color="success">Download CSV</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Col>
                        </div>
                        <Container>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col >
                                            <FormGroup className='m-0'>
                                                <Input type="date" name="date" id="exampleDate" placeholder="From" />
                                            </FormGroup>
                                        </Col>
                                        <Col >
                                            <FormGroup className='m-0'>
                                                <Input type="date" name="date" id="exampleDate" placeholder="To" />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenCountry']} toggle={() => this.handleToggle('isOpenCountry')} >
                                                <DropdownToggle caret>
                                                    Select Country
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenGenr']} toggle={() => this.handleToggle('isOpenGenr')} >
                                                <DropdownToggle caret>
                                                    Select Genre
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row className='mt-15'>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenLanguage']} toggle={() => this.handleToggle('isOpenLanguage')} >
                                                <DropdownToggle caret>
                                                    Select Language
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenTags']} toggle={() => this.handleToggle('isOpenTags')} >
                                                <DropdownToggle caret>
                                                    Select Tags
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenOtt']} toggle={() => this.handleToggle('isOpenOtt')} >
                                                <DropdownToggle caret>
                                                    Select OTT
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isOpenOttType']} toggle={() => this.handleToggle('isOpenOttType')} >
                                                <DropdownToggle caret>
                                                    Select OTT Type
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <Row className='mt-15'>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isRating']} toggle={() => this.handleToggle('isRating')} >
                                                <DropdownToggle caret>
                                                    Select Rating
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isCertification']} toggle={() => this.handleToggle('isCertification')} >
                                                <DropdownToggle caret>
                                                    Select Certification
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isRuntime']} toggle={() => this.handleToggle('isRuntime')} >
                                                <DropdownToggle caret>
                                                    Select Runtime
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>{`>1hr`}</DropdownItem>
                                                    <DropdownItem>1hr-2hr</DropdownItem>
                                                    <DropdownItem>2hr+</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={this.state.allDropDown['isRelease']} toggle={() => this.handleToggle('isRelease')} >
                                                <DropdownToggle caret>
                                                    Select Release year
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Option 1</DropdownItem>
                                                    <DropdownItem>Option 2</DropdownItem>
                                                    <DropdownItem>Option 3</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                    <div className='mt-20 text-right'>
                                        <Button color="primary">Apply</Button>
                                    </div>
                                </CardBody>
                                <Container className='table'>
                                    <Table hover className="align-items-center table-flush" >
                                        <thead >
                                            <tr>
                                                <th rowspan="2" className='table_heading'>Sr No.</th>
                                                <th rowspan="2" className='table_heading'>Title</th>
                                                <th rowspan="2" className='table_heading'>Type</th>
                                                <th rowspan="2" className='table_heading'>Poster</th>
                                                <th colspan="3" style={{ textAlign: 'center' }} className='table_heading'>Information

                                                </th>
                                                <th rowspan="2" className='table_heading'>Action</th>
                                            </tr>
                                            <tr>
                                                <td>Rating </td>
                                                <td>Release Date</td>
                                                <td>Trailers</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((x, i) => {
                                                return <tr>
                                                    <th scope="row">2</th>
                                                    <td>Content Title</td>
                                                    <td>Movie</td>
                                                    <td>  <img
                                                        alt="..."
                                                        className=" img-fluid rounded shadow-lg"
                                                        src="https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-3.jpg"
                                                        style={style1}
                                                    ></img> </td>
                                                    <td>7.8</td>
                                                    <td>04/04/2022</td>
                                                    <td>2</td>
                                                    <td className=" td-actions ">
                                                        <Link to="/admin/addmedia" >
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                                style={{ marginRight: 10 }}
                                                            // onClick={() => this.setState({ isEditOrCreate: !this.state.isEditOrCreate })}
                                                            >
                                                                <i className="fas fa-pen pt-1"></i>
                                                            </Button>
                                                        </Link>
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="danger"
                                                            size="sm"
                                                            type="button"
                                                        >
                                                            <i className="fas fa-trash pt-1"></i>
                                                        </Button>
                                                    </td>

                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                </Container>
                            </Card>
                        </Container>

                    </Container>
                    <Modal isOpen={this.state.modal} toggle={this.modal} >
                        <ModalHeader toggle={this.modal}>
                            Add Media

                        </ModalHeader>
                        <ModalBody >
                            <Form>
                                <FormGroup>
                                    <Input type="search" name="search" id="exampleSearch" placeholder="Search" />
                                </FormGroup>
                            </Form>
                            <Row>
                                <Col className=" mt-5 mt-sm-0" sm="3" xs="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid rounded shadow-lg"
                                        src="https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-3.jpg"
                                        style={style}
                                    ></img>
                                    <large className=" d-block mb-4 text-center mt-10">
                                        My Movie
                                    </large>
                                </Col>
                                <Col className=" mt-5 mt-sm-0" sm="3" xs="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid rounded shadow-lg"
                                        src="https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-3.jpg"
                                        style={style}
                                    ></img>
                                    <large className=" d-block mb-4 text-center mt-10">
                                        My Movie
                                    </large>
                                </Col>
                                <Col className=" mt-5 mt-sm-0" sm="3" xs="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid rounded shadow-lg"
                                        src="https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-3.jpg"
                                        style={style}
                                    ></img>
                                    <large className=" d-block mb-4 text-center mt-10">
                                        My Movie
                                    </large>
                                </Col>
                                <Col className=" mt-5 mt-sm-0" sm="3" xs="6">
                                    <img
                                        alt="..."
                                        className=" img-fluid rounded shadow-lg"
                                        src="https://demos.creative-tim.com/argon-design-system-pro/assets/img/faces/team-3.jpg"
                                        style={style}
                                    ></img>
                                    <large className=" d-block mb-4 text-center mt-10">
                                        My Movie
                                    </large>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                </div>
            </>
        )
    }
}
