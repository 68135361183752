import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";
import axios from "axios";
import store from '../../redux/store'

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.COUPON_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getCouponList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_GET_REQUEST });

        axiosInstance
            .get(`subscription/couponlist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.COUPON_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.COUPON_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateCoupon = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_UPDATE_REQUEST });

        axiosInstance
            .put(`subscription/coupondetail/${id}`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.COUPON_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.COUPON_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deleteCoupon = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_DELETE_REQUEST });

        axiosInstance
            .delete(`subscription/coupondetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.COUPON_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.COUPON_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addCoupon = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_ADD_REQUEST });

        axiosInstance
            .post(`subscription/couponlist`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.COUPON_ADD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.COUPON_ADD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const bulkCouponUpload_old = (formData) => {
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_BULK_UPLOAD_REQUEST });
        axiosInstance
            .post(`subscription/bulkcoupons`, formData)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    debugger
                    dispatch({
                        type: WEB.COUPON_BULK_UPLOAD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.COUPON_BULK_UPLOAD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const bulkCouponUpload = (formData) => {
    const { auth } = store.getState();
    if (!auth.token) {
        return true;
    }
    return (dispatch) => {
        dispatch({ type: WEB.COUPON_BULK_UPLOAD_REQUEST });
        var config = {
            method: 'post',
            url: process.env.REACT_APP_BASE_URL+'subscription/bulkcoupons',
            // url: process.env.REACT_APP_STAGING_BASE_URL+'subscription/bulkcoupons',
            headers: {
                'Authorization': `Bearer ${auth.token}`,
                'Content-Type': 'multipart/form-data',
            },
            data: formData
        };

        axios(config)
            .then(function (res) {
                console.log(JSON.stringify(res.data));
                const payload = res.data;
                    dispatch({
                        type: WEB.COUPON_BULK_UPLOAD_SUCCESS,
                        payload: payload,
                    })
            })
            .catch(function (err) {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.COUPON_BULK_UPLOAD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            });

    }

}

// export const bulkCouponUpload = (formData) => {
//     debugger
//     return (dispatch) => {

//         var config = {
//             method: 'post',
//             url: 'https://liyfd3daif.execute-api.ap-south-1.amazonaws.com/stage_server/frenzee/subscription/bulkcoupons',
//             headers: {
//                 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjo0ODA0NTkwNjMxLCJqdGkiOiIyOGVjZjlkM2ViNDM0MWI1OTU5NmUxMjJlNDczOTU5ZSIsInV1aWQiOiJhNjcxY2FmYi04ZDA2LTQxMTktYTczMi02YWI3ZDUxMDQ5NDQifQ.t8Vbb8IW8xcjA5Drv4tZyMYxmaT1CF03K1l5Tl4OXs0',
//                 'Content-Type': 'multipart/form-data',
//             },
//             data: formData
//         };

//         axios(config)
//             .then(function (response) {
//                 debugger
//                 console.log(JSON.stringify(response.data));
//             })
//             .catch(function (error) {
//                 debugger
//                 console.log(error);
//             });

//     }

// }

