import React from "react";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";
import { useSelector, useDispatch } from 'react-redux'
import { login, clearMessage, forgotPassword, otpValidation, passwordMatch } from '../../redux/actions/auth.action'
import Loader from "views/common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

function Login(props) {
    const [isForgotPassword, setIsForgotPassword] = React.useState(false);
    const [isOtp, setIsOtp] = React.useState(false);
    const [isOtpVerified, setIsOtpVerified] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [resetUsername, setResetUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.auth && state.auth.isLoading ? state.auth.isLoading : false)
    const status = useSelector((state) => state.auth && state.auth.status ? state.auth.status : '')
    const errMess = useSelector((state) => state.auth && state.auth.errMess ? state.auth.errMess : '')

    const handleFormSubmit = () => {
        var body = {
            email: username,
            password: password
        }
        dispatch(login(body, props.history))
    }

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (status == "Mail sent successfully") {
                setIsOtp(true);
            }
            if (status == "Correct OTP") {
                setIsOtpVerified(true);
            }
            if (status == "Password Updated") {
                setNewPassword('');
                setConfirmPassword('');
                setResetUsername('');
                setOtp('');
                setIsForgotPassword(false);
                setIsOtp(false);
            }
            dispatch(clearMessage());
        }

        if (errMess && errMess != "") {
            toast.error((errMess), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMess, status])

    const toastError = (mesg) => {
        toast.error((mesg), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const handleFormResetSubmit = () => {
        if (isForgotPassword && !isOtp) {
            if (resetUsername && resetUsername != "") {
                eventReporting('Auth', 'Forgot password email sent to - ' + resetUsername, 0);
                var body = {
                    email: resetUsername,
                }
                dispatch(forgotPassword(body));
            } else {
                toastError("Email can't be empty");
            }
        } else if (isForgotPassword && isOtp) {
            if (confirmPassword && confirmPassword != "" && newPassword && newPassword != "") {
                if (confirmPassword === newPassword) {
                    eventReporting('Auth', 'Password changed for - ' + resetUsername, otp);
                    var body = {
                        code: otp,
                        email: resetUsername,
                        password: confirmPassword
                    }
                    dispatch(passwordMatch(body));
                } else {
                    toastError("New Password and Confirm Password doesn't match");
                }
            } else {
                toastError("Password can't be empty");
            }
        }
    }

    return (
        <>
            {isLoading && <Loader />}
            <ToastContainer />
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    {isForgotPassword ?
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h3>Forgotten Password?</h3>
                                <small>{!isOtp ? 'Enter your email to reset your password' : 'Enter Sent OTP and New Password'}</small>
                            </div>
                            <Form role="form">
                                {!isOtp ?
                                    <FormGroup className="mb-3">
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Email"
                                                type="email"
                                                autoComplete="new-email"
                                                value={resetUsername}
                                                onChange={(e) => setResetUsername(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    : <>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-settings-gear-65" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Enter OTP"
                                                    type="text"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="New Password"
                                                    type="password"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Confirm Password"
                                                    type="password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                    </>
                                }
                                <div className="text-center">
                                    <Button
                                        onClick={() => handleFormResetSubmit()}
                                        className="my-4" color="primary" type="button"
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        onClick={() => setIsForgotPassword(false)}
                                        className="my-4" color="dark" type="button"
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                        :
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <h3>Welcome to Frenzi Admin!</h3>
                                <small>Enter your username and password</small>
                            </div>
                            <Form role="form">
                                <FormGroup className="mb-3">
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="email"
                                            autoComplete="new-email"
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-lock-circle-open" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="new-password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            onKeyUp={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleFormSubmit();
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id=" customCheckLogin"
                                    type="checkbox"
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor=" customCheckLogin"
                                >
                                    <span className="text-muted">Remember me</span>
                                </label>
                            </div> */}
                                <div className="text-center">
                                    <Button
                                        onClick={() => handleFormSubmit()}
                                        className="my-4" color="primary" type="button"
                                    >
                                        Sign in
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    }
                </Card>
                <Row className="mt-2">
                    <Col xs="6">
                        <p
                            className="text-light pointer"
                            onClick={(e) => setIsForgotPassword(true)}
                        >
                            <small>Forgot password?</small>
                        </p>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default Login;
