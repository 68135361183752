import React from 'react'
import { Card, Col, Container, Row } from 'reactstrap'
import ReactGA from 'react-ga';

export default function Unauthorized() {

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <>
      <Container>
        <Card className='mt-4 p-3 text-center'>
          <div className="w3-display-middle">
            <h1 className="w3-jumbo w3-animate-top w3-center"><code>Access Denied</code></h1>
            <h3 className="w3-center w3-animate-right">You don't have permission to view this page.</h3>
            <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
            <h6 className="w3-center w3-animate-zoom">Contact to administrator</h6>
          </div>
        </Card>
      </Container>
    </>
  )
}
