import React, { Component } from 'react'
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Row, Col, Container, Form, FormGroup,
    Input, Button, Table,

} from 'reactstrap';
import Header from "components/Headers/Header.js";
import './_operations.scss'

const list = [1, 2, 3, 4, 5, 6];

export default class NotificationList extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.modal = this.modal.bind(this);

        this.state = {
            dropdownOpen: false,
            modal: false,
            activeTab: '1'
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    modal() {
        this.setState({
            modal: !this.state.modal
        });
    }
    render() {
        return (
            <>
                <Header />
                <Container fluid>
                    <div>
                        <Row className='search_div'>
                            <Col>
                                <Row>
                                    <Col sm="6">
                                        <Form>
                                            <FormGroup>
                                                <Col sm={12} className="p-0 search-icon-box">
                                                    <Input placeholder='Search' />
                                                    <i className="fas fa-search"></i>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Col className="download">
                                        <Button color="success" className='download_button'>New Notification</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            {/* <Label for="exampleDate">Date</Label> */}
                                            <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <Form>
                                            <FormGroup>
                                                {/* <Label for="exampleDate">Date</Label> */}
                                                <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" />
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    <Row className='notification-sort' >
                                        <Col>
                                            <h4 className="mt-2">
                                                Sort :
                                            </h4>
                                        </Col>
                                        <Col>
                                            <Dropdown color="black" mt={4} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                                <DropdownToggle caret>
                                                    Alphabatically(A-Z)
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Schedule-sent</DropdownItem>
                                                    <DropdownItem>Type</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </Row>
                                {/* <Container className='table'> */}
                                    <Table hover>
                                        <thead >
                                            <tr>
                                                <th rowspan="2" className='table_heading'>Sr No.</th>
                                                <th rowspan="2" className='table_heading'>Notification</th>
                                                <th rowspan="2" className='table_heading'>Type</th>
                                                <th rowspan="2" className='table_heading'>Time</th>
                                                <th colspan="4" style={{ textAlign: 'center' }} className='table_heading'>Stats
                                                </th>
                                                <th rowspan="2" className='table_heading'>Sent By</th>
                                                <th rowspan="2" className='table_heading'>Action</th>
                                            </tr>
                                            <tr>
                                                <th>Total sent </th>
                                                <th>Total received</th>
                                                <th>Opened</th>
                                                <th>Dismissed</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ backgroundColor: '#fff' }}>
                                            {list.map((x, i) => {
                                                return <tr>
                                                    <th scope="row">1</th>
                                                    <td>Frenzi recommends xxxx</td>
                                                    <td>Email</td>
                                                    <td>June 30 21 15:09</td>
                                                    <td>1000</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>admin1@gmail.com</td>
                                                    <td>
                                                        <Row>
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="info"
                                                                size="sm"
                                                                type="button"
                                                            >
                                                                <i className="fas fa-pen pt-1"></i>
                                                            </Button>

                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="danger"
                                                                size="sm"
                                                                type="button"
                                                            >
                                                                <i className="fas fa-trash pt-1"></i>
                                                            </Button>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                {/* </Container> */}
                            </Col>
                        </Row>
                    </div>
                </Container>

            </>
        )
    }
}
