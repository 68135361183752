import { User } from "../constants/constants";
import axiosInstance from "../../helpers/axios";


export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: User.REVIEW_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: User.REVIEW_CLEAR_MESSAGE_SUCCESS });
        }
    }
}

export const getReportedUsers = (value = "", page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_REPORTED_USERS_REQUEST });

        axiosInstance
            .get(`native_main/userreportsearch?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: User.GET_REPORTED_USERS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_REPORTED_USERS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const getUserProfileDetails = (uuid) => {
    return (dispatch) => {
        dispatch({ type: User.GET_USER_PROFILE_DETAILS_REQUEST });

        axiosInstance
            .get(`adminpanel/userdetail/${uuid}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: User.GET_USER_PROFILE_DETAILS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_USER_PROFILE_DETAILS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const activateDeactivateUsers = (id, body) => {
    return (dispatch) => {
        dispatch({ type: User.ACTIVATE_DEACTIVATE_REQUEST });

        axiosInstance
            .put(`adminpanel/user_status_update/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.ACTIVATE_DEACTIVATE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.ACTIVATE_DEACTIVATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const reportUser = (body) => {
    return (dispatch) => {
        dispatch({ type: User.REPORT_USER_REQUEST });

        axiosInstance
            .post(`native_main/userreport`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.REPORT_USER_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.REPORT_USER_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}



export const deleteReport = (id) => {
    return (dispatch) => {
        dispatch({ type: User.DELETE_USER_REPORT_REQUEST });

        axiosInstance
            .delete(`native_main/userreport/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.DELETE_USER_REPORT_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.DELETE_USER_REPORT_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getUserFrenziPoints = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_FRENZI_POINTS_REQUEST });

        axiosInstance
            .get(`tracking/pointsdetail/${id}?page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_FRENZI_POINTS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_FRENZI_POINTS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getBlockedBy = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_BLOCKED_BY_REQUEST });

        axiosInstance
            .get(`adminpanel/userdetail/${id}/blockedby?page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_BLOCKED_BY_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_BLOCKED_BY_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getReportedBy = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_REPORTED_BY_REQUEST });

        axiosInstance
            .get(`adminpanel/userdetail/${id}/reportedby?page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_REPORTED_BY_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_REPORTED_BY_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getInactiveUsers = (val = "",page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_INACTIVE_USERS_REQUEST });

        axiosInstance
            .get(`adminpanel/inactiveuserslist?search=${val}&page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_INACTIVE_USERS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_INACTIVE_USERS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getBlockedTo = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_BLOCKED_TO_REQUEST });

        axiosInstance
            .get(`adminpanel/userdetail/${id}/blockedby?section=blocked_to&page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_BLOCKED_TO_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_BLOCKED_TO_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getUserWatchList = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_USER_WATCHLIST_REQUEST });

        axiosInstance
            .get(`native_main/adminwatchlist?user=${id}&page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_USER_WATCHLIST_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_USER_WATCHLIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getUserWatchListMembers = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_USER_WATCHLIST_MEMBERS_REQUEST });

        axiosInstance
            .get(`native_main/adminwatchlistmember?watchlist=${id}&page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_USER_WATCHLIST_MEMBERS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_USER_WATCHLIST_MEMBERS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getUserWatchListMedia = (id, page = 1, pageLimit = 20) => {
    return (dispatch) => {
        dispatch({ type: User.GET_USER_WATCHLIST_MEDIA_REQUEST });

        axiosInstance
            .get(`native_main/adminwatchlistmedia?watchlist=${id}&page=${page}&pagelimit=${pageLimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: User.GET_USER_WATCHLIST_MEDIA_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: User.GET_USER_WATCHLIST_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}
