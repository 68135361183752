import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import './../subscription/_subscriptions.scss';
import './_collection.scss';
import { useSelector, useDispatch } from 'react-redux'

import { getYoutubeCollectionList, deleteCategory, clearMessage, getYoutubeCollectionListgory, updateCategory, addYTBCollection, updateYTBCollection, deleteYTBCollection } from '../../redux/actions/categories.action'
import CustomPagination from 'components/Pagination/CustomPagination';
import SampleImage from "../../assets/img/theme/team-4-800x800.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import CustomPagination from 'components/Pagination/CustomPagination';
import AWS from 'aws-sdk'
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

export default function YoutubeCollection() {
    const dispatch = useDispatch()

    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const inputFileRef = React.useRef(null);
    const [colloectionPosterPath, setCollectionPosterPath] = React.useState('');
    const [collectionName, setCollectionName] = React.useState('');
    const [collectionDesc, setCollectionDesc] = React.useState('');
    const [inSection, setInsection] = React.useState('');
    const [searchText, setSearchText] = React.useState('');

    const ytdCollectionListResult = useSelector((state) => state.categories && state.categories.ytdCollectionListResult ? state.categories.ytdCollectionListResult : {})
    const status = useSelector((state) => state.categories && state.categories.status ? state.categories.status : '')
    const errMesg = useSelector((state) => state.categories && state.categories.errMesg ? state.categories.errMesg : '')
    const isLoading = useSelector((state) => state.categories && state.categories.isLoading ? state.categories.isLoading : false)


    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            getResults();
            setIsAddPlatform(false);
            setCollectionName("");
            setCollectionDesc("");
            setInsection("");
            setCollectionPosterPath("");
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    const getResults = () => {
        dispatch(getYoutubeCollectionList(searchText, currentPage, pageLimit));
    }

    React.useEffect(() => {
        dispatch(getYoutubeCollectionList(searchText, currentPage, pageLimit));
    }, [currentPage, pageLimit,])

    const handleSearch = () => {
        setCurrentPage(1);
        dispatch(getYoutubeCollectionList(searchText, 1, pageLimit));
    }

    const handlePosterUpdate = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "collection_poster/collection-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                eventReporting('YouTube Collection', "Uploaded file to s3 bucket -" + keyName, data.Location);
                setCollectionPosterPath(data.Location);
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleCategoryUpdate = (e) => {
        if (collectionName && collectionName != '') {
            var body = {
                name: collectionName,
                description: collectionDesc,
                in_section: inSection && inSection != "" ? inSection : null,
                image: colloectionPosterPath && colloectionPosterPath != "" ? colloectionPosterPath : null
            }
            eventReporting('YouTube Collection', 'Collection added - ' + collectionName, collectionName);
            dispatch(addYTBCollection(body));
        }
    }

    const handleStatusChange = (e, id) => {
        const body = {
            is_active: e.target.checked
        }
        eventReporting('YouTube Collection', 'Collection status changed to - ' + e.target.checked, id);
        dispatch(updateYTBCollection(id, body))
    }

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>YouTube Collection list</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search'
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                                onKeyUp={(e) => { if (e.keyCode == 13) { handleSearch() } }}
                            />
                            <i className="fas fa-search" onClick={() => handleSearch()}></i>
                        </Col>
                        <div>
                            <Button color="info" >Download CSV</Button>
                            {/* <Link to="/admin/add-category"> */}
                            <Button color="info" onClick={() => {
                                setIsAddPlatform(!isAddPlatform);
                                ReactGA.modalview(window.location.pathname + '?modal=AddYTCollection')

                            }} >Add Collection</Button>
                            {/* </Link> */}
                        </div>
                    </Row>
                    <Container className='table mt-4'>
                        <Table hover className="align-items-center table-flush">
                            <thead>
                                <tr>
                                    <th width="10%" className='table_heading'>
                                        S.No
                                    </th>
                                    <th className='table_heading'>
                                        Title
                                    </th>
                                    <th className='table_heading'>
                                        Description
                                    </th>
                                    <th className='table_heading'>
                                        Image
                                    </th>
                                    <th className='table_heading'>
                                        In Section
                                    </th>
                                    <th className='table_heading'>
                                        Status
                                    </th>
                                    <th className='table_heading'>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                {
                                    ytdCollectionListResult && ytdCollectionListResult.data && ytdCollectionListResult.data.results && ytdCollectionListResult.data.results.length > 0 && ytdCollectionListResult.data.results.map((x, i) => {
                                        return <tr>
                                            <td scope="row">
                                                {(pageLimit * (currentPage - 1)) + (i + 1)}
                                            </td>
                                            <td>
                                                {x.name ? x.name : 'N/A'}
                                            </td>
                                            <td>
                                                {x.description ? x.description : 'N/A'}
                                            </td>
                                            <td className='category_img'>

                                                {x.image && x.image != "" && x.image != null ?
                                                    <img
                                                        alt="..."
                                                        className="category_img rounded shadow-lg"
                                                        src={x.image ? x.image : ''}
                                                    ></img>
                                                    :
                                                    <div className='d-img'>
                                                        <i className='fas fa-image'></i>
                                                    </div>
                                                }
                                            </td>
                                            <td style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                {x.in_section ? x.in_section : 'N/A'}
                                            </td>
                                            <td>
                                                <div className='text-right'>
                                                    <CustomInput
                                                        checked={x.is_active ? x.is_active : false}
                                                        type="switch"
                                                        id={`exampleCustomSwitch-${i}`}
                                                        name="customSwitch"
                                                        className='p-0'
                                                        onChange={(e) => handleStatusChange(e, x.uuid)}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{width : 120}}>
                                                <Link to={`/admin/edit-collection/${x.uuid}`}>
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="info"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 10 }}
                                                    // onClick={() => handleEdit(i)}
                                                    >
                                                        <i className="fas fa-pen pt-1"></i>
                                                    </Button>
                                                </Link>
                                                <Button
                                                    className=" btn-icon btn-simple"
                                                    color="danger"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => {
                                                        if (window.confirm("Are you sure want to delete this ?")) {
                                                            eventReporting('YouTube Collection', 'Collection deleted', x.uuid);
                                                            dispatch(deleteYTBCollection(x.uuid));
                                                        }
                                                    }}
                                                >
                                                    <i className="fas fa-trash pt-1"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    })}
                            </tbody>
                        </Table>
                        <div className='text-right mt-2'>
                            {ytdCollectionListResult.data && ytdCollectionListResult.data.pagination &&
                                <CustomPagination
                                    pagination={ytdCollectionListResult.data && ytdCollectionListResult.data.pagination && ytdCollectionListResult.data.pagination ? ytdCollectionListResult.data.pagination : {}}
                                    setCurrentPage={(val) => setCurrentPage(val)}
                                    setPageLimit={(val) => setPageLimit(val)}
                                    defaultPageList={ytdCollectionListResult.data && ytdCollectionListResult.data.results ? ytdCollectionListResult.data.results.length : 20}
                                />
                            }
                        </div>
                    </Container>
                    {/* <CustomPagination total={list.length} result={1} /> */}
                    <Modal
                        isOpen={isAddPlatform}
                        toggle={() => setIsAddPlatform(!isAddPlatform)}
                    >
                        <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)}>
                            Add Collection
                        </ModalHeader>
                        <ModalBody>
                            <Row className='pl-3 pr-3 mt-1 w-100 align-items-center '>
                                <Col className="p-0">
                                    <p className='pt-3'>Enter Collection Name</p>
                                </Col>
                                <Col className="mt-2">
                                    <Input placeholder='Collection Name' value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-1 w-100 align-items-center '>
                                <Col className="p-0">
                                    <p className='pt-3'>Description</p>
                                </Col>
                                <Col className="mt-2">
                                    <Input placeholder='Collection Description' value={collectionDesc} onChange={(e) => setCollectionDesc(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-1 w-100 align-items-center '>
                                <Col className="p-0">
                                    <p className='pt-3'>In Section</p>
                                </Col>
                                <Col className="p-0">
                                    <Row>
                                        <FormGroup check style={{ marginLeft: 20 }}>
                                            <Input
                                                id="exampleCheck"
                                                name="trending"
                                                type="checkbox"
                                                checked={inSection == 'trending' ? true : false}
                                                onChange={(e) => setInsection(e.target.checked ? 'trending' : '')}
                                            />
                                            <Label
                                                check
                                                for="exampleCheck"
                                            >
                                                Trending
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check style={{ marginLeft: 20 }}>
                                            <Input
                                                id="exampleCheck2"
                                                name="free"
                                                type="checkbox"
                                                checked={inSection == 'free' ? true : false}
                                                onChange={(e) => setInsection(e.target.checked ? 'free' : '')}
                                            />
                                            <Label
                                                check
                                                for="exampleCheck"
                                            >
                                                Free
                                            </Label>
                                        </FormGroup>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='pl-3 pr-3 mt-3 w-100 '>
                                <Col className="p-0">
                                    <p className="pt-2">Upload File</p>
                                </Col>
                                <Col className="mt-2">
                                    <Input type="file" accept='image/*' onChange={(e) => handlePosterUpdate(e)} />
                                    {colloectionPosterPath && colloectionPosterPath != "" &&
                                        <img src={colloectionPosterPath} style={{ width: 40, height: 50, borderRadius: 3 }} />
                                    }
                                </Col>
                            </Row>
                        </ModalBody>
                        <div className='text-center mb-3'>
                            <Button
                                color="info"
                                onClick={(e) => handleCategoryUpdate(e)}
                                disabled={collectionName && collectionName != "" ? false : true}
                            >
                                Save
                            </Button>
                        </div>
                    </Modal>
                </Card>
            </Container>
        </>
    )
}
