import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.PLATFORM_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getPlatformList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_GET_REQUEST });

        axiosInstance
            .get(`subscription/platformlist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getPlatformData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_GET_DETAIL_REQUEST });

        axiosInstance
            .get(`subscription/platformdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_GET_DETAIL_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_GET_DETAIL_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updatePlatform = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_UPDATE_REQUEST });

        axiosInstance
            .put(`subscription/platformdetail/${id}`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PLATFORM_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deletePlatform = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_DELETE_REQUEST });

        axiosInstance
            .delete(`subscription/platformdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PLATFORM_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addPlatform = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_ADD_REQUEST });

        axiosInstance
            .post(`subscription/platformlist`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PLATFORM_ADD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_ADD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getPlatformPriceList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PRICE_GET_REQUEST });

        axiosInstance
            .get(`subscription/platformdetaillist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_PRICE_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PRICE_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updatePlatformPrice = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PRICE_UPDATE_REQUEST });

        axiosInstance
            .put(`subscription/platformdetaildetail/${id}`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PLATFORM_PRICE_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PRICE_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getPlatformPriceData = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PRICE_GET_DETAIL_REQUEST });

        axiosInstance
            .get(`subscription/platformdetaildetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_PRICE_GET_DETAIL_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PRICE_GET_DETAIL_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deletePlatformPrice = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PRICE_DELETE_REQUEST });

        axiosInstance
            .delete(`subscription/platformdetaildetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PLATFORM_PRICE_DELETE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PRICE_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addPlatformPrice = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PRICE_ADD_REQUEST });

        axiosInstance
            .post(`subscription/platformdetaillist`, { ...body })
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.PLATFORM_PRICE_ADD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PRICE_ADD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getPlanTypes = () => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PLAN_TYPES_GET_REQUEST });

        axiosInstance
            .get(`subscription/plantypes`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_PLAN_TYPES_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PLAN_TYPES_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getPlatformPriceDropDown = (type,pagelimit = 1000) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PRICE_DROPDOWN_GET_REQUEST });

        axiosInstance
            .get(`subscription/platformdropdown?plan_type=${type}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_PRICE_DROPDOWN_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PRICE_DROPDOWN_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getPlanPriceDropDown = (pagelimit = 1000) => {
    return (dispatch) => {
        dispatch({ type: WEB.PLATFORM_PLAN_DROPDOWN_GET_REQUEST });

        axiosInstance
            .get(`subscription/plandropdown?pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.PLATFORM_PLAN_DROPDOWN_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.PLATFORM_PLAN_DROPDOWN_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateWatchOnTv = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.WATCH_ON_TV_UPDATE_REQUEST });

        axiosInstance
            .put(`adminpanel/ottplatformdetail/${id}`, { ...body })
            .then((res) =>{
                if(res.status === 200){
                    const payload = res.data;
                    dispatch({
                        type: WEB.WATCH_ON_TV_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.WATCH_ON_TV_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}