import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Form,
  //  FormGroup,
  //  InputGroupAddon,
  // InputGroupText,
  //Input,
  //InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { logout } from '../../redux/actions/auth.action'
import { useSelector, useDispatch } from 'react-redux'
import React from "react";

const AdminNavbar = (props) => {
  const [userLocalData, setUserLocalData] = React.useState({});

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth && state.auth.userData ? state.auth.userData : {})

  const handleLogout = () => {
    dispatch(logout(props.history));
  }

  React.useEffect(() => {
    var data = localStorage.getItem("frenzi_admin_access");
    const userDetail = JSON.parse(data);
    if (userDetail && userDetail.access) {
      setUserLocalData(userDetail);
    }
  }, [])

  React.useEffect(() => {
    if (userData && userData.access && userLocalData && userLocalData.access) {
      if (userData.access != userLocalData.access) {
        dispatch(logout(props.history));
      } else {
        setUserLocalData(userData);
      }
    }
  }, [userData])


  return (
    <>
      <Navbar className=" bg-gradient-info position-sticky sticky-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          {/*  <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center mt-0">
                  <span className="avatar avatar-sm rounded-circle">
                    {userLocalData && userLocalData.profile ?
                      <img
                        alt="..."
                        src={
                          require("../../assets/img/theme/team-4-800x800.jpg")
                            .default
                        }
                      />
                      :
                      <p>{userLocalData && userLocalData.username ? userLocalData.username.charAt(0) : 'A'}</p>
                    }
                  </span>
                  <Media className="ml-2 m-0 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {userLocalData && userLocalData.username ? userLocalData.username : 'Admin'}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem> */}
                 <DropdownItem to="/admin/change-password" tag={Link}>
                  <i className="ni ni-lock-circle-open" />
                  <span>Change Password</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#" onClick={() => handleLogout()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
