import React, { Component } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, Nav, NavItem, NavLink, TabContent, TabPane, CardText, CardTitle, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import './../subscription/_subscriptions.scss';
import { adminList, createAdmin, adminPermissionDropdown, deleteAdmin, updateAdmin, retriveAdminDetail, clearMessage } from "../../redux/actions/admin.action"
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from 'components/Pagination/CustomPagination';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';
import { CSVLink, CSVDownload } from "react-csv";

const headingArr = ['S.No.', 'UUID', 'Email', 'Is Active', 'Is Superuser'];

export default function AdminList() {
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();

    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false)
    const [isEditOrAdd, setIsEditOrAdd] = React.useState(false);
    const [actionType, setActionType] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [searchText, setSearchText] = React.useState('');
    const [activeAdminId, setActiveAdminId] = React.useState('');
    const [currentAdminData, setCurrentAdminData] = React.useState({});
    const [isReply, setIsReply] = React.useState(false)
    const [selectedPermission, setSelectedPermission] = React.useState([]);
    const [csvData, setCsvData] = React.useState([headingArr]);


    // admin data

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [reEnterPassword, setRePassword] = React.useState('');
    const [admissionPermission, setAdmissionPermission] = React.useState([]);

    const adminAccessList = useSelector((state) => state.adminAccess && state.adminAccess.adminPermoissionResult ? state.adminAccess.adminListResult : {})

    const permissionDropdown = useSelector((state) => state.adminAccess && state.adminAccess.adminPermoissionResult ? state.adminAccess.adminPermoissionResult : {})

    const adminRetriveDetail = useSelector((state) => state.adminAccess && state.adminAccess.adminRetriveDetail ? state.adminAccess.adminRetriveDetail : {})

    const status = useSelector((state) => state.adminAccess && state.adminAccess.status ? state.adminAccess.status : '')
    const isLoading = useSelector((state) => state.adminAccess && state.adminAccess.isLoading ? state.adminAccess.isLoading : false)
    const errMesg = useSelector((state) => state.adminAccess && state.adminAccess.errMesg ? state.adminAccess.errMesg : '')

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            label: val
        });
    }

    React.useEffect(() => {
        if (adminAccessList && adminAccessList.data && adminAccessList.data.results && adminAccessList.data.results.length > 0) {
            setCsvData([]);
            var records = adminAccessList.data.results;
            var tempArr = [];
            if (records.length > 0) {
                records.map((j, k) => {
                    let csvArr = [
                        (pageLimit * (currentPage - 1)) + (k + 1),
                        j.uuid,
                        j.email,
                        j.is_active,
                        j.is_superuser
                    ]
                    tempArr.push(csvArr);
                })
                setCsvData([headingArr, ...tempArr]);
            }
        }
    }, [adminAccessList])

    React.useEffect(() => {
        dispatch(adminList(searchText, currentPage, pageLimit));
    }, [searchText, currentPage, pageLimit])

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    React.useEffect(() => {
        dispatch(adminPermissionDropdown());
    }, [])

    React.useEffect(() => {
        if (adminRetriveDetail && adminRetriveDetail.data && adminRetriveDetail.data.uuid == activeAdminId) {
            setCurrentAdminData({ ...adminRetriveDetail.data });
        }
    }, [adminRetriveDetail])

    const handleStatusChange = (e, id) => {
        const body = {
            is_active: e.target.checked
        }
        eventReporting('Admin', 'Changed Admin status to - ' + e.target.checked, id);
        dispatch(updateAdmin(id, body))
    }

    const handleUpdateAdmin = () => {
        if (currentAdminData.uuid == activeAdminId) {
            const body = {
                admin_permissions: currentAdminData.admin_permissions
            }
            eventReporting('Admin', 'Changed Admin permission to - ' + JSON.stringify(currentAdminData.admin_permissions), activeAdminId);
            dispatch(updateAdmin(activeAdminId, body))
        }
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(adminList(searchText, currentPage, pageLimit));
            dispatch(clearMessage());
            setIsReply(false);
            setActiveAdminId('');
            setCurrentAdminData({});
            clearFormData();
            setIsEditOrAdd(false);
        }
        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [status, errMesg]);

    const clearFormData = () => {
        setEmail('');
        setPassword('');
        setRePassword('');
        setAdmissionPermission([]);
    }


    const getOptions = (items) => {
        const tempArr = [];
        return tempArr;
    }

    const handleMultiSelectChange = (e) => {
        var temp = [];
        if (e && e.length > 0) {
            e.map((y, j) => {
                temp.push(y.value);
            })
        }
        setSelectedPermission([...temp]);
        setAdmissionPermission([...temp]);
    }

    const handleMultiSelectEditChange = (e) => {
        var temp = [];
        if (e && e.length > 0) {
            e.map((y, j) => {
                temp.push(y.value);
            })
        }
        setCurrentAdminData({ ...currentAdminData, ['admin_permissions']: temp });
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this admin ?")) {
            eventReporting('Admin', 'Admin deleted', id);
            dispatch(deleteAdmin(id));
        }
    }

    const handleSave = (type) => {
        if (type == 'Add') {
            const body = {
                "email": email,
                "password": password,
                "re_enter_password": reEnterPassword,
                "admin_permissions": admissionPermission,
            }
            eventReporting('Admin', 'Admin Created - ' + email, email);
            dispatch(createAdmin(body));
        } else {
            const body = {
                "email": email,
                "password": password,
                "re_enter_password": reEnterPassword,
                "admin_permissions": admissionPermission,
            }
            eventReporting('Admin', 'Admin Updated - ' + email, email);
            dispatch(updateAdmin(body));
        }
    }

    const getPermissionOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: x,
                label: x,
            });
        })
        return temp;
    }

    const getPermissionValue = (opt) => {
        var temp = [];
        opt && opt.length > 0 && opt.map((x, i) => {
            temp.push({
                value: x,
                label: x,
            });
        })
        return temp;
    }



    return (
        <div>
            {isLoading && <Loader />}
            <ToastContainer />
            {!isEditOrAdd ? <>
                <h3 className='mb-3 mt-3'>Admin list</h3>
                <Row className='justify-content-between m-0'>
                    <Col sm={4} className="p-0 search-icon-box">
                        <Input placeholder='Search' value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                        <i className="fas fa-search"></i>
                    </Col>
                    <div>
                        <CSVLink data={csvData} onClick={() => {
                            eventReporting('Review', 'Ai reported review data exported to csv  - ', `Ai-Reported-Review-${pageLimit * (currentPage - 1) + 1} - ${pageLimit * (currentPage - 1) + pageLimit}.csv`);
                        }} filename={`Admin-List-${pageLimit * (currentPage - 1) + 1} - ${pageLimit * (currentPage - 1) + pageLimit}.csv`} className="btn btn-primary">Download CSV</CSVLink>
                        <Button color="info"
                            onClick={() => {
                                setIsEditOrAdd(true);
                                setActionType('add');
                                ReactGA.modalview(window.location.pathname + "?modal=AddAdmin");
                            }} >
                            Add Admin</Button>
                    </div>
                </Row>
                <Container className='table'>
                    <Table hover className='mt-3 align-items-center table-flush'>
                        <thead>
                            <tr>
                                <th width="9%" className='table_heading'>
                                    S.No
                                </th>
                                <th className='table_heading'>
                                    Email
                                </th>
                                <th className='table_heading'>
                                    Status
                                </th>
                                <th className='table_heading'>
                                    Permission
                                </th>
                                <th className='table_heading'>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className='vertical-middle'>
                            {adminAccessList && adminAccessList.data && adminAccessList.data.results && adminAccessList.data.results.length > 0 ? adminAccessList.data.results.map((x, i) => {
                                return <tr key={i}>
                                    <td scope="row">
                                        {(pageLimit * (currentPage - 1)) + (i + 1)}
                                    </td>
                                    <td>
                                        {x.email}
                                    </td>
                                    <td>
                                        <div className='text-center'>
                                            <CustomInput
                                                type="switch"
                                                id={`exampleCustomSwitch-${i}`}
                                                name="customSwitch"
                                                className='p-0'
                                                checked={x.is_active ? x.is_active : false}
                                                onChange={(e) => handleStatusChange(e, x.uuid)}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                dispatch(retriveAdminDetail(x.uuid));
                                                setActiveAdminId(x.uuid);
                                                setIsReply(!isReply);
                                            }}

                                        >
                                            <i className="fas fa-pen pt-1"></i>
                                        </Button>
                                    </td>
                                    <td>

                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                            onClick={() => handleDelete(x.uuid)}
                                        >
                                            <i className="fas fa-trash pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })
                                :
                                <tr>
                                    <td colSpan={6}><h2 className='text-center'>No Data Available</h2></td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    {adminAccessList.data && adminAccessList.data.pagination &&
                        <CustomPagination
                            pagination={adminAccessList.data && adminAccessList.data.pagination && adminAccessList.data.pagination ? adminAccessList.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={adminAccessList.data && adminAccessList.data.results ? adminAccessList.data.results.length : 10}
                        />
                    }
                </Container>
            </>
                :
                <>
                    <>
                        <Row className='justify-content-between mt-3 m-0'>
                            <Col sm={4} className="p-0 search-icon-box">
                                <h3 className=''>{actionType == "edit" ? 'Edit' : 'Add'} admin</h3>
                            </Col>
                            <div>
                                <Button color="darker" size="sm" onClick={() => {
                                    clearFormData();
                                    setIsEditOrAdd(!isEditOrAdd);
                                }} ><i className='fas fa-arrow-left mr-3' />Back</Button>
                            </div>
                        </Row>
                        <Row className='m-0 mt-3'>
                            <Col sm={2} className="p-0">
                                <p>Email</p>
                            </Col>
                            <Col sm={4} className="p-0">
                                <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className='m-0 mt-3'>
                            <Col sm={2} className="p-0">
                                <p>Password</p>
                            </Col>
                            <Col sm={4} className="p-0">
                                <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className='m-0 mt-3'>
                            <Col sm={2} className="p-0">
                                <p>Re-Enter Password</p>
                            </Col>
                            <Col sm={4} className="p-0">
                                <Input type='password' value={reEnterPassword} onChange={(e) => setRePassword(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className='m-0 mt-3'>
                            <Col sm={2} className="p-0">
                                <p>Select Permission</p>
                            </Col>
                            <Col sm={4} className="p-0">
                                <div className="d-flex m-100 justify-content-center">
                                    <Form className='w-100'>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            onChange={(e) => handleMultiSelectChange(e)}
                                            value={getPermissionValue(admissionPermission)}
                                            options={getPermissionOptions(permissionDropdown)}
                                            isMulti={true}
                                        />
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        <div className='text-center mt-3'>
                            <Button color="success" onClick={() => handleSave(actionType == "edit" ? 'Edit' : 'Add')} >{actionType == "edit" ? 'Update' : 'Save'}</Button>
                        </div>
                    </>
                </>
            }
            <Modal
                isOpen={isReply}
                toggle={() => setIsReply(!isReply)}
            >
                <ModalHeader className='p-3' toggle={() => setIsReply(!isReply)}>
                    <h3>Permission</h3>
                </ModalHeader>
                <div className='pb-3'>

                    <Row className='pl-3 mt-3 pr-3 w-100'>
                        <Col className="p-0">
                            <div className="d-flex m-100 justify-content-center pl-4">
                                <Form className='w-100'>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        onChange={(e) => handleMultiSelectEditChange(e)}
                                        value={getPermissionValue(currentAdminData.admin_permissions ? currentAdminData.admin_permissions : [])}
                                        options={getPermissionOptions(permissionDropdown)}
                                    />
                                </Form>
                            </div>
                        </Col>

                    </Row>
                    <div style={{ textAlign: 'center' }} className='mt-2'>
                        <Button color="info" onClick={() => handleUpdateAdmin()} >Update</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
