import React from "react";

const QrUpdation = (props) => {
  // console.log(props);
  return (
    <div>
      <input
        className="form-control"
        placeholder=""
        defaultValue={props.defaultValue}
        onChange={props.onChange}></input>
      <button
      className="btn m-2"
        type="submit"
        onClick={props.onClick}
      >
        Update
      </button>
    </div>
  );
};

export default QrUpdation;
