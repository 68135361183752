import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.FRENZI_KEY_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.FRENZI_KEY_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getFrenziValuesList = (value='', page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.FRENZI_KEY_GET_REQUEST });

        axiosInstance
            .get(`adminpanel/validvalues?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.FRENZI_KEY_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.FRENZI_KEY_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const addFrenziKey = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.FRENZI_KEY_POST_REQUEST });

        axiosInstance
            .post(`adminpanel/validvalues`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.FRENZI_KEY_POST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.FRENZI_KEY_POST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deleteFrenziKey = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.FRENZI_KEY_DELETE_REQUEST });

        axiosInstance
            .delete(`adminpanel/validvalues/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.FRENZI_KEY_DELETE_SUCCESS,
                        payload: payload,
                    })
                } else if (res.status === 204) {
                    const payload = { message: 'Key deleted succesfully' }
                    dispatch({
                        type: WEB.FRENZI_KEY_DELETE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.FRENZI_KEY_DELETE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const updateFrenziKey = (uuid,body) => {
    return (dispatch) => {
        dispatch({ type: WEB.FRENZI_KEY_UPDATE_REQUEST });

        axiosInstance
            .put(`adminpanel/validvalues/${uuid}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.FRENZI_KEY_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.FRENZI_KEY_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}
