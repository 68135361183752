import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";


export const getMediaNews = (value) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_NEWS_ARTICLE_REQUEST });
        axiosInstance
            .get(`native_main/inbetweenfeedsnews?search=${value}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_NEWS_ARTICLE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_NEWS_ARTICLE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateMediaNews = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_NEWS_REQUEST });

        axiosInstance
            .put(`native_main/inbetweenfeedsnews/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_NEWS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_NEWS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const addMediaNews = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_NEWS_REQUEST });

        axiosInstance
            .post(`native_main/inbetweenfeedsnews`, body)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.ADD_NEWS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_NEWS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const addFeed = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_FEED_REQUEST });

        axiosInstance
            .post(`native_main/inbetweenfeedsdatafornews`, body)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.ADD_FEED_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_FEED_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteMediaNews = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_NEWS_REQUEST });

        axiosInstance
            .delete(`native_main/inbetweenfeedsnews/${id}`)
            .then((res) => {
                if (res.status === 200 || res.status === 204) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_NEWS_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_NEWS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const inBetweenFeedType = () => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_INBETWEEN_FEEDS_TYPE_REQUEST });
        axiosInstance
            .get(`native_main/inbetweenfeedsdatafornews`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_INBETWEEN_FEEDS_TYPE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_INBETWEEN_FEEDS_TYPE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const inBetweenFeed = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_INBETWEEN_FEEDS_REQUEST });
        axiosInstance
            .get(`native_main/inbetweenfeedsnews/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_INBETWEEN_FEEDS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_INBETWEEN_FEEDS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getFaqList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_FAQ_REQUEST });
        axiosInstance
            .get(`native_main/faqlist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_FAQ_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_FAQ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const editFaq = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_FAQ_EDIT_REQUEST });
        axiosInstance
            .get(`native_main/faqdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_FAQ_EDIT_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_FAQ_EDIT_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateFaq = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_FAQ_REQUEST });
        axiosInstance
            .put(`native_main/faqdetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.UPDATE_FAQ_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_FAQ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const addFaq = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_FAQ_REQUEST });
        axiosInstance
            .post(`native_main/faqlist`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADD_FAQ_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_FAQ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const tncDetail = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_TNC_REQUEST });
        axiosInstance
            .get(`main/tncdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_TNC_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_TNC_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateTncDetail = (id,body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_TNC_REQUEST });
        axiosInstance
            .put(`main/tncdetail/${id}`,body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_TNC_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_TNC_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const deleteFaq = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_FAQ_REQUEST });
        axiosInstance
            .delete(`native_main/faqdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_FAQ_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_FAQ_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const clearMessage = (clear = true) => {
    return (dispatch) => {
      dispatch({ type: WEB.MANAGE_PAGE_CLEAR_MESSAGE_REQUEST });
      if (clear) {
        dispatch({ type: WEB.MANAGE_PAGE_CLEAR_MESSAGE_SUCCESS });
      }
    }
  }