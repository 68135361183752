import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const getCategoriesList = (val, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_LIST_GET_REQUEST });
        axiosInstance
            .get(`adminpanel/collectionslist?search=${val}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.CATEGORY_LIST_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CATEGORY_LIST_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const categorySearchList = (value, searchType = "id", media_type = "", page = 1, pagelimit = 20) => {
    var path = `adminpanel/medialist?search=${value}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}`;
    if (searchType != 'id') {
        path = `native_main/frenzisearch?search_type=media&query=${value}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}`;
    }
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_SEARCH_LIST_REQUEST });
        axiosInstance
            .post(path)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.CATEGORY_SEARCH_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.CATEGORY_SEARCH_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getCategoryListDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_CATEGORY_LISTING_DETAILS_REQUEST });
        axiosInstance
            .get(`main/listingdetail?id=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_CATEGORY_LISTING_DETAILS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_CATEGORY_LISTING_DETAILS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deleteCategory = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_CATEGORY_REQUEST });

        axiosInstance
            .delete(`adminpanel/collectionsdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_CATEGORY_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_CATEGORY_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.CATEGORY_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.CATEGORY_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const addCtaegory = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.POST_ADD_CATEGORY_REQUEST });

        axiosInstance
            .post(`adminpanel/collectionslist`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.POST_ADD_CATEGORY_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.POST_ADD_CATEGORY_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const updateCategory = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_CATEGORY_REQUEST });

        axiosInstance
            .put(`adminpanel/collectionsdetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_CATEGORY_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_CATEGORY_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getDetailsByCategory = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_DETAILS_BY_CATEGORY_REQUEST });
        axiosInstance
            .get(`adminpanel/collectionsdetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_DETAILS_BY_CATEGORY_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_DETAILS_BY_CATEGORY_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const getCollectionsMedia = (id, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_COLLECTIONS_MEDIA_REQUEST });
        axiosInstance
            .get(`adminpanel/collectionmedia?collection=${id}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_COLLECTIONS_MEDIA_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_COLLECTIONS_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const updateCollectionMedia = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_COLLECTIONS_MEDIA_REQUEST });
        axiosInstance
            .post(`adminpanel/collectionmedia`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_COLLECTIONS_MEDIA_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_COLLECTIONS_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


// .................................. youtube collection actions ...................................


export const getYoutubeCollectionList = (val, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.YTD_COLLECTION_LIST_GET_REQUEST });
        axiosInstance
            .get(`adminpanel/youtubecollectionsall?search=${val}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.YTD_COLLECTION_LIST_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.YTD_COLLECTION_LIST_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const addYTBCollection = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.POST_ADD_YTB_COLLECTION_REQUEST });

        axiosInstance
            .post(`adminpanel/youtubecollectionsall`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.POST_ADD_YTB_COLLECTION_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.POST_ADD_YTB_COLLECTION_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const updateYTBCollection = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_YTB_COLLECTION_REQUEST });

        axiosInstance
            .put(`adminpanel/youtubecollectionssingle/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_YTB_COLLECTION_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_YTB_COLLECTION_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const deleteYTBCollection = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_YTB_COLLECTION_REQUEST });

        axiosInstance
            .delete(`adminpanel/youtubecollectionssingle/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_YTB_COLLECTION_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_YTB_COLLECTION_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const getDetailsByCollectionId = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_DETAILS_BY_COLLECTION_ID_REQUEST });
        axiosInstance
            .get(`adminpanel/youtubecollectionssingle/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_DETAILS_BY_COLLECTION_ID_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_DETAILS_BY_COLLECTION_ID_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const getCollectionsLinks = (id, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_COLLECTIONS_YTB_LINKS_REQUEST });
        axiosInstance
            .get(`adminpanel/youtubelistingdetails/${id}?page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_COLLECTIONS_YTB_LINKS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_COLLECTIONS_YTB_LINKS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const addCollectionLinks = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_YTB_LINK_REQUEST });

        axiosInstance
            .post(`adminpanel/youtubelistingdetails/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_YTB_LINK_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_YTB_LINK_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const deleteCollectionLinks = (id, lid) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_YTB_LINK_REQUEST });

        axiosInstance
            .delete(`adminpanel/youtubelistingdetails/${id}?yt_link_id=${lid}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.DELETE_YTB_LINK_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_YTB_LINK_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

