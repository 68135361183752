import Header from 'components/Headers/Header'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Button, Card, Col, Container, CustomInput, Row, Table } from 'reactstrap'
import ReactGA from 'react-ga';

export default function ReportUpload() {
    const [csvFile, setCsvFile] = React.useState("");

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const handleFileUpload = () => {
        let formData = new FormData();
        formData.append('ott_deeplink', csvFile);
        ReactGA.event({
            category: 'Media',
            action: 'Advertisement Report File Uploaded',
            value: csvFile.name ? csvFile.name : '',
            label: csvFile.name ? csvFile.name : ''
        });
        // dispatch(bulkOttDeeplinkUpload(formData));
    }

    return (
        <>
            <Header />
            <ToastContainer />
            <Container>
                <Card className='mt-4 pt-3 pb-3'>
                    <Container className='table'>
                        <h3 className='mb-3'>Upload Report</h3>
                        <Row className='mb-4'>
                            <Col xs={4}>
                                <CustomInput
                                    type='file'
                                    accept=".csv"
                                    onChange={(e) => setCsvFile(e.target.files[0])}
                                    invalid={csvFile && csvFile != "" ? false : true}
                                    valid={csvFile && csvFile != "" ? true : false}
                                />
                            </Col>
                            <Col xs={4}>
                                <Button onClick={() => handleFileUpload()} disabled={csvFile && csvFile != "" ? false : true} color="info" >Upload</Button>
                            </Col>
                        </Row>
                        <Table hover className="align-items-center table-flush">
                            <thead>
                                <tr>
                                    <th width="9%">
                                        S. No.
                                    </th>
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Error
                                    </th>
                                    <th>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='vertical-middle'>
                                <tr>
                                    <td>1</td>
                                    <td>John</td>
                                    <td>N/A</td>
                                    <td>404</td>
                                    <td>
                                        <Button size='sm' color='success'>
                                            Done
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                </Card>
            </Container>
        </>
    )
}
