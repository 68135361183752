import { configureStore } from '@reduxjs/toolkit'
import reducers from "./reducers/index";

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = configureStore(
//   reducers,
//   {},
//   composeEnhancers(applyMiddleware(thunk))
// );


const store = configureStore({
  reducer : reducers
})

export default store;
