import React from 'react'
import Header from "components/Headers/Header.js";
import { Container, Row, Col, Form, FormGroup, Button, Input, Card, Table, Modal, ModalBody, ModalHeader, Label, ModalFooter, CustomInput, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import { getDiscoveryData, deleteDiscoveryPost } from 'redux/actions/discovery.action';
import { clearMessage } from 'redux/actions/discovery.action';
import MediaType from 'components/MediaType/MediaType';
import CustomPagination from 'components/Pagination/CustomPagination';
import { WithContext as ReactTags } from 'react-tag-input';
import AWS from 'aws-sdk'
import { createDiscoveryPost } from 'redux/actions/discovery.action';
import { updateDiscoveryPost } from 'redux/actions/discovery.action';
import { categorySearchList } from 'redux/actions/categories.action';
import Loader from 'views/common/Loader';
import helpers from '../../helpers/common';
import { getWatchList } from 'redux/actions/watchlist.action';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const defaultForm = {
    "post_url": "",
    "post_type": "clip",
    "media": "",
    "watchlist": "",
    "description": "",
    "tags": []
}

export default function Discover() {
    const [addFrenziKeyPopup, setAddFrenziKeyPopup] = React.useState(false);
    const [inputSearchText, setInputSearchText] = React.useState("");
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const [isTrending, setIsTrending] = React.useState('all');
    const [userSection, setUserSection] = React.useState("all");
    const [dateRange, setDateRange] = React.useState("");
    const [currentPageMedia, setCurrentPageMedia] = React.useState(1);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(10);
    const [section, setSection] = React.useState('admin');
    const [isEdit, setIsEdit] = React.useState(false);
    const [isOpen3, setIsOpen3] = React.useState(false);
    const [isOpen4, setIsOpen4] = React.useState(false);
    const [searchType, setSearchType] = React.useState('media');
    const [isVideoLoading, setIsVideoLoading] = React.useState(false);
    const [editData, setEditData] = React.useState(defaultForm);
    const [editId, setEditId] = React.useState('');
    const [isShowList, setIsShowList] = React.useState(false);
    const [currentMedia, setCurrentMedia] = React.useState({});
    const [currentWatclist, setCurrentWatchlist] = React.useState({});
    const [searchText, setSearchText] = React.useState('');
    const [allSelect, setAllSelect] = React.useState('');
    const [idSelect, setIdSelect] = React.useState('Search by Title');
    const [selectById, setSelectByID] = React.useState(false);
    const [toggleFilter, setToggleFilter] = React.useState(false);
    const [toggleFilter2, setToggleFilter2] = React.useState(false);
    const dispatch = useDispatch();
    const wrapperRef = React.useRef(null);

    const discoveryPostList = useSelector((state) => state.discovery && state.discovery.discoveryPostList ? state.discovery.discoveryPostList : {})
    const categoryList = useSelector((state) => state.categories && state.categories.categoriesSearchList ? state.categories.categoriesSearchList : {})

    const watchlistData = useSelector((state) => state.watchlist && state.watchlist.watchlistData ? state.watchlist.watchlistData : {})

    const isLoading = useSelector((state) => state.discovery && state.discovery.isLoading ? state.discovery.isLoading : false)

    const status = useSelector((state) => state.discovery && state.discovery.status ? state.discovery.status : '')
    const errMesg = useSelector((state) => state.discovery && state.discovery.errMesg ? state.discovery.errMesg : '')

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            dispatch(getDiscoveryData(inputSearchText, userSection, section, dateRange, isTrending, currentPage, pageLimit));
            setAddFrenziKeyPopup(false);
            setEditData(defaultForm);
            setCurrentMedia({});
            setCurrentWatchlist({});
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    React.useEffect(() => {
        if ((fromDate && fromDate != '') || (toDate && toDate != '')) {
            let temp = [fromDate || '', toDate || ''];
            setDateRange(temp.join(','));
        } else {
            setDateRange('');
        }
    }, [fromDate, toDate])


    React.useEffect(() => {
        setCurrentPage(1);
        dispatch(getDiscoveryData(inputSearchText, userSection, section, dateRange, isTrending, 1, pageLimit));
    }, [inputSearchText, userSection, pageLimit, dateRange, isTrending])

    React.useEffect(() => {
        dispatch(getDiscoveryData(inputSearchText, userSection, section, dateRange, isTrending, currentPage, pageLimit));
    }, [currentPage])

    const handleFormChange = (value, name) => {
        const tempForm = editData;
        setEditData({ ...tempForm, [name]: value });
    }

    const handleDelete = (i) => {
        if (editData.tags && editData.tags.length > 0) {
            var tempTags = editData.tags;
            tempTags.splice(i, 1);
            handleFormChange(tempTags, 'tags');
        }
    };

    const handleAddition = (tag) => {
        var tempTags = editData.tags ? editData.tags : [];
        tempTags.push(tag?.text);
        handleFormChange(tempTags, 'tags');
    };


    const onFileInputSelect = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }

        let fileDataType = file.type.split("/");
        let fileType = fileDataType[0];
        let fileFormat = fileDataType[1];

        if (fileType.toLowerCase() != "video" && fileFormat.toLowerCase() != "mp4") {
            toast.error(('Please upload video file in mp4 format only'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }

        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
            window.URL.revokeObjectURL(video.src);
            console.log("Duration : " + parseInt(video.duration) + " seconds");
            if (parseInt(video.duration) > 61) {
                toast.error(('Please upload video file less than or equal to 1 minute'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                handleFileUpload(file, video.duration);
            }
        }
        video.src = URL.createObjectURL(file);
    }

    const handleFileUpload = async (file) => {

        let fileName = file.name.replace(/\s/g, "");
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "discover_videos/discovery-post-" + timeStamp + "-" + randomNo + "-" + fileName;
        setIsVideoLoading(true);
        var bucket = process.env.REACT_APP_S3_BUCKET_NAME;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                console.log(err);
                // helpers.toastError(JSON.stringify(err));
                setIsVideoLoading(false);
                return err;
            }
            if (data && data.Location) {
                eventReporting('Discover', 'Video uploaded - ' + data.Location, 'new');
                handleFormChange(data.Location, 'post_url');
                setIsVideoLoading(false);
            }
        });
    }

    const handleCategorySearch = (e) => {
        setSearchText(e.target.value);
        setCurrentPageMedia(1);
        if (e.target.value && e.target.value != "") {
            dispatch(categorySearchList(e.target.value, idSelect == 'Search by Title' ? 'title' : 'id', allSelect));
        } else {
            dispatch(categorySearchList(e.target.value, 'id', allSelect));
        }
    }

    const handleCategorySearchWatchlist = (e) => {
        setSearchText(e.target.value);
        dispatch(getWatchList(e.target.value, 1, 30));
    }

    function isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    const handleOnMediaScroll = (e) => {
        if (isInViewport(e.target.lastChild) && !isLoading) {
            setCurrentPageMedia(currentPage + 1);
        }
    }

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsShowList(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleselectMedia = (data) => {
        setIsShowList(false);
        var media = {
            image: data?.poster ? data?.poster : data?.poster_path,
            title: data?.title,
            media: data.media && data.media != "" && data.media.length > 15 ? data.media : data?.uuid
        }
        setCurrentMedia(media);
        handleFormChange(data.media && data.media != "" && data.media.length > 15 ? data.media : data?.uuid, 'media');
    }

    const handleselectWatchlist = (data) => {
        setIsShowList(false);
        var media = {
            image: data?.thumbnail || '',
            title: data?.category_name,
            media: data?.uuid || ''
        }
        setCurrentWatchlist(media);
        handleFormChange(data?.uuid, 'watchlist');
    }

    React.useEffect(() => {
        var type = idSelect == 'Search by Title' ? 'title' : 'id';
        dispatch(categorySearchList(searchText, type, allSelect, currentPageMedia, 20));
        dispatch(getWatchList(searchText, currentPageMedia, 30));
    }, [currentPageMedia, allSelect])

    const handleSubmit = () => {
        if (searchType == 'media') {
            delete editData.watchlist;
            setCurrentWatchlist({});
        }
        if (searchType == 'watchlist') {
            delete editData.media;
            setCurrentMedia({});
        }

        editData.uploaded_from = 'admin';
        editData.description = editData.description || null;

        if (editData.post_url && editData.post_url != "" && editData.post_type && editData.post_type != "" && (editData.media && editData.media != "" || editData.watchlist && editData.watchlist != "")) {
            if (isEdit) {
                eventReporting('Discover', 'Reel post updated - ', editId);
                dispatch(updateDiscoveryPost(editId, editData));
            } else {
                eventReporting('Discover', 'Reel post created - ', 'new');
                dispatch(createDiscoveryPost(editData));
            }
        } else {
            let mesg = 'Missing required parameters - '
            if (!editData?.post_url || editData?.post_url == "") {
                mesg += 'Video File,'
            }
            if ((!editData?.media || editData?.media == "") || (!editData?.watchlist || editData?.watchlist == "")) {
                mesg += 'Media/Watchlist,'
            }
            // if (!editData?.description || editData?.description == "") {
            //     mesg += 'Description,'
            // }
            toast.error((mesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const getKeyFileName = (url) => {
        if (url.includes("?")) {
            if (!url.includes("amazonaws.com/frenzi-native/")) {
                return url.substring(
                    url.indexOf("amazonaws.com/") + 14,
                    url.lastIndexOf("?")
                );
            } else {
                return url.substring(
                    url.indexOf("frenzi-native/") + 14,
                    url.lastIndexOf("?")
                );
            }
        } else {
            var splitStr = url.substring(url.indexOf('amazonaws.com/') + 14);
            return splitStr;
        }
    }

    const handleDeleteKey = (data) => {
        let id = data.post_id;
        eventReporting('Discover', 'Reel post deleted - ', id);
        dispatch(deleteDiscoveryPost(id));

        // let keyName = getKeyFileName(data.post_url);
        // eventReporting('Frenzi Keys', 'Frenzi Key deleted', id);

        // var bucket = process.env.REACT_APP_S3_BUCKET_NAME;
        // const s3 = new AWS.S3({
        //     params: { Bucket: bucket },
        //     region: process.env.REACT_APP_AWS_REGION,
        // })
        // const params = {
        //     Key: keyName,
        //     Bucket: bucket,
        // }
        // s3.deleteObject(params, async function (err, data) {
        //     if (err) {
        //         console.log(err);
        //         return err;
        //     }
        //     if (data) {
        //         dispatch(deleteDiscoveryPost(id));
        //     }
        // });
    }

    return (
        <>
            <Header />
            <ToastContainer />
            {isVideoLoading && <Loader />}
            <div>
                <Container>
                    <Card className='mt-4 p-3 rounded-lg'>
                        <Row className='mt-2 mb-3' style={{ alignItems: 'center' }} >
                            <Col sm="2" >
                                <Col sm={12} className="p-0 search-icon-box">
                                    <Dropdown color="black" mt={4} toggle={() => { setToggleFilter(!toggleFilter) }}
                                        isOpen={toggleFilter} style={{ width: '100%' }}>
                                        <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                            {userSection}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem value="all" onClick={(e) => {
                                                setUserSection('all');
                                            }}>All</DropdownItem>
                                            <DropdownItem value="frenzi" onClick={(e) => {
                                                setUserSection('frenzi');
                                            }}>Frenzi</DropdownItem>
                                            <DropdownItem value="users" onClick={(e) => {
                                                setUserSection('users');
                                            }}>Users</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </Col>
                            <Col sm="3" >
                                <Col sm={12} className="p-0 search-icon-box">
                                    <Input placeholder='Search' onChange={(e) => setInputSearchText(e.target.value)} value={inputSearchText} />
                                    <i className="fas fa-search"></i>
                                </Col>
                            </Col>
                            <Col sm="2">
                                <Col sm={12} className="p-0">
                                    <Input
                                        type='date'
                                        placeholder='From'
                                        onChange={(e) => setFromDate(e.target.value)}
                                        value={fromDate}
                                    />
                                </Col>
                            </Col>
                            <Col sm="auto">
                                To
                            </Col>
                            <Col sm="2">
                                <Col sm={12} className="p-0">
                                    <Input
                                        type='date'
                                        placeholder='To'
                                        onChange={(e) => setToDate(e.target.value)}
                                        value={toDate}
                                    />
                                </Col>
                            </Col>
                            <Col sm="auto">
                                <Button color="danger" size='sm' onClick={() => {
                                    setFromDate('');
                                    setToDate('');
                                    setInputSearchText('');
                                    setUserSection('all');
                                }}
                                >Clear</Button>
                            </Col>
                            <Col sm="auto">
                                <Button size='sm' color="success" onClick={() => {
                                    setIsEdit(false);
                                    setEditId('');
                                    setSearchType('media');
                                    setEditData(defaultForm);
                                    setCurrentMedia({});
                                    setCurrentWatchlist({});
                                    setAddFrenziKeyPopup(!addFrenziKeyPopup);
                                    ReactGA.modalview(window.location.pathname + '?modal=Add Discovery Post')
                                }} >Add Video</Button>
                            </Col>
                            <Col sm={2} className='mt-3'>
                                <Dropdown color="black" mt={4} toggle={() => { setToggleFilter2(!toggleFilter2) }}
                                    isOpen={toggleFilter2} style={{ width: '100%' }}>
                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                        {isTrending === 'all' ? 'All' : isTrending ? 'Trending' : 'Non-Trending'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem value="all" onClick={(e) => {
                                            setIsTrending('all');
                                        }}>All</DropdownItem>
                                        <DropdownItem value="frenzi" onClick={(e) => {
                                            setIsTrending(false);
                                        }}>Non-Trending</DropdownItem>
                                        <DropdownItem value="users" onClick={(e) => {
                                            setIsTrending(true);
                                        }}>Trending</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                            <Table className="align-items-center table-flush " >
                                <thead >
                                    <tr>
                                        <th className='table_heading'>Sr No.</th>
                                        <th className='table_heading'>Title (Media/Watchlist)</th>
                                        <th className='table_heading'>Clip</th>
                                        <th className='table_heading'>Description</th>
                                        <th className='table_heading'>Uploaded By</th>
                                        <th className='table_heading'>Uploaded On</th>
                                        <th className='table_heading'>Type</th>
                                        <th className='table_heading'>Is Trending</th>
                                        <th className='table_heading'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {discoveryPostList.data?.results.length > 0 &&
                                        discoveryPostList.data.results?.map((x, i) => {
                                            return <tr>
                                                <th scope="row">{(pageLimit * (currentPage - 1)) + (i + 1)}</th>
                                                <td>{x?.title || x?.watchlist}</td>
                                                <td>
                                                    <MediaType
                                                        src={x?.post_url}
                                                        type="video"
                                                        light={x?.thumbnail_url}
                                                    // bucket="temp"
                                                    // videoType="normal"
                                                    />
                                                </td>
                                                <td>{x?.description}</td>
                                                <td>{x?.username}</td>
                                                <td>{x?.created_at || 'N/A'}</td>
                                                <td>{x?.post_type}</td>
                                                <td>
                                                    <CustomInput
                                                        type="switch"
                                                        id={`exampleCustomSwitch-trendingg-${i}`}
                                                        name="customSwitch"
                                                        checked={x.is_trending}
                                                        onChange={(e) => {
                                                            const payload = {
                                                                ...x,
                                                                is_trending: e.target.checked
                                                            }
                                                            dispatch(updateDiscoveryPost(x?.post_id, payload));
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ minWidth: '160px' }} className="td-actions text-left">
                                                    <CopyToClipboard text={x?.post_id}
                                                        onCopy={() => {
                                                            toast.success(('post id copied'), {
                                                                position: "top-right",
                                                                autoClose: 5000,
                                                                hideProgressBar: false,
                                                                closeOnClick: true,
                                                                pauseOnHover: true,
                                                                draggable: true,
                                                                progress: undefined,
                                                            });
                                                        }}>
                                                        <span className=" pointer">
                                                            <Button
                                                                className=" btn-icon btn-simple"
                                                                color="success"
                                                                size="sm"
                                                                type="button"
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                <i className="fas fa-copy pt-1"></i>
                                                            </Button>
                                                        </span>
                                                    </CopyToClipboard>

                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="info"
                                                        size="sm"
                                                        type="button"
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => {
                                                            if (x.media && x.media != null) {
                                                                setSearchType('media');
                                                            }
                                                            if (x.watchlist_id && x.watchlist_id != null) {
                                                                setSearchType('watchlist');
                                                            }
                                                            setIsEdit(true);
                                                            setEditId(x.post_id);
                                                            setEditData({ ...x, tags: x.hashtags, watchlist: x.watchlist_id });
                                                            setCurrentMedia({});
                                                            setCurrentWatchlist({});
                                                            setAddFrenziKeyPopup(!addFrenziKeyPopup);
                                                            ReactGA.modalview(window.location.pathname + '?modal=Edit Discovery Post')
                                                        }}
                                                    >
                                                        <i className="fas fa-pen pt-1"></i>
                                                    </Button>

                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => {
                                                            if (window.confirm("Do you really want to delete this post ?")) {
                                                                handleDeleteKey(x);
                                                            }
                                                        }}
                                                    >
                                                        <i className="fas fa-trash pt-1"></i>
                                                    </Button>
                                                </td>

                                            </tr>
                                        })
                                    }
                                    {discoveryPostList.data?.results.length == 0 &&
                                        <tr><td colSpan={4} className="text-center">No Data Found</td></tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <CustomPagination
                            pagination={discoveryPostList.data && discoveryPostList.data.pagination && discoveryPostList.data.pagination ? discoveryPostList.data.pagination : {}}
                            setCurrentPage={(val) => setCurrentPage(val)}
                            setPageLimit={(val) => setPageLimit(val)}
                            defaultPageList={discoveryPostList.data && discoveryPostList.data.results ? discoveryPostList.data.results.length : 10}
                        />
                    </Card>
                </Container>
                <Modal size='lg' isOpen={addFrenziKeyPopup} toggle={() => setAddFrenziKeyPopup(!addFrenziKeyPopup)}  >
                    <ModalHeader toggle={() => setAddFrenziKeyPopup(!addFrenziKeyPopup)} >
                        {isEdit ? 'Edit' : 'Add'} discovery post
                    </ModalHeader>
                    <ModalBody className='pt-0' >

                        <Row className='pl-3 pr-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Search Type</p>
                            </Col>
                            <Col sm={9}>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <Dropdown className='w-100' isOpen={isOpen4} toggle={() => setIsOpen4(!isOpen4)}>
                                        <DropdownToggle className='w-100' caret>
                                            {searchType ? searchType : 'Select Search Type'}
                                        </DropdownToggle>
                                        <DropdownMenu
                                        >
                                            <DropdownItem
                                                onClick={() => {
                                                    setSearchType('media');
                                                }}
                                            >
                                                Media
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => {
                                                    setSearchType('watchlist');
                                                }}
                                            >
                                                Watchlist
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>

                        {searchType === 'media' &&
                            <>
                                <Row className='pl-3 pr-3 mt-3 w-100'>
                                    <Col sm={3} className="p-0">
                                        <p className='mr-3 mb-0 v-middle'>Search By</p>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="d-flex mr-4 m-100 justify-content-center">
                                            <Dropdown color="black" mt={4} toggle={() => { setSelectByID(!selectById) }}
                                                isOpen={selectById} style={{ width: '100%' }}>
                                                <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                                    {idSelect && idSelect != "" ? idSelect : 'Select ID/Title'}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem value="Search by Title" onClick={(e) => {
                                                        setIdSelect(e.target.value);
                                                    }}>Search by Title</DropdownItem>
                                                    <DropdownItem value="Search by Ids" onClick={(e) => {
                                                        setIdSelect(e.target.value);
                                                    }}>Search by Ids</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='pl-3 pr-3 mt-1 w-100'>
                                    <Col sm={3} className="p-0">
                                        <p className='mr-3 mb-0 v-middle'>Search Media</p>
                                    </Col>
                                    <Col sm={9}>
                                        <div className="d-flex mr-4 m-100 justify-content-center">
                                            <div className="mt-3 w-100 position-relative">
                                                <Input placeholder='Search Movies/Web Series'
                                                    onChange={(e) => {
                                                        handleCategorySearch(e);
                                                    }}
                                                    onClick={() => setIsShowList(true)}
                                                />
                                                {isShowList && categoryList && categoryList.data && categoryList.data.results && categoryList.data.results.length > 0 &&
                                                    <div onScroll={(e) => handleOnMediaScroll(e)} ref={wrapperRef} className='category-list position-absolute'>
                                                        {categoryList.data.results.map((x, i) => {
                                                            return <Row onClick={() => handleselectMedia(x)} className='align-items-center media-item pointer' key={i}>
                                                                <div>
                                                                    <Col>
                                                                        {(x.poster && x.poster != "" && x.poster != null) || (x.poster_path && x.poster_path != "" && x.poster_path != null) ?
                                                                            <img
                                                                                alt="..."
                                                                                className="category_img rounded shadow-lg"
                                                                                src={x.poster ? x.poster : x.poster_path ? x.poster_path : ''}
                                                                            ></img>
                                                                            :
                                                                            <div className='d-img'>
                                                                                <i className='fas fa-image'></i>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </div>
                                                                <Col>
                                                                    <h5 className="dt p-0 m-0">{x.title}</h5>
                                                                    <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Release Date : </b>{x.release_date}</p>
                                                                    <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Release Year : </b>{x.release_year ? x.release_year : ''}</p>
                                                                    <p style={{ fontSize: 12 }} className="p-0 m-0"><b>TMDB ID : </b>{x.tmdb_id ? x.tmdb_id : ''}</p>
                                                                    <p style={{ fontSize: 12 }} className="p-0 m-0"><b>Media Type : </b>{x.media_type ? x.media_type : ''}</p>
                                                                </Col>
                                                            </Row>
                                                        })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                        {searchType === 'watchlist' &&
                            <Row className='pl-3 pr-3 mt-1 w-100'>
                                <Col sm={3} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Search Watchlist</p>
                                </Col>
                                <Col sm={9}>
                                    <div className="d-flex mr-4 m-100 justify-content-center">
                                        <div className="mt-3 w-100 position-relative">
                                            <Input placeholder='Search Watchlist'
                                                onChange={(e) => {
                                                    handleCategorySearchWatchlist(e);
                                                }}
                                                onClick={() => setIsShowList(true)}
                                            />
                                            {isShowList && watchlistData && watchlistData.data && watchlistData.data.results && watchlistData.data.results.length > 0 &&
                                                <div onScroll={(e) => handleOnMediaScroll(e)} ref={wrapperRef} className='category-list position-absolute'>
                                                    {watchlistData.data.results.map((x, i) => {
                                                        return <Row onClick={() => handleselectWatchlist(x)} className='align-items-center media-item pointer' key={i}>
                                                            <div>
                                                                <Col>
                                                                    {(x.thumbnail && x.thumbnail != "" && x.thumbnail != null) ?
                                                                        <img
                                                                            alt="..."
                                                                            className="category_img rounded shadow-lg"
                                                                            src={x.thumbnail ? x.thumbnail : ''}
                                                                        ></img>
                                                                        :
                                                                        <div className='d-img'>
                                                                            <i className='fas fa-image'></i>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </div>
                                                            <Col>
                                                                <h5 className="dt p-0 m-0">{x?.category_name}</h5>
                                                                <p style={{ fontSize: 12 }} className="p-0 m-0"><b>{x?.is_public ? 'Sharelist' : 'Watchlist'}</b></p>
                                                            </Col>
                                                        </Row>
                                                    })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }

                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>UUID</p>
                            </Col>
                            <Col sm={9}>
                                <div style={{ alignItems: 'center' }} className="d-flex mr-4 m-100 justify-content-center">
                                    {searchType == 'media' ?
                                        <>
                                            <Input
                                                value={`${editData[searchType] ? editData[searchType] : ''} ${currentMedia?.title ? `(${currentMedia?.title})` : ''}`}
                                                disabled
                                                className='mr-2'
                                                required
                                            />
                                            {currentMedia && currentMedia.title &&
                                                <>
                                                    <img src={currentMedia?.image} style={{ width: 45, height: 60, borderRadius: 7 }} />
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <Input
                                                value={`${editData[searchType] ? editData[searchType] : ''} ${currentWatclist?.title ? `(${currentWatclist?.title})` : ''}`}
                                                disabled
                                                className='mr-2'
                                                required
                                            />
                                            {currentWatclist && currentWatclist.title &&
                                                <>
                                                    <img src={currentWatclist?.image} style={{ width: 45, height: 60, borderRadius: 7 }} />
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>File</p>
                            </Col>
                            <Col sm={9}>
                                <div style={{ alignItems: 'center' }} className="d-flex mr-4 m-100 justify-content-center p-2 border">
                                    {editData.post_url && editData.post_url != "" &&
                                        <MediaType
                                            src={editData?.post_url}
                                            type="video"
                                            light={editData?.thumbnail_url}
                                        // bucket="temp"
                                        // videoType="normal"
                                        />
                                    }
                                    {!isEdit &&
                                        <CustomInput
                                            type="file"
                                            id="exampleSwitchimagefe"
                                            name="customSwitch"
                                            className='ml-2'
                                            onChange={(e) => onFileInputSelect(e)}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Post Type</p>
                            </Col>
                            <Col sm={9}>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <Dropdown className='w-100' isOpen={isOpen3} toggle={() => setIsOpen3(!isOpen3)}>
                                        <DropdownToggle className='w-100' caret>
                                            {editData.post_type ? editData.post_type : 'Select Plan Type'}
                                        </DropdownToggle>
                                        <DropdownMenu
                                        >
                                            <DropdownItem
                                                onClick={() => handleFormChange('clip', 'post_type')}
                                            >
                                                Clip
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => handleFormChange('review', 'post_type')}
                                            >
                                                Review
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() => handleFormChange('collection', 'post_type')}
                                            >
                                                Collection
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Description</p>
                            </Col>
                            <Col sm={9}>
                                <div className="d-flex mr-4 m-100 justify-content-center">
                                    <Input value={editData?.description} onChange={(e) => handleFormChange(e.target.value, 'description')} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Is Trending</p>
                            </Col>
                            <Col sm={9}>
                                <div style={{ alignItems: 'center' }} className="d-flex mr-4 m-100">
                                    <CustomInput
                                        type="switch"
                                        id={`exampleCustomSwitch-trending`}
                                        name="customSwitch"
                                        checked={editData?.is_trending}
                                        onChange={(e) => {
                                            handleFormChange(e.target.checked, 'is_trending')
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className='pl-3 pr-3 mt-3 w-100'>
                            <Col sm={3} className="p-0">
                                <p className='mr-3 mb-0 v-middle'>Tags</p>
                            </Col>
                            <Col sm={9}>
                                <div className="d-flex mr-4 border p-2 m-100 justify-content-center">
                                    <ReactTags
                                        tags={editData.tags ? editData?.tags.map((x) => {
                                            return { id: x, text: x }
                                        }) : []}
                                        // suggestions={tagsList}
                                        delimiters={delimiters}
                                        handleDelete={handleDelete}
                                        handleAddition={handleAddition}
                                        // handleInputChange={(va) => handleInputChange(va)}
                                        // handleDrag={handleDrag}
                                        // handleTagClick={handleTagClick}
                                        editable={false}
                                        inputFieldPosition="bottom"
                                        autocomplete
                                        allowDragDrop={false}
                                        minQueryLength={1}
                                        placeholder="Type and press enter to add new tag"
                                        classNames={{ tags: 'w-100', tagInput: 'w-100 border-0 ReactTags__tagInput' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <div style={{ textAlign: 'center' }} className='mb-2'>
                        <Button color="info" className='text-center' onClick={handleSubmit}>Submit</Button>
                    </div>
                </Modal>
            </div>
        </>
    )
}

