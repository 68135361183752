import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const mediaGenreSelect = () => {
    return (dispatch) => {
        dispatch({ type: WEB.MEIDIA_GENRE_DROPDOWN_GET_REQUEST });
        axiosInstance
            .get(`native_main/nativegenres`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.MEDIA_GENRE_DROPDOWN_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.MEDIA_GENRE_DROPDOWN_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const mediaLanguageSelect = () => {
    return (dispatch) => {
        dispatch({ type: WEB.MEDIA_LANGUAGE_DROPDOWN_GET_REQUEST });
        axiosInstance
            .get(`native_main/languages?section=dropdown`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.MEDIA_LANGUAGE_DROPDOWN_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.MEDIA_LANGUAGE_DROPDOWN_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const mediaOttSelect = () => {
    return (dispatch) => {
        dispatch({ type: WEB.MEDIA_OTT_DROPDOWN_GET_REQUEST });
        axiosInstance
            .get(`native_main/sources`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.MEDIA_OTT_DROPDOWN_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.MEDIA_OTT_DROPDOWN_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.MEDIA_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.MEDIA_CLEAR_MESSAGE_SUCCESS });
        }
    }

}


export const bulkOttDeeplinkUpload = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.BULK_OTT_DEEPLINK_UPLOAD_REQUEST });
        axiosInstance
            .post(`adminpanel/bulk_ott_deeplink`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.BULK_OTT_DEEPLINK_UPLOAD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.BULK_OTT_DEEPLINK_UPLOAD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const newBulkOttDeeplinkUpload = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.NEW_BULK_OTT_DEEPLINK_UPLOAD_REQUEST });
        axiosInstance
            .post(`adminpanel/bulk_deeplink_update`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.NEW_BULK_OTT_DEEPLINK_UPLOAD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400 || err.status === 500 || err.status === 404) {
                    dispatch({
                        type: WEB.NEW_BULK_OTT_DEEPLINK_UPLOAD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const syncBulkMediaFromTmdb = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.BULK_MEDIA_SYNC_TMDB_REQUEST });
        axiosInstance
            .post(`adminpanel/sync_csv_media`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.BULK_MEDIA_SYNC_TMDB_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400 || err.status === 500 || err.status === 404) {
                    dispatch({
                        type: WEB.BULK_MEDIA_SYNC_TMDB_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const mediaList = (value, searchType = "id", query = "", media_type = "", body = {}, page = 1, pagelimit = 20) => {
    var path = `adminpanel/medialist?search=${value}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}` + query;
    if (searchType != 'id') {
        path = `native_main/frenzisearch?search_type=media&query=${value}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}`;
    }
    return (dispatch) => {
        dispatch({ type: WEB.MEDIA_LIST_GET_REQUEST });
        axiosInstance
            .post(path, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.MEDIA_LIST_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.MEDIA_LIST_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getAddMediaList = (value, searchType = "id", media_type = "", page = 1, pagelimit = 20) => {
    var path = `adminpanel/medialist?search=${value}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}`;
    if (searchType != 'id') {
        path = `native_main/frenzisearch?search_type=media&query=${value}&media_type=${media_type}&page=${page}&pagelimit=${pagelimit}`;
    }
    return (dispatch) => {
        dispatch({ type: WEB.ADD_MEDIA_LIST_GET_REQUEST });
        axiosInstance
            .post(path)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADD_MEDIA_LIST_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_MEDIA_LIST_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const addMediaSearch = (value) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_MEDIA_SEARCH_POST_REQUEST });

        axiosInstance
            .post(`native_main/frenzisearch?query=${value}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.ADD_MEDIA_SEARCH_POST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_MEDIA_SEARCH_POST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getMediaDetails = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_MEDIA_DETAILS_REQUEST });

        axiosInstance
            .get(`adminpanel/mediadetail/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_MEDIA_DETAILS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_MEDIA_DETAILS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const updateMediaDetails = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_MEDIA_DETAILS_REQUEST });

        axiosInstance
            .put(`adminpanel/mediaedit/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.UPDATE_MEDIA_DETAILS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.UPDATE_MEDIA_DETAILS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const addNewMedia = (tmdb_id, media_type) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_NEW_MEDIA_REQUEST });

        axiosInstance
            .get(`native_main/addmedia?tmdb_id=${tmdb_id}&media_type=${media_type}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.ADD_NEW_MEDIA_SUCCESS,
                        payload: payload
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_NEW_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const syncPerson = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.SYNC_PERSON_REQUEST });

        axiosInstance
            .post(`adminpanel/sync_person`, body)
            .then((res) => {
                const payload = res.data;
                dispatch({
                    type: WEB.SYNC_PERSON_SUCCESS,
                    payload: payload,
                })

            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SYNC_PERSON_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const deleteMedia = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_MEDIA_REQUEST });
        axiosInstance
            .delete(`adminpanel/mediadetail/${id}`, { data: { code: 'Lj5V3oaCDwJH4clXUerI' } })
            .then((res) => {
                const payload = res.data;

                dispatch({
                    type: WEB.DELETE_MEDIA_SUCCESS,
                    payload: payload,
                })
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}



export const mediarelateddetail = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.MEDIA_RELATED_DETAIL_REQUEST });

        axiosInstance
            .get(`adminpanel/mediarelateddetail/${id}`)
            .then((res) => {
                const payload = res.data;
                dispatch({
                    type: WEB.MEDIA_RELATED_DETAIL_SUCCESS,
                    payload: payload
                })
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.MEDIA_RELATED_DETAIL_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getTagList = (val, page = 1, pagelimit = 100) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_SEARCH_TAG_LIST_REQUEST });
        axiosInstance
            .get(`native_main/tagslist?search=${val}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_SEARCH_TAG_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_SEARCH_TAG_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getMediaTagList = (id, page = 1) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_MEDIA_TAG_LIST_REQUEST });
        axiosInstance
            .get(`native_main/tagslist?media=${id}&page=${page}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_MEDIA_TAG_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_MEDIA_TAG_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const addRemoveTags = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.ADD_REMOVE_MEDIA_TAGS_REQUEST });

        axiosInstance
            .post(`native_main/tagslist?media=${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.ADD_REMOVE_MEDIA_TAGS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.ADD_REMOVE_MEDIA_TAGS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getMediaCastList = (id, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_MEDIA_CAST_REQUEST });

        axiosInstance
            .get(`native_main/mediacastcrew?section=cast&page=${page}&pagelimit=${pagelimit}&media=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_MEDIA_CAST_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_MEDIA_CAST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getMediaCrewList = (id, page = 1, pagelimit = 10) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_MEDIA_CREW_REQUEST });

        axiosInstance
            .get(`native_main/mediacastcrew?section=crew&page=${page}&pagelimit=${pagelimit}&media=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_MEDIA_CREW_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_MEDIA_CREW_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getMediaCsvFile = (media_type = "", date = '') => {

    let url = `adminpanel/mediaexport?date=${date}`;
    if (media_type && media_type != '') {
        url += `&media_type=${media_type}`;
    }

    return (dispatch) => {
        dispatch({ type: WEB.GET_MEDIA_CSV_FILE_REQUEST });

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_MEDIA_CSV_FILE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: WEB.GET_MEDIA_CSV_FILE_FAILED,
                    payload: {
                        error: err.data.message,
                    },
                });
            })
    }
}

export const getTrailerCsvFile = (date = '') => {

    let url = `adminpanel/trailerexport?date=${date}`;

    return (dispatch) => {
        dispatch({ type: WEB.GET_TRAILER_CSV_FILE_REQUEST });

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_TRAILER_CSV_FILE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: WEB.GET_TRAILER_CSV_FILE_FAILED,
                    payload: {
                        error: err.data.message,
                    },
                });
            })
    }
}



export const getProvidersDropdown = (query = "", page = 1, pagelimit = 20, isActive = true) => {
    let url = `adminpanel/ottplatformlist?search=${query}&page=${page}&pagelimit=${pagelimit}`;
    if (isActive) {
        url += `&is_active=true`;
    }

    return (dispatch) => {
        dispatch({ type: WEB.GET_PROVIDERS_REQUEST });
        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_PROVIDERS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_PROVIDERS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const getProvidersLangDropdown = () => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_PROVIDERS_LANG_REQUEST });
        axiosInstance
            .get(`native_main/languages?section=dropdown`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_PROVIDERS_LANG_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_PROVIDERS_LANG_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getMediaProvidersList = (id, country = '', plan_type = '', language = '', page = 1, pagelimit = 20) => {
    let url = `adminpanel/mediaedit/${id}?page=${page}&pagelimit=${pagelimit}`;
    
    if (country && country != '') {
        url += `&country=${country}`
    }
    if (plan_type && plan_type != '') {
        url += `&plan_type=${plan_type}`
    }
    if (language && language != '') {
        url += `&language=${language}`
    }

    return (dispatch) => {
        dispatch({ type: WEB.MEDIA_PROVIDERS_GET_REQUEST });
        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.MEDIA_PROVIDERS_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.MEDIA_PROVIDERS_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const updateMediaProvidersList = (id, body) => {
    return (dispatch) => {
        dispatch({ type: WEB.UPDATE_MEDIA_PROVIDERS_REQUEST });
        axiosInstance
            .put(`adminpanel/mediaedit/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.UPDATE_MEDIA_PROVIDERS_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400 || err.status === 500 || err.status === 404) {
                    dispatch({
                        type: WEB.UPDATE_MEDIA_PROVIDERS_FAILED,
                        payload: {
                            error: err.data && err.data.message ? err.data.message : 'Server Error !!!',
                        },
                    });
                }
            })
    }
}

export const getMediaCertificatesValues = (page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_MEDIA_CERTIFICATES_REQUEST });

        axiosInstance
            .get(`adminpanel/certificate?page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.GET_MEDIA_CERTIFICATES_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_MEDIA_CERTIFICATES_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const syncMediaFromTmdb = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.SYNC_MEDIA_REQUEST });
        axiosInstance
            .post(`adminpanel/sync_media`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.SYNC_MEDIA_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.SYNC_MEDIA_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}