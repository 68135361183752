import React, { useState, useEffect } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { Route } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { mediasearch } from "../../redux/actions/search.action"
import { mediaGenreSelect, mediaLanguageSelect, mediaOttSelect, mediaList, getAddMediaList, addNewMedia, clearMessage, getTagList, getMediaCsvFile, syncMediaFromTmdb, deleteMedia, syncBulkMediaFromTmdb } from "../../redux/actions/media.action"
import CustomPagination from 'components/Pagination/CustomPagination';
import times from "lodash/times";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import Loader from 'views/common/Loader';
import HttpClient from "./HttpClient";
import { CSVLink } from "react-csv";
import axios from "axios";
import ReactGA from 'react-ga';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import helpers from 'helpers/common';
import AWS from 'aws-sdk'
import { id } from 'date-fns/locale';
import { deleteDataMedia } from '../../redux/actions/media.action';
import { mediarelateddetail } from '../../redux/actions/media.action';
import BulkCsvFileSample from '../../assets/sync_media_csv_template.csv';

const ottType = [
    {
        name: 'Free',
    },
]
const style = { width: "150px" };
const style1 = { width: "80px", height: "70px" };

const MultiValue = props => {
    const { data } = props;
    return <components.MultiValue {...props}>
        <img
            style={{
                width: 30,
                height: 25,
                borderRadius: 3,
                border: '0.5px solid #ddd'
            }}
            src={data.value.image ? data.value.image : ''}
            alt=''
        />
    </components.MultiValue>
};

const headers = [
    { label: "Ttiltle", key: "title" },
    { label: "TMDB ID", key: "tmdb_id" },
    { label: "Media Type", key: "media_type" },
    { label: "Data", key: "data" },
    { label: "Is Free", key: "is_free" },
    { label: "Is Adult", key: "is_adult" },
    { label: "Release Date", key: "release_date" },
    { label: "Last Episode Date", key: "last_episode_date" },
    { label: "Language", key: "language" },
    { label: "Genres", key: "genres" },
    { label: "IMDB ID", key: "imdb_id" },
    { label: "TMDB Rating", key: "tmdb_rating" },
    { label: "IMDB Rating", key: "" },
    { label: "Media Popularity", key: "media_popularity" },
    { label: "Media Overview", key: "media_overview" },
    { label: "Movie Runtime", key: "movie_runtime" },
    { label: "Total Seasons", key: "total_seasons" },
    { label: "Total Episodes", key: "totel_episodes" },
    { label: "Episodes Runtime", key: "episodes_runtime" },

];

export default function NewMedia() {
    const animatedComponents = makeAnimated();
    const components = { MultiValue };

    const [isAddPlatform, setIsAddPlatform] = React.useState(false);
    const [tmdbAdd, setTmdbAdd] = React.useState(false);
    const [allDropdownOpen, setAllOpen] = React.useState(false);
    const [selectById, setSelectByID] = React.useState(false);
    const [countryDropdown, setCountryuDropdown] = React.useState(false)
    const [mediaTypeDropdown, steMediaTypeDropdown] = React.useState(false);
    const [languageDropdown, setLanguageDropdown] = React.useState(false);
    const [ottTypeDropdown, setOttTypeDropdown] = React.useState(false);
    const [runtimeDropdown, setRuntimeDropdown] = React.useState(false);
    const [releaseYeardropdown, setReleaseYearDropdown] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(20);
    const [allOttSelected, setAllOttSelected] = React.useState(false);

    // value to update
    const [allSelect, setAllSelect] = React.useState('');
    const [idSelect, setIdSelect] = React.useState('DB Search');
    const [genreSelect, setGenreSelect] = React.useState('')
    const [languageSelect, setLanguageSelect] = React.useState('')
    const [mediaType, setMediaType] = React.useState('')
    const [syncId, setSyncId] = React.useState('')
    const [ottSelct, setOttSelect] = React.useState('')
    const [ottTypeSelct, setOttTypeSelct] = React.useState('')
    const [tagSelect, setTagSelect] = React.useState('')
    const [searchText, setSearchText] = React.useState('')
    const [addMediaSearchText, setAddMediaSearchText] = React.useState('')
    const [addIdSelect, setAddIdSelect] = React.useState(false);
    const [addMediaSearchList, setAddMediaSearchList] = React.useState([]);
    const [fromDate, setFromDate] = React.useState('');
    const [toDate, setToDate] = React.useState('');
    const [releaseYear, setReleaseYear] = React.useState('');
    const [imdbRating, setImdbRating] = React.useState('');
    const [isFree, setIsFree] = React.useState('');
    const [ogLang, setOgLang] = React.useState('');
    const [selectedGenre, setSelectedGenre] = React.useState([]);
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [selectedOtt, setSelectedOtt] = React.useState([]);
    const [selectRunTime, setSelectRunTime] = React.useState(0);
    const [sortType, setSortType] = React.useState('');
    const [tagsList, setTagsList] = React.useState([]);
    const [currentTagPage, setCurrentTagPage] = React.useState(1);
    const [tagSearchText, setTagSearchText] = React.useState('');
    const [openExport, setOpenExport] = React.useState(false);
    const [exportFileUrl, setExportFileUrl] = React.useState('');
    const [relatedDetail, setRelatedDetail] = useState('')
    const [dateRangeState, setDateRangeState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [csvFile, setCsvFile] = React.useState("");
    

    const dispatch = useDispatch()

    const mediaRelatedDetail = useSelector((state) => state.media && state.media.mediaRelatedDetail ? state.media.mediaRelatedDetail : {})

    const mediaSearchList = useSelector((state) => state.media && state.media.mediaList ? state.media.mediaList : {})

    const addMediaListData = useSelector((state) => state.media && state.media.addMediaList ? state.media.addMediaList : {})

    const genreDropdownResult = useSelector((state) => state.media && state.media.genreDropdownResult ? state.media.genreDropdownResult : {})

    const languageDropdownResult = useSelector((state) => state.media && state.media.languageDropdownResult ? state.media.languageDropdownResult : {})

    const ottDropdownResult = useSelector((state) => state.media && state.media.ottDropdownResult ? state.media.ottDropdownResult : {})

    const addMediaList = useSelector((state) => state.media && state.media.addMediaSearchResult ? state.media.addMediaSearchResult : {})

    const tagDropdownResult = useSelector((state) => state.media && state.media.tagDropdownResult ? state.media.tagDropdownResult : {})

    const status = useSelector((state) => state.media && state.media.status ? state.media.status : '')
    const errMesg = useSelector((state) => state.media && state.media.errMesg ? state.media.errMesg : '')
    const isTagLoading = useSelector((state) => state.media && state.media.isTagLoading ? state.media.isTagLoading : false)

    const isLoading = useSelector((state) => state.media && state.media.isLoading ? state.media.isLoading : false)
    const exportLoading = useSelector((state) => state.media && state.media.exportLoading ? state.media.exportLoading : false)

    const mediaExportData = useSelector((state) => state.media && state.media.mediaExportData ? state.media.mediaExportData : {})

    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const eventReporting = (category, action, val) => {
        ReactGA.event({
            category: category,
            action: action,
            value: val,
            label: val
        });
    }

    // const [dataInCSV, setDataInCSV] = useState("");
    // useEffect(() => {
    //     HttpClient.get().then(res => setDataInCSV(res));
    // }, []);

    // useEffect(() => {
    //     fetchData();
    // }, []);

    // const [csvData, setCsvData] = React.useState([]);
    // const csvDownloadRef = React.useRef();
    // const fetchData = () => {
    //     axios
    //         .get("https://jsonplaceholder.typicode.com/todos/1")
    //         .then((data) => {
    //             setCsvData(data);
    //             setTimeout(() => {
    //                 csvDownloadRef.current.link.click();

    //             }, 100)
    //         })
    //         .catch((error) => alert('Error'))
    // }

    // const fetchData = async () => {
    //     const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
    //     const data = await response.json();
    //     setCsvData(data);
    //     setTimeout(() => {
    //         csvDownloadRef.current.link.click();
    //     })
    // }

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            var type = "id";
            if (mediaSearchList && !mediaSearchList.data && searchText != "") {
                type = idSelect == 'Elastic Search' ? 'title' : 'id';
            }
            applySearch(searchText, type);
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    React.useEffect(() => {
        if (addMediaListData && addMediaListData.data && addMediaListData.data.results && addMediaListData.data.results.length > 0) {
            setAddMediaSearchList([...addMediaSearchList, ...addMediaListData.data.results])
        }
    }, [addMediaListData])

    React.useEffect(() => {
        if (tagDropdownResult && tagDropdownResult.data && tagDropdownResult.data.results && tagDropdownResult.data.results.length > 0) {
            setTagsList(removeDuplicate([...tagsList, ...tagDropdownResult.data.results.map((item) => { return { value: item.uuid, label: item.tag } })]));
        }
    }, [tagDropdownResult])

    const handleMediaSearch = (e) => {
        setCurrentPage(1);
        if (searchText && searchText != "") {
            applySearch(searchText, idSelect == 'Elastic Search' ? 'title' : 'id');
        } else {
            applySearch(searchText, 'id');
        }
    }

    const handleAddMediaSearch = (val) => {
        setAddMediaSearchText(val);
        setAddMediaSearchList([]);
        dispatch(getAddMediaList(val, 'title', '', 1))
    }

    const handleLoadMore = (nextPage) => {
        dispatch(getAddMediaList(addMediaSearchText, 'title', '', nextPage))
    }

    const handleAddMedia = (data) => {
        if (data.tmdb_id && data.tmdb_id != "" && data.media_type && data.media_type != "") {
            eventReporting('Media', 'Media added - ' + data.media_type, data.tmdb_id);
            dispatch(addNewMedia(data.tmdb_id, data.media_type));
        } else {
            toast.error(('Failed !!! - Required data is missing'), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    // const handleDelete = (id) => {
    //     if (window.confirm("Are you sure want to delete this media ? ")) {
    //         eventReporting('Media', 'Media deleted - ', id);
    //         dispatch(deleteMedia(id));
    //     }
    // }

    // const handleDelete1 = () => {
    //     dispatch(deleteMedia());
    // }

    const handleDeleteData = (id) => {
        dispatch(mediarelateddetail(id));
        setModal(true);
    };


    const deleteMediaData = () => {
        if (mediaRelatedDetail?.data?.media) {
            var body = {
                code: 'Lj5V3oaCDwJH4clXUerI'
            }
            const inputValue = window.prompt('Please copy & paste the media ID to delete it');
            if (inputValue !== null && inputValue !== '') {
                if (mediaRelatedDetail?.data?.media === inputValue) {
                    dispatch(deleteMedia(inputValue, body));
                    setModal(false);
                } else {
                    toast.error(("Media id not matched"), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error(('Cancelled !!!'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    // useEffect((id)=>{
    //     dispatch(deleteMedia(id))
    // },[])

    const MediaRelatedData = mediaRelatedDetail && mediaRelatedDetail?.data ? Object.entries(mediaRelatedDetail?.data) : [];

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    React.useEffect(() => {
        // dispatch(mediarelateddetail());
        dispatch(mediaGenreSelect());
        dispatch(mediaLanguageSelect());
        dispatch(mediaOttSelect());
        dispatch(getTagList('', 1, 50));
    }, [])

    React.useEffect(() => {
        var type = "id";
        if (mediaSearchList && !mediaSearchList.data && searchText != "") {
            type = idSelect == 'Elastic Search' ? 'title' : 'id';
        }
        applySearch(searchText, type);
    }, [currentPage, pageLimit, allSelect, genreSelect, fromDate, toDate, selectRunTime, releaseYear, imdbRating, isFree, ogLang, selectedOtt, selectedGenre, sortType, selectedTags])

    const getGenreOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: x.genre_id ? x.genre_id : x.id,
                label: x.genre ? x.genre : x.name,
            });
        })
        return temp;
    }

    const getGenreOptionsValue = (genres) => {
        var result = [];
        if (genres && genres.length > 0) {
            genres.map((x, i) => {
                result.push({
                    value: x.id ? parseInt(x.id) : parseInt(x.genre_id),
                    label: x.name ? x.name : x.genre,
                });
            })
        }
        return result;
    }

    const getOttOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: {
                    image: x.image ? x.image : '',
                    og_link: x.code ? x.code : '',
                },
                label: x.ott_name ? x.ott_name : '',
            });
        })
        return temp;
    }

    const getOttOptionsValue = (ottArr) => {
        var result = [];
        if (ottArr && ottArr.length > 0) {
            ottArr.map((x, i) => {
                result.push({
                    value: x,
                    label: x.image,
                });
            })
        }
        return result;
    }

    const handleSelectAllOtt = (val) => {
        setAllOttSelected(val);
        if (val) {
            var temp = ottDropdownResult;
            let res = [];
            temp && temp.data && temp.data.length > 0 && temp.data.map((x) => {
                res.push({
                    image: x.image,
                    og_link: x.url,
                })
            })
            setSelectedOtt([...res]);
        } else {
            setSelectedOtt([]);
        }
    }

    const handleTagInputChange = (e) => {
        setTagsList([]);
        setTagSearchText(e);
        dispatch(getTagList(e, 1, 50));
    }
    const handleLoadMoreTags = (e) => {
        dispatch(getTagList(tagSearchText, currentTagPage + 1, 50));
        setCurrentTagPage(currentTagPage + 1);
    }

    const handleSelectOnchangeFormaData = (data, name) => {
        if (name === 'ott') {
            var tempData = selectedOtt;
            var tempArr2 = [];
            if (data && data.length > 0) {
                data.map((x, i) => {
                    tempArr2.push(x.value);
                })
            }
            tempData = tempArr2;
            setSelectedOtt([...tempData])
        } else if (name === 'genres') {
            var tempData = selectedGenre;
            var tempArr = [];
            if (data && data.length > 0) {
                data.map((x, i) => {
                    tempArr.push({
                        id: x.value,
                        name: x.label,
                    });
                })
            }
            tempData = tempArr;
            setSelectedGenre([...tempData])
        } else if (name === 'tags') {
            setSelectedTags([...data])
        }
    }

    const applySearch = (val, type) => {
        var query = `&release_year=${releaseYear}&imdb_rating=${imdbRating}&is_free=${isFree}&og_lang=${ogLang}`;
        if (fromDate && toDate) {
            query += `&date=${fromDate},${toDate}`
        }
        if (sortType && sortType != "") {
            query = `&sort=${sortType}`
        }
        var services = [];
        if (selectedOtt && selectedOtt.length > 0) {
            selectedOtt.map((x, i) => {
                services.push(x.og_link);
            })
        }
        var genre = [];
        if (selectedGenre && selectedGenre.length > 0) {
            selectedGenre.map((x, i) => {
                genre.push(x.name);
            })
        }

        var tagList = [];
        if (selectedTags) {
            selectedTags.map((x, i) => {
                tagList.push(x.value);
            })
        }
        var body = {
            service: services,
            genre: genre,
            tags_list: tagList,
        }
        if (selectRunTime && selectRunTime > 0) {
            body.runtime = selectRunTime;
        }

        dispatch(mediaList(val, type, query, allSelect, body, currentPage, pageLimit));
    }

    const removeDuplicate = (array) => {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }
    const downloadCsvFile = (type = 'movie') => {
        let startDate = dateRangeState[0].startDate;
        let endDate = dateRangeState[0].endDate;

        let stm = (startDate.getMonth() + 1) > 9 ? (startDate.getMonth() + 1) : "0" + (startDate.getMonth() + 1);
        let sd = startDate.getFullYear() + "-" + stm + "-" + (startDate.getDate() > 9 ? startDate.getDate() : "0" + startDate.getDate());

        let etm = (endDate.getMonth() + 1) > 9 ? (endDate.getMonth() + 1) : "0" + (endDate.getMonth() + 1);
        let ed = endDate.getFullYear() + "-" + etm + "-" + (endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate());
        let date = sd + "," + ed;
        dispatch(getMediaCsvFile(type, date));
    }

    useEffect(() => {
        if (mediaExportData && mediaExportData.url) {
            setExportFileUrl(mediaExportData.url);
        }
    }, [mediaExportData])

    useEffect(() => {
        if (exportFileUrl && exportFileUrl != '') {
            setTimeout(async () => {
                let path = await helpers.getTempPresignedUrl(exportFileUrl);
                setExportFileUrl('');
                if (path) {
                    const aTag = document.createElement("a");
                    aTag.href = path;
                    aTag.target = '_blank';
                    document.body.appendChild(aTag);
                    aTag.click();
                    setTimeout(() => {
                        handleDeleteKey(exportFileUrl);
                    }, 3000)
                }
            }, [1000])
        }
    }, [exportFileUrl])

    const getKeyFileName = (url) => {
        if (url.includes("?")) {
            if (!url.includes("amazonaws.com/frenzi-native/")) {
                return url.substring(
                    url.indexOf("amazonaws.com/") + 14,
                    url.lastIndexOf("?")
                );
            } else {
                return url.substring(
                    url.indexOf("frenzi-native/") + 14,
                    url.lastIndexOf("?")
                );
            }
        } else {
            var splitStr = url.substring(url.indexOf('amazonaws.com/') + 14);
            return splitStr;
        }
    }

    const handleDeleteKey = (file_path) => {
        let keyName = getKeyFileName(file_path);
        eventReporting('Exported Media csv ', 'Media csv deleted');

        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Bucket: bucket,
        }
        s3.deleteObject(params, async function (err, data) {
            if (err) {
                console.log(err);
                return err;
            }
            if (data) {
                console.log("deleted-", JSON.stringify(data));
            }
        });
    }

    const handleSync = () => {
        if (syncId && mediaType) {
            eventReporting('Media', 'Media Synced from TMDB - ' + syncId, mediaType);
            const body = {
                tmdb_id: syncId,
                media_type: mediaType
            }
            dispatch(syncMediaFromTmdb(body));
            setTmdbAdd(false);
            setSyncId("");
            setMediaType("");
        }
    }

    const handleFileUpload = () => {
        let formData = new FormData();
        formData.append('file', csvFile);
        ReactGA.event({
            category: 'Media',
            action: 'New Bulk ott deeplink uploaded',
            value: csvFile.name ? csvFile.name : '',
            label: csvFile.name ? csvFile.name : ''
        });
        dispatch(syncBulkMediaFromTmdb(formData));
    }

    const downloadFormat = () => {
        const a = document.createElement('a');
        a.href = BulkCsvFileSample;
        a.download = 'sync_media_csv_template.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <>
            <Header />
            <ToastContainer />
            {isLoading && <Loader />}
            <div className='mt-4'>
                <Container>
                    <Card>
                        <CardBody className='bg-gradient-pink mb-4 pb-0'>
                            <Row className='media justify-content-between pb-3 p-0 m-0'>
                                <h3>
                                    MEDIA - List
                                </h3>
                                {openExport &&
                                    <Col xs="12">
                                        <Row>
                                            <Col sm='10' className='p-0'>
                                                <DateRangePicker
                                                    onChange={item => setDateRangeState([item.selection])}
                                                    showSelectionPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    months={2}
                                                    ranges={dateRangeState}
                                                    // minDate={addDays(new Date(dateRangeState[0].startDate), -60)}
                                                    maxDate={addDays(new Date(dateRangeState[0].startDate), 180)}
                                                    direction="horizontal"
                                                />
                                            </Col>
                                            <Col sm='2'>
                                                <Row className='m-0'>
                                                    <Button size='sm' className='mt-3' color={allSelect == "" ? 'warning' : 'primary'} onClick={() => setAllSelect("")} >All</Button>
                                                    <Button size='sm' className='mt-3' color={allSelect == "movie" ? 'warning' : 'primary'} onClick={() => setAllSelect("movie")} >Movies</Button>
                                                    <Button size='sm' className='mt-3' color={allSelect == "tv" ? 'warning' : 'primary'} onClick={() => setAllSelect("tv")} >Shows</Button>
                                                </Row>
                                                <Button disabled={exportLoading} className='w-100 mt-4' style={{ height: '45px' }} color="success" onClick={() => downloadCsvFile(allSelect)}>{exportLoading ? 'Processing...' : "Download CSV"}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                }

                                <Col xs="12" sm="10" className='mt-3'>
                                    <Row>
                                        <Col xs="12" lg="4">
                                            <Button color="success" className='w-100 mb-2'
                                                onClick={() => {
                                                    setTmdbAdd(!tmdbAdd);
                                                    ReactGA.modalview(window.location.pathname + "?modal=AddMediaFromTMDB");
                                                }}
                                            >
                                                Sync Media From TMDB
                                            </Button>
                                        </Col>
                                        <Col xs="12" lg="4">
                                            <Button color="info" className='w-100 mb-2'
                                                onClick={() => {
                                                    setIsAddPlatform(!isAddPlatform);
                                                    ReactGA.modalview(window.location.pathname + "?modal=AddMedia");
                                                }}
                                            >
                                                Add Media
                                            </Button>
                                        </Col>

                                        <Col xs="12" lg="4">
                                            <Button disabled={exportLoading} className='w-100' style={{ height: '45px' }} color="warning" onClick={() => setOpenExport(!openExport)}>{openExport ? 'Close' : 'Open'} Export</Button>
                                        </Col>
                                        {/* <div>
                                        {dataInCSV && (
                                            <a
                                                href={`data:text/csv;charset=utf-8,${escape(dataInCSV)}`}
                                                download="filename.csv"
                                            >
                                                download
                                            </a>
                                        )}
                                    </div> */}
                                        {/* <Button color="info" onClick={fetchData}>Download Csv</Button>
                                    <CSVLink
                                        data={csvData}
                                        headers={headers}
                                        filename={"media.csv"}
                                        target="_blank"
                                        ref={csvDownloadRef}
                                    /> */}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col xs="12" sm="6" lg="2" className='pb-3'>
                                    <Dropdown className='w-100' color="black" mt={4} toggle={() => { setAllOpen(!allDropdownOpen) }}
                                        isOpen={allDropdownOpen} style={{ minWidth: '100%' }}>
                                        <DropdownToggle caret style={{ minWidth: '100%', display: 'block' }} >
                                            {allSelect && allSelect === "" ? 'All' : allSelect == "movie" ? 'Movies' : allSelect == "tv" ? 'Shows' : 'All'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem value="" onClick={(e) => {
                                                setAllSelect(e.target.value);
                                            }}>All</DropdownItem>
                                            <DropdownItem value="movie" onClick={(e) => {
                                                setAllSelect(e.target.value);
                                            }}>Movies</DropdownItem>
                                            <DropdownItem value="tv" onClick={(e) => {
                                                setAllSelect(e.target.value);
                                            }}>Shows</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col xs="12" sm="6" lg="3" className="pb-3">
                                    <Dropdown color="black" mt={4} toggle={() => { setSelectByID(!selectById) }}
                                        isOpen={selectById} style={{ width: '100%' }}>
                                        <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                            {idSelect && idSelect != "" ? idSelect : 'Select ID/Title'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem value="Elastic Search" onClick={(e) => {
                                                setIdSelect(e.target.value);
                                            }}>Elastic Search</DropdownItem>
                                            <DropdownItem value="DB Search" onClick={(e) => {
                                                setIdSelect(e.target.value);
                                            }}>DB Search</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col xs="12" sm="6" lg="4" className='pb-3'>
                                    <Col sm={12} className="p-0 search-icon-box">
                                        <Input onChange={(e) => setSearchText(e.target.value)} placeholder='Search' onKeyUp={(e) => { if (e.keyCode == 13) { handleMediaSearch() } }} />
                                        <i className="fas fa-search" onClick={() => handleMediaSearch()}></i>
                                    </Col>
                                </Col>
                                <Col xs="12" sm="6" lg="3" className='pb-3'>
                                    <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setCountryuDropdown(!countryDropdown) }}
                                        isOpen={countryDropdown}>
                                        <DropdownToggle caret>
                                            Select Country
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Coming Soon...</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                {/* <Col sm="3" className='pr-0 mr-0' >
                                            <Dropdown color="black" mt={4} toggle={() => { setAlphabaticalDropdown(!alphabaticalDropdown) }}
                                                isOpen={alphabaticalDropdown} >
                                                <DropdownToggle caret>
                                                    Sort By
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem>Alpabetically(Z-A)</DropdownItem>
                                                    <DropdownItem>Latest Release</DropdownItem>
                                                    <DropdownItem>Most searched</DropdownItem>
                                                    <DropdownItem>Rating(Highest to lowest)</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col> */}
                            </Row>
                            <Row>
                                <Col className='pb-3' xs="12" sm="6" lg="3">
                                    <FormGroup className='m-0'>
                                        <Input type="date" name="date" id="exampleDate" placeholder="From" onChange={(e) => setFromDate(e.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col className='pb-3' xs="12" sm="6" lg="3">
                                    <FormGroup className='m-0'>
                                        <Input type="date" name="date" id="exampleDate" placeholder="To" onChange={(e) => setToDate(e.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col className='pb-3' xs="12" sm="6" lg="3">
                                    <Form className='edit_media_input w-100 mb-2'>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            placeholder={"Select Genres..."}
                                            components={animatedComponents}
                                            options={
                                                getGenreOptions(genreDropdownResult)
                                            }
                                            value={
                                                getGenreOptionsValue(selectedGenre)
                                            }
                                            onChange={(e) => handleSelectOnchangeFormaData(e, 'genres')}
                                        />
                                    </Form>
                                </Col>
                                <Col className='pb-3' xs="12" sm="6" lg="3">
                                    <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setLanguageDropdown(!languageDropdown) }}
                                        isOpen={languageDropdown}>
                                        <DropdownToggle caret>
                                            {languageSelect && languageSelect != "" ? languageSelect : 'Select Language'}
                                        </DropdownToggle>
                                        <DropdownMenu
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: 200,
                                                            },
                                                        };
                                                    },
                                                },
                                            }}>
                                            {
                                                languageDropdownResult && languageDropdownResult.data && languageDropdownResult.data.length > 0 && languageDropdownResult.data.map((y, j) => {
                                                    return < DropdownItem
                                                        onClick={() => {
                                                            setLanguageSelect(y.lang || y.language);
                                                            setOgLang(y.code);
                                                        }}>{y.lang || y.language}</DropdownItem>
                                                })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Row >
                                <Col className='pb-3' xs="12" sm="6" lg="4">
                                    <Select
                                        isMulti
                                        // closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={tagsList}
                                        placeholder={"Select Tags..."}
                                        value={selectedTags}
                                        isLoading={isTagLoading}
                                        onInputChange={(e) => handleTagInputChange(e)}
                                        onChange={(e) => handleSelectOnchangeFormaData(e, 'tags')}
                                        onMenuScrollToBottom={(e) => handleLoadMoreTags(e)}
                                    />
                                </Col>
                                <Col className='pb-3' xs="11" sm="6" lg="3">
                                    <Form className='edit_media_input w-100 mb-2'>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={true}
                                            placeholder={"Select Otts..."}
                                            // components={animatedComponents}
                                            options={
                                                getOttOptions(ottDropdownResult)
                                            }
                                            value={
                                                getOttOptionsValue(selectedOtt)
                                            }
                                            onChange={(e) => handleSelectOnchangeFormaData(e, 'ott')}
                                            components={components}
                                        />
                                    </Form>
                                </Col>
                                <Col className='pt-2 p-0 m-0' xs="2" sm="2" lg="2">
                                    <FormGroup
                                        check
                                        inline
                                    >
                                        <Input type="checkbox"
                                            checked={allOttSelected}
                                            onChange={(e) => handleSelectAllOtt(e.target.checked)}
                                        />
                                        <Label check>
                                            Select All Ott
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col className='pb-3' xs="11" sm="6" lg="3">
                                    <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setOttTypeDropdown(!ottTypeDropdown) }}
                                        isOpen={ottTypeDropdown}>
                                        <DropdownToggle caret>
                                            {ottTypeSelct && ottTypeSelct != "" ? ottTypeSelct : 'Select OTT Type'}
                                        </DropdownToggle>
                                        <DropdownMenu >
                                            {ottType.map((x, i) => {
                                                return <DropdownItem onClick={() => {
                                                    setOttTypeSelct(x.name);
                                                    if (x.name == 'Free' || x.name == 'free') {
                                                        setIsFree(true);
                                                    } else {
                                                        setIsFree(false);
                                                    }
                                                }}>{x.name}</DropdownItem>
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pb-3' xs="12" sm="6" lg="4">
                                    {/* <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setRatingDropdown(!ratingDropdown) }}
                                        isOpen={ratingDropdown} >
                                        <DropdownToggle caret>
                                            Select Rating
                                        </DropdownToggle>
                                        <DropdownMenu
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: 200,
                                                            },
                                                        };
                                                    },
                                                },
                                            }}
                                        >
                                            <DropdownItem>1</DropdownItem>
                                            <DropdownItem>2</DropdownItem>
                                            <DropdownItem>3</DropdownItem>
                                            <DropdownItem>4</DropdownItem>
                                            <DropdownItem>5</DropdownItem>
                                            <DropdownItem>6</DropdownItem>
                                            <DropdownItem>7</DropdownItem>
                                            <DropdownItem>8</DropdownItem>
                                            <DropdownItem>9</DropdownItem>
                                            <DropdownItem>9</DropdownItem>
                                            <DropdownItem>10</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown> */}

                                    <Form>
                                        <FormGroup>
                                            <Col sm={12} className="p-0 search-icon-box">
                                                <Input min={0} max={10} type='number' onChange={(e) => setImdbRating(e.target.value)} placeholder='Imdb Rating' />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                {/* <Col>
                                        <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setCertificationDropdown(!certificationDropdown) }}
                                            isOpen={certificationDropdown} >
                                            <DropdownToggle caret>
                                                Select Certification
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>1</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col> */}
                                <Col className='pb-3' xs="12" sm="6" lg="4">
                                    <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setRuntimeDropdown(!runtimeDropdown) }}
                                        isOpen={runtimeDropdown} >
                                        <DropdownToggle caret>
                                            {selectRunTime && selectRunTime > 0 ? selectRunTime + " minutes" : 'Select Runtime'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => setSelectRunTime(60)} >{`>1hr`}</DropdownItem>
                                            <DropdownItem onClick={() => setSelectRunTime(90)}>1hr-2hr</DropdownItem>
                                            <DropdownItem onClick={() => setSelectRunTime(120)}>2hr+</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col className='pb-3' xs="12" sm="6" lg="4">
                                    <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { setReleaseYearDropdown(!releaseYeardropdown) }}
                                        isOpen={releaseYeardropdown}>
                                        <DropdownToggle caret>
                                            {releaseYear != "" ? releaseYear : 'Select Release year'}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ maxHeight: 300, overflowY: 'auto' }}>
                                            {times(100, Number).map((item, i) => {
                                                var currentYear = new Date().getFullYear();
                                                return <DropdownItem onClick={() => setReleaseYear(currentYear - i)} >{currentYear - i}</DropdownItem>
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                {/* <Col>
                                    <div className='text-right'>
                                        <Button color="primary">Apply</Button>
                                    </div>
                                </Col> */}
                            </Row>
                        </CardBody>
                        <Container className='table'>
                            <Table hover className="align-items-center table-flush" >
                                <thead className='bg-light' >
                                    <tr>
                                        <th rowspan="2" className='table_heading'>Sr No.</th>
                                        <th rowspan="2" onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "title" ? "-title" : 'title');
                                        }} style={{ minWidth: 110 }} className='table_heading pointer'>
                                            Title
                                            {sortType == "title" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-title" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th rowspan="2" className='table_heading'>Type</th>
                                        <th rowspan="2" className='table_heading'>Poster</th>
                                        <th rowspan="2" className='table_heading'>TMDB Id</th>
                                        <th colspan="4" style={{ textAlign: 'center' }} className='table_heading'>Information

                                        </th>
                                        <th rowspan="2" className='table_heading'>Action</th>
                                    </tr>
                                    <tr>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "imdb_rating" ? "-imdb_rating" : 'imdb_rating');
                                        }} style={{ minWidth: 110 }} className='pointer'>
                                            Rating
                                            {sortType == "imdb_rating" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-imdb_rating" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "release_year" ? "-release_year" : 'release_year');
                                        }} style={{ minWidth: 110 }} className='pointer'>
                                            Release Year
                                            {sortType == "release_year" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-release_year" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <th onClick={() => {
                                            setCurrentPage(1);
                                            setSortType(sortType == "release_date" ? "-release_date" : 'release_date');
                                        }} style={{ minWidth: 110 }} className='pointer'>
                                            Release Date
                                            {sortType == "release_date" ?
                                                <i className="fas pl-1 fa-arrow-down"></i>
                                                : sortType == "-release_date" &&
                                                <i className="fas pl-1 fa-arrow-up"></i>
                                            }
                                        </th>
                                        <td>Trailers</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mediaSearchList && mediaSearchList.data && mediaSearchList.data.results && mediaSearchList.data.results.length > 0 ? mediaSearchList.data.results.map((x, i) => {
                                        return <tr>
                                            <th scope="row"> {i + 1}</th>
                                            <td>{x.title}</td>
                                            <td>{x.media_type}</td>
                                            <td>
                                                {(x.poster && x.poster != "") || (x.poster_path && x.poster_path != "") ?
                                                    <img
                                                        alt=""
                                                        className=" img-fluid rounded shadow-lg"
                                                        src={x.poster ? x.poster : x.poster_path ? x.poster_path : ''}
                                                        style={style1}
                                                    />
                                                    :
                                                    <div style={{ width: 80, height: 70 }} className='default-img'>
                                                        <i className='fas fa-image'></i>
                                                    </div>
                                                }
                                            </td>
                                            <td>{x.tmdb_id ? x.tmdb_id : "N/A"}</td>
                                            <td>{x.rating ? x.rating : "N/A"}</td>
                                            <td>{x.release_year ? x.release_year : "N/A"}</td>
                                            <td>{x.release_date ? x.release_date : "N/A"}</td>
                                            <td>{x.trailer ? x.trailer.length : 0}</td>
                                            <td >
                                                <Row>
                                                    <Link to={`/admin/editmedia/${x.media ? x.media : x.uuid}`} >
                                                        <Button
                                                            className=" btn-icon btn-simple"
                                                            color="info"
                                                            size="sm"
                                                            type="button"
                                                            style={{ marginRight: 10 }}
                                                        >
                                                            <i className="fas fa-pen pt-1"></i>
                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        className=" btn-icon btn-simple"
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => handleDeleteData(x.media ? x.media : x.uuid)}
                                                    >
                                                        <i className="fas fa-trash pt-1"></i>

                                                    </Button>
                                                    {/* <Button
                                                        className=" btn-icon btn-simple"
                                                        color="danger"
                                                        size="sm"
                                                        type="button"
                                                        onClick={() => handleDelete(x.media ? x.media : x.uuid)}
                                                    >
                                                        <i className="fas fa-trash pt-1"></i>
                                                    </Button> */}
                                                </Row>
                                            </td>

                                        </tr>
                                    })
                                        :
                                        <tr>
                                            <td colSpan={10} className='text-center'>
                                                <h3>
                                                    No Data Available
                                                </h3>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <div style={{ marginBottom: 20 }} className='text-right mt-2'>
                                {mediaSearchList.data && mediaSearchList.data.pagination &&
                                    <CustomPagination
                                        pagination={mediaSearchList.data && mediaSearchList.data.pagination && mediaSearchList.data.pagination ? mediaSearchList.data.pagination : {}}
                                        setCurrentPage={(val) => setCurrentPage(val)}
                                        setPageLimit={(val) => setPageLimit(val)}
                                        defaultPageList={mediaSearchList.data && mediaSearchList.data.results ? mediaSearchList.data.results.length : 20}
                                    />
                                }
                            </div>

                        </Container>
                    </Card>
                </Container>

                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                        <h3>Delete Media</h3>

                    </ModalHeader>
                    <ModalBody>
                        <h3>{mediaRelatedDetail?.message} </h3>
                        {MediaRelatedData && MediaRelatedData?.map(([key, value]) => {
                            return <div key={key}>
                                <p><b>{key}</b> : {value}</p>
                            </div>
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => deleteMediaData()}>
                            Delete
                        </Button>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={isAddPlatform}
                    toggle={() => setIsAddPlatform(!isAddPlatform)} >
                    <ModalHeader toggle={() => setIsAddPlatform(!isAddPlatform)} style={{ padding: '0.5rem' }} >
                        <p className="h3">Add Media</p>
                    </ModalHeader>
                    <ModalBody style={{ padding: '1rem', paddingTop: 0 }}>
                        <Row>
                            {/* <Col sm={4}>
                                <Dropdown color="black" toggle={() => { setAddSelectById(!addSelectById) }}
                                    isOpen={addSelectById} style={{ width: '100%' }}>
                                    <DropdownToggle caret style={{ width: '100%', display: 'block' }}>
                                        {addIdSelect && addIdSelect != "" ? addIdSelect : 'Select ID/Title'}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem value="Elastic Search" onClick={(e) => {
                                            setAddIdSelect(e.target.value);
                                        }}>Elastic Search</DropdownItem>
                                        <DropdownItem value="DB Search" onClick={(e) => {
                                            setAddIdSelect(e.target.value);
                                        }}>DB Search</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Col> */}
                            <Col className='mb-3'>
                                <Input type="search" name="search" id="exampleSearch" placeholder="Search" onChange={(e) => handleAddMediaSearch(e.target.value)} value={addMediaSearchText} />
                            </Col>
                        </Row>
                        <Row style={{ maxHeight: '75vh', overflowY: 'auto' }} className="add-media-list">
                            {addMediaSearchList && addMediaSearchList.length > 0 && addMediaSearchList.map((x, i) => {
                                return <Col className="pointer mt-5 mt-sm-0" sm="3" xs="6" onClick={() => handleAddMedia(x)}>
                                    {x.poster && x.poster != "" && x.poster != null ?
                                        <img
                                            alt="..."
                                            className=" img-fluid rounded shadow-lg"
                                            src={x.poster ? x.poster : ''}
                                        ></img>
                                        :
                                        <div className='d-img'>
                                            <i className='fas fa-image'></i>
                                        </div>
                                    }
                                    <large className=" d-block mb-4 text-center mt-10">
                                        {x.title ? x.title : ''}
                                    </large>
                                </Col>
                            })}
                            {addMediaListData && addMediaListData.data && addMediaListData.data.is_next_page &&
                                <Col>
                                    <Button color='success' onClick={() => handleLoadMore(addMediaListData.data.page + 1)} >Load More ...</Button>
                                </Col>
                            }
                        </Row>
                    </ModalBody>
                </Modal>

                <Modal size='large' isOpen={tmdbAdd}
                    toggle={() => setTmdbAdd(!tmdbAdd)} >
                    <ModalHeader toggle={() => setTmdbAdd(!tmdbAdd)} style={{ padding: '0.5rem' }} >
                        <Col xs="12">
                            <p className="h3">Sync Media From TMDB</p>
                        </Col>
                    </ModalHeader>
                    <ModalBody style={{ padding: '1rem', paddingTop: 0 }}>
                        <Col xs="12">
                            <Input type="text" name="tmdb_id" id="exampleSddearch" placeholder="Enter TMDB id" onChange={(e) => setSyncId(e.target.value)} value={syncId} />
                        </Col>
                        <Col xs="12" className='mt-2'>
                            <Dropdown color="black" className='btn-w-100' mt={4} toggle={() => { steMediaTypeDropdown(!mediaTypeDropdown) }}
                                isOpen={mediaTypeDropdown}>
                                <DropdownToggle caret>
                                    {mediaType && mediaType != "" ? mediaType == 'tv' ? 'TV' : 'Movie' : 'Select Media Type'}
                                </DropdownToggle>
                                <DropdownMenu
                                    modifiers={{
                                        setMaxHeight: {
                                            enabled: true,
                                            order: 890,
                                            fn: (data) => {
                                                return {
                                                    ...data,
                                                    styles: {
                                                        ...data.styles,
                                                        overflow: 'auto',
                                                        maxHeight: 200,
                                                    },
                                                };
                                            },
                                        },
                                    }}>
                                    <DropdownItem onClick={() => setMediaType('movie')}>Movie</DropdownItem>
                                    <DropdownItem onClick={() => setMediaType('tv')}>TV</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        {syncId && mediaType ?
                            <Col xs="12" className='mt-2'>
                                <Button onClick={() => handleSync()} color='success'>Sync</Button>
                            </Col>
                            : ''
                        }

                        <Col xs="12" className='mt-4'>
                            <p className="h3">Bulk Sync Media From TMDB</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={8}>
                                    <CustomInput
                                        type='file'
                                        accept=".csv"
                                        onChange={(e) => setCsvFile(e.target.files[0])}
                                        invalid={csvFile && csvFile != "" ? false : true}
                                        valid={csvFile && csvFile != "" ? true : false}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <Button onClick={() => handleFileUpload()} disabled={csvFile && csvFile != "" ? false : true} color="info" >Upload</Button>
                                </Col>
                                <Col xs={12} className='mt-4'>
                                    <Button onClick={() => downloadFormat()} color="warning" >Download Sample File</Button>
                                </Col>
                            </Row>
                        </Col>
                    </ModalBody>
                </Modal>
            </div>
        </>
    )
}
