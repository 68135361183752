import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";

export const clearMessage = (clear = true) => {
  return (dispatch) => {
    dispatch({ type: WEB.REVIEW_CLEAR_MESSAGE_REQUEST });
    if (clear) {
      dispatch({ type: WEB.REVIEW_CLEAR_MESSAGE_SUCCESS });
    }
  }
}

export const userReview = (value, date = '', commentType = '', page = 1, pageLimit = 20,searchType = '') => {
  let url = `tracking/userreviewlist?search=${value}&date=${date}&page=${page}&pagelimit=${pageLimit}`;
  if (commentType && commentType != "" && commentType != null) {
    url += `&comment_type=${commentType}`
  }
  if (searchType && searchType != "" && searchType != null) {
    url += `&${searchType}=true`
  }

  return (dispatch) => {
    dispatch({ type: WEB.USER_REVIEW_REQUEST });

    axiosInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.USER_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.USER_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const reportedReview = (section, value, commentType = '', date = '', page = 1, pageLimit = 20, searchType) => {
  var api = `tracking/reportedreviewlist?section=${section}&search=${value}&page=${page}&pagelimit=${pageLimit}`;
  if (date && date != "" && date != ",") {
    api = `tracking/reportedreviewlist?section=${section}&search=${value}&date=${date}&page=${page}&pagelimit=${pageLimit}`;
  }
  if (commentType && commentType != '' && commentType != null) {
    api += `&comment_type=${commentType}`;
  }
  if (searchType && searchType != "" && searchType != null) {
    api += `&${searchType}=true`
  }
  return (dispatch) => {
    dispatch({ type: WEB.REPORTED_REVIEW_REQUEST });
    axiosInstance
      .get(api)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.REPORTED_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.REPORTED_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}


export const reportReviewByClick = (body) => {
  return (dispatch) => {
    dispatch({ type: WEB.REPORT_REVIEW_BY_CLICK_REQUEST });
    axiosInstance
      .post(`tracking/reviewreport`, {
        ...body,
      })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.REPORT_REVIEW_BY_CLICK_SUCCESSS,
            payload: payload
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.REPORT_REVIEW_BY_CLICK_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const deleteReview = (id, reason = '') => {
  var url = `tracking/adminreviewdetail/${id}`;
  if (reason && reason != "") {
    url += `?reason=${reason}`;
  }
  return (dispatch) => {
    dispatch({ type: WEB.DELETE_REVIEW_REQUEST });
    axiosInstance
      .delete(url)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.DELETE_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.DELETE_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const reviewApprovalList = (value, commentType = '', page = 1, pagelimit = 10) => {
  let url = `tracking/reviewapprovallist?search=${value}&page=${page}&pagelimit=${pagelimit}`;
  if (commentType && commentType != "" && commentType != null) {
    url += `&comment_type=${commentType}`
  }

  return (dispatch) => {
    dispatch({ type: WEB.REVIEW_APPROVAL_LIST_REQUEST });
    axiosInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.REVIEW_APPROVAL_LIST_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.REVIEW_APPROVAL_LIST_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}


export const approveReview = (id, body) => {
  return (dispatch) => {
    dispatch({ type: WEB.APPROVE_REVIEW_REQUEST });
    axiosInstance
      .put(`tracking/reviewapprovaldetail/${id}`, { ...body })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.APPROVE_REVIEW_SUCCESS,
            payload: payload,
          })
        }
      })
      .catch((err) => {
        if (err.status === 400 && err.status === 500) {
          dispatch({
            type: WEB.APPROVE_REVIEW_FAILED,
            payload: {
              error: err.data.message ? err.data.message : 'Server Error',
            },
          });
        }
      })
  }
}

export const rejectReview = (id, body) => {
  return (dispatch) => {
    dispatch({ type: WEB.REJECT_REVIEW_REQUEST });
    axiosInstance
      .put(`tracking/reviewapprovaldetail/${id}`, { ...body })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.REJECT_REVIEW_SUCCESS,
            payload: payload,
          })
        }
      })
      .catch((err) => {
        if (err.status === 400 && err.status === 500) {
          dispatch({
            type: WEB.REJECT_REVIEW_FAILED,
            payload: {
              error: err.data.message ? err.data.message : 'Server Error',
            },
          });
        }
      })
  }
}

export const addTextReview = (body) => {
  return (dispatch) => {
    dispatch({ type: WEB.ADD_TEXT_REVIEW_REQUEST });
    axiosInstance
      .post(`tracking/adminreviewlist`, {
        ...body,
      })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.ADD_TEXT_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          debugger
          dispatch({
            type: WEB.ADD_TEXT_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const addAudioVideoReview = (body) => {
  return (dispatch) => {
    dispatch({ type: WEB.ADD_AUDIO_VIDEO_REVIEW_REQUEST });
    axiosInstance
      .post(`tracking/adminreviewlist`, {
        ...body,
      })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.ADD_AUDIO_VIDEO_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.ADD_AUDIO_VIDEO_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const deleteMultipleReview = (body, reason = '') => {
  var url = `tracking/multipleadminreview`;
  if (reason && reason != "") {
    url += `?reason=${reason}`;
  }

  return (dispatch) => {
    dispatch({ type: WEB.DELETE_MULTIPLE_REVIEWS_REQUEST });
    axiosInstance
      .post(url, {
        ...body,
      })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.DELETE_MULTIPLE_REVIEWS_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.DELETE_MULTIPLE_REVIEWS_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const frenziOfficalReviewList = (searchText = "", searchCommentType = "", currentPage = 1, pageLimit = 20) => {
  let url = `tracking/adminreviewlist?search=${searchText}&page=${currentPage}&pagelimit=${pageLimit}`;
  if (searchCommentType && searchCommentType != "" && searchCommentType != null) {
    url += `&comment_type=${searchCommentType}`
  }

  return (dispatch) => {
    dispatch({ type: WEB.FRENZI_OFFICIAL_REVIEW_LIST_REQUEST });
    axiosInstance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.FRENZI_OFFICIAL_REVIEW_LIST_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.FRENZI_OFFICIAL_REVIEW_LIST_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const getSpecificReview = (id) => {
  return (dispatch) => {
    dispatch({ type: WEB.GET_SPECIFIC_REVIEW_REQUEST });
    axiosInstance
      .get(`tracking/adminreviewdetail/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.GET_SPECIFIC_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.GET_SPECIFIC_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}


export const updateReview = (id, body) => {
  return (dispatch) => {
    dispatch({ type: WEB.UPDATE_REVIEW_REQUEST });
    axiosInstance
      .put(`tracking/adminreviewdetail/${id}`, { ...body })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.UPDATE_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.UPDATE_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}

export const unReportedReview = (body) => {
  return (dispatch) => {
    dispatch({ type: WEB.POST_UNREPORTED_REVIEW_REQUEST });
    axiosInstance
      .post(`tracking/reviewunreport`, {
        ...body,
      })
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data;
          dispatch({
            type: WEB.POST_UNREPORTED_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.POST_UNREPORTED_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}


export const getRequestedReviewList = (value, page = 1, pageLimit = 20) => {
  return (dispatch) => {
    dispatch({ type: WEB.REQUESTED_REVIEW_REQUEST });

    axiosInstance
      .get(`adminpanel/reviewrequestlist?search=${value}&page=${page}&pagelimit=${pageLimit}`)
      .then((res) => {
        if (res.status === 200) {
          const payload = res.data.data;
          dispatch({
            type: WEB.REQUESTED_REVIEW_SUCCESS,
            payload: {
              data: payload,
            },
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          dispatch({
            type: WEB.REQUESTED_REVIEW_FAILED,
            payload: {
              error: err.data.message,
            },
          });
        }
      })
  }
}