import { WEB } from "../constants/constants";
import axiosInstance from "../../helpers/axios";


export const getNotifyList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.NOTIFY_GET_REQUEST });

        axiosInstance
            .get(`adminpanel/notifymelist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.NOTIFY_GET_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.NOTIFY_GET_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const getErrorList = (value, filter = 'all', page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_ERROR_REPORT_LIST_REQUEST });

        axiosInstance
            .get(`tracking/reportederror?search=${value}&resolution_type=${filter}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_ERROR_REPORT_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_ERROR_REPORT_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}


export const getFeedbackList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_FEEDBACK_LIST_REQUEST });

        axiosInstance
            .get(`tracking/feedbacks?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_FEEDBACK_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_FEEDBACK_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }

}

export const errorReportStatus = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.POST_ERROR_REPORT_STATUS_REQUEST });

        axiosInstance
            .post(`tracking/reportederror?uuid=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.POST_ERROR_REPORT_STATUS_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.POST_ERROR_REPORT_STATUS_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const updateReportStatus = (body) => {
    return (dispatch) => {
        dispatch({ type: WEB.POST_ERROR_REPORT_STATUS_UPDATE_REQUEST });

        axiosInstance
            .put(`tracking/reportederrordetail`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data;
                    dispatch({
                        type: WEB.POST_ERROR_REPORT_STATUS_UPDATE_SUCCESS,
                        payload: payload,
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.POST_ERROR_REPORT_STATUS_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const clearMessage = (clear = true) => {
    return (dispatch) => {
        dispatch({ type: WEB.OPERATIONS_CLEAR_MESSAGE_REQUEST });
        if (clear) {
            dispatch({ type: WEB.OPERATIONS_CLEAR_MESSAGE_SUCCESS });
        }
    }

}

export const getCXEngineList = (value, page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_CX_ENGINE_LIST_REQUEST });

        axiosInstance
            .get(`native_main/enginelist?search=${value}&page=${page}&pagelimit=${pagelimit}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_CX_ENGINE_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_CX_ENGINE_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const getCXDeeplinkList = (value, comment, remark, sort, date = '', page = 1, pagelimit = 20) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_CX_DEEPLINK_LIST_REQUEST });

        let url = `native_main/cxdeeplinkdatalist?page=${page}&pagelimit=${pagelimit}`;
        if (value && value != '') {
            url += `&engine=${value || ''}`
        }
        if (comment && comment != '') {
            url += `&comment=${comment || ''}`
        }
        if (remark && remark != '') {
            url += `&remark=${remark || ''}`
        }
        if (sort && sort != '') {
            url += `&sort=${sort}`
        }
        if (date && date != '') {
            url += `&date=${date}`
        }

        axiosInstance
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_CX_DEEPLINK_LIST_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_CX_DEEPLINK_LIST_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const deleteCXRecord = (value) => {
    return (dispatch) => {
        dispatch({ type: WEB.DELETE_CX_RECORD_REQUEST });

        axiosInstance
            .delete(`native_main/cxdeeplinkdatadetail/${value}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.DELETE_CX_RECORD_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.DELETE_CX_RECORD_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}


export const updateCXRemark = (body, id) => {
    return (dispatch) => {
        dispatch({ type: WEB.GET_CX_REMARK_UPDATE_REQUEST });

        axiosInstance
            .put(`native_main/cxdeeplinkdatadetail/${id}`, body)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.GET_CX_REMARK_UPDATE_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.GET_CX_REMARK_UPDATE_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const exportCXcsvList = (id) => {
    return (dispatch) => {
        dispatch({ type: WEB.EXPORT_CX_CSV_REQUEST });

        axiosInstance
            .get(`adminpanel/cx_deeplink_csv/?engine=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const payload = res.data.data;
                    dispatch({
                        type: WEB.EXPORT_CX_CSV_SUCCESS,
                        payload: {
                            data: payload,
                        },
                    })
                }
            })
            .catch((err) => {
                if (err.status === 400) {
                    dispatch({
                        type: WEB.EXPORT_CX_CSV_FAILED,
                        payload: {
                            error: err.data.message,
                        },
                    });
                }
            })
    }
}

export const clearExportData = () => {
    return (dispatch) => {
        dispatch({ type: WEB.CLEAR_EXPORT_DATA });
    }
}