import React, { Component, useEffect } from 'react'
import Header from "components/Headers/Header.js";
import {
    Row, Col, Button, Container, Form, FormGroup, Input,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Card, CardHeader, CardBody, Label, Table, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput
} from 'reactstrap';
import { mediaGenreSelect, mediaLanguageSelect, mediaList } from "../../redux/actions/media.action"
import { getCollections, pushDynamicPost, clearMessage, getActorDropDown } from "../../redux/actions/dynamicFeed.action"
import { userSearch } from '../../redux/actions/search.action';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import AWS from 'aws-sdk'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'views/common/Loader';
import ReactGA from 'react-ga';

const defaultFormData = {
    post_url: "",
    post_type: "image",
    caption: "",
    button_text: "",
    button_type: "",
    button_deeplink: "",
    append_user: false,
    comments: true,
    is_pinned: false,
    language: [],
    post_caption: '',
    poster: '',
    section: 'dynamic_feed',
}

const requiredFormKeys = ['post_type', 'caption', 'post_caption', 'button_text', 'button_type', 'language', 'poster'];

const button_dropdown = [
    { name: 'Media', value: 'media' }, { name: 'New Releases', value: 'newarrivals' }, { name: 'Trending', value: 'toptwenty' }, { name: 'Trending in Friends', value: 'trendinginfriends' },
    { name: 'Recommendations', value: 'recommendations' }, { name: 'Trailers', value: 'trailers' }, { name: 'Hidden Gems', value: 'unspoken' },
    { name: 'Unexplored', value: 'unexplored' }, { name: 'Foreign', value: 'foreign' }, { name: 'Free', value: 'free_content' }, { name: 'Language', value: 'language' },
    { name: 'Genre', value: 'genre' }, { name: 'Coming Soon', value: 'coming_soon' }, { name: 'Collections', value: 'collection' }, { name: 'Collection (Indivisual)', value: 'collections' },
    { name: 'Actor', value: 'actor' }, { name: 'Invite Friends', value: 'invite_friends' }, { name: 'Frenzi Points', value: 'frenzi_points' }, { name: 'Browser', value: 'browser' },
    { name: 'For You', value: 'for_you' }, { name: 'Watchlists', value: 'user_watchlists' }, { name: 'Watchlist', value: 'watchlist_detail' }, { name: 'Top Searches', value: 'top_searches' },
    { name: 'Search', value: 'search' }, { name: 'Notifications', value: 'notification' }, { name: 'Groups', value: 'groups' }, { name: 'Group', value: 'group_feed' }, { name: 'Profile', value: 'user_profile' },
    { name: 'Feedback', value: 'feedback_form' }, { name: 'No Button', value: 'null' }
];

export default function EditAddFeed(props) {
    const [allDropDown, setAllDropDown] = React.useState(false);
    const [allDropDownPost, setAllDropDownPost] = React.useState(false);
    const [allDropDownPost2, setAllDropDownPost2] = React.useState(false);
    const [allDropDownPostType, setAllDropDownPostType] = React.useState(false);
    const [allDropDownLangType, setAllDropDownLangType] = React.useState(false);
    const [isShowDeepLink, setIsShowDeepLink] = React.useState(false);
    const [disableDeeplink, setDisableDeeplink] = React.useState(false);
    const [isDeepDropDown, setIsDeepDropDown] = React.useState(false);
    const [deepDropDownOpt, setDeepDropDownOpt] = React.useState([]);
    const [deepSearchType, setDeepSearchType] = React.useState('');
    const [dynamicFeedData, setDynamicFeedData] = React.useState(defaultFormData);
    const [collectionDataList, setCollectionDataList] = React.useState([]);
    const [searchType, setSearchType] = React.useState('Elastic Search');
    const animatedComponents = makeAnimated();

    // pagination
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageLimit, setPageLimit] = React.useState(100);
    const [searchMediaData, setSearchMediaData] = React.useState([]);
    const [userSearchData, setUserSearchData] = React.useState([]);


    const inputFileRef = React.useRef(null);
    const dispatch = useDispatch();

    const languageDropdownResult = useSelector((state) => state.media && state.media.languageDropdownResult ? state.media.languageDropdownResult : {});
    const genreDropdownResult = useSelector((state) => state.media && state.media.genreDropdownResult ? state.media.genreDropdownResult : {});
    const collectinsData = useSelector((state) => state.dynamicFeed && state.dynamicFeed.collectinsData ? state.dynamicFeed.collectinsData : {});
    const actorDropDownData = useSelector((state) => state.dynamicFeed && state.dynamicFeed.actorDropDownData ? state.dynamicFeed.actorDropDownData : {});

    const userSearchResult = useSelector((state) => state.commonSearch && state.commonSearch.userSearchResult ? state.commonSearch.userSearchResult : {})
    const mediaSearchList = useSelector((state) => state.media && state.media.mediaList ? state.media.mediaList : {})


    const isLoading = useSelector((state) => state.dynamicFeed && state.dynamicFeed.isLoading ? state.dynamicFeed.isLoading : false);
    const status = useSelector((state) => state.dynamicFeed && state.dynamicFeed.status ? state.dynamicFeed.status : '');
    const errMesg = useSelector((state) => state.dynamicFeed && state.dynamicFeed.errMesg ? state.dynamicFeed.errMesg : '');


    React.useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, [])

    const getOptionForSearchResults = () => {
        var temp = [];
        if (deepSearchType == 'media') {
            var tempArr = searchMediaData && searchMediaData.length > 0 ? searchMediaData : [];
            if (tempArr && tempArr.length > 0) {
                tempArr.map((x, i) => {
                    temp.push({
                        value: x.media ? x.media : x.uuid ? x.uuid : '',
                        label: x.title ? x.title : '',
                    });
                })
                return temp;
            }
        }

        if (deepSearchType == 'Actor') {
            var tempArr = actorDropDownData && actorDropDownData.data && actorDropDownData.data.length > 0 ? actorDropDownData.data : [];
            console.log(actorDropDownData)
            if (tempArr && tempArr.length > 0) {
                tempArr.map((x, i) => {
                    temp.push({
                        value: x.uuid ? x.uuid : '',
                        label: x.name ? x.name : '',
                    });
                })
                return temp;
            }
        }

        if (deepSearchType == 'user') {
            var tempArr = userSearchData && userSearchData.length > 0 ? userSearchData : [];
            if (tempArr && tempArr.length > 0) {
                tempArr.map((x, i) => {
                    temp.push({
                        value: x.uuid ? x.uuid : '',
                        label: x.username ? x.username : '',
                    });
                })
                return temp;
            }
        }
    }

    useEffect(() => {
        if (mediaSearchList && mediaSearchList.data && mediaSearchList.data.results && mediaSearchList.data.results.length > 0) {
            setSearchMediaData(removeDuplicate([...searchMediaData, ...mediaSearchList.data.results]))
        }
    }, [mediaSearchList])

    useEffect(() => {
        if (userSearchResult && userSearchResult.data && userSearchResult.data.results && userSearchResult.data.results.length > 0) {
            setUserSearchData(removeDuplicate([...userSearchData, ...userSearchResult.data.results]))
        }
    }, [userSearchResult])

    useEffect(() => {
        dispatch(mediaGenreSelect());
        dispatch(mediaLanguageSelect());
        dispatch(getCollections());
    }, [])

    React.useEffect(() => {
        if (status && status != "") {
            toast.success((status), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
            setDynamicFeedData({ ...defaultFormData });
        }

        if (errMesg && errMesg != "") {
            toast.error((errMesg), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearMessage());
        }
    }, [errMesg, status])

    useEffect(() => {
        if (collectinsData && collectinsData.data && collectinsData.data.results && collectinsData.data.results.length > 0) {
            setCollectionDataList(removeDuplicate([...collectionDataList, ...collectinsData.data.results]))
        }
    }, [collectinsData])

    const removeDuplicate = (array) => {
        var jsonObject = array.map(JSON.stringify);
        var uniqueSet = new Set(jsonObject);
        var uniqueArray = Array.from(uniqueSet).map(JSON.parse);
        return uniqueArray;
    }

    const getLanguageOptions = (opt) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            temp.push({
                value: x.code ? x.code : '',
                label: x.lang ? x.lang : x.language ? x.language : 'N/A',
            });
        })
        return temp;
    }

    const getLanguageOptionsValue = (opt, data) => {
        var temp = [];
        opt && opt.data && opt.data.length > 0 && opt.data.map((x, i) => {
            if (data.length > 0 && data.includes(x.code)) {
                temp.push({
                    value: x.code ? x.code : '',
                    label: x.lang ? x.lang : x.language ? x.language : 'N/A',
                });
            }
        })
        return temp;
    }

    const handlePosterChange = async (event) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        var bucket = process.env.REACT_APP_S3_TEMP_BUCKET_NAME;
        var timeStamp = new Date().getTime();
        var randomNo = parseInt(Math.random() * 100000);
        var keyName = "media_poster/movie-poster-" + timeStamp + "-" + randomNo + "-" + file.name;
        const s3 = new AWS.S3({
            params: { Bucket: bucket },
            region: process.env.REACT_APP_AWS_REGION,
        })
        const params = {
            Key: keyName,
            Body: file,
        }
        s3.upload(params, async function (err, data) {
            if (err) {
                return err;
            }
            if (data && data.Location) {
                // eventReporting('Dynamic Feed', "Uploaded file to s3 bucket -" + keyName, data.Location);
                handleOnchangeFormaData(data.Location, 'poster');
                toast.warning(('Click on save button to update the changes'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const handleMediaSearch = (val) => {
        setCurrentPage(1);
        if (deepSearchType == "media") {
            let type = searchType == 'Elastic Search' ? 'title' : 'id';
            dispatch(mediaList(val, type, '', '', {}, currentPage, pageLimit));
        }
        if (deepSearchType == "Actor") {
            if (val != "") {
                dispatch(getActorDropDown(val));
            }
        }
        if (deepSearchType == "user") {
            dispatch(userSearch(val, '', currentPage, pageLimit));
        }
    }

    const handleOnchangeFormaData = (value, name) => {
        console.log(dynamicFeedData);
        setDynamicFeedData({ ...dynamicFeedData, [name]: value });
    }

    useEffect(() => {
        updateDeeplink(dynamicFeedData.button_type);
    }, [dynamicFeedData.button_type])

    const updateDeeplink = (value) => {
        setIsShowDeepLink(true);
        setDeepSearchType('');
        setDisableDeeplink(false);
        switch (value) {
            case 'invite_friends':
                handleOnchangeFormaData('native_main/addfriends', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'language':
                var tempArr = languageDropdownResult && languageDropdownResult.data && languageDropdownResult.data.length > 0 ? languageDropdownResult.data : [];
                if (tempArr && tempArr.length > 0) {
                    let res = [];
                    tempArr.map((x) => {
                        res.push({ label: x.lang, value: x.code });
                    });
                    setDeepDropDownOpt([...res]);
                    setIsDeepDropDown(true);
                }
                break;
            case 'genre':
                var tempArr = genreDropdownResult && genreDropdownResult.data && genreDropdownResult.data.length > 0 ? genreDropdownResult.data : [];
                if (tempArr && tempArr.length > 0) {
                    let res = [];
                    tempArr.map((x) => {
                        res.push({ label: x.genre, value: x.genre.toLowerCase() });
                    });
                    setDeepDropDownOpt([...res]);
                    setIsDeepDropDown(true);
                }
                break;
            case 'actor':
                setDeepDropDownOpt([]);
                setDeepSearchType('Actor');
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'unexplored':
                handleOnchangeFormaData('native_main/unexplored', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'unspoken':
                handleOnchangeFormaData('native_main/hiddengems', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'newarrivals':
                handleOnchangeFormaData('native_main/fresh', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'recommendations':
                handleOnchangeFormaData('native_main/recommended', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'foreign':
                handleOnchangeFormaData('native_main/foreigncontent3', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'frenzi_points':
                handleOnchangeFormaData('native_main/frenzipointssummary', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'browser':
                setIsShowDeepLink(false);
                setIsDeepDropDown(false);
                break;
            case 'collection':
                handleOnchangeFormaData('native_main/curated', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'collections':
                var tempArr = collectionDataList;
                if (tempArr && tempArr.length > 0) {
                    let res = [];
                    tempArr.map((x) => {
                        res.push({ label: x.name, value: x.uuid });
                    });
                    setDeepDropDownOpt([...res]);
                    setIsDeepDropDown(true);
                }
                break;
            case 'for_you':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'user_watchlists':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'watchlist_detail':
                break;
            case 'top_searches':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'search':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'notification':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'groups':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'group_feed':
                setIsShowDeepLink(false);
                setIsDeepDropDown(false);
                break;
            case 'user_profile':
                setDeepDropDownOpt([]);
                setDeepSearchType('user');
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'trailers':
                handleOnchangeFormaData('native_main/trailersv3', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'free_content':
                handleOnchangeFormaData('native_main/free', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'media':
                setDeepDropDownOpt([]);
                setDeepSearchType('media');
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'trendinginfriends':
                handleOnchangeFormaData('native_main/trendinginfriends', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'toptwenty':
                handleOnchangeFormaData('native_main/toptwenty', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'coming_soon':
                handleOnchangeFormaData('native_main/comingsoon', 'button_deeplink');
                setIsDeepDropDown(false);
                setDisableDeeplink(true);
                break;
            case 'feedback_form':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                break;
            case 'null':
                setIsShowDeepLink(false);
                handleOnchangeFormaData('', 'button_deeplink');
                setIsDeepDropDown(false);
                //    if(this.feedForm.get('section').value=='dynamic_feed'){
                //      this.feedForm.get('button_text').setValue(null);
                //      this.feedForm.get('button_text').clearValidators();
                //      this.feedForm.get('button_text').updateValueAndValidity();
                //    }
                //    this.feedForm.get('button_deeplink').clearValidators();
                //    this.feedForm.get('button_deeplink').updateValueAndValidity();
                break;
        }
    }

    const handleSavePost = () => {
        if (checkError(dynamicFeedData)) {
            dispatch(pushDynamicPost(dynamicFeedData));
        }
    }

    const checkError = (data) => {
        var flag = true;
        Object.keys(data).map((key) => {
            if (requiredFormKeys.includes(key)) {
                if (!data[key] || data[key] == '' || data[key].length == 0) {
                    flag = false;
                    var msg = key + " cannot be empty"
                    showErr(msg);
                }
            }
        })
        if (isShowDeepLink && (data.button_deeplink == "" || !data.button_deeplink)) {
            flag = false;
            var msg = "Deeplink cannot be empty"
            showErr(msg);
        }
        return flag;
    }

    const showErr = (msg) => {
        toast.error((msg), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    console.log("dynamic--", dynamicFeedData);

    return (
        <>
            <Header />
            {isLoading && <Loader />}
            <ToastContainer />
            <div>
                <Container>
                    <div className='media'>
                        <h3>
                            Manage Feed
                        </h3>
                    </div>
                    <Card className='mt-3 pt-2 mb-3 pb-3' >
                        <Row className='align-items-center'>
                            <Col>
                                <Button
                                    className="btn-icon mb-3 ml-2 btn-simple"
                                    color="darker"
                                    size="md"
                                    type="button"
                                    style={{ maxWidth: 100 }}
                                    onClick={() => props.setIsEditOrCreate(false)}
                                >
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            </Col>
                            <Col>
                                <Row className="justify-content-end" >
                                    <h3>Pinned</h3>
                                    <CustomInput
                                        type="switch"
                                        id={`exampleCustomSwitch2`}
                                        name="customSwitch2"
                                        className='ml-3'
                                        checked={dynamicFeedData.is_pinned}
                                        onChange={(e) => handleOnchangeFormaData(e.target.checked, 'is_pinned')}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Col style={{ height: '50px' }}>
                            <Form>
                                <FormGroup row>
                                    <Label for="exampleEmail" sm={3} size="lg"><h3>Section Type</h3></Label>
                                    <Col sm={4}>
                                        <Dropdown color="black" className='btn-w-100' mt={4} isOpen={allDropDown} toggle={() => setAllDropDown(!allDropDown)} >
                                            <DropdownToggle caret>
                                                Feed
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>Feed</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>

                        <Row className={'m-0 mt-3 mb-3'} >
                            <Col sm={3}>
                                <h3 >
                                    Poster
                                </h3>
                            </Col>
                            <Col className='mx-3'>
                                <input ref={inputFileRef} onChange={(e) => handlePosterChange(e)} className='d-none' type={'file'} />
                                <Button
                                    className=" btn-icon btn-simple "
                                    color="darker"
                                    size="sm"
                                    type="button"
                                    onClick={() => inputFileRef.current.click()}
                                >
                                    <i className=" fas fa-upload pt-1"></i>
                                </Button>
                                {dynamicFeedData.poster && dynamicFeedData.poster != "" &&
                                    <img style={{ maxWidth: 70, borderRadius: 4 }} src={dynamicFeedData.poster} />
                                }
                            </Col>
                        </Row>
                        <Col sm={12}>
                            <Form>
                                <FormGroup row className='my-0'>
                                    <Label for="exampleEmail" sm={3} size="lg"><h3>Post Type</h3></Label>
                                    <Col sm={4}>
                                        <Dropdown color="black" className='btn-w-100' mt={4} isOpen={allDropDownPostType} toggle={() => setAllDropDownPostType(!allDropDownPostType)} >
                                            <DropdownToggle caret>
                                                {dynamicFeedData.post_type}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => handleOnchangeFormaData('image', 'post_type')}>Image</DropdownItem>
                                                <DropdownItem onClick={() => handleOnchangeFormaData('video', 'post_type')}>Video</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        {dynamicFeedData.post_type && dynamicFeedData.post_type == "video" &&
                            <Col>
                                <Form>
                                    <FormGroup row className='my-2'>
                                        <Label for="exampleEmail" sm={3} size="lg"><h3>Video Url</h3></Label>
                                        <Col sm={7}>
                                            <Input type="text" name="video_url" id="exampleEmail" placeholder="" />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        }
                        <Col>
                            <Form>
                                <FormGroup row className='my-2'>
                                    <Label for="exampleEmail" sm={3} size="lg"><h3>Caption</h3></Label>
                                    <Col sm={7}>
                                        <Input type="text" placeholder="Enter Caption" onChange={(e) => handleOnchangeFormaData(e.target.value, 'caption')} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup row className='my-2'>
                                    <Label for="exampleEmail" sm={3} size="lg"><h3>Description</h3></Label>
                                    <Col sm={7}>
                                        <Input type="textarea" placeholder='Add Post Caption' name="text" id="exampleText" onChange={(e) => handleOnchangeFormaData(e.target.value, 'post_caption')} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup row className='my-2 '>
                                    <Label for="exampleEmail" sm={3} size="lg"><h3>Button Type</h3></Label>
                                    <Col sm={7}>
                                        <Dropdown color="black" className='btn-w-100' mt={4} isOpen={allDropDownPost} toggle={() => setAllDropDownPost(!allDropDownPost)} >
                                            <DropdownToggle caret>
                                                {dynamicFeedData.button_type && dynamicFeedData.button_type != "" ? button_dropdown.filter(j => j.value == dynamicFeedData.button_type)[0].name : 'Choose Button Type'}
                                            </DropdownToggle>
                                            <DropdownMenu style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                                                {button_dropdown.map((x, i) => {
                                                    return <DropdownItem key={i} onClick={() => handleOnchangeFormaData(x.value, 'button_type')}>{x.name}</DropdownItem>
                                                })}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col>
                            <Form>
                                <FormGroup row className='my-2'>
                                    <Label for="exampleEmail" sm={3} size="lg"><h3>Button Text</h3></Label>
                                    <Col sm={7}>
                                        <Input type="text" placeholder="Add Button Text" value={dynamicFeedData.button_text} onChange={(e) => handleOnchangeFormaData(e.target.value, 'button_text')} />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        {dynamicFeedData.button_type == "media" &&
                            <Col>
                                <Form>
                                    <FormGroup row className='my-2'>
                                        <Label for="exampleEmail" sm={3} size="lg"><h3>Search Type</h3></Label>
                                        <Col sm={7}>
                                            <Dropdown color="black" className='btn-w-100' mt={4} isOpen={allDropDownPost2} toggle={() => setAllDropDownPost2(!allDropDownPost2)} >
                                                <DropdownToggle caret>
                                                    {searchType}
                                                </DropdownToggle>
                                                <DropdownMenu style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                                                    <DropdownItem onClick={() => setSearchType('Elastic Search')}>Elastic Search</DropdownItem>
                                                    <DropdownItem onClick={() => setSearchType('DB Search')}>DB Search</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        }
                        {isShowDeepLink &&
                            <Col>
                                <Form>
                                    <FormGroup row className='my-2'>
                                        <Label for="exampleCustomSelectDisabled" sm={3} size="lg"><h3>Deep Link</h3></Label>
                                        <Col sm={7}>
                                            {isDeepDropDown ?
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    components={animatedComponents}
                                                    // isMulti
                                                    isClearable={true}
                                                    className="w-100"
                                                    options={deepDropDownOpt}
                                                    value={
                                                        deepDropDownOpt.filter(option =>
                                                            option.value === dynamicFeedData.button_deeplink)
                                                    }
                                                    onChange={(e) => handleOnchangeFormaData(e && e.value ? e.value : '', 'button_deeplink')}
                                                />
                                                :

                                                (deepSearchType == "user" || deepSearchType == "media" || deepSearchType == "Actor") ?
                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        components={animatedComponents}
                                                        isClearable={true}
                                                        // isMulti
                                                        className="w-100"
                                                        options={getOptionForSearchResults()}
                                                        // value={getSearchResultValue()}
                                                        onChange={(e) => handleOnchangeFormaData(e && e.value ? e.value : '', 'button_deeplink')}
                                                        onInputChange={(value) => handleMediaSearch(value)}
                                                    />
                                                    :
                                                    <Input disabled={disableDeeplink} type="text" value={dynamicFeedData.button_deeplink} placeholder="Add Deeplink" onChange={(e) => handleOnchangeFormaData(e.target.value, 'button_deeplink')} />
                                            }
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        }
                        <Col>
                            <Form className='edit_media_input w-100'>
                                <FormGroup row className='my-2'>
                                    <Label for="exampleCustomSelectDisabled" sm={3} size="lg"><h3>Set Preference Language</h3></Label>
                                    <Col sm="7">
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            isMulti
                                            className="w-100"
                                            options={getLanguageOptions(languageDropdownResult)}
                                            value={
                                                getLanguageOptionsValue(languageDropdownResult, dynamicFeedData.language)
                                            }
                                            onChange={(e) => {
                                                var tempArr = e.map(x => x.value);
                                                handleOnchangeFormaData(tempArr, 'language')
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                        {/* <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"><h3>Curated</h3></Label>
                                            <Col sm={4} className="curated-form">
                                                <Input type="radio" className='radio-button' />{' Automatically'}
                                                <FormGroup className='mt-2'>
                                                    <Label for="exampleEmail">Deep Link</Label>
                                                    <Input type="email" id="exampleEmail" placeholder="" />
                                                </FormGroup>
                                                <Row className='media-count'>
                                                    <h4>Media Count</h4>
                                                    <input type={'number'} className='input-type' />
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col>
                                    <Form>
                                        <FormGroup row className='my-2'>
                                            <Label for="exampleEmail" sm={3} size="lg"></Label>
                                            <Col sm={4} className="curated-form">
                                                <Input type="radio" className='radio-button' />{' Manually'}
                                                <FormGroup className='mt-2'>
                                                    <Label for="exampleEmail">20 Media Selected</Label>
                                                    <Input type="email" id="exampleEmail" placeholder="" />
                                                </FormGroup>
                                                <Button size="sm" style={{ backgroundColor: '#b4a7d6', borderWidth: 1 }}>Edit Media</Button>
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                </Col> */}
                        <Row>
                            <Col sm={3} ></Col>
                            <Col sm={4} className="success-btn">
                                <Button color="darker" onClick={() => handleSavePost()} className='btn-width'>Save</Button>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </div>
        </>
    )
}

