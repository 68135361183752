import { WEB } from "../constants/constants";


const initialState = {
    isLoading: false,
    isAuthenticated: localStorage.getItem("frenzi_admin_access") ? true : false,
    token: localStorage.getItem("frenzi_admin_access") ? JSON.parse(localStorage.getItem("frenzi_admin_access")).access : '',
    user: {},
    errMesg: "",
    status: "",
    message: '',
    req: {},
    collectinsData: {},
    actorDropDownData:{}
};

export const dynamicFeedReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case WEB.DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.DYNAMIC_FEED_PAGE_CLEAR_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
            };

        case WEB.GET_COLLECTIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_COLLECTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
                collectinsData: payload,
            };
        case WEB.GET_COLLECTIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.PUSH_DYNAMIC_POST_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.PUSH_DYNAMIC_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: payload.message ? payload.message : 'Feed Posted',
            };
        case WEB.PUSH_DYNAMIC_POST_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };
        case WEB.GET_ACTOR_DROPDOWN_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case WEB.GET_ACTOR_DROPDOWN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                errMesg: "",
                status: '',
                actorDropDownData: payload,
            };
        case WEB.GET_ACTOR_DROPDOWN_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errMesg: payload.error,
            };

        default:
            return state;
    }
}