import React from 'react'
import Header from "components/Headers/Header.js";
import { Modal, ModalHeader, Container, Card, Row, Col, Button, Input, Table, CustomInput, } from 'reactstrap';

const list = [
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/altbalaji.png",
        name: "ALT Balaji"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/amazo_pay.png",
        name: "Amazon Pay"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Amazon Prime"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Book My Show"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/ottimg/primevideo.png",
        name: "Amazon Prime"
    },
    {
        image: "https://temp-bucket-agora.s3.ap-south-1.amazonaws.com/Subscription_uploads/platforms/bookmyshow.png",
        name: "Book My Show"
    }

]

export default function Language() {
    const inputFileRef = React.useRef(null);
    const [isLanguageAdd, setIsLanguageAdd] = React.useState(false)
    const [isNewsAdd, setIsNewsAdd] = React.useState(false)
    
    return (
        <>
            <Header />
            <Container>
                <Card className='mt-4 p-3'>
                    <h3 className='mb-3'>Language</h3>
                    <Row className='justify-content-between m-0'>
                        <Col sm={4} className="p-0 search-icon-box">
                            <Input placeholder='Search' />
                            <i className="fas fa-search"></i>
                        </Col>
                        <Button color="info" onClick={() => setIsLanguageAdd(!isLanguageAdd)} >Add Language</Button>
                    </Row>
                    <Table hover className='mt-3'>
                        <thead>
                            <tr>
                                <th width="7%">
                                    S.No.
                                </th>
                                <th>
                                    Language
                                </th>
                                <th>
                                    Logo
                                </th>

                                <th>
                                    Code
                                </th>
                                <th>
                                    Order
                                </th>
                                <th>
                                    Is Active
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((x, i) => {
                                return <tr>
                                    <th scope="row">
                                        {i + 1}
                                    </th>
                                    <td>
                                        {x.name}
                                    </td>
                                    <td>
                                        <img className='platform-img' src={x.image} />
                                    </td>

                                    <td>
                                        4.3
                                    </td>
                                    <td>
                                        N/A
                                    </td>
                                    <td>
                                        <CustomInput
                                            type="switch"
                                            id={`exampleCustomSwitch-${i}`}
                                            name="customSwitch"
                                            className=''
                                        // onChange={(e) => console.log(e.target.checked) }
                                        />
                                    </td>
                                    <td>
                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="info"
                                            size="sm"
                                            type="button"
                                            style={{ marginRight: 10 }}
                                        >
                                            <i className="fas fa-pen pt-1"></i>
                                        </Button>

                                        <Button
                                            className=" btn-icon btn-simple"
                                            color="danger"
                                            size="sm"
                                            type="button"
                                        >
                                            <i className="fas fa-trash pt-1"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Card>
                <Modal
                    isOpen={isLanguageAdd}
                    toggle={() => setIsLanguageAdd(!isLanguageAdd)}
                >
                    <ModalHeader className='p-2' toggle={() => setIsLanguageAdd(!isLanguageAdd)}>
                        <h3>Add Language</h3>
                    </ModalHeader>
                    <div style={{ marginLeft: '15px' }}>
                        <div className='p-2'>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Language</p>
                                </Col>
                                <Col sm={7}>
                                    <Input
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className='p-2'>
                            <Row className='pl-3 pr-3 mt-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Poster</p>
                                </Col>
                                <Col >
                                    <input ref={inputFileRef} className='d-none' type={'file'} />
                                    <Button
                                        className=" btn-icon btn-simple"
                                        color="info"
                                        size="sm"
                                        type="button"
                                        onClick={() => inputFileRef.current.click()}
                                    >
                                        <i className="fas fa-upload pt-1"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        <div className='p-2'>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Code</p>
                                </Col>
                                <Col sm={7}>
                                    <Input
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className='p-2'>
                            <Row className='pl-3 mt-3 pr-3 w-100'>
                                <Col sm={5} className="p-0">
                                    <p className='mr-3 mb-0 v-middle'>Is Active</p>
                                </Col>
                                <Col sm={7}>
                                    <CustomInput
                                        type="switch"
                                        id={`exampleCustomSwitch`}
                                        name="customSwitch"
                                        className=''
                                    // onChange={(e) => console.log(e.target.checked) }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className='text-center mt-3 mb-3'>
                            <Button
                                color="success"
                                onClick={() => setIsNewsAdd(!isNewsAdd)}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </Modal>
            </Container>
        </>
    )
}
